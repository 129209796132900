import { QueryKey } from 'modules/module-api'
import { strings } from 'modules/module-utils'
import { identityFor } from 'modules/utils'
import prefix from 'modules/utils/prefix'
import { createAction } from 'redux-actions'
import analytics, { AnalyticalEvent } from '../../module-analytics'
import {
  IDetermineUserPermissionsPayload,
  ILoggedInPayload,
  ILoginSsoPayload,
  IQueryResultPayload,
  IRestoredPayload,
} from '../model'
import { IDeleteAllRecipientsPayload, ISetActiveBuildingsPayload } from './models'

export const namespaced = prefix('core/orchestration')

export const RESTORE = namespaced('restore')
export const restore = createAction(RESTORE)

export const RESTORED = namespaced('restored')
export const restored = createAction<IRestoredPayload>(RESTORED)

export const LOGIN_SSO = namespaced('loginSso')
export const loginSso = createAction<ILoginSsoPayload>(LOGIN_SSO)

export const LOGGED_IN = namespaced('loggedIn')
export const loggedIn = createAction(LOGGED_IN, identityFor<ILoggedInPayload>(), (_) =>
  analytics.meta.whitelist({
    name: AnalyticalEvent.Login,
  }),
)

export const DETERMINE_USER_PERMISSIONS = namespaced('determineUserPermissions')
export const determineUserPermissions = createAction<IDetermineUserPermissionsPayload>(DETERMINE_USER_PERMISSIONS)

export const ACCOUNT_GEOLOCATION_READY = namespaced('accountGeolocationReady')
export const accountGeoLocationReady = createAction(ACCOUNT_GEOLOCATION_READY)

export const DELETE_ALL_RECIPIENTS = namespaced('deleteAllRecipients')
export const deleteAllRecipients = createAction<IDeleteAllRecipientsPayload>(DELETE_ALL_RECIPIENTS)

export const SET_IS_WIPING_BUILDINGS = namespaced('setIsWipingBuildings')
export const setIsWipingBuildings = createAction<boolean>(SET_IS_WIPING_BUILDINGS)

export const SET_ACTIVE_BUILDINGS = namespaced('setActiveBuildings')
export const setActiveBuildings = createAction<ISetActiveBuildingsPayload>(SET_ACTIVE_BUILDINGS)

export const queryResult = (queryKey: QueryKey) =>
  createAction<IQueryResultPayload>(namespaced(`${strings.camelCase(queryKey)}Result`))

export const ERROR = namespaced('error')
export const error = createAction(ERROR, (payload: string) => ({
  payload,
  meta: analytics.meta.whitelist({
    data: { message: payload },
    name: AnalyticalEvent.Error,
  }),
}))
