import { createReducer } from '@reduxjs/toolkit'

import permissions from '../permissions/Permissions'

import { TableSelectionAction } from 'modules/web-molecules'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {
  clearError,
  error,
  resetFormState,
  setActiveBuildings,
  setCreateStep,
  setDeletionLoading,
  setFormState,
  setIsCreateForm,
  setLoading,
  setPermissions,
  setRecipientType,
  setSelectedItems,
  setSelectedRecipient,
  setVisibleColumns,
} from './actions'
import { CreateRecipientStep, FormState, initialState } from './models'

const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(setCreateStep, (state, { payload }) => {
      state.createRecipientStep = payload
    })
    .addCase(setLoading, (state, { payload }) => {
      state.loading = payload
    })
    .addCase(setDeletionLoading, (state, { payload }) => {
      state.isDeleting = payload
    })
    .addCase(setSelectedItems, (state, { payload }) => {
      switch (payload.action) {
        case TableSelectionAction.DESELECTED_PAGE:
          state.selectedItems = state.selectedItems.filter(
            (currentSelection) => !payload.items.includes(currentSelection),
          )
          break
        case TableSelectionAction.DESELECTED_ALL:
          state.selectedItems = []
          break
        case TableSelectionAction.SELECTED:
          {
            const newList = [...state.selectedItems, ...payload.items]
            state.selectedItems = newList.filter((element, index) => {
              return newList.indexOf(element) === index
            })
          }
          break
        case TableSelectionAction.DESELECTED:
          state.selectedItems = state.selectedItems.filter((currentSelection) => currentSelection != payload.items[0])
          break
      }
    })
    .addCase(setSelectedRecipient, (state, { payload }) => {
      state.isCreateForm = false
      state.createRecipientStep = CreateRecipientStep.RECIPIENT_TYPE
      state.createRecipientType = undefined

      state.selectedRecipient = payload
    })
    .addCase(setRecipientType, (state, { payload }) => {
      state.createRecipientType = payload
    })
    .addCase(setIsCreateForm, (state, { payload }) => {
      state.isCreateForm = payload
    })
    .addCase(resetFormState, (state, _) => {
      state.formState = FormState.HIDDEN
      state.selectedRecipient = undefined
      state.createRecipientStep = CreateRecipientStep.RECIPIENT_TYPE
      state.createRecipientType = undefined
    })
    .addCase(setActiveBuildings, (state, { payload }) => {
      state.activeBuildings = payload
    })
    .addCase(error, (state, { payload }) => {
      state.error = payload
    })
    .addCase(clearError, (state, _) => {
      state.error = undefined
    })
    .addCase(setPermissions, (state, { payload: { userType, canManageRecipients } }) => {
      state.permissions.canCreateRecipient = !!canManageRecipients && permissions.canCreateRecipient.includes(userType)
      state.permissions.canEditRecipient = !!canManageRecipients && permissions.canEditRecipient.includes(userType)
      state.permissions.canViewRecipient = permissions.canViewRecipient.includes(userType)
    })
    .addCase(setFormState, (state, { payload }) => {
      state.formState = payload
    })
    .addCase(setVisibleColumns, (state, { payload }) => {
      state.persistent.visibleColumns = payload
    }),
)

const persistConfig = {
  key: 'recipients-visible-columns',
  storage,
  whitelist: ['persistent'],
}

export const persistedReducer = persistReducer(persistConfig, reducer)

export default persistedReducer
