import React from 'react'

import { EuiTableBody } from '@elastic/eui'
import styled from '@emotion/styled'

import { StyledViewProps } from 'modules/web-atoms'
import * as system from 'styled-system'

export type ITableBodyProps = StyledViewProps & React.ComponentProps<typeof EuiTableBody>

export const StyledTableBody = styled(EuiTableBody)<ITableBodyProps>(
  system.color,
  system.flexbox,
  system.space,
  system.layout,
  system.position,
  system.justifyContent,
  system.alignItems,
  system.borders,
  system.shadow,
  system.background,
)

export const TableBody: React.FC<ITableBodyProps> = (props) => {
  const { children, ...rest } = props
  return <StyledTableBody {...rest}>{children}</StyledTableBody>
}

export default React.memo(TableBody)
