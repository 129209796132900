import { IDropoffLocationDto } from 'api'
import { Expose } from 'class-transformer'
import 'reflect-metadata'

export class ITagItemDto {
  @Expose({ name: 'Id' })
  id: number
  @Expose({ name: 'Name' })
  name: string
  @Expose({ name: 'ColourHex' })
  color: string
  @Expose({ name: 'Enabled' })
  enabled: boolean
  @Expose({ name: 'ClientId' })
  clientId: number | null
  @Expose({ name: 'DevelopmentId' })
  siteId?: number
}

export class ILocationsDto {
  @Expose({ name: 'Id' })
  id: string
  @Expose({ name: 'Name' })
  name: string
  @Expose({ name: 'PublicId' })
  identifier: string
  @Expose({ name: 'DevelopmentId' })
  siteId: number
  @Expose({ name: 'Enabled' })
  enabled?: boolean
}

export class ICourierDto {
  @Expose({ name: 'Id' })
  id: string
  @Expose({ name: 'Name' })
  name: string
  @Expose({ name: 'ClientId' })
  clientId?: number
}

export class ISizeDto {
  @Expose({ name: 'Id' })
  id: string
  @Expose({ name: 'Name' })
  name: string
  @Expose({ name: 'ClientId' })
  clientId?: number
}

export class IPaginatedDropoffLocationsDto extends IDropoffLocationDto {
  @Expose({ name: 'RecipientsCount' })
  numAssignedRecipients: number
}

export class IDeleteDropoffLocationsDto {
  @Expose({ name: 'LocationIds' })
  dropoffLocationIds: string[]
}

export class IDeleteParcelSizesDto {
  @Expose({ name: 'Ids' })
  ids: string[]
}
