import autobind from 'autobind-decorator'
import navigation from 'modules/module-navigation'
import { IUserServerResolveDto } from 'modules/module-onboarding/api/dto'
import { resolveUserServer } from 'modules/module-onboarding/api/onboarding-api'
import authentication, { LoginStep, SignupStep } from 'modules/module-onboarding/authentication'
import orchestration, { ILoginSsoPayload } from 'modules/module-orchestration'
import { restored } from 'modules/module-orchestration/orchestration/actions'
import reporting from 'modules/module-reporting'
import security, { OnboardingStage, Role, serverIdStore } from 'modules/module-security'
import { TokenService } from 'modules/module-security/tokens/TokenService'
import { SupervisorSuite } from 'modules/redux-supervisor'
import { Action } from 'redux-actions'
import { SagaIterator } from 'redux-saga'
import { all, call, put, select, takeLatest, takeLeading } from 'redux-saga/effects'
import sha512 from 'sha512'
import { refresh } from '../api/core-api'
import { IRequestTokenDto } from 'api'

export class AuthenticationOrchestrationSaga extends SupervisorSuite {
  private readonly tokenService: TokenService

  constructor(tokenService: TokenService) {
    super()
    this.tokenService = tokenService
  }

  @autobind
  *start(): SagaIterator {
    yield all([
      takeLeading(orchestration.actions.loginSso, this.loginSso),
      takeLatest(orchestration.actions.RESTORED, this.onRestored),
    ])
  }

  @autobind
  *loginSso({ payload }: Action<ILoginSsoPayload>): SagaIterator {
    const { email, enityId, token } = payload

    try {
      yield call(this.tokenService.dispose)
      yield put(authentication.actions.loading(true))
      yield put(authentication.actions.loginStep(LoginStep.Loading))

      const hashedEmail = sha512(email).toString('hex')
      const doubleHashedEmail = sha512(`${email}${email}`).toString('hex')

      const serverResolveDto: IUserServerResolveDto = yield call(resolveUserServer, hashedEmail, doubleHashedEmail)
      yield call(serverIdStore.set, serverResolveDto.serverId)

      const { accessToken }: IRequestTokenDto = yield call(refresh, token, enityId)
      yield put(authentication.actions.login({ email, accessToken, rememberMe: true, preHashed: false, requiresResolve: false }))
    } catch (err) {
      yield put(reporting.actions.error(err))
    }
  }

  @autobind
  *onRestored({ payload: { success } }: ReturnType<typeof restored>): SagaIterator {
    if (success) {
      const role = yield select(security.selectors.role)
      const onboardingStages = yield select(security.selectors.onboardingStages)

      if (role == Role.USER) {
        if (!onboardingStages.includes(OnboardingStage.ACC_PURPOSE_SELECTED)) {
          yield put(authentication.actions.signupStep(SignupStep.AccountType))
        } else if (!onboardingStages.includes(OnboardingStage.BUILDING_CONFIGURED)) {
          yield put(authentication.actions.signupStep(SignupStep.ConfigureBuilding))
        } else {
          yield put(security.actions.authenticated(true))
          return
        }
        yield put(navigation.actions.navigate({ route: '/auth/signup' }))
      } else {
        yield put(security.actions.authenticated(true))
      }
    } else {
      yield call(this.tokenService.dispose)
    }
  }
}

export default AuthenticationOrchestrationSaga
