import { IFeatureFlagsDto, UserType } from 'api'

export interface ISessionState {
  loading: boolean
  isAuthenticated: boolean
  role?: Role
  username?: string
  userId?: string

  // TODO: Axios caching should replace below
  email?: string
  firstName?: string
  lastName?: string
  phone?: string
  dateOfBirth?: string
  type?: UserType
  primarySite?: number
  additionalSites?: number[]
  client?: any
  clientId?: number
  development?: any
  smsEnabled: boolean
  countryCode?: string

  featureFlags?: IFeatureFlagsDto
}

export enum Role {
  USER = 'USER',
  RECIPIENT = 'RECIPIENT',
}

export enum OnboardingStage {
  SIGNUP_STARTED = 'SIGNUP_STARTED',
  ACC_PURPOSE_SELECTED = 'ACC_PURPOSE_SELECTED',
  WATCH_VIDEO = 'WATCH_VIDEO',
  BUILDING_CONFIGURED = 'BUILDING_CONFIGURED',
  DEMO_BOOKED = 'DEMO_BOOKED',
  AWAIT_APP_DOWNLOAD = 'AWAIT_APP_DOWNLOAD',
  AWAIT_PARCEL_SCAN = 'AWAIT_PARCEL_SCAN',
  FIRST_PARCEL_SCANNED = 'FIRST_PARCEL_SCANNED',
  APP_SETUP_FINISHED = 'APP_SETUP_FINISHED',
  APP_SETUP_SKIPPED = 'APP_SETUP_SKIPPED',
  APP_SETUP_COMPLETE = 'APP_SETUP_COMPLETE',
  ONBOARDING_SKIPPED = 'ONBOARDING_SKIPPED',
  RECIPIENTS_UPLOADED = 'RECIPIENTS_UPLOADED',
  RECIPIENTS_UPLOAD_SKIPPED = 'RECIPIENTS_UPLOAD_SKIPPED',
}

export interface IPullCurrentUserPayload {
  role: Role
}

export interface ISetCurrentUserPayload {
  userId: string
  email?: string
  firstName?: string
  lastName?: string
  phone?: string
  type: number
  role: Role
  primarySite: number
  client: any
  development: any
  smsEnabled: boolean
}

export interface ISetCurrentTenantPayload {
  userId: string
  email: string
  firstName?: string
  lastName?: string
  role: Role
  primarySite: number
  additionalSites: number[]
  dateOfBirth?: string
}

export interface IResultPayload {
  success: boolean
}
