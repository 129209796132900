/* eslint-disable no-redeclare */
import { UseQueryOptions, UseQueryResult } from '@tanstack/react-query'
import * as React from 'react'

import { AxiosPromise } from 'axios'
import { ClassConstructor, plainToClass } from 'class-transformer'
import reporting from 'modules/module-reporting'
import { useAppDispatch } from 'modules/module-utils/hooks'
import useApi from '../../hooks/useApi/use-api'

import QueryKey from '../../query/query'

function useRequest<T, R>(
  queryKey: QueryKey,
  dto: ClassConstructor<T>,
  api: AxiosPromise<R[]>,
  ...params: any[]
): UseQueryResult<T[], any>

function useRequest<T, R>(
  queryKey: QueryKey,
  dto: ClassConstructor<T>,
  api: AxiosPromise<R>,
  ...params: any[]
): UseQueryResult<T, any>

// DO NOT USE: CURRENTLY BROKEN - need to fix rerender issues with params
function useRequest(queryKey: any, dto: any, api: any, ...params: any[]) {
  const dispatch = useAppDispatch()

  const options: UseQueryOptions<any> = React.useMemo(() => {
    return { enabled: !params.length }
  }, [params.length])

  const { data, ...result } = useApi<any>([queryKey, ...params], async () => await api.data, options)

  React.useEffect(() => {
    params.length &&
      result.refetch().catch((error) => {
        dispatch(reporting.actions.error(error))
      })
  }, [params, dispatch, result])

  const parsedData = React.useMemo(() => {
    return data ? plainToClass(dto, data) : undefined
  }, [data, dto])

  return { data: parsedData, ...result }
}

export default useRequest
