/* eslint-disable no-undef */
import { createSelector, Selector } from 'reselect'
import { BRANCH_KEY } from '../branch'

import { IDashboardOwnState } from '../model'

import { IBoardState } from './models'

export const branch: Selector<IDashboardOwnState, IBoardState> = (state) => state[BRANCH_KEY].dashboard

export const currentScreen = createSelector(branch, (branch) => branch.currentScreen)

export const parcelsWaiting = createSelector(branch, (branch) => branch.parcelsWaiting)

export const parcelsScanned = createSelector(branch, (branch) => branch.parcelsScanned)

export const displayDashboardModal = createSelector(branch, (branch) => branch.displayDashboardModal)

export const currentOnboardingModalComponent = createSelector(
  branch,
  (branch) => branch.currentOnboardingModalComponent,
)

export const isLoading = createSelector(branch, (branch) => branch.loading)

export const error = createSelector(branch, (branch) => branch.error)
