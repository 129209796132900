// core styles
import './modules/module-core/intl/i18n'
import './modules/dna/themes/scss/eui_deepfinity_theme.scss'
import 'reflect-metadata'

import React from 'react'
import ReactDOM from 'react-dom'
/* Redux */
import { Provider } from 'react-redux'

import store, { persistor } from 'modules/module-core/redux/store'

import App from './modules/module-core/App'
import { PersistGate } from 'redux-persist/lib/integration/react'
import { EuiErrorBoundary } from '@elastic/eui'

ReactDOM.render(
  <EuiErrorBoundary>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </EuiErrorBoundary>,
  document.getElementById('root'),
)
