export enum Spacing {
  None = 0,
  Auto = 'auto',
  Macro = 4,
  Smallest = 8,
  Small = 16,
  XSmall = 22,
  Medium = 24,
  Large = 32,
  XLarge = 56,
  XXLarge = 96,
  Massive = 192,
}

export type SemanticSpacing =
  | 'none'
  | 'auto'
  | 'macro'
  | 'smallest'
  | 'small'
  | 'xsmall'
  | 'medium'
  | 'large'
  | 'xlarge'
  | 'xxlarge'
  | 'massive'

export type SpacingSizes = Record<SemanticSpacing, Spacing>

export const spacingSizes: SpacingSizes = {
  none: Spacing.None,
  auto: Spacing.Auto,
  macro: Spacing.Macro,
  smallest: Spacing.Smallest,
  small: Spacing.Small,
  xsmall: Spacing.XSmall,
  medium: Spacing.Medium,
  large: Spacing.Large,
  xlarge: Spacing.XLarge,
  xxlarge: Spacing.XXLarge,
  massive: Spacing.Massive,
}
