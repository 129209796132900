import { createAction } from 'redux-actions'

import prefix from 'modules/utils/prefix'

import { AnalyticsConfiguration, IAnalyticsIdentifyPayload, IContextPayload } from '../model'

export const namespaced = prefix('analytics')

export const CONFIGURE = namespaced('configure')
export const configure = createAction<AnalyticsConfiguration>(CONFIGURE)

export const IDENTIFY = namespaced('identify')
export const identify = createAction<IAnalyticsIdentifyPayload>(IDENTIFY)

export const REMOVE_CONTEXT = namespaced('remove-context')
export const removeContext = createAction<IContextPayload>(REMOVE_CONTEXT)

export const CLEAN = namespaced('clean')
export const clean = createAction(CLEAN)

export const FLUSH = namespaced('flush')
export const flush = createAction(FLUSH)

export const EVENTS_SENT = namespaced('events-sent')
export const eventsSent = createAction(EVENTS_SENT)
