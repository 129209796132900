export namespace strings {
  export const capitalize = (value: string | undefined): string => {
    if (!value) return ''
    const trimmed = value.trim()
    return trimmed[0].toUpperCase() + trimmed.slice(1)
  }

  export const camelCase = (value: string): string => {
    return value
      .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
        return index === 0 ? word.toLowerCase() : word.toUpperCase()
      })
      .replace(/(\s+|-|_)/g, '')
  }
}

export default strings
