/*
--------------------------------------------------------------------------
 Stats
--------------------------------------------------------------------------
*/
import moment from 'moment'

import API from './clients'

const OFFSET = moment().utcOffset() / 60

/* Stats */
export async function getTotalParcelsScanned(developmentIds) {
  return API({
    method: 'get',
    url: '/v3/stats/parcels/total_scanned',
    params: { development_ids: developmentIds, include_change: true },
  })
}

export async function getParcelsScanned(developmentIds) {
  return API({
    method: 'get',
    url: '/v3/stats/parcels/scanned',
    params: { development_ids: developmentIds, include_change: true, utc_offset: OFFSET },
  })
}

export async function getParcelsDelivered(developmentIds) {
  return API({
    method: 'get',
    url: '/v3/stats/parcels/delivered',
    params: { development_ids: developmentIds, include_change: true, utc_offset: OFFSET },
  })
}

export async function getParcelsHeld(developmentIds) {
  return API({
    method: 'get',
    url: '/v3/stats/parcels/held',
    params: { development_ids: developmentIds, include_change: true, utc_offset: OFFSET },
  })
}

export async function getParcelsCollected(developmentIds) {
  return API({
    method: 'get',
    url: '/v3/stats/parcels/collected',
    params: { development_ids: developmentIds, include_change: true, utc_offset: OFFSET },
  })
}

export async function getChartsDataPeak(developmentIds, time = 'daily') {
  return API({
    method: 'get',
    url: `/v3/stats/charts_data/peak_times/${time}`,
    params: { development_ids: developmentIds, utc_offset: OFFSET },
  })
}

export async function getChartsDataPeakCSV(developmentIds, time = 'daily') {
  return API({
    method: 'get',
    url: '/v3/stats/charts_data/peak_times/${time}/export',
    params: { development_ids: developmentIds, utc_offset: OFFSET },
  })
}

export async function getChartsDataParcels(developmentIds, from, to) {
  return API({
    method: 'get',
    url: '/v3/stats/charts_data/parcels/delivered_collected',
    params: { development_ids: developmentIds, from, to, utc_offset: OFFSET },
  })
}

export async function getChartsDataParcelsCSV(developmentIds, from, to) {
  return API({
    method: 'get',
    url: '/v3/stats/charts_data/parcels/delivered_collected/export',
    params: { development_ids: developmentIds, from, to, utc_offset: OFFSET },
  })
}

export async function getChartsDataScanningTimes(developmentIds, from, to) {
  return API({
    method: 'get',
    url: '/v3/stats/charts_data/scanning/daily',
    params: { development_ids: developmentIds, utc_offset: OFFSET, from, to },
  })
}

export async function getChartsDataScanningTimesCSV(developmentIds, from, to) {
  return API({
    method: 'get',
    url: '/v3/stats/charts_data/scanning/daily/export',
    params: { development_ids: developmentIds, utc_offset: OFFSET, from, to },
  })
}

export async function getChartsDataTurnaroundTime(developmentIds, from, to) {
  return API({
    method: 'get',
    url: '/v3/stats/charts_data/turnaround/daily',
    params: { development_ids: developmentIds, utc_offset: OFFSET, from, to },
  })
}

export async function getChartsDataTurnaroundTimeCSV(developmentIds, from, to) {
  return API({
    method: 'get',
    url: '/v3/stats/charts_data/turnaround/daily/expor',
    params: { development_ids: developmentIds, utc_offset: OFFSET, from, to },
  })
}

export async function getChartsData(developmentIds, from, to, chartType, isExport) {
  return API({
    method: 'get',
    url: `/v3/stats/charts_data/${chartType}/daily${isExport ? '/export' : ''}`,
    params: { development_ids: developmentIds, utc_offset: OFFSET, from, to },
  })
}
