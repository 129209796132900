import security from 'modules/module-security'
import { useAppSelector } from 'modules/module-utils'
import { Grid, Paragraph, useTranslate } from 'modules/web-atoms'
import React from 'react'

export interface IInsufficientAccessProps extends React.ComponentProps<typeof Grid> {}

const InsufficientAccess: React.FC<IInsufficientAccessProps> = ({ ...props }) => {
  const t = useTranslate('web-molecules.insufficient-access')
  const userId = useAppSelector(security.selectors.id)
  const featureFlags = useAppSelector(security.selectors.featureFlags)

  return (
    <>
      {!!userId && !!featureFlags && (
        <Grid height="100%" width="100%" alignItems="center" justifyContent="center" {...props}>
          <Paragraph size="large" fontWeight="bold">
            {t('no-access')}
          </Paragraph>
        </Grid>
      )}
    </>
  )
}

export default React.memo(InsufficientAccess)
