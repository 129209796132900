import * as React from 'react'

import { CheckCircle } from 'modules/dna'
import { Grid, H1, H2, Image, Paragraph, useTranslate } from 'modules/web-atoms'

export interface FinishOnboardingStepProps {}

export const FinishOnboardingStep: React.FC<FinishOnboardingStepProps> = () => {
  const t = useTranslate('onboarding.signup.onboarding.finish-onboarding-step')
  return (
    <Grid>
      <Grid.Col width={['100%', 650]} alignItems="center" justifyContent="center">
        <H1 textAlign="center" marginBottom="smallest">
          {t('heading')}
        </H1>
        <H2 marginBottom="medium" paddingX="large">
          Press finish to start uploading your <br /> recipients and log parcels for them
        </H2>
        <Grid>
          <Grid.Col alignItems="center" marginBottom="large">
            <Image width={190} src={CheckCircle} />
          </Grid.Col>
        </Grid>
        <Paragraph color="primaryMid" textAlign="center">
          {t('check-email')}
        </Paragraph>
      </Grid.Col>
    </Grid>
  )
}

export default React.memo(FinishOnboardingStep)
