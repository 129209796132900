import * as React from 'react'

import { EuiToast, IconType } from '@elastic/eui'

export enum ToastType {
  INFO,
  SUCCESS,
  WARNING,
  DANGER,
}

type ToastColor = 'primary' | 'success' | 'warning' | 'danger'

export interface IToastStyles {
  color: ToastColor
  iconType: IconType
  toastLifeTimeMs?: number
}

export const ToastTypeStyles: Record<ToastType, IToastStyles> = {
  [ToastType.INFO]: { color: 'primary', iconType: 'asterisk' },
  [ToastType.SUCCESS]: { color: 'success', iconType: 'check' },
  [ToastType.WARNING]: { color: 'warning', iconType: 'help' },
  [ToastType.DANGER]: { color: 'danger', iconType: 'alert' },
}

export interface IToastProps extends React.ComponentProps<typeof EuiToast> {
  type: ToastType
}

export const Toast: React.FC<IToastProps> = (props) => {
  const { type, children, ...rest } = props

  const style: IToastStyles = React.useMemo(() => ToastTypeStyles[type], [type])

  return (
    <EuiToast {...style} {...rest}>
      {children}
    </EuiToast>
  )
}

export default React.memo(Toast)
