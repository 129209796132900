import { UseQueryOptions } from '@tanstack/react-query'
import API from 'api/clients'
import { ClassConstructor, plainToInstance } from 'class-transformer'
import QueryKey from '../../query/query'
import useApi from '../useApi/use-api'

export type Sort<V extends string> = { [name: string]: V }

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface IPaginationParams<V extends string> {
  page: number
  pageSize: number
  sortBy: V
  sort: SortDirection
  filter?: string
}

interface IPaginationResponse<T> {
  Elements: T[]
  TotalResults: number
}

export function usePaginatedApi<V extends string, P extends IPaginationParams<V>, T>(
  queryKey: QueryKey,
  dto: ClassConstructor<T>,
  url: string,
  pageParams: P,
  queryParams?: UseQueryOptions<IPaginationResponse<T> | undefined>,
) {
  const { page, pageSize, sortBy, sort, filter, ...otherParams } = pageParams

  const { data, ...rest } = useApi<IPaginationResponse<T> | undefined>(
    [queryKey, ...Object.values(pageParams)],
    async () => {
      if (queryParams?.enabled!!) {
        const response = await API.get<IPaginationResponse<T>>(url, {
          params: {
            page,
            page_size: pageSize,
            sort_by: sortBy,
            sort,
            filter: filter?.trim(),
            ...otherParams,
          },
        })
        return response.data
      } else {
        return undefined
      }
    },
    {
      keepPreviousData: true,
      select: (data) => {
        return {
          Elements: data?.Elements ? plainToInstance(dto, data.Elements) : ([] as T[]),
          TotalResults: data?.TotalResults || 0,
        }
      },
      ...queryParams,
    },
  )

  return { data: data?.Elements || [], totalItems: data?.TotalResults || 0, ...rest }
}

export default usePaginatedApi
