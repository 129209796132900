import { Plan } from '../billing'
import { PlanType } from './dto'

export const PLAN_MAPPING = {
  [PlanType.FREE]: Plan.FREE,
  [PlanType.TRIAL]: Plan.PRO,
  [PlanType.PRO]: Plan.PRO,
  [PlanType.PRO_MULTISITE]: Plan.PRO_MULTI_SITE,
  [PlanType.ENTERPRISE]: Plan.ENTERPRISE,
}

export const PLAN_TYPE_MAPPING = {
  [Plan.FREE]: PlanType.FREE,
  [Plan.PRO]: PlanType.PRO,
  [Plan.PRO_MULTI_SITE]: PlanType.PRO,
  [Plan.ENTERPRISE]: PlanType.ENTERPRISE,
}
