import * as React from 'react'

import StyledView, { StyledViewProps } from '../StyledView'

export const GridRow: React.FunctionComponent<StyledViewProps> = ({ children, ...rest }) => (
  <StyledView flexDirection="row" display="flex" {...rest}>
    {children}
  </StyledView>
)

GridRow.displayName = 'Grid.Row'

export default GridRow
