import { borderSizes } from '../styles/borders'
import { Color } from '../styles/color'
import { radiiSizes } from '../styles/radii'
import shadows from '../styles/shadows'
import { spacingSizes } from '../styles/spacing'
import { fontSizes, fontWeights, letterSpacings, lineHeights } from '../styles/typography'
import { IDeepfinityTheme } from './model'

export const deepfinityLight: IDeepfinityTheme = {
  colors: {
    none: Color.Transparent,
    black: Color.Black,
    white: Color.White,
    primaryForeground: Color.PrimaryForeground,
    primaryBackground: Color.PrimaryBackground,
    primaryBackgroundMid: Color.PrimaryBackgroundMid,
    primaryBackgroundDark: Color.PrimaryBackgroundDark,
    primaryLight: Color.PrimaryLight,
    primaryMid: Color.PrimaryMid,
    primaryDark: Color.PrimaryDark,
    secondaryLight: Color.SecondaryLight,
    secondaryMid: Color.SecondaryMid,
    secondaryMidDark: Color.SecondaryMidDark,
    secondaryDark: Color.SecondaryDark,
    accentLight: Color.AccentLight,
    accentMid: Color.AccentMid,
    textLight: Color.TextLight,
    textLightMid: Color.TextLightMid,
    textMid: Color.TextMid,
    textMidDark: Color.TextMidDark,
    textDark: Color.TextDark,
    textDestructive: Color.TextDestructive,
    textPurple: Color.TextPurple,
    featureAlt: Color.ButterSquashOrange,
    featureMain: Color.WatermelonRed,
    infoLight: Color.InfoLight,
    infoMid: Color.InfoMid,
    infoDark: Color.InfoDark,
    successLight: Color.SuccessLight,
    successMidLight: Color.SuccessMidLight,
    successMid: Color.SuccessMid,
    successDark: Color.SuccessDark,
    warningLight: Color.WarningLight,
    warningMid: Color.WarningMid,
    warningDark: Color.WarningDark,
    warningDestructive: Color.WarningDestructive,
    dangerLight: Color.DangerLight,
    dangerMid: Color.DangerMid,
    dangerDark: Color.DangerDark,
    dangerDestructive: Color.DangerDestructive,
    gridLine: Color.GridLine,
    borderLight: Color.BorderLight,
  },
  fontSizes,
  fontWeights,
  letterSpacings,
  space: spacingSizes,
  borderWidths: borderSizes,
  radii: radiiSizes,
  lineHeights,
  shadows,
  /* 
  NOTE: While providing styles:
  0th index means *below* the smallest breakpoint
  All indexes afterwards mean next breakpoint and up
  */
  breakpoints: ['500px', '1224px', '1920px'],
}

export default deepfinityLight
