import styled from '@emotion/styled'
import { Grid, StyledViewProps } from 'modules/web-atoms'
import React from 'react'
import * as system from 'styled-system'

export type ITimelineProps = StyledViewProps & React.ComponentProps<typeof Grid>

export const StyledTimeline = styled(Grid)<ITimelineProps>(
  system.color,
  system.flexbox,
  system.space,
  system.layout,
  system.position,
  system.justifyContent,
  system.alignItems,
  system.borders,
  system.shadow,
  system.background,
)

export const Timeline: React.FC<ITimelineProps> = (props) => {
  const { children, ...rest } = props
  const items = React.Children.toArray(children).filter<React.ReactElement>(React.isValidElement)

  return (
    <StyledTimeline width="100%" alignItems="flex-start" gutterSize="medium" {...rest}>
      {items.map((item, idx) => (
        <Grid.Row width="100%" key={idx}>
          {item}
        </Grid.Row>
      ))}
    </StyledTimeline>
  )
}
