import { createAction } from '@reduxjs/toolkit'
import prefix from 'modules/utils/prefix'
import { IUpdateRecipientProfilePayload, IChangePasswordPayload, IDeleteDataPayload } from './models'

export const namespaced = prefix('profile')

export const SET_LOADING = namespaced('setLoading')
export const setLoading = createAction<boolean>(SET_LOADING)

export const UPDATE_RECIPIENT_PROFILE = namespaced('updateRecipientProfile')
export const updateRecipientProfile = createAction<IUpdateRecipientProfilePayload>(UPDATE_RECIPIENT_PROFILE)

export const CHANGE_PASSWORD = namespaced('changePassword')
export const changePassword = createAction<IChangePasswordPayload>(CHANGE_PASSWORD)

export const DELETE_DATA = namespaced('deleteData')
export const deleteData = createAction<IDeleteDataPayload>(DELETE_DATA)
