import * as React from 'react'
import { useDispatch } from 'react-redux'

import { EuiButton, EuiButtonEmpty } from '@elastic/eui'

import { Welcome } from 'modules/dna'
import authentication from 'modules/module-onboarding/authentication'
import { Grid, H1, H2, Image, Page, useTranslate } from 'modules/web-atoms'

import { OnboardingStage } from 'modules/module-security'
import board from '../../../board'

export interface IWelcomeScreenStateProps {}

export interface IWelcomeScreenDispatchProps {}

export type IWelcomeScreenProps = IWelcomeScreenDispatchProps & IWelcomeScreenStateProps

export const WelcomeScreen: React.FC<IWelcomeScreenProps> = (props) => {
  const t = useTranslate('dashboard.get-started.welcome-step')
  const tCommon = useTranslate('commons')

  const dispatch = useDispatch()

  const handleNext = React.useCallback(() => {
    dispatch(board.actions.navigateOnboarding('setup'))
  }, [dispatch])

  const handleSkip = React.useCallback(() => {
    dispatch(authentication.actions.saveOnboardingStage({ onboardingStage: OnboardingStage.ONBOARDING_SKIPPED }))
  }, [dispatch])

  return (
    <Page title={t('title')}>
      <Grid flex={1} alignContent="space-between" justifyContent="space-between" {...props}>
        <Grid flex={1} justifyContent="center" alignItems="center" marginX={['small', 'medium', 'large']}>
          <H1 textAlign="center">{t('heading')}</H1>
          <H2 textAlign="center" marginTop="smallest">
            {t('subheading')}
          </H2>
          <Image marginTop="xlarge" maxWidth={[300, 400]} src={Welcome} />
        </Grid>
        <Grid marginTop="large" justifyContent="flex-end" alignItems="center">
          <EuiButton fill onClick={handleNext}>
            {t('get-started')}
          </EuiButton>
          <EuiButtonEmpty onClick={handleSkip}>{tCommon('action.skip')}</EuiButtonEmpty>
        </Grid>
      </Grid>
    </Page>
  )
}

export default React.memo(WelcomeScreen)
