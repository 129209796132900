import { createAction } from 'redux-actions'

import prefix from 'modules/utils/prefix'

import { IErrorMeta, IErrorOptions, IIdentifyPayload, LogLevel } from '../model'
import { identityFor } from 'modules/utils'

export const namespaced = prefix('errors')

export const ERROR = namespaced('error')
export const error = createAction<string, IErrorMeta<IErrorOptions>>(
  ERROR,
  identityFor<string>(),
  (_, options = {}) => ({
    errors: {
      ...{
        context: options.context,
        level: options.level ?? LogLevel.Error,
        tags: { isHandled: !!options.isHandled, ...options.tags },
      },
    },
  }),
)

export const WARN = namespaced('warn')
export const warn = createAction<string>(WARN)

export const LOG = namespaced('log')
export const log = createAction<string>(LOG)

export const IDENTIFY = namespaced('identify')
export const identify = createAction<IIdentifyPayload>(IDENTIFY)
