import { EuiCheckboxGroupOption } from '@elastic/eui'
import { useColor } from 'modules/dna'
import { SortDirection } from 'modules/module-api'
import { developments } from 'modules/module-developments'
import { FormState } from 'modules/module-developments/development'
import orchestration from 'modules/module-orchestration'
import { useActiveBuildings, useAppSelector, useBuildings } from 'modules/module-utils'
import { SortField as BuildingsSortField } from 'modules/module-utils/hooks/use-buildings/use-buildings'
import { Button, Grid, IGridProps, Icon, IconType, Paragraph, Span, useTranslate } from 'modules/web-atoms'
import { CheckboxSelector, MenuGroup } from 'modules/web-organisms'
import * as React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { PortalPath } from '../../routes/models'

export interface IBuildingSelectorProps extends IGridProps {
  isMinimized: boolean
}

export const BuildingSelector: React.FC<IBuildingSelectorProps> = (props) => {
  const { isMinimized, ...rest } = props

  const t = useTranslate('core.nav.menu.building-selector')
  const dispatch = useDispatch()
  const history = useHistory()

  const [search, setSearch] = React.useState('')
  const [selected, setSelected] = React.useState<string[]>([])

  const isLoading = useAppSelector(developments.selectors.loading)
  const { canAddBuildings } = useAppSelector(developments.selectors.permissions)

  const { activeBuildings, isFetching: isFetchActiveBuildings, error: errorActiveBuildings } = useActiveBuildings()
  const {
    buildings: allBuildings,
    isFetching: isFetchingAllBuildings,
    error: allBuildingsError,
  } = useBuildings({
    page: 1,
    pageSize: 1000,
    sortBy: BuildingsSortField.NAME,
    sort: SortDirection.ASC,
    filter: search,
  })

  const onChange = React.useCallback(
    (selection: string[]) => {
      dispatch(orchestration.actions.setActiveBuildings({ buildings: selection }))
    },
    [dispatch],
  )

  const onSearchChange = React.useCallback((e) => {
    setSearch(e.target.value)
  }, [])

  React.useEffect(() => {
    if (!isFetchActiveBuildings && activeBuildings) {
      setSelected(activeBuildings.map((building) => building.id.toString()))
    }
  }, [activeBuildings, isFetchActiveBuildings])

  const options: EuiCheckboxGroupOption[] = React.useMemo(() => {
    return allBuildings.map((building) => {
      const id = building.id.toString()
      const isSelected = selected.includes(id)
      return {
        id: id,
        labelProps: {
          style: {
            marginTop: -5,
          },
        },
        label: (
          <Paragraph color={isSelected ? 'primaryMid' : 'textLightMid'} textAlign="center" truncated={true}>
            {building.buildingName}
          </Paragraph>
        ),
        compressed: false,
      }
    })
  }, [allBuildings, selected])

  const onAddBuilding = React.useCallback(() => {
    dispatch(developments.actions.setFormState(FormState.CREATING))
    history.push(PortalPath.BUILDINGS_MANAGEMENT)
  }, [dispatch, history])

  const iconColor = useColor('primaryMid')

  const title = React.useMemo(() => {
    if (selected.length === 1) {
      return (
        allBuildings.find((building) => building.id.toString() === selected[0])?.buildingName ?? t('selected-buildings')
      )
    } else {
      return t('multiple-buildings-selected')
    }
  }, [selected, allBuildings, t])

  return (
    <MenuGroup
      title={title}
      popoverBackgroundColor="primaryBackground"
      borderColor="primaryMid"
      borderStyle="solid"
      borderWidth="small"
      borderRadius="small"
      contentColor="primaryMid"
      isMinimized={isMinimized}
      icon={IconType.MenuBuildings}
      popoverWidth={260}
      popoverOverride
      {...rest}
    >
      <Grid
        marginTop="smallest"
        borderRadius="medium"
        justifyContent="center"
        alignItems="center"
        backgroundColor="primaryBackground"
      >
        <CheckboxSelector
          width={240}
          selected={selected}
          searchProps={{
            placeholder: t('search'),
            value: search,
            onChange: onSearchChange,
            isLoading: isFetchActiveBuildings || isFetchingAllBuildings,
          }}
          checkboxProps={{
            options: options,
          }}
          onChange={onChange}
          disabled={isLoading}
        />
        {canAddBuildings && (
          <Button.Frame tabIndex={0} label={t('add')} onClick={onAddBuilding}>
            <Grid justifyContent="center" alignItems="center" padding="macro">
              <Grid.Col>
                <Icon.SelectedBuildingsAdd fill={iconColor} height={15} width={15} />
              </Grid.Col>
              <Grid.Col>
                <Span color="primaryMid">{t('add')}</Span>
              </Grid.Col>
            </Grid>
          </Button.Frame>
        )}
      </Grid>
    </MenuGroup>
  )
}

export default React.memo(BuildingSelector)
