/* eslint-disable react/display-name */
import { EuiButton } from '@elastic/eui'
import security from 'modules/module-security'
import { useAppDispatch, useAppSelector } from 'modules/module-utils/hooks'
import { Grid, Paragraph, useToggle, useTranslate } from 'modules/web-atoms'
import { DeleteConfirmationModal, Table } from 'modules/web-molecules'
import { ICreateOptions, IDeleteOptions, ISearchOptions, TablePanelTemplate } from 'modules/web-templates'
import * as React from 'react'
import development, { FormState } from '../../../development'
import useBuildingsTable from '../../../hooks/use-buildings-table/use-buildings-table'
import permissions from '../../../permissions/Permissions'

export interface IBuildingsTablePageProps {}

export const TABLE_ID = 'table-buildings'
const INTL_PREFIX_MODAL = 'buildings-directory.delete-modal'

export const BuildingsTable: React.FC<IBuildingsTablePageProps> = (props) => {
  const { ...rest } = props

  const t = useTranslate('buildings-directory.table')
  const dispatch = useAppDispatch()

  const selectedBuildingsSize = useAppSelector(development.selectors.selectedBuildingsSize)
  const formState = useAppSelector(development.selectors.formState)
  const isDeleting = useAppSelector(development.selectors.isDeletingBuildings)
  const [isDeletionConfirmationOpen, toggleDeletionConfirmation] = useToggle()

  const {
    columns,
    items,
    selection,
    onItemClicked,
    onSearch,
    isFetching,
    sort,
    error,
    pagination,
    paginationOptions,
    totalBuildings,
    selectedItems,
    onRefresh,
  } = useBuildingsTable()

  const { canRemoveBuildings, canAddBuildings } = useAppSelector(development.selectors.permissions)
  const userType = useAppSelector(security.selectors.type)

  const onAddBuilding = React.useCallback(() => {
    dispatch(development.actions.createBuilding())
  }, [dispatch])

  const onDelete = React.useCallback(() => {
    toggleDeletionConfirmation()
  }, [toggleDeletionConfirmation])

  const onDeleteConfirm = React.useCallback(() => {
    dispatch(development.actions.deleteBuildings())
    toggleDeletionConfirmation()
  }, [dispatch, toggleDeletionConfirmation])

  const onActiveBuilding = React.useCallback(() => {
    dispatch(development.actions.setSelectedActiveBuildings())
  }, [dispatch])

  const createOptions: ICreateOptions = React.useMemo(() => {
    return {
      visible: !!canAddBuildings && formState !== FormState.CREATING,
      onClick: onAddBuilding,
      value: t('add-building'),
    }
  }, [onAddBuilding, canAddBuildings, formState, t])

  const searchOptions: ISearchOptions = React.useMemo(() => {
    return {
      hint: t('search.placeholder'),
      onSearch: onSearch,
    }
  }, [onSearch, t])

  const deleteOptions: IDeleteOptions = React.useMemo(() => {
    return {
      visible: selectedBuildingsSize > 0 && canRemoveBuildings,
      isLoading: isDeleting,
      onClick: onDelete,
      value: `${t('delete')} ${selectedItems.length || 1}`,
    }
  }, [canRemoveBuildings, isDeleting, selectedBuildingsSize, onDelete, selectedItems.length, t])

  return (
    <TablePanelTemplate
      heading={t('heading')}
      subheading={t('subheading', {
        displayed: Math.min(pagination.pageSize, pagination.total),
        total: pagination.total,
      })}
      createOptions={createOptions}
      searchOptions={searchOptions}
      deleteOptions={deleteOptions}
      isDeleteModalOpen={isDeletionConfirmationOpen}
      restrictionLabel={
        !!userType && permissions.canCreateBuilding.includes(userType) && canAddBuildings === false
          ? t('upgrade-plan-label')
          : undefined
      }
    >
      <TablePanelTemplate.Table>
        <Table
          tableId={TABLE_ID}
          columns={columns}
          items={items}
          selection={selection}
          sorting={sort}
          pagination={pagination}
          paginationOptions={paginationOptions}
          loading={isFetching}
          isCheckable={true}
          onItemClicked={onItemClicked}
          selectedItems={selectedItems}
          onRefresh={onRefresh}
        />
      </TablePanelTemplate.Table>

      <TablePanelTemplate.Options>
        {selectedBuildingsSize > 0 && (
          <Grid display={undefined} alignSelf="flex-end">
            <EuiButton isLoading={isDeleting} onClick={onActiveBuilding}>
              <Paragraph whiteSpace="nowrap" color="primaryMid">
                {t('active')} ({selectedItems.length || 1})
              </Paragraph>
            </EuiButton>
          </Grid>
        )}
      </TablePanelTemplate.Options>

      <TablePanelTemplate.DeleteModal>
        <DeleteConfirmationModal
          onClose={toggleDeletionConfirmation}
          onDelete={onDeleteConfirm}
          itemSize={selectedBuildingsSize}
          intlPrefix={INTL_PREFIX_MODAL}
        />
      </TablePanelTemplate.DeleteModal>
    </TablePanelTemplate>
  )
}

export default React.memo(BuildingsTable)
