export interface ObjectMap<T> {
  [key: string]: T
}

export enum Browser {
  Chrome = 'CHROME',
  Firefox = 'FIREFOX',
  Opera = 'OPERA',
  Safari = 'SAFARI',
  Edge = 'EDGE',
  Other = 'OTHER',
}

export enum OperatingSystem {
  Android = 'ANDROID',
  iOS = 'IOS', // tslint:disable-line:enum-naming
  Mac = 'MAC',
  Windows = 'WINDOWS',
  WindowsPhone = 'WINDOWS_PHONE',
  Linux = 'LINUX',
  Other = 'OTHER',
}
