import { UserType } from 'api'
import { ITime } from 'modules/module-utils/dateUtils'
import { TemplateType } from '../api/dto'

export enum NotificationError {
  Invalid = 'invalid',
  Unknown = 'unknown',
}

export enum TemplateListModalType {
  CONFIGURATION = 'CONFIGURATION',
  DELIVERY = 'DELIVERY',
  COLLECTION = 'COLLECTION',
  SIGNUP = 'SIGNUP',
  REMINDER = 'REMINDER',
}

export interface IPermissions {
  canViewNotifications: boolean
  canEditNotifications: boolean
}

export interface INotificationState {
  error?: NotificationError
  loading: boolean
  actionLoading: boolean
  isAssigning: boolean
  isRestoreDefaultLoading: boolean
  currentBuildingIds: number[]
  permissions: IPermissions
  openedListModalId?: string
}

export const initialState: INotificationState = {
  error: undefined,
  loading: false,
  isAssigning: false,
  actionLoading: false,
  isRestoreDefaultLoading: false,
  currentBuildingIds: [],
  permissions: {
    canViewNotifications: false,
    canEditNotifications: false,
  },
}

export interface ISchedulerTimeRange {
  from: ITime
  to: ITime
  enabled: boolean
}

export enum Locale {
  UNITED_KINGDOM = 'en-GB',
  UNITED_STATES = 'en-US',
  SPANISH = 'es-ES',
  ITALIAN = 'it-IT',
}
export enum NotificationScheduleType {
  DEFAULT = 'DEFAULT',
  CUSTOM = 'CUSTOM',
}
export interface IUpdateConfiguration {
  id: string
  templateName: string
  defaultTemplateName: string
  backgroundColor: string
  secondaryColor: string
  logoSize: number
  logoFile?: File
  logoUpdated?: boolean
  locale: Locale
  schedules: {
    default: {
      everyday: ISchedulerTimeRange
    }
    custom: {
      monday: ISchedulerTimeRange
      tuesday: ISchedulerTimeRange
      wednesday: ISchedulerTimeRange
      thursday: ISchedulerTimeRange
      friday: ISchedulerTimeRange
      saturday: ISchedulerTimeRange
      sunday: ISchedulerTimeRange
    }
  }
  schedulerType: NotificationScheduleType
  timezone: string

  emailNotifications: boolean
  smsNotifications: boolean
  schedulerEnabled: boolean
}

export interface ITemplateAssignAll {
  templateId: string
  templateType: TemplateType
}

export interface ICreateTemplate {
  siteId: number
  templateType: TemplateType
}

export interface IResetTemplate {
  siteId: number
  templateId: string
}

export interface IDuplicateTemplate extends IResetTemplate {}

export interface IConfigurationAssignAll {
  configurationId: string
}

export interface IAssignConfiguration {
  siteId: number
  configurationId: string
}

export interface IAssignTemplate {
  siteId: number
  assignedTemplateIds: string[]
  templateIds: string[]
  templateType: TemplateType
}

export interface IRemoveTemplate {
  siteId: number
  assignedTemplateIds: string[]
  templateIdToRemove: string
  templateType: TemplateType
}

export interface ISetTemplateAsDefaultPayload {
  templateId: string
  siteId: number
  templateType: TemplateType
}

export interface ISetPermissionsPayload {
  userType: UserType
}
