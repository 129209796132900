/* eslint-disable react/no-string-refs */
import { processRoute } from 'modules/module-navigation'
import { Grid } from 'modules/web-atoms'
import React from 'react'
import { RouteComponentProps, Switch } from 'react-router-dom'
import useRoutes from '../../routes/use-routes/use-routes'

export interface IOpenContainerProps extends RouteComponentProps {}

export const OpenContainer: React.FC<IOpenContainerProps> = (props) => {
  const { ...rest } = props

  const { unauthenticatedRoutes } = useRoutes()

  return (
    <Grid className="main-content" backgroundColor="primaryBackgroundMid" gutterSize="none">
      <Switch>{processRoute(unauthenticatedRoutes)}</Switch>
    </Grid>
  )
}

export default React.memo(OpenContainer)
