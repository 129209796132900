import * as React from 'react'

import { SemanticColor, ThemeColor } from 'modules/dna'

import { useTheme } from '@emotion/react'
import { useColorBrightness } from '../../../module-utils'
import Grid from '../Grid/Grid'
import Icon, { IconType } from '../Icon/Icon'
import { Link } from '../Link/Link'
import Button from '../buttons/Button/Button'
import { H6 } from '../typography/Heading/Heading'

export enum BannerType {
  SUCCESS,
  WARNING,
  DANGER,
}

export interface IBannerTypeStyle {
  color: ThemeColor
  iconType: IconType
}

export const bannerTypeStyles: Record<BannerType, IBannerTypeStyle> = {
  [BannerType.SUCCESS]: { color: 'successMidLight', iconType: IconType.BannerSuccess },
  [BannerType.WARNING]: { color: 'warningMid', iconType: IconType.BannerDanger },
  [BannerType.DANGER]: { color: 'dangerDestructive', iconType: IconType.BannerDanger },
}

export interface IBannerProps extends React.ComponentProps<typeof Grid> {
  type: BannerType
  message: string
  actionMessage: string
  closeLabel?: string
  onActionClick: () => void
  onDismiss?: () => void
  isDismissible?: boolean
}

export const Banner: React.FC<IBannerProps> = (props) => {
  const {
    type,
    message,
    actionMessage,
    closeLabel,
    onActionClick,
    onDismiss,
    children,
    isDismissible: isDismissible = true,
    ...rest
  } = props

  const typeProps = bannerTypeStyles[type]
  const theme = useTheme()
  const brightness = useColorBrightness(theme.colors[typeProps.color])
  const contentColor: SemanticColor = brightness === 'bright' ? 'black' : 'white'
  const Image = React.useMemo(() => Icon[typeProps.iconType], [typeProps.iconType])

  return (
    <Grid
      className="pt__notificationBanner"
      borderBottomLeftRadius="small"
      borderBottomRightRadius="small"
      backgroundColor={typeProps.color}
      justifyContent="space-between"
      alignItems="center"
      paddingY="macro"
      paddingLeft="small"
      paddingRight="small"
      width="100%"
      {...rest}
    >
      <Grid.Col>
        <Grid alignItems="center">
          <Grid.Col>
            <Image fill={contentColor} />
          </Grid.Col>
          <Grid.Col paddingLeft="smallest">
            <H6 color={contentColor} fontWeight="bold">
              {message}
            </H6>
          </Grid.Col>
        </Grid>
      </Grid.Col>
      <Grid.Col>
        <Grid alignItems="center">
          <Grid.Col paddingRight="smallest">
            <Link
              style={{ backgroundColor: 'transparent' }}
              onClick={onActionClick}
              color={brightness == 'bright' ? 'text' : 'ghost'}
            >
              <H6 color={contentColor}>{actionMessage}</H6>
            </Link>
          </Grid.Col>
          {isDismissible && (
            <Grid.Col>
              <Button.Frame onClick={onDismiss}>
                <Icon.Cross fill={contentColor} aria-label={closeLabel} />
              </Button.Frame>
            </Grid.Col>
          )}
        </Grid>
      </Grid.Col>
    </Grid>
  )
}

export default React.memo(Banner)
