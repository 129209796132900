import { EuiBasicTable, EuiBasicTableColumn, EuiButton, EuiButtonEmpty, EuiSwitchEvent } from '@elastic/eui'
import theme from 'modules/dna'
import { useAppDispatch, useAppSelector } from 'modules/module-utils'
import { FlexItem, Grid, H1, H4, H5, Paragraph, Span, Switch, useTranslate } from 'modules/web-atoms'
import { TableLabel } from 'modules/web-molecules'
import * as React from 'react'
import upload from '../../../upload'

export enum UploadPreviewColumn {
  NAME = 'name',
  CURRENT = 'current',
  ADDITIONS = 'additions',
  DELETIONS = 'deletions',
  FINAL = 'final',
}

export interface IUploadPreviewDetailedItem {
  primary: number
  additional: number
}
export interface IUploadPreviewItem {
  [UploadPreviewColumn.NAME]: string
  [UploadPreviewColumn.CURRENT]: IUploadPreviewDetailedItem
  [UploadPreviewColumn.ADDITIONS]: IUploadPreviewDetailedItem
  [UploadPreviewColumn.DELETIONS]: IUploadPreviewDetailedItem
  [UploadPreviewColumn.FINAL]: IUploadPreviewDetailedItem
}

export interface IUploadPreviewProps extends React.ComponentProps<typeof Grid> {}

export const UploadPreview: React.FC<IUploadPreviewProps> = (props) => {
  const { ...rest } = props

  const t = useTranslate('recipients-upload.upload.preview')
  const tCommon = useTranslate('commons')

  const dispatch = useAppDispatch()

  const loading = useAppSelector(upload.selectors.loading)
  const isWipeAvailable = useAppSelector(upload.selectors.isWipeAvailable)
  const isWipeEnabled = useAppSelector(upload.selectors.wipeEnable)
  const preview = useAppSelector(upload.selectors.uploadPreview)

  const onBack = () => {
    dispatch(upload.actions.navigateRecipientUpload('previous'))
  }

  const onUpload = () => {
    dispatch(upload.actions.uploadRecipients())
  }

  const onWipeChange = (event: EuiSwitchEvent) => {
    const { checked } = event.target
    dispatch(upload.actions.generateUploadPreview(checked))
  }

  const items: IUploadPreviewItem[] = React.useMemo(() => {
    return preview.map((previewItem) => {
      return {
        name: previewItem.siteName,
        current: { primary: previewItem.current, additional: previewItem.currentAdditional },
        additions: { primary: previewItem.additions, additional: previewItem.additionsAdditional },
        deletions: { primary: previewItem.deletions, additional: previewItem.deletionsAdditional },
        final: { primary: previewItem.final, additional: previewItem.finalAdditional },
      }
    })
  }, [preview])

  const columns = React.useMemo((): Array<EuiBasicTableColumn<IUploadPreviewItem>> => {
    return Object.values(UploadPreviewColumn).map((column) => {
      switch (column) {
        case UploadPreviewColumn.CURRENT:
        case UploadPreviewColumn.ADDITIONS:
        case UploadPreviewColumn.DELETIONS:
        case UploadPreviewColumn.FINAL:
          return {
            field: column,
            name: (
              <Paragraph size="medium" fontWeight="bold" paddingBottom="macro" title={t(`column.${column}`)}>
                {t(`column.${column}`)}
              </Paragraph>
            ),
            render: (value) => {
              return (
                <>
                  {!value.additional ? (
                    <Paragraph color="textDark" title={value.primary}>
                      {value.primary}
                    </Paragraph>
                  ) : (
                    <Paragraph color="textDark" title={`${value.primary} +${value.additional}`}>
                      {value.primary} <Span color="primaryMid">({value.additional})</Span>
                    </Paragraph>
                  )}
                </>
              )
            },
          }
        default:
          return {
            field: column,
            name: (
              <Paragraph size="medium" fontWeight="bold" paddingBottom="macro" title={t(`column.${column}`)}>
                {t(`column.${column}`)}
              </Paragraph>
            ),
            render: (value) => {
              return (
                <Paragraph fontWeight="bold" color="textDark" truncated={true} title={value}>
                  {value}
                </Paragraph>
              )
            },
          }
      }
    })
  }, [t])

  const getCellProps = (item, column) => {
    return {
      style: {
        padding: theme.spacing.small,
      },
    }
  }

  return (
    <FlexItem grow={true} alignItems="center" flex={1}>
      <Grid justifyContent="space-between" flex={1}>
        <Grid alignItems="center" paddingX="large" marginTop="large" marginBottom="medium" flex={1}>
          <H1 textAlign="center">{t('heading')}</H1>
          <H4 marginTop="smallest" color="primaryMid" textAlign="center">
            {t('subheading')}
          </H4>
          <Grid maxWidth={1100}>
            <Grid marginTop="xlarge" marginX="smallest">
              <TableLabel labelColour="textDark" labelText={t('table-label.total')} fontColor="textDark" />
              <TableLabel labelColour="primaryMid" labelText={t('table-label.additional')} fontColor="textDark" />

              <Grid className="eui-yScroll" maxHeight={600} marginTop="small">
                <EuiBasicTable<IUploadPreviewItem>
                  items={items}
                  className="module-dashboard-map-file-step-table"
                  rowHeader="firstName"
                  columns={columns}
                  style={{ padding: theme.spacing.smallest }}
                  cellProps={getCellProps}
                />
              </Grid>
            </Grid>
            {isWipeAvailable && (
              <Grid marginTop="xlarge">
                <Grid.Row>
                  <H5 fontWeight="bold">{t('wipe.heading')}</H5>
                </Grid.Row>
                <Grid.Row>
                  <Paragraph>{t('wipe.subheading')}</Paragraph>
                </Grid.Row>
                <Grid.Row marginTop="smallest">
                  <Switch label={t('wipe.switch')} checked={isWipeEnabled} onChange={onWipeChange} disabled={loading} />
                </Grid.Row>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid justifyContent="center" alignItems="center">
          <Grid.Col>
            <EuiButtonEmpty onClick={onBack}>{tCommon('action.back')}</EuiButtonEmpty>
          </Grid.Col>
          <Grid.Col marginLeft="smallest">
            <EuiButton fill onClick={onUpload} style={{ marginLeft: theme.spacing.large }} isLoading={loading}>
              {t('upload')}
            </EuiButton>
          </Grid.Col>
        </Grid>
      </Grid>
    </FlexItem>
  )
}

export default React.memo(UploadPreview)
