import { IDevelopmentDto, TenantType, UserType } from 'api'
import { RecipientSortField } from '../hooks/use-recipients/use-recipients'

import { TableSelectionAction } from 'modules/web-molecules'
import { IFormValues } from '../screens/RecipientsDirectory/RecipientsPanel/RecipientForm/RecipientForm'

export enum CreateRecipientStep {
  RECIPIENT_TYPE = 'recipient-type',
  RECIPIENT_DETAILS = 'recipient-details',
}

export enum FormState {
  HIDDEN = 'hidden',
  CREATING = 'creating',
  EDITING = 'editing',
}

export interface IPermissions {
  canCreateRecipient: boolean
  canEditRecipient: boolean
  canViewRecipient: boolean
}
export interface IRecipientsState {
  // will be an id
  selectedRecipient?: string
  isCreateForm: boolean
  // Array of ids
  selectedItems: string[]
  createRecipientStep: CreateRecipientStep
  createRecipientType?: TenantType
  activeBuildings: IDevelopmentDto[]
  error?: string
  loading: boolean
  isDeleting: boolean
  permissions: IPermissions
  formState: FormState
  persistent: {
    visibleColumns: string[]
  }
}

export const initialState: IRecipientsState = {
  selectedRecipient: undefined,
  isCreateForm: true,
  selectedItems: [],
  createRecipientStep: CreateRecipientStep.RECIPIENT_TYPE,
  createRecipientType: undefined,
  activeBuildings: [],
  error: undefined,
  loading: false,
  isDeleting: false,
  formState: FormState.HIDDEN,
  permissions: {
    canCreateRecipient: false,
    canEditRecipient: false,
    canViewRecipient: false,
  },
  persistent: {
    visibleColumns: [
      RecipientSortField.NAME,
      RecipientSortField.EMAIL,
      RecipientSortField.LOCATION,
      RecipientSortField.BUILDING,
    ],
  },
}

export type ISetCreateStepPayload = CreateRecipientStep

export type IAddRecipientPayload = IFormValues
export interface IUpdateRecipientPayload extends IFormValues {
  id: string
}

export type ISetActiveBuildingsPayload = IDevelopmentDto[]

export interface ISetSelectedItemsPayload {
  items: string[]
  action: TableSelectionAction
}

export interface ISetPermissionsPayload {
  userType: UserType
  canManageRecipients?: boolean
}
