import { IDeepMessageValues } from 'modules/module-intl'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

export const useTranslate = (prefix = '', namespace?: string) => {
  const { t } = useTranslation(namespace)

  const translate = React.useCallback(
    (suffix: string, values: IDeepMessageValues = {}) => {
      return t(`${prefix}.${suffix}`, values)
    },
    [prefix, t],
  )

  return translate
}

export default useTranslate
