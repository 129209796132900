import * as React from 'react'
import Portal from '../../../components/Portal/Portal'
import { Range } from 'slate'
import { ReactEditor } from 'slate-react'
import { Button, Paragraph } from 'modules/web-atoms'
import { IDeepfinityTheme, ThemeColor } from 'modules/dna'
import { ResponsiveValue } from 'styled-system'

export interface ITagOption<T = string> {
  value: T
  label: string
  backgroundColor?: ResponsiveValue<ThemeColor, IDeepfinityTheme>
  textColor?: ResponsiveValue<ThemeColor, IDeepfinityTheme>
}

export interface ITagPortalProps {
  target: Range | undefined
  chars: string[] | undefined
  index: number
  editor: any
  onClick: (char: string) => void
}

export const TagPortal: React.FC<ITagPortalProps> = ({ target, chars, index, editor, onClick, ...rest }) => {
  const ref = React.useRef<HTMLDivElement | undefined>()

  React.useEffect(() => {
    if (target && chars && chars.length > 0) {
      const el = ref.current
      if (!!el) {
        const domRange = ReactEditor.toDOMRange(editor, target)
        const rect = domRange.getBoundingClientRect()
        el.style.top = `${rect.top + window.pageYOffset + 24}px`
        el.style.left = `${rect.left + window.pageXOffset}px`
      }
    }
  }, [chars, editor, index, target])

  return (
    <Portal {...rest}>
      {target && chars && chars.length > 0 && (
        <div
          ref={ref as any}
          style={{
            top: '-9999px',
            left: '-9999px',
            position: 'absolute',
            zIndex: 1,
            padding: '8px',
            background: 'white',
            borderRadius: '4px',
            boxShadow: '0 1px 5px rgba(0,0,0,.2)',
          }}
        >
          {chars.map((char, i) => (
            <Button.Text
              key={char}
              backgroundColor={i === index ? 'infoLight' : 'none'}
              activeBackgroundColor="infoLight"
              padding="macro"
              borderRadius="small"
              onClick={() => onClick(char)}
            >
              <Paragraph>{char}</Paragraph>
            </Button.Text>
          ))}
        </div>
      )}
    </Portal>
  )
}

export default React.memo(TagPortal)
