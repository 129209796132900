import Bowser from 'bowser'
import { OperatingSystem } from 'modules/ts/types'

const browser = Bowser.getParser(window.navigator.userAgent)

export const envUtil = {
  getOperatingSystem(): OperatingSystem {
    if (browser.getOS().name == 'android') {
      return OperatingSystem.Android
    } else if (browser.getOS().name == 'ios') {
      return OperatingSystem.iOS
    } else if (browser.getOS().name == 'mac') {
      return OperatingSystem.Mac
    } else if (browser.getOS().name == 'windows') {
      return OperatingSystem.Windows
    } else if (browser.getOS().name == 'windowsphonee') {
      return OperatingSystem.WindowsPhone
    } else if (browser.getOS().name == 'linux') {
      return OperatingSystem.Linux
    } else {
      return OperatingSystem.Other
    }
  },
}

export default envUtil
