import { createSelector, Selector } from 'reselect'
import { BRANCH_KEY } from '../branch'
import { ISettingsState } from '../model'
import { ISettingState, SettingsFormState } from './models'

export const branch: Selector<ISettingsState, ISettingState> = (state) => state[BRANCH_KEY]?.setting

export const permissions = createSelector(branch, (branch) => branch?.permissions)

export const flagLoading = createSelector(branch, (branch) => branch.flagLoading)

export const showAddTagForm = createSelector(branch, (branch) => branch.showAddTagForm)
export const isAddingTag = createSelector(branch, (branch) => branch.isAddingTag)
export const isEditing = (id: number) => createSelector(branch, (branch) => branch.editingTagIds.includes(id))
export const editingTagIds = createSelector(branch, (branch) => branch.editingTagIds)
export const isUpdating = (id: number) => createSelector(branch, (branch) => branch.updatingTagIds.includes(id))
export const isDeleting = (id: number) => createSelector(branch, (branch) => branch.deletingTagIds.includes(id))

// Locations
export const selectedLocation = createSelector(branch, (branch) => branch.selectedLocation)
export const selectedLocations = createSelector(branch, (branch) => branch.selectedLocations)
export const selectedSite = createSelector(branch, (branch) => branch.selectedSite)
export const locationFormState = createSelector(branch, (branch) => branch.locationFormState)
export const isAddingLocation = createSelector(branch, (branch) => branch.isAddingLocation)
export const isEditingLocation = createSelector(branch, (branch) => branch.isEditingLocation)
export const isDeletingLocations = createSelector(branch, (branch) => branch.isDeletingLocations)
export const isLocationFormVisible = createSelector(
  locationFormState,
  (formState) => formState !== SettingsFormState.HIDDEN,
)

// Couriers
export const selectedCourier = createSelector(branch, (branch) => branch.selectedCourier)
export const selectedCouriers = createSelector(branch, (branch) => branch.selectedCouriers)
export const courierFormState = createSelector(branch, (branch) => branch.courierFormState)
export const isAddingCourier = createSelector(branch, (branch) => branch.isAddingCourier)
export const isEditingCourier = createSelector(branch, (branch) => branch.isEditingCourier)
export const isDeletingCouriers = createSelector(branch, (branch) => branch.isDeletingCouriers)
export const isCourierFormVisible = createSelector(
  courierFormState,
  (formState) => formState !== SettingsFormState.HIDDEN,
)

// Dropoff Locations
export const selectedDropoffLocation = createSelector(branch, (branch) => branch.selectedDropoffLocation)
export const selectedDropoffLocations = createSelector(branch, (branch) => branch.selectedDropoffLocations)
export const dropoffLocationFormState = createSelector(branch, (branch) => branch.dropoffLocationFormState)
export const isAddingDropoffLocation = createSelector(branch, (branch) => branch.isAddingDropoffLocation)
export const isEditingDropoffLocation = createSelector(branch, (branch) => branch.isEditingDropoffLocation)
export const isDeletingDropoffLocations = createSelector(branch, (branch) => branch.isDeletingDropoffLocation)
export const isDropoffLocationFormVisible = createSelector(
  dropoffLocationFormState,
  (formState) => formState !== SettingsFormState.HIDDEN,
)

// Size
export const selectedSize = createSelector(branch, (branch) => branch.selectedSize)
export const selectedSizes = createSelector(branch, (branch) => branch.selectedSizes)
export const sizeFormState = createSelector(branch, (branch) => branch.sizeFormState)
export const isAddingSize = createSelector(branch, (branch) => branch.isAddingSize)
export const isEditingSize = createSelector(branch, (branch) => branch.isEditingSize)
export const isDeletingSizes = createSelector(branch, (branch) => branch.isDeletingSizes)
export const isSizeFormVisible = createSelector(sizeFormState, (formState) => formState !== SettingsFormState.HIDDEN)
