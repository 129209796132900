// System
export const REACT_APP_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT as string
export const REACT_APP_GLOBAL_API = process.env.REACT_APP_GLOBAL_API as string
export const REACT_APP_API = process.env.REACT_APP_API as string
export const REACT_APP_AUTH_API = process.env.REACT_APP_AUTH_API as string
export const REACT_APP_AUTH_API_US = process.env.REACT_APP_AUTH_API_US as string
export const REACT_APP_AUTH_API_EU = process.env.REACT_APP_AUTH_API_EU as string
export const REACT_APP_PM_API = process.env.REACT_APP_PM_API as string
export const REACT_APP_VERSION = process.env.REACT_APP_VERSION as string
export const REACT_APP_IP_LOOKUP_URL = process.env.REACT_APP_IP_LOOKUP_URL as string
export const REACT_APP_AZURE_FUNCTIONS_API = process.env.REACT_APP_AZURE_FUNCTIONS_API as string

// Sentry
export const SENTRY_DNS = process.env.REACT_APP_SENTRY_DNS
export const SENTRY_ENVIRONMENT = REACT_APP_ENVIRONMENT

// Clarity
export const CLARITY_ANALYTICS_ID: string = process.env.ANALYTICS_CLARITY_ID as string

// Heap
export const HEAP_ENABLED = true
export const HEAP_ANALYTICS_ID: string = process.env.ANALYTICS_HEAP_ID as string

// Google tag manager
export const TAG_MANAGER_ID = 'GTM-NJP96PD'

// Zendesk
export const ZENDESK_KEY = process.env.REACT_APP_ZENDESK_KEY
