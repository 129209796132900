import { IDevelopmentDto } from 'api'
import API from 'api/clients'
import { plainToInstance } from 'class-transformer'
import { QueryKey } from 'modules/module-api'
import useApi from 'modules/module-api/hooks/useApi/use-api'
import security, { Role } from 'modules/module-security'
import React from 'react'
import { useAppSelector } from '../use-app-selector/use-app-selector'

export const useActiveBuildings = () => {
  const userRole = useAppSelector(security.selectors.role)

  const { data, isFetching, error } = useApi<IDevelopmentDto[]>(
    [QueryKey.ACTIVE_BUILDINGS],
    async () => {
      if (userRole === Role.USER) {
        const response = await API({
          url: '/v3/users/last-accessed-building/web',
        })
        return response.data
      }
      return []
    },
    { keepPreviousData: true },
  )

  return React.useMemo(() => {
    return { activeBuildings: data ? plainToInstance(IDevelopmentDto, data) : [], isFetching, error }
  }, [data, error, isFetching])
}
