import { createSelector, Selector } from 'reselect'
import { BRANCH_KEY } from '../branch'
import { AVAILABLE_PLANS } from '../constants'
import { IBillingOwnState } from '../model'
import { IPlanConfig } from '../services/models'
import { Currency, IBillingState, Plan } from './models'

const branch: Selector<IBillingOwnState, IBillingState> = (state) => state[BRANCH_KEY].billing

export const isLoading = createSelector(branch, (branch) => branch.loading)
export const isCheckoutLoading = createSelector(branch, (branch) => branch.checkoutLoading)

export const currentPlan = createSelector(branch, (branch) => branch.currentPlan)
export const recommendedPlan = createSelector(branch, (branch) => branch.recommendedPlan)
export const selectedPlan = createSelector(branch, (branch) => branch.selectedPlan)
export const isTrial = createSelector(branch, (branch) => branch.isTrial)
export const daysLeftInTrial = createSelector(branch, (branch) => branch.daysLeftInTrial)
export const daysLeftInGracePeriod = createSelector(branch, (branch) => branch.daysLeftInGracePeriod)
export const hasBeenPaidClient = createSelector(branch, (branch) => branch.hasBeenPaidClient)
export const paymentDetailsLoading = createSelector(branch, (branch) => branch.paymentDetailsLoading)
export const permissions = createSelector(branch, (branch) => branch.permissions)
export const manualOverride = createSelector(branch, (branch) => branch.manualOverride)
export const isSmsEnabled = createSelector(branch, (branch) => branch.isSmsEnabled)
export const currentCurrency = createSelector(branch, (branch) => branch.currentCurrency || Currency.USD)

export const isMultiSite = createSelector(branch, (branch) => {
  const currentPlan = branch.currentPlan
  const proMultiSiteTier = AVAILABLE_PLANS.find((plan) => plan.id === Plan.PRO_MULTI_SITE)?.tier as number

  if (!!currentPlan) {
    if (currentPlan.tier < proMultiSiteTier) {
      return false
    } else {
      return true
    }
  }

  return undefined
})

export const planData = createSelector(branch, (branch) => ({
  currentPlan: branch.currentPlan as IPlanConfig,
  recommendedPlan: branch.recommendedPlan as IPlanConfig,
  selectedPlan: AVAILABLE_PLANS.find((plan) => plan.id === branch.selectedPlan) as IPlanConfig,
  isLoading: !branch.currentPlan || !branch.recommendedPlan,
}))

export const planLimits = createSelector(branch, (branch) => ({
  limitInboundParcels: branch.limitInboundParcels && !branch.manualOverride,
  limitOutboundParcels: branch.limitOutboundParcels && !branch.manualOverride,
  scannedInboundParcelsInBillingPeriod: branch.scannedInboundParcelsInBillingPeriod,
  scannedOutboundParcelsInBillingPeriod: branch.scannedOutboundParcelsInBillingPeriod,
}))
