import { getCurrentUser } from 'api/auth'
import { IFeatureFlagsDto, ITenantProfileDto, IUserDevelopmentClientDto } from 'api/dto/dto'
import { getCurrentTenant } from 'api/tenants'
import autobind from 'autobind-decorator'
import { AxiosInstance, AxiosResponse } from 'axios'
import { plainToClass, plainToInstance } from 'class-transformer'
import analytics from 'modules/module-analytics'
import { QueryService } from 'modules/module-api/query/QueryService'
import { navigation } from 'modules/module-navigation'
import onboarding from 'modules/module-onboarding'
import { ILoggedInPayload, IStoreTokensPayload } from 'modules/module-onboarding/authentication'
import orchestration from 'modules/module-orchestration'
import reporting from 'modules/module-reporting'
import support from 'modules/module-support'
import { SupervisorSuite } from 'modules/redux-supervisor'
import { Action } from 'redux-actions'
import { SagaIterator } from 'redux-saga'
import { all, apply, call, fork, put, select, takeLatest } from 'redux-saga/effects'
import { security } from '..'
import { GeoService } from '../services/GeoService'
import session from '../session'
import { IPullCurrentUserPayload, Role } from '../session/models'
import {
  accessTokenStore,
  buildingIdsStore,
  refreshTokenStore,
  roleStore,
  userIdStore,
  usernameStore,
} from '../storage'
import { IIndexedService } from '../storage/indexed/IndexedService'
import { IReduxPersist } from '../storage/redux-persist/redux-persist'
import tokenService from '../tokens/TokenService'

export class SessionSaga extends SupervisorSuite {
  private readonly apiService: AxiosInstance
  readonly queryService: QueryService
  readonly geoService: GeoService
  readonly persistor: IReduxPersist
  readonly indexed: IIndexedService

  constructor(
    apiService: AxiosInstance,
    queryService: QueryService,
    geoService: GeoService,
    persistor: IReduxPersist,
    indexed: IIndexedService,
  ) {
    super()
    this.apiService = apiService
    this.queryService = queryService
    this.geoService = geoService
    this.persistor = persistor
    this.indexed = indexed
  }

  @autobind
  *start(): SagaIterator {
    yield all([
      takeLatest(onboarding.actions.LOGGED_IN, this.login),
      takeLatest(orchestration.actions.RESTORE, this.restore),
      takeLatest(session.actions.END, this.end),
      takeLatest(session.actions.PULL_CURRENT_USER, this.retrieveCurrentUser),
      takeLatest(session.actions.LOAD_PERMISSIONS, this.loadPermissions),
      takeLatest(session.actions.STORE_TOKENS, this.storeTokens),
    ])
  }

  @autobind
  *determineCountry(): SagaIterator {
    let countryCode: string | undefined = yield select(security.selectors.countryCode)

    if (!countryCode) {
      countryCode = yield apply(this.geoService, this.geoService.determineCountry, [])
      if (countryCode) yield put(security.actions.setCountryCode(countryCode))
    }

    yield put(orchestration.actions.accountGeoLocationReady())
  }

  @autobind
  *login({ payload }: Action<ILoggedInPayload>): SagaIterator {
    yield put(session.actions.loading(true))

    try {
      yield call(tokenService.username.set, payload.username)
      yield call(tokenService.accessToken.set, payload.accessToken)
      yield call(tokenService.refreshToken.set, payload.refreshToken)
      yield call(tokenService.userId.set, payload.userId)
      yield call(roleStore.set, payload.role)
      yield put(session.actions.pullCurrentUser({ role: payload.role }))
      if (payload.role == Role.USER) {
        yield put(navigation.actions.reset({ route: '/portal/get-started' }))
      } else {
        yield put(navigation.actions.reset({ route: '/portal/deliveries/inbound-directory' }))
      }
    } catch (error) {
      yield put(reporting.actions.error(error))
    }
  }

  @autobind
  *storeTokens({ payload }: Action<IStoreTokensPayload>): SagaIterator {
    yield put(session.actions.loading(true))

    yield call(tokenService.username.set, payload.username)
    yield call(tokenService.accessToken.set, payload.accessToken)
    yield call(tokenService.refreshToken.set, payload.refreshToken)
    yield call(tokenService.userId.set, payload.userId)
    yield call(roleStore.set, payload.role)

    yield put(session.actions.loading(false))
  }

  @autobind
  *restore(): SagaIterator {
    yield put(session.actions.loading(true))
    yield fork(this.determineCountry)
    try {
      const role = yield call(roleStore.get)
      yield call(tokenService.cycle)
      yield put(session.actions.pullCurrentUser({ role: role }))
      yield call(this.indexed.init)
    } catch (error) {
      yield call(this.dispose)
      yield put(session.actions.authenticated(false))
      yield put(reporting.actions.error(error))
    } finally {
      yield put(session.actions.loading(false))
    }
  }

  @autobind
  *end(): SagaIterator {
    yield call(this.dispose)
    try {
      yield call(this.queryService.clearCache)
      yield call(this.persistor.clear)
      yield call(this.indexed.deleteDatabase)
    } catch (err) {
      yield put(reporting.actions.error(err))
    }
    yield put(session.actions.authenticated(false))
    yield put(navigation.actions.navigate({ route: '/auth/login' }))
    yield put(analytics.actions.clean())
  }

  @autobind
  async dispose(): Promise<void> {
    await Promise.all([
      refreshTokenStore.clear(),
      accessTokenStore.clear(),
      usernameStore.clear(),
      roleStore.clear(),
      userIdStore.clear(),
      buildingIdsStore.clear(),
    ])
  }

  @autobind
  *loadPermissions(): SagaIterator {
    yield put(security.actions.loading(true))
    try {
      const response = yield call(this.apiService, '/v3/portal/features')
      const flags = plainToInstance(IFeatureFlagsDto, response.data)
      yield put(security.actions.setFeatureFlags(flags))
      yield put(orchestration.actions.determineUserPermissions({ flags }))
      yield put(security.actions.loadPermissionsResult({ success: true }))
    } catch (err) {
      yield put(reporting.actions.error(err))
      yield put(security.actions.loadPermissionsResult({ success: false }))
      yield call(this.dispose)
    } finally {
      yield put(security.actions.loading(false))
    }
  }

  @autobind
  *retrieveCurrentUser({ payload }: Action<IPullCurrentUserPayload>): SagaIterator {
    if (payload.role === Role.USER) {
      const { data }: AxiosResponse<IUserDevelopmentClientDto> = yield call(getCurrentUser)

      yield put(reporting.actions.identify({ user: data.User.Id }))
      yield put(analytics.actions.identify({ userId: data.User.Id, email: data.User.Email, clientId: data.Client.Id }))
      yield put(
        support.actions.identify({ email: data.User.Email, name: `${data.User.FirstName} ${data.User.LastName}` }),
      )

      yield put(
        session.actions.setUser({
          userId: data.User.Id,
          email: data.User.Email,
          firstName: data.User.FirstName,
          lastName: data.User.LastName,
          type: data.User.Type,
          role: payload.role,
          primarySite: data.Development.id,
          client: data.Client,
          development: data.Development,
          smsEnabled: data.Development.textsEnabled ?? false,
          phone: data.User.Phone,
        }),
      )
      yield put(session.actions.setClientId(data.Client.Id))
    } else if (payload.role === Role.RECIPIENT) {
      const response: AxiosResponse<ITenantProfileDto> = yield call(getCurrentTenant)
      const data = plainToClass(ITenantProfileDto, response?.data)

      yield put(reporting.actions.identify({ user: data.id }))
      yield put(analytics.actions.identify({ userId: data.id, email: data.email, clientId: undefined }))
      yield put(
        session.actions.setTenant({
          userId: data.id,
          email: data.email,
          firstName: data.firstName,
          lastName: data.lastName,
          role: payload.role,
          primarySite: data.primarySite,
          additionalSites: data.additionalSites ?? [],
          dateOfBirth: data.dateOfBirth,
        }),
      )
      yield put(session.actions.setClientId(data.clientId))
    }

    yield call(this.loadPermissions)
    yield put(orchestration.actions.restored({ success: true }))
  }
}
