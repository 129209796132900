import {
  EuiBreadcrumb,
  EuiBreadcrumbs,
  EuiButton,
  EuiButtonEmpty,
  EuiTabbedContent,
  EuiTabbedContentTab,
} from '@elastic/eui'
import { PortalPath } from 'modules/module-core/routes/models'
import navigation from 'modules/module-navigation'
import { isElement, useAppDispatch } from 'modules/module-utils'
import { Grid, Page, Paragraph, useScreen, useToggle, useTranslate } from 'modules/web-atoms'
import { ConfirmModal, IPanelProps, Panel } from 'modules/web-molecules'
import React from 'react'

export type INotificationsPageTemplateProps = {
  title: string
  onSubmit: () => void
  onRestoreDefault: () => void
  isSubmitLoading: boolean
  isSubmitDisabled?: boolean
  isRestoreDefaultLoading: boolean
  isLoading?: boolean
  singleTab?: boolean
  // same form as email tab for every tab
  sameForm?: boolean
  hasPermission?: boolean
} & IPanelProps

export enum NotificationsTab {
  EMAIL = 'email',
  SMS = 'sms',
}

export const NotificationsPageTemplate: React.FC<INotificationsPageTemplateProps> = (props) => {
  const {
    title,
    onSubmit,
    onRestoreDefault,
    isSubmitLoading,
    isSubmitDisabled,
    sameForm,
    singleTab,
    isLoading,
    isRestoreDefaultLoading,
    hasPermission,
    ...rest
  } = props
  const t = useTranslate('web-templates.notifications')
  const dispatch = useAppDispatch()

  const { isMobile, isTablet } = useScreen()
  const [selectedTab, setSelectedTab] = React.useState(NotificationsTab.EMAIL)
  const [isConfirmModalOpen, toggleConfirmModal] = useToggle(false)

  const { topContent, emailTab, emailPreview, smsTab, smsPreview } = React.useMemo(() => {
    const children = React.Children.toArray(props.children)
    const topContent = children.filter(isElement(TopContent)).map((content) => content.props.children)
    const emailTab = children.filter(isElement(EmailTab)).map((content) => content.props.children)
    const smsTab = children.filter(isElement(SMSTab)).map((content) => content.props.children)
    const emailPreview = children.filter(isElement(EmailPreview)).map((content) => content.props.children)
    const smsPreview = children.filter(isElement(SMSPreview)).map((content) => content.props.children)
    return { topContent, emailTab, emailPreview, smsTab, smsPreview }
  }, [props.children])

  const onCancel = React.useCallback(() => {
    dispatch(navigation.actions.navigate({ route: PortalPath.NOTIFICATIONS }))
  }, [dispatch])

  const breadcrumbs: EuiBreadcrumb[] = React.useMemo(
    () => [
      {
        text: t('root-page-title'),
        onClick: onCancel,
      },
      {
        text: title,
      },
    ],
    [title, onCancel, t],
  )

  const tabs: EuiTabbedContentTab[] = React.useMemo(() => {
    const tabs = [
      {
        id: NotificationsTab.EMAIL,
        name: t('tabs.email'),
        content: emailTab,
      },
    ]
    if (!singleTab) {
      tabs.push({
        id: NotificationsTab.SMS,
        name: t('tabs.sms'),
        content: sameForm ? emailTab : smsTab,
      })
    }

    return tabs
  }, [sameForm, singleTab, emailTab, smsTab, t])

  const onTabChange = (selectedTab: EuiTabbedContentTab) => {
    const { id } = selectedTab
    setSelectedTab(id as NotificationsTab)
  }

  const handleRestoreDefault = () => {
    toggleConfirmModal(false)
    onRestoreDefault()
  }

  return (
    <Page title={title}>
      <Panel px="large" py="medium" height="100%" {...rest}>
        <Grid height="100%" width="100%" gutterSize="medium">
          <Grid.Row>
            <EuiBreadcrumbs breadcrumbs={breadcrumbs} />
          </Grid.Row>

          <Grid.Row height="100%" width="100%">
            <Grid height="100%" width="100%" gutterSize="large" justifyContent="space-between">
              <Grid height="100%" gutterSize="small">
                <Grid.Row width="100%">{topContent}</Grid.Row>

                <Grid.Row height="100%" width="100%">
                  <Grid width="100%">
                    <Grid.Col
                      flex="1 1 0"
                      justifyContent="space-between"
                      className="eui-yScroll"
                      height={0}
                      minHeight="100%"
                      maxHeight="100%"
                    >
                      <Grid>
                        <EuiTabbedContent
                          initialSelectedTab={tabs[0]}
                          onTabClick={onTabChange}
                          tabs={tabs}
                          color="primary"
                        />

                        <Grid.Row mt="medium">
                          <EuiButton
                            onClick={toggleConfirmModal}
                            isLoading={isRestoreDefaultLoading}
                            color="primary"
                            isDisabled={isLoading}
                          >
                            <Paragraph color="primaryMid" fontWeight="medium">
                              {t('restore-default')}
                            </Paragraph>
                          </EuiButton>
                        </Grid.Row>
                      </Grid>

                      <Grid.Row justifyContent="center">
                        <Grid gutterSize="small">
                          <Grid.Col>
                            <EuiButtonEmpty onClick={onCancel}>{t('cancel')}</EuiButtonEmpty>
                          </Grid.Col>

                          <Grid.Col>
                            <EuiButton
                              isLoading={isSubmitLoading}
                              isDisabled={isLoading}
                              color="primary"
                              type="submit"
                              fill
                              onClick={onSubmit}
                              disabled={isSubmitDisabled || !hasPermission}
                            >
                              {t('submit')}
                            </EuiButton>
                          </Grid.Col>
                        </Grid>
                      </Grid.Row>
                    </Grid.Col>

                    {!isTablet && !isMobile && (
                      <Grid.Col height="100%" flex="1 1 0" marginLeft="small">
                        {selectedTab === NotificationsTab.EMAIL && emailPreview}
                        {selectedTab === NotificationsTab.SMS && smsPreview}
                      </Grid.Col>
                    )}
                  </Grid>
                </Grid.Row>
              </Grid>
            </Grid>
          </Grid.Row>
        </Grid>
        {isConfirmModalOpen && (
          <ConfirmModal
            type="danger"
            heading={t('restore-default')}
            subheading={t('restore-default-subheading')}
            confirmLabel={t('confirm')}
            cancelLabel={t('cancel')}
            onConfirm={handleRestoreDefault}
            onCancel={toggleConfirmModal}
          />
        )}
      </Panel>
    </Page>
  )
}

const TopContent: React.FC = ({ children }) => <>{children}</>
const EmailTab: React.FC = ({ children }) => <>{children}</>
const SMSTab: React.FC = ({ children }) => <>{children}</>
const EmailPreview: React.FC = ({ children }) => <>{children}</>
const SMSPreview: React.FC = ({ children }) => <>{children}</>

const template = Object.assign(React.memo(NotificationsPageTemplate), {
  type: NotificationsPageTemplate,
  TopContent,
  EmailTab,
  EmailPreview,
  SMSTab,
  SMSPreview,
})

export default template
