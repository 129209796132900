import { createReducer } from '@reduxjs/toolkit'
import permissions from '../permissions/Permissions'
import {
  setLoading,
  setRestoreDefaultLoading,
  setCurrentBuildingIds,
  setActionLoading,
  setIsAssigning,
  setPermissions,
  setOpenedListModal,
} from './actions'
import { initialState } from './models'

export const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(setLoading, (state, { payload }) => {
      state.loading = payload
    })
    .addCase(setRestoreDefaultLoading, (state, { payload }) => {
      state.isRestoreDefaultLoading = payload
    })
    .addCase(setCurrentBuildingIds, (state, { payload }) => {
      state.currentBuildingIds = payload
    })
    .addCase(setActionLoading, (state, { payload }) => {
      state.actionLoading = payload
    })
    .addCase(setIsAssigning, (state, { payload }) => {
      state.isAssigning = payload
    })
    .addCase(setPermissions, (state, { payload }) => {
      state.permissions.canEditNotifications = permissions.canEditNotifications.includes(payload.userType)
      state.permissions.canViewNotifications = permissions.canViewNotifications.includes(payload.userType)
    })
    .addCase(setOpenedListModal, (state, { payload }) => {
      state.openedListModalId = payload
    }),
)

export default reducer
