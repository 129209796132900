import { DeepPartial } from 'redux'

import merge from 'deepmerge'

import { IAuthenticationState } from './authentication'

export const BRANCH_KEY = 'onboarding'

export interface IOnboardingBranchState {
  authentication: IAuthenticationState
}

export interface IOnboardingOwnState {
  [BRANCH_KEY]: IOnboardingBranchState
}

export type IOnboardingState = IOnboardingOwnState

export const INITIAL_STATE: IOnboardingOwnState = {
  [BRANCH_KEY]: {
    authentication: { error: undefined, loading: false, accountList: [] },
  },
}

export const createOnboardingState = (state: DeepPartial<IOnboardingOwnState> = {}): IOnboardingOwnState =>
  merge(INITIAL_STATE, state) as IOnboardingState
