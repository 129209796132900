import { createReducer } from '@reduxjs/toolkit'
import { Permissions } from '../permissions'
import {
  setCurrency,
  setCurrentPlan,
  setDaysLeftInGracePeriod,
  setDaysLeftInTrial,
  setHasBeenPaidClient,
  setIsSmsEnabled,
  setIsTrial,
  setLoading,
  setLoadingCheckout,
  setManualOverride,
  setPaymentDetailsLoading,
  setPermissions,
  setPlanLimits,
  setRecommendedPlan,
  setSelectedPlan,
} from './actions'
import { initialState } from './models'

const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(setLoading, (state, { payload }) => {
      state.loading = payload
    })
    .addCase(setLoadingCheckout, (state, { payload }) => {
      state.checkoutLoading = payload
    })
    .addCase(setCurrentPlan, (state, { payload }) => {
      state.currentPlan = payload
      if (!state.selectedPlan) {
        state.selectedPlan = payload.id
      }
    })
    .addCase(setRecommendedPlan, (state, { payload }) => {
      state.recommendedPlan = payload
    })
    .addCase(setSelectedPlan, (state, { payload }) => {
      state.selectedPlan = payload
    })
    .addCase(setIsTrial, (state, { payload }) => {
      state.isTrial = payload
    })
    .addCase(setDaysLeftInTrial, (state, { payload }) => {
      state.daysLeftInTrial = payload
    })
    .addCase(setDaysLeftInGracePeriod, (state, { payload }) => {
      state.daysLeftInGracePeriod = payload
    })
    .addCase(setHasBeenPaidClient, (state, { payload }) => {
      state.hasBeenPaidClient = payload
    })
    .addCase(setPaymentDetailsLoading, (state, { payload }) => {
      state.paymentDetailsLoading = payload
    })
    .addCase(setPermissions, (state, { payload }) => {
      state.permissions.canViewBilling =
        Permissions.canViewBilling.includes(payload.userType) && !payload.manualOverride
    })
    .addCase(setPlanLimits, (state, { payload }) => {
      state.limitInboundParcels = payload.limitInboundParcels
      state.limitOutboundParcels = payload.limitOutboundParcels
      state.scannedInboundParcelsInBillingPeriod = payload.scannedInboundParcelsInBillingPeriod
      state.scannedOutboundParcelsInBillingPeriod = payload.scannedOutboundParcelsInBillingPeriod
    })
    .addCase(setManualOverride, (state, { payload }) => {
      state.manualOverride = payload
    })
    .addCase(setCurrency, (state, { payload }) => {
      state.currentCurrency = payload
    })
    .addCase(setIsSmsEnabled, (state, { payload }) => {
      state.isSmsEnabled = payload
    }),
)

export default reducer
