import { useCallback, useState } from 'react'

// New hack required to force state update
export const useForceUpdate = () => {
  const [, setState] = useState<number>(0)

  const forceUpdate = useCallback(() => {
    setState((n) => n + 1)
  }, [])

  return forceUpdate
}
