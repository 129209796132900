import { IFeatureFlagsDto } from 'api'
import { IStoreTokensPayload } from 'modules/module-onboarding/authentication'
import prefix from 'modules/utils/prefix'
import { createAction } from 'redux-actions'
import { IPullCurrentUserPayload, IResultPayload, ISetCurrentTenantPayload, ISetCurrentUserPayload } from './models'

const namespaced = prefix('security/session')

export const AUTHENTICATED = namespaced('authenticated')
export const authenticated = createAction<boolean>(AUTHENTICATED)

export const END = namespaced('end')
export const end = createAction(END)

export const STORE_TOKENS = namespaced('storeTokens')
export const storeTokens = createAction<IStoreTokensPayload>(STORE_TOKENS)

export const PULL_CURRENT_USER = namespaced('pullCurrentUser')
export const pullCurrentUser = createAction<IPullCurrentUserPayload>(PULL_CURRENT_USER)

export const SET_USER = namespaced('setUser')
export const setUser = createAction<ISetCurrentUserPayload>(SET_USER)

export const SET_TENANT = namespaced('setTenant')
export const setTenant = createAction<ISetCurrentTenantPayload>(SET_TENANT)

export const SET_CLIENT_ID = namespaced('setClientId')
export const setClientId = createAction<number>(SET_CLIENT_ID)

export const SET_FEATURE_FLAGS = namespaced('setFeatureFlags')
export const setFeatureFlags = createAction<IFeatureFlagsDto | undefined>(SET_FEATURE_FLAGS)

export const LOADING = namespaced('loading')
export const loading = createAction(LOADING)

export const LOAD_PERMISSIONS = namespaced('loadPermissions')
export const loadPermissions = createAction(LOAD_PERMISSIONS)

export const LOAD_PERMISSIONS_RESULT = namespaced('loadPermissionsResult')
export const loadPermissionsResult = createAction<IResultPayload>(LOAD_PERMISSIONS_RESULT)

export const SET_COUNTRY_CODE = namespaced('setCountryCode')
export const setCountryCode = createAction<string>(SET_COUNTRY_CODE)
