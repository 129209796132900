import { createReducer } from '@reduxjs/toolkit'
import permissions from '../permissions/Permissions'
import { setPermissions, setLoading, error } from './actions'
import { initialState } from './models'

export const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(setPermissions, (state, { payload }) => {
      state.permissions.canEditClient = permissions.canEditClient.includes(payload.userType)
    })
    .addCase(setLoading, (state, { payload }) => {
      state.loading = payload
    })
    .addCase(error, (state, { payload }) => {
      state.error = payload
    }),
)

export default reducer
