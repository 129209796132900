import * as React from 'react'

import { EuiLoadingSpinner } from '@elastic/eui'

import Grid from '../../Grid/Grid'
import StyledText from '../../typography/StyledText/StyledText'
import ButtonFrame, { IButtonFrameProps } from '../ButtonFrame/ButtonFrame'
import * as styles from '../styles'

export interface IButtonProps extends IButtonFrameProps {
  loading?: boolean
}

const createButton = (style: IButtonFrameProps, name: string): React.FC<IButtonFrameProps> => {
  const Button: React.FC<IButtonProps> = (props) => {
    const { color, fontFamily, fontSize, textAlign, loading, ...button } = props
    const text = { fontFamily, fontSize, textAlign }

    const [isHovered, setIsHovered] = React.useState(false)
    const [isPressed, setIsPressed] = React.useState(false)

    const textColor = React.useMemo(() => {
      switch (true) {
        case props.loading:
          // This keeps the button sizing consistent between states
          return button.backgroundColor
        case !!isHovered && !!button.activeTextColor:
          return button.activeTextColor
        default:
          return color
      }
    }, [button.activeTextColor, button.backgroundColor, color, isHovered, props.loading])

    const backgroundColor = React.useMemo(() => {
      switch (true) {
        case props.loading:
          // This keeps the button sizing consistent between states
          return button.activeBackgroundColor
        case !!isHovered && !!button.activeBackgroundColor:
          return button.activeBackgroundColor
        default:
          return button.backgroundColor
      }
    }, [button.activeBackgroundColor, button.backgroundColor, isHovered, props.loading])

    return (
      <ButtonFrame
        disabled={props.loading}
        {...button}
        backgroundColor={backgroundColor}
        onMouseOver={() => setIsHovered(true)}
        onMouseDown={() => {
          setIsPressed(true)
        }}
        onMouseUp={() => {
          setIsPressed(false)
        }}
        onMouseLeave={() => setIsHovered(false)}
      >
        <>
          <StyledText color={textColor} {...text}>
            {props.children}
          </StyledText>
          {loading && (
            <Grid flavour="absolute-fill" justifyContent="center">
              <EuiLoadingSpinner size="s" color={color} />
            </Grid>
          )}
        </>
      </ButtonFrame>
    )
  }
  Button.displayName = name
  Button.defaultProps = { backgroundColor: 'none', ...style }
  return React.memo(Button)
}

export interface IButton extends React.FunctionComponent<IButtonProps> {
  ContainedLight: React.FunctionComponent<IButtonProps>
  Contained: React.FunctionComponent<IButtonProps>
  Outline: React.FunctionComponent<IButtonProps>
  Frame: React.FunctionComponent<IButtonProps>
  Text: React.FunctionComponent<IButtonProps>
  Primary: React.FunctionComponent<IButtonProps>
  Secondary: React.FunctionComponent<IButtonProps>
}

export const Button: IButton = (props) => <Button.Contained {...props} />

Button.ContainedLight = createButton(styles.ContainedLight, 'ContainedLightButton')
Button.Contained = createButton(styles.Contained, 'ContainedButton')
Button.Outline = createButton(styles.Outline, 'Outline')
Button.Text = createButton(styles.Text, 'Text')
Button.Frame = ButtonFrame
Button.Primary = createButton(styles.Primary, 'PrimaryButton')
Button.Secondary = createButton(styles.Secondary, 'SecondaryButton')

export default Button
