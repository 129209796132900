import autobind from 'autobind-decorator'
import { IValueStore, StorageError } from './store'



export class SessionValueStore implements IValueStore<string> {
  readonly key: string

  constructor(key: string) {
    this.key = key
  }

  @autobind
  async get(): Promise<string> {
    try {
      const item = sessionStorage.getItem(this.key)
      return !!item ? Promise.resolve(item) : Promise.reject(StorageError.NOT_EXIST)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  @autobind
  async set(value: string): Promise<boolean> {
    try {
      sessionStorage.setItem(this.key, value)
      return Promise.resolve(true)
    } catch (error) {
      return Promise.resolve(false)
    }
  }

  @autobind
  async exists(): Promise<boolean> {
    try {
      const item = sessionStorage.getItem(this.key)
      return Promise.resolve(!!item)
    } catch (error) {
      return Promise.resolve(false)
    }
  }

  @autobind
  async clear(): Promise<void> {
    try {
      sessionStorage.removeItem(this.key)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
