import autobind from 'autobind-decorator'
import reporting from 'modules/module-reporting'
import { SupervisorSuite } from 'modules/redux-supervisor'
import { Action } from 'redux-actions'
import { SagaIterator } from 'redux-saga'
import { all, call, put, takeEvery } from 'redux-saga/effects'
import * as actions from '../actions/actions'
import { ISupportIdentifyPayload } from '../model'
import { ZendeskAPI } from 'react-zendesk'

export class SupportSaga extends SupervisorSuite {
  @autobind
  *start(): SagaIterator {
    yield all([takeEvery(actions.IDENTIFY, this.identify)])
  }

  @autobind
  *identify({ payload }: Action<ISupportIdentifyPayload>): SagaIterator {
    try {
      yield call(ZendeskAPI, 'webWidget', 'identify', { email: payload.email, name: payload.name })
    } catch (error) {
      yield put(reporting.actions.error(error))
    }
  }
}

export default SupportSaga
