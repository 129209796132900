import { combineReducers } from 'redux'
import { handleActions } from 'redux-actions'

import * as actions from './actions/actions'
import { IAnalyticsState, IContextPayload, INITIAL_STATE, ITrackingContext } from './model'
import * as selectors from './selectors'

export const context = handleActions<ITrackingContext, IContextPayload>(
  {
    [actions.REMOVE_CONTEXT]: (state, { payload }) => {
      const keysToRemove = Object.keys(payload)
      const newState: JSONObject = {}
      Object.keys(state).forEach((key) => {
        if (!keysToRemove.includes(key)) newState[key] = state[key]
      })
      return newState
    },
  },
  selectors.context(INITIAL_STATE),
)

export default combineReducers<IAnalyticsState>({ context })
