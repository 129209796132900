import board from './board'
import reducer from './root-reducer'
import dashboardSagas from './sagas'

export const dashboard = {
  actions: board.actions,
  reducer,
  sagas: dashboardSagas,
}

export default dashboard
