import { IFeatureFlagsDto } from 'api'
import merge from 'deepmerge'
import { DeepPartial } from 'redux'
import { BRANCH_KEY } from './branch'
import { IOrchestrationState, initialState as orchestratioInitialState } from './orchestration'

export interface IOrchestrationBranchState {
  orchestration: IOrchestrationState
}

export interface IOrchestrationOwnState {
  [BRANCH_KEY]: IOrchestrationBranchState
}

export type IOrchestrationBaseState = IOrchestrationOwnState

export const INITIAL_STATE: IOrchestrationOwnState = {
  [BRANCH_KEY]: {
    orchestration: orchestratioInitialState,
  },
}

export const createOrchestrationState = (state: DeepPartial<IOrchestrationOwnState> = {}): IOrchestrationOwnState =>
  merge(INITIAL_STATE, state) as IOrchestrationOwnState

export interface ILoggedInPayload {
  auth:
    | {
        username: string
        accessToken: string
        refreshToken: string
      }
    | undefined
}

export interface IRestoredPayload {
  success: boolean
}

export interface ILoginSsoPayload {
  email: string
  enityId: string
  token: string
}

export interface IDetermineUserPermissionsPayload {
  flags: IFeatureFlagsDto
}

export interface IQueryResultPayload {
  success: boolean
}
