import { createSelector, Selector } from 'reselect'
import { BRANCH_KEY } from '../branch'

import { IAccountState } from '../model'

import { IUserState } from './models'

export const branch: Selector<IAccountState, IUserState> = (state) => state[BRANCH_KEY]?.user

export const permissions = createSelector(branch, (branch) => branch?.permissions)
export const isLoading = createSelector(branch, (branch) => branch?.loading)
export const error = createSelector(branch, (branch) => branch?.error)
