import autobind from 'autobind-decorator'
import { logError } from 'modules/logging/Sentry'
import { Persistor } from 'redux-persist'

export interface IReduxPersist {
  attach(persistor: Persistor)
  get(): Promise<Persistor>
  clear(): Promise<void>
}

export enum PersistError {
  NOT_READY,
}

export class ReduxPersist implements IReduxPersist {
  persistor: Persistor | undefined

  @autobind
  attach(persistor: Persistor) {
    try {
      this.persistor = persistor
    } catch (error) {
      logError(error)
    }
  }

  @autobind
  async get(): Promise<Persistor> {
    try {
      return this.persistor ? Promise.resolve(this.persistor) : Promise.reject(PersistError.NOT_READY)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  @autobind
  async clear(): Promise<void> {
    try {
      this.persistor?.purge()
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

const reduxPersist = new ReduxPersist()

export default reduxPersist
