import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import axios from 'axios'
import moduleReporting from 'modules/module-reporting'
import { OperatingSystem } from 'modules/ts/types'
import { envUtil } from 'modules/utils/EnvironmentUtils'
import security, { refreshTokenStore } from 'modules/module-security'

export const API =
  'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyCEUHxPDcvSyzlklU2SbzSGRyjWlCciQ7M'
export const STORE_ANDROID = 'https://play.google.com/store/apps/details?id=deepfinity.android'
export const STORE_IOS = 'https://apps.apple.com/us/app/parcel-tracker/id1491350064'

export const useDeeplink = (forceDeeplink = false) => {
  const [link, setLink] = React.useState<string | undefined>()

  const email = useSelector((state: any) => security.selectors.email(state))
  const id = useSelector((state: any) => security.selectors.id(state))
  const dispatch = useDispatch()

  const linkPayload: any = React.useMemo(async () => {
    const refreshToken = await refreshTokenStore.get()
    const linkData = {
      email: email,
      id: id,
      token: refreshToken,
    }

    const encodedLinkData = encodeURI(JSON.stringify(linkData))

    return {
      method: 'post',
      url: API,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        longDynamicLink: `https://getparceltracker.com/?link=https://www.parceltracker.com/signup?data=${encodedLinkData}&apn=deepfinity.android&isi=1491350064&ibi=com.deepfinity.parceltracker`,
      },
    }
  }, [email, id])

  React.useEffect(() => {
    const fetchData = async () => {
      const dynamicLink = await linkPayload
      try {
        const result = await axios(dynamicLink)
        setLink(result.data.shortLink)
      } catch (err) {
        dispatch(moduleReporting.actions.error(err))
      }
    }
    if (link == undefined) fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkPayload])

  const isMobile = React.useMemo(() => {
    return (
      envUtil.getOperatingSystem() == OperatingSystem.iOS || envUtil.getOperatingSystem() == OperatingSystem.Android
    )
  }, [])

  const androidLink = React.useMemo(() => {
    return (link && isMobile) || forceDeeplink ? link : STORE_ANDROID
  }, [forceDeeplink, isMobile, link])

  const iosLink = React.useMemo(() => {
    return (link && isMobile) || forceDeeplink ? link : STORE_IOS
  }, [forceDeeplink, isMobile, link])

  return [androidLink, iosLink]
}

export default useDeeplink
