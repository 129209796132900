import { ITenantDto } from 'api'
import { Expose, Type } from 'class-transformer'
import 'reflect-metadata'

export class IBatchTenantsDto {
  @Expose({ name: 'Tenants' })
  @Type(() => ITenantDto)
  tenants: ITenantDto[]
}

export class IUploadPreviewRequestDto {
  @Expose({ name: 'Tenants' })
  @Type(() => IUploadPreviewTenantDto)
  tenants: IUploadPreviewTenantDto[]
}

export class IUploadPreviewTenantDto {
  @Expose({ name: 'ExternalId' })
  externalId: string | undefined
  @Expose({ name: 'DevelopmentIds' })
  developmentIds: number[]
}

export class IUploadPreviewDto {
  @Expose({ name: 'TotalCurrentCountPerBuilding' })
  @Type(() => IUploadDevelopmentPreviewDto)
  currentTotalCountPerBuilding: IUploadDevelopmentPreviewDto[]
  @Expose({ name: 'CurrentCountPerBuildingAsPrimary' })
  @Type(() => IUploadDevelopmentPreviewDto)
  currentCountPerBuilding: IUploadDevelopmentPreviewDto[]
  @Expose({ name: 'CurrentCountPerBuildingAsAdditional' })
  @Type(() => IUploadDevelopmentPreviewDto)
  currentAdditionalCountPerBuilding: IUploadDevelopmentPreviewDto[]
  @Expose({ name: 'DeletionsPerBuildingAsPrimary' })
  @Type(() => IUploadDevelopmentPreviewDto)
  deletionsPerBuilding: IUploadDevelopmentPreviewDto[]
  @Expose({ name: 'DeletionsPerBuildingAsAdditional' })
  @Type(() => IUploadDevelopmentPreviewDto)
  deletionsAdditionalPerBuilding: IUploadDevelopmentPreviewDto[]
  @Expose({ name: 'AdditionsPerBuildingAsPrimary' })
  @Type(() => IUploadDevelopmentPreviewDto)
  additionsPerBuilding: IUploadDevelopmentPreviewDto[]
  @Expose({ name: 'AdditionsPerBuildingAsAdditional' })
  @Type(() => IUploadDevelopmentPreviewDto)
  additionsAdditionalPerBuilding: IUploadDevelopmentPreviewDto[]
  @Expose({ name: 'TotalFinalCountPerBuilding' })
  @Type(() => IUploadDevelopmentPreviewDto)
  finalCountPerBuilding: IUploadDevelopmentPreviewDto[]
  @Expose({ name: 'FinalCountPerBuildingAsPrimary' })
  @Type(() => IUploadDevelopmentPreviewDto)
  finalPrimaryCountPerBuilding: IUploadDevelopmentPreviewDto[]
  @Expose({ name: 'FinalCountPerBuildingAsAdditional' })
  @Type(() => IUploadDevelopmentPreviewDto)
  finalAdditionalCountPerBuilding: IUploadDevelopmentPreviewDto[]
}

export class IUploadDevelopmentPreviewDto {
  @Expose({ name: 'DevelopmentId' })
  developmentId: number
  @Expose({ name: 'Count' })
  count: number
}
