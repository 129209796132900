import * as React from 'react'

import { IUserDtoClass } from 'api'
import API from 'api/clients'
import { plainToInstance } from 'class-transformer'
import useApi from 'modules/module-api/hooks/useApi/use-api'

import { QueryKey } from 'modules/module-api'
import security from 'modules/module-security'
import { useAppSelector } from '../use-app-selector/use-app-selector'

function useUsers() {
  const client = useAppSelector(security.selectors.client)

  const { data, isFetching, refetch, error } = useApi<IUserDtoClass[]>(
    [QueryKey.ALL_USERS, client.Id],
    async () => {
      const response = await API({
        method: 'get',
        url: `/v3/users/autocomplete`,
        params: {
          client_id: client.Id,
          limit: 9999,
          filter: '',
        },
      })
      return response.data
    },
    {
      enabled: false,
    },
  )

  React.useEffect(() => {
    client && refetch()
  }, [client, refetch])

  const users = React.useMemo(() => {
    return data ? plainToInstance(IUserDtoClass, data) : []
  }, [data])

  return { users, isFetching, error, totalItems: users?.length ?? 0 }
}

export default useUsers
