export const noop = (..._: unknown[]) => {
  // noop
}

export const identity = <T>(value: T) => value

export const identityFor =
  <T>() =>
  (value: T) =>
    value

export const not =
  <Fn extends (...args: any[]) => any>(...fns: Fn[]) =>
  (...args: Parameters<Fn>): boolean =>
    !fns.filter((fn) => fn(...args)).length
