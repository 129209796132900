import { EuiCollapsibleNav } from '@elastic/eui'
import { isElement } from 'modules/module-utils'
import { Grid, useScreen, VerticalHeight } from 'modules/web-atoms'
import * as React from 'react'
import MenuGroup from './MenuGroup/MenuGroup'
import MenuItem from './MenuItem/MenuItem'

export interface ISideMenuProps {
  isMinimized: boolean
  isFlyout: boolean
  onRouteChanged?: (route: string) => void
  isDocked: boolean
  isOpen: boolean
  onClose: () => void
}

const FULL_WIDTH = 240
const MINIMIZED_WIDTH = 82

export const SideMenu: React.FC<ISideMenuProps> = (props) => {
  const { isMinimized, isFlyout, isOpen, isDocked, onClose, onRouteChanged, ...rest } = props

  const { verticalHeight } = useScreen()

  const { header, menu, footer, profile } = React.useMemo(() => {
    const children = React.Children.toArray(props.children)

    const header = children?.filter(isElement(Header)).map((panel) => panel.props.children)
    const menu = children?.filter(isElement(Menu)).map((panel) => panel.props.children)
    const footer = children?.filter(isElement(Footer)).map((panel) => panel.props.children)
    const profile = children?.filter(isElement(Profile)).map((panel) => panel.props.children)

    return { header, menu, footer, profile }
  }, [props.children])

  const menuMargin: any = React.useMemo(() => {
    switch (true) {
      case verticalHeight == VerticalHeight.SMALL:
        return 'xlarge'
      case verticalHeight == VerticalHeight.MEDIUM:
        return 'xlarge'
      case verticalHeight == VerticalHeight.LARGE:
        return 'massive'
      default:
        return 'massive'
    }
  }, [verticalHeight])

  const menuContent = React.useMemo(() => {
    return (
      <Grid
        height="100vh"
        paddingX={isMinimized ? 'smallest' : 'xsmall'}
        alignItems="center"
        justifyContent="space-between"
        paddingBottom="xsmall"
        className="eui-yScrollWithShadows scrollbar-overlay"
      >
        <Grid
          marginTop="small"
          alignItems="center"
          justifyContent="center"
          position="fixed"
          top={-15}
          zIndex={1}
          backgroundColor="primaryBackground"
          width="100%"
          height={50}
        >
          {header}
        </Grid>
        <Grid flex={1} flexGrow={1} marginTop={menuMargin} marginBottom="large">
          {menu}
        </Grid>
        <Grid>
          {footer}
          {profile}
        </Grid>
      </Grid>
    )
  }, [footer, header, isMinimized, menu, menuMargin, profile])

  return (
    <EuiCollapsibleNav
      dockedBreakpoint={768}
      isOpen={isOpen}
      isDocked={isDocked}
      size={isMinimized ? MINIMIZED_WIDTH : FULL_WIDTH}
      {...rest}
      onClose={onClose}
      style={{ transition: 'all 0.5s ease-out' }}
    >
      {menuContent}
    </EuiCollapsibleNav>
  )
}

export interface ISideMenu extends React.MemoExoticComponent<typeof SideMenu> {
  Header: typeof Header
  Menu: typeof Menu
  Footer: typeof Footer
  Profile: typeof Profile
  MenuGroup: typeof MenuGroup
  MenuItem: typeof MenuItem
}

const Header: React.FunctionComponent = ({ children }) => <>{children}</>
const Menu: React.FunctionComponent = ({ children }) => <>{children}</>
const Footer: React.FunctionComponent = ({ children }) => <>{children}</>
const Profile: React.FunctionComponent = ({ children }) => <>{children}</>

const sideMenu: ISideMenu = Object.assign(React.memo(SideMenu), {
  type: SideMenu,
  Header,
  Menu,
  Footer,
  Profile,
  MenuGroup,
  MenuItem,
})

export default sideMenu
