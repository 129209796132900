import { IRequestTokenDto, IUserDevelopmentClientDto } from 'api'
import {
  addClientDetails,
  checkUserLoggedIn,
  checkUserScannedParcel,
  getCurrentUser,
  sendAppDownloadLink,
  setAccountType,
  setOnboardingStage,
  signupUser,
} from 'api/auth'
import autobind from 'autobind-decorator'
import { AxiosInstance, AxiosResponse } from 'axios'
import { plainToInstance } from 'class-transformer'
import http from 'modules/http'
import notifications from 'modules/module-alerts'
import { OpenPath } from 'modules/module-core/routes/models'
import { translate } from 'modules/module-intl'
import navigation from 'modules/module-navigation'
import orchestration from 'modules/module-orchestration'
import security, { OnboardingStage, Role, serverIdStore } from 'modules/module-security'
import { TokenService } from 'modules/module-security/tokens/TokenService'
import { SupervisorSuite } from 'modules/redux-supervisor'
import { REACT_APP_AUTH_API, REACT_APP_AUTH_API_EU, REACT_APP_AUTH_API_US } from 'modules/utils/constants'
import { Action } from 'redux-actions'
import { SagaIterator } from 'redux-saga'
import { all, call, delay, fork, put, race, take, takeLatest, takeLeading } from 'redux-saga/effects'
import sha512 from 'sha512'
import reporting from '../../module-reporting'
import { AccountType, GetLoginModeDto, IUserServerResolveDto, SelectAccountDto } from '../api/dto'
import { authUser, resolveUserServer, userSetupRequest } from '../api/onboarding-api'
import authentication, {
  IAccountTypePayload,
  IFinishSignupPayload,
  IGetLoginModePayload,
  ILoginPayload,
  IOnboardingStagePayload,
  ISendAppLinkPayload,
  ISetupBuildingPayload,
  ISignupPayload,
  IUserSetupRequestPayload,
  LoginMode,
  LoginStep,
  SignupStep,
} from '../authentication'

const { finishMultiLogin } = authentication.actions

export class AuthenticationSaga extends SupervisorSuite {
  private readonly apiService: AxiosInstance
  private readonly tokenService: TokenService

  constructor(apiService: AxiosInstance, tokenService: TokenService) {
    super()
    this.apiService = apiService
    this.tokenService = tokenService
  }

  @autobind
  *start(): SagaIterator {
    yield fork(this.pollUserLoggedIn)
    yield fork(this.pollParcelScanned)
    yield all([
      takeLatest(authentication.actions.LOGIN, this.login),
      takeLatest(authentication.actions.SIGN_UP, this.signup),
      takeLatest(authentication.actions.SEND_APP_LINK, this.sendAppLink),
      takeLatest(authentication.actions.SAVE_ACCOUNT_TYPE, this.saveAccountType),
      takeLatest(authentication.actions.SAVE_ONBOARDING_STAGE, this.saveOnboardingStage),
      takeLatest(authentication.actions.FINISH_SIGNUP, this.finishSignup),
      takeLeading(authentication.actions.SETUP_BUILDING, this.setupBuilding),
      takeLatest(navigation.actions.NAVIGATED, this.cleanState),
      takeLatest(authentication.actions.GET_LOGIN_MODE, this.getLoginMode),
      takeLatest(authentication.actions.CANCEL_MULTI_LOGIN, this.cancelMultiLogin),
      takeLatest(authentication.actions.USER_SETUP_REQUEST, this.userSetupRequest),
    ])
  }

  @autobind
  *getLoginMode({ payload }: Action<IGetLoginModePayload>): SagaIterator {
    yield put(authentication.actions.loading(true))
    const { email } = payload

    try {
      const hashedEmail = sha512(email).toString('hex')
      const doubleHashedEmail = sha512(`${email}${email}`).toString('hex')

      const serverResolveDto: IUserServerResolveDto = yield call(resolveUserServer, hashedEmail, doubleHashedEmail)
      yield call(serverIdStore.set, serverResolveDto.serverId)

      const response = yield call(this.apiService, 'auth/login-mode', {
        method: 'GET',
        params: {
          email,
        },
      })
      const { mode } = plainToInstance(GetLoginModeDto, response.data)

      if ([LoginMode.SAML, LoginMode.OIDC].includes(mode)) {
        let ssoDomain: string
        switch (serverResolveDto.serverId) {
          case 'us-central':
            ssoDomain = REACT_APP_AUTH_API_US
            break
          case 'uk':
            ssoDomain = REACT_APP_AUTH_API
            break
          case 'eu':
            ssoDomain = REACT_APP_AUTH_API_EU
            break
          default:
            ssoDomain = REACT_APP_AUTH_API
            break
        }

        if (mode === LoginMode.SAML) {
          window.location.assign(
            `${ssoDomain}/auth/external/SAML/SSO?email=${email}&return_url=${window.location.href}&allow_recipient_login=true`,
          )
        } else if (mode === LoginMode.OIDC) {
          window.location.assign(
            `${ssoDomain}/auth/external/OIDC/SSO?email=${email}&return_url=${window.location.href}`,
          )
        }
      } else {
        yield put(authentication.actions.setLoginMode(mode))
      }
    } catch (error) {
      yield put(authentication.actions.error(error))
      yield put(reporting.actions.error(error))
    } finally {
      yield put(authentication.actions.loading(false))
    }
  }

  @autobind
  *login({ payload }: Action<ILoginPayload>): SagaIterator {
    const { email, password, preHashed, requiresResolve, accessToken } = payload

    yield put(authentication.actions.loading(true))

    try {
      if (requiresResolve) {
        const hashedEmail = sha512(email).toString('hex')
        const doubleHashedEmail = sha512(`${email}${email}`).toString('hex')

        const serverResolveDto: IUserServerResolveDto = yield call(resolveUserServer, hashedEmail, doubleHashedEmail)
        yield call(serverIdStore.set, serverResolveDto.serverId)
      }

      // TODO: copy this logic
      const hashPassword: string | undefined = preHashed
        ? password
        : password
        ? sha512(password).toString('hex')
        : undefined
      const authData: IRequestTokenDto | SelectAccountDto[] = yield call(authUser, {
        email,
        password: hashPassword,
        accessToken,
      })
      if (Array.isArray(authData)) {
        // Navigate to login page in case this is an external login redirect
        yield put(navigation.actions.navigate({ route: OpenPath.LOGIN }))
        yield put(authentication.actions.loginStep(LoginStep.SelectAccount))

        const sortedAccountList = [...authData].sort((a, b) => {
          if (a.type === AccountType.USER) {
            return -1
          } else if (b.type === AccountType.USER) {
            return 1
          } else {
            return 0
          }
        })
        yield put(authentication.actions.setAccountList(sortedAccountList))
        yield race({
          login: takeLeading(
            authentication.actions.FINISH_MULTI_LOGIN,
            this.finishMultiLogin,
            email,
            hashPassword,
            accessToken,
          ),
          cancel: take(authentication.actions.CANCEL_MULTI_LOGIN),
        })
      } else {
        yield put(
          authentication.actions.loggedIn({
            username: payload.email,
            userId: authData.userId,
            refreshToken: authData.refreshToken,
            accessToken: authData.accessToken,
            role: authData.userType,
          }),
        )
      }
    } catch (error) {
      yield put(authentication.actions.error(error))
      yield put(reporting.actions.error(error))
    } finally {
      yield put(authentication.actions.loading(false))
    }
  }

  @autobind
  *finishMultiLogin(
    email: string,
    hashPassword: string | undefined,
    accessToken: string | undefined,
    { payload }: ReturnType<typeof finishMultiLogin>,
  ): SagaIterator {
    const { userId } = payload

    try {
      const authData: IRequestTokenDto = yield call(authUser, {
        email,
        password: hashPassword,
        userId,
        accessToken,
      })

      yield put(
        authentication.actions.loggedIn({
          username: email,
          userId: authData.userId,
          refreshToken: authData.refreshToken,
          accessToken: authData.accessToken,
          role: authData.userType,
        }),
      )
      yield put(security.actions.authenticated(true))
    } catch (error) {
      yield put(authentication.actions.error(error))
      yield put(reporting.actions.error(error))
    }
  }

  @autobind
  *cancelMultiLogin(): SagaIterator {
    yield put(authentication.actions.loginStep(LoginStep.AccountDetails))
    yield put(authentication.actions.setLoginMode(undefined))
  }

  @autobind
  *signup({ payload }: Action<ISignupPayload>): SagaIterator {
    yield put(authentication.actions.loading(true))
    yield put(authentication.actions.error(undefined))

    try {
      // Store server region for future calls
      yield call(serverIdStore.set, payload.regionId)

      // Singup
      const hashPassword = sha512(payload.password).toString('hex')
      yield call(signupUser, {
        firstName: payload.firstName,
        lastName: payload.lastName,
        email: payload.email,
        password: hashPassword,
        referrer: payload.referrer,
      })

      const authData: IRequestTokenDto | SelectAccountDto[] = yield call(authUser, {
        email: payload.email,
        password: hashPassword,
      })
      let tokenData: IRequestTokenDto

      if (Array.isArray(authData)) {
        const userInfo = authData.filter((data) => data.type === AccountType.USER)[0]
        tokenData = yield call(authUser, {
          email: payload.email,
          password: hashPassword,
          userId: userInfo.id,
        })
      } else {
        tokenData = authData
      }

      yield put(
        security.actions.storeTokens({
          username: payload.email,
          refreshToken: tokenData.refreshToken,
          accessToken: tokenData.accessToken,
          userId: tokenData.userId,
          role: Role.USER,
        }),
      )
      yield put(authentication.actions.signupStep(SignupStep.AccountType))
    } catch (error) {
      yield put(authentication.actions.error(error))
      yield put(reporting.actions.error(error))

      yield put(
        notifications.actions.error({
          message: yield translate('onboarding.signup.account-details.form.validation.email.unknown'),
        }),
      )
    } finally {
      yield put(authentication.actions.loading(false))
    }
  }

  @autobind
  *saveAccountType({ payload }: Action<IAccountTypePayload>): SagaIterator {
    yield put(authentication.actions.loading(true))
    try {
      yield call(setAccountType, {
        AccountType: payload.accountType,
      })
      yield put(authentication.actions.signupStep(SignupStep.ConfigureBuilding))
    } catch (error) {
      yield put(authentication.actions.error(error))
      yield put(reporting.actions.error(error))
    } finally {
      yield put(authentication.actions.loading(false))
    }
  }

  @autobind
  *setupBuilding({ payload }: Action<ISetupBuildingPayload>): SagaIterator {
    yield put(authentication.actions.loading(true))
    try {
      const {
        data: { Client },
      }: AxiosResponse<IUserDevelopmentClientDto> = yield call(getCurrentUser)

      yield call(addClientDetails, {
        ClientId: Client.Id,
        CompanyName: payload.companyName,
        BuildingName: payload.buildingName,
        Industry: payload.industry,
        ReferrerName: payload.referrerName,
        Phone: payload.phone,
      })
      yield put(security.actions.authenticated(true))
      yield put(orchestration.actions.restore({ success: true }))
      yield put(authentication.actions.saveOnboardingStage({ onboardingStage: OnboardingStage.BUILDING_CONFIGURED }))
    } catch (error) {
      yield put(authentication.actions.error(error))
      yield put(reporting.actions.error(error))
    } finally {
      yield put(authentication.actions.loading(false))
    }
  }

  @autobind
  *saveOnboardingStage({ payload }: Action<IOnboardingStagePayload>): SagaIterator {
    yield put(authentication.actions.loading(true))
    try {
      yield call(setOnboardingStage, {
        OnboardingStage: payload.onboardingStage,
      })
      yield put(orchestration.actions.restore({ success: true }))
    } catch (error) {
      yield put(authentication.actions.error(error))
      yield put(reporting.actions.error(error))
    } finally {
      yield put(authentication.actions.loading(false))
    }
  }

  @autobind
  *sendAppLink({ payload }: Action<ISendAppLinkPayload>): SagaIterator {
    yield put(authentication.actions.loading(true))
    try {
      yield call(sendAppDownloadLink, {
        Number: payload.number,
      })
      yield put(notifications.actions.success({ message: 'Download link has been sent to your phone' }))
    } catch (error) {
      yield put(authentication.actions.error(error))
      yield put(reporting.actions.error(error))
    } finally {
      yield put(authentication.actions.loading(false))
    }
  }

  @autobind
  *finishSignup({ payload }: Action<IFinishSignupPayload>): SagaIterator {
    yield put(authentication.actions.loading(true))
    try {
      yield call(addClientDetails, {
        ClientId: payload.clientId,
        CompanyName: payload.companyName,
        BuildingName: payload.buildingName,
      })
      yield put(authentication.actions.signupStep(SignupStep.AccountDetails))
      yield put(security.actions.pullCurrentUser({ role: Role.USER }))
    } catch (error) {
      yield put(authentication.actions.error(error))
      yield put(reporting.actions.error(error))
    } finally {
      yield put(authentication.actions.loading(false))
    }
  }

  @autobind
  *cleanState(): SagaIterator {
    yield put(authentication.actions.clearError())
  }

  @autobind
  *userLoggedInWorker(): SagaIterator {
    while (true) {
      try {
        const { status } = yield call(checkUserLoggedIn)
        if (status === 200) {
          yield put(authentication.actions.saveOnboardingStage({ onboardingStage: OnboardingStage.AWAIT_PARCEL_SCAN }))
          yield put(authentication.actions.stopUserLoggedInPoll())
        }
      } catch (error) {
        yield put(reporting.actions.error(error))
      }
      yield delay(5000)
    }
  }

  @autobind
  *parcelScannedWorker(): SagaIterator {
    while (true) {
      try {
        const { status: ScannedParcelStatus } = yield call(checkUserScannedParcel)
        if (ScannedParcelStatus === 200) {
          yield put(authentication.actions.saveOnboardingStage({ onboardingStage: OnboardingStage.APP_SETUP_FINISHED }))
          yield put(authentication.actions.stopParcelScannedInPoll())
        }
      } catch (error) {
        yield put(reporting.actions.error(error))
      }
      yield delay(5000)
    }
  }

  @autobind
  *pollUserLoggedIn() {
    while (true) {
      yield take(authentication.actions.START_USER_LOGGED_IN_POLL)
      yield race([call(this.userLoggedInWorker), take(authentication.actions.STOP_USER_LOGGED_IN_POLL)])
    }
  }

  @autobind
  *pollParcelScanned() {
    while (true) {
      yield take(authentication.actions.START_PARCEL_SCANNED_POLL)
      yield race([call(this.parcelScannedWorker), take(authentication.actions.STOP_PARCEL_SCANNED_POLL)])
    }
  }

  @autobind
  *userSetupRequest({ payload }: Action<IUserSetupRequestPayload>): SagaIterator {
    const { email, password, token, serverId } = payload
    yield put(authentication.actions.loading(true))
    yield put(authentication.actions.error(undefined))

    try {
      yield call(serverIdStore.set, serverId)

      // Singup
      const hashPassword = sha512(password).toString('hex')
      yield call(userSetupRequest, email, hashPassword, token)

      yield put(notifications.actions.success({ message: 'Your password was set successfully' }))
    } catch (error) {
      yield put(authentication.actions.error(error))
      yield put(reporting.actions.error(error))

      if (http.guards.isError(error) && error?.response?.status === 400) {
        yield put(
          notifications.actions.error(yield translate('onboarding.user-setup.form.validation.error.predefined')),
        )
      } else {
        yield put(
          notifications.actions.error({
            message: yield translate('commons.alert.generic-error'),
          }),
        )
      }
    } finally {
      yield put(authentication.actions.loading(false))
      yield put(navigation.actions.navigate({ route: '/auth/login' }))
    }
  }
}

export default AuthenticationSaga
