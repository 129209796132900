import { INIT, NAVIGATE_RECIPIENT_UPLOAD, RESET_UPLOAD } from 'modules/module-recipient-upload/upload/actions'
import { handleActions } from 'modules/utils/redux'

import flow, { UploadScreen } from './flow'
import { INavigateUploadPayload, initialState, IUploadState } from './models'

export const reducer = handleActions<IUploadState>(
  {
    [NAVIGATE_RECIPIENT_UPLOAD]: (state: IUploadState, { payload }: { payload: INavigateUploadPayload }) => {
      const currentScreen = state.currentScreen
      const currentNode = flow.find((n) => n.id == currentScreen)
      const action = currentNode?.actions.find((a) => a.id == payload)

      if (!!action) state.currentScreen = action.to
    },
    [RESET_UPLOAD]: (state: IUploadState) => {
      state.currentScreen = UploadScreen.UploadFile
    },
    [INIT]: (state: IUploadState) => {
      state.currentScreen = UploadScreen.UploadFile
    },
  },
  initialState,
)

export default reducer
