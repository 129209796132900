export function RGBToHex(rgb) {
  // Choose correct separator
  let sep = rgb.indexOf(',') > -1 ? ',' : ' '
  // Turn "rgb(r,g,b)" into [r,g,b]
  rgb = rgb.substr(4).split(')')[0].split(sep)

  let r = (+rgb[0]).toString(16),
    g = (+rgb[1]).toString(16),
    b = (+rgb[2]).toString(16)

  if (r.length == 1) r = '0' + r
  if (g.length == 1) g = '0' + g
  if (b.length == 1) b = '0' + b

  return '#' + r + g + b
}

export function hexToRGB(h) {
  let r = '0'
  let g = '0'
  let b = '0'

  // 3 digits
  if (h.length == 4) {
    r = '0x' + h[1] + h[1]
    g = '0x' + h[2] + h[2]
    b = '0x' + h[3] + h[3]

    // 6 digits
  } else if (h.length == 7) {
    r = '0x' + h[1] + h[2]
    g = '0x' + h[3] + h[4]
    b = '0x' + h[5] + h[6]
  }

  return 'rgb(' + +r + ',' + +g + ',' + +b + ')'
}

/**
 * Utility function to calculate brightness of the provided hexcolor
 * @param hc Hexcolor to calculate brightness for
 * @param light Color to be used if the background color is dark
 * @param dark Color to be used if the background color is light
 * @returns A text color that will contrast well with the provided hexcolor
 */
export function getContrastYIQ(hc: string, light = '#fff', dark = '#000') {
  const [r, g, b] = [0, 2, 4].map((p) => parseInt(hc.substr(p, 2), 16))
  const yiq = (r * 299 + g * 587 + b * 114) / 1000
  return yiq >= 130 ? dark : light
}
