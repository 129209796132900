import { EuiButton, EuiButtonEmpty } from '@elastic/eui'
import { FormikConfig, useFormik } from 'formik'
import { useAppDispatch, useAppSelector } from 'modules/module-utils'
import { Grid, H1, H3, Paragraph, useTranslate } from 'modules/web-atoms'
import { FieldText, FormRow, IPanelProps, Panel } from 'modules/web-molecules'
import React from 'react'
import * as Yup from 'yup'
import settings, { SettingsFormState } from '../../../settings'

export interface ISizeFormValues {
  name: string
  keepShowingForm?: boolean
}

const INITIAL_VALUES: ISizeFormValues = {
  name: '',
}

export type ISizeFormProps = {} & IPanelProps

export const SizeForm: React.FC<ISizeFormProps> = ({ ...rest }) => {
  const t = useTranslate('settings.tabs.sizes.form')
  const dispatch = useAppDispatch()

  const formState = useAppSelector(settings.selectors.sizeFormState)
  const selectedSize = useAppSelector(settings.selectors.selectedSize)
  const isAdding = useAppSelector(settings.selectors.isAddingSize)
  const isEditing = useAppSelector(settings.selectors.isEditingSize)

  const isFormLoading = formState === SettingsFormState.CREATE ? isAdding : isEditing

  const onCancel = () => {
    dispatch(settings.actions.setSizeFormState(SettingsFormState.HIDDEN))
  }

  const onSubmit: FormikConfig<ISizeFormValues>['onSubmit'] = (values, { resetForm }) => {
    if (formState === SettingsFormState.CREATE) {
      dispatch(settings.actions.addSize({ ...values }))
      resetForm()
    }
  }

  const validationSchema = React.useMemo(
    () =>
      Yup.object<ISizeFormValues>().shape({
        name: Yup.string().trim().required(t('validation.required')),
        identifier: Yup.string().trim().optional().min(1),
      }),
    [t],
  )
  const formik = useFormik<ISizeFormValues>({
    initialValues: INITIAL_VALUES,
    validationSchema,
    onSubmit,
  })
  const { values, touched, errors, setValues, setFieldValue, submitForm, handleChange, resetForm } = formik

  const onSaveAndAddAnother = () => {
    void setFieldValue('keepShowingForm', true)
    void submitForm()
  }

  React.useEffect(() => {
    if (selectedSize) {
      void setValues({
        ...INITIAL_VALUES,
        name: selectedSize.name,
      })
    } else {
      resetForm()
    }
  }, [selectedSize, setValues, resetForm])

  return (
    <Panel width={['100%', 300, 350, 450]} py="medium" px="large" {...rest}>
      <Grid height="100%" justifyContent="space-between">
        <Grid flexGrow={1} minHeight={0} gutterSize="medium" className="eui-yScroll">
          <Grid>
            {formState === SettingsFormState.CREATE ? (
              <Grid gutterSize="none">
                <H1 color="primaryMid" marginBottom={['none', 'smallest']}>
                  {t('heading')}
                </H1>

                <H3 mb="none">{t('subheading')}</H3>
              </Grid>
            ) : (
              <H1 color="primaryMid" marginBottom={['none', 'smallest']}>
                {selectedSize?.name}
              </H1>
            )}
          </Grid>

          <Grid justifyContent="flex-start" paddingX="none">
            <FormRow label={t('name')} touched={touched.name} error={errors.name}>
              <FieldText
                type="text"
                name="name"
                value={values.name}
                onChange={handleChange('name')}
                readOnly={formState !== SettingsFormState.CREATE}
              />
            </FormRow>
          </Grid>
        </Grid>

        <Grid alignItems="center">
          {!selectedSize && (
            <EuiButtonEmpty color="primary" flush="both" isLoading={isFormLoading} onClick={onSaveAndAddAnother}>
              {t('save-another')}
            </EuiButtonEmpty>
          )}

          <Grid alignItems="center" justifyContent="center" gutterSize="medium">
            <Grid.Col>
              <EuiButtonEmpty color="primary" onClick={onCancel} isDisabled={isFormLoading}>
                <Paragraph color="textDark">{t('cancel')}</Paragraph>
              </EuiButtonEmpty>
            </Grid.Col>
            {formState === SettingsFormState.CREATE && (
              <Grid.Col>
                <EuiButton color="primary" fill isLoading={isFormLoading} onClick={submitForm}>
                  {t('save')}
                </EuiButton>
              </Grid.Col>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Panel>
  )
}

export default React.memo(SizeForm)
