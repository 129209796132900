import { EuiTab, EuiTabs } from '@elastic/eui'
import { Grid, IGridProps } from 'modules/web-atoms'
import React from 'react'
import { Route, useHistory, useLocation, useRouteMatch } from 'react-router'

export interface ITabItem {
  id: string
  path: string
  label: string
  content: JSX.Element
  hidden?: boolean
}

export interface ITabsProps extends IGridProps {
  tabs: ITabItem[]
}

const Tabs: React.FC<ITabsProps> = ({ tabs, ...rest }) => {
  const { path } = useRouteMatch()
  const { pathname } = useLocation()
  const history = useHistory()

  const [selectedTabPath, setSelectedTabPath] = React.useState<string>(pathname.split('/').pop() ?? '')

  const onTabChange = React.useCallback(
    (tabPath: string) => () => {
      setSelectedTabPath(tabPath)
      history.push(`${path}/${tabPath}`)
    },
    [history, path],
  )

  const filteredTabs = React.useMemo(() => tabs.filter((tab) => !tab.hidden), [tabs])
  const renderTabs = () =>
    filteredTabs.map((tab, idx) => (
      <EuiTab key={idx} onClick={onTabChange(tab.path)} isSelected={tab.path === selectedTabPath}>
        {tab.label}
      </EuiTab>
    ))

  return (
    <Grid gutterSize="large" flexGrow={1} minHeight={0} width="100%" {...rest}>
      <EuiTabs style={{ width: '100%' }}>{renderTabs()}</EuiTabs>

      <Grid.Row flexGrow={1} minHeight={0} width="100%">
        {filteredTabs.map((tab) => (
          <Route key={tab.id} exact path={`${path}/${tab.path}`}>
            {tab.content}
          </Route>
        ))}
      </Grid.Row>
    </Grid>
  )
}

export default React.memo(Tabs)
