import { UserType } from 'api'
import { TableSelectionAction } from 'modules/web-molecules'

export enum SettingsFormState {
  HIDDEN = 'HIDDEN',
  CREATE = 'CREATE',
  EDIT = 'EDIT',
}

export interface ITagItem {
  id: number
  name: string
  color: string
  enabled: boolean
  clientId?: number | null
  siteId?: number
}

export interface ILocation {
  id: string
  name: string
  identifier: string
  siteId: number
  enabled?: boolean
}

export interface ICourier {
  id: string
  name: string
}

export interface ISize {
  id: string
  name: string
}

export interface IDropoffLocation {
  id: string
  name: string
  clientId: number
  enabled?: boolean
}

export interface IPermissions {
  canDeleteAllRecipients: boolean
  canExportAllRecipients: boolean
  canEditFeatureFlags: boolean
  canManageTags: boolean
  canManageLocations: boolean
  canManageCouriers: boolean
  canManageSizes: boolean
  canManageDropoffLocations: boolean
}

export enum FeatureFlag {
  OUTBOUND,
  COLLECTION,
  BATCH_COLLECTION,
  ADDITIONAL,
  DROPOFF_LOCATION,
}

export interface ISettingState {
  permissions: IPermissions
  flagLoading?: FeatureFlag

  // Tags
  showAddTagForm: boolean
  isAddingTag: boolean
  deletingTagIds: number[]
  updatingTagIds: number[]
  editingTagIds: number[]

  // Locations
  selectedLocation: ILocation | undefined
  selectedLocations: string[]
  locationFormState: SettingsFormState
  isAddingLocation: boolean
  isEditingLocation: boolean
  isDeletingLocations: boolean
  selectedSite?: number

  // Couriers
  selectedCourier: ICourier | undefined
  selectedCouriers: string[]
  courierFormState: SettingsFormState
  isAddingCourier: boolean
  isEditingCourier: boolean
  isDeletingCouriers: boolean

  // Dropoff Locations
  selectedDropoffLocation: IDropoffLocation | undefined
  selectedDropoffLocations: string[]
  dropoffLocationFormState: SettingsFormState
  isAddingDropoffLocation: boolean
  isEditingDropoffLocation: boolean
  isDeletingDropoffLocation: boolean

  // Size
  selectedSize: ISize | undefined
  selectedSizes: string[]
  sizeFormState: SettingsFormState
  isAddingSize: boolean
  isEditingSize: boolean
  isDeletingSizes: boolean
}

export const initialState: ISettingState = {
  permissions: {
    canDeleteAllRecipients: false,
    canExportAllRecipients: false,
    canEditFeatureFlags: false,
    canManageTags: false,
    canManageLocations: false,
    canManageCouriers: false,
    canManageSizes: false,
    canManageDropoffLocations: false,
  },

  showAddTagForm: false,
  isAddingTag: false,
  deletingTagIds: [],
  updatingTagIds: [],
  editingTagIds: [],

  selectedLocation: undefined,
  selectedLocations: [],
  locationFormState: SettingsFormState.HIDDEN,
  isAddingLocation: false,
  isEditingLocation: false,
  isDeletingLocations: false,

  selectedCourier: undefined,
  selectedCouriers: [],
  courierFormState: SettingsFormState.HIDDEN,
  isAddingCourier: false,
  isEditingCourier: false,
  isDeletingCouriers: false,

  selectedDropoffLocation: undefined,
  selectedDropoffLocations: [],
  dropoffLocationFormState: SettingsFormState.HIDDEN,
  isAddingDropoffLocation: false,
  isEditingDropoffLocation: false,
  isDeletingDropoffLocation: false,

  selectedSize: undefined,
  selectedSizes: [],
  sizeFormState: SettingsFormState.HIDDEN,
  isAddingSize: false,
  isEditingSize: false,
  isDeletingSizes: false,
}

export interface ISetPermissionsPayload {
  userType: UserType
}

export interface ISetFeatureFlagPayload {
  flag: FeatureFlag
  value: boolean
  activeBuildings: number[]
}

export interface ISetFeatureFlagLoadingPayload {
  flag: FeatureFlag
  state: boolean
}

export interface ISetTagStatePayload {
  id: number
  state: boolean
}

export interface ISetSelectedTableItems {
  items: string[]
  action: TableSelectionAction
}

export interface ISetTagCreationPayload {
  visible: boolean
  isLoading: boolean
}
