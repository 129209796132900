import { EuiBadge, EuiButton, EuiLink, EuiSearchBar } from '@elastic/eui'
import { useTheme } from '@emotion/react'
import { PortalPath } from 'modules/module-core/routes/models'
import navigation from 'modules/module-navigation'
import { isElement, useAppDispatch } from 'modules/module-utils'
import { not } from 'modules/utils'
import { FlexGrid, FlexItem, Grid, H1, H2, Paragraph, useScreen } from 'modules/web-atoms'
import { IPanelProps, Panel } from 'modules/web-molecules'
import * as React from 'react'
import { Trans } from 'react-i18next'

export interface IDeleteOptions {
  visible: boolean
  isLoading: boolean
  onClick: () => void
  value: string
}

export interface ISearchOptions {
  hint: string
  onSearch: (query: string) => void
}

export interface ICreateOptions {
  visible: boolean
  onClick: () => void
  value: string
}

export interface ISelectOptions {
  visible: boolean
  onClick: () => void
  value: string
  action?: React.ReactNode
}

export type ITablePanelTemplateProps = IPanelProps & {
  isDeleteModalOpen?: boolean
  heading?: string
  subheading?: string
  createOptions: ICreateOptions
  searchOptions: ISearchOptions
  deleteOptions?: IDeleteOptions
  selectOptions?: ISelectOptions
  restrictionLabel?: string
}

const INTL_PREFIX = 'web-templates.table-panel-template'

export const TablePanelTemplate: React.FC<ITablePanelTemplateProps> = (props) => {
  const {
    isDeleteModalOpen,
    heading,
    subheading,
    searchOptions,
    createOptions,
    deleteOptions,
    selectOptions,
    restrictionLabel,
    ...rest
  } = props
  const dispatch = useAppDispatch()
  const { colors } = useTheme()

  const { isMobile } = useScreen()

  const { content, table, deleteModal, options, actions } = React.useMemo(() => {
    const children = React.Children.toArray(props.children)

    const content = children.filter(
      not(isElement(Table), isElement(DeleteModal), isElement(Options), isElement(Actions)),
    )
    const table = children.filter(isElement(Table)).map((toolbar) => toolbar.props.children)
    const deleteModal = children.filter(isElement(DeleteModal)).map((panel) => panel.props.children)
    const options = React.Children.toArray(children.filter(isElement(Options)).map((panel) => panel.props.children))
    const actions = React.Children.toArray(children.filter(isElement(Actions)).map((panel) => panel.props.children))

    return { content, table, deleteModal, options, actions }
  }, [props.children])

  const onQuery = React.useCallback(
    ({ queryText: filter }) => {
      searchOptions.onSearch(filter)
    },
    [searchOptions],
  )

  const onPlanUpgradeClick = () => {
    dispatch(navigation.actions.navigate({ route: PortalPath.BILLING }))
  }

  return (
    <>
      <Panel
        height="100%"
        paddingY={['smallest', 'small', 'large', 'large']}
        paddingX={['smallest', 'smallest', 'large', 'large']}
        {...rest}
      >
        <Grid height="100%" isResponsive={false} gutterSize={['smallest', 'smallest']}>
          <Grid.Row flexGrow={0}>
            <Grid flexWrap="nowrap" direction={['column', 'column', 'row']} flexGrow={1} justifyContent="space-between">
              <Grid.Col>
                <Grid alignItems="center" gutterSize="small">
                  <Grid.Col>
                    <H1 color="textDark" fontWeight="semiBold" fontSize="xxxlarge">
                      {heading}
                    </H1>
                  </Grid.Col>

                  {restrictionLabel && (
                    <Grid.Col>
                      <Grid alignItems="center">
                        <Grid.Col>
                          <EuiBadge color={colors.featureMain}>
                            <Paragraph color="textLight" fontWeight="bold" size="small">
                              {restrictionLabel}
                            </Paragraph>
                          </EuiBadge>
                        </Grid.Col>

                        <Grid.Col>
                          <Paragraph color="primaryMid" size="small">
                            <Trans
                              i18nKey={`${INTL_PREFIX}.upgrade-account`}
                              components={{
                                l: (
                                  <EuiLink
                                    onClick={onPlanUpgradeClick}
                                    color="primary"
                                    style={{ color: colors.primaryMid, textDecorationColor: colors.primaryMid }}
                                  />
                                ),
                              }}
                            />
                          </Paragraph>
                        </Grid.Col>
                      </Grid>
                    </Grid.Col>
                  )}
                </Grid>
                <H2 mt={['none', 'smallest', 'smallest', 'smallest']} color="primaryMid" whiteSpace="nowrap">
                  {subheading}
                </H2>

                <Grid mt="smallest" gutterSize="small">
                  {deleteOptions?.visible && (
                    <Grid.Col>
                      <EuiButton
                        isLoading={deleteOptions.isLoading}
                        onClick={deleteOptions.onClick}
                        fill
                        color="danger"
                        size="s"
                      >
                        {deleteOptions.value}
                      </EuiButton>
                    </Grid.Col>
                  )}

                  {selectOptions?.visible && (
                    <Grid.Col>
                      {selectOptions.action ? (
                        selectOptions.action
                      ) : (
                        <EuiButton onClick={selectOptions.onClick} fill color="primary" size="s">
                          {selectOptions.value}
                        </EuiButton>
                      )}
                    </Grid.Col>
                  )}
                </Grid>
              </Grid.Col>

              <Grid.Col mt={['large', 'small', 'none', 'none']} alignItems={[...Array(2).fill(null), 'flex-end']}>
                {isMobile ? (
                  <Grid gutterSize="small">
                    <FlexGrid gutterSize="s" responsive={false}>
                      {createOptions.visible && (
                        <FlexItem grow={1}>
                          <EuiButton fullWidth onClick={createOptions.onClick} color="primary" fill>
                            <Paragraph whiteSpace="nowrap" px="medium" color="textLight" fontWeight="medium">
                              {createOptions.value}
                            </Paragraph>
                          </EuiButton>
                        </FlexItem>
                      )}
                      {options.map((option, index) => (
                        <FlexItem grow={1} key={index}>
                          {option}
                        </FlexItem>
                      ))}
                    </FlexGrid>
                    <EuiSearchBar box={{ placeholder: searchOptions.hint, incremental: true }} onChange={onQuery} />

                    <FlexGrid
                      display={!actions.length ? 'none' : undefined}
                      responsive={false}
                      marginTop="small"
                      gutterSize="s"
                      marginBottom="none"
                    >
                      {actions.map((action, index) => (
                        <FlexItem key={index}>{action}</FlexItem>
                      ))}
                    </FlexGrid>
                  </Grid>
                ) : (
                  <Grid
                    justifyContent="flex-end"
                    flexWrap="nowrap"
                    gutterSize={[null, null, 'smallest', 'medium']}
                    flexShrink={1}
                  >
                    <Grid gutterSize={[null, 'small', 'small', 'medium']} justifyContent="flex-end">
                      <Grid.Col>
                        <EuiSearchBar
                          box={{
                            placeholder: searchOptions.hint,
                            incremental: true,
                            fullWidth: true,
                          }}
                          onChange={onQuery}
                        />
                      </Grid.Col>
                      {createOptions.visible && (
                        <Grid.Col>
                          <EuiButton onClick={createOptions.onClick} color="primary" fill>
                            <Paragraph
                              whiteSpace="nowrap"
                              px={[null, null, 'smallest', 'medium']}
                              color="textLight"
                              fontWeight="medium"
                            >
                              {createOptions.value}
                            </Paragraph>
                          </EuiButton>
                        </Grid.Col>
                      )}
                      {options.length && <Grid.Col>{options}</Grid.Col>}
                    </Grid>

                    {actions.length && (
                      <FlexGrid
                        responsive={false}
                        marginTop={['macro', 'macro', 'none']}
                        gutterSize="m"
                        justifyContent="flex-end"
                        alignSelf="flex-end"
                        marginBottom="none"
                      >
                        {actions.map((action, index) => (
                          <FlexItem key={index}>{action}</FlexItem>
                        ))}
                      </FlexGrid>
                    )}
                  </Grid>
                )}
              </Grid.Col>
            </Grid>
          </Grid.Row>
          <Grid.Row flexGrow={1} minHeight={0}>
            {table}
          </Grid.Row>
        </Grid>

        {content}
      </Panel>
      {isDeleteModalOpen && deleteModal}
    </>
  )
}

export interface ITablePanelTemplate extends React.MemoExoticComponent<typeof TablePanelTemplate> {
  Table: typeof Table
  DeleteModal: typeof DeleteModal
  Options: typeof Options
  Actions: typeof Actions
}

const Table: React.FC = ({ children }) => <>{children}</>
const DeleteModal: React.FC = ({ children }) => <>{children}</>
const Options: React.FC = ({ children }) => <>{children}</>
const Actions: React.FC = ({ children }) => <>{children}</>

const template: ITablePanelTemplate = Object.assign(React.memo(TablePanelTemplate), {
  type: TablePanelTemplate,
  Table,
  DeleteModal,
  Options,
  Actions,
})

export default template
