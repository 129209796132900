import { handleActions as raHandleActions } from 'redux-actions'

import { produce } from 'immer'

interface actions<State> {
  [key: string]: (state: State, { payload }: { payload: any }) => void
}

export const handleActions = <State>(actions: actions<State>, initialState: State) =>
  raHandleActions(
    Object.keys(actions).reduce((acc, key) => {
      acc[key] = produce(actions[key])
      return acc
    }, {}),
    initialState,
  )
