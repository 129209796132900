import * as React from 'react'
import { useDispatch } from 'react-redux'

import { EuiButton, EuiButtonEmpty, EuiCheckbox, EuiFormRow, EuiLink, htmlIdGenerator } from '@elastic/eui'

import { useFormik } from 'formik'
import theme from 'modules/dna'
import notification from 'modules/module-alerts/alert'
import { FlexItem, Grid, H1, H2, Page, useTranslate } from 'modules/web-atoms'
import * as Yup from 'yup'

import { PortalPath } from 'modules/module-core/routes/models'
import navigation from 'modules/module-navigation'
import { Trans } from 'react-i18next'
import RecipientFilePicker from '../../components/RecipientFilePicker/RecipientFilePicker'
import useRecipientFilesParser from '../../hooks/use-recipient-file-parser/use-recipient-files-parser'
import upload from '../../upload'

export interface IUploadFilePageFormData {
  permissions: boolean
}

export interface IUploadFilePageProps extends React.ComponentProps<typeof FlexItem> {
  isOnboarding?: boolean
}

const INTL_PREFIX = 'recipients-upload.upload-file'
const FILE_TEMPLATE_LINK = 'https://pt-assets-public.s3.eu-west-2.amazonaws.com/Tenant+Onboarding+Template.xlsx'
const KNOWLEDGEBASE_UPLOAD_LINK =
  'https://support.parceltracker.com/hc/en-gb/articles/4403525464337-How-to-bulk-upload-recipients-on-Parcel-Tracker'

export const UploadFilePage: React.FC<IUploadFilePageProps> = ({ isOnboarding, ...rest }) => {
  const t = useTranslate(INTL_PREFIX)
  const tCommon = useTranslate("commons")
  const dispatch = useDispatch()

  const [files, setFiles] = React.useState<FileList | undefined>()
  const [parseFile, parsedFile, parseError] = useRecipientFilesParser()

  const onFilesChange = (files: FileList | null) => {
    if (!!files && files.length) {
      setFiles(files)
    } else {
      setFiles(undefined)
    }
  }

  const onSubmit = React.useCallback(() => {
    if (files?.length) {
      parseFile?.(files)
    } else {
      dispatch(notification.actions.showError({ message: t('error.length') }))
    }
  }, [dispatch, files, parseFile, t])

  React.useEffect(() => {
    !!parsedFile && dispatch(upload.actions.uploadRecipientsFile(parsedFile))
  }, [dispatch, parsedFile])

  React.useEffect(() => {
    if (!!parseError) {
      dispatch(notification.actions.showError({ message: t('error.parse') }))
    }
  }, [dispatch, parseError, t])

  const handleBack = React.useCallback(() => {
    if (isOnboarding) {
      dispatch(upload.actions.navigateRecipientUpload('previous'))
    } else {
      dispatch(navigation.actions.navigate({ route: PortalPath.RESIDENTS_DIRECTORY }))
    }
  }, [dispatch, isOnboarding])

  const uploadFileSchema = React.useMemo(
    () =>
      Yup.object<IUploadFilePageFormData>().shape({
        permissions: Yup.boolean().equals([true], t('form.error.permission')),
      }),
    [],
  )

  const form = useFormik<IUploadFilePageFormData>({
    initialValues: { permissions: false },
    onSubmit: ({ permissions }) => onSubmit(),
    validationSchema: uploadFileSchema,
  })

  const { touched, values, errors } = form
  const { submitForm, handleChange } = form

  return (
    <Page title={t('title')}>
      <FlexItem grow={true} alignItems="center" flex={1} {...rest}>
        <Grid alignItems="center" marginBottom="medium" height="100%" flex={1} justifyContent="center">
          <H1 textAlign="center">{t('heading')}</H1>
          <H2 marginTop="smallest" textAlign="center">
            <Trans
              i18nKey={`${INTL_PREFIX}.subheading`}
              components={{
                linkTemplate: <EuiLink color="subdued" href={FILE_TEMPLATE_LINK} target="_blank" external={false} />,
                linkKnowledge: (
                  <EuiLink color="subdued" href={KNOWLEDGEBASE_UPLOAD_LINK} target="_blank" external={false} />
                ),
              }}
            />
          </H2>
          <Grid marginTop={['large', 'xlarge']} width={500}>
            <RecipientFilePicker fullWidth display="large" onChange={onFilesChange} />
            <EuiFormRow
              isInvalid={touched.permissions && !!errors.permissions}
              error={touched.permissions ? errors.permissions : undefined}
              inputMode="email"
            >
              <EuiCheckbox
                id={htmlIdGenerator()()}
                label={t('form.permissions')}
                checked={values.permissions}
                onChange={handleChange('permissions')}
              />
            </EuiFormRow>
          </Grid>
        </Grid>
        <Grid justifyContent="center" alignItems="center">
          <Grid.Col>
            <EuiButtonEmpty onClick={handleBack}>{tCommon('action.back')}</EuiButtonEmpty>
          </Grid.Col>
          <Grid.Col>
            <EuiButton fill onClick={submitForm} style={{ marginLeft: theme.spacing.large }}>
              {tCommon('action.next')}
            </EuiButton>
          </Grid.Col>
        </Grid>
      </FlexItem>
    </Page>
  )
}

export default React.memo(UploadFilePage)
