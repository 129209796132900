import {
  ICreateExternalRecipientDto,
  ICreateOutboundParcelDto,
  ILogNewDeliveryDto,
  IMarkParcelsAsHeldDto,
  IMoveParcelsDto,
  IParcelBatchActionDto,
  IRecipientCreateParcelDto,
  IUpdateParcelDto,
} from 'api'
import autobind from 'autobind-decorator'
import { AxiosInstance, HttpStatusCode } from 'axios'
import { instanceToPlain } from 'class-transformer'
import http from 'modules/http'
import alerts from 'modules/module-alerts'
import { QueryKey, QueryService } from 'modules/module-api'
import { translate } from 'modules/module-intl/sagas/effects/translate'
import orchestration from 'modules/module-orchestration'
import reporting from 'modules/module-reporting'
import security from 'modules/module-security'
import { strings } from 'modules/module-utils'
import { SupervisorSuite } from 'modules/redux-supervisor'
import { TableSelectionAction } from 'modules/web-molecules'
import { SagaIterator } from 'redux-saga'
import { all, call, put, select, takeEvery, takeLatest, takeLeading } from 'redux-saga/effects'
import { v4 as uuidv4 } from 'uuid'
import { IChangeParcelDetailsDto } from '../api/dto'
import deliveries, { IParcelBatchActionPayload } from '../parcels'
import { changeParcelTenant, markAsHeld, scheduleReminder } from '../parcels/actions'

// Actions
const {
  markAsCollected,
  logNewDelivery,
  updateInboundParcel,
  markAsCollectedOutbound,
  logNewOutboundParcel,
  moveParcels,
  createExternalRecipient,
  recipientCreateParcel,
  error,
} = deliveries.actions

const INTL_PREFIX = 'deliveries-directory'
export class ParcelsSaga extends SupervisorSuite {
  private readonly apiService: AxiosInstance
  private readonly queryService: QueryService

  constructor(apiService: AxiosInstance, queryService: QueryService) {
    super()
    this.apiService = apiService
    this.queryService = queryService
  }

  @autobind
  *start(): SagaIterator {
    yield all([
      takeLatest(orchestration.actions.DETERMINE_USER_PERMISSIONS, this.determineUserPermissions),
      takeLatest(orchestration.actions.SET_ACTIVE_BUILDINGS, this.onSetActiveSites),
      takeLeading(deliveries.actions.MARK_AS_COLLECTED, this.markAsCollected),
      takeLeading(deliveries.actions.MARK_AS_COLLECTED_OUTBOUND, this.markAsCollectedOutbound),
      takeLeading(deliveries.actions.LOG_NEW_DELIVERY, this.logNewDelivery),
      takeLeading(deliveries.actions.LOG_NEW_OUTBOUND_PARCEL, this.logNewOutboundParcel),
      takeLeading(deliveries.actions.UPDATE_INBOUND_PARCEL, this.updateInboundParcel),
      takeLeading(deliveries.actions.MOVE_PARCELS, this.moveParcels),
      takeLeading(deliveries.actions.CREATE_EXTERNAL_RECIPIENT, this.createExternalRecipient),
      takeLeading(deliveries.actions.RECIPIENT_CREATE_PARCEL, this.recipientCreateParcel),
      takeLeading(deliveries.actions.MARK_AS_HELD, this.markAsHeld),
      takeLeading(deliveries.actions.SCHEDULE_REMINDER, this.scheduleReminder),
      takeLeading(deliveries.actions.CHANGE_PARCEL_TENANT, this.changeParcelTenant),
      takeEvery(deliveries.actions.ERROR, this.onError),
    ])
  }

  @autobind
  *determineUserPermissions({
    payload,
  }: ReturnType<typeof orchestration.actions.determineUserPermissions>): SagaIterator {
    const userType = yield select(security.selectors.type)
    const userRole = yield select(security.selectors.role)
    yield put(
      deliveries.actions.setPermissions({
        userType,
        userRole,
        isOutboundEnabled: payload.flags.outboundEnabled,
        isRequestorFormEnabled: payload.flags.multimailroomRequestorEnabled,
      }),
    )
  }

  @autobind
  *onSetActiveSites({ payload }: ReturnType<typeof orchestration.actions.setActiveBuildings>): SagaIterator {
    yield put(deliveries.actions.setSelectedInboundParcels({ items: [], action: TableSelectionAction.DESELECTED_ALL }))
  }

  @autobind
  *markAsCollected({ payload }: ReturnType<typeof markAsCollected>): SagaIterator {
    const { signature, parcelIds, notifyRecipient, notes, batchActionParams } = payload

    yield put(deliveries.actions.setLoading(true))
    const data = new FormData()
    if (signature) data.append('signature', signature)
    if (notes) data.append('collectionNotes', notes)
    if (batchActionParams) {
      const batchActionDto = this.generateBatchActionDto(batchActionParams)
      Object.keys(batchActionDto).forEach((key) => {
        const val = batchActionDto[key]
        const formattedVal = Array.isArray(val) ? val.join(',') : val
        if (formattedVal) data.append(strings.capitalize(key), formattedVal)
      })
    } else {
      data.append('parcelIds', parcelIds.join(','))
    }

    try {
      yield call(
        this.apiService,
        batchActionParams ? '/v3/parcels/manual-collect/batch' : '/v3/parcels/manual-collect',
        {
          method: 'POST',
          data,
          params: {
            sendNotification: notifyRecipient,
          },
          headers: { 'Content-Type': 'multipart/form-data' },
        },
      )
      yield put(alerts.actions.success({ message: yield translate(`${INTL_PREFIX}.mark-as-collected.success`) }))
    } catch (err) {
      yield put(reporting.actions.error(err))

      if (http.guards.isError(error) && error?.response?.status === HttpStatusCode.NotAcceptable) {
        yield put(deliveries.actions.error(yield translate(`${INTL_PREFIX}.parcels.error.cannot-perform`)))
      } else {
        yield put(deliveries.actions.error(yield translate(`${INTL_PREFIX}.default-error`)))
      }
    } finally {
      yield put(deliveries.actions.setShowMarkAsCollectedModal(false))
      yield put(deliveries.actions.setLoading(false))
      yield call(this.queryService.refetchQueries, [QueryKey.PARCELS])
      yield call(this.queryService.refetchQueries, [QueryKey.PARCEL_DETAILS])
      yield put(
        deliveries.actions.setSelectedInboundParcels({ items: [], action: TableSelectionAction.DESELECTED_ALL }),
      )
    }
  }

  @autobind
  *markAsCollectedOutbound({ payload }: ReturnType<typeof markAsCollectedOutbound>): SagaIterator {
    const { parcelId, courierCallSign, signature } = payload

    yield put(deliveries.actions.setLoading(true))
    const data = new FormData()
    data.append('OutboundParcelId', parcelId)
    data.append('CourierCallSign', courierCallSign)
    data.append('Signature', signature)

    try {
      yield call(this.apiService, '/v3/outbound-parcels/manual-collect', {
        method: 'POST',
        data,
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      yield put(alerts.actions.success({ message: yield translate(`${INTL_PREFIX}.mark-as-collected.success`) }))
    } catch (err) {
      yield put(deliveries.actions.error(yield translate(`${INTL_PREFIX}.default-error`)))
      yield put(reporting.actions.error(err))
    } finally {
      yield put(deliveries.actions.setShowMarkAsCollectedModal(false))
      yield put(deliveries.actions.setLoading(false))
      yield call(this.queryService.refetchQueries, [QueryKey.OUTBOUND_PARCELS])
      yield call(this.queryService.refetchQueries, [QueryKey.OUTBOUND_PARCEL_DETAILS])
    }
  }

  @autobind
  *logNewDelivery({ payload }: ReturnType<typeof logNewDelivery>): SagaIterator {
    const {
      recipientId,
      barcode,
      docId,
      quantity,
      courier,
      trackingNumber,
      parcelTags,
      notes,
      parcelDimensions,
      buildingId,
      parcelLocation,
      dropoffLocation,
    } = payload

    yield put(deliveries.actions.setLoadingNewDelivery(true))
    const data = new ILogNewDeliveryDto()
    data.recipientId = recipientId
    data.buildingId = buildingId
    data.parcelTags = parcelTags
    data.parcelDimensions = parcelDimensions
    data.barcode = barcode
    data.docId = docId
    data.quantity = quantity
    data.courier = courier
    data.trackingNumber = trackingNumber
    data.notes = notes
    data.mailroomLocation = parcelLocation
    data.dropoffLocation = dropoffLocation

    try {
      yield call(this.apiService, '/v3/parcels/manual_log_in', {
        method: 'POST',
        data: instanceToPlain(data),
      })
      yield put(alerts.actions.success({ message: yield translate(`${INTL_PREFIX}.log-new-delivery.success`) }))
      yield call(this.queryService.refetchQueries, [QueryKey.PARCELS])
    } catch (err) {
      yield put(deliveries.actions.error(yield translate(`${INTL_PREFIX}.default-error`)))
      yield put(reporting.actions.error(err))
    } finally {
      yield put(deliveries.actions.setLoadingNewDelivery(false))
    }
  }

  @autobind
  *logNewOutboundParcel({ payload }: ReturnType<typeof logNewOutboundParcel>): SagaIterator {
    const { senderId, recipientName, addressLine, barcode, city, country, courierId, postCode, trackingNumber, notes } =
      payload

    yield put(deliveries.actions.setLoadingNewDelivery(true))
    const data = new ICreateOutboundParcelDto()
    data.senderId = senderId
    data.addressLine = addressLine
    data.recipientName = recipientName
    data.barcode = barcode
    data.city = city
    data.country = country
    data.courierId = courierId
    data.postCode = postCode
    data.trackingNumber = trackingNumber
    data.loggedIn = new Date().toISOString()
    data.notes = notes

    try {
      yield call(this.apiService, '/v3/outbound-parcels/manual-log-in', {
        method: 'POST',
        data: instanceToPlain(data),
      })
      yield put(alerts.actions.success({ message: yield translate(`${INTL_PREFIX}.log-new-outbound-parcel.success`) }))
      yield call(this.queryService.refetchQueries, [QueryKey.OUTBOUND_PARCELS])
    } catch (err) {
      yield put(deliveries.actions.error(yield translate(`${INTL_PREFIX}.default-error`)))
      yield put(reporting.actions.error(err))
    } finally {
      yield put(deliveries.actions.setLoadingNewDelivery(false))
    }
  }

  @autobind
  *updateInboundParcel({ payload }: ReturnType<typeof updateInboundParcel>): SagaIterator {
    yield put(deliveries.actions.setLoading(true))

    try {
      const {
        parcelId,
        barcode,
        courier,
        notes,
        parcelDimensions,
        parcelTags,
        trackingNumber,
        recipientId,
        sessionId,
        deliveredOn,
        collectedOn,
        deliveredById,
        collectedById,
        signatureFile,
        collectionNotes,
        notifyRecipient,
        parcelLocation,
        docId,
        quantity,
        numberOfParcels,
        dropoffLocation,
      } = payload

      const data = new IUpdateParcelDto()
      data.parcelId = parcelId
      data.recipientId = recipientId
      data.sessionId = sessionId
      data.recordModified = new Date().toISOString()
      data.deliveredOn = deliveredOn
      data.deliveredById = deliveredById
      data.collectedOn = collectedOn
      data.collectedById = collectedById
      data.barcode = barcode
      data.trackingNumber = trackingNumber
      data.courier = courier
      data.notes = notes
      data.parcelDimensions = parcelDimensions
      data.tags = parcelTags
      data.collectionNotes = collectionNotes
      data.mailroomLocation = parcelLocation
      data.docId = docId
      data.quantity = quantity
      data.numberOfParcels = numberOfParcels
      data.dropoffLocation = dropoffLocation

      yield call(this.apiService, `/v3_1/parcels/${parcelId}`, {
        method: 'PUT',
        data: instanceToPlain(data),
      })

      if ((signatureFile || collectionNotes) && !collectedOn) {
        const data = new FormData()
        if (signatureFile) data.append('signature', signatureFile)
        if (collectionNotes) data.append('collectionNotes', collectionNotes)
        data.append('parcelIds', parcelId)

        yield call(this.apiService, `/v3/parcels/manual-collect`, {
          method: 'PUT',
          data,
          params: {
            sendNotification: notifyRecipient,
          },
          headers: { 'Content-Type': 'multipart/form-data' },
        })
      }
      yield put(alerts.actions.success({ message: yield translate('deliveries-directory.edit-parcel.success') }))
    } catch (err) {
      yield put(deliveries.actions.error(yield translate(`${INTL_PREFIX}.default-error`)))
      yield put(reporting.actions.error(err))
    } finally {
      yield put(deliveries.actions.setShowMarkAsCollectedModal(false))
      yield put(deliveries.actions.setLoading(false))
      yield call(this.queryService.refetchQueries, [QueryKey.PARCEL_DETAILS])
      yield call(this.queryService.refetchQueries, [QueryKey.PARCELS])
    }
  }

  @autobind
  *moveParcels({ payload }: ReturnType<typeof moveParcels>): SagaIterator {
    yield put(deliveries.actions.setLoading(true))

    try {
      const data = new IMoveParcelsDto()
      const { parcelIds, buildingId, sendNotification, batchActionParams } = payload
      data.parcelIds = parcelIds
      data.destination = buildingId

      if (batchActionParams) {
        yield call(this.apiService, '/v3/parcels/move/batch', {
          method: 'POST',
          data: this.generateBatchActionDto(batchActionParams),
          params: {
            sendNotification,
            destination: buildingId,
          },
        })
      } else {
        yield call(this.apiService, '/v3/parcels/move', {
          method: 'POST',
          data: instanceToPlain(data),
          params: {
            sendNotification,
          },
        })
      }

      yield put(alerts.actions.success({ message: yield translate('deliveries-directory.move-modal.success') }))
      yield call(this.queryService.refetchQueries, [QueryKey.PARCELS])
    } catch (err) {
      if (http.guards.isError(error) && error?.response?.status === HttpStatusCode.NotAcceptable) {
        yield put(deliveries.actions.error(yield translate(`${INTL_PREFIX}.parcels.error.cannot-perform`)))
      } else {
        yield put(alerts.actions.error({ message: yield translate('deliveries-directory.move-modal.error') }))
      }
    } finally {
      yield put(deliveries.actions.setLoading(false))
    }
  }

  @autobind
  *createExternalRecipient({ payload }: ReturnType<typeof createExternalRecipient>): SagaIterator {
    yield put(deliveries.actions.setLoading(true))

    try {
      const { firstName, lastName, email, addressLine1, addressLine2, city, postCode, country } = payload
      const buildingId: number = yield select(security.selectors.developmentId)
      const data = new ICreateExternalRecipientDto()
      data.firstName = firstName
      data.lastName = lastName
      data.email = email
      data.type = 'external'
      data.buildingId = buildingId
      data.addressLine1 = addressLine1
      data.addressLine2 = addressLine2
      data.city = city
      data.postcode = postCode
      data.country = country

      yield call(this.apiService, '/v3/tenants', {
        method: 'POST',
        data: instanceToPlain(data),
      })
      yield put(
        alerts.actions.success({ message: yield translate('deliveries-directory.create-external-recipient.success') }),
      )
      yield call(this.queryService.refetchQueries, [QueryKey.REQUESTOR_RECIPIENTS_AUTOCOMPLETE])
    } catch (error) {
      yield put(reporting.actions.error(error))
      yield put(
        alerts.actions.error({ message: yield translate('deliveries-directory.create-external-recipient.error') }),
      )
    } finally {
      yield put(deliveries.actions.setLoading(false))
    }
  }

  @autobind
  *recipientCreateParcel({ payload }: ReturnType<typeof recipientCreateParcel>): SagaIterator {
    yield put(deliveries.actions.setLoading(true))

    try {
      const {
        recipientId,
        courier,
        notes,
        parcelDimensions,
        parcelTags,
        offloadSite,
        numberOfParcels,
        trackingNumber,
        dropoffLocation,
      } = payload
      const userId: string = yield select(security.selectors.id)
      const data = new IRecipientCreateParcelDto()
      data.senderId = userId
      data.recipientId = recipientId
      data.buildingId = offloadSite
      data.numberOfParcels = numberOfParcels
      data.dropoffLocation = dropoffLocation
      data.parcelTags = parcelTags
      data.parcelDimensions = parcelDimensions
      data.barcode = uuidv4()
      data.courier = courier
      data.trackingNumber = trackingNumber
      data.notes = notes

      const response = yield call(this.apiService, '/v3/parcels/manual-log-in', {
        method: 'POST',
        data: instanceToPlain(data),
      })
      yield put(deliveries.actions.setParcelLabelModal({ isOpen: true, parcelId: response.data.Id }))
      yield put(alerts.actions.success({ message: yield translate(`${INTL_PREFIX}.log-new-delivery.success`) }))
      yield call(this.queryService.refetchQueries, [QueryKey.PARCELS])
    } catch (error) {
      yield put(reporting.actions.error(error))
      yield put(deliveries.actions.error(yield translate(`${INTL_PREFIX}.default-error`)))
    } finally {
      yield put(deliveries.actions.setLoading(false))
    }
  }

  @autobind
  *markAsHeld({ payload }: ReturnType<typeof markAsHeld>): SagaIterator {
    yield put(deliveries.actions.setLoading(true))

    try {
      const { parcelIds, enableNotification, batchActionParams } = payload
      const data = new IMarkParcelsAsHeldDto()
      data.parcelIds = parcelIds

      yield call(this.apiService, batchActionParams ? '/v3/parcels/mark-as-held/batch' : '/v3/parcels/mark-as-held', {
        method: 'POST',
        params: {
          sendNotification: enableNotification,
        },
        data: instanceToPlain(batchActionParams ? this.generateBatchActionDto(batchActionParams) : data),
      })

      yield put(alerts.actions.success({ message: yield translate(`${INTL_PREFIX}.edit-parcel.mark-as-held.success`) }))
    } catch (error) {
      yield put(reporting.actions.error(error))

      if (http.guards.isError(error) && error?.response?.status === HttpStatusCode.MethodNotAllowed) {
        yield put(deliveries.actions.error(yield translate(`${INTL_PREFIX}.cannot-mark-as-held`)))
      } else {
        yield put(deliveries.actions.error(yield translate(`${INTL_PREFIX}.default-error`)))
      }
    } finally {
      yield call(this.queryService.refetchQueries, [QueryKey.PARCEL_DETAILS])
      yield call(this.queryService.refetchQueries, [QueryKey.PARCELS])
      yield put(deliveries.actions.setLoading(false))
    }
  }

  @autobind
  *scheduleReminder({ payload }: ReturnType<typeof scheduleReminder>): SagaIterator {
    const { parcelId } = payload

    try {
      yield call(this.apiService, `/v3/parcels/send-reminder/${parcelId}`, {
        method: 'POST',
      })
      yield put(alerts.actions.success({ message: yield translate(`${INTL_PREFIX}.edit-parcel.reminder.success`) }))
    } catch (error) {
      yield put(reporting.actions.error(error))
      yield put(deliveries.actions.error(yield translate(`${INTL_PREFIX}.default-error`)))
    } finally {
      yield call(this.queryService.refetchQueries, [QueryKey.PARCEL_REMINDER_AVAILABLE])
    }
  }

  @autobind
  *changeParcelTenant({ payload }: ReturnType<typeof changeParcelTenant>): SagaIterator {
    const { tenantId, parcelId } = payload

    try {
      let dto = new IChangeParcelDetailsDto()
      dto.tenantId = tenantId

      yield call(this.apiService, 'v3/parcels/change-recipient', {
        params: { parcel_id: parcelId },
        data: instanceToPlain(dto),
        method: 'POST',
      })

      yield put(
        alerts.actions.success({
          message: yield translate(`${INTL_PREFIX}.edit-parcel.change-recipient-modal.success`),
        }),
      )
    } catch (error) {
      yield put(reporting.actions.error(error))
      yield put(deliveries.actions.error(yield translate(`${INTL_PREFIX}.default-error`)))
    } finally {
      yield call(this.queryService.refetchQueries, [QueryKey.PARCEL_DETAILS, parcelId])
      yield call(this.queryService.refetchQueries, [QueryKey.PARCELS])
    }
  }

  @autobind
  *onError({ payload }: ReturnType<typeof error>): SagaIterator {
    yield put(alerts.actions.error({ message: payload }))
  }

  @autobind
  private generateBatchActionDto(payload: IParcelBatchActionPayload) {
    const data = new IParcelBatchActionDto()
    Object.keys(payload).forEach((key) => {
      data[key] = payload[key]
    })
    return data
  }
}

export default ParcelsSaga
