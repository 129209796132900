import * as React from 'react'

import styled from '@emotion/styled'
import { Image as AntImage } from 'antd'

import * as system from 'styled-system'

import { Property } from 'csstype'
import { IDeepfinityTheme } from 'modules/dna'
import { ResponsiveValue } from 'styled-system'
import { StyledViewProps } from '../Grid/StyledView'

const StyledImage: React.FunctionComponent<any> = styled(AntImage)<StyledViewProps>(
  system.color,
  system.flexbox,
  system.space,
  system.layout,
  system.position,
  system.justifyContent,
  system.alignItems,
  system.borders,
  system.shadow,
  system.background,
)

export type StyledImageProps = React.ComponentProps<typeof StyledImage>

export interface IImagePreviewProps extends Omit<StyledImageProps, 'alt' | 'height' | 'width'> {
  label?: string
  width?: ResponsiveValue<Property.Width<system.TLengthStyledSystem>, IDeepfinityTheme> | undefined
  height?: ResponsiveValue<Property.Width<system.TLengthStyledSystem>, IDeepfinityTheme> | undefined
}

export const ImagePreview: React.FC<IImagePreviewProps> = (props) => {
  const { label, children, ...rest } = props

  return (
    <StyledImage alt={label!!} {...rest}>
      {children}
    </StyledImage>
  )
}

ImagePreview.displayName = 'Image'

ImagePreview.defaultProps = {
  label: '',
}

export default React.memo(ImagePreview)
