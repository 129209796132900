import { AuthAPI, GlobalAPI, IRequestTokenDto } from 'api'
import { instanceToPlain, plainToInstance } from 'class-transformer'
import { IUserServerResolveDto, IUserServerResolveRequestDto, SelectAccountDto, SetPasswordDto } from './dto'

export interface ILoginParams {
  email: string
  password?: string
  accessToken?: string
  userId?: string
}

export interface IResolveServerParams {}

export async function authUser(params: ILoginParams): Promise<IRequestTokenDto | SelectAccountDto[]> {
  const { email, password, userId, accessToken } = params

  const { data } = await AuthAPI({
    method: 'post',
    url: `auth/token/web/${userId ?? ''}`,
    headers: { 'Content-Type': 'application/json' },
    data: JSON.stringify({ email, password, accessToken }),
  })
  return Array.isArray(data) ? plainToInstance(SelectAccountDto, data) : plainToInstance(IRequestTokenDto, data)
}

export async function resolveUserServer(
  hashedEmail: string,
  doubleHashedEmail: string,
): Promise<IUserServerResolveDto> {
  const requestDto = new IUserServerResolveRequestDto()
  requestDto.hashedEmail = hashedEmail
  requestDto.doubleHashedEmail = doubleHashedEmail

  const { data } = await GlobalAPI({
    method: 'post',
    url: 'resolve-server',
    headers: { 'Content-Type': 'application/json' },
    data: instanceToPlain(requestDto),
  })

  return plainToInstance(IUserServerResolveDto, data)
}

export async function userSetupRequest(email: string, password: string, token: string): Promise<any> {
  const requestDto = new SetPasswordDto()
  requestDto.email = email
  requestDto.password = password
  requestDto.token = token

  const { data } = await AuthAPI({
    method: 'post',
    url: 'accounts/set-password',
    headers: { 'Content-Type': 'application/json' },
    data: instanceToPlain(requestDto),
  })

  return data
}
