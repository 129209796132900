export enum OpenPath {
  LOGIN = '/auth/login',
  SIGN_UP = '/auth/signup',
  FORGOT_PASSWORD = '/auth/forgot-password',
  SET_PASSWORD = '/auth/set-password',
  EXTERNAL_LOGIN = '/auth/external-login',
}

export enum PortalPath {
  GET_STARTED = '/portal/get-started',
  RESIDENTS_DIRECTORY = '/portal/residents-directory',
  RECIPIENT_UPLOADER = '/portal/recipient-uploader',
  USERS_MANAGEMENT = '/portal/users-management',
  BUILDINGS_MANAGEMENT = '/portal/buildings-management',
  PROFILE = '/portal/profile',
  NOTIFICATIONS = '/portal/notifications',
  SETTINGS = '/portal/settings',
  BILLING = '/portal/billing',
}
