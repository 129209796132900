import * as React from 'react'

import styled from '@emotion/styled'
import shouldForwardProp from '@styled-system/should-forward-prop'

import { IDeepfinityTheme } from 'modules/dna'
import * as system from 'styled-system'

import { ABSOLUTE_FILL, CENTER } from '../../styles/position'

export const variants = {
  center: CENTER,
  'absolute-fill': ABSOLUTE_FILL,
}

// TODO theme shadows
export type StyledViewProps = system.ColorProps<IDeepfinityTheme> &
  system.FlexboxProps<IDeepfinityTheme> &
  system.LayoutProps<IDeepfinityTheme> &
  system.JustifyContentProps<IDeepfinityTheme> &
  system.AlignItemsProps<IDeepfinityTheme> &
  system.BorderProps<IDeepfinityTheme> &
  system.ShadowProps &
  system.GridGapProps &
  system.GridProps &
  system.PositionProps<IDeepfinityTheme> & {
    flavour?: keyof typeof variants
    role?: React.AriaRole
    cursor?: string
    id?: string
  } & system.SpaceProps<IDeepfinityTheme> & { className?: string }

export const StyledView: React.FunctionComponent<StyledViewProps> = styled('div', { shouldForwardProp })(
  system.color,
  system.flexbox,
  system.grid,
  system.space,
  system.layout,
  system.position,
  system.justifyContent,
  system.alignItems,
  system.borders,
  system.shadow,
  system.gridGap,
  system.background,
  system.variant({ prop: 'flavour', variants }),
  system.system({
    role: true,
    cursor: true,
  }),
)

export default StyledView
