import * as React from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import OnboardingModal from 'modules/module-onboarding/components/OnboardingModal/OnboardingModal'
import PortalContainer from '../../components/PortalContainer/PortalContainer'
import { OpenPath } from '../models'

interface IAuthenticatedRouteProps extends RouteProps {
  isAuthenticated: boolean
  showOnboardingModal: boolean
}

const AuthenticatedRoute: React.FC<IAuthenticatedRouteProps> = (props) => {
  const { isAuthenticated, showOnboardingModal, component: Component, ...rest } = props

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <>
            <PortalContainer /> {showOnboardingModal && <OnboardingModal />}
          </>
        ) : (
          <Redirect to={OpenPath.LOGIN} />
        )
      }
    />
  )
}

export default React.memo(AuthenticatedRoute)
