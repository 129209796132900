import { EuiSearchBar, EuiSwitchEvent } from '@elastic/eui'
import alerts from 'modules/module-alerts'
import { useAppDispatch } from 'modules/module-utils'
import { Grid, Switch, useTranslate } from 'modules/web-atoms'
import { IPopoverButtonProps, PopoverButton } from 'modules/web-molecules'
import * as React from 'react'

export interface ISwitchSelectorItem<T extends string = string> {
  id: T
  label: string
  checked: boolean
}
export interface ISwitchSelectorProps<T extends string = string> extends Omit<IPopoverButtonProps, 'children'> {
  label: string
  searchLabel?: string
  switches: Array<ISwitchSelectorItem<T>>
  onChange: (newSwitches: Array<ISwitchSelectorItem<T>>) => void
  // Always keep a minimum number of switches checked
  minimumSelection?: number
}

export const SwitchSelector = <T extends string = string>({
  searchLabel,
  onChange: onSwitchChanged,
  minimumSelection,
  switches,
  ...rest
}: ISwitchSelectorProps<T>) => {
  const t = useTranslate('web-organisms.switch-selector')
  const dispatch = useAppDispatch()
  const [filter, setFilter] = React.useState('')

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value)
  }

  const onChange = (itemId: T) => (event: EuiSwitchEvent) => {
    const { checked } = event.target

    const numChecked = switches.reduce((acc, item) => (item.checked ? acc + 1 : acc), checked ? 1 : -1)
    const isBelowMinimum = !!(minimumSelection && numChecked < minimumSelection)
    const newSwitches = switches.map((item) => {
      const isChecked = checked ? checked : isBelowMinimum
      return { ...item, checked: item.id === itemId ? isChecked : item.checked }
    })
    if (isBelowMinimum) {
      const message = t('minimum-selection', { minimum: minimumSelection })
      dispatch(alerts.actions.error({ message }))
    }

    onSwitchChanged(newSwitches)
  }

  return (
    <PopoverButton {...rest}>
      <Grid>
        {searchLabel && (
          <Grid.Row>
            <EuiSearchBar
              box={{ placeholder: searchLabel, incremental: true, value: filter, onChange: onSearchChange }}
            />
          </Grid.Row>
        )}

        <Grid.Row
          borderY={searchLabel ? '1px solid' : undefined}
          borderColor="gridLine"
          py={searchLabel ? 'small' : 'macro'}
          className="eui-yScroll"
          maxHeight={200}
        >
          <Grid gutterSize="smallest">
            {switches
              .filter((item) => item.label.toLowerCase().includes(filter.toLowerCase()))
              .map((item) => (
                <Switch
                  key={item.id}
                  id={item.id}
                  label={item.label}
                  checked={item.checked}
                  onChange={onChange(item.id)}
                  compressed
                  showLabel
                />
              ))}
          </Grid>
        </Grid.Row>
      </Grid>
    </PopoverButton>
  )
}

export default React.memo(SwitchSelector) as typeof SwitchSelector
