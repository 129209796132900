import { createReducer } from '@reduxjs/toolkit'
import { initialState } from './models'
import { setLoading } from './actions'

const reducer = createReducer(initialState, (builder) =>
  builder.addCase(setLoading, (state, { payload }) => {
    state.isLoading = payload
  }),
)

export default reducer
