import AsyncLock from 'async-lock'
import autobind from 'autobind-decorator'
import api from 'modules/module-core/api'
import {
  accessTokenStore,
  buildingIdsStore,
  IValueStore,
  refreshTokenStore,
  roleStore,
  userIdStore,
  usernameStore,
} from '../storage'

export interface ITokenService {
  readonly username: IValueStore<string>
  readonly accessToken: IValueStore<string>
  readonly refreshToken: IValueStore<string>
  cycle(): Promise<void>
  dispose(): Promise<void>
  refresh(): Promise<void>
}

export class TokenService implements ITokenService {
  private static readonly LOCK_KEY = 'refresh'

  readonly username: IValueStore<string>
  readonly accessToken: IValueStore<string>
  readonly refreshToken: IValueStore<string>
  readonly role: IValueStore<string>
  readonly userId: IValueStore<string>
  readonly buildingIds: IValueStore<string>

  private readonly lock = new AsyncLock()

  constructor(
    username: IValueStore<string>,
    accessToken: IValueStore<string>,
    refreshToken: IValueStore<string>,
    userId: IValueStore<string>,
    role: IValueStore<string>,
    buildingIds: IValueStore<string>,
  ) {
    this.username = username
    this.accessToken = accessToken
    this.refreshToken = refreshToken
    this.userId = userId
    this.role = role
    this.buildingIds = buildingIds
  }

  @autobind
  async refresh(): Promise<void> {
    const responseData = await api.refresh(await this.refreshToken.get(), await this.userId.get())
    if (responseData) {
      const { accessToken, refreshToken } = responseData

      await Promise.all([this.accessToken.set(accessToken), this.refreshToken.set(refreshToken)])
    }
  }

  @autobind
  async cycle(): Promise<void> {
    return this.lock.isBusy()
      ? await this.lock.acquire(TokenService.LOCK_KEY, () => {})
      : await this.lock.acquire(TokenService.LOCK_KEY, this.refresh)
  }

  @autobind
  async dispose(): Promise<void> {
    await Promise.all([
      this.username.clear(),
      this.accessToken.clear(),
      this.refreshToken.clear(),
      this.userId.clear(),
      this.role.clear(),
      this.buildingIds.clear(),
    ])
  }
}

const tokenService = new TokenService(
  usernameStore,
  accessTokenStore,
  refreshTokenStore,
  userIdStore,
  roleStore,
  buildingIdsStore,
)

export default tokenService
