import { createAction } from '@reduxjs/toolkit'
import prefix from 'modules/utils/prefix'
import { IFormValues } from '../components/ContactFormModal/ContactFormModal'
import { IPlanConfig } from '../services/models'
import { ICheckoutPayload, Currency, Plan, ISetPermissionsPayload, ISetPlanLimitsPayload } from './models'

export const namespaced = prefix('billing')

export const SET_LOADING = namespaced('setLoading')
export const setLoading = createAction<boolean>(SET_LOADING)

export const SET_LOADING_CHECKOUT = namespaced('setLoadingCheckout')
export const setLoadingCheckout = createAction<boolean>(SET_LOADING_CHECKOUT)

export const SET_CURRENT_PLAN = namespaced('setCurrentPlan')
export const setCurrentPlan = createAction<IPlanConfig>(SET_CURRENT_PLAN)

export const SET_RECOMMENDED_PLAN = namespaced('setRecommendedPlan')
export const setRecommendedPlan = createAction<IPlanConfig>(SET_RECOMMENDED_PLAN)

export const ERROR = namespaced('error')
export const error = createAction<any>(ERROR)

export const POLL_BILLING_DATA = namespaced('pollBillingData')
export const pollBillingData = createAction(POLL_BILLING_DATA)

export const FORCE_REFRESH = namespaced('forceRefresh')
export const forceRefresh = createAction(FORCE_REFRESH)

export const SET_SELECTED_PLAN = namespaced('setSelectedPlan')
export const setSelectedPlan = createAction<Plan>(SET_SELECTED_PLAN)

export const SET_HAS_BEEN_PAID_CLIENT = namespaced('setHasBeenPaidClient')
export const setHasBeenPaidClient = createAction<boolean>(SET_HAS_BEEN_PAID_CLIENT)

export const CHECKOUT = namespaced('checkout')
export const checkout = createAction<ICheckoutPayload>(CHECKOUT)

export const SET_IS_TRIAL = namespaced('setIsTrial')
export const setIsTrial = createAction<boolean>(SET_IS_TRIAL)

export const SET_DAYS_LEFT_IN_TRIAL = namespaced('setDaysLeftInTrial')
export const setDaysLeftInTrial = createAction<number | undefined>(SET_DAYS_LEFT_IN_TRIAL)

export const SET_DAYS_LEFT_IN_GRACE_PERIOD = namespaced('setDaysLeftInGracePeriod')
export const setDaysLeftInGracePeriod = createAction<number | undefined>(SET_DAYS_LEFT_IN_GRACE_PERIOD)

export const PROCESS_TRIAL_CONDITION = namespaced('processTrialCondition')
export const processTrialCondition = createAction<number | undefined>(PROCESS_TRIAL_CONDITION)

export const PROCESS_GRACE_PERIOD_CONDITION = namespaced('processGracePeriodCondition')
export const processGracePeriodCondition = createAction<number | undefined>(PROCESS_GRACE_PERIOD_CONDITION)

export const SUBMIT_CONTACT_FORM = namespaced('submitContactForm')
export const submitContactForm = createAction<IFormValues>(SUBMIT_CONTACT_FORM)

export const PAYMENT_DETAILS_REDIRECT = namespaced('paymentDetailsRedirect')
export const paymentDetailsRedirect = createAction(PAYMENT_DETAILS_REDIRECT)

export const SET_PAYMENT_DETAILS_LOADING = namespaced('setPaymentDetailsLoading')
export const setPaymentDetailsLoading = createAction<boolean>(SET_PAYMENT_DETAILS_LOADING)

export const DOWNGRADE_PLAN = namespaced('downgradePlan')
export const downgradePlan = createAction(DOWNGRADE_PLAN)

export const SET_PERMISSIONS = namespaced('setPermissions')
export const setPermissions = createAction<ISetPermissionsPayload>(SET_PERMISSIONS)

export const SET_PLAN_LIMITS = namespaced('setPlanLimits')
export const setPlanLimits = createAction<ISetPlanLimitsPayload>(SET_PLAN_LIMITS)

export const SET_MANUAL_OVERRIDE = namespaced('setManualOverride')
export const setManualOverride = createAction<boolean>(SET_MANUAL_OVERRIDE)

export const SET_CURRENCY = namespaced('setCurrency')
export const setCurrency = createAction<Currency>(SET_CURRENCY)

export const SET_IS_SMS_ENABLED = namespaced('setIsSmsEnabled')
export const setIsSmsEnabled = createAction<boolean>(SET_IS_SMS_ENABLED)
