import React from 'react'

import { EuiToolTip } from '@elastic/eui'
import styled from '@emotion/styled'

import { StyledViewProps } from 'modules/web-atoms'
import * as system from 'styled-system'

export type IToolTipProps = Omit<StyledViewProps, 'position'> &
  React.ComponentProps<typeof EuiToolTip> & {
    disabled?: boolean
  }

export const StyledToolTip = styled(EuiToolTip)<IToolTipProps>(
  system.color,
  system.flexbox,
  system.space,
  system.layout,
  system.position,
  system.justifyContent,
  system.alignItems,
  system.borders,
  system.shadow,
  system.background,
)

export const ToolTip: React.FC<IToolTipProps> = (props) => {
  const { disabled, children, ...rest } = props
  return disabled ? <>{children}</> : <StyledToolTip {...rest}>{children}</StyledToolTip>
}

export default React.memo(ToolTip)
