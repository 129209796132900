import * as React from 'react'

import { EuiAccordion, EuiAccordionProps } from '@elastic/eui'
import styled from '@emotion/styled'

import * as system from 'styled-system'

import { StyledViewProps } from '../Grid/StyledView'

const functionalAccordion: React.FC<EuiAccordionProps> = ({ children, ...rest }) => (
  <EuiAccordion {...rest}>{children}</EuiAccordion>
)

const StyledAccordion = styled(functionalAccordion)<StyledViewProps>(
  system.color,
  system.flexbox,
  system.space,
  system.layout,
  system.position,
  system.justifyContent,
  system.alignItems,
  system.borders,
  system.position,
)

export interface IAccordionProps extends React.ComponentProps<typeof StyledAccordion> {}

export const Accordion: React.FC<IAccordionProps> = (props) => {
  const { children, ...rest } = props

  return <StyledAccordion {...rest}>{children}</StyledAccordion>
}

Accordion.displayName = 'Accordion'

export default React.memo(Accordion)
