import { createAction } from '@reduxjs/toolkit'
import { TenantType } from 'api'

import analytics, { AnalyticalEvent } from 'modules/module-analytics'
import prefix from 'modules/utils/prefix'

import {
  FormState,
  IAddRecipientPayload,
  ISetActiveBuildingsPayload,
  ISetCreateStepPayload,
  ISetPermissionsPayload,
  ISetSelectedItemsPayload,
  IUpdateRecipientPayload,
} from './models'

export const namespaced = prefix('recipients')

// ACTIONS

export const SET_CREATE_STEP = namespaced('setCreateStep')
export const setCreateStep = createAction<ISetCreateStepPayload>(SET_CREATE_STEP)

export const SET_LOADING = namespaced('setLoading')
export const setLoading = createAction<boolean>(SET_LOADING)

export const SET_SELECTED_RECIPIENT = namespaced('setSelectedRecipient')
export const setSelectedRecipient = createAction<string | undefined>(SET_SELECTED_RECIPIENT)

export const SET_IS_CREATE_FORM = namespaced('setIsCreateForm')
export const setIsCreateForm = createAction<boolean>(SET_IS_CREATE_FORM)

export const SET_RECIPIENT_TYPE = namespaced('setRecipientType')
export const setRecipientType = createAction<TenantType | undefined>(SET_RECIPIENT_TYPE)

export const RESET_FORM_STATE = namespaced('resetFormState')
// Resets all form state. This action ought to be dispatched on form submits / cancels or when
// the central state of the form changes (editing vs creating)
export const resetFormState = createAction(RESET_FORM_STATE)

export const ADD_RECIPIENT = namespaced('addRecipient')
export const addRecipient = createAction<IAddRecipientPayload>(ADD_RECIPIENT)

export const UPDATE_RECIPIENT = namespaced('updateRecipient')
export const updateRecipient = createAction<IUpdateRecipientPayload>(UPDATE_RECIPIENT)

export const DELETE_RECIPIENTS = namespaced('deleteRecipients')
export const deleteRecipients = createAction(DELETE_RECIPIENTS)

export const SET_ACTIVE_BUILDINGS = namespaced('setActiveBuildings')
export const setActiveBuildings = createAction<ISetActiveBuildingsPayload>(SET_ACTIVE_BUILDINGS)

export const SET_SELECTED_ITEMS = namespaced('setSelectedItems')
export const setSelectedItems = createAction<ISetSelectedItemsPayload>(SET_SELECTED_ITEMS)

export const SET_DELETION_LOADING = namespaced('setDeletionLoading')
export const setDeletionLoading = createAction<boolean>(SET_DELETION_LOADING)

export const SET_PERMISSIONS = namespaced('setPermissions')
export const setPermissions = createAction<ISetPermissionsPayload>(SET_PERMISSIONS)

export const SET_FORM_STATE = namespaced('setFormState')
export const setFormState = createAction<FormState>(SET_FORM_STATE)

export const SET_VISIBLE_COLUMNS = namespaced('setVisibleColumns')
export const setVisibleColumns = createAction<string[]>(SET_VISIBLE_COLUMNS)

export const ERROR = namespaced('error')
export const error = createAction(ERROR, (payload: string) => ({
  payload,
  meta: analytics.meta.whitelist({
    data: { message: payload },
    name: AnalyticalEvent.Error,
  }),
}))

export const CLEAR_ERROR = namespaced('clearError')
export const clearError = createAction(CLEAR_ERROR)

type Actions = typeof setCreateStep | typeof error | typeof clearError

export default Actions
