import { downloadFile } from 'api'
import API from 'api/clients'
import { QueryKey } from 'modules/module-api'
import useApi from 'modules/module-api/hooks/useApi/use-api'
import { useMemo } from 'react'
import { useActiveBuildings } from '../use-active-buildings/use-active-buildings'

export function useExportRecipients() {
  const { activeBuildings, isFetching: isFetchingBuildings } = useActiveBuildings()
  const buildingIds = useMemo(() => activeBuildings.map((b) => b.id), [activeBuildings])
  const {
    data,
    isFetching: isFetchingFile,
    error,
    refetch,
  } = useApi(
    [QueryKey.RECIPIENTS_EXPORT, buildingIds],
    async () => {
      if (!!buildingIds.length) {
        const response = await API({
          url: '/v3/tenants/export',
          params: {
            development_ids: buildingIds.join(','),
          },
          responseType: 'blob',
        })
        return response
      } else {
        return undefined
      }
    },
    {
      enabled: false,
      cacheTime: 0,
      onSuccess: (response) => {
        if (!!response?.data)
          downloadFile(
            response,
            'Recipients',
            'xlsx',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          )
      },
    },
  )

  return {
    file: data || '',
    isExporting: isFetchingBuildings || isFetchingFile,
    error,
    fetchCSVFile: refetch,
  }
}

export default useExportRecipients
