import * as React from 'react'

import { EuiFieldNumber } from '@elastic/eui'
import styled from '@emotion/styled'

import { StyledViewProps } from 'modules/web-atoms'
import * as system from 'styled-system'
import { isNumber, toNumber } from 'modules/module-utils'

export interface IBaseProps {
  onChange?: (value: number) => void
}

export type IFieldNumberProps = StyledViewProps &
  Omit<React.ComponentProps<typeof EuiFieldNumber>, 'onChange' | 'onInput'>

export const StyledFieldNumber = styled(EuiFieldNumber)<IFieldNumberProps>(
  system.color,
  system.flexbox,
  system.space,
  system.layout,
  system.position,
  system.justifyContent,
  system.alignItems,
  system.borders,
  system.shadow,
  system.background,
)

export const FieldNumber: React.FC<IFieldNumberProps & IBaseProps> = (props) => {
  const { children, onChange, ...rest } = props

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    if (isNumber(value)) onChange?.(toNumber(value))
  }

  return (
    <StyledFieldNumber onChange={handleChange} {...rest}>
      {children}
    </StyledFieldNumber>
  )
}

export default React.memo(FieldNumber)
