import React from 'react'
import RadioCard, { IRadioCardProps } from './RadioCard/RadioCard'
import RadioPlain, { IRadioPlainProps } from './RadioPlain/RadioPlain'
import RadioSimple, { IRadioSimpleProps } from './RadioSimple/RadioSimple'
import { IRadioButtonProps } from './types'

export interface IRadioButton extends React.FunctionComponent<IRadioButtonProps> {
  Card: React.FunctionComponent<IRadioCardProps>
  Plain: React.FunctionComponent<IRadioPlainProps>
  Simple: React.FunctionComponent<IRadioSimpleProps>
}

export const RadioButton: IRadioButton = (props) => <RadioButton.Card {...props} />

RadioButton.Card = (props) => <RadioCard {...props} />
RadioButton.Card.displayName = 'RadioCard'
RadioButton.Plain = (props) => <RadioPlain {...props} />
RadioButton.Plain.displayName = 'RadioPlain'
RadioButton.Simple = (props) => <RadioSimple {...props} />
RadioButton.Simple.displayName = 'RadioSimple'

export default RadioButton
