/* eslint-disable react/display-name */

import { Page, useTranslate } from 'modules/web-atoms'
import * as React from 'react'
import { useSelector } from 'react-redux'
import upload from '../../upload'
import UploadPreview from './UploadPreview/UploadPreview'
import UploadProgress, { Lottie } from './UploadProgress/UploadProgress'

export interface IUploadPageProps {}

export const UploadPage: React.FC<IUploadPageProps> = (props) => {
  const { ...rest } = props
  const t = useTranslate('recipients-upload.upload')

  const uploading = useSelector(upload.selectors.uploading)

  if (!uploading) {
    Lottie.preload()
  }

  return <Page title={t('title')}>{!uploading ? <UploadPreview /> : <UploadProgress />}</Page>
}

export default React.memo(UploadPage)
