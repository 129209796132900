import React from 'react'
import { isElement } from 'modules/module-utils'
import { Grid, IGridProps, useScreen } from 'modules/web-atoms'
import SettingsTabTemplate from '../SettingsTabTemplate/SettingsTabTemplate'

type FCWithChildren = (props: { children?: React.ReactNode }) => React.ReactElement
const Table: FCWithChildren = ({ children }) => <>{children}</>
const Form: FCWithChildren = ({ children }) => <>{children}</>

export interface ISettingsTableFormTemplateProps extends IGridProps {
  heading: string
  description: string
  isFormVisible: boolean
  hasManagePermissions: boolean
  children: React.ReactNode
}
const SettingsTableFormTemplate = (props: ISettingsTableFormTemplateProps) => {
  const { heading, description, isFormVisible, hasManagePermissions, ...rest } = props
  const { isMobile } = useScreen()

  const { table, form } = React.useMemo(() => {
    const children = React.Children.toArray(props.children)

    const table = children.filter(isElement(Table)).map((table) => table.props.children)
    const form = children.filter(isElement(Form)).map((form) => form.props.children)

    return { table, form }
  }, [props.children])

  return (
    <SettingsTabTemplate heading={heading} description={description} flexGrow={1} height="100%" minHeight={0} {...rest}>
      <SettingsTabTemplate.Section flexGrow={1} minHeight={0}>
        <Grid flexGrow={1} minHeight={0} gutterSize={['none', 'large', 'large', 'xxlarge']}>
          {!(isMobile && isFormVisible) && <Grid.Col>{table}</Grid.Col>}

          {hasManagePermissions && isFormVisible && <Grid.Col width="100%">{form}</Grid.Col>}
        </Grid>
      </SettingsTabTemplate.Section>
    </SettingsTabTemplate>
  )
}

export interface ISettingsTableFormTemplate extends React.MemoExoticComponent<typeof SettingsTableFormTemplate> {
  Table: typeof Table
  Form: typeof Form
}

const template: ISettingsTableFormTemplate = Object.assign(React.memo(SettingsTableFormTemplate), {
  type: SettingsTableFormTemplate,
  Table,
  Form,
})

export default template
