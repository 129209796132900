/* eslint-disable no-undef */
import { createAction } from 'redux-actions'

import analytics, { AnalyticalEvent } from 'modules/module-analytics'
import { identityFor } from 'modules/utils'
import prefix from 'modules/utils/prefix'

import { ISetColumnAlignmentPayload, IUploadRecipientsFilePayload } from './'
import {
  IAdjustRowPayload,
  IAlignmentErrorPayload,
  IInvalidFields,
  IRemoveRowsPayload,
  ISetRecipientsFilePayload,
  ISetRecipientsSitePayload,
  IUpdateAlignItemsPayload,
  IUpdateEditablePayload,
  IUpdateUploadPreviewPayload,
  IUpdateValidateItemsPayload,
  IUpdateValidatePagePayload,
  IUploadCompletedUpdatePayload as IUploadFinishedUpdate,
  IUploadProgressPayload,
  IUploadStatusPayload
} from './models'

export const namespaced = prefix('recipients-upload')

export const NAVIGATE_RECIPIENT_UPLOAD = namespaced('navigateRecipientUpload')
export const navigateRecipientUpload = createAction(NAVIGATE_RECIPIENT_UPLOAD, identityFor<string>(), (payload) =>
  analytics.meta.whitelist({
    data: { payload },
    name: AnalyticalEvent.RecipientFileNavigated,
  }),
)

export const UPLOAD_RECIPIENTS_FILE = namespaced('uploadRecipientsFile')
export const uploadRecipientsFile = createAction(
  UPLOAD_RECIPIENTS_FILE,
  identityFor<IUploadRecipientsFilePayload>(),
  (_) =>
    analytics.meta.whitelist({
      name: AnalyticalEvent.AccountType,
    }),
)

export const SET_RECIPIENTS_FILE = namespaced('setRecipientsFile')
export const setRecipientsFile = createAction<ISetRecipientsFilePayload>(SET_RECIPIENTS_FILE)

export const SET_COLUMN_ALIGNMENT = namespaced('setColumnAlignment')
export const setColumnAlignment = createAction<ISetColumnAlignmentPayload>(SET_COLUMN_ALIGNMENT)

export const VALIDATE_FILE = namespaced('validateFile')
export const validateFile = createAction(VALIDATE_FILE)

export const CHECK_FIELD_VALIDATION = namespaced('checkFieldValidation')
export const checkFieldValidation = createAction(CHECK_FIELD_VALIDATION)

export const SET_IS_VALID = namespaced('setIsValid')
export const setIsValid = createAction<boolean>(SET_IS_VALID)

export const UPDATE_ALIGN_ITEMS = namespaced('updateAlignItems')
export const updateAlignItems = createAction<IUpdateAlignItemsPayload>(UPDATE_ALIGN_ITEMS)

export const UPDATE_VALIDATE_PAGE = namespaced('updateValidatePage')
export const updateValidatePage = createAction<IUpdateValidatePagePayload>(UPDATE_VALIDATE_PAGE)

export const ADJUST_ROW = namespaced('adjustRow')
export const adjustRow = createAction<IAdjustRowPayload>(ADJUST_ROW)

export const REMOVE_ROWS = namespaced('removeRows')
export const removeRows = createAction<IRemoveRowsPayload>(REMOVE_ROWS)

export const UPDATE_EDITABLE = namespaced('updateEditable')
export const updateEditable = createAction<IUpdateEditablePayload>(UPDATE_EDITABLE)

export const UPDATE_VALIDATE_ITEMS = namespaced('updateValidateItems')
export const updateValidateItems = createAction<IUpdateValidateItemsPayload>(UPDATE_VALIDATE_ITEMS)

export const FINALISE_UPLOAD = namespaced('finaliseUpload')
export const finaliseUpload = createAction(FINALISE_UPLOAD)

export const SITE_SELECTION_TOGGLE = namespaced('siteSelectionToggle')
export const siteSelectionToggle = createAction<boolean>(SITE_SELECTION_TOGGLE)

export const SET_RECIPIENTS_SITE = namespaced('setRecipientsSite')
export const setRecipientsSite = createAction<ISetRecipientsSitePayload>(SET_RECIPIENTS_SITE)

export const UPLOAD_RECIPIENTS = namespaced('uploadRecipients')
export const uploadRecipients = createAction(UPLOAD_RECIPIENTS)

export const ALIGNMENT_ERROR = namespaced('alignmentError')
export const alignmentError = createAction<IAlignmentErrorPayload>(ALIGNMENT_ERROR)

export const INVALID_FIELDS = namespaced('invalidFields')
export const invalidFields = createAction<IInvalidFields>(INVALID_FIELDS)

export const SET_UPLOAD_PREVIEW = namespaced('setUploadPreview')
export const setUploadPreview = createAction<IUpdateUploadPreviewPayload>(SET_UPLOAD_PREVIEW)

export const GENERATE_UPLOAD_PREVIEW = namespaced('generateUploadPreview')
export const generateUploadPreview = createAction<boolean>(GENERATE_UPLOAD_PREVIEW)

export const UPLOAD_STATUS = namespaced('uploadStatus')
export const uploadStatus = createAction(UPLOAD_STATUS, identityFor<IUploadStatusPayload>(), (payload) =>
  analytics.meta.whitelist({
    data: { payload },
    name: AnalyticalEvent.RecipientFileUploadStatus,
  }),
)

export const UPLOAD_FINISHED_UPDATE = namespaced('uploadFinishedUpdate')
export const uploadFinishedUpdate = createAction<IUploadFinishedUpdate>(UPLOAD_FINISHED_UPDATE)

export const UPLOAD_PROGRESS = namespaced('uploadProgress')
export const uploadProgress = createAction<IUploadProgressPayload>(UPLOAD_PROGRESS)

export const SET_TOTAL = namespaced('setTotal')
export const setTotal = createAction<number>(SET_TOTAL)

export const RESET_UPLOAD = namespaced('resetUpload')
export const resetUpload = createAction(RESET_UPLOAD)

export const SET_NUM_INVALID_ROWS = namespaced('setNumInvalidRows')
export const setNumInvalidRows = createAction<number>(SET_NUM_INVALID_ROWS)

export const INIT = namespaced('init')
export const init = createAction(INIT)

export const LOADING = namespaced('loading')
export const loading = createAction<boolean>(LOADING)

export const SET_IS_SYNCING = namespaced('setIsSyncing')
export const setIsSyncing = createAction<boolean>(SET_IS_SYNCING)

export const SET_IS_WIPE_AVAILABLE = namespaced('setIsWipeAvailable')
export const setIsWipeAvailable = createAction<boolean>(SET_IS_WIPE_AVAILABLE)

export const ERROR = namespaced('error')
export const error = createAction(ERROR, identityFor<Error>(), (payload) =>
  analytics.meta.whitelist({
    data: { message: payload.message },
    name: AnalyticalEvent.Error,
  }),
)

type Action =
  | typeof uploadRecipientsFile
  | typeof setRecipientsFile
  | typeof setColumnAlignment
  | typeof validateFile
  | typeof adjustRow
  | typeof resetUpload
  | typeof loading
  | typeof error

export default Action
