import { DeliveriesPath } from 'modules/module-deliveries'
import { Role } from 'modules/module-security'
import * as React from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import OpenContainer from '../../components/OpenContainer/OpenContainer'
import { PortalPath } from '../models'

interface IUnauthenticatedRouteProps extends RouteProps {
  isAuthenticated: boolean
  role?: Role
}

const UnauthenticatedRoute: React.FC<IUnauthenticatedRouteProps> = (props) => {
  const { isAuthenticated, role, ...rest } = props

  return (
    <Route
      {...rest}
      render={(props) =>
        !isAuthenticated ? (
          <OpenContainer {...props} />
        ) : (
          !!role &&
          (role == Role.USER ? (
            <Redirect to={PortalPath.GET_STARTED} />
          ) : (
            <Redirect to={DeliveriesPath.INBOUND_DIRECTORY} />
          ))
        )
      }
    />
  )
}

export default React.memo(UnauthenticatedRoute)
