import { createSelector, Selector } from 'reselect'
import { BRANCH_KEY } from '../branch'
import { IDeliveriesOwnState } from '../model'
import { IParcelsState } from './models'

export const branch: Selector<IDeliveriesOwnState, IParcelsState> = (state) => state[BRANCH_KEY].parcels

export const isLoading = createSelector(branch, (branch) => branch.loading)

export const isExporting = createSelector(branch, (branch) => branch.isExporting)

export const loadingNewDelivery = createSelector(branch, (branch) => branch.loadingNewDelivery)

export const activeBuildings = createSelector(branch, (branch) => branch.activeBuildings)

export const currentParcel = createSelector(branch, (branch) => branch.currentParcel)

export const showMarkAsCollectedModal = createSelector(branch, (branch) => branch.showMarkAsCollectedModal)

export const permissions = createSelector(branch, (branch) => branch.permissions)

export const selectedInboundParcels = createSelector(branch, (branch) => branch.selectedInboundParcels)

export const selectedOutboundParcels = createSelector(branch, (branch) => branch.selectedOutboundParcels)

export const inboundColumnSelectionIds = createSelector(branch, (branch) => branch.persistent.inboundColumnSelectionIds)

export const outboundColumnSelectionIds = createSelector(
  branch,
  (branch) => branch.persistent.outboundColumnSelectionIds,
)

export const selectedTags = createSelector(branch, (branch) => branch.persistent.selectedTags)

export const parcelLabelModal = createSelector(branch, (branch) => branch.parcelLabelModal)
