import authentication from './authentication'
import reducer from './root-reducer'
import onboardingSagas from './sagas'

export const onboarding = {
  actions: authentication.actions,
  reducer,
  sagas: onboardingSagas,
}

export default onboarding
