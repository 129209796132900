import billing from 'modules/module-billing/billing'
import { createSelector, Selector } from 'reselect'
import { BRANCH_KEY } from '../branch'

import { IDevelopmentsState } from '../model'

import { IDevelopmentState } from './models'

export const branch: Selector<IDevelopmentsState, IDevelopmentState> = (state) => state[BRANCH_KEY]?.development

export const selectedBuildings = createSelector(branch, (branch) => branch?.selectedBuildings)

export const submittedForm = createSelector(branch, (branch) => branch.submittedForm)

export const selectedBuilding = createSelector(branch, (branch) => branch?.selectedBuilding)

export const formState = createSelector(branch, (branch) => branch?.formState)

export const isDeletingBuildings = createSelector(branch, (branch) => branch?.isDeletingBuildings)

export const isDeletingBuilding = createSelector(branch, (branch) => branch?.isDeletingBuilding)

export const isUpdating = createSelector(branch, (branch) => branch?.isUpdating)

export const isCreateForm = createSelector(branch, (branch) => {
  return !branch?.selectedBuilding
})

export const isEditForm = createSelector(branch, (branch) => {
  return !!branch?.selectedBuilding
})

export const selectedBuildingsSize = createSelector(branch, (branch) => {
  if (!!branch?.selectedBuildings?.length) return branch?.selectedBuildings?.length
  if (!!branch?.selectedBuilding) return 1
  return 0
})

export const currentDevelopments = createSelector(branch, (branch) => branch?.currentDevelopments)

export const permissions = createSelector(branch, billing.selectors.isMultiSite, (branch, isMultiSite) => ({
  ...branch.permissions,
  canAddBuildings: branch.permissions.canAddBuildings && isMultiSite,
}))

export const loading = createSelector(branch, (branch) => branch?.loading)

export const error = createSelector(branch, (branch) => branch?.error)
