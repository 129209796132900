import { Buffer, buffers, Channel, channel, SagaIterator, Task } from 'redux-saga'
import { call, join, put, SagaReturnType, spawn } from 'redux-saga/effects'

export interface ISuperviseResult {
  children: Channel<Task>
  parent: Task
}

interface IRecoverOptions {
  children: Channel<Task>
}

export const recover = <Fn extends (...args: any[]) => any>(fn: Fn, ...args: Parameters<Fn>) =>
  call(recover._setup, fn, ...args)

recover._setup = function* <Fn extends (...args: any[]) => any>(
  fn: Fn,
  ...args: Parameters<Fn>
): SagaIterator<ISuperviseResult> {
  const buffer: Buffer<Task> = yield call(buffers.expanding)
  const children: Channel<Task> = yield call(channel, buffer)

  const parent: Task = yield spawn(recover._watch, fn, { children }, ...args)

  return { children, parent }
}

recover._watch = function* <Fn extends (...args: any[]) => any>(
  fn: Fn,
  options: IRecoverOptions,
  ...args: Parameters<Fn>
): SagaIterator<SagaReturnType<Fn>> {
  try {
    while (true) {
      try {
        const task: Task = yield spawn(fn, ...args)

        yield put(options.children, task)

        return yield join(task)
      } catch (error) {
        // noop - handled by supervisors
      }
    }
  } finally {
    yield call(options.children.close)
  }
}

export default recover
