import { AxiosResponse } from 'axios'
import API from './clients'
import { ITenantProfileDto } from './dto/dto'

export async function getCurrentTenant(): Promise<AxiosResponse<ITenantProfileDto>> {
  return API({
    method: 'get',
    url: '/v3/tenants/current',
    params: { include: '*' },
  })
}
