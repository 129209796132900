import React from 'react'

import { EuiSearchBar } from '@elastic/eui'
import styled from '@emotion/styled'

import { StyledViewProps } from 'modules/web-atoms'
import * as system from 'styled-system'

export type ISearchBarProps = StyledViewProps & React.ComponentProps<typeof EuiSearchBar>

export const StyledSearchBar = styled(EuiSearchBar)<ISearchBarProps>(
  system.color,
  system.flexbox,
  system.space,
  system.layout,
  system.position,
  system.justifyContent,
  system.alignItems,
  system.borders,
  system.shadow,
  system.background,
)

export const SearchBar: React.FC<ISearchBarProps> = (props) => {
  return <StyledSearchBar {...props} />
}

export default React.memo(SearchBar)
