import { EuiButton } from '@elastic/eui'
import { useFormik } from 'formik'
import { useCurrentRecipient } from '../../../hooks'
import { Grid, IGridProps, useTranslate } from 'modules/web-atoms'
import { FieldText, FormRow } from 'modules/web-molecules'
import React from 'react'
import { TabPanels } from '../RecipientProfilePage'
import { useAppDispatch, useAppSelector } from 'modules/module-utils'
import profile from '../../../profile'
import moment from 'moment'
import * as Yup from 'yup'

export interface IEditRecipientInfoFormValues {
  firstName: string
  lastName: string
  email: string
}

export interface IEditRecipientInfoTabProps extends IGridProps {}

export const EditRecipientInfoTab: React.FC<IEditRecipientInfoTabProps> = ({ ...rest }) => {
  const t = useTranslate(`recipient-portal.tabs.${TabPanels.MY_INFORMATION}`)
  const dispatch = useAppDispatch()
  const isLoading = useAppSelector(profile.selectors.isLoading)

  const { recipientData, isFetching, error } = useCurrentRecipient()

  const validationSchema = React.useMemo(
    () =>
      Yup.object<IEditRecipientInfoFormValues>().shape({
        firstName: Yup.string().trim().required(t('validation.required')),
        lastName: Yup.string().trim(),
      }),
    [t],
  )

  const onSubmit = (values: IEditRecipientInfoFormValues) => {
    if (recipientData) {
      dispatch(
        profile.actions.updateRecipientProfile({
          recipientId: recipientData.id,
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          alias: recipientData.alias,
          developmentId: recipientData.primarySite,
          gdprComply: recipientData.gdprComply,
          notificationOptions: recipientData.notificationOptions,
          phone: recipientData.phone,
          room: recipientData.room,
          type: recipientData.type,
          recordModified: moment.utc().format(),
        }),
      )
    }
  }

  const { values, touched, errors, setValues, handleChange, submitForm } = useFormik<IEditRecipientInfoFormValues>({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
    },
    validationSchema,
    onSubmit,
  })

  React.useEffect(() => {
    if (!!recipientData) {
      void setValues({
        firstName: recipientData.firstName,
        lastName: recipientData.lastName || '',
        email: recipientData.email,
      })
    }
  }, [recipientData, setValues])

  return (
    <Grid mt="large" {...rest}>
      <FormRow label={t('form.first-name')} touched={touched.firstName} error={errors.firstName}>
        <FieldText
          value={values.firstName}
          name="firstName"
          onChange={handleChange('firstName')}
          isLoading={isFetching}
        />
      </FormRow>

      <FormRow label={t('form.last-name')} touched={touched.lastName} error={errors.lastName} mt="smallest">
        <FieldText value={values.lastName} name="lastName" onChange={handleChange('lastName')} isLoading={isFetching} />
      </FormRow>

      <FormRow label={t('form.email')} touched={touched.email} error={errors.email} isDisabled mt="smallest">
        <FieldText value={values.email} type="email" name="email" disabled isLoading={isFetching} />
      </FormRow>

      <Grid.Row mt="medium">
        <EuiButton onClick={submitForm} color="primary" fill isDisabled={isFetching} isLoading={isLoading}>
          {t('save')}
        </EuiButton>
      </Grid.Row>
    </Grid>
  )
}

export default React.memo(EditRecipientInfoTab)
