import { QueryClient, QueryFilters, QueryKey, RefetchOptions } from '@tanstack/react-query'

import autobind from 'autobind-decorator'

import queryClient from './client'

export interface IQueryService {
  readonly client: QueryClient
  clearCache(): Promise<void>
  refetchQueries(queryKey?: QueryKey, filters?: QueryFilters, options?: RefetchOptions): Promise<void>
}

export class QueryService implements IQueryService {
  readonly client: QueryClient

  constructor(client: QueryClient) {
    this.client = client
  }

  @autobind
  async clearCache(): Promise<void> {
    return await this.client.removeQueries()
  }

  @autobind
  async refetchQueries(queryKey?: QueryKey, filters?: QueryFilters, options?: RefetchOptions): Promise<void> {
    return this.client.refetchQueries(queryKey, filters, options)
  }
}

const queryService = new QueryService(queryClient)

export default queryService
