import * as React from 'react'

import { logError } from 'modules/logging/Sentry'

export const useRecipientFilesParser = () => {
  const [parsedFile, setParsedFile] = React.useState()
  const [error, setError] = React.useState<any>()

  const parseFile = React.useCallback(async (files: FileList) => {
    setError(undefined)

    if (!files.length) {
      console.error('No files selected error', files)
      setError('No files selected')
      return
    }
    const file = files[0]

    const xlsxParser = await import('xls-parser')

    try {
      const data = await xlsxParser
        .onFileSelection(file, { showNullProperties: true, hideEmptyRows: true })
        .catch((err) => {
          throw err
        })

      const parsedData = data
      const keys = Object.keys(parsedData)
      if (keys.length) {
        setParsedFile(parsedData[keys[0]])
      } else {
        setError('No data found')
      }
    } catch (err) {
      setError(err)
      logError(err)
    }
  }, [])

  return [parseFile, parsedFile, error]
}

export default useRecipientFilesParser
