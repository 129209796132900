/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, useTheme } from '@emotion/react'
import { ResponsiveColor } from 'modules/dna'
import * as React from 'react'
import Button from '../../buttons/Button/Button'
import { IButtonFrameProps } from '../../buttons/ButtonFrame/ButtonFrame'
import Grid from '../../Grid/Grid'
import { StyledViewProps } from '../../Grid/StyledView'
import { IRadioButtonProps } from '../types'

interface IRadioStyle {
  radio: {
    backgroundColor: ResponsiveColor
  }
  container: {
    backgroundColor: ResponsiveColor
  }
}

const RADIO_STYLE = new Map<boolean, IRadioStyle>([
  [
    true,
    {
      radio: { backgroundColor: 'primaryMid' },
      container: { backgroundColor: 'secondaryMid' },
    },
  ],
  [
    false,
    {
      radio: { backgroundColor: 'secondaryMid' },
      container: { backgroundColor: 'primaryBackground' },
    },
  ],
])

export type IRadioCardProps = IRadioButtonProps &
  IButtonFrameProps & {
    radioStyle?: StyledViewProps
    containerStyle?: StyledViewProps
  }

export const RadioCard: React.FC<IRadioCardProps> = ({
  id,
  selected,
  isSelected,
  children,
  onClick,
  onPressIn,
  onPressOut,
  legend,
  contentContainerStyles,
  radioStyle,
  containerStyle,
  ...rest
}) => {
  const { radio, container } = React.useMemo(() => {
    return RADIO_STYLE.get(selected ?? false)!!
  }, [selected])
  const { colors } = useTheme()

  const handlers = {
    onClick: React.useCallback(() => onClick?.(id), [id, onClick]),
    onMouseDown: React.useCallback(() => onPressIn?.(id), [id, onPressIn]),
    onMouseUp: React.useCallback(() => onPressOut?.(id), [id, onPressOut]),
  }

  return (
    <Grid
      position="relative"
      flexGrow={1}
      alignItems="center"
      marginX={['none', 'none', 'small']}
      height="100%"
      gutterSize="none"
      {...containerStyle}
      aria-label="radio"
    >
      <Button.Frame
        borderRadius={['xlarge', 'xxlarge']}
        boxShadow="medium"
        css={{
          ':focus-visible': {
            border: `3px ${colors.primaryMid} solid`,
          },
        }}
        height="100%"
        {...rest}
        {...container}
        {...handlers}
      >
        <Grid
          marginLeft="small"
          marginTop="none"
          width={[28, 30]}
          height={[28, 30]}
          border="8px"
          borderRadius="round"
          alignSelf="flex-start"
          justifySelf="flex-start"
          gutterSize="none"
          {...radio}
          {...radioStyle}
        />

        <Grid
          marginX={['auto', 'medium']}
          marginY="macro"
          justifySelf="center"
          alignSelf="center"
          justifyContent="center"
          alignItems="center"
          width={['100%', legend ? 180 : 'fit-content']}
          height={legend ? [150, 180] : '100%'}
          paddingX="smallest"
          gutterSize="none"
          {...contentContainerStyles}
        >
          {children}
        </Grid>
      </Button.Frame>
      {legend && <Grid marginTop="small">{legend}</Grid>}
    </Grid>
  )
}

export default React.memo(RadioCard)
