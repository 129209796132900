import { API } from 'api'
import { QueryService } from 'modules/module-api'
import { all, fork } from 'redux-saga/effects'
import geoService from '../services/GeoService'
import indexedService from '../storage/indexed/IndexedService'
import reduxPersist from '../storage/redux-persist/redux-persist'
import { SessionSaga } from './SessionSaga'

export default function* securitySagas(queryService: QueryService) {
  yield all([fork(new SessionSaga(API, queryService, geoService, reduxPersist, indexedService).supervise)])
}
