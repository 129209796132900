import * as React from 'react'
import { useDispatch } from 'react-redux'

import { EuiStepsHorizontal } from '@elastic/eui'
import { EuiStepHorizontalProps } from '@elastic/eui/src/components/steps/step_horizontal'

import recipientsUpload from 'modules/module-recipient-upload'
import { noop } from 'modules/utils'
import { Grid, Page, useTranslate } from 'modules/web-atoms'
import { InsufficientAccess, Panel } from 'modules/web-molecules'

import { useAppSelector } from 'modules/module-utils'
import recipients from '../../recipients'
import upload from '../../upload'
import uploadFlow from '../../upload/flow'

export interface IUploadRecipientsPageStateProps {}

export interface IUploadRecipientsPageDispatchProps {}

export type IUploadRecipientsPageProps = IUploadRecipientsPageDispatchProps & IUploadRecipientsPageStateProps

export const UploadRecipientsPage: React.FC<IUploadRecipientsPageProps> = (props) => {
  const t = useTranslate('recipients.upload')
  const { ...rest } = props

  const dispatch = useDispatch()
  const currentScreen = useAppSelector(upload.selectors.currentScreen)
  const { canCreateRecipient } = useAppSelector(recipients.selectors.permissions)

  React.useEffect(() => {
    dispatch(recipientsUpload.actions.init())
  }, [dispatch])

  const screen = React.useMemo(() => {
    const node = uploadFlow.find((n) => n.id == currentScreen)
    const Component = node?.component
    return Component ? <Component /> : undefined
  }, [currentScreen])

  const steps = React.useMemo(() => {
    let foundCurrentScreen = false
    return uploadFlow.map<Omit<EuiStepHorizontalProps, 'step'>>((node) => {
      const isCurrentScreen = node.id == currentScreen
      if (isCurrentScreen) foundCurrentScreen = true
      const status = isCurrentScreen ? undefined : foundCurrentScreen ? 'incomplete' : 'complete'
      const isComplete = isCurrentScreen ? undefined : foundCurrentScreen ? false : true

      return {
        isSelected: isCurrentScreen,
        isComplete,
        status,
        onClick: noop,
      }
    })
  }, [currentScreen])

  return (
    <Page title={t('title')}>
      {canCreateRecipient ? (
        <Grid height={['100%', '95vh']} width="100%" overflow="hidden">
          <Panel padding="none" paddingBottom="medium" className="eui-yScroll" {...rest}>
            <Grid height="100%">
              <Grid.Row marginTop="large" alignSelf="center" maxWidth={700}>
                <EuiStepsHorizontal steps={steps} />
              </Grid.Row>
              {screen}
            </Grid>
          </Panel>
        </Grid>
      ) : (
        <InsufficientAccess />
      )}
    </Page>
  )
}

export default React.memo(UploadRecipientsPage)
