import { SagaIterator } from 'redux-saga'
import { all, call, put, select, takeLatest, takeLeading } from 'redux-saga/effects'

import { ICreateUserDto } from 'api'
import autobind from 'autobind-decorator'
import { AxiosInstance } from 'axios'
import { instanceToPlain } from 'class-transformer'
import notification from 'modules/module-alerts/alert'
import { IQueryService, QueryKey } from 'modules/module-api'
import { translate } from 'modules/module-intl'
import reporting from 'modules/module-reporting'
import { SupervisorSuite } from 'modules/redux-supervisor'

import orchestration from 'modules/module-orchestration'
import security from 'modules/module-security'
import { TableSelectionAction } from 'modules/web-molecules'
import { permissions } from '../permissions/Permissions'
import user, { FormState } from '../user'
import { addUser, selectedUser, selectedUsers, setPermissions, updateUser } from '../user/actions'

export class UsersSaga extends SupervisorSuite {
  private readonly apiService: AxiosInstance
  private readonly queryService: IQueryService

  constructor(apiService: AxiosInstance, queryService: IQueryService) {
    super()
    this.apiService = apiService
    this.queryService = queryService
  }

  @autobind
  *start(): SagaIterator {
    yield all([
      takeLatest(orchestration.actions.DETERMINE_USER_PERMISSIONS, this.determineUserPermissions),
      takeLeading(user.actions.ADD_USER, this.onAddUser),
      takeLeading(user.actions.UPDATE_USER, this.onUpdateUser),
      takeLeading(user.actions.DELETE_USER, this.onDeleteUser),
      takeLeading(user.actions.DELETE_USERS, this.onDeleteUsers),
      takeLatest(user.actions.SELECTED_USER, this.onSelectedUser),
      takeLatest(user.actions.SELECTED_USERS, this.onSelectedUsers),
    ])
  }

  @autobind
  *determineUserPermissions(): SagaIterator {
    const userType = yield select(security.selectors.type)
    yield put(setPermissions({ userType }))
  }

  @autobind
  *onAddUser({ payload }: ReturnType<typeof addUser>): SagaIterator {
    yield put(user.actions.updateLoading(true))
    yield put(user.actions.setSubmittedForm(false))
    try {
      const data = new ICreateUserDto()
      data.firstName = payload.firstName.trim()
      data.lastName = payload.lastName.trim()
      data.email = payload.email.trim().toLowerCase()
      data.type = payload.type
      data.developmentIds = payload.access

      yield call(this.apiService, '/v3/portal/users', {
        method: 'post',
        data: instanceToPlain(data),
      })

      yield put(user.actions.setSubmittedForm(true))
      const message = yield translate('users-directory.form.notification.created')
      yield put(notification.actions.showSuccess({ message }))
    } catch (error) {
      const message = yield translate('users-directory.form.notification.created.error')
      yield put(notification.actions.showError({ message }))
      yield put(reporting.actions.error(error))
    } finally {
      yield put(user.actions.updateLoading(false))
      yield call(this.queryService.refetchQueries, [QueryKey.USERS])
      yield call(this.queryService.refetchQueries, [QueryKey.ALL_USERS])
    }
  }

  @autobind
  *onUpdateUser({ payload }: ReturnType<typeof updateUser>): SagaIterator {
    yield put(user.actions.updateLoading(true))
    try {
      const data = new ICreateUserDto()
      data.id = payload.id
      data.firstName = payload.firstName.trim()
      data.lastName = payload.lastName.trim()
      data.email = payload.email.trim().toLowerCase()
      data.type = payload.type
      data.developmentIds = payload.access

      yield call(this.apiService, `/v3/portal/users/${payload.id}`, {
        method: 'put',
        data: instanceToPlain(data),
      })

      const message = yield translate('users-directory.form.notification.updated')
      yield put(notification.actions.showSuccess({ message }))
    } catch (error) {
      const message = yield translate('users-directory.form.notification.updated.error')
      yield put(notification.actions.showError({ message }))
      yield put(reporting.actions.error(error))
    } finally {
      yield put(user.actions.updateLoading(false))
      yield call(this.queryService.refetchQueries, [QueryKey.USERS])
      yield call(this.queryService.refetchQueries, [QueryKey.ALL_USERS])
      yield call(this.queryService.refetchQueries, [QueryKey.FETCH_USER, payload.id] as any)
    }
  }

  @autobind
  *onDeleteUsers(): SagaIterator {
    yield put(user.actions.deleteUsersLoading(true))

    try {
      const selectedUsers: string[] = yield select(user.selectors.selectedUsers)
      const selectedUser: string = yield select(user.selectors.selectedUser)
      const usersIds = selectedUsers.length ? selectedUsers : [selectedUser]

      yield all(
        usersIds.map((userId) =>
          call(this.apiService, `/v3/users/${userId}`, {
            method: 'DELETE',
          }),
        ),
      )

      const message = yield translate('users-directory.table.notification.deleted')
      yield put(notification.actions.showSuccess({ message }))
    } catch (err) {
      const message = yield translate('users-directory.table.notification.deleted.error')
      yield put(notification.actions.showError({ message }))
      yield put(reporting.actions.error(err))
    } finally {
      yield put(user.actions.setSelectedUsers({ items: [], action: TableSelectionAction.DESELECTED_ALL }))
      yield put(user.actions.setSelectedUser({ user: undefined }))
      yield put(user.actions.setFormState(FormState.HIDDEN))

      yield call(this.queryService.refetchQueries, [QueryKey.USERS])
      yield call(this.queryService.refetchQueries, [QueryKey.ALL_USERS])
      yield put(user.actions.deleteUsersLoading(false))
    }
  }

  @autobind
  *onDeleteUser(): SagaIterator {
    yield put(user.actions.deleteUsersLoading(true))

    try {
      const userId = yield select(user.selectors.selectedUser)

      yield call(this.apiService, `/v3/users/${userId}`, {
        method: 'DELETE',
      })

      const message = yield translate('users-directory.table.notification.deleted')
      yield put(notification.actions.showSuccess({ message }))
    } catch (err) {
      const message = yield translate('users-directory.table.notification.deleted.error')
      yield put(notification.actions.showError({ message }))
      yield put(reporting.actions.error(err))
    } finally {
      yield put(user.actions.setSelectedUsers({ items: [], action: TableSelectionAction.DESELECTED_ALL }))
      yield put(user.actions.setSelectedUser({ user: undefined }))
      yield put(user.actions.setFormState(FormState.HIDDEN))

      yield call(this.queryService.refetchQueries, [QueryKey.USERS])
      yield call(this.queryService.refetchQueries, [QueryKey.ALL_USERS])
      yield put(user.actions.deleteUsersLoading(false))
    }
  }

  @autobind
  *onSelectedUser({ payload }: ReturnType<typeof selectedUser>): SagaIterator {
    try {
      if (!!payload.user) {
        const currentAccess = yield select(security.selectors.type)
        const canEdit = yield call(permissions.canEditUser, currentAccess, payload.user.type)

        if (canEdit) {
          yield put(user.actions.setSelectedUser({ user: payload.user.id }))
        } else {
          const message = yield translate('users-directory.form.notification.access.error')
          yield put(notification.actions.showError({ message }))
        }
      } else {
        yield put(user.actions.setSelectedUser({ user: undefined }))
      }
    } catch (err) {
      const message = yield translate('commons.alert.generic-error')
      yield put(notification.actions.showError({ message }))
      yield put(reporting.actions.error(err))
    }
  }

  @autobind
  *onSelectedUsers({ payload }: ReturnType<typeof selectedUsers>): SagaIterator {
    try {
      const { items, action } = payload

      const currentAccess = yield select(security.selectors.type)
      const filteredUsers = items
        .filter((selectedUser) => permissions.canEditUser(currentAccess, selectedUser.type))
        .map((selectedUser) => selectedUser.id)

      yield put(user.actions.setSelectedUsers({ items: filteredUsers, action }))
    } catch (err) {
      const message = yield translate('commons.alert.generic-error')
      yield put(notification.actions.showError({ message }))
      yield put(reporting.actions.error(err))
    }
  }
}

export default UsersSaga
