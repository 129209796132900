import reducer from './root-reducer'
import recipientsSagas from './sagas'
import upload from './upload'
import recipients from './recipients'

export * from './screens'

export const recipientsModule = {
  actions: {
    ...upload.actions,
    ...recipients.actions,
  },
  selectors: {
    ...upload.selectors,
    ...recipients.selectors,
  },
  reducer,
  sagas: recipientsSagas,
}

export default recipientsModule
