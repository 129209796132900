/* eslint-disable react/display-name */
import lazy from '@loadable/component'
import { deliveriesRoutes } from 'modules/module-deliveries'
import { IModuleRoute } from 'modules/module-navigation'
import security, { Role } from 'modules/module-security'
import { statisticsRoutes } from 'modules/module-statistics'
import { useAppSelector } from 'modules/module-utils'
import * as React from 'react'
import { OpenPath, PortalPath } from '../models'

const Notifications = lazy(async () => import('modules/module-notifications/screens/NotificationsContainer/NotificationsContainer'))
const ProfilePage = lazy(async () => import('modules/module-account/screens/ProfilePage/ProfilePage'))
const BillingPage = lazy(async () => import('modules/module-billing/screens/BillingPage/BillingPage'))
const GetStarted = lazy(async () => import('modules/module-dashboard/screens/GetStarted/GetStartedPage'))
const ExternalLogin = lazy((async () => import('modules/module-onboarding/screens/ExternalLogin/ExternalLogin')))
const ForgotPasswordPage = lazy(async () => import('modules/module-onboarding/screens/ForgotPassword/ForgotPasswordPage'))
const Login = lazy((async () => import('modules/module-onboarding/screens/Login/LoginPage')))
const Signup = lazy(async () => import('modules/module-onboarding/screens/Signup/SignupPage'))
const SetPassword = lazy((async () => import('modules/module-onboarding/screens/UserSetup/UserSetupPage')))
const RecipientsDirectory = lazy((async () => import('modules/module-recipients/screens/RecipientsDirectory/RecipientsDirectory')))
const RecipientProfilePage = lazy((async () => import('modules/module-recipients/screens/RecipientProfilePage/RecipientProfilePage')))
const SettingsPage = lazy((async () => import('modules/module-settings/screens/SettingsPage/SettingsPage')))
const UploadRecipientsPage = lazy(async () => import('modules/module-recipients/screens/UploadRecipientsPage/UploadRecipientsPage'))
const BuildingsPage = lazy(async () => import('modules/module-developments/screens/BuildingsPage/BuildingsPage'))
const UsersPage = lazy(async () => import('modules/module-users/screens/UsersPage/UsersPage'))

function useRoutes() {
  const role = useAppSelector(security.selectors.role)

  const unauthenticatedRoutes: IModuleRoute = React.useMemo(() => {
    return {
      path: 'auth',
      routes: [
        {
          path: OpenPath.LOGIN,
          page: Login,
        },
        {
          path: OpenPath.SIGN_UP,
          page: Signup,
        },
        {
          path: OpenPath.FORGOT_PASSWORD,
          page: ForgotPasswordPage,
        },
        {
          path: OpenPath.SET_PASSWORD,
          page: SetPassword,
        },
        {
          path: OpenPath.EXTERNAL_LOGIN,
          page: ExternalLogin,
        },
      ],
    }
  }, [])

  const authenticatedRoutes: IModuleRoute = React.useMemo(() => {
    return {
      path: 'portal',
      routes: [
        {
          path: PortalPath.GET_STARTED,
          page: GetStarted,
          role: Role.USER,
        },
        {
          path: PortalPath.RESIDENTS_DIRECTORY,
          page: RecipientsDirectory,
          role: Role.USER,
        },
        {
          path: PortalPath.USERS_MANAGEMENT,
          page: UsersPage,
          role: Role.USER,
        },
        {
          path: PortalPath.BUILDINGS_MANAGEMENT,
          page: BuildingsPage,
          role: Role.USER,
        },
        {
          path: PortalPath.PROFILE,
          page: role == Role.USER ? ProfilePage : RecipientProfilePage,
        },
        {
          path: PortalPath.NOTIFICATIONS,
          page: Notifications,
          role: Role.USER,
        },
        {
          path: PortalPath.RECIPIENT_UPLOADER,
          page: UploadRecipientsPage,
          role: Role.USER,
        },
        {
          path: PortalPath.SETTINGS,
          page: SettingsPage,
          role: Role.USER,
        },
        {
          path: PortalPath.BILLING,
          page: BillingPage,
          role: Role.USER,
        },
        statisticsRoutes,
        deliveriesRoutes,
      ],
    }
  }, [role])

  return { unauthenticatedRoutes, authenticatedRoutes }
}

export default useRoutes
