import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { TimelineIconColor, TimelineIconType, TimelineLineType } from './TimelineItem'

export interface IIconContainerProps {
  lineType: TimelineLineType
}
export const IconContainer = styled.div<IIconContainerProps>`
  display: flex;
  position: relative;
  flex-grow: 0;
  justify-content: center;
  align-items: flex-start;
  height: 100%;

  ::before {
    content: '';
    position: absolute;
    inset-block-start: 0px;
    inline-size: 2px;
    background-color: ${(props) => props.theme.colors.primaryMid};

    ${(props) =>
      props.lineType !== 'none' &&
      css`
        block-size: calc(100% + 24px);
      `};

    ${(props) =>
      props.lineType === 'halfway' &&
      css`
        background: ${`linear-gradient(to bottom, ${props.theme.colors.primaryMid} 0%, ${props.theme.colors.primaryMid} 60%, ${props.theme.colors.secondaryDark} 60%)`};
      `};

    ${(props) =>
      props.lineType === 'dotted' &&
      css`
        background: transparent;
        border-left: 2px dotted ${props.theme.colors.secondaryDark};
      `};

    ${(props) =>
      props.lineType === 'lightSolid' &&
      css`
        background-color: ${props.theme.colors.secondaryDark};
      `}
  }
`

export interface ITimelineIconProps {
  iconType: TimelineIconType
  iconColor: TimelineIconColor
}
export const TimelineIcon = styled.div<ITimelineIconProps>`
  border: 2px solid
    ${(props) => (props.iconColor === 'default' ? props.theme.colors.primaryMid : props.theme.colors.secondaryDark)};
  border-radius: 100%;
  position: relative;
  width: 16px;
  height: 16px;
  background-color: ${(props) => props.theme.colors.primaryBackground};

  ${(props) =>
    (props.iconType === 'large' || props.iconType === 'largeDotted') &&
    css`
      width: 24px;
      height: 24px;
    `}
  ${(props) =>
    props.iconType === 'largeDotted' &&
    css`
      ::before {
        content: '';
        display: block;
        position: absolute;
        width: 16px;
        height: 16px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 100%;
        background-color: ${props.iconColor === 'default'
          ? props.theme.colors.primaryMid
          : props.theme.colors.secondaryDark};
      }
    `};
`
