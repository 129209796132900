import * as React from 'react'

import { ResponsiveColor } from 'modules/dna'
import { Grid, IGridProps, Paragraph } from 'modules/web-atoms'

export interface ITabelLableProps extends IGridProps {
  labelText?: string
  labelColour?: ResponsiveColor
  fontColor: ResponsiveColor
}

export const TableLabel: React.FC<ITabelLableProps> = (props) => {
  const { labelText, labelColour, fontColor, ...rest } = props

  return (
    <Grid {...rest}>
      <Grid.Col>
        <Grid borderRadius="small" backgroundColor={labelColour} width={25} height={25} />
      </Grid.Col>
      <Grid.Col>
        <Paragraph color={fontColor}>{labelText}</Paragraph>
      </Grid.Col>
    </Grid>
  )
}

TableLabel.defaultProps = {
  fontColor: 'textDark',
}

export default React.memo(TableLabel)
