import TagItem, { TagItemMode } from '../../../components/TagItem/TagItem'
import { DynamicGrid, IDynamicGridProps } from 'modules/web-atoms'
import React from 'react'
import { EuiLoadingSpinner } from '@elastic/eui'
import { useAppSelector } from 'modules/module-utils'
import settings from '../../../settings'
import useTags from '../../../hooks/use-tags/use-tags'

export interface ITagsGridProps extends Omit<IDynamicGridProps, 'children'> {}

const TAG_MIN_HEIGHT = 130
const TAG_FORM_HEIGHT = 285

const TagsList: React.FC<ITagsGridProps> = ({ ...rest }) => {
  const showAddTagForm = useAppSelector(settings.selectors.showAddTagForm)
  const { tags, isLoading, error } = useTags()
  const sortedTags = React.useMemo(() => [...tags].sort((a, b) => (a.clientId === null ? 1 : -1)), [tags])
  const editingTagIds = useAppSelector(settings.selectors.editingTagIds)

  return (
    <DynamicGrid
      className="eui-yScrollWithShadows"
      width="100%"
      minHeight={0}
      gridTemplateColumns="repeat(auto-fit, 300px)"
      gridTemplateRows={`repeat(auto-fit, ${TAG_MIN_HEIGHT}px)`}
      gridGap="medium"
      padding="smallest"
      justifyContent={['center', 'center', 'left']}
      {...rest}
    >
      {isLoading ? (
        <DynamicGrid.Item
          gridColumn="1 / auto-fill"
          gridRow="1 / auto-fill"
          justifyContent="center"
          alignItems="center"
        >
          <EuiLoadingSpinner size="xl" />
        </DynamicGrid.Item>
      ) : (
        <>
          {showAddTagForm && (
            <DynamicGrid.Item minHeight={TAG_MIN_HEIGHT} height={TAG_FORM_HEIGHT} gridRow="span 2">
              <TagItem mode={TagItemMode.CREATE} />
            </DynamicGrid.Item>
          )}
          {sortedTags.map((tag) => {
            const showForm = editingTagIds.includes(tag.id)
            return (
              <DynamicGrid.Item
                key={tag.id}
                minHeight={TAG_MIN_HEIGHT}
                height={showForm ? TAG_FORM_HEIGHT : undefined}
                gridRow={showForm ? 'span 2' : undefined}
              >
                <TagItem tag={tag} />
              </DynamicGrid.Item>
            )
          })}
        </>
      )}
    </DynamicGrid>
  )
}

export default React.memo(TagsList)
