import { IDevelopmentDto, IOutboundParcelRowItem, IParcelRowItem, UserType } from 'api'
import { Role } from 'modules/module-security'
import { TableSelectionAction } from 'modules/web-molecules'
import { IFormValues as IMarkAsCollectedValues } from '../components/MarkAsCollectedModal/MarkAsCollectedModal'
import { OutboundSortFields } from '../hooks/use-outbound-parcels/use-outbound-parcels'
import { InboundSortFields } from '../hooks/use-parcels/use-parcels'
import { IFormValues as ILogNewDeliveryValues } from '../screens/DeliveriesDirectory/LogDeliveryModal/LogDeliveryModal'
import { IFormValues as ILogNewOutboundParcelValues } from '../screens/OutboundDeliveriesDirectory/LogNewParcelModal/LogNewParcelModal'
import { ParcelDirection, ParcelStatus } from '../screens/models'

export interface IPermissions {
  canViewParcels: boolean
  canViewCollectedParcels: boolean
  canViewOutbound: boolean
  canEditParcels: boolean
  canCreateParcels: boolean
  canFilterParcelTypes: boolean
  canCreateRequestorParcels: boolean
  canCollectParcels: boolean
  canExportParcels: boolean
}

export interface IParcelsState {
  activeBuildings: IDevelopmentDto[]
  currentParcel?: IParcelRowItem | IOutboundParcelRowItem
  loading: boolean
  error?: string
  isExporting: boolean
  loadingNewDelivery: boolean
  showMarkAsCollectedModal: boolean
  permissions: IPermissions
  selectedInboundParcels: string[]
  selectedOutboundParcels: string[]
  parcelLabelModal: {
    isOpen: boolean
    parcelId: string | undefined
  }
  persistent: {
    inboundColumnSelectionIds: string[]
    selectedTags: number[]
    outboundColumnSelectionIds: string[]
  }
}

export const initialState: IParcelsState = {
  activeBuildings: [],
  loading: false,
  currentParcel: undefined,
  error: undefined,
  isExporting: false,
  loadingNewDelivery: false,
  showMarkAsCollectedModal: false,
  selectedInboundParcels: [],
  selectedOutboundParcels: [],
  parcelLabelModal: {
    isOpen: false,
    parcelId: undefined,
  },
  persistent: {
    inboundColumnSelectionIds: Object.values(InboundSortFields),
    outboundColumnSelectionIds: Object.values(OutboundSortFields),
    selectedTags: [],
  },
  permissions: {
    canViewParcels: false,
    canViewCollectedParcels: false,
    canViewOutbound: false,
    canEditParcels: false,
    canCollectParcels: false,
    canCreateParcels: false,
    canFilterParcelTypes: false,
    canCreateRequestorParcels: false,
    canExportParcels: false,
  },
}

export interface IMarkAsCollectedPayload extends IMarkAsCollectedValues {
  parcelIds: string[]
  signature?: File
}

export interface IMarkAsCollectedOutboundPayload {
  parcelId: string
  courierCallSign: string
  signature: File
}

export type ILogNewDeliveryPayload = ILogNewDeliveryValues

export interface IUpdateInboundParcelPayload {
  parcelId: string
  recipientId: string
  sessionId: string
  deliveredOn: string | undefined
  collectedOn: string | undefined
  deliveredById: string | undefined
  collectedById: string | undefined
  barcode?: string
  courier?: string
  trackingNumber?: string
  parcelTags?: number[]
  parcelDimensions?: string
  notes?: string
  signatureFile?: File
  collectionNotes?: string
  notifyRecipient?: boolean
  parcelLocation?: string
  dropoffLocation?: string
  docId?: string
  quantity?: number
  numberOfParcels?: number
}

export interface ISetPermissionsPayload {
  userType: UserType
  userRole: Role
  isOutboundEnabled?: boolean
  isRequestorFormEnabled?: boolean
}

export interface ISetSelectedItemsPayload {
  items: string[]
  action: TableSelectionAction
}

export type ILogNewOutboundParcelPayload = ILogNewOutboundParcelValues

export interface IMoveParcelsPayload {
  parcelIds: string[]
  buildingId: number
  sendNotification: boolean
}

export interface ICreateExternalRecipientPayload {
  firstName: string
  lastName: string
  email?: string
  addressLine1: string
  addressLine2?: string
  city?: string
  postCode?: string
  country?: string
}

export interface IRecipientCreateParcelPayload {
  recipientId: string
  courier?: string
  trackingNumber?: string
  parcelTags?: number[]
  notes?: string
  parcelDimensions?: string
  offloadSite: number
  numberOfParcels: number
  dropoffLocation?: string
}

export interface IScheduleReminderPayload {
  parcelId: string
}

export interface IMarkAsHeldPayload {
  parcelIds: string[]
  enableNotification: boolean
}

export interface IParcelLabelModalPayload {
  isOpen: boolean
  parcelId: string | undefined
}

export interface IParcelBatchActionPayload {
  statuses: ParcelStatus[]
  directions?: ParcelDirection[]
  filter?: string
  utcOffset: number
  tagIds: number[]
  developmentIds: number[]
  excludedParcelIds: string[]
  tenantId?: string
  deliveredOnFrom?: string
  deliveredOnTo?: string
  collectedOnFrom?: string
  collectedOnTo?: string
}

export type BatchActionPayload<P> = P & {
  batchActionParams?: IParcelBatchActionPayload
}

export interface IChangeParcelTenantPayload {
  tenantId: string
  parcelId: string
}
