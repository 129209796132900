import * as Sentry from '@sentry/react'
import { SeverityLevel } from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { ExtraErrorData as ExtraErrorDataIntegration } from '@sentry/integrations'
import autobind from 'autobind-decorator'
import { history } from 'modules/module-navigation/connected-router/middleware'
import { SentryErrorBase } from '../../errors/SentryErrorBase'
import { IMessageOptions, LogLevel } from '../../model'
import { IBreadcrumb, IIdentityParams, IReporter, SentryError } from '../model'
import { fingerprintContext } from './finger-printers/context/context'
import { FingerPrinter } from './finger-printers/models'
import { statusCode } from './finger-printers/status-code/status-code'
import { EventFormatter } from './formatters/models'
import { removeRequestData } from './formatters/remove-request-data/remove-request-data'
import { removeResponseData } from './formatters/remove-response-data/remove-response-data'

export interface ISentryReporterParams {
  source: string
  version: string
  debug: boolean
  environment: string
  tags?: JSONPrimitiveObject
}

export const LOG_LEVEL_MAPPING: { [key in LogLevel]: SeverityLevel } = {
  [LogLevel.Info]: 'info',
  [LogLevel.Warning]: 'warning',
  [LogLevel.Error]: 'error',
  [LogLevel.Fatal]: 'fatal',
  [LogLevel.Debug]: 'debug',
  [LogLevel.None]: 'log',
}

export class SentryReporter implements IReporter {
  static readonly FINGER_PRINTERS: FingerPrinter[] = [statusCode, fingerprintContext]
  static readonly EVENT_FORMATTERS: EventFormatter[] = [removeRequestData, removeResponseData]
  private readonly implicitTags?: JSONPrimitiveObject

  constructor(private readonly params: ISentryReporterParams) {
    this.implicitTags = params.tags
    this.init(this.params)
  }

  @autobind
  async identify({ user }: IIdentityParams): Promise<void> {
    Sentry.setUser({ id: user })
    return Promise.resolve()
  }

  @autobind
  async breadcrumb(crumb: IBreadcrumb) {
    Sentry.addBreadcrumb(crumb)

    return Promise.resolve()
  }

  @autobind
  async log(message: string, { tags }: IMessageOptions = {}) {
    if (this.params.debug) {
      console.log(message)
    }

    Sentry.captureMessage(message, {
      level: 'info',
      tags: this.tags(tags),
    })

    return Promise.resolve()
  }

  @autobind
  async warn(message: string, { tags }: IMessageOptions = {}) {
    if (this.params.debug) {
      console.warn(message)
    }

    Sentry.captureMessage(message, {
      level: 'warning',
      tags: this.tags(tags),
    })

    return Promise.resolve()
  }

  @autobind
  async error(exception: SentryError, options: IMessageOptions = {}) {
    const { context, tags } = options

    if (this.params.debug) {
      console.error(context || '', exception)
    }
    Sentry.captureException(exception, {
      extra: this.extra(exception, { context }),
      fingerprint: this.fingerprint(exception, options),
      level: this.severity(exception, options.level),
      tags: this.tags(exception instanceof SentryErrorBase ? exception.tags : undefined, tags),
    })
    return Promise.resolve()
  }

  private init(config: ISentryReporterParams) {
    const { debug, environment, source, version } = config
    Sentry.init({
      debug,
      dsn: source,
      environment,
      release: version,
      normalizeDepth: 5,
      beforeBreadcrumb: this.beforeBreadcrumb,
      integrations: [
        new ExtraErrorDataIntegration({
          depth: 4,
        }),
        new Integrations.BrowserTracing({
          // Can also use reactRouterV4Instrumentation
          routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
        }),
      ],
      beforeSend: this.beforeSend,
    })
    Sentry.addGlobalEventProcessor(this.beforeSend)
  }

  private readonly beforeBreadcrumb = (
    breadcrumb: Sentry.Breadcrumb,
    hint?: Sentry.BreadcrumbHint,
  ): Sentry.Breadcrumb | null => {
    return breadcrumb.category === 'console' ? null : breadcrumb
  }

  private readonly beforeSend = (event: any) =>
    SentryReporter.EVENT_FORMATTERS.reduce((ev, formatter) => formatter(ev), event)

  private readonly tags = (...tags: Array<IMessageOptions['tags']>) =>
    Object.entries(Object.assign({ ...this.implicitTags }, ...tags) as JSONPrimitiveObject)
      .filter(([_, value]) => value !== undefined)
      .reduce((stringified, [key, value]) => Object.assign(stringified, { [key]: value?.toString() }), {})

  private readonly extra = (error: SentryError, ...extra: JSONObject[]) => {
    const additional = error instanceof SentryErrorBase ? extra.concat(error.extra) : extra
    return Object.assign({}, ...additional)
  }

  private readonly severity = (error: SentryError, fallback = LogLevel.Error) => {
    const result = error instanceof SentryErrorBase ? error.logLevel : fallback
    return result !== LogLevel.None ? LOG_LEVEL_MAPPING[result] : undefined
  }

  private readonly fingerprint = (error: SentryError, options: IMessageOptions): string[] => {
    const additional = error instanceof SentryErrorBase ? error.fingerprints : []
    return SentryReporter.FINGER_PRINTERS.reduce(
      (prints, printer) => printer(error, { ...options, tags: this.tags(options.tags) }, prints),
      ['{{ default }}'].concat(additional),
    )
  }
}

export default SentryReporter
