import { LogLevel } from '../model'

export abstract class SentryErrorBase extends Error {
  tags: JSONPrimitiveObject = {}

  fingerprints: string[] = []

  logLevel: LogLevel = LogLevel.Error

  extra: JSONObject = {}

  context(message: string) {
    this.message = message
    return this
  }

  fingerprint(fingerprint: string): SentryErrorBase {
    this.fingerprints.push(fingerprint)
    return this
  }

  level(level: LogLevel): SentryErrorBase {
    this.logLevel = level
    return this
  }

  tag(tag: string, value?: JSONPrimitive): SentryErrorBase {
    Object.assign(this.tags, { [tag]: value })
    return this
  }

  addTags(tags: JSONPrimitiveObject): SentryErrorBase {
    Object.assign(this.tags, tags)
    return this
  }

  report(extra?: JSONObject): SentryErrorBase {
    Object.assign(this.extra, extra)
    return this
  }
}
