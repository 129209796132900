import { EuiButton, EuiButtonProps, EuiPopover, EuiPopoverProps } from '@elastic/eui'
import { Grid, IParagraphProps, Paragraph, useToggle } from 'modules/web-atoms'
import React, { useMemo } from 'react'

export interface IPopoverButtonProps extends Omit<EuiPopoverProps, 'button' | 'closePopover' | 'children'> {
  label: string
  children: React.ReactNode
  isDisabled?: boolean
  icon?: React.ReactNode
  iconSide?: 'left' | 'right'
  labelProps?: IParagraphProps
  buttonProps?: EuiButtonProps
}

export const PopoverButton: React.FC<IPopoverButtonProps> = ({
  label,
  buttonProps,
  icon,
  children,
  labelProps,
  isDisabled,
  iconSide = 'left',
  ...rest
}) => {
  const [isPopoverToggled, setTogglePopover] = useToggle(false)

  const closePopover = () => {
    setTogglePopover(false)
  }

  const button = useMemo(
    () => (
      <EuiButton color="primary" fullWidth isDisabled={isDisabled} onClick={setTogglePopover} {...buttonProps}>
        <Grid>
          {icon && iconSide === 'left' && <Grid.Col mt="macro">{icon}</Grid.Col>}

          <Grid.Col width="max-content">
            <Paragraph color="primaryMid" {...labelProps}>
              {label}
            </Paragraph>
          </Grid.Col>

          {icon && iconSide === 'right' && <Grid.Col mt="macro">{icon}</Grid.Col>}
        </Grid>
      </EuiButton>
    ),
    [icon, label, setTogglePopover, isDisabled, labelProps, buttonProps, iconSide],
  )

  return (
    <EuiPopover
      style={{ width: '100%' }}
      panelStyle={{ marginTop: 10 }}
      button={button}
      isOpen={isPopoverToggled}
      closePopover={closePopover}
      hasArrow={false}
      {...rest}
    >
      {children}
    </EuiPopover>
  )
}

export default React.memo(PopoverButton)
