const useRGB = (hexcode: string): [number, number, number] => {
  const hex = hexcode.substring(1)
  if (![3, 6].includes(hex.length)) return [0, 0, 0]

  let formattedHex = hex
  if (hex.length === 3) {
    formattedHex = hex
      .split('')
      .map((hex) => `${hex}${hex}`)
      .join('')
  }
  const components: string[] = []
  for (let i = 0, hexLength = formattedHex.length; i < hexLength; i += 2) {
    components.push(formattedHex.substring(i, i + 2))
  }

  return [parseInt(components[0], 16), parseInt(components[1], 16), parseInt(components[2], 16)]
}

export default useRGB
