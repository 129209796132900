import { EuiButton } from '@elastic/eui'
import profile from '../../../profile'
import { useAppDispatch, useAppSelector } from 'modules/module-utils'
import { Grid, IGridProps, useToggle, useTranslate } from 'modules/web-atoms'
import React from 'react'
import { TabPanels } from '../RecipientProfilePage'
import { ConfirmModal } from 'modules/web-molecules'
import { useCurrentRecipient } from '../../../hooks'

export interface IRecipientGDPRTabProps extends IGridProps {}

export const RecipientGDPRTab: React.FC<IRecipientGDPRTabProps> = ({ ...rest }) => {
  const t = useTranslate(`recipient-portal.tabs.${TabPanels.GDPR}`)
  const dispatch = useAppDispatch()
  const isLoading = useAppSelector(profile.selectors.isLoading)
  const [showDeleteModal, toggleDeleteModal] = useToggle(false)
  const { recipientData, isFetching } = useCurrentRecipient()

  const onDeleteData = () => {
    toggleDeleteModal()
    if (recipientData?.id) {
      dispatch(profile.actions.deleteData({ recipientId: recipientData.id }))
    }
  }

  return (
    <Grid mt="large" gutterSize="small" {...rest}>
      <Grid.Col>
        <EuiButton
          href="https://www.parceltracker.com/pp"
          target="_blank"
          rel="noopener noreferrer"
          color="primary"
          fill
        >
          {t('view-privacy-policy')}
        </EuiButton>
      </Grid.Col>

      <Grid.Col>
        <EuiButton color="danger" fill onClick={toggleDeleteModal} isDisabled={isFetching} isLoading={isLoading}>
          {t('delete-my-data')}
        </EuiButton>
      </Grid.Col>

      {showDeleteModal && (
        <ConfirmModal
          type='danger'
          cancelLabel={t('delete.cancel')}
          confirmLabel={t('delete.confirm')}
          heading={t('delete.heading')}
          message={t('delete.description')}
          onCancel={toggleDeleteModal}
          onConfirm={onDeleteData}
        />
      )}
    </Grid>
  )
}

export default React.memo(RecipientGDPRTab)
