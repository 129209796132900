import { Action } from 'redux-actions'
import { SagaIterator } from 'redux-saga'
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'

import autobind from 'autobind-decorator'
import copy from 'copy-to-clipboard'
import { SupervisorSuite } from 'modules/redux-supervisor'

import { ICopyPayload, IShowBannerPayload } from '../alert'
import * as actions from '../alert/actions'
import * as selectors from '../alert/selectors'

export class AlertSaga extends SupervisorSuite {
  @autobind
  *start(): SagaIterator {
    yield all([takeEvery(actions.COPY, this.onCopy), takeLatest(actions.SHOW_BANNER, this.onBannerShow)])
  }

  @autobind
  *onCopy({ payload }: Action<ICopyPayload>): SagaIterator {
    const { value, ...config } = payload
    yield call(copy, value)

    yield put(actions.show(config))
  }

  @autobind
  *onBannerShow({ payload }: Action<IShowBannerPayload>): SagaIterator {
    const currentBanner = yield select(selectors.banner)
    if (currentBanner) {
      yield put(actions.hide(currentBanner.id))
    }
    yield put(actions.storeBanner(payload))
  }
}

export default AlertSaga
