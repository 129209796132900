import { Expose, Type } from 'class-transformer'
import { ParcelDirection, ParcelState, ParcelType } from 'modules/module-deliveries/screens/models'
import { UploadUpdate } from 'modules/module-recipient-upload/upload'
import { Role } from 'modules/module-security'
import 'reflect-metadata'

export interface IUserDto {
  Id: string
  Email: string
  FirstName: string
  LastName: string
  Type: UserType
  JobTitle?: string
  Location?: string
  DevelopmentId: number
  Phone?: string
  RecordModified: string
}

export enum UserType {
  NORMAL = 0,
  ADMIN = 1,
  OWNER = 2,
  DEBUG = 3,
  BASIC = 4,
}

export enum ParcelDimensions {
  LETTER = 'LETTER',
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

export enum TenantType {
  COMPANY = 'Company',
  INDIVIDUAL = 'Tenant',
  EXTERNAL = 'external',
}

export class IUserDtoClass {
  @Expose({ name: 'Id' })
  id: string
  @Expose({ name: 'Email' })
  email: string
  @Expose({ name: 'FirstName' })
  firstName: string
  @Expose({ name: 'LastName' })
  lastName: string
  @Expose({ name: 'Type' })
  type: UserType
  @Expose({ name: 'JobTitle' })
  jobTitle?: string
  @Expose({ name: 'Location' })
  location?: string
  @Expose({ name: 'DevelopmentId' })
  developmentId: number
  @Expose({ name: 'Phone' })
  phone?: string
  @Expose({ name: 'RecordModified' })
  recordModified: string
}

export class IAssignedUserDto {
  @Expose({ name: 'Id' })
  id: string
  @Expose({ name: 'Email' })
  email: string
  @Expose({ name: 'FirstName' })
  firstName: string
  @Expose({ name: 'LastName' })
  lastName: string
  @Expose({ name: 'Type' })
  type: UserType
}

export class ICreateUserDto {
  @Expose({ name: 'Id' })
  id?: string
  @Expose({ name: 'FirstName' })
  firstName: string
  @Expose({ name: 'LastName' })
  lastName: string
  @Expose({ name: 'Email' })
  email: string
  @Expose({ name: 'Type' })
  type: UserType
  @Expose({ name: 'DevelopmentIds' })
  developmentIds: number[]
}

export class IAuthorizedDevelopmentsDto {
  @Expose({ name: 'DevelopmentIds' })
  developmentIds: number[]
}

export class ITenantDto {
  @Expose({ name: 'Id' })
  id: string
  @Expose({ name: 'Id2' })
  id2?: string
  @Expose({ name: 'FirstName' })
  firstName: string
  @Expose({ name: 'LastName' })
  lastName?: string
  @Expose({ name: 'Alias' })
  alias?: string
  @Expose({ name: 'Email' })
  email: string
  @Expose({ name: 'Phone' })
  phone?: string
  @Expose({ name: 'DevelopmentId' })
  primarySite: number
  @Expose({ name: 'DevelopmentIds' })
  additionalSites?: number[]
  @Expose({ name: 'Room' })
  room: string
  @Expose({ name: 'NotificationOptions' })
  notificationOptions: number | undefined
  @Expose({ name: 'Type' })
  type?: TenantType
  @Expose({ name: 'GDPRComply' })
  gdprComply?: boolean
  @Expose({ name: 'DateOfBirth' })
  dateOfBirth?: string
  @Expose({ name: 'RecordModified' })
  recordModified?: string
  @Expose({ name: 'BuildingName' })
  siteName?: string
  @Expose({ name: 'AdditionalBuildingNames' })
  additionalSiteNames?: string[]
  @Expose({ name: 'AddressLine1' })
  addressLine1?: string
  @Expose({ name: 'AddressLine2' })
  addressLine2?: string
  @Expose({ name: 'Postcode' })
  postcode?: string
  @Expose({ name: 'City' })
  city?: string
  @Expose({ name: 'Country' })
  country?: string
  @Expose({ name: 'DropoffLocation' })
  dropoffLocation?: string
  @Expose({ name: 'DropoffLocationName' })
  dropoffLocationName?: string
}

export class ITenantBatchUploadResultDto {
  @Expose({ name: 'TenantId' })
  tenantId: string
  @Expose({ name: 'Outcome' })
  outcome: UploadUpdate
}

export class ITenantDiffDeleteTenantDto {
  @Expose({ name: 'ExternalId' })
  externalId: string | undefined
  @Expose({ name: 'DevelopmentIds' })
  developmentIds: number[]
}
export class ITenantsDiffDeleteDto {
  @Expose({ name: 'Tenants' })
  @Type(() => ITenantDiffDeleteTenantDto)
  tenants: ITenantDiffDeleteTenantDto[]
}

export class IRecipientAutocompleteDto {
  @Expose({ name: 'TenantId' })
  id: string
  @Expose({ name: 'DevelopmentId' })
  buildingId: number
  @Expose({ name: 'BuildingName' })
  buildingName: string
  @Expose({ name: 'Text' })
  text: string
  @Expose({ name: 'FirstName' })
  firstName: string
  @Expose({ name: 'LastName' })
  lastName?: string
  @Expose({ name: 'Alias' })
  alias?: string
  @Expose({ name: 'Room' })
  room: string
  @Expose({ name: 'DropoffLocation' })
  dropoffLocation?: string
}

export class IRequestorRecipientAutocompleteDto {
  @Expose({ name: 'Id' })
  id: string
  @Expose({ name: 'Location' })
  location: string[]
  @Expose({ name: 'Name' })
  name: string
  @Expose({ name: 'DropoffLocation' })
  dropoffLocation?: string
}

export class IParcelTagOptionDto {
  @Expose({ name: 'Id' })
  value: number
  @Expose({ name: 'Name' })
  label: string
  @Expose({ name: 'ColourHex' })
  color: string
  @Expose({ name: 'Enabled' })
  enabled: boolean
}

export class IMailroomLocationDto {
  @Expose({ name: 'Id' })
  id: string
  @Expose({ name: 'Name' })
  name: string
  @Expose({ name: 'PublicId' })
  publicId: string
  @Expose({ name: 'DevelopmentId' })
  developmentId: number
}

export class IDropoffLocationDto {
  @Expose({ name: 'Id' })
  id: string
  @Expose({ name: 'Name' })
  name: string
  @Expose({ name: 'PublicId' })
  publicId: string
  @Expose({ name: 'Enabled' })
  enabled: boolean
  @Expose({ name: 'isPermanent' })
  isPermanent: boolean
  @Expose({ name: 'ClientId' })
  clientId: number
}

export interface IParcelDto {
  Id: string
  Source?: string
  Courier?: string
  LoggedIn: string
  SessionId: string
  Data: string
  Data2?: string
  Data3?: string
  LoggingMode: number
  CollectionMode: number
  LoggedInBy: string
  LoggedOutBy: string
  RecordModified: string
}

export enum ParcelTypeDto {
  INBOUND = 'INBOUND',
  DISPATCHED = 'DISPATCHED',
}

export enum ParcelStatusDto {
  EXPECTED = 'EXPECTED',
  HELD = 'HELD',
  RETRIEVED = 'RETRIEVED',
  COLLECTED = 'COLLECTED',
}
export class IParcelRowItem {
  @Expose({ name: 'ParcelId' })
  parcelId: string
  @Expose({ name: 'BuildingName' })
  buildingName: string
  @Expose({ name: 'Barcode' })
  identifier?: string
  @Expose({ name: 'DevelopmentId' })
  buildingId: number
  @Expose({ name: 'TenantName' })
  tenantName: string
  @Expose({ name: 'Collected' })
  collected: string
  @Expose({ name: 'Courier' })
  courier: string
  @Expose({ name: 'DeliveredToClient' })
  firstReceived: string
  @Expose({ name: 'Delivered' })
  delivered: string
  @Expose({ name: 'PicturePreviewUrl' })
  picturePreviewUrl?: string
  @Expose({ name: 'LastModified' })
  lastModified: string
  @Expose({ name: 'TagIds' })
  tagIds: number[]
  @Expose({ name: 'Room' })
  destination: string
  @Expose({ name: 'HandlerName' })
  handler?: string
  @Expose({ name: 'Status' })
  status?: ParcelStatusDto
  @Expose({ name: 'Type' })
  type?: ParcelTypeDto
  @Expose({ name: 'TrackingNumber' })
  trackingNumber?: string
  @Expose({ name: 'ParcelDirection' })
  parcelDirection?: ParcelDirection
  @Expose({ name: 'DocId' })
  docId?: string
  @Expose({ name: 'PreviousBuildingName' })
  previousBuildingName?: string  
  @Expose({ name: 'Quantity' })
  quantity?: string
  @Expose({ name: 'MailroomLocation' })
  mailroomLocation?: string
  @Expose({ name: 'DropoffLocation' })
  dropoffLocation?: string
}

class IBaseParcelDetails {
  @Expose({ name: 'Id' })
  parcelId: string
  @Expose({ name: 'TenantId' })
  recipientId: string
  @Expose({ name: 'SessionId' })
  sessionId: string
  @Expose({ name: 'DocId' })
  docId?: string
  @Expose({ name: 'Quantity' })
  quantity?: number
  @Expose({ name: 'BoxCount' })
  numberOfParcels?: number
  @Expose({ name: 'Size' })
  parcelDimensions?: string
  @Expose({ name: 'TrackingNumber' })
  trackingNumber?: string
  @Expose({ name: 'Courier' })
  courier?: string
  @Expose({ name: 'Notes' })
  notes?: string
  @Expose({ name: 'CollectionNotes' })
  collectionNotes?: string
  @Expose({ name: 'PickedUp' })
  collectedOn?: string
  @Expose({ name: 'LoggedOutBy' })
  collectedById?: string
  @Expose({ name: 'LoggedIn' })
  deliveredOn?: string
  @Expose({ name: 'LoggedInBy' })
  deliveredById?: string
  @Expose({ name: 'SecureCollection' })
  secureCollection?: boolean
  @Expose({ name: 'ManuallyCollected' })
  manuallyCollected?: boolean
  @Expose({ name: 'RecordModified' })
  recordModified: string
  @Expose({ name: 'DropoffLocation' })
  dropoffLocation?: string
}

export class ParcelHistoryEventTenantDataDto {
  @Expose({ name: 'PreviousId' })
  previousId: string
  @Expose({ name: 'PreviousId2' })
  previousId2?: string
  @Expose({ name: 'PreviousName' })
  previousName: string
  @Expose({ name: 'PreviousLocation' })
  previousLocation: string

  @Expose({ name: 'CurrentId' })
  currentId: string
  @Expose({ name: 'CurrentId2' })
  currentId2?: string
  @Expose({ name: 'CurrentName' })
  currentName: string
  @Expose({ name: 'CurrentLocation' })
  currentLocation: string
}

export class ReceiverType {
  INTERNAL = 'internal'
  EXTERNAL = 'external'
}

export class ParcelHistoryEventDispatchedDataDto {
  @Expose({ name: 'SenderId' })
  senderId?: string
  @Expose({ name: 'SenderName' })
  senderName?: string

  @Expose({ name: 'ReceiverId' })
  receiverId?: string
  @Expose({ name: 'ReceiverName' })
  receiverName?: string

  @Expose({ name: 'ReceiverLocation' })
  receiverLocation?: string
  @Expose({ name: 'ReceiverType' })
  receiverType?: ReceiverType
}

export class IParcelHistoryEventDataDto {
  @Expose({ name: 'ActorId' })
  actorId: string
  @Expose({ name: 'ActorName' })
  actorName: string
  @Expose({ name: 'Tenant' })
  @Type(() => ParcelHistoryEventTenantDataDto)
  tenant?: ParcelHistoryEventTenantDataDto
  @Expose({ name: 'DispatchedParcel' })
  @Type(() => ParcelHistoryEventDispatchedDataDto)
  dispatchedParcel?: ParcelHistoryEventDispatchedDataDto
}

export class IHistoryItemDto {
  @Expose({ name: 'Status' })
  status: ParcelState
  @Expose({ name: 'Timestamp' })
  timestamp?: string
  @Expose({ name: 'IsActive' })
  isActive: boolean
  @Expose({ name: 'Mailroom' })
  mailroom?: string
  @Expose({ name: 'UserName' })
  username?: string
  @Expose({ name: 'TenantName' })
  tenantName?: string
  @Expose({ name: 'TenantLocation' })
  tenantLocation?: string
  @Expose({ name: 'Data' })
  @Type(() => IParcelHistoryEventDataDto)
  data?: IParcelHistoryEventDataDto
}

export class IParcelDetailsDto extends IBaseParcelDetails {
  @Expose({ name: 'TenantName' })
  recipientName: string
  @Expose({ name: 'Data' })
  barcode: string
  @Expose({ name: 'TenantId' })
  recipientId: string
  @Expose({ name: 'Sender' })
  senderId?: string
  @Expose({ name: 'SenderEntity' })
  @Type(() => ITenantDto)
  sender?: ITenantDto
  @Expose({ name: 'Tags' })
  tags?: IParcelTagOptionDto[]
  @Expose({ name: 'CollectionNotes' })
  collectionNotes?: string
  @Expose({ name: 'SignatureUrl' })
  signatureUrl?: string
  @Expose({ name: 'OcrPhotoUrl' })
  ocrPhotoUrl?: string
  @Expose({ name: 'BarcodePhotoUrl' })
  barcodePhotoUrl?: string
  @Expose({ name: 'AdditionalPhotoUrl' })
  additionalPhotoUrl?: string
  @Expose({ name: 'LoggedOutByName' })
  collectedBy?: string
  @Expose({ name: 'LoggedInByName' })
  deliveredBy?: string
  @Expose({ name: 'DevelopmentId' })
  buildingId?: number
  @Expose({ name: 'MailroomLocation' })
  mailroomLocation?: string
  @Expose({ name: 'HandlerName' })
  handlerName?: string
  @Expose({ name: 'BoxCount' })
  numberOfParcels?: number
  @Expose({ name: 'History' })
  @Type(() => IHistoryItemDto)
  history?: IHistoryItemDto[]
}

export class IOutboundParcelDetailsDto {
  @Expose({ name: 'Barcode' })
  barcode?: string
  @Expose({ name: 'BatchId' })
  batchId: string
  @Expose({ name: 'CourierCallSign' })
  courierCallSign?: string
  @Expose({ name: 'CourierName' })
  courierName?: string
  @Expose({ name: 'HandoffBatchId' })
  handoffBatchId: string
  @Expose({ name: 'Id' })
  id: string
  @Expose({ name: 'LoggedIn' })
  loggedIn: string
  @Expose({ name: 'LoggedInBy' })
  loggedInBy: string
  @Expose({ name: 'LoggedInByName' })
  loggedInByName: string
  @Expose({ name: 'LoggedOut' })
  loggedOut?: string
  @Expose({ name: 'LoggedOutBy' })
  loggedOutBy?: string
  @Expose({ name: 'LoggedOutByName' })
  loggedOutByName?: string
  @Expose({ name: 'Notes' })
  notes?: string
  @Expose({ name: 'PackageSize' })
  packageSize?: ParcelDimensions
  @Expose({ name: 'ParcelBarcodePhotoUrl' })
  barcodePhotoUrl?: string
  @Expose({ name: 'ParcelBoxPhotoUrl' })
  boxPhotoUrl?: string
  @Expose({ name: 'ParcelSignaturePhotoUrl' })
  signaturePhotoUrl?: string
  @Expose({ name: 'RecipientAddressLine1' })
  addressLine1?: string
  @Expose({ name: 'RecipientCity' })
  city?: string
  @Expose({ name: 'RecipientCountry' })
  country?: string
  @Expose({ name: 'RecipientName' })
  recipientName?: string
  @Expose({ name: 'RecipientPostcode' })
  postCode?: string
  @Expose({ name: 'TenantId' })
  senderId: string
  @Expose({ name: 'TenantName' })
  senderName: string
  @Expose({ name: 'TrackingNumber' })
  trackingNumber?: string
}

export class ILogNewDeliveryDto {
  @Expose({ name: 'TenantId' })
  recipientId: string
  @Expose({ name: 'DevelopmentId' })
  buildingId?: number
  @Expose({ name: 'TagIds' })
  parcelTags?: number[]
  @Expose({ name: 'Size' })
  parcelDimensions?: string
  @Expose({ name: 'Barcode' })
  barcode: string
  @Expose({ name: 'DocId' })
  docId?: string
  @Expose({ name: 'Quantity' })
  quantity?: number
  @Expose({ name: 'Courier' })
  courier?: string
  @Expose({ name: 'TrackingNumber' })
  trackingNumber?: string
  @Expose({ name: 'Notes' })
  notes?: string
  @Expose({ name: 'MailroomLocation' })
  mailroomLocation?: string
  @Expose({ name: 'DropoffLocation' })
  dropoffLocation?: string
}

export class IRecipientCreateParcelDto {
  @Expose({ name: 'Sender' })
  senderId: string
  @Expose({ name: 'TenantId' })
  recipientId: string
  @Expose({ name: 'DevelopmentId' })
  buildingId: number
  @Expose({ name: 'BoxCount' })
  numberOfParcels?: number
  @Expose({ name: 'DropoffLocation' })
  dropoffLocation?: string
  @Expose({ name: 'TagIds' })
  parcelTags?: number[]
  @Expose({ name: 'Size' })
  parcelDimensions?: string
  @Expose({ name: 'Barcode' })
  barcode: string
  @Expose({ name: 'Courier' })
  courier?: string
  @Expose({ name: 'TrackingNumber' })
  trackingNumber?: string
  @Expose({ name: 'Notes' })
  notes?: string
}

export class IParcelBatchActionDto {
  @Expose({ name: 'Statuses' })
  statuses: string[]
  @Expose({ name: 'Directions' })
  directions?: string[]
  @Expose({ name: 'Types' })
  types?: ParcelType[]
  @Expose({ name: 'Filter' })
  filter: string
  @Expose({ name: 'UtcOffset' })
  utcOffset: number
  @Expose({ name: 'TagIds' })
  tagIds: number[]
  @Expose({ name: 'DevelopmentIds' })
  developmentIds: number[]
  @Expose({ name: 'ExcludedParcelIds' })
  excludedParcelIds: string[]
  @Expose({ name: 'TenantId' })
  tenantId?: string
  @Expose({ name: 'DeliveredOnFrom' })
  deliveredOnFrom?: string
  @Expose({ name: 'DeliveredOnTo' })
  deliveredOnTo?: string
  @Expose({ name: 'CollectionOnFrom' })
  collectionOnFrom?: string
  @Expose({ name: 'CollectedOnTo' })
  collectedOnTo?: string
}

export class IMoveParcelsDto {
  @Expose({ name: 'ParcelIds' })
  parcelIds: string[]
  @Expose({ name: 'Destination' })
  destination: number
}

export class IMarkParcelsAsHeldDto {
  @Expose({ name: 'ParcelIds' })
  parcelIds: string[]
}

export class IUpdateParcelDto extends IBaseParcelDetails {
  @Expose({ name: 'Data' })
  barcode?: string
  @Expose({ name: 'Tags' })
  tags?: number[]
  @Expose({ name: 'MailroomLocation' })
  mailroomLocation?: string
}

export class IOutboundParcelRowItem {
  @Expose({ name: 'ParcelId' })
  parcelId: string
  @Expose({ name: 'BuildingName' })
  buildingName: string
  @Expose({ name: 'DevelopmentId' })
  buildingId: number
  @Expose({ name: 'RecipientName' })
  recipientName: string
  @Expose({ name: 'Collected' })
  collected: string
  @Expose({ name: 'Courier' })
  courier: string
  @Expose({ name: 'DroppedOff' })
  droppedOff: string
  @Expose({ name: 'SenderName' })
  senderName: string
  @Expose({ name: 'PicturePreviewUrl' })
  picturePreviewUrl?: string
  @Expose({ name: 'LastModified' })
  lastModified?: string
}

export class IDevelopmentDto {
  @Expose({ name: 'Id' })
  id: number
  @Expose({ name: 'BuildingName' })
  buildingName: string
  @Expose({ name: 'ClientId' })
  clientId: number
  @Expose({ name: 'AddressLine1' })
  addressLine1?: string
  @Expose({ name: 'AddressLine2' })
  addressLine2?: string
  @Expose({ name: 'Postcode' })
  postcode?: string
  @Expose({ name: 'City' })
  city?: string
  @Expose({ name: 'Country' })
  country?: string
  @Expose({ name: 'BuildingSize' })
  buildingSize?: number
  @Expose({ name: 'TextsSent' })
  textsSent?: number
  @Expose({ name: 'RecordModified' })
  recordModified: string
  @Expose({ name: 'TextsEnabled' })
  textsEnabled?: boolean
  @Expose({ name: 'Enabled' })
  enabled?: boolean
  @Expose({ name: 'TenantsCount' })
  tenantsCount?: number
}

export class IRecipientBuildingOptionDto {
  @Expose({ name: 'BuildingId' })
  id: number
  @Expose({ name: 'BuildingName' })
  buildingName: string
}

export class ICourierOptionDto {
  @Expose({ name: 'Id' })
  id: number
  @Expose({ name: 'Name' })
  name: string
  @Expose({ name: 'ClientId' })
  clientId?: number
}

export class IParcelSizeOptionDto {
  @Expose({ name: 'Id' })
  id: number
  @Expose({ name: 'Name' })
  name: string
  @Expose({ name: 'ClientId' })
  clientId?: number
}

export interface IClientDto {
  Id: number
  Name: string
  AddressLine1?: string
  AddressLine2?: string
  Postcode?: string
  City?: string
  Country?: string
  RecordModified: string
}

export class IClientDtoClass {
  @Expose({ name: 'Id' })
  id: string
  @Expose({ name: 'Name' })
  name: string
  @Expose({ name: 'AddressLine1' })
  addressLine1?: string
  @Expose({ name: 'AddressLine2' })
  addressLine2?: string
  @Expose({ name: 'Postcode' })
  postcode?: string
  @Expose({ name: 'City' })
  city?: string
  @Expose({ name: 'Country' })
  country?: string
  @Expose({ name: 'RecordModified' })
  recordModified: string
}

export interface IUserDevelopmentClientDto {
  Client: IClientDto
  Development: IDevelopmentDto
  User: IUserDto
}

export class IUserDevelopmentClientDtoClass {
  @Expose({ name: 'Client' })
  @Type(() => IClientDtoClass)
  client: IClientDtoClass
  @Expose({ name: 'Development' })
  @Type(() => IDevelopmentDto)
  development: IDevelopmentDto
  @Expose({ name: 'User' })
  @Type(() => IUserDtoClass)
  user: IUserDtoClass
}

export class ITenantProfileDto extends ITenantDto {
  @Expose({ name: 'Username' })
  username?: string
  @Expose({ name: 'ClientId' })
  clientId: number
}

export class ICreateExternalRecipientDto {
  @Expose({ name: 'FirstName' })
  firstName: string
  @Expose({ name: 'LastName' })
  lastName: string
  @Expose({ name: 'Email' })
  email?: string
  @Expose({ name: 'Type' })
  type: 'external'
  @Expose({ name: 'DevelopmentId' })
  buildingId: number
  @Expose({ name: 'AddressLine1' })
  addressLine1: string
  @Expose({ name: 'AddressLine2' })
  addressLine2?: string
  @Expose({ name: 'City' })
  city?: string
  @Expose({ name: 'Postcode' })
  postcode?: string
  @Expose({ name: 'Country' })
  country?: string
}

export class ICreateOutboundParcelDto {
  @Expose({ name: 'TenantId' })
  senderId: string
  @Expose({ name: 'RecipientName' })
  recipientName?: string
  @Expose({ name: 'RecipientAddressLine1' })
  addressLine?: string
  @Expose({ name: 'RecipientPostcode' })
  postCode?: string
  @Expose({ name: 'RecipientCity' })
  city?: string
  @Expose({ name: 'RecipientCountry' })
  country?: string
  @Expose({ name: 'LoggedIn' })
  loggedIn: string
  @Expose({ name: 'CourierId' })
  courierId?: string
  @Expose({ name: 'TrackingNumber' })
  trackingNumber?: string
  @Expose({ name: 'Barcode' })
  barcode?: string
  @Expose({ name: 'Notes' })
  notes?: string
}

export class ICreateDevelopmentDto {
  @Expose({ name: 'Id' })
  id?: string
  @Expose({ name: 'BuildingName' })
  buildingName?: string
  @Expose({ name: 'ClientId' })
  clientId: string
  @Expose({ name: 'AddressLine1' })
  addressLine1?: string
  @Expose({ name: 'AddressLine2' })
  addressLine2?: string
  @Expose({ name: 'Postcode' })
  postcode?: string
  @Expose({ name: 'City' })
  city?: string
  @Expose({ name: 'Country' })
  country?: string
  @Expose({ name: 'BuildingSize' })
  buildingSize?: string
}

export class ICreateDevelopmentWithUsersDto extends ICreateDevelopmentDto {
  @Expose({ name: 'Emails' })
  emails: string[]
}

export class ISetLastAccessBuildingsDto {
  @Expose({ name: 'DevelopmentIds' })
  developmentIds: string[]
}

export enum FeatureFlags {
  OUTBOUND = 'outboundEnabled',
  MANAGE_RECIPIENTS = 'manageRecipientsEnabled',
  ADDITIONAL_PARCEL_DETAILS = 'additionalParcelDetails',
}

export class IFeatureFlagsDto {
  @Expose({ name: 'ManageRecipientsEnabled' })
  manageRecipientsEnabled?: boolean
  @Expose({ name: 'InboundParcelCollectionEnabled' })
  collectionEnabled?: boolean
  @Expose({ name: 'OutboundEnabled' })
  outboundEnabled?: boolean
  @Expose({ name: 'AdditionalParcelDetails' })
  additionalParcelDetails?: boolean
  @Expose({ name: 'InboundBatchCollectionEnabled' })
  batchCollectionEnabled?: boolean
  @Expose({ name: 'MultimailroomEnabled' })
  multimailroomEnabled?: boolean
  @Expose({ name: 'MultimailroomRequestorEnabled' })
  multimailroomRequestorEnabled?: boolean
  @Expose({ name: 'DropoffLocationsEnabled' })
  dropoffLocationsEnabled?: boolean
}

export class IChangePasswordDto {
  @Expose({ name: 'Email' })
  email: string
  @Expose({ name: 'CurrentPassword' })
  currentPassword: string
  @Expose({ name: 'NewPassword' })
  newPassword: string
}

export class ISetPasswordDto {
  @Expose({ name: 'CurrentPassword' })
  currentPassword: string
  @Expose({ name: 'NewPassword' })
  newPassword: string
}

export class IParcelStatsDto {
  @Expose({ name: 'Value' })
  value: number
  @Expose({ name: 'Change' })
  change?: string
}

export class IChartDataSeries {
  @Expose({ name: 'Name' })
  name: string
  @Expose({ name: 'Values' })
  values: number[] | string[]
}

export class IChartDataDto {
  @Expose({ name: 'Series' })
  @Type(() => IChartDataSeries)
  series: IChartDataSeries[]
  @Expose({ name: 'XValues' })
  xValues: number[] | string[]
}

export class IHeatMapDataSeries {
  @Expose({ name: 'X' })
  x: string | number
  @Expose({ name: 'Y' })
  y: string | number
}

export class IHeatMapDataDto {
  @Expose({ name: 'Name' })
  id: string
  @Expose({ name: 'Data' })
  @Type(() => IHeatMapDataSeries)
  data: IHeatMapDataSeries[]
}

export class IBarDataDto {
  @Expose({ name: 'Key' })
  key: string
  @Expose({ name: 'Value' })
  value: number | string
}

export class IRefreshTokenDto {
  @Expose({ name: 'RefreshToken' })
  refreshToken: string
  @Expose({ name: 'EntityId' })
  userId: string
}

export class IRequestTokenDto {
  @Expose({ name: 'accessToken' })
  accessToken: string
  @Expose({ name: 'expires' })
  expires: string
  @Expose({ name: 'refreshToken' })
  refreshToken: string
  @Expose({ name: 'entityType' })
  userType: Role
  @Expose({ name: 'entityId' })
  userId: string
}
