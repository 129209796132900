export enum RecipientField {
  UNKNOWN = 'unknown',
  ID = 'id',
  FIRST_NAME = 'first-name',
  LAST_NAME = 'last-name',
  NICK_NAME = 'nick-name',
  LOCATION = 'location',
  EMAIL = 'email',
  PHONE = 'phone',
  CONTACT_PREFERENCE = 'contact-preference',
  TYPE = 'type',
  SITE = 'site',
  ADDITIONAL_SITES = 'additional-sites',
  DROP_OFF = 'drop-off',
}

export const recipientFields: RecipientField[] = [
  RecipientField.UNKNOWN,
  RecipientField.ID,
  RecipientField.FIRST_NAME,
  RecipientField.LAST_NAME,
  RecipientField.NICK_NAME,
  RecipientField.LOCATION,
  RecipientField.EMAIL,
  RecipientField.PHONE,
  RecipientField.CONTACT_PREFERENCE,
  RecipientField.TYPE,
  RecipientField.SITE,
  RecipientField.ADDITIONAL_SITES,
  RecipientField.DROP_OFF,
]

export const requiredFields = [RecipientField.FIRST_NAME, RecipientField.LOCATION, RecipientField.EMAIL]

export enum FieldViolation {
  REQUIRED = 'required',
  MAX_LENGTH = 'max-length',
  UNIQUE = 'unique',
  LENGTH = 'length',
  FORMAT = 'format',
  NON_EMPTY = 'non-empty',
}

export const FIELD_MATCHES: { [key in RecipientField]: string[] } = {
  [RecipientField.UNKNOWN]: [],
  [RecipientField.ID]: ['id', 'id (optional)'],
  [RecipientField.FIRST_NAME]: ['first name', 'firstname', 'first-name', 'name', 'first name (required)'],
  [RecipientField.LAST_NAME]: ['last name', 'lastname', 'last-name', 'surname', 'last name (required)'],
  [RecipientField.NICK_NAME]: ['nick name', 'nickname', 'nick-name', 'alias', 'nickname (optional)'],
  [RecipientField.LOCATION]: [
    'room',
    'location',
    'flat',
    'department',
    'position',
    'flat number',
    'room number',
    'location/room number (required)',
  ],
  [RecipientField.EMAIL]: ['email', 'email address', 'email-address', 'email (required)'],
  [RecipientField.PHONE]: ['phone', 'mobile', 'number', 'mobile number (optional)'],
  [RecipientField.CONTACT_PREFERENCE]: [
    'contact preference',
    'preference',
    'contact-preference',
    'contact preference (optional)',
  ],
  [RecipientField.TYPE]: ['type (optional)', 'recipient type', 'type', 'tenant type'],
  [RecipientField.SITE]: ['site', 'site (optional)', 'building', 'primary site'],
  [RecipientField.ADDITIONAL_SITES]: ['additonal sites', 'additional sites (optional)', 'sites'],
  [RecipientField.DROP_OFF]: ['drop-off location (optional)', 'drop-off ', 'alternative location'],
}

export type RecipientFile = Array<{ [key: string]: string | undefined }>

export type RecipientUpload = { [key: string]: { [key: string]: string } }

export type ColumnAlignment = Partial<Record<string, RecipientField>>

export type Validation = { [key: string]: Partial<Record<RecipientField, FieldViolation | undefined>> }

export type IdFrequencyMap = Record<string, string[]>

export enum FileParseResult {
  VALID = 'valid',
  MISSING_FIELDS = 'missing-fields',
  INVALID_FORMAT = 'invalid-format',
}

export enum ContactPreference {
  PHONE = 'Phone',
  EMAIL = 'Email',
  BOTH = 'Both',
}

export enum RecipientType {
  INDIVIDUAL = 'individual',
  COMPANY = 'company',
}

export interface WordMap {
  [key: string]: number
}
