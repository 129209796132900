import { UserType } from 'api'
import { IPlanConfig, PaymentMethod } from '../services/models'

export interface IPermissions {
  canViewBilling: boolean
}

export enum PaymentManagement {
  VENDOR = 'vendor',
  CUSTOM = 'custom',
  NONE = 'none',
}

export enum Plan {
  FREE = 'free',
  PRO = 'pro',
  PRO_MULTI_SITE = 'pro-multi-site',
  ENTERPRISE = 'enterprise',
}

export enum PlanBenefits {
  LOG_LIMITED_PARCELS = 'log-limited-parcels',
  LOG_UNLIMITED_PARCELS = 'log-unlimited-parcels',
  LIVE_SUPPORT = 'live-support',
  DIGITAL_LOG = 'digital-log',
  PROOF_OF_COLLECTION = 'proof-of-collection',
  CUSTOMIZE_NOTIFICATIONS = 'customize-notifications',
  SLA = 'sla',
  MULTI_SITE_MANAGEMENT = 'multi-site-management',
  MULTI_SITE = 'multi-site',
  SECURITY_AND_PERMISSIONS = 'security-permissions',
  ALL_OF_FREE = 'all-of-free',
  ALL_OF_PRO = 'all-of-pro',
}

export enum BillingAction {
  UPGRADE = 'upgrade',
  DOWNGRADE = 'downgrade',
  CONTACT_US = 'contact-us',
}

export enum Currency {
  USD = 'USD',
  AUD = 'AUD',
  GBP = 'GBP',
  EUR = 'EUR',
}

export interface ICheckoutPayload {
  method: PaymentMethod
  currency: Currency
}

export interface ISetPermissionsPayload {
  userType: UserType
  manualOverride?: boolean
}

export type ISetPlanLimitsPayload = Pick<
  IBillingState,
  | 'limitInboundParcels'
  | 'limitOutboundParcels'
  | 'scannedInboundParcelsInBillingPeriod'
  | 'scannedOutboundParcelsInBillingPeriod'
>

export interface IBillingState {
  permissions: IPermissions
  loading: boolean
  isTrial: boolean
  checkoutLoading: boolean
  paymentDetailsLoading: boolean
  currentPlan?: IPlanConfig
  recommendedPlan?: IPlanConfig
  selectedPlan?: Plan
  daysLeftInTrial?: number
  daysLeftInGracePeriod?: number
  hasBeenPaidClient?: boolean
  manualOverride?: boolean
  isSmsEnabled: boolean

  limitInboundParcels?: boolean
  limitOutboundParcels?: boolean
  scannedInboundParcelsInBillingPeriod: number
  scannedOutboundParcelsInBillingPeriod: number
  currentCurrency?: Currency
}

export const initialState: IBillingState = {
  permissions: {
    canViewBilling: false,
  },
  loading: false,
  checkoutLoading: false,
  isTrial: false,
  paymentDetailsLoading: false,
  isSmsEnabled: false,

  scannedInboundParcelsInBillingPeriod: 0,
  scannedOutboundParcelsInBillingPeriod: 0,
}
