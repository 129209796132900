import * as React from 'react'

import { EuiForm, EuiFormProps } from '@elastic/eui'
import styled from '@emotion/styled'
import shouldForwardProp from '@styled-system/should-forward-prop'

import * as system from 'styled-system'

import { StyledViewProps } from '../Grid/StyledView'

export type IFormProps = StyledViewProps & EuiFormProps

export const StyledForm = styled(EuiForm, { shouldForwardProp })<IFormProps>(
  system.color,
  system.flexbox,
  system.space,
  system.layout,
  system.position,
  system.justifyContent,
  system.alignItems,
  system.borders,
  system.shadow,
  system.background,
  system.margin,
)

export const Form: React.FC<IFormProps> = (props) => {
  const { children, ...rest } = props
  return <StyledForm {...rest}>{children}</StyledForm>
}

export default React.memo(Form)
