import { EuiHeader, EuiHeaderSectionItemButton, EuiIcon } from '@elastic/eui'
import { Grid, H6, Icon, useTranslate } from 'modules/web-atoms'
import * as React from 'react'
import icon from '../../../dna/assets/images/icon.svg'

export interface IPortalHeaderProps extends React.ComponentProps<typeof EuiHeader> {
  onToggleNav: () => void
}

export const PortalHeader: React.FC<IPortalHeaderProps> = (props) => {
  const { onToggleNav, ...rest } = props

  const t = useTranslate('core.nav.header')

  const leftSectionItems = React.useMemo(
    () => [
      <Grid direction="row" key="logo" alignItems="center" marginLeft="small">
        <EuiIcon type={icon} size="xl" />
        <H6 color="textDark" paddingLeft="macro" whiteSpace="nowrap">
          {t('name')}
        </H6>
      </Grid>,
    ],
    [t],
  )

  const rightSectionItems = React.useMemo(
    () => [
      <EuiHeaderSectionItemButton
        size="s"
        flush="both"
        key="menu-toggle"
        aria-label="Toggle main navigation"
        onClick={onToggleNav}
      >
        <Icon.MenuHeaderToggle />
      </EuiHeaderSectionItemButton>,
    ],
    [onToggleNav],
  )

  return (
    <EuiHeader
      position="fixed"
      sections={[
        {
          items: leftSectionItems,
          borders: 'none',
        },
        {
          items: rightSectionItems,
          borders: 'none',
        },
      ]}
      {...rest}
    />
  )
}

export default React.memo(PortalHeader)
