import { createSelector, Selector } from '@reduxjs/toolkit'
import { BRANCH_KEY } from '../branch'
import { IRecipientsOwnState } from '../model'
import { IRecipientsState } from './models'

export const branch: Selector<IRecipientsOwnState, IRecipientsState> = (state) => state[BRANCH_KEY].recipients

export const isLoading = createSelector(branch, (branch) => branch.loading)

export const isDeleting = createSelector(branch, (branch) => branch.isDeleting)

export const error = createSelector(branch, (branch) => branch.error)

export const createRecipientStep = createSelector(branch, (branch) => branch.createRecipientStep)

export const selectedRecipient = createSelector(branch, (branch) => branch.selectedRecipient)

export const selectedItems = createSelector(branch, (branch) => branch.selectedItems)

export const recipientsToDelete = createSelector(
  [selectedItems, selectedRecipient],
  (selectedItems: string[], selectedRecipient: string | undefined) => {
    const recipientsToDelete = selectedItems.length ? selectedItems : [selectedRecipient]
    return recipientsToDelete.filter((id) => !!id) as string[]
  },
)

export const numRecipientsToDelete = createSelector(recipientsToDelete, (arr) => arr.length)

export const isCreateForm = createSelector(branch, (branch) => branch.isCreateForm)

export const createRecipientType = createSelector(branch, (branch) => branch.createRecipientType)

export const activeBuildings = createSelector(branch, (branch) => branch.activeBuildings)

export const permissions = createSelector(branch, (branch) => branch.permissions)

export const activeBuildingsIdMap = createSelector(activeBuildings, (buildings) => {
  const map = {}
  for (const b of buildings) {
    map[b.id] = b.buildingName
  }
  return map
})

export const formState = createSelector(branch, (branch) => branch.formState)

export const visibleColumns = createSelector(branch, (branch) => branch.persistent.visibleColumns)
