import { AxiosResponse } from 'axios'

export const createAndDownloadBlobFile = (fileContent: Blob, filename) => {
  var link = document.createElement('a')
  // Browsers that support HTML5 download attribute
  if (link.download !== undefined && link.download !== null) {
    var url = URL.createObjectURL(fileContent)
    link.setAttribute('href', url)
    link.setAttribute('download', filename)
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
}

export const extractContentType = (data: AxiosResponse<any, any>): string | undefined => {
  if (!data || !data.headers) {
    return undefined
  }
  let contentType = data.headers['content-type']
  return contentType
}

export const extractContentTypeOrUseDefault = (data: AxiosResponse<any, any>, defaultContentType: string) => {
  let contentType: string | undefined = extractContentType(data)

  if (!contentType) {
    return defaultContentType
  }

  return contentType
}

export const extractFileNameFromContentDisposition = (data: AxiosResponse<any, any>): string | undefined => {
  if (!data || !data.headers) {
    return undefined
  }

  let contentDisposition = data.headers['content-disposition']
  if (!contentDisposition) {
    return undefined
  }

  let park: string[] = contentDisposition.split('filename =')
  if (park != null && park.length > 1) {
    return park[1]
  }
  return undefined
}

export const extractFileNameOrUseDefault = (
  data: AxiosResponse<any, any>,
  defaultName: string,
  defaultFileExtension: string,
): string | undefined => {
  let filename: string | undefined = extractFileNameFromContentDisposition(data)
  if (!filename) return `${defaultName}${defaultFileExtension}`
  return filename
}

export const getExtensionType = (contentType: string): string => {
  switch (contentType) {
    case 'text/csv':
      return 'csv'
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return 'xlsx'
    default:
      return 'csv'
  }
}

export const downloadFile = (
  response: AxiosResponse<any, any>,
  defaultFileName: string,
  defaultFileExtension: string,
  defaultContentType: string,
) => {
  // TODO get file disposition working
  if (!response.data) return

  let fileName = extractFileNameOrUseDefault(response, defaultFileName, defaultFileExtension)
  let contentType = extractContentTypeOrUseDefault(response, defaultContentType)

  const blob = new Blob([response.data], { type: contentType })
  createAndDownloadBlobFile(blob, `${defaultFileName}.${getExtensionType(contentType)}`)
}
