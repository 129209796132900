import { IDevelopmentDto, ITenantDto, TenantType } from 'api'
import autobind from 'autobind-decorator'

import { isNumber, toNumber } from 'modules/module-utils'
import { IUploadPreviewRequestDto, IUploadPreviewTenantDto } from '../api/dto'
import {
  ColumnAlignment,
  ContactPreference,
  FIELD_MATCHES,
  FieldViolation,
  FileParseResult,
  IdFrequencyMap,
  RecipientField,
  RecipientFile,
  RecipientType,
  RecipientUpload,
  Validation,
  requiredFields,
} from './types'
import { validateFieldValue } from './validation'

export class UploadingService {
  @autobind
  validateFile(input: any): FileParseResult {
    const isValidFile = this.isValidFile(input)
    if (isValidFile) {
      let hasAllFields = false
      input.forEach((element) => {
        const hasRequiredFields = Object.keys(element).length >= requiredFields.length
        if (hasRequiredFields) hasAllFields = hasRequiredFields
      })
      return hasAllFields ? FileParseResult.VALID : FileParseResult.MISSING_FIELDS
    } else {
      return FileParseResult.INVALID_FORMAT
    }
  }

  @autobind
  sanitiseFile(input: RecipientFile): [RecipientUpload, string[]] {
    const [processed, columns] = this.sanitiseColumns(input)
    const file: RecipientUpload = {}

    processed.forEach((row, index) => {
      const rowColumns = Object.keys(row)
      const processedRow: { [key: string]: string } = columns.reduce((accumulator, column) => {
        if (!rowColumns.includes(column)) {
          row[column] = undefined
        }

        const value =
          row[column] != undefined && typeof row[column] == 'string'
            ? row[column]?.trim()
            : row[column] != undefined && typeof row[column] == 'number'
            ? row[column]?.toString()
            : row[column]

        return { ...accumulator, [column]: value }
      }, {})

      file[index] = processedRow
    })

    return [file, columns]
  }

  @autobind
  sanitiseColumns(file: RecipientFile): [RecipientFile, string[]] {
    const columns = new Set<string>()
    const processed = file.map((row) => {
      const rowColumns = Object.keys(row)
      return rowColumns.reduce((newRow, column) => {
        const trimmed = column.trim()
        if (trimmed.length) columns.add(trimmed)
        return { ...newRow, [trimmed]: row[column] }
      }, {})
    })
    return [processed, Array.from(columns)]
  }

  @autobind
  alignColumns(columns: string[]): ColumnAlignment {
    const alignment = columns.reduce((accumulator, column) => {
      let field = this.matchColumn(column)

      const matchedColumns = Object.keys(accumulator)
      const matchExists = matchedColumns.reduce((matched, matchedColumn) => {
        return accumulator[matchedColumn] == field || matched
      }, false)

      if (!!field && !matchExists) {
        return { ...accumulator, [column]: field }
      } else {
        return { ...accumulator, [column]: RecipientField.UNKNOWN }
      }
    }, {})

    return alignment
  }

  @autobind
  isValidAlignment(alignment: ColumnAlignment): string[] {
    const values = Object.values(alignment)
    const missingFields: RecipientField[] = []
    requiredFields.forEach((required) => {
      if (!values.includes(required)) {
        missingFields.push(required)
      }
    })
    return missingFields
  }

  @autobind
  validateRows(
    uploadData: RecipientUpload,
    alignment: ColumnAlignment,
    sites: IDevelopmentDto[],
    sort = false,
  ): [Validation, RecipientUpload, string[], IdFrequencyMap] {
    const invalidValidation: Validation = {}
    const validValidation: Validation = {}

    const invalidInput: RecipientUpload = {}
    const validInput: RecipientUpload = {}

    const idFrequencyTable: IdFrequencyMap = this.buildIdFrequencyMap(uploadData, alignment)
    const rowIds = Object.keys(uploadData)

    rowIds.forEach((rowId) => {
      const row = uploadData[rowId]

      let hasInvalidField = false
      const validationRow = Object.keys(row)
        .filter((column) => {
          const chosen = alignment[column]
          return chosen != RecipientField.UNKNOWN
        })
        .reduce((acc, column) => {
          const chosen = alignment[column]!!
          const typeValue = this.getTenantField(RecipientField.TYPE, row, alignment)
          const processedValue = this.processFieldValue(chosen, row[column], sites)

          let fieldValidation = validateFieldValue(chosen, processedValue, typeValue, idFrequencyTable, sites)
          if (fieldValidation != undefined) hasInvalidField = true
          return { ...acc, [chosen]: fieldValidation }
        }, {} as Partial<Record<RecipientField, FieldViolation | undefined>>)

      if (hasInvalidField && sort) {
        invalidValidation[rowId] = validationRow
        invalidInput[rowId] = row
      } else {
        validValidation[rowId] = validationRow
        validInput[rowId] = row
      }
    })

    const inputOrder = Object.keys(invalidValidation).concat(Object.keys(validValidation))

    return [
      { ...invalidValidation, ...validValidation },
      { ...invalidInput, ...validInput },
      inputOrder,
      idFrequencyTable,
    ]
  }

  @autobind
  adjustAndValidateRow(
    newValue: string,
    rowId: string,
    column: string,
    input: RecipientUpload,
    validation: Validation,
    alignment: ColumnAlignment,
    idFrequencyMap: IdFrequencyMap,
    sites: IDevelopmentDto[],
  ): [Validation, RecipientUpload, IdFrequencyMap] {
    let newValidation = { ...validation }
    let newIdFrequencyMap = { ...idFrequencyMap }
    const chosenColumn = Object.keys(alignment).find((key) => alignment[key] === column)!!

    // process id frequency
    if (alignment[chosenColumn] === RecipientField.ID) {
      const row = input[rowId]
      const prevId = row[chosenColumn]

      if (newIdFrequencyMap[prevId].length > 1) {
        newIdFrequencyMap[prevId] = newIdFrequencyMap[prevId].filter((id) => id != rowId)
      } else {
        delete newIdFrequencyMap[prevId]
      }

      if (newIdFrequencyMap[newValue]) {
        newIdFrequencyMap[newValue] = [...newIdFrequencyMap[newValue], rowId]
      } else {
        newIdFrequencyMap[newValue] = [rowId]
      }

      // recalculate validation for rows associated with previous id
      newValidation = this.validateIdFrequencies(newValidation, newIdFrequencyMap, prevId, sites)
      // calculate validation for rows associated with new id
      newValidation = this.validateIdFrequencies(newValidation, newIdFrequencyMap, newValue, sites)
    }

    // update value
    const newInput = { ...input, [rowId]: { ...input[rowId], [chosenColumn]: newValue } }

    newValidation[rowId] = Object.keys(newInput[rowId])
      .filter((column) => {
        const chosen = alignment[column]
        return chosen != RecipientField.UNKNOWN
      })
      .reduce((acc, column) => {
        const chosen = alignment[column]!!
        const typeValue = this.getTenantField(RecipientField.TYPE, newInput[rowId], alignment)
        const processedValue = this.processFieldValue(chosen, newInput[rowId][column], sites)

        let fieldValidation = validateFieldValue(chosen, processedValue, typeValue, newIdFrequencyMap, sites)
        return { ...acc, [chosen]: fieldValidation }
      }, {} as Partial<Record<RecipientField, FieldViolation | undefined>>)

    return [newValidation, newInput, newIdFrequencyMap]
  }

  /**
   * Processes the raw values from the CSV into a format that the validator can understand
   * @param field The field type to process
   * @param value The raw value
   * @param sites The sites to be used for site associated fields processing
   * @returns processed value
   */
  @autobind
  processFieldValue(field: RecipientField, value: string | undefined, sites: IDevelopmentDto[]): string | undefined {
    if (!value) {
      return value
    } else if (field == RecipientField.SITE) {
      if (isNumber(value)) {
        return value
      }
      const foundSite = sites.find((site) => site.buildingName.toLocaleLowerCase() == value.trim().toLocaleLowerCase())
      return foundSite?.id.toString() ?? value
    } else if (field == RecipientField.ADDITIONAL_SITES) {
      if (isNumber(value)) {
        return value
      }
      const splitSites = value.split(',').map((site) => site.trim())
      if (splitSites.length) {
        const containsSiteIds = splitSites.every((site) => isNumber(site))
        if (containsSiteIds) {
          return splitSites.join(',')
        } else {
          // process site names into ID's
          const validNames = splitSites.every((siteValue) =>
            sites.some((site) => site.buildingName.toLocaleLowerCase() == siteValue.trim().toLocaleLowerCase()),
          )

          if (validNames) {
            return splitSites
              .map((siteValue) => {
                const foundSite = sites.find(
                  (site) => site.buildingName.toLocaleLowerCase() == siteValue.trim().toLocaleLowerCase(),
                )
                return foundSite?.id.toString()
              })
              .join(',')
          } else {
            return value
          }
        }
      } else {
        return value
      }
    }
    return value
  }

  /**
   * Generate a Set containing a mapping of RecipientField and corrisponding counter of failed validations
   * @param validation
   * @returns
   */
  @autobind
  generateValidationCounterSet(validation: Validation): [Set<RecipientField>, number] {
    let invalidFields: Set<RecipientField> = new Set()
    let numInvalidRows = 0

    Object.keys(validation).forEach((rowId) => {
      const row = validation[rowId]
      let isRowInvalid = false
      Object.keys(row).forEach((column) => {
        if (row[column] != undefined) {
          invalidFields.add(column as RecipientField)
          isRowInvalid = true
        }
      })
      if (isRowInvalid) numInvalidRows += 1
    })

    return [invalidFields, numInvalidRows]
  }

  /**
   * Determine if all rows have a specified and valid site
   * @param input
   * @param alignment
   * @returns
   */
  @autobind
  checkSiteSelectionRequired(input: RecipientUpload, alignment: ColumnAlignment): boolean {
    let isValid = true

    const inputKeys = Object.keys(input)
    for (let index = 0; index < inputKeys.length; index++) {
      const row = input[inputKeys[index]]
      const rowColumns = Object.keys(row)

      const siteColumn = rowColumns.find((column) => {
        const chosen = alignment[column]!!
        return chosen == RecipientField.SITE
      })

      if (siteColumn == undefined) {
        isValid = false
        break
      } else {
        if (row[siteColumn] == undefined || row[siteColumn] == '') {
          isValid = false
          break
        }
      }
    }
    return isValid
  }

  @autobind
  createPreviewRecipients(
    input: RecipientUpload,
    alignment: ColumnAlignment,
    defaultDevelopmentId: number,
    sites: IDevelopmentDto[],
    multimailroomEnabled: boolean,
  ): IUploadPreviewRequestDto {
    const dto = new IUploadPreviewRequestDto()
    const recipients: IUploadPreviewTenantDto[] = []

    const inputKeys = Object.keys(input)
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let index = 0; index < inputKeys.length; index++) {
      const row = input[inputKeys[index]]
      const rowColumns = Object.keys(row)

      const externalIdColumn = rowColumns.find((column) => {
        const chosen = alignment[column]!!
        return chosen == RecipientField.ID
      })

      let externalId: string | undefined = undefined
      if (externalIdColumn != undefined) {
        externalId = this.processFieldValue(RecipientField.ID, row[externalIdColumn], sites)
      }

      const tenant: IUploadPreviewTenantDto = new IUploadPreviewTenantDto()
      tenant.externalId = externalId

      const siteColumn = rowColumns.find((column) => {
        const chosen = alignment[column]!!
        return chosen == RecipientField.SITE
      })

      let primarySite: number = defaultDevelopmentId

      if (siteColumn != undefined) {
        const siteValue = this.processFieldValue(RecipientField.SITE, row[siteColumn], sites)
        if (!!siteValue) {
          primarySite = toNumber(siteValue)
        } else {
          primarySite = defaultDevelopmentId
        }
      }

      let additionalSites: number[] = []

      const additionalSiteColumn = rowColumns.find((column) => {
        const chosen = alignment[column]!!
        return chosen == RecipientField.ADDITIONAL_SITES
      })

      if (additionalSiteColumn != undefined) {
        const additionalSiteIds = this.processFieldValue(
          RecipientField.ADDITIONAL_SITES,
          row[additionalSiteColumn],
          sites,
        )
        if (!!additionalSiteIds && multimailroomEnabled) {
          const splitSites = additionalSiteIds
            .split(',')
            .map((siteId) => siteId.trim())
            .map((siteId) => toNumber(siteId))
          additionalSites = splitSites
        }
      }

      tenant.developmentIds = [...new Set([primarySite, ...additionalSites])]
      recipients.push(tenant)
    }

    dto.tenants = recipients
    return dto
  }

  @autobind
  createRecipients(
    input: RecipientUpload,
    alignment: ColumnAlignment,
    defaultDevelopmentId: number,
    sites: IDevelopmentDto[],
    multimailroomEnabled: boolean,
  ): ITenantDto[] {
    const recipients: ITenantDto[] = Object.values(input).map((row) => {
      const tenant: ITenantDto = new ITenantDto()
      tenant.id = '00000000-0000-0000-0000-000000000000'
      tenant.id2 = this.getTenantField(RecipientField.ID, row, alignment) || undefined
      tenant.firstName = this.getTenantField(RecipientField.FIRST_NAME, row, alignment)!!
      tenant.lastName = this.getTenantField(RecipientField.LAST_NAME, row, alignment) || ''
      tenant.alias = this.getTenantField(RecipientField.NICK_NAME, row, alignment)
      tenant.email = this.getTenantField(RecipientField.EMAIL, row, alignment)!!
      tenant.dateOfBirth = undefined
      tenant.phone = this.getTenantField(RecipientField.PHONE, row, alignment)
      tenant.room = this.getTenantField(RecipientField.LOCATION, row, alignment)!!
      tenant.notificationOptions = this.getNotificationOption(
        this.getTenantField(RecipientField.CONTACT_PREFERENCE, row, alignment),
      )
      tenant.type = this.getRecipientTypeOption(this.getTenantField(RecipientField.TYPE, row, alignment))
      tenant.dropoffLocationName = this.getTenantField(RecipientField.DROP_OFF, row, alignment)
      tenant.gdprComply = true

      const siteRaw = this.getTenantField(RecipientField.SITE, row, alignment)
      const additionalRaw = this.getTenantField(RecipientField.ADDITIONAL_SITES, row, alignment)

      if (!!siteRaw) {
        const siteId = this.processFieldValue(RecipientField.SITE, siteRaw, sites)
        if (!!siteId) {
          tenant.primarySite = toNumber(siteId)
        }
      }
      if (tenant.primarySite == undefined) {
        tenant.primarySite = defaultDevelopmentId
      }

      if (multimailroomEnabled && additionalRaw != undefined) {
        const additionalSiteIds = this.processFieldValue(RecipientField.ADDITIONAL_SITES, additionalRaw, sites)
        if (!!additionalSiteIds) {
          const splitSites = additionalSiteIds
            .split(',')
            .map((siteId) => siteId.trim())
            .map((siteId) => toNumber(siteId))

          const filteredSites = splitSites.filter((site) => site != tenant.primarySite)
          tenant.additionalSites = [...new Set(filteredSites)]
        }
      }

      return tenant
    })

    return recipients
  }

  @autobind
  private buildIdFrequencyMap(input: RecipientUpload, alignment: ColumnAlignment): IdFrequencyMap {
    const idFrequencyMap: IdFrequencyMap = {}
    const rowIds = Object.keys(input)

    const idColumn = Object.keys(alignment).find((key) => alignment[key] === RecipientField.ID)

    if (!!idColumn) {
      rowIds.forEach((rowId) => {
        const row = input[rowId]

        const id = row[idColumn]
        if (id) {
          if (idFrequencyMap[id]?.length) idFrequencyMap[id].push(rowId)
          else idFrequencyMap[id] = [rowId]
        }
      })
    }

    return idFrequencyMap
  }

  @autobind
  private validateIdFrequencies(
    validation: Validation,
    idFrequencyMap: IdFrequencyMap,
    id: string,
    sites: IDevelopmentDto[],
  ): Validation {
    const newValidation = { ...validation }
    const rowIds = idFrequencyMap[id]
    rowIds?.forEach((rowId) => {
      newValidation[rowId] = {
        ...validation[rowId],
        [RecipientField.ID]: validateFieldValue(RecipientField.ID, id, undefined, idFrequencyMap, sites),
      }
    })

    return newValidation
  }

  @autobind
  private getNotificationOption(preference: string | undefined): number {
    if (preference == undefined) {
      return 0
    } else if (Object.values(ContactPreference).includes(preference as any)) {
      if (preference == ContactPreference.EMAIL) {
        return 0
      } else {
        return 2
      }
    } else {
      return 0
    }
  }

  @autobind
  private getRecipientTypeOption(preference: string | undefined): TenantType {
    if (preference == undefined) {
      return TenantType.INDIVIDUAL
    } else if (Object.values(RecipientType).includes(preference as any)) {
      if (preference == RecipientType.COMPANY) {
        return TenantType.COMPANY
      } else {
        return TenantType.INDIVIDUAL
      }
    } else {
      return TenantType.INDIVIDUAL
    }
  }

  @autobind
  private getTenantField(
    field: RecipientField,
    row: { [key: string]: string | undefined },
    alignment: ColumnAlignment,
  ): string | undefined {
    const alignmentFields = Object.values(alignment)
    if (alignmentFields.includes(field)) {
      const rawColumn = Object.keys(alignment).find((key) => alignment[key] === field)!!
      return !!row[rawColumn] && !!row[rawColumn]?.length ? row[rawColumn]?.trim() : undefined
    } else {
      return undefined
    }
  }

  @autobind
  private isValidFile(input: any): boolean {
    return !!Array.isArray(input) && !!Object.keys(input[0]).length
  }

  @autobind
  private matchColumn(column: string): RecipientField | undefined {
    const sanitisedColumn = column.toLowerCase()
    const possibleColumns = Object.keys(FIELD_MATCHES)
    let matchedColumn: RecipientField | undefined = undefined

    for (let index in possibleColumns) {
      const row: string[] = FIELD_MATCHES[possibleColumns[index]]
      if (row.includes(sanitisedColumn)) {
        matchedColumn = possibleColumns[index] as RecipientField
        break
      }
    }

    return matchedColumn
  }
}
