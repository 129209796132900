import reducer from './root-reducer'
import dashboardSagas from './sagas'
import upload from './upload'

export * from './screens'

export const recipientsUpload = {
  actions: upload.actions,
  reducer,
  sagas: dashboardSagas,
}

export default recipientsUpload
