import * as React from 'react'
import { useSelector } from 'react-redux'

import { useAppDispatch } from 'modules/module-utils'
import { Banner } from 'modules/web-atoms'

import * as actions from '../../actions'
import { banner } from '../../selectors'

export interface IBannerContainer extends Partial<React.ComponentProps<typeof Banner>> {}

export const BannerContainer: React.FC<IBannerContainer> = (props) => {
  const dispatch = useAppDispatch()
  const bannerProps = useSelector(banner)

  const onDismissClick = React.useCallback(() => {
    bannerProps && dispatch(actions.bannerHide(bannerProps?.id))
  }, [bannerProps, dispatch])

  const onActionClick = React.useCallback(() => {
    dispatch(actions.bannerAction())
  }, [dispatch])

  return (
    <>
      {bannerProps && <Banner onActionClick={onActionClick} onDismiss={onDismissClick} {...bannerProps} {...props} />}
    </>
  )
}
