import * as React from 'react'

import { EuiCheckboxGroup } from '@elastic/eui'
import styled from '@emotion/styled'

import { StyledViewProps } from 'modules/web-atoms'
import * as system from 'styled-system'

export type ICheckboxGroupProps = StyledViewProps & React.ComponentProps<typeof EuiCheckboxGroup>

export const StyledCheckboxGroup = styled(EuiCheckboxGroup)<ICheckboxGroupProps>(
  system.color,
  system.flexbox,
  system.space,
  system.layout,
  system.position,
  system.justifyContent,
  system.alignItems,
  system.borders,
  system.shadow,
  system.background,
)

export const CheckboxGroup: React.FC<ICheckboxGroupProps> = (props) => {
  const { children, ...rest } = props
  return <StyledCheckboxGroup {...rest}>{children}</StyledCheckboxGroup>
}

export default React.memo(CheckboxGroup)
