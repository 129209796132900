export enum Color {
  Transparent = 'transparent',
  Black = '#000',
  White = '#fff',

  // Deepfinity theme
  PrimaryForeground = '#1c1c1c',
  PrimaryBackground = '#ffffff',
  PrimaryBackgroundMid = '#fafaff',
  PrimaryBackgroundDark = '#F9F9FB',

  PrimaryLight = '#f6f7fa',
  PrimaryMid = '#1f818c',
  PrimaryDark = '#196770',

  SecondaryLight = '#edf1f6',
  SecondaryMid = '#EBEBEB',
  SecondaryMidDark = '#E4E7EA',
  SecondaryDark = '#C0C1C4',

  BorderLight = '#DFE2F0',

  AccentLight = '#f7da4b',
  AccentMid = '#29B6F6',

  TextLight = '#FFFFFF',
  TextLightMid = '#767676',
  TextMid = '#7383A1',
  TextMidDark = '#465063',
  TextDark = '#1c1c1c',
  TextDestructive = '#000000',
  TextPurple = '#6070C7',
  ButterSquashOrange = '#FFB13D',
  WatermelonRed = '#FF5D6B',

  InfoLight = '#B8DCE1',
  InfoMid = '#79b3ba',
  InfoDark = '#62a7af',

  SuccessLight = '#65B0B8',
  SuccessMidLight = '#66CC36',
  SuccessMid = '#2A838E',
  SuccessDark = '#196770',

  WarningLight = '#FFB589',
  WarningMid = '#FFB13D',
  WarningDark = '#F17D39',
  WarningDestructive = '#D65B12',

  DangerLight = '#FF9BA2',
  DangerMid = '#FF6873',
  DangerDark = '#FF3D4D',
  DangerDestructive = '#BD271E',

  GridLine = '#EEF0F3',

  EmailTemplateCodeBackground = '#eee'
}

export type SemanticColor =
  | 'none'
  | 'black'
  | 'white'
  | 'primaryForeground'
  | 'primaryBackground'
  | 'primaryBackgroundMid'
  | 'primaryBackgroundDark'
  | 'primaryLight'
  | 'primaryMid'
  | 'primaryDark'
  | 'secondaryLight'
  | 'secondaryMid'
  | 'secondaryMidDark'
  | 'secondaryDark'
  | 'accentLight'
  | 'accentMid'
  | 'textLight'
  | 'textLightMid'
  | 'textMid'
  | 'textMidDark'
  | 'textDark'
  | 'textDestructive'
  | 'textPurple'
  | 'butterSquashOrange'
  | 'watermelonRed'
  | 'infoLight'
  | 'infoMid'
  | 'infoDark'
  | 'successLight'
  | 'successMidLight'
  | 'successMid'
  | 'successDark'
  | 'warningLight'
  | 'warningMid'
  | 'warningDark'
  | 'warningDestructive'
  | 'dangerLight'
  | 'dangerMid'
  | 'dangerDark'
  | 'dangerDestructive'
  | 'gridLine'
  | 'borderLight'

export type SemanticColors = Record<SemanticColor, Color>

export const defaultColors: SemanticColors = {
  none: Color.Transparent,
  black: Color.Black,
  white: Color.White,
  primaryForeground: Color.PrimaryForeground,
  primaryBackground: Color.PrimaryBackground,
  primaryBackgroundMid: Color.PrimaryBackgroundMid,
  primaryBackgroundDark: Color.PrimaryBackgroundDark,
  primaryLight: Color.PrimaryLight,
  primaryMid: Color.PrimaryMid,
  primaryDark: Color.PrimaryDark,
  secondaryLight: Color.SecondaryLight,
  secondaryMid: Color.SecondaryMid,
  secondaryMidDark: Color.SecondaryMidDark,
  secondaryDark: Color.SecondaryDark,
  accentLight: Color.AccentLight,
  accentMid: Color.AccentMid,
  textLight: Color.TextLight,
  textLightMid: Color.TextLightMid,
  textMid: Color.TextMid,
  textMidDark: Color.TextMidDark,
  textDark: Color.TextDark,
  textDestructive: Color.TextDestructive,
  textPurple: Color.TextPurple,
  butterSquashOrange: Color.ButterSquashOrange,
  watermelonRed: Color.WatermelonRed,
  infoLight: Color.InfoLight,
  infoMid: Color.InfoMid,
  infoDark: Color.InfoDark,
  successLight: Color.SuccessLight,
  successMidLight: Color.SuccessMidLight,
  successMid: Color.SuccessMid,
  successDark: Color.SuccessDark,
  warningLight: Color.WarningLight,
  warningMid: Color.WarningMid,
  warningDark: Color.WarningDark,
  warningDestructive: Color.WarningDestructive,
  dangerLight: Color.DangerLight,
  dangerMid: Color.DangerMid,
  dangerDark: Color.DangerDark,
  dangerDestructive: Color.DangerDestructive,
  gridLine: Color.GridLine,
  borderLight: Color.BorderLight,
}
