import * as React from 'react'

import { EuiButton } from '@elastic/eui'

import { Error } from 'modules/dna'
import { Grid, H1, H3, Image, Modal, useTranslate } from 'modules/web-atoms'

import { Trans } from 'react-i18next'
import { RecipientField } from '../../../uploading/types'

const INTL_PREFIX = 'recipients-upload.validate-rows.warning-modal'

export interface IInvalidFieldWarningModalProps extends Omit<React.ComponentProps<typeof Modal>, 'children'> {
  onConfirm: () => void
  fields: RecipientField[]
}

export const InvalidFieldWarningModal: React.FC<IInvalidFieldWarningModalProps> = ({
  onConfirm,
  onClose,
  fields,
  ...rest
}) => {
  const t = useTranslate(INTL_PREFIX)
  const tCommon = useTranslate('commons')

  const missingFields = React.useMemo(() => {
    return fields.map((field) => t(`field.${field}`)).join(', ')
  }, [fields, t])

  return (
    <Modal borderRadius="xxlarge" onClose={onClose} width={['100%', 580]} height="100%" {...rest}>
      <Grid
        paddingX="xxlarge"
        paddingTop="large"
        alignItems="center"
        paddingBottom="medium"
        className="eui-yScroll"
        flex={1}
      >
        <Grid.Row flex={1}>
          <H1 textAlign="center">{t('heading')}</H1>
        </Grid.Row>
        <Grid.Row flex={1} mt="small">
          <H3 textAlign="center">
            <Trans
              i18nKey={`${INTL_PREFIX}.subheading`}
              components={{
                span: <br />,
              }}
            />
          </H3>
        </Grid.Row>
        <Grid.Row flex={1}>
          <H3 textAlign="center" color="dangerDestructive" fontWeight="bold">
            {missingFields}
          </H3>
        </Grid.Row>
        <Grid.Row marginTop="medium" flex={1}>
          <Image src={Error} />
        </Grid.Row>
        <Grid.Row flex={1} mt="small">
          <H3 textAlign="center">{t('message')}</H3>
        </Grid.Row>
        <Grid.Row marginTop="large" flex={1}>
          <Grid gutterSize="medium">
            <Grid.Col>
              <EuiButton color="primary" fill onClick={onConfirm}>
                {tCommon('action.confirm')}
              </EuiButton>
            </Grid.Col>

            <Grid.Col>
              <EuiButton color="danger" onClick={onClose} fill>
                {tCommon('action.cancel')}
              </EuiButton>
            </Grid.Col>
          </Grid>
        </Grid.Row>
      </Grid>
    </Modal>
  )
}

export default React.memo(InvalidFieldWarningModal)
