import * as React from 'react'

import { IDeepfinityTheme, SemanticSpacing } from 'modules/dna'
import { ResponsiveValue } from 'styled-system'

import useScreen from '../../hooks/use-screen/use-screen'
import GridCol from './GridCol/GridCol'
import GridRow from './GridRow/GridRow'
import StyledView, { StyledViewProps } from './StyledView'

export type GridDirection = 'column' | 'row'

const isCol = ({ type }: React.ReactElement) => type === Grid.Col

export interface IGridProps extends StyledViewProps {
  isResponsive?: boolean
  gutterSize?: ResponsiveValue<SemanticSpacing, IDeepfinityTheme>
  // Will override default direction. Responsive values will have to be provided
  direction?: ResponsiveValue<GridDirection, IDeepfinityTheme>
  growItems?: boolean
}

interface IGrid extends React.FC<IGridProps> {
  Col: typeof GridCol
  Row: typeof GridRow
}

export const Grid: IGrid = (props) => {
  const { isResponsive, children, gutterSize, direction: propDirection, growItems, ...rest } = props

  const { isMobile } = useScreen()

  const computedDirection: GridDirection = React.useMemo(() => {
    return isMobile && !!isResponsive
      ? 'column'
      : React.Children.toArray(children).filter(React.isValidElement).filter(isCol).length
      ? 'row'
      : 'column'
  }, [children, isMobile, isResponsive])
  const direction = propDirection || computedDirection

  const items = React.Children.toArray(children).filter<React.ReactElement>(React.isValidElement)

  return (
    <StyledView margin="none" padding="none" flexDirection={direction} display="flex" gridGap={gutterSize} {...rest}>
      {items.map((item) => {
        return React.cloneElement(item, {
          flexGrow: growItems ? 1 : undefined,
          ...item.props,
        })
      })}
    </StyledView>
  )
}

Grid.Col = GridCol
Grid.Row = GridRow

Grid.displayName = 'Grid'
Grid.defaultProps = {
  gutterSize: 'smallest',
}

export default Grid
