import * as React from 'react'

import { EuiFieldPassword, EuiFieldPasswordProps } from '@elastic/eui'
import styled from '@emotion/styled'

import { StyledViewProps } from 'modules/web-atoms'
import * as system from 'styled-system'

export type IFieldPasswordProps = StyledViewProps & EuiFieldPasswordProps

export const StyledFieldPassword = styled(EuiFieldPassword)<IFieldPasswordProps>(
  system.color,
  system.flexbox,
  system.space,
  system.layout,
  system.position,
  system.justifyContent,
  system.alignItems,
  system.borders,
  system.shadow,
  system.background,
)

export const FieldPassword: React.FC<IFieldPasswordProps> = (props) => {
  const { children, ...rest } = props
  return <StyledFieldPassword {...rest}>{children}</StyledFieldPassword>
}

export default React.memo(FieldPassword)
