import { Expose, Type } from 'class-transformer'
import 'reflect-metadata'
import { Currency } from '../billing'

export class ICardSetupDto {
  @Expose({ name: 'RedirectUrl' })
  redirectUrl: string
}

export enum PaymentStatus {
  PAID = 'PAID',
  OVERDUE = 'OVERDUE',
}

export enum PlanType {
  FREE = 'FREE',
  TRIAL = 'TRIAL',
  PRO = 'PRO',
  PRO_MULTISITE = 'PRO_MULTISITE',
  ENTERPRISE = 'ENTERPRISE',
}

export class PlanStatusDto {
  @Expose({ name: 'ClientId' })
  clientId: number
  @Expose({ name: 'ContractStartDate' })
  contractStartDate: string
  @Expose({ name: 'CurrentBillingPeriodStart' })
  currentBillingPeriodStart: string
  @Expose({ name: 'NextBillingPeriod' })
  nextBillingPeriod: string
  @Expose({ name: 'Currency' })
  currency?: Currency
  @Expose({ name: 'CurrentPlan' })
  currentPlan: PlanType
  @Expose({ name: 'PaymentStatus' })
  paymentStatus: PaymentStatus
  @Expose({ name: 'DaysLeftInTrial' })
  daysLeftInTrial?: number
  @Expose({ name: 'DaysLeftInGracePeriod' })
  daysLeftInGracePeriod?: number
  @Expose({ name: 'LimitInboundParcelsScanning' })
  limitInboundParcelsScanning: boolean
  @Expose({ name: 'LimitOutboundParcelsScanning' })
  limitOutboundParcelsScanning: boolean
  @Expose({ name: 'ScannedInboundParcelsInBillingPeriod' })
  scannedInboundParcelsInBillingPeriod: number
  @Expose({ name: 'ScannedOutboundParcelsInBillingPeriod' })
  scannedOutboundParcelsInBillingPeriod: number
  @Expose({ name: 'ManualOverride' })
  manualOverride: boolean
  @Expose({ name: 'HasBeenPaidClient' })
  hasBeenPaidClient: boolean
  @Expose({ name: 'SmsEnabled' })
  smsEnabled: boolean
}

export class UsageBreakdownDto {
  @Expose({ name: 'BuildingId' })
  buildingId: number
  @Expose({ name: 'BuildingName' })
  buildingName: string
  @Expose({ name: 'RecipientsCount' })
  recipientsCount: number
  @Expose({ name: 'RecipientsIncluded' })
  recipientsIncluded: number
  @Expose({ name: 'InboundParcelsScanned' })
  inboundParcelsScanned: number
  @Expose({ name: 'OutboundParcelsScanned' })
  outboundParcelsScanned: number
  @Expose({ name: 'SmsSent' })
  smsSent: number
  @Expose({ name: 'SmsTotal' })
  smsTotal: number
  @Expose({ name: 'RecipientExcessTotal' })
  recipientExcessTotal: number
  @Expose({ name: 'BuildingTotal' })
  buildingTotal: number
}

export class UsageReportDto {
  @Expose({ name: 'PeriodStart' })
  periodStart: string
  @Expose({ name: 'PeriodEnd' })
  periodEnd: string
  @Expose({ name: 'LastUpdate' })
  lastUpdate: string
  @Expose({ name: 'ClientId' })
  clientId: number
  @Expose({ name: 'CostPerBuilding' })
  costPerBuilding: number
  @Expose({ name: 'CostPerRecipientExcess' })
  costPerRecipientExcess: number
  @Expose({ name: 'CostPerSmsNotification' })
  costPerSmsNotification: number
  @Expose({ name: 'TotalCostAllBuildings' })
  totalCostAllBuildings: number
  @Expose({ name: 'UsageBreakdowns' })
  @Type(() => UsageBreakdownDto)
  usageBreakdowns: UsageBreakdownDto[]
}

export class UsageEntryDto {
  @Expose({ name: 'BuildingId' })
  buildingId: number
  @Expose({ name: 'BuildingName' })
  buildingName: string
  @Expose({ name: 'RecipientsCount' })
  recipientsCount: number
  @Expose({ name: 'InboundParcelsScanned' })
  inboundParcelsScanned: number
  @Expose({ name: 'OutboundParcelsScanned' })
  outboundParcelsScanned: number
  @Expose({ name: 'SmsSent' })
  smsSent: number
}

export class UsageDto {
  @Expose({ name: 'ClientId' })
  clientId: number
  @Expose({ name: 'UsageBreakdowns' })
  @Type(() => UsageEntryDto)
  usageBreakdowns: UsageEntryDto[]
}

export class CreatePaymentIntentDto {
  @Expose({ name: 'ClientId' })
  clientId: number
}

export class RecipientCountDto {
  @Expose({ name: 'BuildingId' })
  buildingId: number
  @Expose({ name: 'BuildingName' })
  buildingName: string
  @Expose({ name: 'TenantCount' })
  recipientCount: number
}

export class CheckoutDto {
  @Expose({ name: 'RedirectUrl' })
  redirectUrl: string
}

export class SubmitContactFormDto {
  @Expose({ name: 'Plan' })
  plan: PlanType
  @Expose({ name: 'Sites' })
  sites: number
  @Expose({ name: 'Email' })
  email: string
  @Expose({ name: 'Mobile' })
  mobile: string
  @Expose({ name: 'ClientId' })
  clientId: number
}

export class BillingPortalAccessDto {
  @Expose({ name: 'ClientId' })
  clientId: string
  @Expose({ name: 'UrlRedirect' })
  urlRedirect: string
}

export class BillingPortalAccessResponseDto {
  @Expose({ name: 'Url' })
  url: string
}
