import autobind from 'autobind-decorator'

import { IAnalyticsService, IIdentityParams, ITrackParams } from '../model'

import { heapUtil } from './heap'

export class HeapService implements IAnalyticsService {
  async init() {
    heapUtil.getHeap()
  }

  async identify({ userId }: IIdentityParams): Promise<void> {
    heapUtil.getHeap()?.identify(userId)
  }

  @autobind
  async track({ event, properties }: ITrackParams) {
    if (properties) {
      heapUtil.evaluateHeapCall((heap) => heap.track(event, flatten(properties)))
    } else {
      heapUtil.evaluateHeapCall((heap) => heap.track(event, {}))
    }
  }

  async flush(): Promise<void> {
    heapUtil.getHeap()?.resetIdentity()
  }

  async clean(): Promise<void> {
    heapUtil.getHeap()?.resetIdentity()
  }
}

const flatten = (obj, prefix = '', res = {}) =>
  Object.entries(obj).reduce((r, [key, val]) => {
    const k = `${prefix}${key}`
    if (typeof val === 'object') {
      flatten(val, `${k}.`, r)
    } else {
      res[k] = val
    }
    return r
  }, res)

export default HeapService
