import { createAction } from '@reduxjs/toolkit'
import prefix from 'modules/utils/prefix'
import { ICourierFormValues } from '../screens/SettingsPage/CouriersTab/CouriersForm'
import { IDropoffLocationFormValues } from '../screens/SettingsPage/DropoffLocationsTab/DropoffLocationForm'
import { ILocationFormValues } from '../screens/SettingsPage/LocationsTab/LocationForm'
import { ISizeFormValues } from '../screens/SettingsPage/SizesTab/SizeForm'
import {
  ICourier,
  IDropoffLocation,
  ILocation,
  ISetFeatureFlagLoadingPayload,
  ISetFeatureFlagPayload,
  ISetPermissionsPayload,
  ISetSelectedTableItems,
  ISetTagCreationPayload,
  ISetTagStatePayload,
  ISize,
  ITagItem,
  SettingsFormState,
} from './models'

export const namespaced = prefix('settings')

export const SET_PERMISSIONS = namespaced('setPermissions')
export const setPermissions = createAction<ISetPermissionsPayload>(SET_PERMISSIONS)

export const SET_FEATURE_FLAG = namespaced('setFeatureFlag')
export const setFeatureFlag = createAction<ISetFeatureFlagPayload>(SET_FEATURE_FLAG)

export const SET_LOADING_FLAG = namespaced('setLoadingFlag')
export const setLoadingFlag = createAction<ISetFeatureFlagLoadingPayload>(SET_LOADING_FLAG)

// Tags

export const SHOW_ADD_TAG_FORM = namespaced('showAddTagForm')
export const showAddTagForm = createAction<boolean>(SHOW_ADD_TAG_FORM)

export const SET_TAG_CREATION = namespaced('setTagCreation')
export const setTagCreation = createAction<ISetTagCreationPayload>(SET_TAG_CREATION)

export const SET_IS_DELETING_TAG = namespaced('setIsDeletingTag')
export const setIsDeletingTag = createAction<ISetTagStatePayload>(SET_IS_DELETING_TAG)

export const SET_IS_UPDATING_TAG = namespaced('setIsUpdatingTag')
export const setIsUpdatingTag = createAction<ISetTagStatePayload>(SET_IS_UPDATING_TAG)

export const SET_IS_EDITING_TAG = namespaced('setIsEditingTag')
export const setIsEditingTag = createAction<ISetTagStatePayload>(SET_IS_EDITING_TAG)

export const FETCH_TAGS_RESULT = namespaced('fetchTagsResult')
export const fetchTagsResult = createAction<{ success: boolean }>(FETCH_TAGS_RESULT)

export const UPDATE_TAG = namespaced('updateTag')
export const updateTag = createAction<ITagItem>(UPDATE_TAG)

export const ADD_TAG = namespaced('addTag')
export const addTag = createAction<Omit<ITagItem, 'id'>>(ADD_TAG)

export const DELETE_TAG = namespaced('deleteTag')
export const deleteTag = createAction<number>(DELETE_TAG)

// Locations

export const SET_SELECTED_LOCATION = namespaced('setSelectedLocation')
export const setSelectedLocation = createAction<ILocation | undefined>(SET_SELECTED_LOCATION)

export const SET_SELECTED_LOCATIONS = namespaced('setSelectedLocations')
export const setSelectedLocations = createAction<ISetSelectedTableItems>(SET_SELECTED_LOCATIONS)

export const SET_SELECTED_SITE = namespaced('setSelectedSite')
export const setSelectedSite = createAction<number>(SET_SELECTED_SITE)

export const SET_LOCATION_FORM_STATE = namespaced('setLocationFormState')
export const setLocationFormState = createAction<SettingsFormState>(SET_LOCATION_FORM_STATE)

export const SET_IS_ADDING_LOCATION = namespaced('setIsAddingLocation')
export const setIsAddingLocation = createAction<boolean>(SET_IS_ADDING_LOCATION)

export const SET_IS_EDITING_LOCATION = namespaced('setIsEditingLocation')
export const setIsEditingLocation = createAction<boolean>(SET_IS_EDITING_LOCATION)

export const SET_IS_DELETING_LOCATIONS = namespaced('setIsDeletingLocations')
export const setIsDeletingLocations = createAction<boolean>(SET_IS_DELETING_LOCATIONS)

export const ADD_LOCATION = namespaced('addLocation')
export const addLocation = createAction<ILocationFormValues>(ADD_LOCATION)

export const EDIT_LOCATION = namespaced('editLocation')
export const editLocation = createAction<ILocationFormValues>(EDIT_LOCATION)

export const DELETE_LOCATIONS = namespaced('deleteLocations')
export const deleteLocations = createAction(DELETE_LOCATIONS)

// Couriers

export const SET_SELECTED_COURIER = namespaced('setSelectedCourier')
export const setSelectedCourier = createAction<ICourier | undefined>(SET_SELECTED_COURIER)

export const SET_SELECTED_COURIERS = namespaced('setSelectedCouriers')
export const setSelectedCouriers = createAction<ISetSelectedTableItems>(SET_SELECTED_COURIERS)

export const SET_COURIER_FORM_STATE = namespaced('setCourierFormState')
export const setCourierFormState = createAction<SettingsFormState>(SET_COURIER_FORM_STATE)

export const SET_IS_ADDING_COURIER = namespaced('setIsAddingCourier')
export const setIsAddingCourier = createAction<boolean>(SET_IS_ADDING_COURIER)

export const SET_IS_EDITING_COURIER = namespaced('setIsEditingCourier')
export const setIsEditingCourier = createAction<boolean>(SET_IS_EDITING_COURIER)

export const SET_IS_DELETING_COURIERS = namespaced('setIsDeletingCouriers')
export const setIsDeletingCouriers = createAction<boolean>(SET_IS_DELETING_COURIERS)

export const ADD_COURIER = namespaced('addCourier')
export const addCourier = createAction<ICourierFormValues>(ADD_COURIER)

export const EDIT_COURIER = namespaced('editCourier')
export const editCourier = createAction<ICourierFormValues>(EDIT_COURIER)

export const DELETE_COURIERS = namespaced('deleteCouriers')
export const deleteCouriers = createAction(DELETE_COURIERS)

// Dropoff Locations

export const SET_SELECTED_DROPOFF_LOCATION = namespaced('setSelectedDropoffLocation')
export const setSelectedDropoffLocation = createAction<IDropoffLocation | undefined>(SET_SELECTED_DROPOFF_LOCATION)

export const SET_SELECTED_DROPOFF_LOCATIONS = namespaced('setSelectedDropoffLocations')
export const setSelectedDropoffLocations = createAction<ISetSelectedTableItems>(SET_SELECTED_DROPOFF_LOCATIONS)

export const SET_DROPOFF_LOCATION_FORM_STATE = namespaced('setDropoffLocationFormState')
export const setDropoffLocationFormState = createAction<SettingsFormState>(SET_DROPOFF_LOCATION_FORM_STATE)

export const SET_IS_ADDING_DROPOFF_LOCATION = namespaced('setIsAddingDropoffLocation')
export const setIsAddingDropoffLocation = createAction<boolean>(SET_IS_ADDING_DROPOFF_LOCATION)

export const SET_IS_EDITING_DROPOFF_LOCATION = namespaced('setIsEditingDropoffLocation')
export const setIsEditingDropoffLocation = createAction<boolean>(SET_IS_EDITING_DROPOFF_LOCATION)

export const SET_IS_DELETING_DROPOFF_LOCATIONS = namespaced('setIsDeletingDropoffLocations')
export const setIsDeletingDropoffLocations = createAction<boolean>(SET_IS_DELETING_DROPOFF_LOCATIONS)

export const ADD_DROPOFF_LOCATION = namespaced('addDropoffLocation')
export const addDropoffLocation = createAction<IDropoffLocationFormValues>(ADD_DROPOFF_LOCATION)

export const EDIT_DROPOFF_LOCATION = namespaced('editDropoffLocation')
export const editDropoffLocation = createAction<IDropoffLocationFormValues>(EDIT_DROPOFF_LOCATION)

export const DELETE_DROPOFF_LOCATIONS = namespaced('deleteDropoffLocations')
export const deleteDropoffLocations = createAction(DELETE_DROPOFF_LOCATIONS)

// Size

export const SET_SELECTED_SIZE = namespaced('setSelectedSize')
export const setSelectedSize = createAction<ISize | undefined>(SET_SELECTED_SIZE)

export const SET_SELECTED_SIZES = namespaced('setSelectedSizes')
export const setSelectedSizes = createAction<ISetSelectedTableItems>(SET_SELECTED_SIZES)

export const SET_SIZE_FORM_STATE = namespaced('setSizeFormState')
export const setSizeFormState = createAction<SettingsFormState>(SET_SIZE_FORM_STATE)

export const SET_IS_ADDING_SIZE = namespaced('setIsAddingSize')
export const setIsAddingSize = createAction<boolean>(SET_IS_ADDING_SIZE)

export const SET_IS_EDITING_SIZE = namespaced('setIsEditingSize')
export const setIsEditingSize = createAction<boolean>(SET_IS_EDITING_SIZE)

export const SET_IS_DELETING_SIZES = namespaced('setIsDeletingSizes')
export const setIsDeletingSizes = createAction<boolean>(SET_IS_DELETING_SIZES)

export const ADD_SIZE = namespaced('addSize')
export const addSize = createAction<ISizeFormValues>(ADD_SIZE)

export const DELETE_SIZES = namespaced('deleteSizes')
export const deleteSizes = createAction(DELETE_SIZES)

type Action = typeof setPermissions
export default Action
