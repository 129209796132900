export enum AnalyticalEvent {
  Register = 'Register',
  Login = 'Login',
  Logout = 'Logout',
  Navigate = 'Navigate',

  AccountCreation = 'AccountCreation',
  AccountType = 'AccountType',
  SendSms = 'SendSms',
  SkippedSMS = 'SkippedSms',
  RecipientOnboarding = 'RecipientOnboarding',
  AppLink = 'AppLink',
  BuildingInfoSet = 'BuildingInfoSet',
  RecipientUploadMethodChosen = 'RecipientsUploadMethodChosen',
  OnboardingStage = 'OnboardingStage',

  RecipientFileUploaded = 'RecipientFileUploaded',
  RecipientFileNavigated = 'RecipientFileNavigated',
  RecipientFileUploadStatus = 'RecipientFileUploadStatus',

  Error = 'Error',
}

export enum AnalyticalEventValue {
  AccountCreation_STARTED = 'STARTED',
  AccountCreation_VERIFIED = 'VERIFIED',
  AccountCreation_Completed = 'COMPLETED',
  AccountCreation_Dropped = 'DROPPED',

  RecipientOnboarding_Landing = 'LANDING',
  RecipientOnboarding_PAGE = 'RECIPIENT_PAGE',

  Ios = 'IOS',
  Android = 'ANDROID',
}
