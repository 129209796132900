import { AnalyticsConfiguration } from '../model'

import { AnalyticsService } from './analytics-service'

export class AnalyticsServiceFactory {
  create(config: AnalyticsConfiguration) {
    return new AnalyticsService()
  }
}

export default new AnalyticsServiceFactory()
