import { setOnboardingStage } from 'api/auth'
import { getParcelsHeld, getParcelsScanned } from 'api/stats'
import autobind from 'autobind-decorator'
import notification from 'modules/module-alerts/alert'
import { PortalPath } from 'modules/module-core/routes/models'
import developments from 'modules/module-developments'
import navigation from 'modules/module-navigation'
import orchestration from 'modules/module-orchestration'
import moduleReporting from 'modules/module-reporting'
import { OnboardingStage } from 'modules/module-security'
import { SupervisorSuite } from 'modules/redux-supervisor'
import { Action } from 'redux-actions'
import { SagaIterator } from 'redux-saga'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import board, { IOnboardingNavigatePayload, ISetUploadingMethodPayload } from '../board'
import { UploadingMethod } from '../screens/onboarding/SelectUploadFlowScreen/SelectUploadFlowScreen'

export class DashboardSaga extends SupervisorSuite {
  @autobind
  *start(): SagaIterator {
    yield all([
      takeLatest(board.actions.NAVIGATE_ONBOARDING, this.onNavigation),
      takeLatest(board.actions.SET_UPLOADING_METHOD, this.onUploadMethodChosen),
      takeLatest(board.actions.GET_PARCELS_SCANNED, this.getParcelsScanned),
      takeLatest(board.actions.GET_PARCELS_WAITING, this.getParcelsWaiting),
    ])
  }

  @autobind
  *onNavigation({ payload }: Action<IOnboardingNavigatePayload>): SagaIterator {
    // Probably only needed in reducer
  }

  @autobind
  *onUploadMethodChosen({ payload }: Action<ISetUploadingMethodPayload>): SagaIterator {
    if (payload == UploadingMethod.FILE) {
      yield put(board.actions.navigateOnboarding('upload-file'))
    } else {
      yield call(setOnboardingStage, {
        OnboardingStage: OnboardingStage.RECIPIENTS_UPLOAD_SKIPPED,
      })
      yield put(navigation.actions.navigate({ route: PortalPath.RESIDENTS_DIRECTORY }))
      yield put(orchestration.actions.restore())
    }
  }

  @autobind
  *getParcelsScanned(): SagaIterator {
    yield put(board.actions.loading(true))
    try {
      const currentDevelopments = yield select(developments.selectors.currentDevelopments)
      const developmentsIds = currentDevelopments?.map((development) => development.Id).join(',')
      const { data } = yield call(getParcelsScanned, developmentsIds)
      yield put(board.actions.setParcelsScanned(data.Value))
    } catch (error) {
      yield put(moduleReporting.actions.error(error))
    } finally {
      yield put(board.actions.loading(false))
    }
  }

  @autobind
  *getParcelsWaiting(): SagaIterator {
    yield put(board.actions.loading(true))
    try {
      const currentDevelopments = yield select(developments.selectors.currentDevelopments)
      const developmentsIds = currentDevelopments?.map((development) => development.Id).join(',')
      const { data } = yield call(getParcelsHeld, developmentsIds)
      yield put(board.actions.setParcelsWaiting(data.Value))
    } catch (error) {
      yield put(moduleReporting.actions.error(error))
      yield put(
        notification.actions.showError({
          title: 'Something went wrong!',
          message: '',
        }),
      )
    } finally {
      yield put(board.actions.loading(false))
    }
  }
}

export default DashboardSaga
