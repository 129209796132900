import React from 'react'
import styled from '@emotion/styled'
import { Grid } from 'modules/web-atoms'
import shouldForwardProp from '@styled-system/should-forward-prop'
import { StyledViewProps } from 'modules/web-atoms'
import * as system from 'styled-system'
import { IconContainer, TimelineIcon } from './styles'

export type TimelineLineType = 'halfway' | 'dotted' | 'solid' | 'lightSolid' | 'none'
export type TimelineIconType = 'default' | 'large' | 'largeDotted'
export type TimelineIconColor = 'default' | 'subdued'

export type ITimelineItemProps = StyledViewProps &
  React.ComponentProps<typeof Grid> & {
    lineType?: TimelineLineType
    iconType?: TimelineIconType
    iconColor?: TimelineIconColor
  }

export const StyledTimelineItem = styled(Grid, { shouldForwardProp })<ITimelineItemProps>(
  system.color,
  system.flexbox,
  system.space,
  system.layout,
  system.position,
  system.justifyContent,
  system.alignItems,
  system.borders,
  system.shadow,
  system.background,
)

export const TimelineItem: React.FC<ITimelineItemProps> = (props) => {
  const { children, lineType = 'solid', iconType = 'default', iconColor = 'default', ...rest } = props

  return (
    <StyledTimelineItem width="100%" gutterSize="small" {...rest}>
      <Grid.Col width={24}>
        <IconContainer lineType={lineType}>
          <TimelineIcon iconType={iconType} iconColor={iconColor} />
        </IconContainer>
      </Grid.Col>

      <Grid.Col width="100%" maxWidth={400}>
        {children}
      </Grid.Col>
    </StyledTimelineItem>
  )
}

export default React.memo(TimelineItem)
