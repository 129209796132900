import styled, { CSSObject } from '@emotion/styled'
import css from '@styled-system/css'
import { ResponsiveColor } from 'modules/dna'
import React, { useMemo } from 'react'
import Grid from '../../Grid/Grid'
import Button from '../../buttons/Button/Button'
import { IButtonFrameProps } from '../../buttons/ButtonFrame/ButtonFrame'
import Paragraph from '../../typography/Paragraph/Paragraph'
import { IRadioButtonProps } from '../types'

interface IBaseProps {
  label?: string
}

export type IRadioPlainProps = IRadioButtonProps & IButtonFrameProps & IBaseProps

interface IRadioStyle {
  container: {
    borderColor: ResponsiveColor
  }
  labelText: {
    color: ResponsiveColor
  }
}

const RADIO_STYLE = new Map<boolean, IRadioStyle>([
  [
    true,
    {
      container: { borderColor: 'primaryMid' },
      labelText: { color: 'primaryMid' },
    },
  ],
  [
    false,
    {
      container: { borderColor: 'none' },
      labelText: { color: 'textDark' },
    },
  ],
])

const StyledButton = styled(Button.Frame)(
  css({
    width: '100%',
    height: '100%',
    borderRadius: 'medium',
    boxShadow: 'small',
    backgroundColor: 'primaryLight',
    justifyContent: 'center',
    alignItems: 'center',
    borderStyle: 'solid',
    borderWidth: 'medium',
    boxSizing: 'border-box',

    '&:focus': {
      backgroundColor: 'secondaryMid',
    },
  } as CSSObject),
)

export const RadioPlain: React.FC<IRadioPlainProps> = ({
  id,
  selected,
  children,
  onClick,
  onPressIn,
  onPressOut,
  legend,
  label,
  contentContainerStyles,
  ...rest
}) => {
  const { container, labelText } = React.useMemo(() => {
    return RADIO_STYLE.get(selected ?? false)!!
  }, [selected])

  const handlers = useMemo(
    () => ({
      onClick: () => onClick?.(id),
      onMouseDown: () => onPressIn?.(id),
      onMouseUp: () => onPressOut?.(id),
    }),
    [id, onClick, onPressIn, onPressOut],
  )

  return (
    <Grid gutterSize={['macro', 'macro', 'smallest']} alignItems="center" height="100%" aria-label="radio">
      <StyledButton flexGrow={1} tabIndex={0} {...rest} {...container} {...handlers} aria-label={label}>
        <Grid justifyContent="center" alignItems="center" {...contentContainerStyles}>
          {children}
        </Grid>
      </StyledButton>

      {label && (
        <Grid.Row>
          <Paragraph fontWeight="bold" {...labelText} size="medium">
            {label}
          </Paragraph>
        </Grid.Row>
      )}
    </Grid>
  )
}

export default React.memo(RadioPlain)
