export interface IPhoneCountryOption {
  name: string
  code: string
  prefix: string
}

export const phoneCountries: IPhoneCountryOption[] = [
  {
    name: 'Afghanistan',
    code: 'af',
    prefix: '93',
  },
  {
    name: 'Albania',
    code: 'al',
    prefix: '355',
  },
  {
    name: 'Algeria',
    code: 'dz',
    prefix: '213',
  },
  {
    name: 'Andorra',
    code: 'ad',
    prefix: '376',
  },
  {
    name: 'Angola',
    code: 'ao',
    prefix: '244',
  },
  {
    name: 'Antigua and Barbuda',
    code: 'ag',
    prefix: '1268',
  },
  {
    name: 'Argentina',
    code: 'ar',
    prefix: '54',
  },
  {
    name: 'Armenia',
    code: 'am',
    prefix: '374',
  },
  {
    name: 'Aruba',
    code: 'aw',
    prefix: '297',
  },
  {
    name: 'Australia',
    code: 'au',
    prefix: '61',
  },
  {
    name: 'Austria',
    code: 'at',
    prefix: '43',
  },
  {
    name: 'Azerbaijan',
    code: 'az',
    prefix: '994',
  },
  {
    name: 'Bahamas',
    code: 'bs',
    prefix: '1242',
  },
  {
    name: 'Bahrain',
    code: 'bh',
    prefix: '973',
  },
  {
    name: 'Bangladesh',
    code: 'bd',
    prefix: '880',
  },
  {
    name: 'Barbados',
    code: 'bb',
    prefix: '1246',
  },
  {
    name: 'Belarus',
    code: 'by',
    prefix: '375',
  },
  {
    name: 'Belgium',
    code: 'be',
    prefix: '32',
  },
  {
    name: 'Belize',
    code: 'bz',
    prefix: '501',
  },
  {
    name: 'Benin',
    code: 'bj',
    prefix: '229',
  },
  {
    name: 'Bhutan',
    code: 'bt',
    prefix: '975',
  },
  {
    name: 'Bolivia',
    code: 'bo',
    prefix: '591',
  },
  {
    name: 'Bosnia and Herzegovina',
    code: 'ba',
    prefix: '387',
  },
  {
    name: 'Botswana',
    code: 'bw',
    prefix: '267',
  },
  {
    name: 'Brazil',
    code: 'br',
    prefix: '55',
  },
  {
    name: 'British Indian Ocean Territory',
    code: 'io',
    prefix: '246',
  },
  {
    name: 'Brunei',
    code: 'bn',
    prefix: '673',
  },
  {
    name: 'Bulgaria',
    code: 'bg',
    prefix: '359',
  },
  {
    name: 'Burkina Faso',
    code: 'bf',
    prefix: '226',
  },
  {
    name: 'Burundi',
    code: 'bi',
    prefix: '257',
  },
  {
    name: 'Cambodia',
    code: 'kh',
    prefix: '855',
  },
  {
    name: 'Cameroon',
    code: 'cm',
    prefix: '237',
  },
  {
    name: 'Canada',
    code: 'ca',
    prefix: '1',
  },
  {
    name: 'Cape Verde',
    code: 'cv',
    prefix: '238',
  },
  {
    name: 'Caribbean Netherlands',
    code: 'bq',
    prefix: '599',
  },
  {
    name: 'Central African Republic',
    code: 'cf',
    prefix: '236',
  },
  {
    name: 'Chad',
    code: 'td',
    prefix: '235',
  },
  {
    name: 'Chile',
    code: 'cl',
    prefix: '56',
  },
  {
    name: 'China',
    code: 'cn',
    prefix: '86',
  },
  {
    name: 'Colombia',
    code: 'co',
    prefix: '57',
  },
  {
    name: 'Comoros',
    code: 'km',
    prefix: '269',
  },
  {
    name: 'Congo',
    code: 'cd',
    prefix: '243',
  },
  {
    name: 'Congo',
    code: 'cg',
    prefix: '242',
  },
  {
    name: 'Costa Rica',
    code: 'cr',
    prefix: '506',
  },
  {
    name: 'Côte d’Ivoire',
    code: 'ci',
    prefix: '225',
  },
  {
    name: 'Croatia',
    code: 'hr',
    prefix: '385',
  },
  {
    name: 'Cuba',
    code: 'cu',
    prefix: '53',
  },
  {
    name: 'Curaçao',
    code: 'cw',
    prefix: '599',
  },
  {
    name: 'Cyprus',
    code: 'cy',
    prefix: '357',
  },
  {
    name: 'Czech Republic',
    code: 'cz',
    prefix: '420',
  },
  {
    name: 'Denmark',
    code: 'dk',
    prefix: '45',
  },
  {
    name: 'Djibouti',

    code: 'dj',
    prefix: '253',
  },
  {
    name: 'Dominica',
    code: 'dm',
    prefix: '1767',
  },
  {
    name: 'Dominican Republic',
    code: 'do',
    prefix: '1',
  },
  {
    name: 'Ecuador',
    code: 'ec',
    prefix: '593',
  },
  {
    name: 'Egypt',
    code: 'eg',
    prefix: '20',
  },
  {
    name: 'El Salvador',
    code: 'sv',
    prefix: '503',
  },
  {
    name: 'Equatorial Guinea',
    code: 'gq',
    prefix: '240',
  },
  {
    name: 'Eritrea',
    code: 'er',
    prefix: '291',
  },
  {
    name: 'Estonia',
    code: 'ee',
    prefix: '372',
  },
  {
    name: 'Ethiopia',
    code: 'et',
    prefix: '251',
  },
  {
    name: 'Fiji',
    code: 'fj',
    prefix: '679',
  },
  {
    name: 'Finland',
    code: 'fi',
    prefix: '358',
  },
  {
    name: 'France',
    code: 'fr',
    prefix: '33',
  },
  {
    name: 'French Guiana',
    code: 'gf',
    prefix: '594',
  },
  {
    name: 'French Polynesia',
    code: 'pf',
    prefix: '689',
  },
  {
    name: 'Gabon',
    code: 'ga',
    prefix: '241',
  },
  {
    name: 'Gambia',
    code: 'gm',
    prefix: '220',
  },
  {
    name: 'Georgia',
    code: 'ge',
    prefix: '995',
  },
  {
    name: 'Germany',
    code: 'de',
    prefix: '49',
  },
  {
    name: 'Ghana',
    code: 'gh',
    prefix: '233',
  },
  {
    name: 'Greece',
    code: 'gr',
    prefix: '30',
  },
  {
    name: 'Grenada',
    code: 'gd',
    prefix: '1473',
  },
  {
    name: 'Guadeloupe',
    code: 'gp',
    prefix: '590',
  },
  {
    name: 'Guam',
    code: 'gu',
    prefix: '1671',
  },
  {
    name: 'Guatemala',
    code: 'gt',
    prefix: '502',
  },
  {
    name: 'Guinea',
    code: 'gn',
    prefix: '224',
  },
  {
    name: 'Guinea-Bissau',
    code: 'gw',
    prefix: '245',
  },
  {
    name: 'Guyana',
    code: 'gy',
    prefix: '592',
  },
  {
    name: 'Haiti',
    code: 'ht',
    prefix: '509',
  },
  {
    name: 'Honduras',
    code: 'hn',
    prefix: '504',
  },
  {
    name: 'Hong Kong',
    code: 'hk',
    prefix: '852',
  },
  {
    name: 'Hungary',
    code: 'hu',
    prefix: '36',
  },
  {
    name: 'Iceland',
    code: 'is',
    prefix: '354',
  },
  {
    name: 'India',
    code: 'in',
    prefix: '91',
  },
  {
    name: 'Indonesia',
    code: 'id',
    prefix: '62',
  },
  {
    name: 'Iran',
    code: 'ir',
    prefix: '98',
  },
  {
    name: 'Iraq',
    code: 'iq',
    prefix: '964',
  },
  {
    name: 'Ireland',
    code: 'ie',
    prefix: '353',
  },
  {
    name: 'Israel',
    code: 'il',
    prefix: '972',
  },
  {
    name: 'Italy',
    code: 'it',
    prefix: '39',
  },
  {
    name: 'Jamaica',
    code: 'jm',
    prefix: '1876',
  },
  {
    name: 'Japan',
    code: 'jp',
    prefix: '81',
  },
  {
    name: 'Jordan',
    code: 'jo',
    prefix: '962',
  },
  {
    name: 'Kazakhstan',
    code: 'kz',
    prefix: '7',
  },
  {
    name: 'Kenya',
    code: 'ke',
    prefix: '254',
  },
  {
    name: 'Kiribati',
    code: 'ki',
    prefix: '686',
  },
  {
    name: 'Kosovo',
    code: 'xk',
    prefix: '383',
  },
  {
    name: 'Kuwait',
    code: 'kw',
    prefix: '965',
  },
  {
    name: 'Kyrgyzstan',
    code: 'kg',
    prefix: '996',
  },
  {
    name: 'Laos',
    code: 'la',
    prefix: '856',
  },
  {
    name: 'Latvia',
    code: 'lv',
    prefix: '371',
  },
  {
    name: 'Lebanon',
    code: 'lb',
    prefix: '961',
  },
  {
    name: 'Lesotho',
    code: 'ls',
    prefix: '266',
  },
  {
    name: 'Liberia',
    code: 'lr',
    prefix: '231',
  },
  {
    name: 'Libya',

    code: 'ly',
    prefix: '218',
  },
  {
    name: 'Liechtenstein',
    code: 'li',
    prefix: '423',
  },
  {
    name: 'Lithuania',
    code: 'lt',
    prefix: '370',
  },
  {
    name: 'Luxembourg',
    code: 'lu',
    prefix: '352',
  },
  {
    name: 'Macau',
    code: 'mo',
    prefix: '853',
  },
  {
    name: 'Macedonia',
    code: 'mk',
    prefix: '389',
  },
  {
    name: 'Madagascar',
    code: 'mg',
    prefix: '261',
  },
  {
    name: 'Malawi',
    code: 'mw',
    prefix: '265',
  },
  {
    name: 'Malaysia',
    code: 'my',
    prefix: '60',
  },
  {
    name: 'Maldives',
    code: 'mv',
    prefix: '960',
  },
  {
    name: 'Mali',
    code: 'ml',
    prefix: '223',
  },
  {
    name: 'Malta',
    code: 'mt',
    prefix: '356',
  },
  {
    name: 'Marshall Islands',
    code: 'mh',
    prefix: '692',
  },
  {
    name: 'Martinique',
    code: 'mq',
    prefix: '596',
  },
  {
    name: 'Mauritania',
    code: 'mr',
    prefix: '222',
  },
  {
    name: 'Mauritius',
    code: 'mu',
    prefix: '230',
  },
  {
    name: 'Mexico',
    code: 'mx',
    prefix: '52',
  },
  {
    name: 'Micronesia',
    code: 'fm',
    prefix: '691',
  },
  {
    name: 'Moldova',
    code: 'md',
    prefix: '373',
  },
  {
    name: 'Monaco',
    code: 'mc',
    prefix: '377',
  },
  {
    name: 'Mongolia',
    code: 'mn',
    prefix: '976',
  },
  {
    name: 'Montenegro',
    code: 'me',
    prefix: '382',
  },
  {
    name: 'Morocco',
    code: 'ma',
    prefix: '212',
  },
  {
    name: 'Mozambique',
    code: 'mz',
    prefix: '258',
  },
  {
    name: 'Myanmar',
    code: 'mm',
    prefix: '95',
  },
  {
    name: 'Namibia',
    code: 'na',
    prefix: '264',
  },
  {
    name: 'Nauru',
    code: 'nr',
    prefix: '674',
  },
  {
    name: 'Nepal',
    code: 'np',
    prefix: '977',
  },
  {
    name: 'Netherlands',
    code: 'nl',
    prefix: '31',
  },
  {
    name: 'New Caledonia',
    code: 'nc',
    prefix: '687',
  },
  {
    name: 'New Zealand',
    code: 'nz',
    prefix: '64',
  },
  {
    name: 'Nicaragua',
    code: 'ni',
    prefix: '505',
  },
  {
    name: 'Niger',
    code: 'ne',
    prefix: '227',
  },
  {
    name: 'Nigeria',
    code: 'ng',
    prefix: '234',
  },
  {
    name: 'North Korea',
    code: 'kp',
    prefix: '850',
  },
  {
    name: 'Norway',
    code: 'no',
    prefix: '47',
  },
  {
    name: 'Oman',
    code: 'om',
    prefix: '968',
  },
  {
    name: 'Pakistan',
    code: 'pk',
    prefix: '92',
  },
  {
    name: 'Palau',
    code: 'pw',
    prefix: '680',
  },
  {
    name: 'Palestine',
    code: 'ps',
    prefix: '970',
  },
  {
    name: 'Panama',
    code: 'pa',
    prefix: '507',
  },
  {
    name: 'Papua New Guinea',
    code: 'pg',
    prefix: '675',
  },
  {
    name: 'Paraguay',
    code: 'py',
    prefix: '595',
  },
  {
    name: 'Peru',
    code: 'pe',
    prefix: '51',
  },
  {
    name: 'Philippines',
    code: 'ph',
    prefix: '63',
  },
  {
    name: 'Poland',
    code: 'pl',
    prefix: '48',
  },
  {
    name: 'Portugal',
    code: 'pt',
    prefix: '351',
  },
  {
    name: 'Gibraltar',
    code: 'gi',
    prefix: '350',
  },
  {
    name: 'Puerto Rico',
    code: 'pr',
    prefix: '1',
  },
  {
    name: 'Qatar',
    code: 'qa',
    prefix: '974',
  },
  {
    name: 'Réunion',
    code: 're',
    prefix: '262',
  },
  {
    name: 'Romania',
    code: 'ro',
    prefix: '40',
  },
  {
    name: 'Russia',
    code: 'ru',
    prefix: '7',
  },
  {
    name: 'Rwanda',
    code: 'rw',
    prefix: '250',
  },
  {
    name: 'Saint Kitts and Nevis',
    code: 'kn',
    prefix: '1869',
  },
  {
    name: 'Saint Lucia',
    code: 'lc',
    prefix: '1758',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    code: 'vc',
    prefix: '1784',
  },
  {
    name: 'Samoa',
    code: 'ws',
    prefix: '685',
  },
  {
    name: 'San Marino',
    code: 'sm',
    prefix: '378',
  },
  {
    name: 'São Tomé and Príncipe',
    code: 'st',
    prefix: '239',
  },
  {
    name: 'Saudi Arabia',
    code: 'sa',
    prefix: '966',
  },
  {
    name: 'Senegal',
    code: 'sn',
    prefix: '221',
  },
  {
    name: 'Serbia',
    code: 'rs',
    prefix: '381',
  },
  {
    name: 'Seychelles',
    code: 'sc',
    prefix: '248',
  },
  {
    name: 'Sierra Leone',
    code: 'sl',
    prefix: '232',
  },
  {
    name: 'Singapore',
    code: 'sg',
    prefix: '65',
  },
  {
    name: 'Slovakia',
    code: 'sk',
    prefix: '421',
  },
  {
    name: 'Slovenia',

    code: 'si',
    prefix: '386',
  },
  {
    name: 'Solomon Islands',

    code: 'sb',
    prefix: '677',
  },
  {
    name: 'Somalia',
    code: 'so',
    prefix: '252',
  },
  {
    name: 'South Africa',
    code: 'za',
    prefix: '27',
  },
  {
    name: 'South Korea',
    code: 'kr',
    prefix: '82',
  },
  {
    name: 'South Sudan',
    code: 'ss',
    prefix: '211',
  },
  {
    name: 'Spain',
    code: 'es',
    prefix: '34',
  },
  {
    name: 'Sri Lanka',
    code: 'lk',
    prefix: '94',
  },
  {
    name: 'Sudan',
    code: 'sd',
    prefix: '249',
  },
  {
    name: 'Suriname',
    code: 'sr',
    prefix: '597',
  },
  {
    name: 'Swaziland',
    code: 'sz',
    prefix: '268',
  },
  {
    name: 'Sweden',
    code: 'se',
    prefix: '46',
  },
  {
    name: 'Switzerland',
    code: 'ch',
    prefix: '41',
  },
  {
    name: 'Syria',
    code: 'sy',
    prefix: '963',
  },
  {
    name: 'Taiwan',
    code: 'tw',
    prefix: '886',
  },
  {
    name: 'Tajikistan',
    code: 'tj',
    prefix: '992',
  },
  {
    name: 'Tanzania',
    code: 'tz',
    prefix: '255',
  },
  {
    name: 'Thailand',
    code: 'th',
    prefix: '66',
  },
  {
    name: 'Timor-Leste',
    code: 'tl',
    prefix: '670',
  },
  {
    name: 'Togo',
    code: 'tg',
    prefix: '228',
  },
  {
    name: 'Tonga',
    code: 'to',
    prefix: '676',
  },
  {
    name: 'Trinidad and Tobago',
    code: 'tt',
    prefix: '1868',
  },
  {
    name: 'Tunisia',
    code: 'tn',
    prefix: '216',
  },
  {
    name: 'Turkey',
    code: 'tr',
    prefix: '90',
  },
  {
    name: 'Turkmenistan',
    code: 'tm',
    prefix: '993',
  },
  {
    name: 'Tuvalu',
    code: 'tv',
    prefix: '688',
  },
  {
    name: 'Uganda',
    code: 'ug',
    prefix: '256',
  },
  {
    name: 'Ukraine',
    code: 'ua',
    prefix: '380',
  },
  {
    name: 'United Arab Emirates',
    code: 'ae',
    prefix: '971',
  },
  {
    name: 'United Kingdom',
    code: 'gb',
    prefix: '44',
  },
  {
    name: 'United States',
    code: 'us',
    prefix: '1',
  },
  {
    name: 'Uruguay',
    code: 'uy',
    prefix: '598',
  },
  {
    name: 'Uzbekistan',
    code: 'uz',
    prefix: '998',
  },
  {
    name: 'Vanuatu',
    code: 'vu',
    prefix: '678',
  },
  {
    name: 'Vatican City',
    code: 'va',
    prefix: '39',
  },
  {
    name: 'Venezuela',
    code: 've',
    prefix: '58',
  },
  {
    name: 'Vietnam',
    code: 'vn',
    prefix: '84',
  },
  {
    name: 'Yemen',
    code: 'ye',
    prefix: '967',
  },
  {
    name: 'Zambia',
    code: 'zm',
    prefix: '260',
  },
  {
    name: 'Zimbabwe',
    code: 'zw',
    prefix: '263',
  },
]
