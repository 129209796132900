import { Action } from 'redux-actions'
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'

import { SagaIterator } from '@redux-saga/types'

import autobind from 'autobind-decorator'
import i18n from 'modules/module-core/intl/i18n'
import reporting from 'modules/module-reporting'
import { SupervisorSuite } from 'modules/redux-supervisor'

import orchestration from 'modules/module-orchestration/orchestration'
import translation, { ITranslatePayload } from '../translation'

export class TranslationSaga extends SupervisorSuite {
  @autobind
  *start(): SagaIterator {
    yield all([
      takeLatest(orchestration.actions.RESTORE, this.restore),
      takeEvery(translation.actions.translate, this.translate),
    ])
  }

  @autobind
  *restore(): SagaIterator {}

  @autobind
  *translate({ payload }: Action<ITranslatePayload>): SagaIterator {
    try {
      const { id, values } = payload
      const result: string = yield call(i18n.t, id, values as any)
      yield put(translation.actions.result(result, payload))
    } catch (err) {
      const context = `Failed to translate id: ${payload.id}`
      yield put(reporting.actions.error(err, { context }))
      yield put(translation.actions.result(payload.id, payload))
    }
  }
}

export default TranslationSaga
