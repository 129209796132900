import { useAppSelector } from 'modules/module-utils'
import { IOption, ISelectProps, Select, useTranslate } from 'modules/web-atoms'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { changeLanguage } from '../../intl/intl'
import settings from '../../settings'
import { Language, SUPPORTED_LANGUAGES } from '../../settings/models'

export interface IRecipientBuildingSelectorProps
  extends Omit<ISelectProps<string>, 'options' | 'selectedValue' | 'onSelect' | 'onChange'> {}

const LanguageSelector: React.FC<IRecipientBuildingSelectorProps> = ({ ...rest }) => {
  const t = useTranslate('commons')

  const dispatch = useDispatch()
  const { i18n } = useTranslation()

  const language = useAppSelector(settings.selectors.language)

  React.useEffect(() => {
    dispatch(settings.actions.changeLanguage(i18n.language as Language))
  })

  const onSelect = React.useCallback(
    (value: string | undefined) => {
      if (value) {
        void changeLanguage(value)
        dispatch(settings.actions.changeLanguage(value as Language))
      }
    },
    [dispatch],
  )

  const languageOptions: Array<IOption<string>> = React.useMemo(
    () =>
      SUPPORTED_LANGUAGES.map((language) => ({
        value: language,
        label: t(`language.${language}`),
      })),
    [t],
  )

  return <Select<string> options={languageOptions} selectedValue={language} onSelect={onSelect} {...rest} />
}

export default React.memo(LanguageSelector)
