import { AxiosError } from 'axios'

import { FingerPrinter } from '../models'

const hasStatusCode = (error: any): error is AxiosError => {
  return !!error?.response?.status
}

export const statusCode: FingerPrinter = (error, _, fingerprint) => {
  if (hasStatusCode(error) && error.response) {
    return fingerprint.concat(error.response.status.toString())
  }

  return fingerprint
}
