import { UserType } from 'api'
import { TableSelectionAction } from 'modules/web-molecules'

export interface IPermissions {
  canViewUsers: boolean
  canCreateUsers: boolean
  canEditUsers: boolean
  canRemoveUsers: boolean
}

export enum FormState {
  HIDDEN = 'hidden',
  CREATING = 'creating',
  EDITING = 'editing',
}

export interface IUserState {
  permissions: IPermissions

  selectedUsers: string[]
  selectedUser?: string
  isDeletingUsers: boolean
  isDeletingUser: boolean
  isUpdating: boolean
  formState: FormState

  error?: string
  loading: boolean
  submittedForm: boolean
}

export const initialState: IUserState = {
  permissions: {
    canViewUsers: false,
    canCreateUsers: false,
    canEditUsers: false,
    canRemoveUsers: false,
  },
  selectedUsers: [],
  selectedUser: undefined,
  formState: FormState.HIDDEN,
  isDeletingUsers: false,
  isDeletingUser: false,
  isUpdating: false,

  error: undefined,
  loading: false,
  submittedForm: false,
}

export interface ISetPermissionsPayload {
  userType: UserType
}

export interface ISelectedUsersPayload {
  items: Array<{ id: string; type: UserType }>
  action: TableSelectionAction
}
export interface ISetSelectedUsersPayload {
  items: string[]
  action: TableSelectionAction
}

export interface ISelectedUserPayload {
  user?: { id: string; type: UserType }
}
export interface ISetSelectedUserPayload {
  user?: string
}

export interface IAddUserPayload {
  email: string
  firstName: string
  lastName: string
  type: UserType
  access: number[]
}

export interface IUpdateUserPayload {
  id: string
  email: string
  firstName: string
  lastName: string
  type: UserType
  access: number[]
}

// TODO: remove
export enum sortBy {
  name = 'NAME',
  type = 'TYPE',
  email = 'EMAIL',
}
