import * as React from 'react'

import { createStyledText, StyledTextProps, TextComponentType } from '../StyledText/StyledText'

import { HeadingStyleProps, headingStyles } from './styles'

const createHeading = (styles: HeadingStyleProps, displayName: TextComponentType) => {
  const StyledHeading = createStyledText(displayName)

  const Heading: React.FunctionComponent<StyledTextProps> = ({ children, ...rest }) => (
    <StyledHeading whiteSpace="normal" {...styles} {...rest}>
      {children}
    </StyledHeading>
  )

  Heading.displayName = displayName

  return React.memo(Heading) as React.FunctionComponent<StyledTextProps>
}

export const H1 = createHeading(headingStyles.h1, 'h1')
export const H2 = createHeading(headingStyles.h2, 'h2')
export const H3 = createHeading(headingStyles.h3, 'h3')
export const H4 = createHeading(headingStyles.h4, 'h4')
export const H5 = createHeading(headingStyles.h5, 'h5')
export const H6 = createHeading(headingStyles.h6, 'h6')
export const H7 = createHeading(headingStyles.h7, 'h6')
export const H8 = createHeading(headingStyles.h8, 'h6')
export const H9 = createHeading(headingStyles.h9, 'h6')
export const H10 = createHeading(headingStyles.h10, 'h6')
