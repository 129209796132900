import { Paragraph, StyledView } from 'modules/web-atoms'
import * as React from 'react'
import { useFocused, useSelected } from 'slate-react'

export interface ITagProps extends React.ComponentProps<typeof StyledView> {
  attributes: any
  element: any
}

export const Tag: React.FC<ITagProps> = ({ attributes, children, element, ...rest }) => {
  const selected = useSelected()
  const focused = useFocused()

  return (
    <StyledView
      {...attributes}
      contentEditable={false}
      data-cy={`tag-${element.character.replace(' ', '-')}`}
      padding="macro"
      margin="macro"
      verticalAlign="baseline"
      display="inline-block"
      borderRadius="small"
      boxShadow={selected && focused ? '0 0 0 2px #B4D5FF' : 'none'}
      backgroundColor={element.backgroundColor}
      {...rest}
    >
      <Paragraph color={element.textColor}>
        {children}@{element.character}
      </Paragraph>
    </StyledView>
  )
}

export default React.memo(Tag)
