/* istanbul ignore file */
import * as actions from './actions'
import reducer from './reducer'
import * as selectors from './selectors'

export * from './models'

export const development = {
  actions,
  reducer,
  selectors,
}

export default development
