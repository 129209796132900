import { FontFace, IDeepfinityTheme } from 'modules/dna'
import * as system from 'styled-system'
import { ParagraphVariant } from './Paragraph'

export type ParagraphStyleProps = system.ColorProps<IDeepfinityTheme> & system.TypographyProps<IDeepfinityTheme>

type ParagraphVariants = Record<ParagraphVariant, ParagraphStyleProps>

export const paragraphStyles: ParagraphVariants = {
  small: {
    color: 'textDark',
    fontFamily: FontFace.SofiaPro,
    fontSize: ['macro', 'xsmall', 'xsmall'],
    fontWeight: 'light',
    lineHeight: 'xsmall',
  },
  medium: {
    color: 'textDark',
    fontFamily: FontFace.SofiaPro,
    fontSize: ['small', 'medium', 'medium'],
    fontWeight: 'light',
    lineHeight: 'small',
  },
  large: {
    color: 'textDark',
    fontFamily: FontFace.SofiaPro,
    fontSize: ['medium', 'large', 'large'],
    fontWeight: 'regular',
    lineHeight: 'medium',
  },
  huge: {
    color: 'textDark',
    fontFamily: FontFace.SofiaPro,
    fontSize: ['large', 'xlarge', 'xxlarge'],
    fontWeight: 'bold',
    lineHeight: 'large',
  },
  xhuge: {
    color: 'textDark',
    fontFamily: FontFace.SofiaPro,
    fontSize: ['xlarge', 'xxlarge', 'xhuge'],
    fontWeight: 'bold',
    lineHeight: 'xhuge',
  },
}
