/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx, useTheme } from '@emotion/react'
import { ResponsiveColor, useColor } from 'modules/dna'
import { Button, Grid, H3, IGridProps, Icon, IconType, useHover, useTranslate } from 'modules/web-atoms'
import { ToolTip } from 'modules/web-molecules'
import * as React from 'react'
import { useHistory, useLocation } from 'react-router-dom'


export interface IMenuItemProps extends IGridProps {
  path: string
  title: string
  icon: IconType
  onClick?: (path: string) => void

  isMinimized: boolean
  isNested?: boolean
  isDisabled?: boolean
}

export const MenuItem: React.FC<IMenuItemProps> = (props) => {
  const { title, icon, path, isNested, isMinimized, isDisabled, onClick, children, ...rest } = props

  const t = useTranslate('web-organisms.menu')
  const history = useHistory()
  const location = useLocation()
  const { colors } = useTheme()

  const [isHovered, hoverEventHandlers] = useHover<HTMLButtonElement>()

  const isSelected = React.useMemo(() => location.pathname == path, [location.pathname, path])

  const Image = React.useMemo(() => Icon[icon], [icon])

  const backgroundColor: ResponsiveColor = React.useMemo(() => {
    switch (true) {
      case isDisabled:
        return 'none'
      case isSelected:
        return 'primaryMid'
      case !!isHovered:
        return 'secondaryMid'
      default:
        return 'none'
    }
  }, [isDisabled, isSelected, isHovered])

  const contentColor: ResponsiveColor = React.useMemo(() => {
    switch (true) {
      case isSelected:
        return 'primaryBackground'
      case !!isHovered:
        return 'primaryMid'
      default:
        return 'textMidDark'
    }
  }, [isHovered, isSelected])

  const iconColor = useColor(contentColor)

  const onMenuClick = React.useCallback(() => {
    history.push(path)
    onClick?.(path)
  }, [history, onClick, path])

  const isPopover = isMinimized && isNested

  return (
    <Grid {...rest}>
      <ToolTip position="right" content={isDisabled ? t('disabled') : undefined} delay="regular">
        <Button.Frame
          label={title}
          borderRadius="small"
          paddingY={isMinimized && !isNested ? 'none' : 'smallest'}
          paddingX={isMinimized && !isNested ? 'macro' : isSelected ? 'small' : 'smallest'}
          height={isMinimized && !isNested ? 35 : undefined}
          width={isMinimized && !isNested ? 36 : isPopover ? '100%' : 205}
          backgroundColor={backgroundColor}
          onClick={onMenuClick}
          disabled={isDisabled}
          css={{
            ':focus-visible': {
              backgroundColor: colors.secondaryMid,
              h3: {
                color: colors.primaryMid,
                textDecorationLine: 'underline',
              },
              svg: {
                fill: colors.primaryMid,
              },
            },
          }}
          {...hoverEventHandlers}
        >
          {isMinimized && !isNested ? (
            <ToolTip content={title} position="right" delay="regular">
              <Grid height={35} alignItems="center" justifyContent="center">
                <Image fill={iconColor} />
              </Grid>
            </ToolTip>
          ) : (
            <Grid alignItems="center" justifyContent={'flex-start'} flex={1} flexShrink={1}>
              <Grid.Col marginLeft={isPopover ? 'none' : isNested ? 'large' : 'smallest'} width={25}>
                <Image fill={iconColor} />
              </Grid.Col>
              <Grid.Col marginLeft="smallest" flexShrink={1} flexWrap="nowrap">
                <H3
                  textDecorationLine={isHovered ? 'underline' : undefined}
                  fontSize="small"
                  color={contentColor}
                  truncated
                  title={title}
                >
                  {title}
                </H3>
              </Grid.Col>
              {children && <Grid.Col>{children}</Grid.Col>}
            </Grid>
          )}
        </Button.Frame>
      </ToolTip>
    </Grid>
  )
}

export interface IMenuItem extends React.MemoExoticComponent<typeof MenuItem> {
  Item: typeof Item
}

const Item: React.FunctionComponent = ({ children }) => <>{children}</>

const menuItem: IMenuItem = Object.assign(React.memo(MenuItem), {
  type: MenuItem,
  Item,
})

export default menuItem
