import * as React from 'react'

import { ConnectedRouter } from 'connected-react-router'

import { history } from './middleware'

export type ConnectedRouterProviderProps = Omit<React.ComponentProps<typeof ConnectedRouter>, 'history'>

export const ConnectedRouterProvider: React.FC<ConnectedRouterProviderProps> = (props) => {
  const { children, ...rest } = props

  return (
    <ConnectedRouter {...rest} history={history}>
      {children}
    </ConnectedRouter>
  )
}

export default ConnectedRouterProvider
