import reporting from 'modules/module-reporting'
import { SagaIterator } from 'redux-saga'
import { call, put, take } from 'redux-saga/effects'
import translation, { IDeepMessageValues, ITranslatePayload } from '../../translation'

export const translate = (id: string, values: IDeepMessageValues = {}) =>
  call(translate._execute, { id, values: { ...values } })

translate._execute = function* (payload: ITranslatePayload): SagaIterator<string> {
  yield put(translation.actions.translate(payload))
  const result = yield take(translation.matchers.result(payload))

  if (typeof result.payload === 'string') {
    return result.payload
  } else {
    yield put(reporting.actions.error(`translate effect returned non-string translation for: ${payload.id}`))
    return payload.id
  }
}
