import autobind from 'autobind-decorator'
import { IndexedDatabase } from './IndexedDb'

export interface IIndexedService {
  init(): Promise<void>
  getDatabase(): Promise<IndexedDatabase>
  deleteDatabase(): Promise<void>
}

export class IndexedService implements IIndexedService {
  private database: IndexedDatabase

  @autobind
  async init(): Promise<void> {
    try {
      this.database = await new IndexedDatabase()
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  @autobind
  async getDatabase(): Promise<IndexedDatabase> {
    return Promise.resolve(this.database)
  }

  @autobind
  async deleteDatabase(): Promise<void> {
    return this.database.delete()
  }
}

const indexedService = new IndexedService()

export default indexedService
