import { SortDirection } from 'modules/module-api'
import { IUsePaginatedTableParams, useAppDispatch, useAppSelector, usePaginatedTable } from 'modules/module-utils'
import { H5, Paragraph, useTranslate } from 'modules/web-atoms'
import {
  IColumns,
  IPagination,
  ITableItem,
  ITableSelectedChange,
  TableColumnType,
  TableSelectionAction,
} from 'modules/web-molecules'
import React from 'react'
import settings, { SettingsFormState } from '../../settings'
import { LocationsSortField, useLocations } from '../use-locations/use-locations'

export type ILocationsTableColumns = LocationsSortField
export type ILocationsTableItem = ITableItem<LocationsSortField>

export const useLocationsTable = (siteId: number | undefined) => {
  const t = useTranslate('settings.tabs.locations.table')
  const dispatch = useAppDispatch()
  const selectedLocation = useAppSelector(settings.selectors.selectedLocation)
  const selectedLocations = useAppSelector(settings.selectors.selectedLocations)

  const onSortChange = () => {
    dispatch(settings.actions.setSelectedLocation(undefined))
  }

  const onSelectionChange: ITableSelectedChange<ILocationsTableColumns, ILocationsTableItem> = (
    items: ILocationsTableItem[],
    action: TableSelectionAction,
  ) => {
    dispatch(
      settings.actions.setSelectedLocations({
        items: items.map((item) => item.id),
        action,
      }),
    )
  }

  const paginationParams: IUsePaginatedTableParams<
    ILocationsTableColumns,
    ILocationsTableItem,
    LocationsSortField,
    typeof LocationsSortField
  > = {
    sortClass: LocationsSortField,
    pageParams: {
      page: 0,
      pageSize: 10,
      sortBy: LocationsSortField.NAME,
      sort: SortDirection.ASC,
      filter: '',
    },
    itemsPerPage: [10, 20, 50],
    onSortChange,
    onSelectionChange,
  }

  const { paging, paginationOptions, onSearch, selection, sort } = usePaginatedTable<
    ILocationsTableColumns,
    ILocationsTableItem,
    LocationsSortField,
    typeof LocationsSortField
  >(paginationParams)

  const { locations, isFetching, totalItems, error } = useLocations(
    {
      page: paging.page + 1,
      sortBy: paging.sortBy,
      sort: paging.sort,
      pageSize: paging.pageSize,
      development_id: siteId,
      filter: paging.filter,
    },
    {
      enabled: !!siteId,
      cacheTime: 0,
    },
  )

  const onItemSelected = (item: ILocationsTableItem) => {
    if (selectedLocation?.id === item.id) {
      dispatch(settings.actions.setSelectedLocation(undefined))
      dispatch(settings.actions.setLocationFormState(SettingsFormState.HIDDEN))
    } else {
      const location = locations.find((location) => location.id === item.id)
      dispatch(settings.actions.setSelectedLocation(location))
      dispatch(settings.actions.setLocationFormState(SettingsFormState.EDIT))
    }
  }

  const pagination: IPagination = {
    pageIndex: paging.page,
    pageSize: paging.pageSize,
    total: totalItems,
  }

  const columns: IColumns<ILocationsTableColumns> = {
    [LocationsSortField.NAME]: {
      label: <H5 padding="smallest">{t('column.name')}</H5>,
      type: TableColumnType.Column,
      isSortable: true,
    },
    [LocationsSortField.IDENTIFIER]: {
      label: <H5 padding="smallest">{t('column.identifier')}</H5>,
      type: TableColumnType.Column,
      isSortable: true,
    },
    [LocationsSortField.ENABLED]: {
      label: <H5 padding="smallest">{t('column.enabled')}</H5>,
      type: TableColumnType.Column,
      isSortable: true,
    },
  }

  const items: ILocationsTableItem[] = React.useMemo(() => {
    return locations.map((location) => {
      const id = location.id
      const enabledCol = location?.enabled ? t('enabled') : t('disabled')
      return {
        id,
        isSelectable: true,
        isChecked: !!selectedLocations.find((itemId) => itemId === id),
        isSelected: !!selectedLocation && id === selectedLocation.id,
        isCheckable: true,
        [LocationsSortField.NAME]: {
          render: () => (
            <Paragraph title={location.name} fontWeight="bold" size="medium" padding="smallest" truncated={true}>
              {location.name}
            </Paragraph>
          ),
        },
        [LocationsSortField.IDENTIFIER]: {
          render: () => (
            <Paragraph title={location.identifier} size="medium" padding="smallest" truncated={true}>
              {location.identifier}
            </Paragraph>
          ),
        },
        [LocationsSortField.ENABLED]: {
          render: () => (
            <Paragraph
              title={enabledCol}
              size="medium"
              padding="smallest"
              truncated={true}
              fontWeight="medium"
              color={location?.enabled ? 'primaryMid' : 'dangerDestructive'}
            >
              {enabledCol}
            </Paragraph>
          ),
        },
      }
    })
  }, [locations, selectedLocation, selectedLocations, t])

  return {
    columns,
    items,
    selection,
    onSearch,
    onItemClicked: onItemSelected,
    isFetching,
    error,
    pagination,
    sort,
    paginationOptions,
    selectedLocations,
  }
}
