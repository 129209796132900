import Dexie, { Table } from 'dexie'

export const KEY_DB_NAME = 'parcel_tracker_database'

export interface IRecipientUploadTable {
  id: string
  name: string
  age: number
}

export class IndexedDatabase extends Dexie {
  recipientUpload!: Table<IRecipientUploadTable>

  constructor() {
    super(KEY_DB_NAME)
    this.version(1).stores({
      recipientUpload: '++id', // Primary key and indexed props
    })
  }
}
