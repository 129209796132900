export * from './components'
export * from './intl/intl'
export { default as intlNext } from './intl/intl'
export * from './sagas/effects/translate'
export * from './translation'

import reducer from './root-reducer'
import sagas from './sagas'

export const intl = {
  sagas,
  reducer: reducer,
}

export default intl
