import { createAction } from 'redux-actions'

import analytics, { AnalyticalEvent } from 'modules/module-analytics'
import { identityFor } from 'modules/utils'
import prefix from 'modules/utils/prefix'

import { INavigatePayload } from './models'

export const namespaced = prefix('navigation/navigation')

export const NAVIGATE = namespaced('navigate')
export const navigate = createAction(NAVIGATE, identityFor<INavigatePayload>(), (payload) =>
  analytics.meta.whitelist({
    data: { ...payload } as JSONObject,
    name: AnalyticalEvent.Navigate,
  }),
)

export const RESET = namespaced('reset')
export const reset = createAction<INavigatePayload>(RESET)

export const BACK = namespaced('back')
export const back = createAction(NAVIGATE)

export const FORWARD = namespaced('forward')
export const forward = createAction(NAVIGATE)

export const NAVIGATED = namespaced('navigated')
export const navigated = createAction(NAVIGATED)
