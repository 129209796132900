/* eslint-disable no-undef */
import { createSelector, Selector } from 'reselect'
import { BRANCH_KEY } from '../branch'

import { IAlertsOwnState } from '../model'

import { IAlertState } from './model'

export const branch: Selector<IAlertsOwnState, IAlertState> = (state) => state[BRANCH_KEY].alert

export const messages = createSelector(branch, (branch) => branch.toasts)

export const banner = createSelector(branch, (branch) => branch.banner)
