import React from 'react'
import { EuiButton, EuiButtonEmpty, EuiPopover, EuiPopoverProps } from '@elastic/eui'
import { Grid, Paragraph, useToggle } from 'modules/web-atoms'
import { ResponsiveColor } from 'modules/dna'

export interface IDropdownMenuItem {
  label: string
  onClick: () => void
}

export interface IDropdownMenuProps extends Omit<EuiPopoverProps, 'closePopover' | 'isOpen' | 'button'> {
  items: IDropdownMenuItem[]
  labelColor?: ResponsiveColor
}

export const DropdownMenu: React.FC<IDropdownMenuProps> = ({ items, children, panelStyle, labelColor, ...rest }) => {
  const [isPopoverOpen, togglePopover] = useToggle(false)

  const button = (
    <EuiButton iconType="arrowDown" iconSide="right" onClick={togglePopover}>
      <Paragraph width={100} color={labelColor ?? 'primaryMid'}>
        {children}
      </Paragraph>
    </EuiButton>
  )

  const onItemClick = (cb: () => void) => () => {
    cb()
    togglePopover()
  }

  return (
    <EuiPopover
      panelPaddingSize="s"
      closePopover={togglePopover}
      isOpen={isPopoverOpen}
      button={button}
      hasArrow={false}
      panelStyle={{ marginTop: 10, ...panelStyle }}
      {...rest}
    >
      <Grid>
        {items.map((item, idx) => (
          <EuiButtonEmpty style={{ minWidth: 150 }} key={idx} color="primary" onClick={onItemClick(item.onClick)}>
            {item.label}
          </EuiButtonEmpty>
        ))}
      </Grid>
    </EuiPopover>
  )
}

export default React.memo(DropdownMenu)
