import prefix from 'modules/utils/prefix'
import { ITranslatePayload } from './model'
import { identity } from 'modules/utils'
import { createAction } from 'redux-actions'

export const namespaced = prefix('translation')

export const TRANSLATE = namespaced('translate')
export const translate = createAction<ITranslatePayload>(TRANSLATE)

export const RESULT = namespaced('result')
export const result = createAction<string, ITranslatePayload, string, ITranslatePayload>(
  RESULT,
  identity,
  (_, translation) => translation,
)
