import { GlobalAPI } from 'api'
import { Expose, instanceToPlain, plainToInstance } from 'class-transformer'
import { QueryKey, useApi } from 'modules/module-api'
import { useTranslate } from 'modules/web-atoms'
import React, { useEffect, useMemo } from 'react'
import sha512 from 'sha512'
import { emailRegex } from '../../formValidations'
import { useDebounce } from '../use-debounce/use-debounce'

export class IEmailExistsDto {
  @Expose({ name: 'Exists' })
  exists: boolean
}

export class IEmailExistsRequestDto {
  @Expose({ name: 'HashedEmail' })
  hashedEmail: string
  @Expose({ name: 'DoubleHashedEmail' })
  doubleHashedEmail: string
}

export const useEmailExists = (email: string, enabled = false) => {
  const t = useTranslate('onboarding.signup.account-details.form.validation.email')

  const debouncedEmail = useDebounce('', 3000, false)(email)
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined)
  const isValidEmail = useMemo(() => {
    return debouncedEmail != undefined && emailRegex.test(debouncedEmail)
  }, [debouncedEmail])

  const { data, isFetching, refetch, error } = useApi<IEmailExistsDto>(
    [QueryKey.COURIERS, debouncedEmail],
    async () => {
      const dto = new IEmailExistsRequestDto()
      dto.hashedEmail = sha512(email).toString('hex')
      dto.doubleHashedEmail = sha512(`${email}${email}`).toString('hex')

      const response = await GlobalAPI({
        url: 'email-exists',
        method: 'POST',
        data: instanceToPlain(dto),
      })
      return response.data
    },
    {
      enabled: isValidEmail && enabled,
      cacheTime: 0,
      select: (data) => {
        return plainToInstance(IEmailExistsDto, data)
      },
    },
  )

  useEffect(() => {
    if (error!!) {
      setErrorMessage(t('unknown'))
    } else if (data!! && data.exists) {
      setErrorMessage(t('registered'))
    } else {
      setErrorMessage(undefined)
    }
  }, [data, error, t])

  React.useEffect(() => {
    if (!enabled) setErrorMessage(undefined)
  }, [enabled])

  return errorMessage
}

export default useEmailExists
