import { createSelector, Selector } from 'reselect'

import { IUsersOwnState } from '../model'

import { IUserState } from './models'

export const branch: Selector<IUsersOwnState, IUserState> = (state) => state.users.user

export const selectedUsers = createSelector(branch, (branch) => branch?.selectedUsers)

export const submittedForm = createSelector(branch, (branch) => branch?.submittedForm)

export const selectedUser = createSelector(branch, (branch) => branch?.selectedUser)

export const isDeletingUsers = createSelector(branch, (branch) => branch?.isDeletingUsers)

export const isDeletingUser = createSelector(branch, (branch) => branch?.isDeletingUser)

export const isUpdating = createSelector(branch, (branch) => branch?.isUpdating)

export const formState = createSelector(branch, (branch) => branch.formState)

export const selectedUsersSize = createSelector(branch, (branch) => {
  if (!!branch?.selectedUsers?.length) return branch?.selectedUsers?.length
  if (!!branch?.selectedUser) return 1
  return 0
})

export const permissions = createSelector(branch, (branch) => branch?.permissions)
