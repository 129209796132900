import * as React from 'react'
import { Trans } from 'react-i18next'

import { Laptop, ParcelPhone } from 'modules/dna'
import { Grid, H3, Image, RadioButton, Span } from 'modules/web-atoms'
import { RadioGroup } from 'modules/web-molecules'

export enum Flow {
  SETUP_APP = 'SETUP_APP',
  DEMO_BOOKED = 'DEMO_BOOKED',
}

export interface ISelectFlowStepProps {
  value: string
  onChange: (string) => void
}

const INTL_PREFIX = 'onboarding.signup.onboarding.select-flow-step'

export const SelectFlowStep: React.FC<ISelectFlowStepProps> = ({ value, onChange }) => {
  const handleChange = (value) => {
    onChange(value)
  }

  const radioOptions = React.useMemo(() => {
    return [
      {
        value: Flow.SETUP_APP,
        image: ParcelPhone,
        label: 'label.parcel-phone',
        legend: (
          <H3 textAlign="center" size="large">
            <Trans
              i18nKey={`${INTL_PREFIX}.setup-app-flow`}
              components={{
                span: <Span fontWeight="bold" color="primaryMid" />,
              }}
            />
          </H3>
        ),
      },
      {
        value: Flow.DEMO_BOOKED,
        image: Laptop,
        label: 'label.laptop',
        legend: (
          <H3 textAlign="center" size="large">
            <Trans
              i18nKey={`${INTL_PREFIX}.demo-booked-flow`}
              components={{
                span: <Span fontWeight="bold" color="primaryMid" />,
              }}
            ></Trans>
          </H3>
        ),
      },
    ]
  }, [])

  return (
    <Grid
      flex={1}
      alignItems="center"
      justifyContent="center"
      marginTop="medium"
      marginBottom="xlarge"
      paddingX={['small', 'large']}
    >
      <Grid.Row justifyContent="center" marginTop="medium">
        <RadioGroup flex={1} justifyContent="center" onChange={handleChange}>
          {radioOptions.map(({ value, image, legend, label }, index) => (
            <RadioButton.Card containerStyle={{ height: 250 }} key={index} id={value} legend={legend}>
              <Image src={image} minWidth={100} label={label} />
            </RadioButton.Card>
          ))}
        </RadioGroup>
      </Grid.Row>
    </Grid>
  )
}

export default React.memo(SelectFlowStep)
