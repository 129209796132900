import { Paragraph, useTranslate } from 'modules/web-atoms'
import React from 'react'
import styled from '@emotion/styled'
import { strings, useColorBrightness } from 'modules/module-utils'
import { IDeepfinityTheme, ThemeColor } from 'modules/dna'
import * as system from 'styled-system'
import { ToolTip } from 'modules/web-molecules'

type StyledLabelContainerProps = system.BorderProps<IDeepfinityTheme> &
  system.PaddingProps<IDeepfinityTheme> &
  system.MarginProps<IDeepfinityTheme> &
  system.BoxShadowProps<IDeepfinityTheme> &
  system.LayoutProps<IDeepfinityTheme> &
  system.ColorProps
const StyledLabelContainer: React.FC<StyledLabelContainerProps> = styled('div')(
  system.borders,
  system.margin,
  system.padding,
  system.color,
  system.layout,
  system.boxShadow,
)

export interface ITagLabelProps extends StyledLabelContainerProps {
  tagName: string
  tagColor: string
}

const TagLabel: React.FC<ITagLabelProps> = ({ tagName, tagColor, ...rest }) => {
  const t = useTranslate('settings.tabs.tags')
  const brightness = useColorBrightness(tagColor)
  const textColor: ThemeColor = React.useMemo(() => {
    return brightness === 'bright' ? 'textDark' : 'textLight'
  }, [brightness])
  const capitalizedName = strings.capitalize(tagName)

  return (
    <StyledLabelContainer
      boxShadow="small"
      backgroundColor={tagColor}
      py="smallest"
      px="medium"
      borderRadius="large"
      minWidth={[90, 120]}
      height={38}
      {...rest}
    >
      <ToolTip position="top" delay="regular" content={tagName}>
        <Paragraph textAlign="center" color={textColor} fontWeight="medium" truncated={true}>
          {capitalizedName || t('default-name')}
        </Paragraph>
      </ToolTip>
    </StyledLabelContainer>
  )
}

export default React.memo(TagLabel)
