import { QueryCache, QueryClient } from '@tanstack/react-query'

export const queryCache = new QueryCache()
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
  queryCache,
})

export default queryClient
