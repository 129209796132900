import { FontFace, IDeepfinityTheme } from 'modules/dna'
import * as system from 'styled-system'

export type HeadingStyleProps = system.ColorProps<IDeepfinityTheme> & system.TypographyProps<IDeepfinityTheme>

type HeadingVariant = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'h7' | 'h8' | 'h9' | 'h10'

type HeadingVariants = Record<HeadingVariant, HeadingStyleProps>

export const headingStyles: HeadingVariants = {
  h1: {
    color: 'textDark',
    fontFamily: FontFace.SofiaPro,
    fontSize: ['large', 'xxlarge', 'xxxlarge'],
    fontWeight: 'bold',
    lineHeight: 'xlarge',
  },
  h2: {
    color: 'primaryMid',
    fontFamily: FontFace.SofiaPro,
    fontSize: ['large', 'xlarge', 'xlarge'],
    fontWeight: 'semiBold',
    lineHeight: 'small',
  },
  h3: {
    color: 'textDark',
    fontFamily: FontFace.SofiaPro,
    fontSize: ['xsmall', 'medium', 'large'],
    fontWeight: 'regular',
    lineHeight: ['smallest', 'medium'],
  },
  h4: {
    color: 'primaryMid',
    fontFamily: FontFace.SofiaPro,
    fontSize: ['xsmall', 'medium', 'large'],
    fontWeight: 'bold',
  },
  h5: {
    color: 'primaryMid',
    fontFamily: FontFace.SofiaPro,
    fontSize: ['macro', 'xsmall', 'medium'],
    lineHeight: 'small',
  },
  h6: {
    color: 'textLight',
    fontFamily: FontFace.SofiaPro,
    fontSize: ['macro', 'xsmall', 'medium'],
    fontWeight: 'medium',
  },
  h7: {
    color: 'textDark',
    fontFamily: FontFace.SofiaPro,
    fontSize: 'xsmall',
    fontWeight: 'regular',
  },
  h8: {
    color: 'primaryMid',
    fontFamily: FontFace.SofiaPro,
    fontSize: 'xsmall',
    fontWeight: 'regular',
  },
  h9: {
    color: 'primaryMid',
    fontFamily: FontFace.SofiaPro,
    fontSize: 'xsmall',
    fontWeight: 'regular',
    lineHeight: 'xsmall',
  },
  h10: {
    color: 'textDark',
    fontFamily: FontFace.SofiaPro,
    fontSize: 'xsmall',
    fontWeight: 'bold',
  },
}
