import lazy from '@loadable/component'
import { IModuleRoute } from 'modules/module-navigation'

export enum NotificationsPath {
  KANBAN_PAGE = '/portal/notifications',
  CONFIGURATION_PAGE = '/portal/notifications/configure-notifications',
  EDIT_TEMPLATE = '/portal/notifications/edit-template',
}

const EditConfigurationPage = lazy(async () => import('../screens/EditConfigurationPage/EditConfigurationPage'))
const NotificationsKanban = lazy(async () => import('../screens/NotificationsKanban/NotificationsKanban'))
const NotificationEditTemplatePage = lazy(async () => import('../screens/EditNotificationTemplatePage/EditNotificationTemplatePage'))

export const routes: IModuleRoute[] = [
  {
    path: NotificationsPath.KANBAN_PAGE,
    page: NotificationsKanban,
  },
  {
    path: NotificationsPath.CONFIGURATION_PAGE,
    page: EditConfigurationPage,
  },
  {
    path: NotificationsPath.EDIT_TEMPLATE,
    page: NotificationEditTemplatePage,
  },
]

export default routes
