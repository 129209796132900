import { EuiLink } from '@elastic/eui'
import { useColor } from 'modules/dna'
import { Grid, Icon, IconType, IGridProps, useHover } from 'modules/web-atoms'
import { Paragraph } from 'modules/web-atoms/components/typography/Paragraph/Paragraph'
import { ToolTip } from 'modules/web-molecules'
import * as React from 'react'
import { useHistory } from 'react-router'

export interface IMenuLinkItem extends IGridProps {
  isMinimized: boolean
  link?: string
  to?: string
  title: string
  icon: IconType
}

export const MenuLinkItem: React.FC<IMenuLinkItem> = (props) => {
  const { isMinimized, link, icon, title, to, ...rest } = props
  const href = to ? undefined : link
  const history = useHistory()
  const [isHovered, eventHandlers] = useHover()

  const onClick = (event: React.BaseSyntheticEvent) => {
    if (to) {
      event.preventDefault()
      history.push(to)
    }
  }

  const Image = Icon[icon]
  const imageColor = useColor('secondaryDark')
  return (
    <>
      {isMinimized ? (
        <Grid alignItems="center" height={35} {...rest}>
          <Grid>
            <ToolTip content={title} position="right" delay="regular">
              <EuiLink
                onClick={onClick}
                href={href}
                style={{
                  textDecoration: 'blink',
                }}
                target="_blank"
                external={false}
              >
                <Image fill={imageColor} />
              </EuiLink>
            </ToolTip>
          </Grid>
        </Grid>
      ) : (
        <Grid {...rest}>
          <EuiLink
            onClick={onClick}
            href={href}
            target="_blank"
            style={{ color: '#767676', textDecoration: isHovered ? 'underline' : 'none' }}
            external={false}
            {...eventHandlers}
          >
            <Paragraph color="textLightMid">{title}</Paragraph>
          </EuiLink>
        </Grid>
      )}
    </>
  )
}

export default React.memo(MenuLinkItem)
