import * as React from 'react'

import StyledText, { StyledTextProps } from '../StyledText/StyledText'

import { paragraphStyles } from './styles'

export type ParagraphVariant = 'small' | 'medium' | 'large' | 'huge' | 'xhuge'

export interface IParagraphProps extends Omit<StyledTextProps, 'fontSize'> {
  size?: ParagraphVariant
}

export const Paragraph: React.FC<IParagraphProps> = ({ size, children, ...rest }) => {
  const style = paragraphStyles[size!!]

  return (
    <StyledText {...style} {...rest}>
      {children}
    </StyledText>
  )
}

Paragraph.defaultProps = {
  size: 'medium',
}

Paragraph.displayName = 'Paragraph'

export default React.memo(Paragraph) as React.FC<IParagraphProps>
