import { intlNext, changeLanguage } from 'modules/module-intl'
import { Language } from 'modules/module-intl/settings'

void intlNext.init({
  fallbackLng: Language.English,
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
}, async () => changeLanguage(intlNext.language))

export default intlNext
