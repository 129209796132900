import { UserType } from 'api'

export interface IPermissions {
  canViewStatistics: boolean
  canViewOutboundStats: boolean
}

export interface IStatisticsState {
  permissions: IPermissions
}

export const initialState: IStatisticsState = {
  permissions: {
    canViewStatistics: false,
    canViewOutboundStats: false,
  },
}

export interface ISetPermissionsPayload {
  userType: UserType
  isOutboundEnabled?: boolean
}
