import { EuiButton } from '@elastic/eui'
import { useAppDispatch, useAppSelector } from 'modules/module-utils'
import { Grid, useTranslate } from 'modules/web-atoms'
import React from 'react'
import SettingsTabTemplate from '../../../components/SettingsTabTemplate/SettingsTabTemplate'
import settings from '../../../settings'
import TagsList from './TagsList'

export interface ITagsTabProps {}

const TagsTab: React.FC<ITagsTabProps> = () => {
  const t = useTranslate('settings.tabs.tags')
  const dispatch = useAppDispatch()

  const { canManageTags } = useAppSelector(settings.selectors.permissions)

  const onAddTag = () => {
    dispatch(settings.actions.showAddTagForm(true))
  }

  return (
    <SettingsTabTemplate heading={t('heading')} description={t('description')} width="100%" minHeight={0}>
      <SettingsTabTemplate.Section minHeight={0}>
        <Grid gutterSize="smallest" minHeight={0}>
          {canManageTags && (
            <Grid.Row justifyContent="right">
              <EuiButton onClick={onAddTag} color="primary" fill>
                {t('add-tag')}
              </EuiButton>
            </Grid.Row>
          )}
          <Grid.Row flexGrow={1} minHeight={0}>
            <TagsList />
          </Grid.Row>
        </Grid>
      </SettingsTabTemplate.Section>
    </SettingsTabTemplate>
  )
}

export default React.memo(TagsTab)
