export enum LogLevel {
  None = 'none',
  Debug = 'debug',
  Info = 'info',
  Warning = 'warning',
  Error = 'error',
  Fatal = 'fatal',
}

export type IErrorContext = JSONPrimitiveObject

export const BRANCH_KEY = 'reporting'

export interface IErrorState {
  context: IErrorContext
}

export interface IReportingBranchState {
  errors: IErrorState
}

export interface IReportingOwnState {
  [BRANCH_KEY]: IReportingBranchState
}

export interface IReportingConfiguration {
  type: ReporterType
  source: string
  debug: boolean
  environment: string
  version: string
}

export enum ReporterType {
  Sentry = 'sentry',
}

export interface IMessageOptions {
  context?: string
  tags?: JSONPrimitiveObject
  level?: Exclude<LogLevel, LogLevel.None>
}

export interface IErrorOptions extends IMessageOptions {
  isHandled?: boolean
}

export interface IIdentifyPayload {
  user: string
}

export interface IErrorMeta<Shape> {
  errors: Shape
}

export interface IContextShape {
  context: IErrorContext
}

export const INITIAL_STATE: IReportingOwnState = {
  [BRANCH_KEY]: {
    errors: { context: {} },
  },
}
