import { AnyAction } from 'redux'
import { Action, ActionMeta } from 'redux-actions'
import { SagaIterator } from 'redux-saga'
import { all, call, select, takeEvery } from 'redux-saga/effects'

import autobind from 'autobind-decorator'
import { SupervisorSuite } from 'modules/redux-supervisor'
import { SENTRY_ENVIRONMENT } from 'modules/utils/constants'

import * as actions from '../actions/actions'
import * as matchers from '../actions/matchers'
import { IErrorMeta, IErrorOptions, IIdentifyPayload } from '../model'
import { IReporter } from '../reporters/model'
import * as selectors from '../selectors'

export interface IReportingSagaParams {
  reporter: IReporter | Promise<IReporter>
}

export class ReportingSaga extends SupervisorSuite {
  private readonly _reporter: IReporter | Promise<IReporter>

  constructor({ reporter }: IReportingSagaParams) {
    super()

    this._reporter = reporter
  }

  @autobind
  *start(): SagaIterator {
    yield all([
      takeEvery(actions.IDENTIFY, this.identify),
      takeEvery(matchers.isError, this.error),
      takeEvery(actions.WARN, this.warn),
      takeEvery(actions.LOG, this.log),
    ])
  }

  @autobind
  *identify({ payload }: Action<IIdentifyPayload>): SagaIterator {
    const reporter: IReporter = yield call(this.reporter)
    yield call(reporter.identify, payload)
  }

  @autobind
  *error(action: AnyAction): SagaIterator {
    const { type, payload, meta } = action
    // eslint-disable-next-line no-unsafe-optional-chaining
    const { tags: additional, ...options } = this.isMetaError(action) ? meta?.errors : { tags: meta }

    const reporter: IReporter = yield call(this.reporter)

    const tags: JSONPrimitiveObject = yield select(selectors.tags, additional)

    const error = payload ?? type
    yield call(reporter.error, error, { tags, ...options })
  }

  @autobind
  *warn({ payload }: Action<string>): SagaIterator {
    const reporter: IReporter = yield call(this.reporter)
    const tags: JSONPrimitiveObject = yield select(selectors.tags)

    yield call(reporter.warn, payload, { tags })
  }

  @autobind
  *log({ payload }: Action<string>): SagaIterator {
    const reporter: IReporter = yield call(this.reporter)
    const tags: JSONPrimitiveObject = yield select(selectors.tags)

    yield call(reporter.log, payload, { tags })
  }

  readonly reporter = async (): Promise<IReporter> => Promise.resolve(this._reporter)

  private readonly isMetaError = (action: AnyAction): action is ActionMeta<unknown, IErrorMeta<IErrorOptions>> =>
    action?.meta?.errors
}

export default ReportingSaga
