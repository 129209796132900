export * from './screens'
export * from './paths'
import setting from './settings'
import reducer from './root-reducer'
import settingsSagas from './sagas'

export const settingsModule = {
  actions: {
    ...setting.actions,
  },
  selectors: {
    ...setting.selectors,
  },
  reducer,
  sagas: settingsSagas,
}

export default settingsModule
