import { EventFormatter } from '../models'

export const removeResponseData: EventFormatter = (event) => {
  if (event.extra?.response?.config?.data) {
    delete event.extra.response.config.data
  }

  return event
}

export default removeResponseData
