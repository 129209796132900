import * as React from 'react'

import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import shouldForwardProp from '@styled-system/should-forward-prop'

import { IDeepfinityTheme, ResponsiveColor, Spacing, transitionDecoration } from 'modules/dna'
import * as system from 'styled-system'
import Grid from '../../Grid/Grid'

const framing = {
  primary: {
    marginTop: Spacing.Medium,
    marginBottom: Spacing.Medium,
    marginLeft: Spacing.Medium,
    marginRight: Spacing.Medium,
  },
  secondary: {
    marginTop: Spacing.Smallest,
    marginLeft: Spacing.Medium,
    marginRight: Spacing.Medium,
  },
}

export type TransformProps = system.ShadowProps<IDeepfinityTheme> &
  system.ColorProps<IDeepfinityTheme> &
  system.FlexboxProps<IDeepfinityTheme> &
  system.TypographyProps<IDeepfinityTheme> &
  system.LayoutProps<IDeepfinityTheme> &
  system.MarginProps<IDeepfinityTheme> &
  system.PositionProps<IDeepfinityTheme> &
  system.BorderProps<IDeepfinityTheme> &
  system.SpaceProps<IDeepfinityTheme> & {
    framing?: keyof typeof framing
  }

const transform = system.compose(
  system.color,
  system.flexbox,
  system.borders,
  system.margin,
  system.position,
  system.layout,
  system.variant({ prop: 'framing', variants: framing }),
)

export type StyledButtonProps = TransformProps & system.ButtonStyleProps<IDeepfinityTheme>

export const StyledButton = styled('button', { shouldForwardProp })<StyledButtonProps>(
  system.shadow,
  system.buttonStyle,
  system.typography,
  system.padding,
  system.flexbox,
  system.color,
  system.borders,
  transitionDecoration,
)

export interface IButtonFrameProps extends React.ComponentProps<typeof StyledButton> {
  activeTextColor?: ResponsiveColor
  activeBackgroundColor?: ResponsiveColor
  label?: string
  onClick?: React.MouseEventHandler | undefined
}

const useContainer = (props: IButtonFrameProps) => transform({ ...props, theme: useTheme() })

export const ButtonFrame: React.FunctionComponent<IButtonFrameProps> = (props) => {
  const { label, children, ...button } = props

  const container = useContainer(button)
  const borders = system.border(props)
  const flex = system.flexGrow(container)
  //Fixme: Text decoration didn't work
  return (
    <Grid testID="button:container" boxShadow={props.boxShadow} {...container} alignItems="stretch" height="100%">
      <Grid overflow="hidden" alignItems="stretch" height="100%" {...flex} {...borders}>
        <StyledButton aria-label={label} style={{ transition: 'all 0.5s ease-out', height: '100%' }} {...button}>
          {children}
        </StyledButton>
      </Grid>
    </Grid>
  )
}

export default React.memo(ButtonFrame) as typeof ButtonFrame
