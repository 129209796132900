import React from 'react'

import { ButtonColor, EuiButton, EuiButtonEmpty } from '@elastic/eui'

import { ResponsiveColor } from 'modules/dna'
import { Grid, H1, Icon, Modal, Paragraph } from 'modules/web-atoms'

export type AlertModalType = 'success' | 'warning' | 'error'

export interface IAlertModalProps extends Omit<React.ComponentProps<typeof Modal>, 'title' | 'onClose' | 'children'> {
  type: AlertModalType

  onAction: () => void
  onClose: () => void

  heading: string
  description?: string
  actionLabel: string
  cancelLabel?: string
  showIcon?: boolean
  isLoading?: boolean
}

export interface IAlertModalStyles {
  textColor?: ResponsiveColor
  color: ButtonColor
  icon: JSX.Element
}

export const AlertModalStyles: Record<AlertModalType, IAlertModalStyles> = {
  success: { textColor: 'primaryMid', color: 'success', icon: <Icon.Success /> },
  warning: { textColor: undefined, color: 'danger', icon: <Icon.ErrorAlt /> },
  error: { textColor: 'dangerDestructive', color: 'danger', icon: <Icon.ErrorAlt /> },
}

export const AlertModal: React.FC<IAlertModalProps> = (props) => {
  const { type, heading, description, onAction, onClose, actionLabel, cancelLabel, showIcon, isLoading, ...rest } =
    props

  const style: IAlertModalStyles = AlertModalStyles[type]
  const icon: JSX.Element | undefined = showIcon ? style.icon : undefined

  return (
    <Modal borderRadius={['small', 'medium']} {...rest} maxWidth={300} hasClose={true} onClose={onClose}>
      <Grid flexGrow={1} width="100%" alignItems="center" padding="medium" gutterSize="smallest">
        <Grid alignItems="center">
          {icon && <Grid.Col>{icon}</Grid.Col>}
          <Grid.Col>
            <H1 fontSize="xlarge" textAlign="center" color={style.textColor}>
              {heading}
            </H1>
          </Grid.Col>
        </Grid>
        <Paragraph textAlign="center">{description}</Paragraph>

        <Grid gutterSize="medium" marginTop="large">
          {cancelLabel && (
            <Grid.Col>
              <EuiButtonEmpty onClick={onClose}>{cancelLabel}</EuiButtonEmpty>
            </Grid.Col>
          )}
          <Grid.Col>
            <EuiButton
              isLoading={isLoading}
              type="submit"
              form="alertModal"
              onClick={onAction}
              fill
              color={style.color}
            >
              {actionLabel}
            </EuiButton>
          </Grid.Col>
        </Grid>
      </Grid>
    </Modal>
  )
}

export default React.memo(AlertModal)
