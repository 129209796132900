import { SortDirection } from 'modules/module-api'
import { IUsePaginatedTableParams, useAppDispatch, useAppSelector, usePaginatedTable } from 'modules/module-utils'
import { H5, Paragraph, useTranslate } from 'modules/web-atoms'
import {
  IColumns,
  IPagination,
  ITableItem,
  ITableSelectedChange,
  TableColumnType,
  TableSelectionAction,
} from 'modules/web-molecules'
import React from 'react'
import settings, { SettingsFormState } from '../../settings'
import useSizes, { SizesSortField } from '../use-sizes/use-sizes'

export type ISizesTableColumns = SizesSortField
export type ISizesTableItem = ITableItem<SizesSortField>

export const useSizesTable = (siteId: number | undefined) => {
  const t = useTranslate('settings.tabs.sizes.table')
  const dispatch = useAppDispatch()

  const selectedSize = useAppSelector(settings.selectors.selectedSize)
  const selectedSizes = useAppSelector(settings.selectors.selectedSizes)

  const onSortChange = () => {
    dispatch(settings.actions.setSelectedSize(undefined))
  }

  const onSelectionChange: ITableSelectedChange<ISizesTableColumns, ISizesTableItem> = (
    items: ISizesTableItem[],
    action: TableSelectionAction,
  ) => {
    dispatch(
      settings.actions.setSelectedSizes({
        items: items.map((item) => item.id),
        action,
      }),
    )
  }

  const paginationParams: IUsePaginatedTableParams<
    ISizesTableColumns,
    ISizesTableItem,
    SizesSortField,
    typeof SizesSortField
  > = {
    sortClass: SizesSortField,
    pageParams: {
      page: 0,
      pageSize: 10,
      sortBy: SizesSortField.NAME,
      sort: SortDirection.ASC,
      filter: '',
    },
    itemsPerPage: [10, 20, 50],
    onSortChange,
    onSelectionChange,
  }

  const { paging, paginationOptions, onSearch, selection, sort } = usePaginatedTable<
    ISizesTableColumns,
    ISizesTableItem,
    SizesSortField,
    typeof SizesSortField
  >(paginationParams)

  const { sizes, isFetching, totalItems, error } = useSizes(
    {
      page: paging.page + 1,
      sortBy: paging.sortBy,
      sort: paging.sort,
      pageSize: paging.pageSize,
      filter: paging.filter,
    },
    {
      enabled: !!siteId,
      cacheTime: 0,
    },
  )

  const onItemSelected = (item: ISizesTableItem) => {
    if (selectedSize?.id === item.id) {
      dispatch(settings.actions.setSelectedSize(undefined))
      dispatch(settings.actions.setSizeFormState(SettingsFormState.HIDDEN))
    } else {
      const size = sizes.find((size) => size.id === item.id)
      dispatch(settings.actions.setSelectedSize(size))
      dispatch(settings.actions.setSizeFormState(SettingsFormState.EDIT))
    }
  }

  const pagination: IPagination = {
    pageIndex: paging.page,
    pageSize: paging.pageSize,
    total: totalItems,
  }

  const columns: IColumns<ISizesTableColumns> = {
    [SizesSortField.NAME]: {
      label: <H5 padding="smallest">{t('column.name')}</H5>,
      type: TableColumnType.Column,
      isSortable: true,
    },
    [SizesSortField.TYPE]: {
      label: <H5 padding="smallest">{t('column.type')}</H5>,
      type: TableColumnType.Column,
      isSortable: false,
    },
  }

  const items: ISizesTableItem[] = React.useMemo(() => {
    return sizes.map((size) => {
      const id = size.id
      return {
        id,
        isSelectable: !!size.clientId,
        isChecked: !!selectedSizes.find((itemId) => itemId === id),
        isSelected: !!selectedSize && id === selectedSize.id,
        isCheckable: !!size.clientId,
        [SizesSortField.NAME]: {
          render: () => (
            <Paragraph title={size.name} fontWeight="bold" size="medium" padding="smallest" truncated={true}>
              {size.name}
            </Paragraph>
          ),
        },
        [SizesSortField.TYPE]: {
          render: () => {
            return !size.clientId ? (
              <Paragraph title={t('default')} fontWeight="bold" size="medium" padding="smallest" truncated={true}>
                {t('default')}
              </Paragraph>
            ) : (
              <></>
            )
          },
        },
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sizes, selectedSize, selectedSizes])

  return {
    columns,
    items,
    selection,
    onSearch,
    onItemClicked: onItemSelected,
    isFetching,
    error,
    pagination,
    sort,
    paginationOptions,
    selectedSizes: selectedSizes,
  }
}
