export interface IValueStore<T> {
    readonly key: string
    get(): Promise<T>
    set(value: T): Promise<boolean>
    exists(): Promise<boolean>
    clear(): Promise<void>
  }
  
  export enum StorageError {
    NOT_EXIST,
  }