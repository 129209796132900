import { UserType } from 'api'
import { TableSelectionAction } from 'modules/web-molecules'

export interface IDevelopment {
  Id: string
  BuildingName: string
  ClientId: number
  AddressLine1?: string
  AddressLine2?: string
  Postcode?: string
  City?: string
  Country: string
  BuildingSize?: number
  TextsSent?: number
  RecordModified: any
  TextsEnabled?: boolean
  Enabled?: boolean
}

export interface IPermissions {
  canViewBuildings: boolean
  canEditBuildings: boolean
  canAddBuildings: boolean
  canRemoveBuildings: boolean
}

export enum FormState {
  HIDDEN = 'hidden',
  CREATING = 'creating',
  EDITING = 'editing',
}

export const initialState: IDevelopmentState = {
  selectedBuildings: [],
  selectedBuilding: undefined,
  isDeletingBuildings: false,
  isDeletingBuilding: false,
  isUpdating: false,
  formState: FormState.HIDDEN,

  currentDevelopments: [],
  permissions: {
    canViewBuildings: false,
    canEditBuildings: false,
    canAddBuildings: false,
    canRemoveBuildings: false,
  },
  error: undefined,
  loading: false,
  submittedForm: false,
}
export interface IDevelopmentState {
  selectedBuildings: string[]
  selectedBuilding?: string
  isDeletingBuildings: boolean
  isDeletingBuilding: boolean
  isUpdating: boolean
  formState: FormState

  currentDevelopments: IDevelopment[]
  permissions: IPermissions
  error?: string
  loading: boolean
  submittedForm: boolean
}
export type ISetDevelopmentsPayload = IDevelopment[]

export interface ISetPermissionsPayload {
  userType: UserType
}

export interface ISetSelectedBuildingsPayload {
  items: string[]
  action: TableSelectionAction
}

export interface ISetSelectedBuildingPayload {
  building?: string
}

export interface IAddBuildingPayload {
  name: string
  address: string
  city: string
  postcode: string
  users: string[]
}

export interface IUpdateBuildingPayload {
  id: string
  name: string
  address: string
  city: string
  postcode: string
  users: string[]
}
