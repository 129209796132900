import { createSelector, Selector } from 'reselect'
import { BRANCH_KEY, ISecurityState } from '../model'
import { ISessionState, OnboardingStage, Role } from './models'

export const branch: Selector<ISecurityState, ISessionState> = (state) => state[BRANCH_KEY]?.session

export const loading = createSelector(branch, (branch) => branch?.loading)

export const isAuthenticated = createSelector(branch, (branch) => branch?.isAuthenticated)

export const firstName = createSelector(branch, (branch) => branch?.firstName)

export const lastName = createSelector(branch, (branch) => branch?.lastName)

export const email = createSelector(branch, (branch) => branch?.email)

export const phone = createSelector(branch, (branch) => branch?.phone)

export const id = createSelector(branch, (branch) => branch?.userId)

export const developmentId = createSelector(branch, (branch) => branch?.primarySite ?? -1)

export const additionalSites = createSelector(branch, (branch) => branch?.additionalSites ?? [])

export const role = createSelector(branch, (branch) => branch?.role)

export const isRecipient = createSelector(branch, (branch) => branch?.role === Role.RECIPIENT)

export const type = createSelector(branch, (branch) => branch?.type)

export const client = createSelector(branch, (branch) => branch?.client)
export const clientId = createSelector(branch, (branch) => branch?.clientId)

export const development = createSelector(branch, (branch) => branch?.development)

export const featureFlags = createSelector(branch, (branch) => branch?.featureFlags)

export const countryCode = createSelector(branch, (branch) => branch.countryCode)

export const showOnboardingModal = createSelector(
  branch,
  (branch) =>
    !branch?.client?.OnboardingStages.some((stage) =>
      [OnboardingStage.APP_SETUP_COMPLETE, OnboardingStage.APP_SETUP_SKIPPED, OnboardingStage.DEMO_BOOKED].includes(
        stage,
      ),
    ),
)

export const onboardingStages = createSelector(branch, (branch) => branch?.client?.OnboardingStages)

export const lastOnboardingStage = createSelector(
  branch,
  (branch) => branch?.client?.OnboardingStages[branch?.client?.OnboardingStages.length - 1],
)

export const showResidentsOnboardingScreen = createSelector(branch, (branch) =>
  branch?.client?.OnboardingStages != undefined
    ? branch?.client?.OnboardingStages.indexOf(OnboardingStage.RECIPIENTS_UPLOADED) < 0 &&
      branch?.client?.OnboardingStages.indexOf(OnboardingStage.RECIPIENTS_UPLOAD_SKIPPED) < 0 &&
      branch?.client?.OnboardingStages.indexOf(OnboardingStage.ONBOARDING_SKIPPED) < 0
    : undefined,
)
