export const HEARTBEAT_NAME = 'analytics'

export interface IAnalyticsMeta {
  name: string
  data?: JSONObject
}

export interface IWhitelistMetaShape {
  analytics: IAnalyticsMeta
}
export type ITrackingContext = JSONObject

export interface IAnalyticsState {
  context: ITrackingContext
}

export const BRANCH_KEY = 'analytics'

export interface IAnalyticsBranchState {
  analytics: IAnalyticsState
}

export interface IAnalyticsOwnState {
  [BRANCH_KEY]: IAnalyticsBranchState
}

export type AnalyticsConfiguration = null

export type IContextPayload = ITrackingContext

export interface IAnalyticsIdentifyPayload {
  userId: string
  email: string
  clientId?: number
}

export const INITIAL_STATE: IAnalyticsOwnState = {
  [BRANCH_KEY]: {
    analytics: { context: {} },
  },
}
