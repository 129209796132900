import { UserType } from 'api'
import { LanguageSelector } from 'modules/module-intl'
import { Form, Grid, useTranslate } from 'modules/web-atoms'
import { FormRow } from 'modules/web-molecules'
import React from 'react'
import { TabPanels } from '../RecipientProfilePage'

export interface IFormValues {
  id: string
  firstName: string
  lastName: string
  role: string
  userType: UserType
  developmentId: number
}

interface IRecipientSettingsTabProps extends React.ComponentProps<typeof Grid> {}

const RecipientSettingsTab: React.FC<IRecipientSettingsTabProps> = ({ ...rest }) => {
  const t = useTranslate(`recipient-portal.tabs.${TabPanels.SETTING}`)

  return (
    <Grid mt="large" gutterSize="large" width="100%" {...rest}>
      <Grid.Row>
        <Form width="100%">
          <FormRow label={t('language')}>
            <LanguageSelector />
          </FormRow>
        </Form>
      </Grid.Row>
    </Grid>
  )
}

export default React.memo(RecipientSettingsTab)
