import { IOutboundParcelRowItem } from 'api'
import API from 'api/clients'
import { plainToClass } from 'class-transformer'
import { QueryKey } from 'modules/module-api'
import useApi from 'modules/module-api/hooks/useApi/use-api'
import { security } from 'modules/module-security'
import { useAppSelector, useDebounce } from 'modules/module-utils'
import { Moment } from 'moment'
import { useMemo } from 'react'
import { CollectionStatusOutbound } from '../../screens/models'
import { IPaginationSortParams } from '../models'

export enum OutboundSortFields {
  PARCEL_IMAGE = 'PARCEL_IMAGE',
  SENDER_NAME = 'SENDER_NAME',
  RECIPIENT_NAME = 'RECIPIENT_NAME',
  BUILDING_NAME = 'BUILDING_NAME',
  COURIER = 'COURIER',
  COLLECTED = 'COLLECTED',
  DROPPED_OFF = 'DROPPED_OFF',
  LAST_MODIFIED = 'LAST_MODIFIED',
}

export interface IUseOutboundParcelsParams extends IPaginationSortParams<OutboundSortFields> {
  buildingIds: number[]
  parcelStatus: CollectionStatusOutbound
  utcOffset: number
  droppedOffFrom?: Moment
  droppedOffTo?: Moment
  collectedOnFrom?: Moment
  collectedOnTo?: Moment
  filter?: string
  recipientId?: string
}

interface IResponse {
  Elements: any[]
  Page: number
  PageSize: number
  TotalPages: number
  TotalResults: number
}

export function useOutboundParcels({
  buildingIds,
  page,
  pageSize,
  sortBy,
  sort,
  parcelStatus,
  utcOffset,
  droppedOffFrom,
  droppedOffTo,
  collectedOnFrom,
  collectedOnTo,
  recipientId,
  filter = '',
}: IUseOutboundParcelsParams) {
  const debouncedFilter = useDebounce('', 500, false)(filter)
  const client = useAppSelector(security.selectors.client)

  const { data, isFetching, error } = useApi<IResponse>(
    [
      QueryKey.OUTBOUND_PARCELS,
      buildingIds,
      page,
      pageSize,
      sortBy,
      sort,
      parcelStatus,
      utcOffset,
      droppedOffFrom,
      droppedOffTo,
      collectedOnFrom,
      collectedOnTo,
      debouncedFilter,
      recipientId,
      client,
    ],
    async () => {
      if (!!buildingIds.length) {
        const response = await API({
          url: '/v3/outbound-parcels/search',
          params: {
            development_ids: buildingIds.join(','),
            page,
            page_size: pageSize,
            sort_by: sortBy,
            collection_status: parcelStatus,
            dropped_off_from: droppedOffFrom?.toISOString(),
            dropped_off_to: droppedOffTo?.toISOString(),
            collected_on_from: collectedOnFrom?.toISOString(),
            collected_on_to: collectedOnTo?.toISOString(),
            sort,
            utc_offset: utcOffset,
            tenant_id: recipientId,
            filter: debouncedFilter,
          },
        })
        return response.data
      }
    },
    { keepPreviousData: true },
  )

  const { outboundParcelsList, totalItems } = useMemo(() => {
    const outboundParcelsList = data?.Elements ? plainToClass(IOutboundParcelRowItem, data?.Elements) : []
    const totalItems = data?.TotalResults || 0
    return { outboundParcelsList, totalItems }
  }, [data])

  return { outboundParcelsList, totalItems, isFetching, error }
}

export default useOutboundParcels
