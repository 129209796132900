import * as React from 'react'

import { EuiSuperSelect, EuiSuperSelectOption } from '@elastic/eui'

import { UserType } from 'api'
import { FormikConfig, useFormik } from 'formik'
import { strings } from 'modules/module-utils'
import { useAppDispatch, useAppSelector, useEmailExists, useValidations } from 'modules/module-utils/hooks'
import { Form, IGridProps, Paragraph, useToggle, useTranslate } from 'modules/web-atoms'
import { ComboBox, DeleteConfirmationModal, FieldText, FormRow } from 'modules/web-molecules'
import { TableForm, TableFormTemplate } from 'modules/web-templates'
import * as Yup from 'yup'

import security from 'modules/module-security'
import useSelectedBuildings from '../../../hooks/use-selected-buildings/use-selected-buildings'
import useUser from '../../../hooks/use-user/use-user'
import permissionRules from '../../../permissions/Permissions'
import user, { FormState } from '../../../user'

export interface IFormValues {
  firstName: string
  lastName: string
  email: string
  type: UserType
  access: number[]
}

export interface IUserFormProps extends IGridProps {}

const INTL_PREFIX_MODAL = 'users-directory.delete-modal'

export const UserForm: React.FC<IUserFormProps> = (props) => {
  const { ...rest } = props

  const t = useTranslate('users-directory.form')
  const tCommon = useTranslate('commons')
  const dispatch = useAppDispatch()
  const [isDeleteOpen, toggle] = useToggle()
  const validations = useValidations()

  const { canCreateUsers, canRemoveUsers, canEditUsers } = useAppSelector(user.selectors.permissions)
  const currentUserType = useAppSelector(security.selectors.type)
  const isUpdating = useAppSelector(user.selectors.isUpdating)
  const isDeleting = useAppSelector(user.selectors.isDeletingUser)
  const selectedUser = useAppSelector(user.selectors.selectedUser)
  const formState = useAppSelector(user.selectors.formState)
  const isCreateForm = formState === FormState.CREATING

  const { user: currentUser, error, isFetching } = useUser(selectedUser)

  const {
    options,
    selectedBuildings,
    onBuildingSelection,
    buildingsError,
    isFetchingAllBuildings,
    isFetchingAssignedBuildings,
    setSelectedBuildings,
  } = useSelectedBuildings(selectedUser)

  const onSubmitUser: FormikConfig<IFormValues>['onSubmit'] = React.useCallback(
    (values, { resetForm }) => {
      if (!!selectedUser) {
        dispatch(
          user.actions.updateUser({
            id: selectedUser,
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            type: values.type,
            access: values.access,
          }),
        )
      } else {
        dispatch(
          user.actions.addUser({
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            type: values.type,
            access: values.access,
          }),
        )
      }
    },
    [selectedUser, dispatch],
  )

  const validationSchema = React.useMemo(
    () =>
      Yup.object<IFormValues>().shape({
        email: currentUser ? Yup.string() : validations.userEmail,
        firstName: Yup.string()
          .transform((v: string): string => v.trim())
          .min(2, ({ min }) => t('validation.min', { min }))
          .max(100, ({ max }) => t('validation.max', { max }))
          .required(t('validation.required')),
        lastName: Yup.string()
          .transform((v: string): string => v.trim())
          .min(2, ({ min }) => t('validation.min', { min }))
          .max(100, ({ max }) => t('validation.max', { max }))
          .required(t('validation.required')),
        access: Yup.array().min(1, t('validation.access.min')).required(t('validation.required')),
      }),
    [t, validations.userEmail, currentUser],
  )

  const formik = useFormik<IFormValues>({
    initialValues: {
      email: '',
      firstName: '',
      lastName: '',
      type: UserType.NORMAL,
      access: selectedBuildings.map((site) => site.value!),
    },
    onSubmit: onSubmitUser,
    validationSchema,
  })
  const {
    values,
    resetForm,
    touched,
    errors,
    setFieldValue,
    submitForm,
    handleSubmit,
    setValues,
    setFieldError,
    setFieldTouched,
    validateForm,
  } = formik

  const emailExistsError = useEmailExists(values.email, isCreateForm && !errors.email)

  React.useEffect(() => {
    void setFieldValue(
      'access',
      selectedBuildings.map((site) => site.value),
    )
  }, [selectedBuildings, setFieldValue])

  const customHandleChange = React.useCallback(
    (e: React.ChangeEvent<any>) => {
      const { value, name } = e.target
      void setFieldValue(name, value)
    },
    [setFieldValue],
  )

  const onUserTypeChange = React.useCallback(
    (userType: string) => {
      void setFieldTouched('email', true)
      void setFieldValue('type', UserType[userType])
    },
    [setFieldValue, setFieldTouched],
  )

  const onDelete = React.useCallback(() => {
    dispatch(user.actions.deleteUser())
    toggle()
  }, [dispatch, toggle])

  const onCancel = React.useCallback(() => {
    void setValues((values) => ({
      ...values,
      firstName: '',
      lastName: '',
      email: '',
      type: UserType.NORMAL,
      access: [],
    }))
    setSelectedBuildings([])
    dispatch(user.actions.cancelUserForm())
  }, [dispatch, setValues, setSelectedBuildings])

  React.useEffect(() => {
    void setValues((values) => ({
      ...values,
      firstName: strings.capitalize(currentUser?.firstName) || '',
      lastName: strings.capitalize(currentUser?.lastName) || '',
      email: strings.capitalize(currentUser?.email) || '',
      type: currentUser?.type || UserType.NORMAL,
    }))
  }, [currentUser, setValues])

  const deleteOptions: TableForm.IDeleteOptions = {
    value: t('delete'),
    loading: isDeleting,
    isDeleteModalOpen: isDeleteOpen,
    onClick: toggle,
  }

  const heading = isCreateForm ? t('create.heading') : strings.capitalize(currentUser?.firstName)
  const subheading = t('create.subheading')

  const formType: TableForm.FormType = isCreateForm ? TableForm.FormType.CREATE : TableForm.FormType.EDIT
  const permissions: TableForm.IPermissions = {
    canDelete: canRemoveUsers,
    canEdit: canEditUsers,
    canAdd: canCreateUsers,
  }

  const userTypeOptions: Array<EuiSuperSelectOption<string>> = React.useMemo(() => {
    return [UserType.OWNER, UserType.ADMIN, UserType.NORMAL, UserType.BASIC]
      .filter((userType) => !!currentUserType && permissionRules.canEditUser(currentUserType, userType))
      .map((userType) => {
        return {
          value: UserType[userType],
          inputDisplay: t(`type.${userType}`),
          dropdownDisplay: (
            <React.Fragment>
              <Paragraph color="primaryMid" fontWeight="bold">
                {t(`type.${userType}`)}
              </Paragraph>
              <Paragraph color="textMidDark">{t(`type.${userType}.description`)}</Paragraph>
            </React.Fragment>
          ),
        }
      })
  }, [currentUserType, t])

  const userType = React.useMemo(() => {
    return UserType[values.type]
  }, [values.type])

  return (
    <TableFormTemplate
      heading={heading}
      subheading={subheading}
      cancelValue={tCommon('action.cancel')}
      saveValue={tCommon('action.save')}
      createValue={tCommon('action.create')}
      formType={formType}
      deleteOptions={deleteOptions}
      permissions={permissions}
      onSubmit={submitForm}
      onCancel={onCancel}
      loading={isUpdating}
      isFetching={isFetching}
    >
      <TableFormTemplate.Form>
        <Form component="form" paddingX="none" py="large" onSubmit={handleSubmit}>
          <FormRow
            label={tCommon('form.email')}
            touched={touched.email || !!emailExistsError}
            error={errors.email || emailExistsError}
            inputMode="email"
          >
            <FieldText
              name="email"
              value={values.email}
              onChange={customHandleChange}
              disabled={!canEditUsers || !!currentUser}
              autoFocus={isCreateForm}
            />
          </FormRow>
          <FormRow
            label={tCommon('form.first-name')}
            touched={touched.firstName}
            error={errors.firstName}
            inputMode="text"
            marginTop="small"
          >
            <FieldText
              name="firstName"
              value={values.firstName}
              onChange={customHandleChange}
              disabled={!canEditUsers}
              autoFocus={!isCreateForm && canEditUsers}
            />
          </FormRow>
          <FormRow
            label={tCommon('form.last-name')}
            touched={touched.lastName}
            error={errors.lastName}
            inputMode="text"
            marginTop="small"
          >
            <FieldText name="lastName" value={values.lastName} onChange={customHandleChange} disabled={!canEditUsers} />
          </FormRow>
          <FormRow label={t('type')} touched={touched.type} error={errors.type} inputMode="text" marginTop="small">
            <EuiSuperSelect
              options={userTypeOptions}
              name="type"
              valueOfSelected={userType}
              onChange={onUserTypeChange}
              disabled={!canEditUsers}
              itemLayoutAlign="top"
              hasDividers
            />
          </FormRow>
          <FormRow
            label={t('access')}
            marginTop="small"
            touched={touched.access}
            error={errors.access === 'string' ? errors.access : errors.access?.toString()}
          >
            <ComboBox
              placeholder={t('access')}
              width="100%"
              options={options}
              selectedOptions={selectedBuildings}
              onChange={onBuildingSelection}
              isLoading={isFetchingAllBuildings || isFetchingAssignedBuildings}
              isDisabled={!canEditUsers}
              isClearable={true}
            />
          </FormRow>
        </Form>
      </TableFormTemplate.Form>

      <TableFormTemplate.DeleteModal>
        <DeleteConfirmationModal onClose={toggle} onDelete={onDelete} itemSize={1} intlPrefix={INTL_PREFIX_MODAL} />
      </TableFormTemplate.DeleteModal>
    </TableFormTemplate>
  )
}

export default React.memo(UserForm)
