import React from 'react'
import Zendesk, { IZendeskProps } from 'react-zendesk'

export interface IZendeskChat extends IZendeskProps {}

const deepfinityZendeskStyles: {
  [key: string]: any
} = {
  launcher: { mobile: { labelVisible: false } },
}

export const ZendeskChat: React.FC<IZendeskChat> = (props) => {
  const { children, ...rest } = props
  return (
    <Zendesk {...deepfinityZendeskStyles} {...rest}>
      {children}
    </Zendesk>
  )
}

export default ZendeskChat
