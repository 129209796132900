import React from 'react'
import { EuiRange } from '@elastic/eui'

import styled from '@emotion/styled'
import { StyledViewProps } from 'modules/web-atoms'
import * as system from 'styled-system'

export type ISliderProps = StyledViewProps & React.ComponentProps<typeof EuiRange>

const StyledSlider = styled(EuiRange)<ISliderProps>(
  system.color,
  system.flexbox,
  system.space,
  system.layout,
  system.position,
  system.justifyContent,
  system.alignItems,
  system.borders,
  system.shadow,
  system.background,
)

export const Slider: React.FC<ISliderProps> = (props) => {
  return <StyledSlider {...props} />
}

export default Slider
