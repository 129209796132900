import { EuiDatePicker, EuiDatePickerRange } from '@elastic/eui'
import { useTranslate } from 'modules/web-atoms'
import { Moment } from 'moment'
import React from 'react'

interface IDatePickerControlOptions {
  value: Moment | undefined
  onChange: (val: Moment) => void
  onClear?: () => void
}

export interface IDatePickerRangeProps
  extends Omit<React.ComponentProps<typeof EuiDatePickerRange>, 'startDateControl' | 'endDateControl'> {
  start: IDatePickerControlOptions
  end: IDatePickerControlOptions
}

export const DatePickerRange: React.FC<IDatePickerRangeProps> = ({ start, end, ...rest }) => {
  const t = useTranslate('deliveries-directory')
  const isInvalid = !!start.value && !!end.value && start.value > end.value

  const onChange = (onPropsChange: IDatePickerControlOptions['onChange']) => (value: Moment | undefined | null) => {
    if (value) onPropsChange(value)
  }

  return (
    <EuiDatePickerRange
      fullWidth
      startDateControl={
        <EuiDatePicker
          selected={start.value}
          onChange={onChange(start.onChange)}
          startDate={start.value}
          endDate={end.value}
          aria-label={t('start-date')}
          onClear={start.onClear}
          isInvalid={isInvalid}
          showTimeSelect
        />
      }
      endDateControl={
        <EuiDatePicker
          selected={end.value}
          onChange={onChange(end.onChange)}
          startDate={start.value}
          endDate={end.value}
          aria-label={t('end-date')}
          onClear={end.onClear}
          isInvalid={isInvalid}
          showTimeSelect
        />
      }
      {...rest}
    />
  )
}

export default React.memo(DatePickerRange)
