import { combineReducers } from 'redux'

import account from 'modules/module-account'
import analytics from 'modules/module-analytics'
import dashboard from 'modules/module-dashboard'
import deliveries from 'modules/module-deliveries'
import navigation from 'modules/module-navigation'
import alerts from 'modules/module-alerts'
import notifications from 'modules/module-notifications'
import onboarding from 'modules/module-onboarding'
import recipientsUpload from 'modules/module-recipient-upload'
import recipients from 'modules/module-recipients'
import reporting from 'modules/module-reporting'
import users from 'modules/module-users'
import settings from 'modules/module-settings'
import statistics from 'modules/module-statistics'
import billing from 'modules/module-billing'
import orchestration from 'modules/module-orchestration'
import intl from 'modules/module-intl'

import developments from '../../module-developments'
import { security } from 'modules/module-security'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const reducers = {
  onboarding: onboarding.reducer,
  account: account.reducer,
  security: security.reducer,
  dashboard: dashboard.reducer,
  developments: developments.reducer,
  router: navigation.router.reducer,
  analytics: analytics.reducer,
  reporting: reporting.reducer,
  alerts: alerts.reducer,
  notifications: notifications.reducer,
  recipients: recipients.reducer,
  recipientsUpload: recipientsUpload.reducer,
  users: users.reducer,
  deliveries: deliveries.reducer,
  settings: settings.reducer,
  statistics: statistics.reducer,
  billing: billing.reducer,
  orchestration: orchestration.reducer,
  intl: intl.reducer,
}

const rootPersistConfig = {
  key: 'root',
  storage,
  whitelist: [],
}

export const appReducer = persistReducer(rootPersistConfig, combineReducers(reducers))

const rootReducer = (state, action) => {
  if (action.type === security.actions.END) {
    state = {}
  }
  return appReducer(state, action)
}

export default rootReducer
