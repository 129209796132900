import API, { AuthAPI } from 'api/clients'
import { all, fork } from 'redux-saga/effects'

import RecipientsSaga from './RecipientsSaga'
import RecipientProfileSaga from './RecipientProfileSaga'
import { queryService } from 'modules/module-api'

export default function* recipientsSagas() {
  yield all([
    fork(new RecipientsSaga(API, queryService).supervise),
    fork(new RecipientProfileSaga(API, AuthAPI).supervise),
  ])
}
