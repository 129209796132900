import { IDeepfinityTheme } from 'modules/dna'
import * as system from 'styled-system'

export const ABSOLUTE_FILL: system.PositionProps<IDeepfinityTheme> = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
}

export const CENTER: system.FlexboxProps<IDeepfinityTheme> = {
  justifyContent: 'center',
  alignItems: 'center',
}
