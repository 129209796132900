import { UserType } from 'api'

export namespace permissions {
  export const canDeleteAllRecipients: UserType[] = [UserType.OWNER, UserType.DEBUG, UserType.ADMIN]
  export const canExportAllRecipients: UserType[] = [UserType.OWNER, UserType.DEBUG, UserType.ADMIN]
  export const canEditFeatureFlags: UserType[] = [UserType.OWNER, UserType.DEBUG, UserType.ADMIN]
  export const canManageTags: UserType[] = [UserType.OWNER, UserType.DEBUG, UserType.ADMIN]
  export const canManageLocations: UserType[] = [UserType.OWNER, UserType.DEBUG, UserType.ADMIN]
  export const canManageCouriers: UserType[] = [UserType.OWNER, UserType.DEBUG, UserType.ADMIN]
  export const canManageSizes: UserType[] = [UserType.OWNER, UserType.DEBUG, UserType.ADMIN]
  export const canManageDropoffLocations: UserType[] = [UserType.OWNER, UserType.DEBUG, UserType.ADMIN]
}

export default permissions
