import * as React from 'react'
import { useDispatch } from 'react-redux'

import { EuiButton } from '@elastic/eui'

import { CircleTickDark } from 'modules/dna'
import { Grid, H1, H2, Image, Page, useTranslate } from 'modules/web-atoms'
import orchestration from 'modules/module-orchestration'

const INTL_PREFIX = 'dashboard.onboarding.finish'

export interface FinishOnboardingScreenProps extends React.ComponentProps<typeof Grid> {}

export const FinishOnboardingScreen: React.FC<FinishOnboardingScreenProps> = (props) => {
  const t = useTranslate(INTL_PREFIX)
  const tCommon = useTranslate("commons")

  const dispatch = useDispatch()

  const handleFinish = React.useCallback(() => {
    dispatch(orchestration.actions.restore())
  }, [dispatch])

  return (
    <Page title={t('title')}>
      <Grid
        justifyContent="space-between"
        alignItems="center"
        marginTop="large"
        justifyItems="center"
        minHeight="60vh"
        flex={1}
        alignSelf="center"
        padding="medium"
        {...props}
      >
        <Grid justifyContent="center" alignItems="center" marginTop="xlarge" paddingX="small" flex={1}>
          <Grid.Row>
            <H1 textAlign="center">{t('heading')}</H1>
          </Grid.Row>
          <Grid.Row marginTop="smallest" marginBottom="xlarge">
            <H2 textAlign="center">{t('subheading')}</H2>
          </Grid.Row>
          <Image minWidth={200} width={300} src={CircleTickDark} />
        </Grid>
        <Grid.Row marginTop="xxlarge">
          <EuiButton onClick={handleFinish} fill>
            {tCommon('action.finish')}
          </EuiButton>
        </Grid.Row>
      </Grid>
    </Page>
  )
}

export default React.memo(FinishOnboardingScreen)
