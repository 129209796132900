import React from 'react'
import settings, { SettingsFormState } from 'modules/module-settings/settings'
import { useAppDispatch, useAppSelector } from 'modules/module-utils'
import { Grid, H1, H3, Paragraph, Switch, useTranslate } from 'modules/web-atoms'
import { FieldText, FormRow, IPanelProps, Panel } from 'modules/web-molecules'
import { FormikConfig, useFormik } from 'formik'
import * as Yup from 'yup'
import { EuiButton, EuiButtonEmpty, EuiSwitchEvent } from '@elastic/eui'

export interface IDropoffLocationFormValues {
  name: string
  enabled: boolean
  keepShowingForm?: boolean
}

const INITIAL_VALUES: IDropoffLocationFormValues = {
  name: '',
  enabled: true,
  keepShowingForm: false,
}

export type IDropoffLocationFormProps = IPanelProps

const DropoffLocationForm = ({ ...rest }) => {
  const t = useTranslate('settings.tabs.dropoff-locations.form')
  const tCommon = useTranslate('commons')
  const dispatch = useAppDispatch()
  const formState = useAppSelector(settings.selectors.dropoffLocationFormState)
  const selectedItem = useAppSelector(settings.selectors.selectedDropoffLocation)
  const isAdding = useAppSelector(settings.selectors.isAddingDropoffLocation)
  const isEditing = useAppSelector(settings.selectors.isEditingDropoffLocation)
  const isFormLoading = formState === SettingsFormState.CREATE ? isAdding : isEditing

  const onCancel = () => {
    dispatch(settings.actions.setDropoffLocationFormState(SettingsFormState.HIDDEN))
  }

  const onSubmit: FormikConfig<IDropoffLocationFormValues>['onSubmit'] = (values, { resetForm }) => {
    if (formState === SettingsFormState.CREATE) {
      dispatch(settings.actions.addDropoffLocation({ ...values }))
      resetForm()
    } else {
      dispatch(settings.actions.editDropoffLocation({ ...values }))
    }
  }

  const validationSchema = React.useMemo(
    () =>
      Yup.object<IDropoffLocationFormValues>().shape({
        name: Yup.string().trim().required(tCommon('form.required')),
      }),
    [tCommon],
  )
  const formik = useFormik<IDropoffLocationFormValues>({
    initialValues: INITIAL_VALUES,
    validationSchema,
    onSubmit,
  })
  const { values, touched, errors, setValues, setFieldValue, submitForm, handleChange, resetForm } = formik

  const onSaveAndAddAnother = () => {
    void setFieldValue('keepShowingForm', true)
    void submitForm()
  }

  const onEnabledChange = (e: EuiSwitchEvent) => {
    const { checked } = e.target
    void setFieldValue('enabled', checked)
  }

  React.useEffect(() => {
    if (selectedItem) {
      void setValues({
        ...INITIAL_VALUES,
        name: selectedItem.name,
        enabled: !!selectedItem.enabled,
      })
    } else {
      resetForm()
    }
  }, [selectedItem, setValues, resetForm])

  return (
    <Panel width={['100%', 300, 350, 450]} py="medium" px="large" {...rest}>
      <Grid height="100%" justifyContent="space-between">
        <Grid flexGrow={1} minHeight={0} gutterSize="medium" className="eui-yScroll">
          <Grid>
            {formState === SettingsFormState.CREATE ? (
              <Grid gutterSize="none">
                <H1 color="primaryMid" marginBottom={['none', 'smallest']}>
                  {t('heading')}
                </H1>

                <H3 mb="none">{t('subheading')}</H3>
              </Grid>
            ) : (
              <H1 color="primaryMid" marginBottom={['none', 'smallest']}>
                {selectedItem?.name}
              </H1>
            )}
          </Grid>

          <Grid justifyContent="flex-start" paddingX="none">
            <FormRow label={t('name')} touched={touched.name} error={errors.name}>
              <FieldText type="text" name="name" value={values.name} onChange={handleChange('name')} />
            </FormRow>

            <Switch
              label={tCommon('form.enabled')}
              checked={values.enabled}
              onChange={onEnabledChange}
              mt="small"
              ml="macro"
            />
          </Grid>
        </Grid>

        <Grid alignItems="center">
          {!selectedItem && (
            <EuiButtonEmpty color="primary" flush="both" isLoading={isFormLoading} onClick={onSaveAndAddAnother}>
              {t('save-another')}
            </EuiButtonEmpty>
          )}

          <Grid alignItems="center" justifyContent="center" gutterSize="medium">
            <Grid.Col>
              <EuiButtonEmpty color="primary" onClick={onCancel} isDisabled={isFormLoading}>
                <Paragraph color="textDark">{tCommon('action.cancel')}</Paragraph>
              </EuiButtonEmpty>
            </Grid.Col>
            <Grid.Col>
              <EuiButton color="primary" fill isLoading={isFormLoading} onClick={submitForm}>
                {tCommon('action.save')}
              </EuiButton>
            </Grid.Col>
          </Grid>
        </Grid>
      </Grid>
    </Panel>
  )
}

export default React.memo(DropoffLocationForm)
