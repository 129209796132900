enum SettingsTabPaths {
  DATA = 'data',
  FEATURES = 'features',
  TAGS = 'tags',
  LOCATIONS = 'locations',
  DROPOFF_LOCATIONS = 'dropoff-locations',
  COURIERS = 'couriers',
  SIZES = 'sizes',
}

export default SettingsTabPaths
