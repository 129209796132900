import { SagaIterator } from 'redux-saga'
import { call, CallEffect, delay, put, race, spawn, take } from 'redux-saga/effects'

import { v4 as uuidv4 } from 'uuid'

import { IShowBannerPayload } from '.'
import * as actions from './actions'
import * as matchers from './matchers'

type payload = Omit<IShowBannerPayload, 'id'>

/**
 * @example
 *  const bannerParams = {
    message: 'Hello this is a test message'
    actionMessage: 'Press me to call some a
    type: BannerType.WARNING,
    }
    yield banner(bannerParams, this.onAction) 
 */
export const banner = (params: payload, saga, ...args): CallEffect => call(banner._show, params, saga, args)

const hideAfterLifeTime = (lifetimeInMs: number, id: string) =>
  function* () {
    yield delay(lifetimeInMs)
    yield put(actions.bannerHide(id))
  }

const listenForAction = (id: string, saga: any) =>
  function* () {
    while (true) {
      const { action, hide } = yield race({
        action: take(actions.BANNER_ACTION),
        hide: take(matchers.hidden(id)),
      })
      if (action) yield call(saga)
      if (hide) return
    }
  }

banner._show = function* (params: payload, saga, ...args): SagaIterator {
  const id = uuidv4()
  const { autohide } = params
  yield put(actions.showBanner({ id, ...params }))

  if (autohide) yield spawn(hideAfterLifeTime(autohide, id))
  yield spawn(listenForAction(id, saga))
}
