import * as React from 'react'
import { useLocation } from 'react-router'

import queryString, { ParsedQuery } from 'query-string'

const useQueryParams = <Params extends { [K in keyof Params]?: string | null | Array<string | null> } = ParsedQuery>(): Params => {
  const location = useLocation()

  return React.useMemo(() => queryString.parse(location.search), [location]) as Params
}

export default useQueryParams
