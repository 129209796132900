import { all, fork } from 'redux-saga/effects'

import account from 'modules/module-account'
import analytics from 'modules/module-analytics'
import queryService from 'modules/module-api/query/QueryService'
import dashboard from 'modules/module-dashboard'
import deliveries from 'modules/module-deliveries'
import { developments } from 'modules/module-developments'
import navigation from 'modules/module-navigation'
import alerts from 'modules/module-alerts'
import notifications from 'modules/module-notifications'
import onboarding from 'modules/module-onboarding'
import recipientsUpload from 'modules/module-recipient-upload'
import recipientsModule from 'modules/module-recipients'
import reporting from 'modules/module-reporting'
import users from 'modules/module-users'
import settings from 'modules/module-settings'
import intl from 'modules/module-intl'
import AuthenticationOrchestrationSaga from './AuthenticationOrchestrationSaga'
import LaunchSaga from './LaunchSaga'
import support from 'modules/module-support'
import statistics from 'modules/module-statistics'
import billing from 'modules/module-billing'
import security from 'modules/module-security'
import orchestration from 'modules/module-orchestration'
import tokenService from 'modules/module-security/tokens/TokenService'

export default function* rootSaga() {
  yield all([
    fork(new LaunchSaga().supervise),
    fork(new AuthenticationOrchestrationSaga(tokenService).supervise),
    fork(security.sagas, queryService),
    fork(dashboard.sagas),
    fork(onboarding.sagas),
    fork(account.sagas),
    fork(navigation.sagas),
    fork(developments.sagas),
    fork(analytics.sagas),
    fork(reporting.sagas),
    fork(alerts.sagas),
    fork(notifications.sagas),
    fork(recipientsUpload.sagas),
    fork(recipientsModule.sagas),
    fork(users.sagas),
    fork(deliveries.sagas),
    fork(settings.sagas),
    fork(intl.sagas),
    fork(support.sagas),
    fork(statistics.sagas),
    fork(billing.sagas),
    fork(orchestration.sagas),
  ])
}
