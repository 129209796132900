export enum Border {
  None = 0,
  Small = 1,
  Medium = 3,
  Large = 5,
}

export type BorderName = 'none' | 'small' | 'medium' | 'large'

export type BorderSizes = Record<BorderName, Border>

export const borderSizes: BorderSizes = {
  none: Border.None,
  small: Border.Small,
  medium: Border.Medium,
  large: Border.Large,
}

export default borderSizes
