import * as React from 'react'
import styled from '@emotion/styled'
import { EuiText } from '@elastic/eui'

interface ILabelProps extends React.ComponentProps<typeof EuiText> {}

export const StyledLabel = styled.label`
  /* Text */
  font-size: 12px;
  span,
  button,
  a {
    font-weight: bold;
  }
`

export const Label: React.FC<ILabelProps> = (props) => {
  const { children, ...rest } = props

  return (
    <EuiText {...rest}>
      <StyledLabel>{children}</StyledLabel>
    </EuiText>
  )
}

Label.defaultProps = {
  size: undefined,
}

export default Label
