/* eslint-disable react/display-name */
import { UserType } from 'api'
import { SortDirection } from 'modules/module-api'
import security from 'modules/module-security'
import { IUsePaginatedTableParams, strings, useAppSelector, usePaginatedTable } from 'modules/module-utils'
import { Paragraph, useTranslate } from 'modules/web-atoms'
import {
  IColumns,
  IPagination,
  ITableItem,
  ITableSelectedChange,
  TableColumn,
  TableColumnType,
  TableSelectionAction,
} from 'modules/web-molecules'
import * as React from 'react'
import { useDispatch } from 'react-redux'
import permissions from '../../permissions/Permissions'
import user, { FormState } from '../../user'
import useUsers, { SortField } from '../use-users/use-users'

export type IUsersTableColumns = SortField

export type IUserTableItem = ITableItem<IUsersTableColumns> & {
  type: UserType
}

export const useUsersTable = () => {
  const dispatch = useDispatch()
  const t = useTranslate('users-directory.table')

  const selectedItem = useAppSelector(user.selectors.selectedUser)
  const selectedItems = useAppSelector(user.selectors.selectedUsers)
  const currentType = useAppSelector(security.selectors.type)
  const { canEditUsers } = useAppSelector(user.selectors.permissions)

  const onItemSelected = React.useCallback(
    (item: IUserTableItem) => {
      if (selectedItem == item.id) {
        dispatch(user.actions.selectedUser({ user: undefined }))
        dispatch(user.actions.setFormState(FormState.HIDDEN))
      } else {
        if (canEditUsers) {
          dispatch(user.actions.selectedUser({ user: { id: item.id, type: item.type } }))
          dispatch(user.actions.setFormState(FormState.EDITING))
        }
      }
    },
    [dispatch, selectedItem, canEditUsers],
  )

  const onSortChange = React.useCallback(() => {
    dispatch(user.actions.setSelectedUser({ user: undefined }))
  }, [dispatch])

  const onSelectionChange: ITableSelectedChange<IUsersTableColumns, IUserTableItem> = React.useCallback(
    (items: IUserTableItem[], action: TableSelectionAction) => {
      dispatch(
        user.actions.selectedUsers({
          items: items.map((item) => {
            return { id: item.id, type: item.type }
          }),
          action,
        }),
      )
    },
    [dispatch],
  )

  const paginationParams: IUsePaginatedTableParams<IUsersTableColumns, IUserTableItem, SortField, typeof SortField> =
    React.useMemo(() => {
      return {
        sortClass: SortField,
        pageParams: {
          page: 0,
          pageSize: 20,
          sortBy: SortField.NAME,
          sort: SortDirection.ASC,
          filter: '',
        },
        itemsPerPage: [10, 20, 50],
        onSortChange,
        onSelectionChange,
        onItemClicked: onItemSelected,
      }
    }, [onSelectionChange, onItemSelected, onSortChange])

  const { paging, paginationOptions, selection, sort, onSearch } = usePaginatedTable<
    IUsersTableColumns,
    IUserTableItem,
    SortField,
    typeof SortField
  >(paginationParams)

  const { users, isFetching, totalItems, error, refetch } = useUsers({
    page: paging.page + 1,
    sortBy: paging.sortBy,
    sort: paging.sort,
    pageSize: paging.pageSize,
    filter: paging.filter,
  })

  const onRefresh = () => {
    if (!isFetching) {
      void refetch()
    }
  }

  const pagination: IPagination = React.useMemo(() => {
    return {
      pageIndex: paging.page,
      pageSize: paging.pageSize,
      total: totalItems,
    }
  }, [paging.page, paging.pageSize, totalItems])

  const columns: IColumns<IUsersTableColumns> = React.useMemo(() => {
    return {
      [SortField.NAME]: {
        label: <TableColumn>{t('column.name')}</TableColumn>,
        type: TableColumnType.Column,
        isSortable: true,
      },
      [SortField.EMAIL]: {
        label: <TableColumn>{t('column.email')}</TableColumn>,
        type: TableColumnType.Column,
        isSortable: true,
      },
      [SortField.TYPE]: {
        label: <TableColumn>{t('column.type')}</TableColumn>,
        type: TableColumnType.Column,
        isSortable: true,
      },
    }
  }, [t])

  const items: IUserTableItem[] = React.useMemo(() => {
    return users
      ? users.map((user): IUserTableItem => {
          const id = user.id.toString()
          return {
            id: id,
            type: user.type,
            isSelectable: true,
            isChecked: !!selectedItems.find((itemId) => itemId === user.id),
            isSelected: !!selectedItem && id == selectedItem,
            isCheckable: !!currentType && permissions.canEditUser(currentType, user.type),
            [SortField.NAME]: {
              render: () => (
                <Paragraph fontWeight="bold" size="medium" padding="smallest" truncated={true}>
                  {strings.capitalize(user.firstName)} {strings.capitalize(user.lastName)}
                </Paragraph>
              ),
            },
            [SortField.EMAIL]: {
              render: () => (
                <Paragraph padding="smallest" truncated={true}>
                  {user.email}
                </Paragraph>
              ),
            },
            [SortField.TYPE]: {
              render: () => (
                <Paragraph padding="smallest" truncated={true}>
                  {t(`column.type.${user.type}`)}
                </Paragraph>
              ),
            },
          }
        })
      : []
  }, [users, selectedItem, selectedItems, currentType, t])

  return {
    columns,
    items,
    selection,
    onSearch,
    onItemClicked: onItemSelected,
    isFetching,
    error,
    pagination,
    sort,
    paginationOptions,
    onRefresh,
  }
}

export default useUsersTable
