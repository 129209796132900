import { EuiButton } from '@elastic/eui'
import orchestration from 'modules/module-orchestration/orchestration'
import { useActiveBuildings, useAppSelector, useExportRecipients } from 'modules/module-utils'
import { Grid, H3, Paragraph, Span, useToggle, useTranslate } from 'modules/web-atoms'
import React from 'react'
import { Trans } from 'react-i18next'
import SettingsTabTemplate, {
  ISettingsTabTemplateProps,
} from '../../../components/SettingsTabTemplate/SettingsTabTemplate'
import settings from '../../../settings'
import DeleteAllModal from '../DeleteAllModal/DeleteAllModal'

const INTL_PREFIX = 'settings.tabs.data'

interface IDataTabProps extends Partial<ISettingsTabTemplateProps> {}

const DataTab: React.FC<IDataTabProps> = ({ ...rest }) => {
  const t = useTranslate(INTL_PREFIX)

  const [showDeleteAllModal, toggleDeleteModal] = useToggle(false)
  const { activeBuildings, isFetching } = useActiveBuildings()
  const { file, fetchCSVFile, isExporting, error } = useExportRecipients()

  const permissions = useAppSelector(settings.selectors.permissions)
  const isDeletingAllRecipients = useAppSelector(orchestration.selectors.isWipingBuilding)

  const onExportRecipients = async () => {
    await fetchCSVFile()
  }

  return (
    <SettingsTabTemplate heading={t('heading')} description={t('description')} {...rest}>
      <SettingsTabTemplate.Section maxWidth={320}>
        <Grid gutterSize="large">
          <H3 color="textDark" fontWeight="medium">
            {t('recipients.heading')}
          </H3>

          <Grid>
            <Grid.Row>
              <EuiButton
                fill
                color="primary"
                isLoading={isExporting}
                onClick={onExportRecipients}
                disabled={!permissions.canExportAllRecipients}
              >
                {t('recipients.export-all')}
              </EuiButton>
            </Grid.Row>

            <Paragraph>{t('recipients.export-all.about')}</Paragraph>
          </Grid>

          <Grid>
            <Grid.Row>
              <EuiButton
                fill
                color="danger"
                isLoading={isDeletingAllRecipients}
                onClick={toggleDeleteModal}
                disabled={!permissions.canDeleteAllRecipients}
              >
                <i className="fas fa-trash-alt" />
                <Paragraph ml="smallest" color="textLight" display="inline" fontWeight="medium">
                  {t('recipients.delete-all')}
                </Paragraph>
              </EuiButton>
            </Grid.Row>

            <Paragraph>
              <Trans
                i18nKey={`${INTL_PREFIX}.recipients.delete-all.about`}
                components={{ span: <Span fontWeight="bold" color="dangerDestructive" /> }}
              />
            </Paragraph>
          </Grid>

          {showDeleteAllModal && <DeleteAllModal activeBuildings={activeBuildings} onClose={toggleDeleteModal} />}
        </Grid>
      </SettingsTabTemplate.Section>
    </SettingsTabTemplate>
  )
}

export default React.memo(DataTab)
