import { handleActions } from 'modules/utils/redux'

import { HIDE, SHOW, BANNER_HIDE, STORE_BANNER } from './actions'
import { initialState, IAlertState, IShowBannerPayload, IToast } from './model'

export const reducer = handleActions<IAlertState>(
  {
    [SHOW]: (state: IAlertState, { payload }: { payload: IToast }) => {
      if (state.toasts.length == 3) state.toasts.shift()
      state.toasts.push(payload)
    },
    [HIDE]: (state: IAlertState, { payload }: { payload: string }) => {
      state.toasts = state.toasts.filter((toast) => toast.id !== payload)
    },
    [BANNER_HIDE]: (state: IAlertState) => {
      state.banner = undefined
    },
    [STORE_BANNER]: (state: IAlertState, { payload }: { payload: IShowBannerPayload }) => {
      state.banner = payload
    },
  },
  initialState,
)

export default reducer
