import { IClientDtoClass, ISetPasswordDto, IUserDtoClass } from 'api'
import autobind from 'autobind-decorator'
import { AxiosInstance } from 'axios'
import { instanceToPlain } from 'class-transformer'
import http from 'modules/http'
import { alerts } from 'modules/module-alerts'
import { translate } from 'modules/module-intl'
import orchestration from 'modules/module-orchestration'
import { reporting } from 'modules/module-reporting'
import security from 'modules/module-security'
import { SupervisorSuite } from 'modules/redux-supervisor'
import { SagaIterator } from 'redux-saga'
import { all, call, put, select, takeEvery, takeLatest, takeLeading } from 'redux-saga/effects'
import sha512 from 'sha512'
import user from '../user'

const INTL_PREFIX = 'account.profile'

const { updateAccount, updateCompanyInfo, updatePassword, error } = user.actions

export class UserSaga extends SupervisorSuite {
  private readonly apiService: AxiosInstance

  constructor(apiService: AxiosInstance) {
    super()
    this.apiService = apiService
  }

  @autobind
  *start(): SagaIterator {
    yield all([
      takeLatest(orchestration.actions.DETERMINE_USER_PERMISSIONS, this.determineUserPermissions),
      takeLeading(user.actions.UPDATE_ACCOUNT, this.updateAccount),
      takeLeading(user.actions.UPDATE_COMPANY_INFO, this.updateCompanyInfo),
      takeLeading(user.actions.UPDATE_PASSWORD, this.updatePassword),
      takeEvery(user.actions.ERROR, this.onError),
    ])
  }

  @autobind
  *determineUserPermissions(): SagaIterator {
    const userType = yield select(security.selectors.type)
    yield put(user.actions.setPermissions({ userType }))
  }

  @autobind
  *updateAccount({ payload }: ReturnType<typeof updateAccount>): SagaIterator {
    yield put(user.actions.setLoading(true))
    const { firstName, lastName, developmentId, email, id, role, userType } = payload
    const data = new IUserDtoClass()
    data.firstName = firstName.trim()
    data.lastName = lastName.trim()
    data.developmentId = developmentId
    data.email = email.trim().toLowerCase()
    data.id = id
    data.jobTitle = role.trim()
    data.type = userType
    data.recordModified = new Date().toISOString()

    try {
      yield call(this.apiService, `/v3/users/${id}`, {
        method: 'PUT',
        data: instanceToPlain(data),
      })
      const userRole = yield select(security.selectors.role)
      yield put(security.actions.pullCurrentUser({ role: userRole }))
      yield put(alerts.actions.success({ message: yield translate(`${INTL_PREFIX}.update-success`) }))
    } catch (err) {
      yield put(reporting.actions.error(err))
      yield put(user.actions.error(yield translate(`${INTL_PREFIX}.default-error`)))
    } finally {
      yield put(user.actions.setLoading(false))
    }
  }

  @autobind
  *updateCompanyInfo({ payload }: ReturnType<typeof updateCompanyInfo>): SagaIterator {
    yield put(user.actions.setLoading(true))
    const { id, companyName, addressLine1, addressLine2, postcode, city, country } = payload
    const data = new IClientDtoClass()
    data.id = id
    data.name = companyName.trim()
    data.addressLine1 = addressLine1.trim()
    data.addressLine2 = addressLine2?.trim()
    data.postcode = postcode.trim()
    data.city = city.trim()
    data.country = country.trim()
    data.recordModified = new Date().toISOString()

    try {
      yield call(this.apiService, `/v3/clients/${id}`, {
        method: 'PUT',
        data: instanceToPlain(data),
      })
      yield put(alerts.actions.success({ message: yield translate(`${INTL_PREFIX}.update-success`) }))
    } catch (err) {
      yield put(reporting.actions.error(err))
      yield put(user.actions.error(yield translate(`${INTL_PREFIX}.default-error`)))
    } finally {
      yield put(user.actions.setLoading(false))
    }
  }

  @autobind
  *updatePassword({ payload }: ReturnType<typeof updatePassword>): SagaIterator {
    yield put(user.actions.setLoading(true))
    const { userId, newPassword, currentPassword } = payload
    const data = new ISetPasswordDto()
    data.currentPassword = sha512(currentPassword).toString('hex')
    data.newPassword = sha512(newPassword).toString('hex')

    try {
      yield call(this.apiService, `/v3/accounts/${userId}/change_password`, {
        method: 'PATCH',
        data: instanceToPlain(data),
      })
      yield put(alerts.actions.success({ message: yield translate(`${INTL_PREFIX}.update-success`) }))
    } catch (err) {
      yield put(reporting.actions.error(err))

      if (http.guards.isError(err) && err.response?.status === 400) {
        yield put(user.actions.error(yield translate(`${INTL_PREFIX}.invalid-current-password`)))
      } else {
        yield put(user.actions.error(yield translate(`${INTL_PREFIX}.default-error`)))
      }
    } finally {
      yield put(user.actions.setLoading(false))
    }
  }

  @autobind
  *onError({ payload }: ReturnType<typeof error>): SagaIterator {
    if (payload) yield put(alerts.actions.error({ message: payload }))
  }
}

export default UserSaga
