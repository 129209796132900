import { all, fork } from 'redux-saga/effects'

import DevelopmentSaga from './DevelopmentSaga'
import API from 'api/clients'
import { queryService } from 'modules/module-api'
import tokenService from 'modules/module-security/tokens/TokenService'

export default function* developmentsSagas() {
  yield all([fork(new DevelopmentSaga(API, tokenService, queryService).supervise)])
}
