import { createReducer } from '@reduxjs/toolkit'
import { Role } from 'modules/module-security'
import { TableSelectionAction } from 'modules/web-molecules'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import permissions from '../permissions/Permissions'
import {
  error,
  setActiveBuildings,
  setCurrentParcel,
  setInboundColumnSelection,
  setLoading,
  setLoadingNewDelivery,
  setOutboundColumnSelection,
  setParcelLabelModal,
  setPermissions,
  setSelectedInboundParcels,
  setSelectedOutboundParcels,
  setSelectedTags,
  setShowMarkAsCollectedModal,
} from './actions'
import { initialState } from './models'

const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(setActiveBuildings, (state, { payload }) => {
      state.activeBuildings = payload
    })
    .addCase(setLoading, (state, { payload }) => {
      state.loading = payload
    })
    .addCase(error, (state, { payload }) => {
      state.error = payload
    })
    .addCase(setLoadingNewDelivery, (state, { payload }) => {
      state.loadingNewDelivery = payload
    })
    .addCase(setCurrentParcel, (state, { payload }) => {
      state.currentParcel = payload
    })
    .addCase(setShowMarkAsCollectedModal, (state, { payload }) => {
      state.showMarkAsCollectedModal = payload
    })
    .addCase(
      setPermissions,
      (state, { payload: { userType, userRole, isOutboundEnabled, isRequestorFormEnabled } }) => {
        const isRecipient = userRole === Role.RECIPIENT

        state.permissions.canViewParcels = isRecipient || permissions.canViewParcels.includes(userType)
        state.permissions.canViewCollectedParcels =
          isRecipient || permissions.canViewCollectedParcels.includes(userType)
        state.permissions.canViewOutbound = isRecipient
          ? !!isOutboundEnabled
          : permissions.canViewParcels.includes(userType) && !!isOutboundEnabled
        state.permissions.canEditParcels = !isRecipient && permissions.canEditParcels.includes(userType)
        state.permissions.canCreateParcels = permissions.canCreateParcels.includes(userType)
        state.permissions.canFilterParcelTypes = !isRecipient && !!isRequestorFormEnabled
        state.permissions.canCreateRequestorParcels = isRecipient && !!isRequestorFormEnabled
        state.permissions.canCollectParcels = !isRecipient && permissions.canCollectParcels.includes(userType)
        state.permissions.canExportParcels = !isRecipient && permissions.canExportParcels.includes(userType)
      },
    )
    .addCase(setSelectedInboundParcels, (state, { payload }) => {
      switch (payload.action) {
        case TableSelectionAction.DESELECTED_PAGE:
          state.selectedInboundParcels = state.selectedInboundParcels.filter(
            (currentSelection) => !payload.items.includes(currentSelection),
          )
          break
        case TableSelectionAction.DESELECTED_ALL:
          state.selectedInboundParcels = []
          break
        case TableSelectionAction.SELECTED:
          {
            const newList = [...state.selectedInboundParcels, ...payload.items]
            state.selectedInboundParcels = newList.filter((element, index) => {
              return newList.indexOf(element) === index
            })
          }
          break
        case TableSelectionAction.DESELECTED:
          state.selectedInboundParcels = state.selectedInboundParcels.filter(
            (currentSelection) => currentSelection != payload.items[0],
          )
          break
      }
    })
    .addCase(setSelectedOutboundParcels, (state, { payload }) => {
      //state.selectedOutboundParcels = payload
    })
    .addCase(setInboundColumnSelection, (state, { payload }) => {
      state.persistent.inboundColumnSelectionIds = payload
    })
    .addCase(setOutboundColumnSelection, (state, { payload }) => {
      state.persistent.outboundColumnSelectionIds = payload
    })
    .addCase(setSelectedTags, (state, { payload }) => {
      state.persistent.selectedTags = payload
    })
    .addCase(setParcelLabelModal, (state, { payload }) => {
      state.parcelLabelModal.isOpen = payload.isOpen
      state.parcelLabelModal.parcelId = payload.parcelId
    }),
)

const persistConfig = {
  key: 'parcels',
  storage,
  whitelist: ['persistent'],
}

export const persistedReducer = persistReducer(persistConfig, reducer)

export default persistedReducer
