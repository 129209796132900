import { useAppSelector } from 'modules/module-utils'
import { useTranslate } from 'modules/web-atoms'
import React from 'react'
import SettingsTableFormTemplate, {
  ISettingsTableFormTemplateProps,
} from '../../../components/SettingsTableFormTemplate/SettingsTableFormTemplate'
import settings from '../../../settings'
import SizesForm from './SizeForm'
import SizeTable from './SizesTable'

export interface ISizesTabProps extends Partial<ISettingsTableFormTemplateProps> {}

const SizesTab: React.FC<ISizesTabProps> = ({ ...rest }) => {
  const t = useTranslate('settings.tabs.sizes')
  const isFormVisible = useAppSelector(settings.selectors.isSizeFormVisible)
  const { canManageSizes } = useAppSelector(settings.selectors.permissions)

  return (
    <SettingsTableFormTemplate
      heading={t('heading')}
      description={t('description')}
      isFormVisible={isFormVisible}
      hasManagePermissions={canManageSizes}
      {...rest}
    >
      <SettingsTableFormTemplate.Table>
        <SizeTable />
      </SettingsTableFormTemplate.Table>

      <SettingsTableFormTemplate.Form>
        <SizesForm />
      </SettingsTableFormTemplate.Form>
    </SettingsTableFormTemplate>
  )
}

export default React.memo(SizesTab)
