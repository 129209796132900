import React, { useState } from 'react'
import { Trans } from 'react-i18next'

import {
  EuiButton,
  EuiButtonEmpty,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
} from '@elastic/eui'

import { Error as DangerIcon } from 'modules/dna'
import { isNumeric } from 'modules/utils/Utils'
import { Grid, H1, H2, Image, IModalProps, Modal, Paragraph, Span, useTranslate } from 'modules/web-atoms'
import FieldText from '../FieldText/FieldText'
import FormRow from '../FormRow/FormRow'

interface IDeleteModalProps extends Omit<IModalProps, 'onCancel' | 'children'> {
  itemSize: number
  intlPrefix: string
  onDelete: () => void
  onClose: () => void
}

const DeleteConfirmationModal: React.FC<IDeleteModalProps> = (props) => {
  const { itemSize, intlPrefix, onClose, onDelete, ...rest } = props

  const [error, setError] = useState<string | undefined>(undefined)
  const [confirmedSize, setConfirmedSize] = useState<number | undefined>()

  const t = useTranslate(intlPrefix)
  const tCommon = useTranslate('commons')

  const onDeleteConfirm = React.useCallback(() => {
    if (!confirmedSize) {
      setError(t('error'))
      return
    }
    if (!error) onDelete()
  }, [error, onDelete, confirmedSize, t])

  const onConfirmationFieldChange = React.useCallback(
    (e: React.ChangeEvent<any>) => {
      const { value: rawValue } = e.target
      const value = isNumeric(rawValue) ? parseInt(rawValue) : undefined

      setConfirmedSize(value)
      if (value === itemSize) setError(undefined)
      else setError(t('error'))
    },
    [itemSize, t],
  )

  return (
    <Modal title={t('title')} width={650} borderRadius={['none', 'large']} hasClose={true} onClose={onClose} {...rest}>
      <EuiModalHeader>
        <Grid flexGrow={1} alignItems="center">
          <Grid.Row>
            <EuiModalHeaderTitle>
              <H1 fontSize="xxxlarge" textAlign="center">
                {t('title')}
              </H1>
            </EuiModalHeaderTitle>
          </Grid.Row>

          <Grid.Row>
            <H2 fontSize="large" color="textDark" textAlign="center">
              <Trans
                i18nKey={`${intlPrefix}.deleting`}
                values={{ selected: itemSize }}
                count={itemSize}
                components={{ span: <Span color="dangerDestructive" /> }}
              />
            </H2>
          </Grid.Row>
        </Grid>
      </EuiModalHeader>

      <EuiModalBody>
        <Grid flexGrow={1} alignItems="center">
          <Grid.Row>
            <Image src={DangerIcon} />
          </Grid.Row>

          <Grid.Row>
            <FormRow
              width="270px"
              label={
                <Paragraph size="medium" fontWeight="bold">
                  {t('num-to-delete')}
                </Paragraph>
              }
              marginTop="small"
              touched={!!error}
              inputMode="numeric"
              error={error}
            >
              <FieldText
                name="numToDelete"
                placeholder={`${itemSize}`}
                value={confirmedSize ?? ''}
                onChange={onConfirmationFieldChange}
              />
            </FormRow>
          </Grid.Row>
        </Grid>
      </EuiModalBody>

      <EuiModalFooter>
        <Grid mt={['none', 'large']} flexGrow={1} justifyContent="center" gutterSize="medium">
          <Grid.Col>
            <EuiButtonEmpty onClick={onClose}>{tCommon('action.cancel')}</EuiButtonEmpty>
          </Grid.Col>

          <Grid.Col>
            <EuiButton type="submit" form="modalFormId" onClick={onDeleteConfirm} fill color="danger">
              {tCommon('action.confirm')}
            </EuiButton>
          </Grid.Col>
        </Grid>
      </EuiModalFooter>
    </Modal>
  )
}

export default React.memo(DeleteConfirmationModal)
