import { Transforms } from 'slate'
import { CustomText, TagElement } from 'types/slate-types'

export const isCustomText = (customText: unknown): customText is CustomText =>
  !!(customText as CustomText)?.bold ||
  !!(customText as CustomText)?.italic ||
  !!(customText as CustomText)?.code ||
  !!(customText as CustomText)?.underline ||
  !!(customText as CustomText)?.isInsideTag ||
  !!(customText as CustomText)?.text

export const htmlToPlainText = (html: string): string => {
  return new DOMParser().parseFromString(html, 'text/html').body.textContent ?? ''
}

export const isTag = (tag: unknown): tag is TagElement => (tag as TagElement)?.type == 'tag'

export const insertTag = (
  editor,
  tagId: string,
  character: string,
  props: Pick<TagElement, 'backgroundColor' | 'textColor'>,
) => {
  const tag: TagElement = {
    type: 'tag',
    tagId,
    character,
    children: [{ text: character, isInsideTag: true }],
    ...props,
  }

  Transforms.insertNodes(editor, tag)
  Transforms.move(editor)
}

export const withErrorHandler = (editor: any) => {
  return new Proxy(editor, {
    get(target, prop, receiver) {
      const value = Reflect.get(target, prop, receiver)

      if (typeof value === 'function') {
        return new Proxy(value, {
          apply: (method, thisArg, args) => {
            try {
              return method.apply(thisArg, args)
            } catch (error) {
              console.error(error)
            }
          },
        })
      }

      return value
    },
  })
}
