import * as React from 'react'

export const useToggle = (initial = false) => {
  const [isToggled, setToggle] = React.useState<boolean>(initial)

  // casted to any to work with eui ChangeEventHandler
  const toggle = React.useCallback(
    (override: boolean | undefined = undefined) => {
      if (typeof override == 'boolean') {
        setToggle(override)
      } else {
        setToggle((isToggled) => !isToggled)
      }
    },
    [setToggle],
  ) as any

  return [isToggled, toggle] as [boolean, any]
}

export default useToggle
