import { EuiLoadingSpinner } from '@elastic/eui'
import styled from '@emotion/styled'
import shouldForwardProp from '@styled-system/should-forward-prop'
import React from 'react'
import * as system from 'styled-system'
import { StyledViewProps } from '../Grid/StyledView'

export type ISpinnerProps = StyledViewProps & React.ComponentProps<typeof EuiLoadingSpinner>

export const StyledSpinner = styled(EuiLoadingSpinner, { shouldForwardProp })<ISpinnerProps>(
  system.color,
  system.flexbox,
  system.space,
  system.layout,
  system.position,
  system.justifyContent,
  system.alignItems,
  system.borders,
  system.shadow,
  system.background,
)

export const Spinner: React.FC<ISpinnerProps> = (props) => {
  const { children, ...rest } = props
  return <StyledSpinner {...rest}>{children}</StyledSpinner>
}

export default React.memo(Spinner)
