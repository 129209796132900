import { useClientBuildings } from 'modules/module-utils'
import { useTranslate } from 'modules/web-atoms'
import { IRadioSelectorItem, RadioSelector } from 'modules/web-organisms'
import * as React from 'react'

export type ISiteSelectorProps = Omit<React.ComponentProps<typeof RadioSelector>, 'radios' | 'label'>

export const SiteSelector: React.FC<ISiteSelectorProps> = ({ ...rest }) => {
  const t = useTranslate('recipients-upload.select-site')

  const { buildings, isFetching, error } = useClientBuildings()

  const radios: IRadioSelectorItem[] = React.useMemo(
    () =>
      buildings?.map((building) => ({
        id: building.id.toString(),
        label: building.buildingName,
      })) ?? [],
    [buildings],
  )

  const label: string = React.useMemo(() => {
    return radios.find((radio) => radio.id == rest.selectedRadio)?.label ?? t('label')
  }, [radios, rest.selectedRadio, t])

  return (
    <RadioSelector
      buttonProps={{ iconType: 'arrowDown', iconSide: 'right' }}
      labelProps={{ maxWidth: [120, 200], truncated: true, minWidth: [100, 150], textAlign: 'left' }}
      radios={radios}
      label={label}
      {...rest}
    />
  )
}

export default React.memo(SiteSelector)
