import { SortDirection } from 'modules/module-api'
import security from 'modules/module-security'
import {
  IUsePaginatedTableParams,
  strings,
  useAppDispatch,
  useAppSelector,
  usePaginatedTable,
} from 'modules/module-utils'
import { Paragraph, useTranslate } from 'modules/web-atoms'
import {
  IColumns,
  IPagination,
  ITableItem,
  ITableSelectedChange,
  TableColumn,
  TableColumnType,
  TableSelectionAction,
  ToolTip,
} from 'modules/web-molecules'
import moment from 'moment'
import { useCallback, useMemo } from 'react'
import recipients, { FormState } from '../../recipients'
import useRecipients, { RecipientSortField } from '../use-recipients/use-recipients'

type BaseColumnType = Exclude<RecipientSortField, RecipientSortField.BUILDING>
type ExtendedColumnType = RecipientSortField
type IRecipientsTableItem = ITableItem<ExtendedColumnType>

function useRecipientsTable() {
  const dispatch = useAppDispatch()
  const t = useTranslate('recipients-directory.table')

  const selectedItems = useAppSelector(recipients.selectors.selectedItems)
  const selectedItem = useAppSelector(recipients.selectors.selectedRecipient)
  const activeBuildings = useAppSelector(recipients.selectors.activeBuildings)
  const { canEditRecipient } = useAppSelector(recipients.selectors.permissions)
  const featureFlags = useAppSelector(security.selectors.featureFlags)

  const columns: IColumns<BaseColumnType> = useMemo(() => {
    let cols: any = {
      [RecipientSortField.NAME]: {
        label: <TableColumn>{t(`column.${RecipientSortField.NAME}`)}</TableColumn>,
        type: TableColumnType.Column,
        isSortable: true,
      },
      [RecipientSortField.ALIAS]: {
        label: <TableColumn>{t(`column.${RecipientSortField.ALIAS}`)}</TableColumn>,
        type: TableColumnType.Column,
        isSortable: false,
      },
      [RecipientSortField.EMAIL]: {
        label: <TableColumn>{t(`column.${RecipientSortField.EMAIL}`)}</TableColumn>,
        type: TableColumnType.Column,
        isSortable: true,
      },
      [RecipientSortField.LOCATION]: {
        label: <TableColumn>{t(`column.${RecipientSortField.LOCATION}`)}</TableColumn>,
        type: TableColumnType.Column,
        isSortable: true,
      },
      [RecipientSortField.TYPE]: {
        label: <TableColumn>{t(`column.${RecipientSortField.TYPE}`)}</TableColumn>,
        type: TableColumnType.Column,
        isSortable: false,
      },
    }
    if (activeBuildings.length > 1) {
      cols[RecipientSortField.BUILDING] = {
        label: <TableColumn>{t(`column.${RecipientSortField.BUILDING}`)}</TableColumn>,
        type: TableColumnType.Column,
        isSortable: true,
      }
    }
    if (featureFlags?.dropoffLocationsEnabled) {
      cols[RecipientSortField.DROPOFF_LOCATION_NAME] = {
        label: <TableColumn>{t(`column.${RecipientSortField.DROPOFF_LOCATION_NAME}`)}</TableColumn>,
        type: TableColumnType.Column,
        isSortable: true,
      }
    }

    cols = {
      ...cols,
      [RecipientSortField.LAST_MODIFIED]: {
        label: <TableColumn>{t(`column.${RecipientSortField.LAST_MODIFIED}`)}</TableColumn>,
        type: TableColumnType.Column,
        isSortable: true,
      },
    }

    return cols
  }, [activeBuildings.length, featureFlags?.dropoffLocationsEnabled, t])

  const onItemClicked = useCallback(
    (item: ITableItem<ExtendedColumnType>) => {
      if (canEditRecipient) {
        dispatch(recipients.actions.setFormState(FormState.EDITING))
        dispatch(recipients.actions.setSelectedRecipient(item.id))
      }
    },
    [dispatch, canEditRecipient],
  )

  const onSelectionChange: ITableSelectedChange<ExtendedColumnType, IRecipientsTableItem> = useCallback(
    (items: IRecipientsTableItem[], action: TableSelectionAction) => {
      dispatch(
        recipients.actions.setSelectedItems({
          items: items.map((i) => i.id),
          action,
        }),
      )
    },
    [dispatch],
  )

  const paginationParams: IUsePaginatedTableParams<
    ExtendedColumnType,
    IRecipientsTableItem,
    RecipientSortField,
    typeof RecipientSortField
  > = useMemo(() => {
    return {
      sortClass: RecipientSortField,
      pageParams: {
        page: 0,
        pageSize: 50,
        sortBy: RecipientSortField.NAME,
        sort: SortDirection.ASC,
        filter: '',
      },
      itemsPerPage: [50, 100, 200],
      onSelectionChange,
    }
  }, [onSelectionChange])
  const { paging, paginationOptions, selection, sort: sorting, onSearch } = usePaginatedTable(paginationParams)

  const { recipientsList, isFetchingTenants, totalItems, error, refetch } = useRecipients({
    buildingIds: activeBuildings.map((b) => b.id),
    page: paging.page + 1,
    pageSize: paging.pageSize,
    sortBy: paging.sortBy,
    sort: paging.sort,
    filter: paging.filter,
  })

  const onRefresh = () => {
    if (!isFetchingTenants) {
      void refetch()
    }
  }

  const pagination: IPagination = useMemo(
    () => ({
      pageIndex: paging.page,
      pageSize: paging.pageSize,
      total: totalItems,
    }),
    [paging.page, paging.pageSize, totalItems],
  )

  const items: Array<ITableItem<ExtendedColumnType>> = useMemo(
    () =>
      recipientsList.map((item): ITableItem<ExtendedColumnType> => {
        const { name, alias, location, email, sites, id, type, lastModified, dropoffLocationName } = item

        return {
          id,
          isCheckable: true,
          isChecked: !!selectedItems.find((itemId) => itemId === id),
          isSelectable: true,
          isSelected: !!selectedItem && id === selectedItem,
          [RecipientSortField.NAME]: {
            height: 72,
            render: () => (
              <Paragraph title={name} padding="smallest" truncated>
                {strings.capitalize(name)}
              </Paragraph>
            ),
            truncateText: true,
          },
          [RecipientSortField.ALIAS]: {
            height: 72,
            render: () => (
              <Paragraph title={alias} padding="smallest" truncated>
                {strings.capitalize(alias)}
              </Paragraph>
            ),
            truncateText: true,
          },
          [RecipientSortField.EMAIL]: {
            render: () => (
              <Paragraph title={email} truncated padding="smallest">
                {email}
              </Paragraph>
            ),
            truncateText: true,
          },
          [RecipientSortField.LOCATION]: {
            render: () => (
              <Paragraph title={location} truncated padding="smallest">
                {location}
              </Paragraph>
            ),
            truncateText: true,
          },
          [RecipientSortField.DROPOFF_LOCATION_NAME]: {
            render: () => (
              <Paragraph title={location} truncated padding="smallest">
                {dropoffLocationName}
              </Paragraph>
            ),
            truncateText: true,
          },
          [RecipientSortField.TYPE]: {
            render: () => (
              <Paragraph title={type} truncated padding="smallest">
                {t(`column.value.${type?.toLowerCase()}`)}
              </Paragraph>
            ),
            truncateText: true,
          },
          [RecipientSortField.BUILDING]: {
            render: () => {
              const siteNames = sites.join(', ')
              const text = (
                <Paragraph title={siteNames} truncated padding="smallest">
                  {siteNames}
                </Paragraph>
              )
              return sites.length > 1 ? (
                <ToolTip position="top" delay="regular" content={siteNames}>
                  {text}
                </ToolTip>
              ) : (
                text
              )
            },
            truncateText: true,
          },
          [RecipientSortField.LAST_MODIFIED]: {
            render: () => {
              const content = moment(lastModified).format('DD MMM YYYY HH:mm')
              return (
                <Paragraph title={content} truncated padding="smallest">
                  {content}
                </Paragraph>
              )
            },
            truncateText: true,
          },
        }
      }),
    [recipientsList, selectedItems, selectedItem, t],
  )

  return {
    columns,
    items,
    pagination,
    paginationOptions,
    sorting,
    selection,
    onItemClicked,
    isFetchingTenants,
    totalItems,
    onSearch,
    onRefresh,
    error,
  }
}

export default useRecipientsTable
