import { combineReducers } from 'redux'

import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import profile from './profile'
import recipients from './recipients'
import upload from './upload'

const reducers = {
  upload: upload.reducer,
  recipients: recipients.reducer,
  profile: profile.reducer,
}

const persistConfig = {
  key: 'recipients',
  storage,
  whitelist: [],
}

const rootReducer = combineReducers(reducers)

export const persistedReducer = persistReducer(persistConfig, rootReducer)

export default persistedReducer
