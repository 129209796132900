import billing from './billing'
import reducer from './root-reducer'
import billingSagas from './sagas'

export const billingModule = {
  actions: {
    ...billing.actions,
  },
  selectors: {
    ...billing.selectors,
  },
  sagas: billingSagas,
  reducer,
}

export default billingModule
