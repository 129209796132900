import * as React from 'react'
import { Trans } from 'react-i18next'

import { EuiLink } from '@elastic/eui'

import { LabelAustralia, LabelInternational } from 'modules/dna'
import security from 'modules/module-security'
import { useAppSelector } from 'modules/module-utils'
import { Grid, H1, H3, Image, useTranslate } from 'modules/web-atoms'

export interface IScanParcelStepProps {}

const INTL_PREFIX = 'onboarding.signup.onboarding.scan-parcel-step'
const TUTORIAL_LINK = 'https://youtu.be/b9UtOpw0uQ4?t=53'

export const ScanParcelStep: React.FC<IScanParcelStepProps> = (props) => {
  const country = useAppSelector(security.selectors.countryCode)
  const [parcel, setParcel] = React.useState<string>(LabelInternational)
  const t = useTranslate(INTL_PREFIX)

  React.useEffect(() => {
    if (country && country === 'AU') {
      setParcel(LabelAustralia)
    }
  }, [country])

  React.useCallback(() => {}, [])

  return (
    <Grid {...props} className="modal-scan-parcel-step">
      <Grid.Col width={['100%', 800]} alignItems="center">
        <H1 marginX={['xlarge', 'smallest']} textAlign="center">
          {t('heading')}
        </H1>
        <Grid marginTop="medium" marginX={['xlarge', 'none']}>
          <Grid.Col alignItems="center">
            <Grid
              padding={['small', 'medium']}
              width={['100%', 400]}
              alignItems="center"
              borderRadius="large"
              backgroundColor="secondaryMid"
            >
              <Image src={parcel} />
            </Grid>
            <H3 marginTop={['small', 'medium']} size="large" textAlign={['start', 'center']}>
              <Trans
                i18nKey={`${INTL_PREFIX}.struggling`}
                components={{
                  linkage: <EuiLink color="subdued" href={TUTORIAL_LINK} target="_blank" rel="noopener noreferrer" />,
                }}
              />
            </H3>
          </Grid.Col>
        </Grid>
      </Grid.Col>
    </Grid>
  )
}

export default React.memo(ScanParcelStep)
