import { EuiLoadingSpinner, EuiSwitchEvent } from '@elastic/eui'
import { Grid, IGridProps, Paragraph, Switch } from 'modules/web-atoms'
import * as React from 'react'

export interface ISettingsToggleProps extends IGridProps {
  isChecked: boolean
  label: string
  description: string
  isLoading: boolean
  onChange: (event: EuiSwitchEvent) => void
  isDisabled: boolean
}

export const SettingsToggle = ({
  isChecked,
  label,
  description,
  onChange,
  isLoading,
  isDisabled,
  ...rest
}: ISettingsToggleProps) => {
  return (
    <Grid gutterSize="smallest" {...rest}>
      <Grid alignItems="center">
        <Grid.Col>
          <Switch
            aria-label={label}
            label={label}
            onChange={onChange}
            checked={isChecked}
            disabled={isDisabled || isLoading}
          />
        </Grid.Col>

        {isLoading && (
          <Grid.Col>
            <EuiLoadingSpinner size="m" />
          </Grid.Col>
        )}
      </Grid>
      <Paragraph>{description}</Paragraph>
    </Grid>
  )
}

SettingsToggle.defaultProps = {
  isDisabled: false,
}

export default React.memo(SettingsToggle)
