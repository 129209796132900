import { IParcelRowItem } from 'api'
import { plainToInstance } from 'class-transformer'
import { IPaginationParams, QueryKey, usePaginatedApi } from 'modules/module-api'
import { useDebounce } from 'modules/module-utils'
import { Moment } from 'moment'
import { useMemo } from 'react'
import { ParcelDirection, ParcelStatus, ParcelType } from '../../screens/models'

export enum InboundSortFields {
  PARCEL_IMAGE = 'PARCEL_IMAGE',
  BARCODE = 'BARCODE',
  TENANT_NAME = 'TENANT_NAME',
  BUILDING_NAME = 'BUILDING_NAME',
  COURIER = 'COURIER',
  TRACKING_NUMBER = 'TRACKING_NUMBER',
  DOC_ID = 'DOC_ID',
  FIRST_RECEIVED = 'FIRST_RECEIVED',
  DELIVERED = 'DELIVERED',
  COLLECTED = 'COLLECTED',
  LAST_MODIFIED = 'LAST_MODIFIED',
  TAGS = 'TAGS',
  TYPE = 'TYPE',
  DESTINATION = 'ROOM',
  HANDLER = 'HANDLER',
  STATUS = 'STATUS',
  DIRECTION = 'PARCEL_DIRECTION',
  QUANTITY = 'QUANTITY',
}

export interface IUseParcelsParams extends IPaginationParams<InboundSortFields> {
  buildingIds: number[]
  parcelStatus: ParcelStatus[]
  parcelDirection?: ParcelDirection[]
  parcelTypes?: ParcelType[]
  utcOffset: number
  /**
   * Specify a recipient id when a tenant is logged in
   */
  recipientId?: string
  deliveredOnFrom?: Moment
  deliveredOnTo?: Moment
  collectedOnFrom?: Moment
  collectedOnTo?: Moment
  tagIds?: number[]
  filter?: string
}

export function useParcels({
  buildingIds,
  page,
  pageSize,
  sortBy,
  sort,
  parcelStatus,
  utcOffset,
  deliveredOnFrom,
  deliveredOnTo,
  collectedOnFrom,
  collectedOnTo,
  recipientId,
  tagIds,
  parcelDirection,
  parcelTypes,
  filter = '',
}: IUseParcelsParams) {
  const debouncedParams = useDebounce<IUseParcelsParams>(
    {
      buildingIds,
      page,
      pageSize,
      sortBy,
      sort,
      parcelStatus,
      parcelDirection,
      parcelTypes,
      utcOffset,
      deliveredOnFrom,
      deliveredOnTo,
      collectedOnFrom,
      collectedOnTo,
      recipientId,
      tagIds,
      filter,
    },
    500,
    false,
  )({
    buildingIds,
    page,
    pageSize,
    sortBy,
    sort,
    parcelStatus,
    parcelTypes,
    parcelDirection,
    utcOffset,
    deliveredOnFrom,
    deliveredOnTo,
    collectedOnFrom,
    collectedOnTo,
    recipientId,
    tagIds,
    filter,
  })

  const params = useMemo(
    () => ({
      development_ids: debouncedParams!!.buildingIds.join(','),
      tag_ids: debouncedParams!!.tagIds?.length ? debouncedParams!!.tagIds.join(',') : undefined,
      directions: debouncedParams!!.parcelDirection?.length ? debouncedParams!!.parcelDirection.join(',') : undefined,
      page: debouncedParams!!.page,
      pageSize: debouncedParams!!.pageSize,
      sortBy: debouncedParams!!.sortBy,
      statuses: debouncedParams!!.parcelStatus.join(','),
      types: debouncedParams!!.parcelTypes?.join(','),
      delivered_on_from: debouncedParams!!.deliveredOnFrom?.toISOString(),
      delivered_on_to: debouncedParams!!.deliveredOnTo?.toISOString(),
      collected_on_from: debouncedParams!!.collectedOnFrom?.toISOString(),
      collected_on_to: debouncedParams!!.collectedOnTo?.toISOString(),
      sort: debouncedParams!!.sort,
      utc_offset: debouncedParams!!.utcOffset,
      tenant_id: debouncedParams!!.recipientId,
      filter: debouncedParams!!.filter,
    }),
    [debouncedParams],
  )

  const { data, ...rest } = usePaginatedApi(QueryKey.PARCELS, IParcelRowItem, '/v3/parcels/search', params, {
    enabled: debouncedParams!!.buildingIds.length > 0 && debouncedParams!!.parcelStatus.length > 0,
    cacheTime: 60000,
    refetchInterval: 61000,
    select: (data) => {
      return {
        Elements: data?.Elements
          ? plainToInstance(IParcelRowItem, data?.Elements).map((parcel) => ({
              ...parcel,
              tagIds: parcel.tagIds ? (parcel.tagIds as unknown as string).split(',').map((id) => parseInt(id)) : [],
            }))
          : [],
        TotalResults: data?.TotalResults || 0,
      }
    },
  })

  return { parcelsList: data, ...rest }
}

export default useParcels
