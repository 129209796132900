import { EuiRadioGroup, EuiSearchBar } from '@elastic/eui'
import { Grid } from 'modules/web-atoms'
import { IPopoverButtonProps, PopoverButton } from 'modules/web-molecules'
import React from 'react'

export interface IRadioSelectorItem<T extends string = string> {
  id: T
  label: string
}

export interface IRadioSelectorProps<T extends string = string> extends Omit<IPopoverButtonProps, 'children'> {
  label: string
  searchLabel: string
  radios: Array<IRadioSelectorItem<T>>
  selectedRadio: string
  onChange: (id: string) => void
}

const RadioSelector = <T extends string = string>(props: IRadioSelectorProps<T>) => {
  const { searchLabel, radios, selectedRadio, onChange, ...rest } = props
  const [filter, setFilter] = React.useState('')

  const onSearchChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = event.target.value
    setFilter(value)
  }

  const filteredRadios = React.useMemo(() => {
    return radios.filter((radio) => radio.label.toLowerCase().includes(filter.toLowerCase()))
  }, [filter, radios])

  return (
    <PopoverButton {...rest}>
      <Grid>
        <Grid.Row>
          <EuiSearchBar
            box={{ placeholder: searchLabel, incremental: true, value: filter, onChange: onSearchChange }}
          />
        </Grid.Row>
        <Grid.Row
          px="macro"
          className="eui-yScroll"
          borderY="1px solid"
          borderColor="gridLine"
          py="small"
          maxHeight={200}
        >
          <EuiRadioGroup options={filteredRadios} idSelected={selectedRadio} onChange={onChange} name="radio group" />
        </Grid.Row>
      </Grid>
    </PopoverButton>
  )
}

export default React.memo(RadioSelector) as typeof RadioSelector
