import { UserType } from 'api'

export namespace permissions {
  export const canViewUsers: UserType[] = [UserType.OWNER, UserType.ADMIN, UserType.DEBUG]
  export const canCreateUsers: UserType[] = [UserType.OWNER, UserType.ADMIN, UserType.DEBUG]
  export const canEditUsers: UserType[] = [UserType.OWNER, UserType.ADMIN, UserType.DEBUG]
  export const canDeleteUsers: UserType[] = [UserType.OWNER, UserType.ADMIN, UserType.DEBUG]

  export const editUserPermissions: Record<UserType, UserType[]> = {
    [UserType.DEBUG]: [UserType.DEBUG, UserType.OWNER, UserType.ADMIN, UserType.NORMAL, UserType.BASIC],
    [UserType.OWNER]: [UserType.OWNER, UserType.ADMIN, UserType.NORMAL, UserType.BASIC],
    [UserType.ADMIN]: [UserType.ADMIN, UserType.NORMAL, UserType.BASIC],
    [UserType.NORMAL]: [],
    [UserType.BASIC]: [],
  }

  export const canEditUser = (currentUser: UserType, targetUser: UserType | undefined): boolean =>
    targetUser == undefined || (editUserPermissions[currentUser]?.includes(targetUser) ?? false)
}

export default permissions
