import { IReportingConfiguration, ReporterType } from '../model'

import { IReporter } from './model'
import SentryReporter from './sentry/sentry-reporter'

export class ReporterFactory {
  create(config: IReportingConfiguration): IReporter {
    switch (config.type) {
      case ReporterType.Sentry: {
        const { type, debug, environment, source, version, ...rest } = config
        return new SentryReporter({
          debug,
          environment,
          source,
          tags: {
            ...rest,
          },
          version,
        })
      }
      default: {
        throw new Error(`unknown reporter type: ${config.type}`)
      }
    }
  }
}

export default new ReporterFactory()
