import { useAppSelector } from 'modules/module-utils'
import { FlexGroup, useTranslate } from 'modules/web-atoms'
import { TablePageTemplate } from 'modules/web-templates'
import * as React from 'react'
import development, { FormState } from '../../development'
import { BuildingForm } from './BuildingForm/BuildingForm'
import { BuildingsTable } from './BuildingsTable/BuildingsTable'

export interface IBuildingsPageProps extends React.ComponentProps<typeof FlexGroup> {}

export const BuildingsPage: React.FC<IBuildingsPageProps> = (props) => {
  const t = useTranslate('buildings-directory')
  const { ...rest } = props

  const { canViewBuildings } = useAppSelector(development.selectors.permissions)
  const formState = useAppSelector(development.selectors.formState)

  return (
    <TablePageTemplate
      title={t('title')}
      hasViewPermission={canViewBuildings}
      isFormVisible={formState !== FormState.HIDDEN}
      {...rest}
    >
      <TablePageTemplate.Table>
        <BuildingsTable />
      </TablePageTemplate.Table>
      <TablePageTemplate.Form>
        <BuildingForm />
      </TablePageTemplate.Form>
    </TablePageTemplate>
  )
}

export default React.memo(BuildingsPage)
