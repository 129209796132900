import autobind from 'autobind-decorator'

import ClarityService from './clarity/clarity-service'
import HeapService from './heap/heap-service'
import { IAnalyticsService, IIdentityParams, ITrackParams } from './model'

export class AnalyticsService implements IAnalyticsService {
  private readonly heapService = new HeapService()
  private readonly clarityService = new ClarityService()

  @autobind
  async init() {
    await this.heapService.init()
    await this.clarityService.init()
  }

  @autobind
  async identify(identity: IIdentityParams): Promise<void> {
    await this.heapService.identify(identity)
    await this.clarityService.identify(identity)
  }

  @autobind
  async track(track: ITrackParams) {
    await this.heapService.track(track)
    await this.clarityService.track(track)
  }

  @autobind
  async flush(): Promise<void> {
    await this.heapService.flush()
    await this.clarityService.flush()
  }

  @autobind
  async clean(): Promise<void> {
    await this.heapService.clean()
    await this.clarityService.clean()
  }
}

export default AnalyticsService
