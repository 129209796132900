import { UseQueryOptions } from '@tanstack/react-query'
import { IPaginationParams, QueryKey, SortDirection, usePaginatedApi } from 'modules/module-api'
import security from 'modules/module-security'
import { ISizeDto } from 'modules/module-settings/api/dto'
import { useAppSelector } from 'modules/module-utils'
import { useDebounce } from '../../../module-utils/hooks/use-debounce/use-debounce'

export enum SizesSortField {
  NAME = 'NAME',
  TYPE = 'TYPE',
}

export interface IUseSizesParams extends IPaginationParams<SizesSortField> {}

export const useSizes = (
  params: IUseSizesParams = {
    page: 1,
    pageSize: 10,
    sortBy: SizesSortField.NAME,
    sort: SortDirection.ASC,
    filter: '',
  },
  queryOptions?: UseQueryOptions<any>,
) => {
  const clientId = useAppSelector(security.selectors.clientId)
  const debouncedFilter = useDebounce('')(params.filter)

  const { data, ...rest } = usePaginatedApi(
    QueryKey.SIZES,
    ISizeDto,
    '/v3/parcel-sizes',
    {
      ...params,
      client_id: clientId,
      filter: debouncedFilter,
    },
    queryOptions,
  )

  return {
    sizes: data,
    ...rest,
  }
}

export default useSizes
