/* eslint-disable no-undef */
import { createSelector, Selector } from 'reselect'

import http from '../../http'
import { BRANCH_KEY, IOnboardingOwnState } from '../model'

import { IAuthenticationState, VerificationError } from './models'

export const branch: Selector<IOnboardingOwnState, IAuthenticationState> = (state) => state[BRANCH_KEY].authentication

export const isLoading = createSelector(branch, (branch) => branch.loading)

export const error = createSelector(branch, (branch) => branch.error ?? null)

export const signupStep = createSelector(branch, (branch) => branch.signupStep ?? null)

export const loginStep = createSelector(branch, (branch) => branch.loginStep ?? null)

export const accountList = createSelector(branch, (branch) => branch.accountList)

export const errorStatus = createSelector(error, (error) => {
  switch (true) {
    case http.guards.isError(error) && error?.response?.status == 400:
      return VerificationError.Invalid
    case !!error:
      return VerificationError.Unknown
    default:
      return null
  }
})

export const loginMode = createSelector(branch, (branch) => branch.loginMode)
