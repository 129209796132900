import { SagaIterator } from 'redux-saga'
import { all, call, put, select, takeLatest, takeLeading } from 'redux-saga/effects'

import { ICreateDevelopmentWithUsersDto, ISetLastAccessBuildingsDto } from 'api'
import { getLastAccessedDevelopments } from 'api/developments'
import autobind from 'autobind-decorator'
import { AxiosInstance } from 'axios'
import { instanceToPlain } from 'class-transformer'
import notification from 'modules/module-alerts/alert'
import { IQueryService, QueryKey } from 'modules/module-api'
import { translate } from 'modules/module-intl'
import reporting from 'modules/module-reporting'
import { SupervisorSuite } from 'modules/redux-supervisor'

import http from 'modules/http'
import alerts from 'modules/module-alerts'
import orchestration from 'modules/module-orchestration'
import security, { Role } from 'modules/module-security'
import { TokenService } from 'modules/module-security/tokens/TokenService'
import { TableSelectionAction } from 'modules/web-molecules'
import development from '../development'
import {
  addBuilding,
  loading,
  pullCurrentDevelopments,
  setCurrentDevelopments,
  setPermissions,
  updateBuilding,
} from '../development/actions'

export class DevelopmentSaga extends SupervisorSuite {
  private readonly apiService: AxiosInstance
  private readonly tokenService: TokenService
  private readonly queryService: IQueryService

  constructor(apiService: AxiosInstance, tokenService: TokenService, queryService: IQueryService) {
    super()
    this.apiService = apiService
    this.tokenService = tokenService
    this.queryService = queryService
  }

  @autobind
  *start(): SagaIterator {
    yield all([
      takeLatest(orchestration.actions.DETERMINE_USER_PERMISSIONS, this.determineUserPermissions),
      takeLatest(development.actions.PULL_CURRENT_DEVELOPMENTS, this.onPullCurrentDevelopments),
      takeLeading(development.actions.ADD_BUILDING, this.onAddBuilding),
      takeLeading(development.actions.UPDATE_BUILDING, this.onUpdateBuilding),
      takeLeading(development.actions.DELETE_BUILDING, this.onDeleteBuilding),
      takeLeading(development.actions.DELETE_BUILDINGS, this.onDeleteBuildings),
      takeLeading(development.actions.SET_SELECTED_ACTIVE_BUILDINGS, this.onSetSelectedActiveBuildings),
      takeLeading(orchestration.actions.SET_ACTIVE_BUILDINGS, this.onSetActiveBuildings),
    ])
  }

  @autobind
  *determineUserPermissions(): SagaIterator {
    const userType = yield select(security.selectors.type)
    yield put(setPermissions({ userType }))
    yield put(pullCurrentDevelopments())
  }

  @autobind
  *onPullCurrentDevelopments(): SagaIterator {
    yield put(loading(true))
    try {
      const role = yield select(security.selectors.role)
      if (role === Role.USER) {
        const { data } = yield call(getLastAccessedDevelopments)
        yield put(setCurrentDevelopments(data))
        yield call(
          this.tokenService.buildingIds.set,
          data.map((building) => building.Id),
        )
      }
    } catch (error) {
      yield put(reporting.actions.error(error))
    } finally {
      yield put(loading(false))
    }
  }

  @autobind
  *onAddBuilding({ payload }: ReturnType<typeof addBuilding>): SagaIterator {
    yield put(development.actions.updateLoading(true))
    yield put(development.actions.setSubmittedForm(false))
    try {
      const client = yield select(security.selectors.client)

      const data = new ICreateDevelopmentWithUsersDto()
      data.buildingName = payload.name.trim()
      data.clientId = client.Id
      data.addressLine1 = payload.address.trim()
      data.postcode = payload.postcode.trim()
      data.city = payload.city.trim()
      data.emails = payload.users

      yield call(this.apiService, '/v3/portal/developments', {
        method: 'post',
        data: instanceToPlain(data),
      })

      yield put(development.actions.setSubmittedForm(true))
      const message = yield translate('buildings-directory.form.notification.created')
      yield put(notification.actions.showSuccess({ message }))
    } catch (error) {
      if (http.guards.isError(error) && error.response?.status === 409) {
        const message = yield translate('buildings-directory.form.notification.duplicate-site')
        yield put(notification.actions.showError({ message }))
      } else {
        const message = yield translate('buildings-directory.form.notification.created.error')
        yield put(notification.actions.showError({ message }))
        yield put(reporting.actions.error(error))
      }
    } finally {
      yield put(development.actions.updateLoading(false))
      yield call(this.queryService.refetchQueries, [QueryKey.BUILDINGS])
    }
  }

  @autobind
  *onUpdateBuilding({ payload }: ReturnType<typeof updateBuilding>): SagaIterator {
    yield put(development.actions.updateLoading(true))
    try {
      const client = yield select(security.selectors.client)
      const data = new ICreateDevelopmentWithUsersDto()
      data.id = payload.id
      data.buildingName = payload.name.trim()
      data.clientId = client.Id
      data.addressLine1 = payload.address.trim()
      data.postcode = payload.postcode.trim()
      data.city = payload.city.trim()
      data.emails = payload.users

      yield call(this.apiService, `/v3/portal/developments/${payload.id}`, {
        method: 'put',
        data: instanceToPlain(data),
      })
      const message = yield translate('buildings-directory.form.notification.updated')
      yield put(notification.actions.showSuccess({ message }))
    } catch (error) {
      if (http.guards.isError(error) && error.response?.status === 409) {
        const message = yield translate('buildings-directory.form.notification.duplicate-site')
        yield put(notification.actions.showError({ message }))
      } else {
        const message = yield translate('buildings-directory.form.notification.updated.error')
        yield put(notification.actions.showError({ message }))
        yield put(reporting.actions.error(error))
      }
    } finally {
      yield put(development.actions.updateLoading(false))
      yield call(this.queryService.refetchQueries, [QueryKey.BUILDINGS])
      yield call(this.queryService.refetchQueries, [QueryKey.BUILDING, payload.id] as any)
      yield call(this.queryService.refetchQueries, [QueryKey.ASSIGNED_USERS, payload.id] as any)
    }
  }

  @autobind
  *onDeleteBuildings(): SagaIterator {
    yield put(development.actions.deleteBuildingsLoading(true))

    try {
      const selectedBuildings: string[] = yield select(development.selectors.selectedBuildings)
      const selectedBuilding: string = yield select(development.selectors.selectedBuilding)
      const buildingsIds = selectedBuildings.length ? selectedBuildings : [selectedBuilding]
      yield all(
        buildingsIds.map((buildingId) =>
          call(this.apiService, `/v3/developments/${buildingId}`, {
            method: 'DELETE',
          }),
        ),
      )

      const message = yield translate('buildings-directory.table.notification.deleted')
      yield put(notification.actions.showSuccess({ message }))
    } catch (err) {
      if (http.guards.isError(err) && err.response?.status === 412) {
        const message = yield translate('buildings-directory.table.notification.cannot-delete-all-buildings')
        yield put(alerts.actions.error({ message }))
      } else {
        const message = yield translate('buildings-directory.table.notification.deleted.error')
        yield put(notification.actions.showError({ message }))
        yield put(reporting.actions.error(err))
      }
    } finally {
      yield put(development.actions.pullCurrentDevelopments())
      yield put(development.actions.setSelectedBuildings({ items: [], action: TableSelectionAction.DESELECTED_ALL }))
      yield put(development.actions.setSelectedBuilding({ building: undefined }))

      yield call(this.queryService.refetchQueries, [QueryKey.BUILDINGS])
      yield call(this.queryService.refetchQueries, [QueryKey.ACTIVE_BUILDINGS])
      yield put(development.actions.deleteBuildingsLoading(false))
    }
  }

  @autobind
  *onDeleteBuilding(): SagaIterator {
    yield put(development.actions.deleteBuildingsLoading(true))
    try {
      const buildingId = yield select(development.selectors.selectedBuilding)
      yield call(this.apiService, `/v3/developments/${buildingId}`, {
        method: 'DELETE',
      })

      const message = yield translate('buildings-directory.table.notification.deleted')
      yield put(notification.actions.showSuccess({ message }))
    } catch (err) {
      const message = yield translate('buildings-directory.table.notification.deleted.error')
      yield put(notification.actions.showError({ message }))
      yield put(reporting.actions.error(err))
    } finally {
      yield put(development.actions.pullCurrentDevelopments())
      yield put(development.actions.setSelectedBuildings({ items: [], action: TableSelectionAction.DESELECTED_ALL }))
      yield put(development.actions.setSelectedBuilding({ building: undefined }))

      yield call(this.queryService.refetchQueries, [QueryKey.BUILDINGS])
      yield call(this.queryService.refetchQueries, [QueryKey.ACTIVE_BUILDINGS])
      yield put(development.actions.deleteBuildingsLoading(false))
    }
  }

  @autobind
  *onSetSelectedActiveBuildings(): SagaIterator {
    const selectedBuildings: string[] = yield select(development.selectors.selectedBuildings)
    const selectedBuilding: string | undefined = yield select(development.selectors.selectedBuilding)

    const activeBuildings = selectedBuildings.length
      ? selectedBuildings
      : !!selectedBuilding
      ? [selectedBuilding]
      : undefined

    if (!!activeBuildings) {
      yield put(orchestration.actions.setActiveBuildings({ buildings: activeBuildings }))
    }
  }

  @autobind
  *onSetActiveBuildings({ payload }: ReturnType<typeof orchestration.actions.setActiveBuildings>): SagaIterator {
    try {
      yield put(development.actions.loading(true))
      const data = new ISetLastAccessBuildingsDto()
      data.developmentIds = payload.buildings

      yield call(this.apiService, `/v3/users/last-accessed-building/web`, {
        method: 'post',
        data: instanceToPlain(data),
      })
      yield put(development.actions.pullCurrentDevelopments())
      yield put(development.actions.setSelectedBuildings({ items: [], action: TableSelectionAction.DESELECTED_ALL }))
      yield put(development.actions.setSelectedBuilding({ building: undefined }))
      yield call(this.tokenService.buildingIds.set, data.developmentIds.join(','))

      yield call(this.queryService.refetchQueries, [QueryKey.ACTIVE_BUILDINGS])
      yield put(security.actions.loadPermissions())

      const message = yield translate('buildings-directory.table.notification.active-building')
      yield put(notification.actions.showSuccess({ message }))
    } catch (error) {
      yield put(reporting.actions.error(error))
    } finally {
      yield put(development.actions.loading(false))
    }
  }
}

export default DevelopmentSaga
