import { NAVIGATE_RECIPIENT_UPLOAD } from 'modules/module-recipient-upload/upload/actions'
import { handleActions } from 'modules/utils/redux'

import {
  NAVIGATE_ONBOARDING,
  SET_CURRENT_ONBOARDING_MODAL_COMPONENT,
  SET_DISPLAY_ONBOARDING_MODAL,
  SET_PARCELS_SCANNED,
  SET_PARCELS_WAITING,
} from './actions'
import onboardingFlow from './flow'
import { IBoardState, initialState, IOnboardingNavigatePayload, ModalComponent } from './models'

export const reducer = handleActions<IBoardState>(
  {
    [SET_DISPLAY_ONBOARDING_MODAL]: (state: IBoardState, { payload }: { payload: boolean }) => {
      state.displayDashboardModal = payload
    },
    [SET_CURRENT_ONBOARDING_MODAL_COMPONENT]: (state: IBoardState, { payload }: { payload: ModalComponent }) => {
      state.currentOnboardingModalComponent = payload
    },
    [SET_PARCELS_WAITING]: (state: IBoardState, { payload }: { payload: number }) => {
      state.parcelsWaiting = payload
    },
    [SET_PARCELS_SCANNED]: (state: IBoardState, { payload }: { payload: number }) => {
      state.parcelsScanned = payload
    },
    [NAVIGATE_ONBOARDING]: (state: IBoardState, { payload }: { payload: IOnboardingNavigatePayload }) => {
      const currentScreen = state.currentScreen
      const currentNode = onboardingFlow.find((n) => n.id == currentScreen)
      const action = currentNode?.actions.find((a) => a.id == payload)

      if (!!action) state.currentScreen = action.to
    },
    [NAVIGATE_RECIPIENT_UPLOAD]: (state: IBoardState, { payload }: { payload: IOnboardingNavigatePayload }) => {
      const currentScreen = state.currentScreen
      const currentNode = onboardingFlow.find((n) => n.id == currentScreen)
      const action = currentNode?.actions.find((a) => a.id == payload)

      if (!!action) state.currentScreen = action.to
    },
  },
  initialState,
)

export default reducer
