// Sentry
import * as Sentry from '@sentry/react'

export function logError(e: any, message?: string) {
  try {
    if (message) Sentry.addBreadcrumb({ message: message })
    Sentry.captureException(e)
  } catch (error) {
    console.log(error)
  }
}

export function logCrumb(message: string) {
  console.log(message)
  try {
    Sentry.addBreadcrumb({ message })
  } catch (error) {
    console.log(error)
  }
}
