import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { EuiGlobalToastList } from '@elastic/eui'
import { Toast } from '@elastic/eui/src/components/toast/global_toast_list'

import { ToastTypeStyles, useTranslate } from 'modules/web-atoms'

import { hide } from '../../actions'
import { messages } from '../../selectors'

export interface IToasts {}

export const Toasts: React.FC<IToasts> = (props) => {
  const t = useTranslate('alerts.toasts')
  const dispatch = useDispatch()

  const notifications = useSelector(messages)

  const toasts: Toast[] = React.useMemo(() => {
    return notifications.map((notification) => {
      const style = ToastTypeStyles[notification.type]
      return {
        id: notification.id,
        iconType: style.iconType,
        color: style.color,
        text: notification.message,
        title: notification.title,
      }
    })
  }, [notifications])

  const hideToast = React.useCallback(
    (removedToast) => {
      dispatch(hide(removedToast.id))
    },
    [dispatch],
  )

  return (
    <EuiGlobalToastList
      side="left"
      toasts={toasts}
      dismissToast={hideToast}
      toastLifeTimeMs={6000}
      aria-label={t('label')}
    />
  )
}

export default React.memo(Toasts)
