import * as React from 'react'

import {
  EuiCheckboxGroupIdToSelectedMap,
  EuiCheckboxGroupOption,
} from '@elastic/eui/src/components/form/checkbox/checkbox_group'

import { Grid, IGridProps, Paragraph, useTranslate } from 'modules/web-atoms'
import { CheckboxGroup, FieldSearch, ICheckboxGroupProps } from 'modules/web-molecules'

export interface ICheckBoxSelectorProps extends IGridProps {
  selected: string[]
  onChange: (selection: string[]) => void
  searchProps: React.ComponentProps<typeof FieldSearch>
  checkboxProps: Omit<ICheckboxGroupProps, 'idToSelectedMap' | 'onChange'>
  disabled?: boolean
}

const OPTION_ALL = 'option-all'

export const CheckboxSelector: React.FC<ICheckBoxSelectorProps> = (props) => {
  const { onChange, searchProps, selected, checkboxProps, children, disabled, ...rest } = props
  const t = useTranslate('web-organisms.checkbox-selector')

  const { options, ...restCheckboxProps } = checkboxProps

  const [checkboxSelection, setCheckboxSelection] = React.useState<EuiCheckboxGroupIdToSelectedMap>({})
  const [checkboxOptions, setCheckboxOptions] = React.useState<EuiCheckboxGroupOption[]>([])

  React.useEffect(() => {
    const update = selected.reduce(
      (acc, id) => {
        return { ...acc, [id]: true }
      },
      selected.length == checkboxOptions.length - 1 ? { [OPTION_ALL]: true } : { [OPTION_ALL]: false },
    )
    setCheckboxSelection(update)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  React.useEffect(() => {
    setCheckboxOptions([
      {
        id: OPTION_ALL,
        labelProps: {
          style: {
            marginBottom: 20,
          },
        },
        label: (
          <Paragraph lineHeight="smallest" color="textLightMid" textAlign="center" size="medium" fontWeight="bold">
            {selected.length === options.length ? t('deselect-all') : t('select-all')}
          </Paragraph>
        ),
      },
      ...options,
    ])
  }, [options, t, selected])

  const onCheckedChange = React.useCallback(
    (id: string) => {
      if (id === OPTION_ALL) {
        const currentSelected = Object.keys(checkboxSelection).length
        if (currentSelected == checkboxOptions.length) {
          checkboxOptions.length > 1 &&
            setCheckboxSelection({
              [checkboxOptions[1].id]: true,
            })
          checkboxOptions.length > 1 && onChange([checkboxOptions[1].id])
        } else {
          const update = checkboxOptions.reduce((acc, item) => {
            return { ...acc, [item.id]: true }
          }, {})
          const updatedKeys = Object.keys(update).filter((item) => item !== OPTION_ALL)
          setCheckboxSelection(update)
          onChange(updatedKeys)
        }
      } else {
        const currentKeys = Object.keys(checkboxSelection)
        const isEnabled = currentKeys.includes(id)
        if (isEnabled) {
          const updatedKeys = currentKeys.filter((item) => item !== id)
          const update = updatedKeys.reduce((acc, item) => {
            return { ...acc, [item]: true }
          }, {})
          setCheckboxSelection(update)
          onChange(updatedKeys)
        } else {
          const updatedKeys = [...currentKeys, id]
          const update = updatedKeys.reduce((acc, item) => {
            return { ...acc, [item]: true }
          }, {})
          setCheckboxSelection(update)
          onChange(updatedKeys)
        }
      }
    },
    [checkboxSelection, checkboxOptions, onChange],
  )

  return (
    <Grid {...rest}>
      <FieldSearch isClearable={true} {...searchProps} />
      <Grid marginY="smallest" height={300} className="eui-yScrollWithShadows">
        <CheckboxGroup
          height="100%"
          paddingX="macro"
          marginTop="smallest"
          options={checkboxOptions}
          onChange={onCheckedChange}
          idToSelectedMap={checkboxSelection}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  )
}

export default React.memo(CheckboxSelector)
