import { EventFormatter } from '../models'

export const removeRequestData: EventFormatter = (event) => {
  if (event.extra?.config?.data) {
    delete event.extra.config.data
  }

  if (event.payload?.config?.data) {
    delete event.payload.config.data
  }

  return event
}

export default removeRequestData
