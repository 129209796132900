import autobind from 'autobind-decorator'
import { Plan } from '../billing'
import { AVAILABLE_PLANS, MAX_PRO_RECIPIENTS } from '../constants'
import { IPlanConfig } from './models'

export class BillingService {
  @autobind
  getRecommendedPlan(currentPlan: Plan, numRecipients: number, numBuildings: number): IPlanConfig {
    if (numRecipients > MAX_PRO_RECIPIENTS || currentPlan === Plan.ENTERPRISE) {
      return this.getPlan(Plan.ENTERPRISE)
    } else if (numBuildings > 1) {
      return this.getPlan(Plan.PRO_MULTI_SITE)
    } else {
      const currentPlanConfig = this.getPlan(currentPlan)
      return AVAILABLE_PLANS.find((plan) => plan.tier - currentPlanConfig.tier === 1) as IPlanConfig
    }
  }

  @autobind
  getBannerState(): any {}

  @autobind
  getPlan(planId: Plan): IPlanConfig {
    return AVAILABLE_PLANS.find((plan) => plan.id === planId) as IPlanConfig
  }
}

export default BillingService
