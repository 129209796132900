/// <reference path="./@types/json.d.ts"/>

import * as actions from './actions/actions'
import * as matchers from './actions/matchers'
import * as meta from './actions/meta'
import reporter from './reporting/reporter'
import reducer from './root-reducer'
import sagas from './saga'

export * from './model'
export * from './redux/sentry-enhancer'
export * from './reporters/model'
export * from './errors/SentryErrorBase'

export { reporter }

export const reporting = {
  sagas,
  reducer,
  actions,
  matchers,
  meta,
}

export default reporting
