import { createLogger } from 'redux-logger'
import createSagaMiddleware from 'redux-saga'

import analytics from 'modules/module-analytics'
import navigation from 'modules/module-navigation'
import { REACT_APP_ENVIRONMENT } from 'modules/utils/constants'

export const sagaMiddleware = createSagaMiddleware()

const middleware = [sagaMiddleware, navigation.router.middleware, analytics.heartbeat]

/* istanbul ignore next */
if (REACT_APP_ENVIRONMENT === 'development') {
  const invariant = require('redux-immutable-state-invariant').default

  middleware.push(invariant())
  middleware.push(createLogger({ collapsed: true }))
}

export default middleware
