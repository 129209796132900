import React from 'react'
import { EuiComboBox, EuiComboBoxProps, EuiComboBoxSingleSelectionShape } from '@elastic/eui'
import styled from '@emotion/styled'
import { Grid, StyledViewProps } from 'modules/web-atoms'
import * as system from 'styled-system'
import { useTheme } from '@emotion/react'

export type IComboBoxProps = StyledViewProps &
  Omit<EuiComboBoxProps<any>, 'singleSelection'> & {
    singleSelection?: boolean | EuiComboBoxSingleSelectionShape
  }

export const StyledComboBox = styled(EuiComboBox)<IComboBoxProps>(
  system.color,
  system.flexbox,
  system.space,
  system.layout,
  system.position,
  system.justifyContent,
  system.alignItems,
  system.borders,
  system.shadow,
  system.background,
)

const transform = system.compose(
  system.color,
  system.flexbox,
  system.borders,
  system.margin,
  system.position,
  system.layout,
)

export const ComboBox: React.FC<IComboBoxProps> = (props) => {
  const container = transform({ ...props, theme: useTheme() })

  return (
    <Grid {...container}>
      <StyledComboBox style={{ margin: 0, padding: 0, maxWidth: 'auto' }} {...props} />
    </Grid>
  )
}

export default React.memo(ComboBox)
