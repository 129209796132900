import React from 'react'
import StyledView from '../Grid/StyledView'
import GridItem from './GridItem'

export interface IDynamicGridProps extends React.ComponentProps<typeof StyledView> {
  children: React.ReactNode
}

const DynamicGridContainer: React.FC<IDynamicGridProps> = ({ children, ...rest }) => {
  return (
    <StyledView {...rest} display="grid">
      {children}
    </StyledView>
  )
}

export interface IDynamicGrid extends React.MemoExoticComponent<typeof DynamicGridContainer> {
  Item: typeof GridItem
}

const DynamicGrid: IDynamicGrid = Object.assign(React.memo(DynamicGridContainer), {
  type: DynamicGridContainer,
  Item: GridItem,
})

export default DynamicGrid
