import { DeepPartial } from 'redux'

import deepmerge from 'deepmerge'

import { ISessionState } from './session/models'

export const BRANCH_KEY = 'security'

export interface ISecurityBranchState {
  session: ISessionState
}

export interface ISecurityOwnState {
  [BRANCH_KEY]: ISecurityBranchState
}

export type ISecurityState = ISecurityOwnState

export const INITIAL_STATE: ISecurityState = {
  [BRANCH_KEY]: {
    session: {
      loading: false,
      isAuthenticated: false,
      role: undefined,
      username: undefined,
      userId: undefined,

      email: undefined,
      dateOfBirth: undefined,
      firstName: undefined,
      lastName: undefined,
      type: undefined,
      client: undefined,
      development: undefined,
      countryCode: undefined,
      smsEnabled: false,
    },
  },
}

export const createState = (state: DeepPartial<ISecurityOwnState> = {}): ISecurityOwnState =>
  deepmerge(INITIAL_STATE, state) as ISecurityState
