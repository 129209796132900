import * as React from 'react'

import { EuiFlexGrid } from '@elastic/eui'
import styled from '@emotion/styled'

import * as system from 'styled-system'

import { StyledViewProps } from '../Grid/StyledView'

export type IFlexGridProps = StyledViewProps & React.ComponentProps<typeof EuiFlexGrid>

export const StyledFlexGrid = styled(EuiFlexGrid)<IFlexGridProps>(
  system.color,
  system.flexbox,
  system.space,
  system.layout,
  system.position,
  system.justifyContent,
  system.alignItems,
  system.borders,
  system.shadow,
  system.background,
)

export const FlexGrid: React.FC<IFlexGridProps> = (props) => {
  const { children, ...rest } = props
  return <StyledFlexGrid {...rest}>{children}</StyledFlexGrid>
}

FlexGrid.displayName = 'FlexGrid'

export default FlexGrid
