export * as styles from './styles/'
import { colors, spacing } from './styles'

export * from './styles/color'
export * from './styles/borders'
export * from './styles/radii'
export * from './styles/spacing'
export * from './styles/typography'
export * from './styles/radii'
export * from './themes/model'
export * from './themes/light'

export * from './ThemeDecorator/ThemeDecorator'
export * from './decorations/decorations'

export * from './hooks/use-color'
export { default as useColor } from './hooks/use-color'

export * from './assets/images'
export * from './assets/animations'

const theme = {
  spacing,
  colors,
}

export default theme
