import useRoutes from '../../routes/use-routes/use-routes'
import { processRoute } from 'modules/module-navigation'
import { BannerContainer } from 'modules/module-alerts'
import { Grid, IGridProps, useScreen, useToggle, useTranslate } from 'modules/web-atoms'
import * as React from 'react'
import { Switch } from 'react-router'
import PortalHeader from '../PortalHeader/PortalHeader'
import { PortalSideMenu } from '../PortalSideMenu/PortalSideMenu'

export interface IPortalContainerProps extends IGridProps {}

export const PortalContainer: React.FC<IPortalContainerProps> = (props) => {
  const t = useTranslate('core.banner')
  const { ...rest } = props

  const { isMobile } = useScreen()
  const [isOpen, toggleSideMenu] = useToggle(true)

  const { authenticatedRoutes } = useRoutes()

  return (
    <Grid
      position="relative"
      height="100vh"
      backgroundColor="primaryBackgroundMid"
      gutterSize="none"
      {...rest}
      role="main"
    >
      {isMobile && <PortalHeader onToggleNav={toggleSideMenu} />}
      <BannerContainer closeLabel={t('hide.label')} />
      <PortalSideMenu isOpen={isOpen || !isMobile} isDocked={!isMobile} onClose={() => toggleSideMenu(false)} />
      <Grid
        className="main-content"
        backgroundColor="primaryBackgroundMid"
        padding={['none', 'medium']}
        paddingTop="medium"
      >
        <Switch>{processRoute(authenticatedRoutes)}</Switch>
      </Grid>
    </Grid>
  )
}

export default React.memo(PortalContainer)
