import { createSelector, Selector } from '@reduxjs/toolkit'
import { BRANCH_KEY } from '../branch'

import { IRecipientsOwnState } from '../model'

import { IRecipientProfileState } from './models'

export const branch: Selector<IRecipientsOwnState, IRecipientProfileState> = (state) => state[BRANCH_KEY].profile

export const isLoading = createSelector(branch, (branch) => branch.isLoading)
