import { ITenantProfileDto } from 'api'
import API from 'api/clients'
import { plainToInstance } from 'class-transformer'
import { QueryKey } from 'modules/module-api'
import useApi from 'modules/module-api/hooks/useApi/use-api'
import React from 'react'

function useCurrentRecipient() {
  const { data, isFetching, error } = useApi<ITenantProfileDto>([QueryKey.GET_CURRENT_RECIPIENT], async () => {
    const response = await API({
      method: 'GET',
      url: '/v3/tenants/current',
      params: { include: '*' },
    })
    return response.data
  })

  const recipientData = React.useMemo(() => (data ? plainToInstance(ITenantProfileDto, data) : undefined), [data])

  return { recipientData, isFetching, error }
}

export default useCurrentRecipient
