import * as React from 'react'

import { EuiButton, EuiButtonEmpty, EuiLoadingSpinner } from '@elastic/eui'

import { isElement } from 'modules/module-utils'
import { not } from 'modules/utils'
import { Grid, H1, H2, H3, Paragraph, useScreen } from 'modules/web-atoms'
import { IPanelProps, Panel } from 'modules/web-molecules'

export enum FormType {
  CREATE,
  EDIT,
}

export interface IDeleteOptions {
  value: string
  loading: boolean
  isDeleteModalOpen: boolean
  onClick: () => void
}

export interface IPermissions {
  canDelete: boolean
  canEdit: boolean
  canAdd: boolean
}

export type ITableFormTemplateProps = IPanelProps & {
  heading: string
  subheading: string
  cancelValue: string
  saveValue: string
  createValue: string
  formType: FormType
  deleteOptions: IDeleteOptions
  permissions: IPermissions
  isFetching: boolean
  loading: boolean
  isSubmitDisabled?: boolean
  onCancel: () => void
  onSubmit: () => void
}

export const TableFormTemplate: React.FC<ITableFormTemplateProps> = (props) => {
  const {
    heading,
    subheading,
    cancelValue,
    saveValue,
    deleteOptions,
    formType,
    permissions,
    isFetching,
    loading,
    isSubmitDisabled,
    onCancel,
    onSubmit,
    ...rest
  } = props

  const { isMobile } = useScreen()

  const isCreateForm = formType === FormType.CREATE
  const isEditForm = formType === FormType.EDIT

  const { content, form, deleteModal } = React.useMemo(() => {
    const children = React.Children.toArray(props.children)

    const content = children.filter(not(isElement(Form), isElement(DeleteModal)))
    const form = children.filter(isElement(Form)).map((panel) => panel.props.children)
    const deleteModal = children.filter(isElement(DeleteModal)).map((panel) => panel.props.children)
    return { content, form, deleteModal }
  }, [props.children])

  return (
    <>
      <Panel padding="none" height="100%" {...rest}>
        <Grid height="100%" justifyContent="flex-start" overflow="hidden">
          {isFetching ? (
            <Grid width="100%" height="100%" alignItems="center" justifyContent="center">
              <EuiLoadingSpinner size="xl" />
            </Grid>
          ) : (
            <Grid.Col flexGrow={1}>
              <Grid height="100%" overflow="hidden" {...rest}>
                <Grid
                  paddingX={['small', 'small', 'small', 'xlarge']}
                  paddingY={['smallest', 'smallest', 'smallest', 'large']}
                  className="eui-yScroll"
                >
                  <Grid>
                    <H1 color="primaryMid" marginTop={['macro', 'smallest', 'smallest']}>
                      {heading}
                    </H1>
                    {isCreateForm && (
                      <H3 color="textDark" marginTop="macro">
                        {subheading}
                      </H3>
                    )}
                    {isMobile && isEditForm && permissions.canDelete && (
                      <Grid.Row marginTop={['macro', 'smallest', 'smallest']}>
                        <EuiButton
                          isLoading={deleteOptions.loading}
                          onClick={deleteOptions.onClick}
                          fill
                          color="danger"
                          size="s"
                          fullWidth={false}
                        >
                          {deleteOptions.value}
                        </EuiButton>
                      </Grid.Row>
                    )}
                  </Grid>
                  {form}
                </Grid>

                <Grid
                  marginBottom={['large', 'medium', null, 'xlarge']}
                  marginTop="small"
                  gutterSize="medium"
                  isResponsive={false}
                  justifyContent="center"
                >
                  <Grid.Col mx={['smallest', 'small']}>
                    <EuiButtonEmpty color="primary" onClick={onCancel} disabled={loading}>
                      {cancelValue}
                    </EuiButtonEmpty>
                  </Grid.Col>
                  <Grid.Col mx={['smallest', 'small']}>
                    <EuiButton
                      isLoading={isFetching || loading}
                      color="primary"
                      type="submit"
                      textProps={{ style: { color: 'white' } }}
                      fill
                      isDisabled={isSubmitDisabled || (isEditForm ? !permissions.canEdit : !permissions.canAdd)}
                      onClick={onSubmit}
                    >
                      <Paragraph px={['none', 'medium']} color="textLight" fontWeight="medium">
                        {saveValue}
                      </Paragraph>
                    </EuiButton>
                  </Grid.Col>
                </Grid>
              </Grid>
            </Grid.Col>
          )}
        </Grid>
      </Panel>
      {deleteOptions.isDeleteModalOpen && deleteModal}
    </>
  )
}

export interface ITableFormTemplate extends React.MemoExoticComponent<typeof TableFormTemplate> {
  Form: typeof Form
  DeleteModal: typeof DeleteModal
}

const Form: React.FunctionComponent = ({ children }) => <>{children}</>
const DeleteModal: React.FunctionComponent = ({ children }) => <>{children}</>

const template: ITableFormTemplate = Object.assign(React.memo(TableFormTemplate), {
  type: TableFormTemplate,
  Form,
  DeleteModal,
})

export default template
