import { createReducer } from '@reduxjs/toolkit'
import { initialState } from './models'
import { changeLanguage } from './actions'

const reducer = createReducer(initialState, (builder) =>
  builder.addCase(changeLanguage, (state, { payload }) => {
    state.language = payload
  }),
)

export default reducer
