import { useDebounce } from 'modules/module-utils'
import * as React from 'react'

const FRAME_SRC_DOC = `
<html>
  <head>
  <script type="module">
    window.addEventListener('message', (event) => {
      const { type, value } = event.data

      if (type === 'html') {
        document.body.innerHTML = value
      }
    })
  </script>
  </head>
  <body>
  </body>
</html>
`

export interface IFrameProps
  extends React.DetailedHTMLProps<React.IframeHTMLAttributes<HTMLIFrameElement>, HTMLIFrameElement> {
  html: string
}

export const IFrame: React.FC<IFrameProps> = ({ html, ...rest }) => {
  const iframeElement = React.useRef<HTMLIFrameElement | null>(null)

  // Debounce to avoid issues with messages being lost
  const debouncedHtml = useDebounce('', 100, false)(html)

  React.useEffect(() => {
    if (iframeElement.current?.contentWindow) {
      iframeElement.current?.contentWindow?.postMessage({ type: 'html', value: debouncedHtml }, '*')
    }
  }, [debouncedHtml, iframeElement])

  return <iframe ref={iframeElement} srcDoc={FRAME_SRC_DOC} {...rest} />
}

export default React.memo(IFrame)
