import React from 'react'
import StyledView from '../Grid/StyledView'

export interface IDynamicGridItemProps extends React.ComponentProps<typeof StyledView> {}

const DynamicGridItem: React.FC<IDynamicGridItemProps> = ({ children, ...rest }) => {
  return (
    <StyledView display="flex" {...rest}>
      {children}
    </StyledView>
  )
}

export default DynamicGridItem
