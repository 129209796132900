import * as React from 'react'

import { EuiFieldText } from '@elastic/eui'
import styled from '@emotion/styled'

import { StyledViewProps } from 'modules/web-atoms'
import * as system from 'styled-system'

export type IFieldTextProps = StyledViewProps & React.ComponentProps<typeof EuiFieldText>

export const StyledFieldText = styled(EuiFieldText)<IFieldTextProps>(
  system.color,
  system.flexbox,
  system.space,
  system.layout,
  system.position,
  system.justifyContent,
  system.alignItems,
  system.borders,
  system.shadow,
  system.background,
)

export const FieldText: React.FC<IFieldTextProps> = (props) => {
  const { children, readOnly, onChange, ...rest } = props
  const onTextChange = !readOnly ? onChange : undefined
  return (
    <StyledFieldText onChange={onTextChange} {...rest}>
      {children}
    </StyledFieldText>
  )
}

export default FieldText
