import React from 'react'

import settings, { SettingsFormState } from 'modules/module-settings/settings'
import { IUsePaginatedTableParams, useAppDispatch, useAppSelector, usePaginatedTable } from 'modules/module-utils'
import { H5, Paragraph, useTranslate } from 'modules/web-atoms'
import {
  IColumns,
  IPagination,
  ITableItem,
  ITableSelectedChange,
  TableColumnType,
  TableSelectionAction,
} from 'modules/web-molecules'
import useDropoffLocations, { DropoffLocationsSortField } from '../use-dropoff-locations/use-dropoff-locations'
import { SortDirection } from 'modules/module-api'

export type IDropoffLocationsTableColumns = DropoffLocationsSortField
export type IDropoffLocationsTableItem = ITableItem<DropoffLocationsSortField>

export default function useDropoffLocationsTable(clientId: number | undefined) {
  const t = useTranslate('settings.tabs.dropoff-locations.table')
  const dispatch = useAppDispatch()
  const selectedItems = useAppSelector(settings.selectors.selectedDropoffLocations)
  const selectedItem = useAppSelector(settings.selectors.selectedDropoffLocation)

  const onSortChange = () => {
    dispatch(settings.actions.setSelectedDropoffLocation(undefined))
  }

  const onSelectionChange: ITableSelectedChange<IDropoffLocationsTableColumns, IDropoffLocationsTableItem> = (
    items: IDropoffLocationsTableItem[],
    action: TableSelectionAction,
  ) => {
    dispatch(
      settings.actions.setSelectedDropoffLocations({
        items: items.map((item) => item.id),
        action,
      }),
    )
  }

  const paginationParams: IUsePaginatedTableParams<
    IDropoffLocationsTableColumns,
    IDropoffLocationsTableItem,
    DropoffLocationsSortField,
    typeof DropoffLocationsSortField
  > = {
    sortClass: DropoffLocationsSortField,
    pageParams: {
      page: 0,
      pageSize: 10,
      sortBy: DropoffLocationsSortField.NAME,
      sort: SortDirection.ASC,
      filter: '',
    },
    itemsPerPage: [10, 20, 50],
    onSortChange,
    onSelectionChange,
  }

  const { paging, paginationOptions, onSearch, selection, sort } = usePaginatedTable<
    IDropoffLocationsTableColumns,
    IDropoffLocationsTableItem,
    DropoffLocationsSortField,
    typeof DropoffLocationsSortField
  >(paginationParams)

  const { dropoffLocations, isFetching, totalItems, error } = useDropoffLocations(
    {
      page: paging.page + 1,
      sortBy: paging.sortBy,
      sort: paging.sort,
      pageSize: paging.pageSize,
      client_id: clientId,
      filter: paging.filter,
    },
    {
      enabled: !!clientId,
      cacheTime: 0,
    },
  )

  const onItemSelected = (item: IDropoffLocationsTableItem) => {
    if (selectedItem?.id === item.id) {
      dispatch(settings.actions.setSelectedDropoffLocation(undefined))
      dispatch(settings.actions.setDropoffLocationFormState(SettingsFormState.HIDDEN))
    } else {
      const dropoffLocation = dropoffLocations.find((dropoffLocation) => dropoffLocation.id === item.id)
      dispatch(settings.actions.setSelectedDropoffLocation(dropoffLocation))
      dispatch(settings.actions.setDropoffLocationFormState(SettingsFormState.EDIT))
    }
  }

  const pagination: IPagination = {
    pageIndex: paging.page,
    pageSize: paging.pageSize,
    total: totalItems,
  }

  const columns: IColumns<IDropoffLocationsTableColumns> = {
    [DropoffLocationsSortField.NAME]: {
      label: <H5 padding="smallest">{t('column.name')}</H5>,
      type: TableColumnType.Column,
      isSortable: true,
    },
    [DropoffLocationsSortField.ENABLED]: {
      label: <H5 padding="smallest">{t('column.enabled')}</H5>,
      type: TableColumnType.Column,
      isSortable: true,
    },
    [DropoffLocationsSortField.RECIPIENTS_COUNT]: {
      label: <H5 padding="smallest">{t('column.assigned-recipients')}</H5>,
      type: TableColumnType.Column,
      isSortable: true,
    },
  }

  const items: IDropoffLocationsTableItem[] = React.useMemo(() => {
    return dropoffLocations.map((dropoffLocation) => {
      const id = dropoffLocation.id
      const enabledCol = dropoffLocation?.enabled ? t('enabled') : t('disabled')
      return {
        id,
        isSelectable: true,
        isChecked: !!selectedItems.find((itemId) => itemId === id),
        isSelected: !!selectedItem && id === selectedItem.id,
        isCheckable: true,
        [DropoffLocationsSortField.NAME]: {
          render: () => (
            <Paragraph title={dropoffLocation.name} fontWeight="bold" size="medium" padding="smallest" truncated={true}>
              {dropoffLocation.name}
            </Paragraph>
          ),
        },
        [DropoffLocationsSortField.ENABLED]: {
          render: () => (
            <Paragraph
              title={enabledCol}
              size="medium"
              padding="smallest"
              truncated={true}
              fontWeight="medium"
              color={dropoffLocation?.enabled ? 'primaryMid' : 'dangerDestructive'}
            >
              {enabledCol}
            </Paragraph>
          ),
        },
        [DropoffLocationsSortField.RECIPIENTS_COUNT]: {
          render: () => (
            <Paragraph
              title={`${dropoffLocation.numAssignedRecipients}`}
              fontWeight="bold"
              size="medium"
              padding="smallest"
              truncated={true}
            >
              {dropoffLocation.numAssignedRecipients}
            </Paragraph>
          ),
        },
      }
    })
  }, [dropoffLocations, selectedItem, selectedItems, t])

  return {
    columns,
    items,
    selection,
    onSearch,
    onItemClicked: onItemSelected,
    isFetching,
    error,
    pagination,
    sort,
    paginationOptions,
    selectedItems,
  }
}
