import { Expose, Type } from 'class-transformer'
import 'reflect-metadata'

export enum Locale {
  UNITED_KINGDOM = 'en-GB',
  UNITED_STATES = 'en-US',
  SPANISH = 'es-ES',
  ITALIAN = 'it-IT',
}

export enum TemplateType {
  DELIVERY = 'DELIVERY',
  COLLECTION = 'COLLECTION',
  SIGNUP = 'SIGNUP',
  REMINDER = 'REMINDER',
}

export enum NotificationScheduleType {
  DEFAULT = 'DEFAULT',
  CUSTOM = 'CUSTOM',
}

export class IScheduleTimeDto {
  @Expose({ name: 'Hours' })
  hours: number
  @Expose({ name: 'Minutes' })
  minutes: number
}
export class IScheduleDateRangeDto {
  @Expose({ name: 'From' })
  @Type(() => IScheduleTimeDto)
  from: IScheduleTimeDto
  @Expose({ name: 'To' })
  @Type(() => IScheduleTimeDto)
  to: IScheduleTimeDto
  @Expose({ name: 'Enabled' })
  enabled: boolean
}
export class IDefaultScheduleDto {
  @Expose({ name: 'Everyday' })
  @Type(() => IScheduleDateRangeDto)
  everyday: IScheduleDateRangeDto
}
export class ICustomScheduleDto {
  @Expose({ name: 'Monday' })
  @Type(() => IScheduleDateRangeDto)
  monday: IScheduleDateRangeDto
  @Expose({ name: 'Tuesday' })
  @Type(() => IScheduleDateRangeDto)
  tuesday: IScheduleDateRangeDto
  @Expose({ name: 'Wednesday' })
  @Type(() => IScheduleDateRangeDto)
  wednesday: IScheduleDateRangeDto
  @Expose({ name: 'Thursday' })
  @Type(() => IScheduleDateRangeDto)
  thursday: IScheduleDateRangeDto
  @Expose({ name: 'Friday' })
  @Type(() => IScheduleDateRangeDto)
  friday: IScheduleDateRangeDto
  @Expose({ name: 'Saturday' })
  @Type(() => IScheduleDateRangeDto)
  saturday: IScheduleDateRangeDto
  @Expose({ name: 'Sunday' })
  @Type(() => IScheduleDateRangeDto)
  sunday: IScheduleDateRangeDto
}
export class ISchedulesDto {
  @Expose({ name: 'Default' })
  @Type(() => IDefaultScheduleDto)
  default: IDefaultScheduleDto
  @Expose({ name: 'Custom' })
  @Type(() => ICustomScheduleDto)
  custom: ICustomScheduleDto
}
export class ISchedulerConfigDto {
  @Expose({ name: 'Enabled' })
  enabled: boolean
  @Expose({ name: 'Timezone' })
  timezone: string
  @Expose({ name: 'Type' })
  type: NotificationScheduleType
  @Expose({ name: 'Schedules' })
  @Type(() => ISchedulesDto)
  schedules: ISchedulesDto
}
export class INotificationConfigurationDto {
  @Expose({ name: 'Id' })
  id: string
  @Expose({ name: 'LogoUrl' })
  logoUrl: string
  @Expose({ name: 'LogoUpdated' })
  logoUpdated?: boolean
  @Expose({ name: 'Name' })
  name: string
  @Expose({ name: 'BackgroundColour' })
  backgroundColour: string
  @Expose({ name: 'AccentColour' })
  accentColour: string
  @Expose({ name: 'LogoWidth' })
  logoWidth: number
  @Expose({ name: 'GlobalEmailsEnabled' })
  globalEmailsEnabled: boolean
  @Expose({ name: 'GlobalSmsEnabled' })
  globalSmsEnabled: boolean
  @Expose({ name: 'Locale' })
  locale: Locale
  @Expose({ name: 'ConfigurationPreview' })
  configurationPreview: string
  @Expose({ name: 'SchedulerConfig' })
  @Type(() => ISchedulerConfigDto)
  scheduler?: ISchedulerConfigDto
}

class IBaseTemplateDataDto {
  @Expose({ name: 'name' })
  name: string
  @Expose({ name: 'emailSubject' })
  subject: string
  @Expose({ name: 'type' })
  type: TemplateType
  @Expose({ name: 'headerText' })
  heading: string
  @Expose({ name: 'text1' })
  subheading: string
  @Expose({ name: 'text2' })
  body: string
}

export class IEditTemplateDto {
  @Expose({ name: 'id' })
  id: string
  @Expose({ name: 'name' })
  name: string
  @Expose({ name: 'headerText' })
  heading: string
  @Expose({ name: 'text1' })
  subheading: string
  @Expose({ name: 'text2' })
  body: string
  @Expose({ name: 'emailSubject' })
  subject: string
  @Expose({ name: 'reminderDelay' })
  reminderDelay?: number
  @Expose({ name: 'maxReminders' })
  maxReminders?: number
  @Expose({ name: 'qrCodeEnabled' })
  qrCodeEnabled?: boolean
  @Expose({ name: 'displayParcelNotesInDetails' })
  notesEnabled?: boolean
  @Expose({ name: 'signatureEnabled' })
  signatureEnabled?: boolean
  @Expose({ name: 'additionalParcelDetailsEnabled' })
  additionalParcelDetails?: boolean
  @Expose({ name: 'smsTemplate' })
  smsTemplate?: string
}

export class ITemplateFormDataDto extends IBaseTemplateDataDto {
  @Expose({ name: 'emailTemplate' })
  emailTemplate: string
  @Expose({ name: 'smsTemplate' })
  smsTemplate?: string
  @Expose({ name: 'reminderDelay' })
  reminderDelay?: number
  @Expose({ name: 'maxReminders' })
  maxReminders?: number
  @Expose({ name: 'qrCodeEnabled' })
  qrCodeEnabled?: boolean
  @Expose({ name: 'displayParcelNotesInDetails' })
  displayParcelNotesInDetails?: boolean
  @Expose({ name: 'additionalParcelDetailsEnabled' })
  additionalParcelDetailsEnabled?: boolean
  @Expose({ name: 'signatureEnabled' })
  signatureEnabled?: boolean
}

export class IAssignmentTemplateDataDto extends IBaseTemplateDataDto {
  @Expose({ name: 'id' })
  id: string
  @Expose({ name: 'isDefault' })
  isDefault?: boolean
}

export class IAssignmentDto {
  @Expose({ name: 'BuildingName' })
  buildingName: string
  @Expose({ name: 'BuildingId' })
  siteId: number
  @Expose({ name: 'Configuration' })
  @Type(() => INotificationConfigurationDto)
  configuration: INotificationConfigurationDto
  @Expose({ name: 'DeliveryTemplates' })
  @Type(() => IAssignmentTemplateDataDto)
  deliveryTemplates?: IAssignmentTemplateDataDto[]
  @Expose({ name: 'CollectionTemplates' })
  @Type(() => IAssignmentTemplateDataDto)
  collectionTemplates?: IAssignmentTemplateDataDto[]
  @Expose({ name: 'ReminderTemplate' })
  @Type(() => IAssignmentTemplateDataDto)
  reminderTemplate?: IAssignmentTemplateDataDto
  @Expose({ name: 'SignupTemplate' })
  @Type(() => IAssignmentTemplateDataDto)
  signupTemplate?: IAssignmentTemplateDataDto
}

export class ISetTemplateAsDefaultDto {
  @Expose({ name: 'TemplateId' })
  templateId: string
  @Expose({ name: 'DevelopmentId' })
  buildingId: number
  @Expose({ name: 'TemplateType' })
  templateType: TemplateType
}
