import { AnyAction } from 'redux'
import { Action } from 'redux-actions'
import { HeartbeatAction } from 'redux-heartbeat'
import { SagaIterator } from 'redux-saga'
import { all, call, delay, put, race, select, take, takeEvery } from 'redux-saga/effects'

import autobind from 'autobind-decorator'
import reporting from 'modules/module-reporting'
import { SupervisorSuite } from 'modules/redux-supervisor'

import * as actions from '../actions/actions'
import * as matchers from '../actions/matchers'
import heartbeat from '../heartbeat'
import { AnalyticsConfiguration, IAnalyticsIdentifyPayload } from '../model'
import * as selectors from '../selectors'
import serviceFactory from '../service/analytics-service-factory'
import { IAnalyticsService } from '../service/model'

export interface IAnalyticsSagaParams {
  service: IAnalyticsService
}

export class AnalyticsSaga extends SupervisorSuite {
  constructor(private service?: IAnalyticsService) {
    super()
  }

  @autobind
  *start(): SagaIterator {
    yield all([
      takeEvery(actions.CONFIGURE, this.configure),
      takeEvery(actions.IDENTIFY, this.identify),
      takeEvery(actions.CLEAN, this.clean),

      takeEvery(matchers.heartbeat, this.heartbeat),
      takeEvery(actions.FLUSH, this.flushEvents),
    ])
  }

  @autobind
  *configure({ payload }: Action<AnalyticsConfiguration>): SagaIterator {
    yield call(this.clean)

    this.service = yield call(serviceFactory.create, payload)

    if (this.service) {
      yield call(this.service.init)

      yield call(heartbeat.start)
    }
  }

  @autobind
  *identify({ payload }: Action<IAnalyticsIdentifyPayload>): SagaIterator {
    if (this.service) {
      try {
        yield call(this.service.identify, payload)
      } catch (error) {
        yield put(reporting.actions.error(error))
      }
    }
  }

  @autobind
  *clean(): SagaIterator {
    if (this.service) yield call(this.service.clean)
  }

  @autobind
  *flushEvents(): SagaIterator {
    yield call(heartbeat.beat)

    yield race({
      flush: take(actions.EVENTS_SENT),
      timeout: delay(500),
    })

    if (this.service) yield call(this.service.flush)
  }

  @autobind
  *heartbeat(heartbeatAction: HeartbeatAction): SagaIterator {
    try {
      yield all(heartbeatAction.payload.map((event) => call(this.track, event.action)))
    } catch (error) {
      yield put(reporting.actions.error(error))
    }

    yield put(actions.eventsSent())
  }

  @autobind
  *track(action: AnyAction): SagaIterator {
    if (this.service) {
      const properties = yield call(this.buildEventProperties, action)
      yield call(this.service.track, { event: action.meta.analytics.name, properties })
    }
  }

  @autobind
  *buildEventProperties(action: AnyAction): SagaIterator {
    const context = yield select(selectors.context)
    const data = matchers.isWhitelisted(action) ? action.meta.analytics.data : {}
    return { ...context, ...data }
  }
}

export default AnalyticsSaga
