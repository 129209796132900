import { EuiButton } from '@elastic/eui'
import { FormikConfig, useFormik } from 'formik'
import { useAppDispatch, useAppSelector, useValidations } from 'modules/module-utils'
import { Grid, IGridProps, useTranslate } from 'modules/web-atoms'
import { FieldPassword, FormRow } from 'modules/web-molecules'
import React from 'react'
import * as Yup from 'yup'
import { useCurrentRecipient } from '../../../hooks'
import profile from '../../../profile'
import { TabPanels } from '../RecipientProfilePage'

export interface IRecipientSecurityFormValues {
  currentPassword: string
  newPassword: string
  confirmNewPassword: string
}

export interface IRecipientSecurityTabProps extends IGridProps {}

export const RecipientSecurityTab: React.FC<IRecipientSecurityTabProps> = ({ ...rest }) => {
  const t = useTranslate(`recipient-portal.tabs.${TabPanels.SECURITY}`)
  const dispatch = useAppDispatch()

  const isLoading = useAppSelector(profile.selectors.isLoading)
  const validations = useValidations()
  const { recipientData, isFetching, error } = useCurrentRecipient()

  const validationSchema = React.useMemo(
    () =>
      Yup.object<IRecipientSecurityFormValues>().shape({
        currentPassword: Yup.string().required(t('validation.required')),
        newPassword: validations.password,
        confirmNewPassword: Yup.string().equals([Yup.ref('newPassword')], t('validation.does-not-match')),
      }),
    [t, validations.password],
  )

  const onSubmit: FormikConfig<IRecipientSecurityFormValues>['onSubmit'] = (values, { resetForm }) => {
    if (recipientData) {
      dispatch(
        profile.actions.changePassword({
          email: recipientData.email,
          oldPassword: values.currentPassword,
          newPassword: values.newPassword,
        }),
      )
      resetForm()
    }
  }

  const { values, touched, errors, handleChange, submitForm } = useFormik<IRecipientSecurityFormValues>({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    validationSchema,
    onSubmit,
  })

  return (
    <Grid mt="large" {...rest}>
      <FormRow label={t('form.current-password')} touched={touched.currentPassword} error={errors.currentPassword}>
        <FieldPassword
          value={values.currentPassword}
          name="currentPassword"
          type="dual"
          onChange={handleChange('currentPassword')}
          disabled={isFetching}
        />
      </FormRow>

      <FormRow label={t('form.new-password')} touched={touched.newPassword} error={errors.newPassword} mt="smallest">
        <FieldPassword
          value={values.newPassword}
          name="newPassword"
          type="dual"
          onChange={handleChange('newPassword')}
          disabled={isFetching}
        />
      </FormRow>

      <FormRow
        label={t('form.confirm-new-password')}
        touched={touched.confirmNewPassword}
        error={errors.confirmNewPassword}
        mt="smallest"
      >
        <FieldPassword
          value={values.confirmNewPassword}
          name="confirmNewPassword"
          type="dual"
          onChange={handleChange('confirmNewPassword')}
          disabled={isFetching}
        />
      </FormRow>

      <Grid.Row mt="medium">
        <EuiButton onClick={submitForm} color="primary" fill isLoading={isLoading} isDisabled={isFetching}>
          {t('change-password')}
        </EuiButton>
      </Grid.Row>
    </Grid>
  )
}

export default React.memo(RecipientSecurityTab)
