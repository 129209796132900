import axios, { AxiosInstance } from 'axios'
import { makeUseAxios } from 'axios-hooks'
import { logError } from 'modules/logging/Sentry'
import { refresh } from 'modules/module-core/api/core-api'
import {
  StorageError,
  accessTokenStore,
  buildingIdsStore,
  refreshTokenStore,
  roleStore,
  serverIdStore,
  userIdStore,
  usernameStore,
} from 'modules/module-security/storage'
import {
  REACT_APP_API,
  REACT_APP_AUTH_API,
  REACT_APP_AZURE_FUNCTIONS_API,
  REACT_APP_GLOBAL_API,
  REACT_APP_PM_API,
  REACT_APP_VERSION,
} from 'modules/utils/constants'
import { v4 as uuidv4 } from 'uuid'

const HEADER_PLATFORM = 'X-Client-Platform'
const HEADER_PLATFORM_VALUE = 'web-platform'
const HEADER_VERSION = 'X-Client-Version'
const HEADER_SERVER = 'X-Server-Id'
const HEADER_CORRELATION_ID = 'X-Correlation-Id'
const HEADER_CORRELATION_SESSION = 'X-Correlation-Session-Id'
const HEADER_CORRELATION_SESSION_VALUE = uuidv4()
const HEADER_CORRELATION_USER = 'X-Correlation-User-Id'
const HEADER_CORRELATION_DEVELOPMENT = 'X-Correlation-Development-Id'

let isAlreadyFetchingAccessToken = false

// This is the list of waiting requests that will retry after the JWT refresh complete
let subscribers: any[] = []

function onAccessTokenFetched(access_token) {
  subscribers.forEach((callback) => {
    callback(access_token)
  })
  subscribers = []
}

function addSubscriber(callback) {
  subscribers.push(callback)
}

async function handleFailedTokens() {
  await refreshTokenStore.clear()
  await accessTokenStore.clear()
  await usernameStore.clear()
  await roleStore.clear()
}

const resetTokenAndReattemptRequest = async (
  refreshToken: string,
  userId: string,
  originalRequest,
  api: AxiosInstance,
) => {
  try {
    const retryOriginalRequest = new Promise((resolve) => {
      addSubscriber((access_token) => {
        originalRequest.headers.Authorization = `Bearer ${access_token}`
        resolve(axios(originalRequest))
      })
    })

    if (!isAlreadyFetchingAccessToken) {
      isAlreadyFetchingAccessToken = true
      originalRequest._retry = true

      const refreshData = await refresh(refreshToken, userId)
      // Update tokens into localStorage
      if (refreshData) {
        await accessTokenStore.set(refreshData.accessToken)
        await refreshTokenStore.set(refreshData.refreshToken)

        // Update axios headers
        api.defaults.headers.common.Authorization = `Bearer ${refreshData.accessToken}`
        isAlreadyFetchingAccessToken = false

        onAccessTokenFetched(refreshData.accessToken)
      }
    }
    return retryOriginalRequest
  } catch (error) {
    await handleFailedTokens()
    return Promise.reject(error)
  }
}

class ApiFactory {
  static getApi(baseURL: string, includeAuthentication: boolean, includeServerId: boolean): AxiosInstance {
    const API = axios.create({
      baseURL,
      headers: {
        'Content-Type': 'application/json',
      },
    })

    // request interceptor to add token to request headers
    API.interceptors.request.use(
      async (config) => {
        const headers = await this.createHeaders(includeAuthentication, includeServerId)
        Object.keys(headers).forEach((header: string) => {
          config.headers[header] = headers[header]
        })
        return config
      },
      function (error) {
        logError(error)
        return error
      },
    )

    // Handle 401 responses
    API.interceptors.response.use(
      async (response) => {
        return Promise.resolve(response)
      },
      async function (error) {
        try {
          const originalRequest = error.config

          const email = await usernameStore.get()
          const refreshToken = await refreshTokenStore.get()
          const userId = await userIdStore.get()

          if (email && error.response.status === 401 && refreshToken && !originalRequest._retry) {
            return resetTokenAndReattemptRequest(refreshToken, userId, originalRequest, API)
          } else if (error.response.status === 401) {
            await handleFailedTokens()
          }
        } catch (err) {
          if (error == StorageError.NOT_EXIST) {
            await handleFailedTokens()
          }
        }
        return Promise.reject(error)
      },
    )

    return API
  }

  private static async createHeaders(
    includeAuthentication: boolean,
    includeServerId: boolean,
  ): Promise<{ [key: string]: string }> {
    let headers = {
      [HEADER_PLATFORM]: HEADER_PLATFORM_VALUE,
      [HEADER_VERSION]: REACT_APP_VERSION,
      [HEADER_CORRELATION_ID]: uuidv4(),
      [HEADER_CORRELATION_SESSION]: HEADER_CORRELATION_SESSION_VALUE,
    }

    if (includeAuthentication) {
      let token = '',
        userId = '',
        buildingIds = ''
      try {
        token = await accessTokenStore.get()
        userId = await userIdStore.get()
        buildingIds = await buildingIdsStore.get()
      } catch (error) {
        console.log(error)
      }
      headers = {
        ...headers,
        ...{
          Authorization: `Bearer ${token}`,
          [HEADER_CORRELATION_USER]: userId,
          [HEADER_CORRELATION_DEVELOPMENT]: buildingIds,
        },
      }
    }

    if (includeServerId) {
      const serverId = await serverIdStore.get()
      headers = { ...headers, ...{ [HEADER_SERVER]: serverId } }
    }

    return headers
  }
}

// Client for authenticated endpoints
export const GlobalAPI = ApiFactory.getApi(REACT_APP_GLOBAL_API, false, false)
export const AuthAPI = ApiFactory.getApi(REACT_APP_AUTH_API, false, true)
export const API = ApiFactory.getApi(REACT_APP_API, true, true)
export const PlanManagementAPI = ApiFactory.getApi(REACT_APP_PM_API, true, true)
export const AzureFunctionsAPI = ApiFactory.getApi(REACT_APP_AZURE_FUNCTIONS_API, true, true)

export const useAxios = makeUseAxios({ axios: API, cache: false })

export default API
