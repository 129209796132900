import * as React from 'react'

import {
  EuiButtonEmpty,
  EuiContextMenuItem,
  EuiContextMenuPanel,
  EuiI18n,
  EuiPagination,
  EuiPopover,
} from '@elastic/eui'

import { Grid } from 'modules/web-atoms'

export type PageChangeHandler = (pageIndex: number) => void
export type ItemsPerPageChangeHandler = (pageSize: number) => void

export interface ITablePaginationProps extends React.ComponentProps<typeof EuiPagination> {
  activePage?: number
  hidePerPageOptions?: boolean
  itemsPerPage?: number
  itemsPerPageOptions?: number[]
  onChangeItemsPerPage?: ItemsPerPageChangeHandler
  onChangePage?: PageChangeHandler
  pageCount?: number
}

export const TablePagination: React.FC<ITablePaginationProps> = (props) => {
  const {
    activePage,
    itemsPerPage = 50,
    itemsPerPageOptions = [10, 20, 50, 100],
    hidePerPageOptions = false,
    onChangeItemsPerPage = () => {},
    onChangePage,
    pageCount,
    ...rest
  } = props

  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false)

  const onButtonClick = React.useCallback(() => {
    setIsPopoverOpen(!isPopoverOpen)
  }, [isPopoverOpen])

  const closePopover = () => {
    setIsPopoverOpen(false)
  }

  const button = React.useMemo(
    () => (
      <EuiButtonEmpty
        size="xs"
        color="text"
        iconType="arrowDown"
        iconSide="right"
        data-test-subj="tablePaginationPopoverButton"
        onClick={onButtonClick}
      >
        <EuiI18n token="euiTablePagination.rowsPerPage" default="Rows per page" />: {itemsPerPage}
      </EuiButtonEmpty>
    ),
    [itemsPerPage, onButtonClick],
  )

  const items = itemsPerPageOptions.map((itemsPerPageOption) => (
    <EuiContextMenuItem
      key={itemsPerPageOption}
      icon={itemsPerPageOption === itemsPerPage ? 'check' : 'empty'}
      onClick={() => {
        closePopover()
        onChangeItemsPerPage(itemsPerPageOption)
      }}
      data-test-subj={`tablePagination-${itemsPerPageOption}-rows`}
    >
      <EuiI18n
        token="euiTablePagination.rowsPerPageOption"
        values={{ rowsPerPage: itemsPerPageOption }}
        default="{rowsPerPage} rows"
      />
    </EuiContextMenuItem>
  ))

  const itemsPerPagePopover = (
    <EuiPopover
      button={button}
      isOpen={isPopoverOpen}
      closePopover={closePopover}
      panelPaddingSize="none"
      anchorPosition="upRight"
    >
      <EuiContextMenuPanel items={items} />
    </EuiPopover>
  )

  return (
    <Grid flexGrow={0} justifyContent="space-between" alignItems="center">
      <Grid.Col>{hidePerPageOptions ? null : itemsPerPagePopover}</Grid.Col>
      <Grid.Col>
        <EuiPagination pageCount={pageCount} activePage={activePage} onPageClick={onChangePage} {...rest} />
      </Grid.Col>
    </Grid>
  )
}

export default React.memo(TablePagination)
