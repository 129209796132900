import security from 'modules/module-security'
import { useAppSelector } from 'modules/module-utils'
import { H1, Page, useTranslate } from 'modules/web-atoms'
import { IPanelProps, ITabItem, Panel, Tabs } from 'modules/web-molecules'
import React, { useMemo } from 'react'
import { SettingsTabPaths } from '../../paths'
import CouriersTab from './CouriersTab/CouriersTab'
import DataTab from './DataTab/DataTab'
import DropoffLocationsTab from './DropoffLocationsTab/DropoffLocationsTab'
import FeaturesTab from './FeaturesTab/FeaturesTab'
import LocationsTab from './LocationsTab/LocationsTab'
import SizeTab from './SizesTab/SizesTab'
import TagsTab from './TagsTab/TagsTab'

type ISettingsPageProps = IPanelProps

export const SettingsPage: React.FC<ISettingsPageProps> = ({ ...rest }) => {
  const t = useTranslate('settings')
  const featureFlags = useAppSelector(security.selectors.featureFlags)

  const tabs = useMemo<ITabItem[]>(() => {
    const tabs: ITabItem[] = [
      {
        id: 'data',
        path: SettingsTabPaths.DATA,
        label: t('tabs.data'),
        content: <DataTab />,
      },
      {
        id: 'features',
        path: SettingsTabPaths.FEATURES,
        label: t('tabs.features'),
        content: <FeaturesTab />,
      },
      {
        id: 'tags',
        path: SettingsTabPaths.TAGS,
        label: t('tabs.tags'),
        content: <TagsTab />,
      },
      {
        id: 'locations',
        path: SettingsTabPaths.LOCATIONS,
        label: t('tabs.locations'),
        content: <LocationsTab />,
      },
      {
        id: 'couriers',
        path: SettingsTabPaths.COURIERS,
        label: t('tabs.couriers'),
        content: <CouriersTab />,
      },
      {
        id: 'sizes',
        path: SettingsTabPaths.SIZES,
        label: t('tabs.sizes'),
        content: <SizeTab />,
      },
    ]
    if (featureFlags?.dropoffLocationsEnabled) {
      tabs.push({
        id: 'dropoff-locations',
        path: SettingsTabPaths.DROPOFF_LOCATIONS,
        label: t('tabs.dropoff-locations'),
        content: <DropoffLocationsTab />,
      })
    }

    return tabs
  }, [featureFlags?.dropoffLocationsEnabled, t])

  return (
    <Page title={t('title')}>
      <Panel
        display="flex"
        flexDirection="column"
        height={['100%', '95vh']}
        px={['small', 'large']}
        py="large"
        {...rest}
      >
        <H1 color="textDark" fontWeight="semiBold" fontSize="xxxlarge">
          {t('heading')}
        </H1>

        <Tabs tabs={tabs} mt="small" />
      </Panel>
    </Page>
  )
}

export default React.memo(SettingsPage)
