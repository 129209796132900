import { EuiBadge } from '@elastic/eui'
import { useTheme } from '@emotion/react'
import styled, { CSSObject } from '@emotion/styled'
import css from '@styled-system/css'
import { ResponsiveColor } from 'modules/dna'
import React from 'react'
import Grid from '../../Grid/Grid'
import Button from '../../buttons/Button/Button'
import { IButtonFrameProps } from '../../buttons/ButtonFrame/ButtonFrame'
import { H9 } from '../../typography/Heading/Heading'
import Paragraph from '../../typography/Paragraph/Paragraph'
import { IRadioButtonProps } from '../types'

interface IBaseProps {
  label: string
  description: string
  badge?: string
}

export type IRadioSimpleProps = IRadioButtonProps & IButtonFrameProps & IBaseProps

interface IRadioStyle {
  container: {
    borderColor: ResponsiveColor
  }
  radio: {
    backgroundColor: ResponsiveColor
  }
}

const RADIO_STYLE = new Map<boolean, IRadioStyle>([
  [
    true,
    {
      radio: { backgroundColor: 'primaryMid' },
      container: { borderColor: 'textDark' },
    },
  ],
  [
    false,
    {
      radio: { backgroundColor: 'secondaryMid' },
      container: { borderColor: 'secondaryMid' },
    },
  ],
])

const StyledButton = styled(Button.Frame)(
  css({
    display: 'flex',
    width: '100%',
    height: '100%',
    borderRadius: 'large',
    boxShadow: 'small',
    backgroundColor: 'primaryBackground',
    justifyContent: 'center',
    alignItems: 'flex-start',
    borderStyle: 'solid',
    borderWidth: 'medium',
    boxSizing: 'border-box',

    '&:focus': {
      backgroundColor: 'secondaryMid',
    },
  } as CSSObject),
)

export const RadioSimple: React.FC<IRadioSimpleProps> = ({
  id,
  selected,
  isSelected,
  children,
  onClick,
  onPressIn,
  onPressOut,
  label,
  description,
  badge,
  ...rest
}) => {
  const { radio, container } = React.useMemo(() => {
    return RADIO_STYLE.get(selected ?? false)!!
  }, [selected])
  const { colors } = useTheme()

  const handlers = {
    onClick: React.useCallback(() => onClick?.(id), [id, onClick]),
    onMouseDown: React.useCallback(() => onPressIn?.(id), [id, onPressIn]),
    onMouseUp: React.useCallback(() => onPressOut?.(id), [id, onPressOut]),
  }

  return (
    <Grid aria-label="radio" height="100%">
      <StyledButton flexGrow={1} tabIndex={0} {...rest} {...container} {...handlers} aria-label={label}>
        <Grid px="small" gutterSize="smallest">
          <Grid.Col>
            <Grid borderRadius="round" width={[23, 25]} height={[23, 25]} {...radio} />
          </Grid.Col>

          <Grid.Col>
            <Grid gutterSize="smallest">
              <Grid gutterSize="smallest">
                <Grid.Col>
                  <H9 fontWeight="bold" color="textDark" fontSize="medium">
                    {label}
                  </H9>
                </Grid.Col>
                {badge && (
                  <Grid.Col>
                    <EuiBadge color={colors.secondaryMid}>{badge}</EuiBadge>
                  </Grid.Col>
                )}
              </Grid>

              <Paragraph color="textLightMid" textAlign="left">
                {description}
              </Paragraph>
            </Grid>
          </Grid.Col>
        </Grid>
      </StyledButton>
    </Grid>
  )
}

export default React.memo(RadioSimple)
