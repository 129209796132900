export { default as Permissions } from './permissions/Permissions'
export * from './screens'

import reducer from './root-reducer'
import usersSaga from './sagas'
import user from './user'

export const users = {
  ...user,
  sagas: usersSaga,
  reducer: reducer,
}

export default users
