import * as React from 'react'

import { EuiBadge, EuiBadgeProps } from '@elastic/eui'
import styled from '@emotion/styled'

import * as system from 'styled-system'
import { StyledViewProps } from '../Grid/StyledView'

export type IBadgeProps = Omit<StyledViewProps, 'color'> & EuiBadgeProps

const StyledBadge = styled(EuiBadge)<IBadgeProps>(
  system.color,
  system.flexbox,
  system.space,
  system.layout,
  system.position,
  system.justifyContent,
  system.alignItems,
  system.borders,
  system.position,
)

export const Badge: React.FC<IBadgeProps> = (props) => {
  const { children, ...rest } = props

  return <StyledBadge {...rest}>{children}</StyledBadge>
}

Badge.displayName = 'Badge'

export default React.memo(Badge)
