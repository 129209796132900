import { API } from 'api'
import { plainToInstance } from 'class-transformer'
import { QueryKey, useApi } from 'modules/module-api'
import security from 'modules/module-security'
import { useAppSelector } from 'modules/module-utils'
import React from 'react'
import { ITagItemDto } from '../../api/dto'

const useTags = () => {
  const clientId = useAppSelector(security.selectors.clientId)

  const { data, isFetching, isLoading, error } = useApi<ITagItemDto[]>(
    [QueryKey.FETCH_TAGS, clientId],
    async () => {
      if (clientId) {
        const response = await API({
          url: '/v3/parcel-tags',
          params: {
            client_id: clientId,
          },
        })
        return response.data
      }
    },
    {
      keepPreviousData: true,
    },
  )

  const tags = React.useMemo(() => (data ? plainToInstance(ITagItemDto, data) : []), [data])
  return { tags, isFetching, isLoading, error }
}

export default useTags
