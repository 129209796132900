import React, { useCallback, useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'

export interface IWindowSize {
  height: number
  width: number
}

const LARGE_HEIGHT = 1500
const MEDIUM_HEIGHT = 1200
const SMALL_HEIGHT = 800

export enum VerticalHeight {
  LARGE,
  MEDIUM,
  SMALL,
}

export const useScreen = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' })
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1224 })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isDesktop = useMediaQuery({ minDeviceWidth: 1224 })
  const isLaptop = useMediaQuery({ query: '(min-width: 1024px) and (max-width: 1500px)' })

  const [windowSize, setWindowSize] = useState<IWindowSize>({ height: window.innerHeight, width: window.innerWidth })
  const [verticalHeight, setVerticalHeight] = useState<VerticalHeight>(VerticalHeight.LARGE)

  React.useEffect(() => {
    let height: VerticalHeight
    switch (true) {
      case windowSize.height <= SMALL_HEIGHT:
        height = VerticalHeight.SMALL
        break
      case windowSize.height <= MEDIUM_HEIGHT:
        height = VerticalHeight.MEDIUM
        break
      case windowSize.height <= LARGE_HEIGHT:
        height = VerticalHeight.LARGE
        break
      default:
        height = VerticalHeight.LARGE
        break
    }
    setVerticalHeight(height)
  }, [windowSize.height])

  const handleWindowSizeChange = useCallback(() => {
    setWindowSize({ height: window.innerHeight, width: window.innerWidth })
  }, [])

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
  }, [handleWindowSizeChange])

  return React.useMemo(() => {
    return { windowSize, isMobile, isTablet, isTabletOrMobile, isDesktop, isLaptop, verticalHeight }
  }, [isDesktop, isMobile, isLaptop, isTablet, isTabletOrMobile, windowSize, verticalHeight])
}

export default useScreen
