import { UploadScreen } from './flow'

export interface IUploadState {
  currentScreen: UploadScreen
  loading: boolean
}

export const initialState: IUploadState = {
  currentScreen: UploadScreen.UploadFile,
  loading: false,
}

export type INavigateUploadPayload = string
