/* eslint-disable no-undef */
import { createAction } from 'redux-actions'

import analytics, { AnalyticalEvent } from 'modules/module-analytics'
import { identityFor } from 'modules/utils'
import prefix from 'modules/utils/prefix'

import { INavigateUploadPayload } from './'

export const namespaced = prefix('dashboard')

export const NAVIGATE_UPLOAD = namespaced('navigateUpload')
export const navigateUpload = createAction<INavigateUploadPayload>(NAVIGATE_UPLOAD)

export const LOADING = namespaced('loading')
export const loading = createAction<boolean>(LOADING)

export const ERROR = namespaced('error')
export const error = createAction(ERROR, identityFor<Error>(), (payload) =>
  analytics.meta.whitelist({
    data: { message: payload.message },
    name: AnalyticalEvent.Error,
  }),
)

type Action = typeof navigateUpload | typeof loading | typeof error

export default Action
