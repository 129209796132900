import reducer from './root-reducer'
import accountSagas from './sagas'
import user from './user'

export const account = {
  actions: { user: user.actions },
  reducer,
  sagas: accountSagas,
}

export default account
