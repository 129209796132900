import * as React from 'react'

import { EuiPanel } from '@elastic/eui'
import styled from '@emotion/styled'
import shouldForwardProp from '@styled-system/should-forward-prop'

import { StyledViewProps } from 'modules/web-atoms'
import * as system from 'styled-system'

export type IPanelProps = StyledViewProps & React.ComponentProps<typeof EuiPanel>

export const StyledPanel = styled(EuiPanel, { shouldForwardProp })<IPanelProps>(
  system.color,
  system.flexbox,
  system.space,
  system.layout,
  system.position,
  system.justifyContent,
  system.alignItems,
  system.borders,
  system.shadow,
  system.background,
)

export const Panel: React.FC<IPanelProps> = (props) => {
  const { children, ...rest } = props
  return <StyledPanel {...rest}>{children}</StyledPanel>
}

export default React.memo(Panel)
