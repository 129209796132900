import { EuiPage } from '@elastic/eui'
import styled from '@emotion/styled'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import * as system from 'styled-system'
import { StyledViewProps } from '../Grid/StyledView'

const StyledPage = styled(EuiPage)<StyledViewProps>(
  system.color,
  system.flexbox,
  system.space,
  system.layout,
  system.position,
  system.justifyContent,
  system.alignItems,
  system.borders,
  system.position,
)

export type StyledPageProps = React.ComponentProps<typeof StyledPage>

export type IPageProps = StyledPageProps

export const Page: React.FC<IPageProps> = (props) => {
  const { title, children, ...rest } = props
  const fullTitle = `${title} - Parcel Tracker`

  return (
    <StyledPage id={fullTitle} title={fullTitle} grow={true} paddingSize="none" backgroundColor="none" {...rest}>
      <Helmet>
        <meta name="description" content={fullTitle} />
        <title>{fullTitle}</title>
      </Helmet>
      {children}
    </StyledPage>
  )
}

Page.displayName = 'Page'

export default React.memo(Page)
