import * as React from 'react'

import { IAssignedUserDto } from 'api'
import API from 'api/clients'
import { plainToInstance } from 'class-transformer'
import { QueryKey } from 'modules/module-api'
import useApi from 'modules/module-api/hooks/useApi/use-api'
import { useUsers } from 'modules/module-utils/hooks'

function useAssignedUsers(buildingId?: string) {
  const { users: allUsers, isFetching: isFetchingAllUsers, error: allUsersError } = useUsers()

  const {
    data,
    isFetching: isFetchingAssignedUsers,
    refetch: refetchAssignedUsers,
    error: assignedError,
  } = useApi<IAssignedUserDto[]>(
    [QueryKey.ASSIGNED_USERS, buildingId],
    async () => {
      const response = await API({
        method: 'get',
        url: `/v3/portal/developments/assigned-users`,
        params: {
          development_id: buildingId,
        },
      })
      return response.data
    },
    {
      enabled: false,
    },
  )

  React.useEffect(() => {
    !!buildingId && refetchAssignedUsers()
  }, [buildingId, refetchAssignedUsers])

  const assignedUsers: IAssignedUserDto[] = React.useMemo(() => {
    return data ? plainToInstance(IAssignedUserDto, data) : []
  }, [data])

  return { allUsers, assignedUsers, isFetchingAllUsers, isFetchingAssignedUsers, error: allUsersError && assignedError }
}

export default useAssignedUsers
