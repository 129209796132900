import { AnyAction } from 'redux'
import { ActionMeta } from 'redux-actions'
import { HEARTBEAT_ACTION_TYPE, HeartbeatAction } from 'redux-heartbeat'

import { HEARTBEAT_NAME, IWhitelistMetaShape } from '../model'

export const heartbeat = (action: AnyAction): action is HeartbeatAction =>
  action.type === HEARTBEAT_ACTION_TYPE && action.meta?.name === HEARTBEAT_NAME

export const isWhitelisted = <P = unknown>(action: AnyAction): action is ActionMeta<P, IWhitelistMetaShape> =>
  !!(action as ActionMeta<P, IWhitelistMetaShape>).meta?.analytics
