import autobind from 'autobind-decorator'
import { reporter } from 'modules/module-reporting'

import { IAnalyticsService, IIdentityParams, ITrackParams } from '../model'
import { clarity } from 'react-microsoft-clarity'
import { CLARITY_ANALYTICS_ID } from 'modules/utils/constants'

export class ClarityService implements IAnalyticsService {
  async init() {
    clarity.init(CLARITY_ANALYTICS_ID)
    clarity.consent()
  }

  async identify({ userId, clientId, email }: IIdentityParams): Promise<void> {
    if (clarity.hasStarted()) {
      clarity.identify('USER_ID', { userProperty: userId })
      if (clientId != undefined) clarity.identify('client', { userProperty: clientId.toString() })
      if (email != undefined) clarity.identify('email', { userProperty: email.toString() })
    }
  }

  @autobind
  async track({ event, properties }: ITrackParams) {
    try {
      if (properties) {
        clarity.setTag(event, JSON.stringify(flatten(properties)))
      } else {
        clarity.setTag(event, '')
      }
    } catch (error) {
      await reporter.error(error as any, {})
    }
  }

  async flush(): Promise<void> {}

  async clean(): Promise<void> {}
}

const flatten = (obj, prefix = '', res = {}) =>
  Object.entries(obj).reduce((r, [key, val]) => {
    const k = `${prefix}${key}`
    if (typeof val === 'object') {
      flatten(val, `${k}.`, r)
    } else {
      res[k] = val
    }
    return r
  }, res)

export default ClarityService
