import { shallowMerge } from 'modules/utils'
import createCachedSelector from 're-reselect'
import { createSelector, Selector } from 'reselect'

import { BRANCH_KEY, IErrorState, IReportingOwnState } from './model'

export const branch: Selector<IReportingOwnState, IErrorState> = (state) => state[BRANCH_KEY].errors

export const context = createSelector(branch, (branch) => branch.context)

const cachedTags = createCachedSelector<
  IReportingOwnState,
  JSONPrimitiveObject | undefined,
  JSONPrimitiveObject,
  JSONPrimitiveObject | undefined,
  JSONPrimitiveObject
>(
  context,
  (_, additional) => additional,
  (context, additional) => shallowMerge(context, additional),
)((_, additional = {}) => JSON.stringify(additional))

export const tags = (state: IReportingOwnState, additional?: JSONPrimitiveObject) => cachedTags(state, additional)
