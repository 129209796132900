import * as React from 'react'

import styled from '@emotion/styled'
import shouldForwardProp from '@styled-system/should-forward-prop'

import { IDeepfinityTheme, whitespaceDecoration } from 'modules/dna'
import * as system from 'styled-system'

export type StyledTextProps = system.ColorProps<IDeepfinityTheme> &
  system.SpaceProps<IDeepfinityTheme> &
  system.TypographyProps<IDeepfinityTheme> &
  system.LayoutProps<IDeepfinityTheme> &
  system.FlexboxProps<IDeepfinityTheme> &
  system.PositionProps<IDeepfinityTheme> & { whiteSpace?: string }

const StyledSpan: React.ComponentType<StyledTextProps> = styled('span', { shouldForwardProp })(
  system.color,
  system.space,
  system.typography,
  system.layout,
  system.system({
    textDecorationLine: true,
    textTransform: true,
  }),
  system.flexbox,
  whitespaceDecoration,
)

export type ISpanProps = React.ComponentProps<typeof StyledSpan>

export const Span: React.FunctionComponent<ISpanProps> = ({ children, ...rest }) => {
  return <StyledSpan {...rest}>{children}</StyledSpan>
}

Span.displayName = 'Span'

export default Span
