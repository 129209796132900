import billing from 'modules/module-billing'
import deliveries, { DeliveriesPath } from 'modules/module-deliveries'
import notifications from 'modules/module-notifications'
import { SettingsTabPaths } from 'modules/module-settings'
import { StatisticsPath } from 'modules/module-statistics/navigation/routes'
import statistics from 'modules/module-statistics/statistics'
import { useAppSelector } from 'modules/module-utils'
import { IconType } from 'modules/web-atoms'
import React from 'react'
import { PortalPath } from '../../routes/models'

export enum Condition {
  NO_MANUAL_OVERRIDE = 'no-manual-override',
  HIDE_FOR_RECIPIENT = 'hide-for-recipient',
}

export interface ISideMenuOption {
  name: string
  path: string
  icon: IconType
  onboarding?: boolean
  subMenu?: ISideMenuOption[]
  restrictAccess?: boolean
  conditions?: Condition[]
}

const useSideMenuRoutes = () => {
  const { canViewOutbound, canViewParcels } = useAppSelector(deliveries.selectors.permissions)
  const { canViewOutboundStats } = useAppSelector(statistics.selectors.permissions)
  const { canViewBilling } = useAppSelector(billing.selectors.permissions)
  const { canViewNotifications } = useAppSelector(notifications.selectors.permissions)

  const userMenus: ISideMenuOption[] = React.useMemo(
    () => [
      {
        name: 'dashboard',
        path: PortalPath.GET_STARTED,
        icon: IconType.MenuDashboard,
        onboarding: true,
      },
      {
        name: 'residents',
        path: PortalPath.RESIDENTS_DIRECTORY,
        icon: IconType.MenuRecipients,
      },
      {
        name: 'deliveries',
        path: '/portal/deliveries',
        icon: IconType.MenuDeliveries,
        restrictAccess: !canViewParcels && !canViewOutbound,
        subMenu: [
          {
            name: 'inbound',
            path: DeliveriesPath.INBOUND_DIRECTORY,
            icon: IconType.MenuDeliveriesInbound,
            restrictAccess: !canViewParcels,
          },
          {
            name: 'outbound',
            path: DeliveriesPath.OUTBOUND_DIRECTORY,
            icon: IconType.MenuDeliveriesOutbound,
            restrictAccess: !canViewOutbound,
          },
        ],
      },
      {
        name: 'statistics',
        path: '/portal/statistics',
        icon: IconType.MenuStatistics,
        subMenu: [
          {
            name: 'inbound',
            path: StatisticsPath.INBOUND_STATS,
            icon: IconType.MenuStatistics,
          },
          {
            name: 'outbound',
            path: StatisticsPath.OUTBOUND_STATS,
            icon: IconType.MenuStatistics,
            restrictAccess: !canViewOutboundStats,
          },
        ],
      },
      {
        name: 'users',
        path: PortalPath.USERS_MANAGEMENT,
        icon: IconType.MenuUsers,
      },
      {
        name: 'sites',
        path: PortalPath.BUILDINGS_MANAGEMENT,
        icon: IconType.MenuBuildings,
      },
      {
        name: 'notifications',
        path: PortalPath.NOTIFICATIONS,
        restrictAccess: !canViewNotifications,
        icon: IconType.MenuNotifications,
      },
      {
        name: 'settings',
        path: `${PortalPath.SETTINGS}/${SettingsTabPaths.DATA}`,
        icon: IconType.MenuSettings,
      },
      {
        name: 'billing',
        path: PortalPath.BILLING,
        icon: IconType.MenuBilling,
        restrictAccess: !canViewBilling,
        conditions: [Condition.NO_MANUAL_OVERRIDE],
      },
    ],
    [canViewParcels, canViewOutbound, canViewOutboundStats, canViewNotifications, canViewBilling],
  )

  const tenantMenus: ISideMenuOption[] = React.useMemo(
    () => [
      {
        name: 'deliveries',
        path: '/portal/deliveries',
        icon: IconType.MenuDeliveries,
        subMenu: [
          {
            name: 'inbound',
            path: DeliveriesPath.INBOUND_DIRECTORY,
            icon: IconType.MenuDeliveriesInbound,
          },
          {
            name: 'outbound',
            path: DeliveriesPath.OUTBOUND_DIRECTORY,
            icon: IconType.MenuDeliveriesOutbound,
            restrictAccess: !canViewOutbound,
          },
        ],
      },
    ],
    [canViewOutbound],
  )

  return { userMenus, tenantMenus }
}

export default useSideMenuRoutes
