import { EuiTabbedContent, EuiTabbedContentTab } from '@elastic/eui'
import { IPageProps, Page, useTranslate } from 'modules/web-atoms'
import { Panel } from 'modules/web-molecules'
import React from 'react'
import EditRecipientInfoTab from './EditRecipientInfoTab/EditRecipientInfoTab'
import RecipientGDPRTab from './RecipientGDPRTab/RecipientGDPRTab'
import RecipientSecurityTab from './RecipientSecurityTab/RecipientSecurityTab'
import RecipientSettingsTab from './RecipientSettingsTab/RecipientSettingsTab'

export enum TabPanels {
  MY_INFORMATION = 'my-information',
  SECURITY = 'security',
  GDPR = 'gdpr',
  SETTING = 'setting',
}

export interface IRecipientProfilePageProps extends IPageProps {}

export const RecipientProfilePage: React.FC<IRecipientProfilePageProps> = ({ ...rest }) => {
  const t = useTranslate('recipient-portal')
  const [activeTab, setActiveTab] = React.useState<TabPanels>(TabPanels.MY_INFORMATION)

  const tabs: EuiTabbedContentTab[] = React.useMemo(
    () => [
      {
        id: TabPanels.MY_INFORMATION,
        name: t(`tabs.${TabPanels.MY_INFORMATION}`),
        content: <EditRecipientInfoTab />,
      },
      {
        id: TabPanels.SECURITY,
        name: t(`tabs.${TabPanels.SECURITY}`),
        content: <RecipientSecurityTab />,
      },
      {
        id: TabPanels.SETTING,
        name: t(`tabs.${TabPanels.SETTING}`),
        content: <RecipientSettingsTab />,
      },
      {
        id: TabPanels.GDPR,
        name: t(`tabs.${TabPanels.GDPR}`),
        content: <RecipientGDPRTab />,
      },
    ],
    [t],
  )

  const onTabChange = (selectedTab: EuiTabbedContentTab) => {
    setActiveTab(selectedTab.id as TabPanels)
  }

  return (
    <Page title={t('title')} {...rest}>
      <Panel px="large">
        <EuiTabbedContent initialSelectedTab={tabs[0]} tabs={tabs} color="primary" onTabClick={onTabChange} />
      </Panel>
    </Page>
  )
}

export default React.memo(RecipientProfilePage)
