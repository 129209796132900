import React from 'react'
import { Grid, H1, IGridProps, useScreen, useTranslate } from 'modules/web-atoms'

const VIDEO_URL = 'https://www.youtube.com/embed/b9UtOpw0uQ4'

export interface IWatchVideoStepProps extends IGridProps {}

export const WatchVideoStep: React.FC<IWatchVideoStepProps> = ({ ...rest }) => {
  const t = useTranslate('onboarding.signup.onboarding.watch-video-step')
  const { isMobile } = useScreen()

  return (
    <Grid gutterSize="small" {...rest}>
      <H1 textAlign="center">{t('heading')}</H1>

      <iframe
        width={isMobile ? 320 : 640}
        height={isMobile ? 180 : 360}
        src={VIDEO_URL}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Youtube Video"
      />
    </Grid>
  )
}

export default WatchVideoStep
