import React from 'react'
import { useTranslate } from 'modules/web-atoms'
import DropoffLocationsTable from './DropoffLocationsTable'
import DropoffLocationForm from './DropoffLocationForm'
import { useAppSelector } from 'modules/module-utils'
import settings from '../../../settings'
import SettingsTableFormTemplate, {
  ISettingsTableFormTemplateProps,
} from 'modules/module-settings/components/SettingsTableFormTemplate/SettingsTableFormTemplate'

export interface IDropoffLocationsTabProps extends Partial<ISettingsTableFormTemplateProps> {}

const DropoffLocationsTab: React.FC<IDropoffLocationsTabProps> = ({ ...rest }) => {
  const t = useTranslate('settings.tabs.dropoff-locations')
  const isFormVisible = useAppSelector(settings.selectors.isDropoffLocationFormVisible)
  const { canManageDropoffLocations } = useAppSelector(settings.selectors.permissions)

  return (
    <SettingsTableFormTemplate
      heading={t('heading')}
      description={t('description')}
      isFormVisible={isFormVisible}
      hasManagePermissions={canManageDropoffLocations}
      {...rest}
    >
      <SettingsTableFormTemplate.Table>
        <DropoffLocationsTable />
      </SettingsTableFormTemplate.Table>

      <SettingsTableFormTemplate.Form>
        <DropoffLocationForm />
      </SettingsTableFormTemplate.Form>
    </SettingsTableFormTemplate>
  )
}

export default React.memo(DropoffLocationsTab)
