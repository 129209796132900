import SettingsTabTemplate, {
  ISettingsTabTemplateProps,
} from '../../../components/SettingsTabTemplate/SettingsTabTemplate'
import React from 'react'
import { Grid, useScreen, useTranslate } from 'modules/web-atoms'
import LocationsTable from './LocationsTable'
import LocationForm from './LocationForm'
import { useAppSelector } from 'modules/module-utils'
import settings from '../../../settings'

export interface ILocationsTabProps extends Partial<ISettingsTabTemplateProps> {}

const LocationsTab: React.FC<ILocationsTabProps> = ({ ...rest }) => {
  const t = useTranslate('settings.tabs.locations')
  const { isMobile } = useScreen()
  const isFormVisible = useAppSelector(settings.selectors.isLocationFormVisible)
  const { canManageLocations } = useAppSelector(settings.selectors.permissions)

  return (
    <SettingsTabTemplate
      heading={t('heading')}
      description={t('description')}
      flexGrow={1}
      height="100%"
      minHeight={0}
      {...rest}
    >
      <SettingsTabTemplate.Section flexGrow={1} minHeight={0}>
        <Grid flexGrow={1} minHeight={0} gutterSize={['none', 'large', 'large', 'xxlarge']}>
          {!(isMobile && isFormVisible) && (
            <Grid.Col>
              <LocationsTable />
            </Grid.Col>
          )}

          {canManageLocations && isFormVisible && (
            <Grid.Col width="100%">
              <LocationForm />
            </Grid.Col>
          )}
        </Grid>
      </SettingsTabTemplate.Section>
    </SettingsTabTemplate>
  )
}

export default React.memo(LocationsTab)
