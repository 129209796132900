import { ITenantDto } from 'api'
import API from 'api/clients'
import { plainToClass } from 'class-transformer'
import { QueryKey } from 'modules/module-api'
import useApi from 'modules/module-api/hooks/useApi/use-api'
import { useEffect, useMemo } from 'react'

function useFetchRecipient(id: string | undefined) {
  const { data, isFetching, error, refetch } = useApi<ITenantDto>(
    [QueryKey.FETCH_RECIPIENT, id],
    async () => {
      const response = await API({
        url: '/v3/tenants',
        params: {
          id,
        },
      })
      return response.data
    },
    {
      enabled: false,
    },
  )

  const recipientData = useMemo(() => plainToClass(ITenantDto, data), [data])

  useEffect(() => {
    id && refetch()
  }, [id, refetch])

  return { data: recipientData, isFetching, error }
}

export default useFetchRecipient
