import SettingsTabTemplate, {
  ISettingsTabTemplateProps,
} from '../../../components/SettingsTabTemplate/SettingsTabTemplate'
import { Grid, useTranslate } from 'modules/web-atoms'
import React from 'react'
import SettingsToggle from '../../../components/SettingsToggle/SettingsToggle'
import security from 'modules/module-security'
import { useActiveBuildingsIds, useAppDispatch, useAppSelector } from 'modules/module-utils'
import settings, { FeatureFlag } from '../../../settings'
import { EuiSwitchEvent } from '@elastic/eui'

export interface IFeaturesTabProps extends Partial<ISettingsTabTemplateProps> {}

const FeaturesTab: React.FC<IFeaturesTabProps> = ({ ...rest }) => {
  const t = useTranslate('settings.tabs.features')
  const dispatch = useAppDispatch()
  const featureFlags = useAppSelector(security.selectors.featureFlags)
  const permissions = useAppSelector(settings.selectors.permissions)
  const flagLoading = useAppSelector(settings.selectors.flagLoading)
  const { buildingIds } = useActiveBuildingsIds()
  const isToggleDisabled = !permissions.canEditFeatureFlags || flagLoading !== undefined

  const onFlagChange = (flag: FeatureFlag) => (event: EuiSwitchEvent) => {
    const { checked } = event.target
    dispatch(
      settings.actions.setFeatureFlag({
        flag,
        value: checked,
        activeBuildings: buildingIds,
      }),
    )
  }

  return (
    <SettingsTabTemplate heading={t('heading')} description={t('description')} {...rest}>
      <SettingsTabTemplate.Section maxWidth={350}>
        <Grid gutterSize="large">
          {featureFlags !== undefined && !featureFlags.multimailroomEnabled && (
            <SettingsToggle
              isChecked={featureFlags?.outboundEnabled ?? false}
              label={t('outbound.label')}
              description={t('outbound.description')}
              isLoading={flagLoading === FeatureFlag.OUTBOUND}
              onChange={onFlagChange(FeatureFlag.OUTBOUND)}
              isDisabled={isToggleDisabled}
            />
          )}
          <SettingsToggle
            isChecked={featureFlags?.collectionEnabled ?? false}
            label={t('collection.label')}
            description={t('collection.description')}
            isLoading={flagLoading === FeatureFlag.COLLECTION}
            onChange={onFlagChange(FeatureFlag.COLLECTION)}
            isDisabled={isToggleDisabled}
          />
          <SettingsToggle
            isChecked={featureFlags?.batchCollectionEnabled ?? false}
            label={t('batch-collection.label')}
            description={t('batch-collection.description')}
            isLoading={flagLoading === FeatureFlag.BATCH_COLLECTION}
            onChange={onFlagChange(FeatureFlag.BATCH_COLLECTION)}
            isDisabled={isToggleDisabled}
          />
          <SettingsToggle
            isChecked={featureFlags?.additionalParcelDetails ?? false}
            label={t('additional.label')}
            description={t('additional.description')}
            isLoading={flagLoading === FeatureFlag.ADDITIONAL}
            onChange={onFlagChange(FeatureFlag.ADDITIONAL)}
            isDisabled={isToggleDisabled}
          />
        </Grid>
      </SettingsTabTemplate.Section>
    </SettingsTabTemplate>
  )
}

export default React.memo(FeaturesTab)
