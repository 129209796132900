import { FontFace } from 'modules/dna'

import { IButtonFrameProps } from './ButtonFrame/ButtonFrame'

export const Contained: IButtonFrameProps = {
  alignItems: 'center',
  activeBackgroundColor: 'primaryDark',
  backgroundColor: 'primaryMid',
  activeTextColor: 'textLight',
  color: 'textLight',
  fontFamily: FontFace.SofiaPro,
  fontSize: 'medium',
  fontWeight: 'bold',
  justifyContent: 'center',
  borderRadius: 'small',
  textAlign: 'center',
  paddingX: 'large',
  paddingY: 'smallest',
}

export const ContainedLight: IButtonFrameProps = {
  alignItems: 'center',
  activeBackgroundColor: 'primaryDark',
  backgroundColor: 'accentLight',
  color: 'textDark',
  fontFamily: FontFace.SofiaPro,
  fontSize: 'medium',
  justifyContent: 'center',
  padding: 'small',
  borderRadius: 'small',
  textAlign: 'center',
}

export const Outline: IButtonFrameProps = {
  alignItems: 'center',
  color: 'textDark',
  activeBackgroundColor: 'primaryDark',
  fontFamily: FontFace.SofiaPro,
  fontSize: 'medium',
  lineHeight: 'medium',
  justifyContent: 'center',
  padding: 'small',
  border: 1,
  borderColor: 'textMid',
  borderRadius: 'small',
  textAlign: 'center',
}

export const Text: IButtonFrameProps = {
  alignItems: 'center',
  color: 'textDark',
  activeBackgroundColor: 'primaryDark',
  fontFamily: FontFace.SofiaPro,
  fontSize: 'medium',
  justifyContent: 'center',
  padding: 'small',
  borderColor: 'none',
  borderRadius: 'small',
  textAlign: 'center',
}

export const Primary: IButtonFrameProps = {
  ...Contained,
}

export const Secondary: IButtonFrameProps = {
  ...Text,
}
