import { initReactI18next } from 'react-i18next'

import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { Language, SUPPORTED_LANGUAGES } from '../settings'

const intlNext = i18n.use(initReactI18next).use(LanguageDetector)

export const changeLanguage = async (lang: string) => {
  lang = ['en-gb', 'en-us'].includes(lang.toLowerCase()) ? Language.English : lang

  if (!SUPPORTED_LANGUAGES.includes(lang as Language)) {
    lang = Language.English
  }

  if (!intlNext.hasResourceBundle(lang, 'translation')) {
    const resource = await import(`intl-bundles/${lang}.json`)
    intlNext.addResources(lang, 'translation', resource)

    if (lang != Language.English) {
      // load english as a backup translation if intl is missing
      const englishResource = await import(`intl-bundles/${Language.English}.json`)
      intlNext.addResources(Language.English, 'translation', englishResource)
    }
  }

  await intlNext.changeLanguage(lang)
}

export default intlNext
