import * as React from 'react'

import { ButtonColor, EuiButton, EuiButtonEmpty } from '@elastic/eui'

import { Grid, H1, H2, H3, H5, Modal, Paragraph } from 'modules/web-atoms'

export type ConfirmModalType = 'danger' | 'confirm'

export interface IConfirmModalProps extends Omit<React.ComponentProps<typeof Modal>, 'title' | 'onClose' | 'children'> {
  type: ConfirmModalType

  onConfirm: () => void
  onCancel: () => void

  heading: string
  subheading?: string
  message?: string
  confirmLabel: string
  cancelLabel: string
  children?: React.ReactNode
}

export const ConfirmModal: React.FC<IConfirmModalProps> = (props) => {
  const { type, heading, subheading, message, onConfirm, onCancel, confirmLabel, cancelLabel, children, ...rest } =
    props

  const color: ButtonColor | undefined = type === 'danger' ? 'danger' : undefined

  return (
    <Modal borderRadius={['small', 'medium']} {...rest} maxWidth={300} hasClose={false} onClose={onCancel}>
      <Grid padding="medium">
        <H1 color="primaryDark" fontSize="xlarge" textAlign="center">
          {heading}
        </H1>
        {subheading && (
          <H5 color="textDark" textAlign="start" marginTop="medium">
            {subheading}
          </H5>
        )}
        {message && (
          <Paragraph textAlign="start" marginTop="small">
            {message}
          </Paragraph>
        )}
        {children}
        <Grid marginTop="medium" alignSelf="flex-end">
          <Grid.Col>
            <EuiButtonEmpty onClick={onCancel}>{cancelLabel}</EuiButtonEmpty>
          </Grid.Col>
          <Grid.Col>
            <EuiButton type="submit" form="modalFormId" onClick={onConfirm} fill color={color}>
              {confirmLabel}
            </EuiButton>
          </Grid.Col>
        </Grid>
      </Grid>
    </Modal>
  )
}

export default React.memo(ConfirmModal)
