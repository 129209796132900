import * as React from 'react'
import { useDispatch } from 'react-redux'

import { EuiButton } from '@elastic/eui'

import { CircleTickDark } from 'modules/dna'
import { PortalPath } from 'modules/module-core/routes/models'
import navigation from 'modules/module-navigation'
import orchestration from 'modules/module-orchestration'
import { Grid, H1, H2, Image, useTranslate } from 'modules/web-atoms'
import { Trans } from 'react-i18next'

const INTL_PREFIX = 'recipients.upload.finish'

export interface IUploadRecipientsFinishPageProps extends React.ComponentProps<typeof Grid> {}

export const UploadRecipientsFinishPage: React.FC<IUploadRecipientsFinishPageProps> = (props) => {
  const t = useTranslate(INTL_PREFIX)

  const dispatch = useDispatch()

  const handleFinish = React.useCallback(() => {
    dispatch(orchestration.actions.restore({ success: true }))
    dispatch(navigation.actions.navigate({ route: PortalPath.RESIDENTS_DIRECTORY }))
  }, [dispatch])

  return (
    <Grid
      justifyContent="space-between"
      alignItems="center"
      marginTop="large"
      justifyItems="center"
      minHeight="60vh"
      flex={1}
      maxWidth={700}
      alignSelf="center"
      padding="medium"
      {...props}
    >
      <Grid justifyContent="center" alignItems="center" marginTop="xlarge" paddingX="small" flex={1}>
        <Grid.Row>
          <H1 textAlign="center">{t('heading')}</H1>
        </Grid.Row>
        <Grid.Row marginTop="smallest" marginBottom="xlarge">
          <H2 textAlign="center">
            <Trans
              i18nKey={`${INTL_PREFIX}.subheading`}
              components={{
                br: <br />,
              }}
            />
          </H2>
        </Grid.Row>
        <Image width={300} src={CircleTickDark} />
      </Grid>
      <Grid.Row marginTop="xxlarge">
        <EuiButton onClick={handleFinish} fill>
          {t('finish')}
        </EuiButton>
      </Grid.Row>
    </Grid>
  )
}

export default React.memo(UploadRecipientsFinishPage)
