import * as React from 'react'

import { IUserDtoClass } from 'api'
import API from 'api/clients'
import { plainToClass } from 'class-transformer'
import { QueryKey } from 'modules/module-api'
import useApi from 'modules/module-api/hooks/useApi/use-api'
import reporting from 'modules/module-reporting'
import { useAppDispatch } from 'modules/module-utils/hooks'

function useUser(userId?: string) {
  const dispatch = useAppDispatch()
  const { data, isFetching, error, refetch } = useApi<IUserDtoClass>(
    [QueryKey.FETCH_USER, userId],
    async () => {
      const response = await API({
        url: `/v3/users`,
        params: {
          id: userId,
        },
      })
      return response.data
    },
    {
      enabled: false,
    },
  )

  React.useEffect(() => {
    !!userId &&
      refetch().catch((error) => {
        dispatch(reporting.actions.error(error))
      })
  }, [userId, dispatch, refetch])

  const user = React.useMemo(() => {
    return data ? plainToClass(IUserDtoClass, data) : undefined
  }, [data])

  return { user, isFetching, error }
}

export default useUser
