import { isElement } from 'modules/module-utils'
import { Divider, Grid, H2, IGridProps, Paragraph } from 'modules/web-atoms'
import React from 'react'

const Section: React.FC<IGridProps> = ({ children }) => <>{children}</>

export interface ISettingsTabTemplateProps extends IGridProps {
  heading: string
  description: string
}

const SettingsTabTemplate: React.FC<ISettingsTabTemplateProps> = ({ heading, description, children, ...rest }) => {
  const { sections } = React.useMemo(() => {
    const childrenArr = React.Children.toArray(children)

    const sections = childrenArr.filter(isElement(Section))
    return { sections }
  }, [children])

  return (
    <Grid gutterSize="smallest" {...rest}>
      <Grid gutterSize="none">
        <H2 fontWeight="medium" fontSize="xxxlarge" color="textDark">
          {heading}
        </H2>
        <Paragraph mt="small">{description}</Paragraph>
        <Divider mt="medium" mb={['macro', 'smallest', 'smallest', 'medium']} />
      </Grid>

      {sections.map((section, idx) => (
        <Grid key={idx} {...section.props}>
          {section.props.children}
          {idx !== sections.length - 1 && <Divider />}
        </Grid>
      ))}
    </Grid>
  )
}

export interface ISettingsTabTemplate extends React.MemoExoticComponent<typeof SettingsTabTemplate> {
  Section: typeof Section
}

const template: ISettingsTabTemplate = Object.assign(React.memo(SettingsTabTemplate), {
  type: SettingsTabTemplate,
  Section,
})

export default template
