import { EuiButton, EuiSearchBar } from '@elastic/eui'
import security from 'modules/module-security'
import useDropoffLocationsTable from 'modules/module-settings/hooks/use-dropoff-locations-table/use-dropoff-locations-table'
import { useAppDispatch, useAppSelector } from 'modules/module-utils'
import { Grid, useToggle, useTranslate } from 'modules/web-atoms'
import { DeleteConfirmationModal, Table } from 'modules/web-molecules'
import { ICreateOptions, IDeleteOptions, ISearchOptions } from 'modules/web-templates'
import React from 'react'
import settings, { SettingsFormState } from '../../../settings'

const TABLE_ID = 'table-dropoff-locations'
const INTL_PREFIX_MODAL = 'settings.tabs.dropoff-locations.delete-modal'

const DropoffLocationsTable = () => {
  const t = useTranslate('settings.tabs.dropoff-locations.table')
  const tCommon = useTranslate('commons')
  const dispatch = useAppDispatch()

  const [isDeleteModalOpen, toggleDeleteModal] = useToggle(false)

  const clientId = useAppSelector(security.selectors.clientId)
  const selectedItem = useAppSelector(settings.selectors.selectedDropoffLocation)
  const isAdding = useAppSelector(settings.selectors.isAddingDropoffLocation)
  const isDeleting = useAppSelector(settings.selectors.isDeletingDropoffLocations)
  const dropoffLocationFormState = useAppSelector(settings.selectors.dropoffLocationFormState)
  const { canManageDropoffLocations } = useAppSelector(settings.selectors.permissions)

  const {
    columns,
    items,
    selection,
    onItemClicked,
    isFetching,
    sort,
    onSearch,
    pagination,
    paginationOptions,
    selectedItems,
  } = useDropoffLocationsTable(clientId)

  const onAddClick = React.useCallback(() => {
    dispatch(settings.actions.setDropoffLocationFormState(SettingsFormState.CREATE))
    dispatch(settings.actions.setSelectedDropoffLocation(undefined))
  }, [dispatch])

  const createOptions: ICreateOptions = React.useMemo(
    () => ({
      visible: canManageDropoffLocations && dropoffLocationFormState !== SettingsFormState.CREATE,
      onClick: onAddClick,
      value: t('add'),
    }),
    [canManageDropoffLocations, dropoffLocationFormState, onAddClick, t],
  )

  const onDelete = React.useCallback(() => {
    dispatch(settings.actions.deleteDropoffLocations())
    toggleDeleteModal()
  }, [dispatch, toggleDeleteModal])

  const deleteOptions: IDeleteOptions = React.useMemo(
    () => ({
      visible: (selectedItems.length > 0 || !!selectedItem) && canManageDropoffLocations,
      isLoading: isDeleting,
      onClick: onDelete,
      value: tCommon('action.delete-count', { count: selectedItems.length || 1 }),
    }),
    [canManageDropoffLocations, isDeleting, onDelete, selectedItem, selectedItems.length, tCommon],
  )

  const searchOptions: ISearchOptions = React.useMemo(
    () => ({
      hint: t('search.placeholder'),
      onSearch,
    }),
    [t, onSearch],
  )

  const onQuery = React.useCallback(
    ({ queryText: filter }) => {
      searchOptions?.onSearch(filter)
    },
    [searchOptions],
  )

  return (
    <Grid flexGrow={1} minHeight={0} gutterSize="small">
      <Grid flexWrap="wrap" gutterSize="small" justifyContent="space-between">
        <Grid.Col>
          <Grid>
            <Grid>
              <Grid.Col>
                {deleteOptions.visible && (
                  <EuiButton color="danger" fill onClick={toggleDeleteModal} isLoading={deleteOptions.isLoading}>
                    {deleteOptions.value}
                  </EuiButton>
                )}
              </Grid.Col>
            </Grid>
          </Grid>
        </Grid.Col>

        <Grid.Col alignSelf={['flex-start', 'flex-end']}>
          <Grid gutterSize={['smallest', 'medium']} flexWrap="wrap">
            <Grid.Col>
              <EuiSearchBar box={{ placeholder: searchOptions.hint, incremental: true }} onChange={onQuery} />
            </Grid.Col>
            {createOptions.visible && (
              <EuiButton
                onClick={createOptions.onClick}
                color="primary"
                fill
                isDisabled={!clientId}
                isLoading={isAdding}
              >
                {createOptions.value}
              </EuiButton>
            )}
          </Grid>
        </Grid.Col>
      </Grid>

      <Table
        tableId={TABLE_ID}
        columns={columns}
        items={items}
        selection={selection}
        sorting={sort}
        pagination={pagination}
        paginationOptions={paginationOptions}
        loading={isFetching || !clientId}
        isCheckable={canManageDropoffLocations}
        onItemClicked={canManageDropoffLocations ? onItemClicked : undefined}
        selectedItems={selectedItems}
      />

      {isDeleteModalOpen && (
        <DeleteConfirmationModal
          onClose={toggleDeleteModal}
          onDelete={onDelete}
          itemSize={selectedItems.length || 1}
          intlPrefix={INTL_PREFIX_MODAL}
        />
      )}
    </Grid>
  )
}

export default React.memo(DropoffLocationsTable)
