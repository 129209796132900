import * as React from 'react'

import { Direction } from '@elastic/eui'

import { IPaginationParams, Sort, SortDirection } from 'modules/module-api'
import { useToggle } from 'modules/web-atoms'
import { IPaginationOptions, ISorting, ITableItem, ITableSelectedChange, ITableSelection } from 'modules/web-molecules'

export interface IBatchTableSelection<T> {
  deselectedItems: T[]
  isBatchSelectEnabled: boolean
  toggleBatchSelect: (value?: boolean) => void
  setDeselectedItems: (items: T[]) => void
}

export interface IUsePaginatedTableParams<
  Columns extends string,
  Item extends ITableItem<Columns>,
  V extends string,
  S extends Sort<V>,
> {
  sortClass: S
  pageParams: IPaginationParams<V>
  itemsPerPage?: number[]
  onSortChange?: () => void
  onSelectionChange?: ITableSelectedChange<Columns, Item>
}

export function usePaginatedTable<
  Columns extends string,
  Item extends ITableItem<Columns>,
  V extends string,
  S extends Sort<V>,
>(params: IUsePaginatedTableParams<Columns, Item, V, S>) {
  const [search, setSearch] = React.useState(params.pageParams.filter ?? '')
  const [pageSize, setPageSize] = React.useState(params.pageParams.pageSize)
  const [pageIndex, setPageIndex] = React.useState(params.pageParams.page)
  const [sortField, setSortField] = React.useState<V>(params.pageParams.sortBy)
  const [sortDirection, setSortDirection] = React.useState<SortDirection>(params.pageParams.sort)

  const [isBatchSelectEnabled, toggleBatchSelect] = useToggle(false)
  const [deselectedItems, setDeselectedItems] = React.useState<Item[]>([])

  const selection: ITableSelection<Columns, Item> = React.useMemo(() => {
    return {
      selectable: (item) => !!params.onSelectionChange,
      onSelectionChange: params.onSelectionChange,
    }
  }, [params.onSelectionChange])

  const batchSelection: IBatchTableSelection<Item> = React.useMemo(
    () => ({
      deselectedItems,
      setDeselectedItems,
      isBatchSelectEnabled,
      toggleBatchSelect: (value) => {
        setDeselectedItems([])
        toggleBatchSelect(value)
      },
    }),
    [deselectedItems, isBatchSelectEnabled, toggleBatchSelect],
  )

  const sort: ISorting<any> = React.useMemo(() => {
    return {
      sort: {
        field: sortField,
        direction: sortDirection === SortDirection.ASC ? 'asc' : 'desc',
      },
      onSortChange: (columnId: string, direction: Direction) => {
        setSortField(columnId as V)
        setSortDirection(direction == 'asc' ? SortDirection.ASC : SortDirection.DESC)
        if (params.onSortChange) params.onSortChange()
      },
    }
  }, [params, sortDirection, sortField])

  const paging: IPaginationParams<V> = React.useMemo(() => {
    return {
      filter: search,
      pageSize,
      page: pageIndex,
      sortBy: sortField,
      sort: sortDirection,
    }
  }, [pageIndex, pageSize, search, sortDirection, sortField])

  React.useEffect(() => {
    setPageIndex(0)
  }, [pageSize])

  const paginationOptions: IPaginationOptions = React.useMemo(() => {
    return {
      itemsPerPage: params.itemsPerPage ?? [10, 20, 50],
      onPageSizeChange: setPageSize,
      onPageIndexChange: setPageIndex,
    }
  }, [params.itemsPerPage])

  const onSearch = React.useCallback((filter: string) => {
    setPageIndex(0)
    setSearch(filter)
  }, [])

  return { paging, paginationOptions, selection, batchSelection, sort, onSearch, setPageIndex }
}
