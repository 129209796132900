import { SagaIterator } from '@redux-saga/types'
import autobind from 'autobind-decorator'
import orchestration from 'modules/module-orchestration'
import security from 'modules/module-security'
import { SupervisorSuite } from 'modules/redux-supervisor'
import { determineUserPermissions } from 'modules/module-orchestration/orchestration/actions'
import { put, select, all, takeLatest } from 'redux-saga/effects'
import statistics from '../statistics'

export class StatisticsSaga extends SupervisorSuite {
  @autobind
  *start(): SagaIterator {
    yield all([takeLatest(orchestration.actions.DETERMINE_USER_PERMISSIONS, this.determineUserPermissions)])
  }

  @autobind
  *determineUserPermissions({ payload }: ReturnType<typeof determineUserPermissions>): SagaIterator {
    const userType = yield select(security.selectors.type)
    yield put(statistics.actions.setPermissions({ userType, isOutboundEnabled: payload.flags.outboundEnabled }))
  }
}

export default StatisticsSaga
