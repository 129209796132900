import * as actions from './actions'
import reducer from './reducer'
import * as selectors from './selectors'

export * from './models'

const statistics = {
  actions,
  reducer,
  selectors,
}

export default statistics
