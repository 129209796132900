import * as React from 'react'

import {
  EuiCheckbox,
  EuiTableHeaderCell,
  EuiTableRow,
  EuiTableRowCell,
  EuiTableRowCellCheckbox,
  HorizontalAlignment,
} from '@elastic/eui'

export enum TableColumnType {
  Checkbox = 'checkbox',
  Column = 'Column',
}

export interface IColumn extends React.ComponentProps<typeof EuiTableHeaderCell> {
  label?: React.ReactNode
  type: TableColumnType
  alignment?: HorizontalAlignment
  isSortable: boolean
}

export type IColumns<T extends string> = { [K in T]: IColumn }
export interface ITableItemCell extends React.ComponentProps<typeof EuiTableRowCell> {
  isChecked?: boolean
  render?: () => React.ReactNode
}

export type ITableItem<T extends string> = React.ComponentProps<typeof EuiTableRow> & {
  readonly [k in T]: ITableItemCell
} & {
  id: string
  isCheckable?: boolean
  isChecked?: boolean
}

export interface ITableItemProps<T extends string, Item extends ITableItem<T>> {
  columns: IColumns<T>
  item: Item
  isChecked: boolean
  onSelectionChange: (item: Item) => void
  isCheckboxDisabled?: boolean
  onClick?: (item: Item) => void
}

export function TableItem<T extends string, Item extends ITableItem<T>>(props: ITableItemProps<T, Item>) {
  const { columns, item, onClick, isChecked, onSelectionChange, isCheckboxDisabled, ...rest } = props

  const onChange = React.useCallback(() => {
    onSelectionChange(item)
  }, [item, onSelectionChange])

  const onClicked = React.useCallback(
    (event) => {
      const target = event.target

      if (target.id !== `checkbox-${item.id}` && target.tagName !== 'IMG' && target.className !== 'ant-image-mask-info')
        onClick?.(item)
    },
    [item, onClick],
  )

  const cells: React.ReactElement[] = React.useMemo(() => {
    return Object.keys(columns).map((columnKey, index) => {
      const column = columns[columnKey]

      if (column.type == TableColumnType.Checkbox) {
        return (
          <EuiTableRowCellCheckbox id={`item-cell-checkbox-${item.id}-${index}`} key={`${item.id}${index}`}>
            <EuiCheckbox
              id={`checkbox-${item.id}`}
              checked={isChecked}
              onChange={onChange}
              type="inList"
              aria-label={`Checkbox ${item.id}`}
              disabled={isCheckboxDisabled}
            />
          </EuiTableRowCellCheckbox>
        )
      }
      const itemColumn = item[columnKey]
      return (
        <EuiTableRowCell
          id={`item-cell-checkbox-${item.id}-${index}`}
          key={`${item.id}${index}`}
          align={column.alignment}
          truncateText={itemColumn && itemColumn.truncateText}
          textOnly={itemColumn ? itemColumn.textOnly : true}
          mobileOptions={{
            header: column.label,
            ...itemColumn.mobileOptions,
          }}
        >
          {itemColumn.render && itemColumn.render()}
        </EuiTableRowCell>
      )
    })
  }, [columns, item, isChecked, onChange, isCheckboxDisabled])

  return (
    <EuiTableRow
      id={`item-${item.id}`}
      key={item.id}
      isSelected={item.isSelected}
      isSelectable={item.isSelectable}
      onClick={onClicked}
      {...rest}
    >
      {cells}
    </EuiTableRow>
  )
}

export default React.memo(TableItem)
