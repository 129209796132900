import * as React from 'react'

import { EuiCheckbox } from '@elastic/eui'
import styled from '@emotion/styled'
import shouldForwardProp from '@styled-system/should-forward-prop'

import * as system from 'styled-system'
import { StyledViewProps } from '../Grid/StyledView'

export type ICheckboxProps = StyledViewProps & React.ComponentProps<typeof EuiCheckbox>

export const StyledCheckbox = styled(EuiCheckbox, { shouldForwardProp })<ICheckboxProps>(
  system.color,
  system.flexbox,
  system.space,
  system.layout,
  system.position,
  system.justifyContent,
  system.alignItems,
  system.borders,
  system.shadow,
  system.background,
)

export const Checkbox: React.FC<ICheckboxProps> = (props) => {
  const { children, ...rest } = props
  return <StyledCheckbox {...rest}>{children}</StyledCheckbox>
}

export default React.memo(Checkbox)
