import billing from 'modules/module-billing/billing'
import security, { Role } from 'modules/module-security'
import { useAppSelector } from 'modules/module-utils'
import { Grid, H3, Icon, IconType, Link, useScreen, useTranslate } from 'modules/web-atoms'
import { ISideMenuProps, MenuLinkItem, ProfileMenuItem, SideMenu } from 'modules/web-organisms'
import { SideMenu as SideMenuContainer } from 'modules/web-organisms/components/SideMenu/SideMenu'
import * as React from 'react'
import { useSideMenuRoutes } from '../../hooks'
import { Condition, ISideMenuOption } from '../../hooks/use-side-menu-routes/use-side-menu-routes'
import { PortalPath } from '../../routes/models'
import { BuildingSelector } from '../BuildingSelectorMenuItem/BuildingSelectorMenuItem'

const LINK_HELP_CENTER = 'https://support.parceltracker.com'
const LINK_TERMS = 'https://www.parceltracker.com/terms-and-conditions'
const LINK_PRIVACY_POLICY = 'https://www.parceltracker.com/tc'

export interface IPortalSideMenu extends Omit<ISideMenuProps, 'isMinimized' | 'isFlyout'> {}

export const PortalSideMenu: React.FC<IPortalSideMenu> = (props) => {
  const { ...rest } = props
  const t = useTranslate('core.nav')

  const role = useAppSelector(security.selectors.role)
  const manualOverride = useAppSelector(billing.selectors.manualOverride)
  const onboardingRequired = useAppSelector(security.selectors.showResidentsOnboardingScreen)

  const { isMobile, isTablet } = useScreen()
  const { userMenus, tenantMenus } = useSideMenuRoutes()

  const checkIfConditionIsSatisfied = React.useCallback(
    (condition: Condition) => {
      switch (condition) {
        case Condition.NO_MANUAL_OVERRIDE:
          // do not show menu item if manual override is set
          return manualOverride !== undefined && !manualOverride
        case Condition.HIDE_FOR_RECIPIENT:
          return role !== Role.RECIPIENT
        default:
          return true
      }
    },
    [manualOverride, role],
  )

  const filterMenuItems = React.useCallback(
    (menuItems: ISideMenuOption[]) => {
      return menuItems
        .filter((menuItem) => {
          const conditions = menuItem.conditions || []
          const isConditionSatisfied = conditions.every((condition) => checkIfConditionIsSatisfied(condition))
          const restrictAccess = menuItem.restrictAccess

          return isConditionSatisfied && !restrictAccess
        })
        .map((menuItem) => {
          if (menuItem.subMenu) {
            return {
              ...menuItem,
              subMenu: filterMenuItems(menuItem.subMenu),
            }
          }
          return menuItem
        })
    },
    [checkIfConditionIsSatisfied],
  )

  const menus = React.useMemo(() => {
    const menuConfig: ISideMenuOption[] = filterMenuItems(role == Role.RECIPIENT ? tenantMenus : userMenus)

    return menuConfig.map((menu) => {
      const submenus = menu.subMenu
      if (!!submenus && submenus.length) {
        if (submenus.length > 1) {
          return (
            <SideMenu.MenuGroup
              path={menu.path}
              title={t(`menu.${menu.name}`)}
              icon={menu.icon}
              isMinimized={isTablet}
              isDisabled={onboardingRequired}
            >
              {submenus.map((subMenu) => {
                return (
                  <SideMenu.MenuItem
                    key={subMenu.path}
                    path={subMenu.path}
                    title={t(`menu.${subMenu.name}`)}
                    icon={subMenu.icon}
                    isMinimized={isTablet}
                    isDisabled={onboardingRequired && !subMenu.onboarding}
                  />
                )
              })}
            </SideMenu.MenuGroup>
          )
        } else {
          const submenu = submenus[0]
          return (
            <SideMenu.MenuItem
              path={submenu.path}
              title={t(`menu.${menu.name}`)}
              icon={menu.icon}
              isMinimized={isTablet}
              isDisabled={onboardingRequired && !menu.onboarding}
            />
          )
        }
      } else {
        return (
          <SideMenu.MenuItem
            path={menu.path}
            title={t(`menu.${menu.name}`)}
            icon={menu.icon}
            isMinimized={isTablet}
            isDisabled={onboardingRequired && !menu.onboarding}
          />
        )
      }
    })
  }, [isTablet, onboardingRequired, role, t, filterMenuItems, userMenus, tenantMenus])

  return (
    <SideMenuContainer isFlyout={isMobile} isMinimized={isTablet} {...rest}>
      {!isMobile && (
        <SideMenu.Header>
          <Link aria-label={t('header.name')} style={{ textDecoration: 'none' }} to={PortalPath.GET_STARTED}>
            <Grid alignItems="center">
              <Grid.Col>
                <Icon.MenuIcon />
              </Grid.Col>
              {!isTablet && (
                <Grid.Col>
                  <H3 fontSize="xlarge" fontWeight="bold" whiteSpace="nowrap">
                    {t('header.name')}
                  </H3>
                </Grid.Col>
              )}
            </Grid>
          </Link>
        </SideMenu.Header>
      )}
      <SideMenu.Menu>
        {role == Role.USER && (
          <BuildingSelector marginTop={['none', 'medium', 'medium', 'none']} isMinimized={isTablet} />
        )}
        {menus}
      </SideMenu.Menu>
      <SideMenu.Footer>
        <Grid gutterSize="smallest" marginX={'small'}>
          <MenuLinkItem isMinimized={isTablet} title={t('links.terms')} link={LINK_TERMS} icon={IconType.MenuTerms} />
          <MenuLinkItem
            isMinimized={isTablet}
            title={t('links.pp')}
            link={LINK_PRIVACY_POLICY}
            icon={IconType.MenuPrivacyPolicy}
          />
          <MenuLinkItem
            isMinimized={isTablet}
            title={t('links.help-center')}
            link={LINK_HELP_CENTER}
            icon={IconType.MenuHelp}
          />
        </Grid>
      </SideMenu.Footer>
      <SideMenu.Profile>
        <ProfileMenuItem marginTop="small" isMinimized={isTablet} />
      </SideMenu.Profile>
    </SideMenuContainer>
  )
}

export default React.memo(PortalSideMenu)
