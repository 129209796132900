import autobind from 'autobind-decorator'
import analytics from 'modules/module-analytics'
import initTagManager from 'modules/module-analytics/tag-manager'
import orchestration from 'modules/module-orchestration'
import { refreshTokenStore, userIdStore } from 'modules/module-security/storage'
import { SupervisorSuite } from 'modules/redux-supervisor'
import { SagaIterator } from 'redux-saga'
import { call, fork, put } from 'redux-saga/effects'

export class LaunchSaga extends SupervisorSuite {
  @autobind
  *start(): SagaIterator {
    yield fork(this.launch)
    yield fork(this.performLaunchTasks)
  }

  /**
   * Handle oauth authentication
   */
  @autobind
  *launch(): SagaIterator {
    if ((yield call(refreshTokenStore.exists)) && (yield call(userIdStore.exists))) {
      yield put(orchestration.actions.restore({ success: true }))
    } else {
      yield put(orchestration.actions.restore({ success: false }))
    }
    yield put(analytics.actions.configure(null))
  }

  @autobind
  *performLaunchTasks(): SagaIterator {
    yield call(initTagManager)
  }
}

export default LaunchSaga
