import React from 'react'
import { Flow } from 'modules/module-navigation'
import { AlignColumnsPage, UploadFilePage, UploadPage, ValidateRowsPage } from 'modules/module-recipient-upload'

import FinishOnboardingScreen from '../screens/onboarding/FinishOnboardingScreen/FinishOnboardingScreen'
import SelectUploadFlowScreen from '../screens/onboarding/SelectUploadFlowScreen/SelectUploadFlowScreen'
import WelcomeScreen from '../screens/onboarding/WelcomeScreen/WelcomeScreen'

export type OnboardingFlow = Flow<OnboardingScreen>

export enum OnboardingScreen {
  Welcome = 'welcome',
  UploadMethod = 'upload-method',
  UploadFile = 'upload-file',
  AlignColumns = 'align-columns',
  ValidateRows = 'validateRows',
  Upload = 'upload',
  Finish = 'finish',
}

export const onboardingFlow: OnboardingFlow = [
  {
    id: OnboardingScreen.Welcome,
    component: WelcomeScreen,
    actions: [
      {
        id: 'setup',
        to: OnboardingScreen.UploadMethod,
      },
    ],
  },
  {
    id: OnboardingScreen.UploadMethod,
    component: SelectUploadFlowScreen,
    actions: [
      {
        id: 'upload-file',
        to: OnboardingScreen.UploadFile,
      },
      {
        id: 'previous',
        to: OnboardingScreen.Welcome,
      },
    ],
  },
  {
    id: OnboardingScreen.UploadFile,
    component: () => <UploadFilePage isOnboarding />,
    actions: [
      {
        id: 'align-columns',
        to: OnboardingScreen.AlignColumns,
      },
      {
        id: 'previous',
        to: OnboardingScreen.UploadMethod,
      },
    ],
  },
  {
    id: OnboardingScreen.AlignColumns,
    component: AlignColumnsPage,
    actions: [
      {
        id: 'validate',
        to: OnboardingScreen.ValidateRows,
      },
      {
        id: 'previous',
        to: OnboardingScreen.UploadFile,
      },
    ],
  },
  {
    id: OnboardingScreen.ValidateRows,
    component: ValidateRowsPage,
    actions: [
      {
        id: 'confirm',
        to: OnboardingScreen.Upload,
      },
      {
        id: 'previous',
        to: OnboardingScreen.AlignColumns,
      },
    ],
  },
  {
    id: OnboardingScreen.Upload,
    component: UploadPage,
    actions: [
      {
        id: 'finish',
        to: OnboardingScreen.Finish,
      },
      {
        id: 'previous',
        to: OnboardingScreen.ValidateRows,
      },
    ],
  },
  {
    id: OnboardingScreen.Finish,
    component: FinishOnboardingScreen,
    actions: [],
  },
]

export default onboardingFlow
