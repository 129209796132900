import { EuiButton, EuiButtonEmpty, EuiButtonIcon, EuiLoadingSpinner, EuiSwitchEvent } from '@elastic/eui'
import { FormikConfig, useFormik } from 'formik'
import settings, { ITagItem } from '../../settings'
import { Grid, Icon, useToggle, Paragraph, useTranslate, Switch } from 'modules/web-atoms'
import { IDynamicGridItemProps } from 'modules/web-atoms/components/DynamicGrid/GridItem'
import { ConfirmModal, FieldColorPicker, FieldText, FormRow } from 'modules/web-molecules'
import React from 'react'
import TagLabel from '../TagLabel/TagLabel'
import { strings, useAppDispatch, useAppSelector, useColorBrightness } from 'modules/module-utils'
import * as Yup from 'yup'

interface IFormValues {
  name: string
  backgroundColor: string
}

export enum TagItemMode {
  VIEW = 'view',
  EDIT = 'edit',
  CREATE = 'create',
}

export interface ITagItemProps extends IDynamicGridItemProps {
  mode?: TagItemMode
  tag?: ITagItem
}

const INTL_PREFIX = 'settings.tabs.tags'
const DEFAULT_BG_COLOR = '#f7da4b'

const SHADOW_BLUR = '0px'
const STROKE_WIDTH = '0.3px'
const TEXT_STROKE_SHADOW = `-${STROKE_WIDTH} -${STROKE_WIDTH} ${SHADOW_BLUR} #000, ${STROKE_WIDTH} -${STROKE_WIDTH} ${SHADOW_BLUR} #000, -${STROKE_WIDTH} ${STROKE_WIDTH} ${SHADOW_BLUR} #000, ${STROKE_WIDTH} ${STROKE_WIDTH} ${SHADOW_BLUR} #000`

const TagItem: React.FC<ITagItemProps> = ({ mode = TagItemMode.VIEW, tag: tagData, ...rest }) => {
  const t = useTranslate(INTL_PREFIX)
  const dispatch = useAppDispatch()

  // Hide actions if tag is default
  const isDefault = tagData?.clientId === null
  const tag: ITagItem = React.useMemo(
    () => ({
      id: tagData?.id ?? -1,
      name: tagData?.name ?? '',
      color: tagData?.color ?? DEFAULT_BG_COLOR,
      enabled: tagData?.enabled ?? false,
    }),
    [tagData],
  )

  const { canManageTags } = useAppSelector(settings.selectors.permissions)
  const isAdding = useAppSelector(settings.selectors.isAddingTag)
  const isEditing = useAppSelector(settings.selectors.isEditing(tag.id))
  const isUpdating = useAppSelector(settings.selectors.isUpdating(tag.id))
  const isDeleting = useAppSelector(settings.selectors.isDeleting(tag.id))
  const colorBrightness = useColorBrightness(tag.color, 1.7)

  const isSaveLoading = mode === TagItemMode.CREATE ? isAdding : isUpdating
  const disableActions = isUpdating || isDeleting
  const showForm = mode === TagItemMode.CREATE || isEditing

  const [showDeleteModal, toggleDeleteModal] = useToggle(false)

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string()
          .trim()
          .max(64, ({ max }) => t('validation.max', { max }))
          .required(t('validation.required')),
        backgroundColor: Yup.string()
          .trim()
          .matches(/#(?:[0-9a-fA-F]{6})\b/, t('validation.invalid-color'))
          .required(t('validation.required')),
      }),
    [t],
  )

  const onSubmit: FormikConfig<IFormValues>['onSubmit'] = (values) => {
    if (mode === TagItemMode.CREATE) {
      dispatch(
        settings.actions.addTag({
          name: values.name,
          color: values.backgroundColor,
          enabled: true,
        }),
      )
    } else {
      dispatch(
        settings.actions.updateTag({
          ...tag,
          name: values.name,
          color: values.backgroundColor,
        }),
      )
    }
  }

  const { values, touched, errors, handleChange, setFieldValue, setValues, submitForm, resetForm } =
    useFormik<IFormValues>({
      initialValues: {
        name: tag.name,
        backgroundColor: tag.color,
      },
      validationSchema,
      onSubmit,
    })

  const onCancel = () => {
    if (mode === TagItemMode.CREATE) {
      dispatch(settings.actions.showAddTagForm(false))
    } else {
      dispatch(settings.actions.setIsEditingTag({ id: tag.id, state: false }))
      void resetForm()
    }
  }

  React.useEffect(() => {
    if (tag) {
      void setValues({
        name: tag.name,
        backgroundColor: tag.color,
      })
    }
  }, [tag, setValues])

  const onColorChange = (color: string) => {
    let value = color.replaceAll('#', '')
    void setFieldValue('backgroundColor', `#${value}`)
  }

  const onEnabledChange = (event: EuiSwitchEvent) => {
    dispatch(
      settings.actions.updateTag({
        ...tag,
        enabled: event.target.checked,
      }),
    )
  }

  const onEdit = () => {
    dispatch(settings.actions.setIsEditingTag({ id: tag.id, state: true }))
  }

  const onDelete = () => {
    dispatch(settings.actions.deleteTag(tag.id))
    toggleDeleteModal()
  }

  return (
    <Grid
      height="100%"
      width="100%"
      backgroundColor="primaryBackground"
      borderRadius="xmedium"
      padding="small"
      boxShadow="0px 1px 4px rgba(0, 0, 0, 0.25)"
    >
      {showForm ? (
        <Grid height="100%" justifyContent="space-between">
          <Grid className="eui-yScroll" padding="macro">
            <FormRow label={t('form.name')} touched={touched.name} error={errors.name}>
              <FieldText
                name="name"
                value={values.name}
                autoFocus
                placeholder={t('form.name.placeholder')}
                onChange={handleChange('name')}
              />
            </FormRow>
            <FormRow
              label={t('form.background-color')}
              touched={touched.backgroundColor}
              error={errors.backgroundColor}
            >
              <FieldColorPicker onChange={onColorChange} color={values.backgroundColor} />
            </FormRow>
            <TagLabel
              mt="smallest"
              maxWidth="100%"
              width="fit-content"
              tagName={values.name}
              tagColor={values.backgroundColor}
            />
          </Grid>

          <Grid justifyContent="space-between">
            <Grid.Col>
              <EuiButtonEmpty color="primary" disabled={isSaveLoading} onClick={onCancel}>
                {t('form.cancel')}
              </EuiButtonEmpty>
            </Grid.Col>
            <Grid.Col>
              <EuiButton color="primary" isLoading={isSaveLoading} fill onClick={submitForm}>
                {t('form.save')}
              </EuiButton>
            </Grid.Col>
          </Grid>
        </Grid>
      ) : (
        <Grid height="100%" justifyContent="space-between">
          <Grid.Col flexGrow={1} minWidth={0}>
            <Grid>
              <TagLabel tagName={tag.name} maxWidth="100%" width="fit-content" tagColor={tag.color} />

              <Grid gutterSize="none">
                <Paragraph color="textDark" maxWidth={[100, 160]} truncated fontWeight="medium" size="medium">
                  {strings.capitalize(tag.name)}
                </Paragraph>
                <Paragraph
                  color="none"
                  fontWeight="medium"
                  size="medium"
                  textShadow={colorBrightness === 'bright' ? TEXT_STROKE_SHADOW : undefined}
                >
                  <span style={{ color: tag.color }}>{tag.color.substring(1).toUpperCase()}</span>
                </Paragraph>
              </Grid>
            </Grid>
          </Grid.Col>

          <Grid.Col>
            {isDefault ? (
              <Paragraph color="primaryMid" fontWeight="medium">
                {t('default')}
              </Paragraph>
            ) : canManageTags ? (
              <Grid height="100%" justifyContent="space-between">
                <Grid alignItems="center">
                  <Grid.Col>
                    <EuiButtonIcon
                      aria-label={t('item.edit')}
                      iconType={Icon.Pencil}
                      size="xs"
                      iconSize="original"
                      color="text"
                      onClick={onEdit}
                      isDisabled={disableActions}
                    />
                  </Grid.Col>
                  <Grid.Col width={45} justifyContent="center" alignItems="center">
                    {isUpdating ? (
                      <EuiLoadingSpinner size="m" />
                    ) : (
                      <Switch
                        aria-label={t('item.toggle')}
                        disabled={disableActions}
                        label={null}
                        showLabel={false}
                        checked={tag.enabled}
                        onChange={onEnabledChange}
                      />
                    )}
                  </Grid.Col>
                </Grid>

                <Grid alignSelf="end">
                  {isDeleting ? (
                    <EuiLoadingSpinner size="m" />
                  ) : (
                    <EuiButtonIcon
                      aria-label={t('item.delete')}
                      iconType={Icon.Trash}
                      size="xs"
                      iconSize="original"
                      color="danger"
                      onClick={toggleDeleteModal}
                      isDisabled={disableActions}
                    />
                  )}
                </Grid>
              </Grid>
            ) : undefined}
          </Grid.Col>
        </Grid>
      )}

      {showDeleteModal && (
        <ConfirmModal
          type="danger"
          onConfirm={onDelete}
          onCancel={toggleDeleteModal}
          heading={t('delete-modal.heading')}
          subheading={t('delete-modal.subheading', { tagName: tag.name })}
          confirmLabel={t('delete-modal.confirm')}
          cancelLabel={t('delete-modal.cancel')}
        />
      )}
    </Grid>
  )
}

export default React.memo(TagItem)
