import { system } from 'styled-system'

export const whitespaceDecoration = system({
  whiteSpace: { property: 'whiteSpace' },
})

export type TextOverFlowDecorationProp = 'clip' | 'ellipsis' | '-'

export type TextLineDecorationProp = 'none' | 'underline' | 'overline' | 'line-through'

export const textOverflowDecoration = system({
  textOverflow: { property: 'textOverflow' },
})

export const transitionDecoration = system({
  transition: { property: 'transition' },
})
