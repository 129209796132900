import { UploadingMethod } from '../screens/onboarding/SelectUploadFlowScreen/SelectUploadFlowScreen'

import { OnboardingScreen } from './flow'

export enum ClientDetailsError {
  Invalid = 'invalid',
  Unknown = 'unknown',
}

export enum ModalComponent {
  SMS_LINK = 'SMS_LINK',
  SCAN_QR = 'SCAN_QR',
  SCAN_PARCEL = 'SCAN_PARCEL',
}
export interface IBoardState {
  currentScreen: OnboardingScreen
  parcelsWaiting?: number
  parcelsScanned?: number
  currentOnboardingModalComponent: ModalComponent
  displayDashboardModal: boolean
  error?: ClientDetailsError
  loading: boolean
}

export const initialState: IBoardState = {
  currentScreen: OnboardingScreen.Welcome,
  parcelsWaiting: 0,
  parcelsScanned: 0,
  currentOnboardingModalComponent: ModalComponent.SMS_LINK,
  displayDashboardModal: false,
  error: undefined,
  loading: false,
}

export type IOnboardingNavigatePayload = string

export type ISetUploadingMethodPayload = UploadingMethod
