import { EuiSuperSelect } from '@elastic/eui'
import styled from '@emotion/styled'
import { Span, StyledViewProps } from 'modules/web-atoms'
import React, { useMemo } from 'react'
import * as system from 'styled-system'
import { ButtonContainer } from './styles'

export type ISuperSelectProps = StyledViewProps & React.ComponentProps<typeof EuiSuperSelect>

const StyledSuperSelect = styled(EuiSuperSelect)<ISuperSelectProps>(
  system.color,
  system.flexbox,
  system.space,
  system.layout,
  system.position,
  system.justifyContent,
  system.alignItems,
  system.borders,
  system.shadow,
  system.background,
)

interface IOption {
  value: string
  text: string
}

interface ISelectProps extends Omit<ISuperSelectProps, 'onChange'> {
  options: IOption[]
  onChange: (value: string) => void
}

export const SuperSelect: React.FC<ISelectProps> = ({ options: baseOptions, placeholder, onChange, ...rest }) => {
  const options = useMemo(
    () =>
      baseOptions.map((o) => ({
        value: o.value,
        inputDisplay: (
          <Span color="primaryMid" fontSize="medium">
            {o.text}
          </Span>
        ),
      })),
    [baseOptions],
  )

  return (
    <ButtonContainer>
      <StyledSuperSelect
        minWidth={['min-content', 'min-content', 220]}
        alignItems="center"
        justifyContent="center"
        borderRadius="small"
        borderColor="primaryMid"
        backgroundColor="none"
        options={options}
        onChange={onChange}
        {...rest}
      />
    </ButtonContainer>
  )
}

export default React.memo(SuperSelect)
