import { IAlginItem, IValidateRowItem } from '../screens'
import { ColumnAlignment, RecipientField, RecipientUpload } from '../uploading/types'

export interface IUploadStatusState {
  status: UploadingStatus
  uploaded: number
  duplicates: number
  errored: number
}

export enum UploadingStatus {
  STARTED = 'started',
  IN_PROGRESS = 'in-progress',
  COMPLETED = 'completed',
  PROCESS_FAILED = 'process-failed',
}

export interface IUploadPreviewSite {
  siteId: number
  siteName: string
  current: number
  currentAdditional: number
  additions: number
  additionsAdditional: number
  deletions: number
  deletionsAdditional: number
  final: number
  finalAdditional: number
}

export interface IUploadState {
  total: number
  isValid: boolean
  numInvalidRows: number
  alignItems: IAlginItem[]
  invalidFields?: RecipientField[]
  validateItems: IValidateRowItem[]
  validateColumns: RecipientField[]
  validatePage: {
    pageIndex: number
    pageSize: number
  }
  isWipeAvailable: boolean
  wipeEnable: boolean
  uploadPreview: IUploadPreviewSite[]
  isSyncing: boolean
  progress: number
  siteSelectionRequired: boolean
  uploadState?: IUploadStatusState

  error?: AlignError | Error
  loading: boolean
}

export const initialState: IUploadState = {
  total: 0,
  isValid: false,
  numInvalidRows: 0,
  alignItems: [],
  validateItems: [],
  validateColumns: [],
  validatePage: {
    pageIndex: 0,
    pageSize: 10,
  },
  isWipeAvailable: false,
  wipeEnable: false,
  uploadPreview: [],
  isSyncing: false,
  progress: 0,
  siteSelectionRequired: false,
  error: undefined,
  loading: false,
}

export type IUploadRecipientsFilePayload = Array<{ [key: string]: string }>

export interface ISetRecipientsFilePayload {
  input: RecipientUpload
  columns: string[]
  alignment: ColumnAlignment
}

export interface ISetColumnAlignmentPayload {
  column: string
  field: RecipientField
}

export interface IAdjustRowPayload {
  column: string
  id: string
  value: string
}

export interface IRemoveRowsPayload {
  rowIds: string[]
}

export type IInvalidFields = RecipientField[]

export interface IAlignmentErrorPayload {
  error: AlignError | Error
}

export interface ISetRecipientsSitePayload {
  siteId: number
}

export type IGeneratePreviewPayload = boolean

export type IUploadStatusPayload = UploadingStatus

export enum UploadUpdate {
  SUCCESS = 'SUCCESS',
  DUPLICATION = 'DUPLICATE',
  ERROR = 'ERROR',
}

export interface IUploadCompletedUpdatePayload {
  successes: number
  duplicates: number
  errors: number
}

export type IUploadProgressPayload = number

export type ISetExternalIdsPayload = { [key: number]: string[] }

export enum AlignError {
  REQUIRED_MISSING = 'required-missing',
  OTHER = 'other',
}

export interface IUpdateIdLookupPayload {
  prevValue: string
  newValue: string
}

export type IUpdateAlignItemsPayload = IAlginItem[]

export interface IUpdateValidatePagePayload {
  page: number
  pageSize: number
}

export type IUpdateEditablePayload = { itemId: string; column: RecipientField } | undefined

export type IGenerateValidateItemsPayload =
  | { editable: { itemId: string; column: RecipientField } | undefined }
  | undefined

export interface IUpdateValidateItemsPayload {
  items: IValidateRowItem[]
  columns: RecipientField[]
}

export interface IUpdateUploadPreviewPayload {
  sites: IUploadPreviewSite[]
}
