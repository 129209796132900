import useRGB from '../use-rgb/use-rgb'

export type ColorBrightness = 'bright' | 'dark'

// Higher the tolerance, the less likely it is for a color to be considered 'bright'
export const useColorBrightness = (hexcode: string, tolerance: number = 1): ColorBrightness => {
  const rgb = useRGB(hexcode)
  // https://www.w3.org/TR/AERT/#color-contrast
  const brightness = (rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000
  return brightness > 125 * tolerance ? 'bright' : 'dark'
}

export default useColorBrightness
