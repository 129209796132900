import { UserType } from 'api'

export namespace permissions {
  export const canViewParcels: UserType[] = [
    UserType.OWNER,
    UserType.ADMIN,
    UserType.DEBUG,
    UserType.NORMAL,
    UserType.BASIC,
  ]
  export const canViewCollectedParcels: UserType[] = [UserType.OWNER, UserType.ADMIN, UserType.DEBUG, UserType.NORMAL]
  export const canEditParcels: UserType[] = [UserType.OWNER, UserType.ADMIN, UserType.DEBUG, UserType.NORMAL]
  export const canCreateParcels: UserType[] = [
    UserType.OWNER,
    UserType.ADMIN,
    UserType.DEBUG,
    UserType.NORMAL,
    UserType.BASIC,
  ]
  export const canCollectParcels: UserType[] = [
    UserType.OWNER,
    UserType.ADMIN,
    UserType.DEBUG,
    UserType.NORMAL,
    UserType.BASIC,
  ]
  export const canExportParcels: UserType[] = [UserType.OWNER, UserType.ADMIN, UserType.DEBUG]
}

export default permissions
