import { UseQueryOptions } from '@tanstack/react-query'
import { IPaginationParams, QueryKey, SortDirection, usePaginatedApi } from 'modules/module-api'
import { ILocationsDto } from 'modules/module-settings/api/dto'
import { useDebounce } from '../../../module-utils/hooks/use-debounce/use-debounce'

export enum LocationsSortField {
  NAME = 'NAME',
  IDENTIFIER = 'PUBLIC_ID',
  ENABLED = 'ENABLED',
}

export interface IUseLocationsParams extends IPaginationParams<LocationsSortField> {
  development_id?: number
}

export const useLocations = (
  params: IUseLocationsParams = {
    page: 1,
    pageSize: 10,
    sortBy: LocationsSortField.NAME,
    sort: SortDirection.ASC,
    filter: '',
  },
  queryOptions?: UseQueryOptions<any>,
) => {
  const debouncedFilter = useDebounce('')(params.filter)

  const { data, ...rest } = usePaginatedApi(
    QueryKey.LOCATIONS,
    ILocationsDto,
    '/v3/mailroom-locations',
    {
      ...params,
      filter: debouncedFilter,
    },
    queryOptions,
  )

  return {
    locations: data,
    ...rest,
  }
}

export default useLocations
