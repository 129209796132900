import { EuiComboBoxOptionOption } from '@elastic/eui'
import { IDevelopmentDto } from 'api'
import { useActiveBuildings } from 'modules/module-utils'
import React from 'react'
import useAssignedBuildings from '../use-assigned-buildings/use-assigned-buildings'

export const OPTION_ALL = -1

const allOption: EuiComboBoxOptionOption<number> = {
  label: 'Select all buildings',
  value: OPTION_ALL,
}

const mapSitesToComboOption = (building: IDevelopmentDto): EuiComboBoxOptionOption<number> => {
  return {
    label: building.buildingName,
    value: building.id,
    key: building.id.toString(),
  }
}

const useSelectedBuildings = (userId?: string) => {
  const [selectedBuildings, setSelectedBuildings] = React.useState<EuiComboBoxOptionOption<number>[]>([])

  const { allBuildings, assignedBuildings, error, isFetchingAllBuildings, isFetchingAssignedBuildings } =
    useAssignedBuildings(userId)
  const { activeBuildings, isFetching: isFetchingActiveBuildings } = useActiveBuildings()

  const options: Array<EuiComboBoxOptionOption<number>> = React.useMemo(() => {
    const buildingOptions = allBuildings?.map(mapSitesToComboOption) ?? []
    if (buildingOptions?.length) {
      return [allOption, ...buildingOptions]
    } else {
      return buildingOptions
    }
  }, [allBuildings])

  React.useEffect(() => {
    if (!!assignedBuildings?.length) {
      const assignedIds = assignedBuildings.map((user) => user.id)
      const hasAll = allBuildings?.filter((user) => assignedIds.includes(user.id)).length === 0

      if (hasAll) {
        setSelectedBuildings([allOption])
      } else {
        const assigned = assignedBuildings.map(mapSitesToComboOption)
        setSelectedBuildings(assigned)
      }
    }
  }, [assignedBuildings, allBuildings, userId])

  React.useEffect(() => {
    if (!isFetchingActiveBuildings && !userId) {
      setSelectedBuildings(activeBuildings.map(mapSitesToComboOption))
    }
  }, [isFetchingActiveBuildings, activeBuildings, userId])

  const onBuildingSelection = React.useCallback(
    (options: EuiComboBoxOptionOption<number>[]) => {
      const filteredOptions = options.filter((option) => option.value !== OPTION_ALL)

      const allBuildingsSelected = allBuildings?.length == filteredOptions.length
      const hasAll = options.find((option) => option.value == OPTION_ALL)
      const allSelected = selectedBuildings?.find((building) => building.value === OPTION_ALL)

      if (hasAll && allBuildingsSelected) {
        setSelectedBuildings([])
      } else if (!!hasAll && !allSelected) {
        setSelectedBuildings(allBuildings?.map(mapSitesToComboOption) ?? [])
      } else {
        setSelectedBuildings(filteredOptions)
      }
    },
    [allBuildings, selectedBuildings],
  )

  return {
    assignedBuildings,
    options,
    selectedBuildings,
    onBuildingSelection,
    buildingsError: error,
    isFetchingAllBuildings,
    isFetchingAssignedBuildings,
    setSelectedBuildings,
  }
}

export default useSelectedBuildings
