import { handleActions } from 'modules/utils/redux'
import {
  ALIGNMENT_ERROR,
  GENERATE_UPLOAD_PREVIEW,
  INIT,
  INVALID_FIELDS,
  LOADING,
  NAVIGATE_RECIPIENT_UPLOAD,
  RESET_UPLOAD,
  SET_COLUMN_ALIGNMENT,
  SET_IS_SYNCING,
  SET_IS_VALID,
  SET_IS_WIPE_AVAILABLE,
  SET_NUM_INVALID_ROWS,
  SET_RECIPIENTS_FILE,
  SET_TOTAL,
  SET_UPLOAD_PREVIEW,
  SITE_SELECTION_TOGGLE,
  UPDATE_ALIGN_ITEMS,
  UPDATE_VALIDATE_ITEMS,
  UPDATE_VALIDATE_PAGE,
  UPLOAD_FINISHED_UPDATE,
  UPLOAD_PROGRESS,
  UPLOAD_STATUS,
} from './actions'
import {
  IAlignmentErrorPayload,
  IInvalidFields,
  ISetColumnAlignmentPayload,
  ISetRecipientsFilePayload,
  IUpdateAlignItemsPayload,
  IUpdateUploadPreviewPayload,
  IUpdateValidateItemsPayload,
  IUpdateValidatePagePayload,
  IUploadCompletedUpdatePayload,
  IUploadProgressPayload,
  IUploadState,
  IUploadStatusPayload,
  UploadingStatus,
  initialState,
} from './models'

export const reducer = handleActions<IUploadState>(
  {
    [LOADING]: (state: IUploadState, { payload }: { payload: boolean }) => {
      if (state) {
        state.loading = payload
        if (payload) state.error = undefined
      }
    },
    [SET_IS_SYNCING]: (state: IUploadState, { payload }: { payload: boolean }) => {
      state.isSyncing = payload
    },
    [NAVIGATE_RECIPIENT_UPLOAD]: (state: IUploadState) => {
      if (state) {
        state.error = undefined
        state.invalidFields = undefined
      }
    },
    [SET_RECIPIENTS_FILE]: (state: IUploadState, { payload }: { payload: ISetRecipientsFilePayload }) => {
      state.error = undefined
      state.loading = false
    },
    [UPDATE_ALIGN_ITEMS]: (state: IUploadState, { payload }: { payload: IUpdateAlignItemsPayload }) => {
      state.alignItems = payload
    },
    [SET_COLUMN_ALIGNMENT]: (state: IUploadState, { payload }: { payload: ISetColumnAlignmentPayload }) => {
      state.error = undefined
    },
    [UPDATE_VALIDATE_PAGE]: (state: IUploadState, { payload }: { payload: IUpdateValidatePagePayload }) => {
      state.validatePage = { pageIndex: payload.page, pageSize: payload.pageSize }
    },
    [SET_IS_WIPE_AVAILABLE]: (state: IUploadState, { payload }: { payload: boolean }) => {
      state.isWipeAvailable = payload
    },
    [UPDATE_VALIDATE_ITEMS]: (state: IUploadState, { payload }: { payload: IUpdateValidateItemsPayload }) => {
      state.validateItems = payload.items
      state.validateColumns = payload.columns
    },
    [INVALID_FIELDS]: (state: IUploadState, { payload }: { payload: IInvalidFields }) => {
      state.invalidFields = payload
      state.error = undefined
    },
    [SET_NUM_INVALID_ROWS]: (state: IUploadState, { payload }: { payload: number }) => {
      state.numInvalidRows = payload
    },
    [GENERATE_UPLOAD_PREVIEW]: (state: IUploadState, { payload }: { payload: boolean }) => {
      state.wipeEnable = payload
    },
    [SET_UPLOAD_PREVIEW]: (state: IUploadState, { payload }: { payload: IUpdateUploadPreviewPayload }) => {
      state.uploadPreview = payload.sites
    },
    [UPLOAD_STATUS]: (state: IUploadState, { payload }: { payload: IUploadStatusPayload }) => {
      if (state.uploadState == undefined) {
        state.uploadState = {
          status: payload,
          uploaded: 0,
          duplicates: 0,
          errored: 0,
        }
      } else {
        state.uploadState.status = payload
      }
      state.error = undefined
    },
    [UPLOAD_FINISHED_UPDATE]: (state: IUploadState, { payload }: { payload: IUploadCompletedUpdatePayload }) => {
      if (state.uploadState == undefined) {
        state.uploadState = {
          status: UploadingStatus.IN_PROGRESS,
          uploaded: 0,
          duplicates: 0,
          errored: 0,
        }
      } else {
        state.uploadState = {
          ...state.uploadState,
          status: UploadingStatus.COMPLETED,
          uploaded: payload.successes,
          duplicates: payload.duplicates,
          errored: payload.errors,
        }
        state.error = undefined
      }
    },
    [UPLOAD_PROGRESS]: (state: IUploadState, { payload }: { payload: IUploadProgressPayload }) => {
      state.progress = payload
    },
    [SET_TOTAL]: (state: IUploadState, { payload }: { payload: number }) => {
      state.total = payload
    },
    [RESET_UPLOAD]: (state: IUploadState) => {
      state.error = undefined
      state.invalidFields = undefined
      state.uploadState = undefined
      state.validatePage = { pageIndex: 0, pageSize: 10 }
    },
    [INIT]: (state: IUploadState) => {
      return initialState
    },
    [ALIGNMENT_ERROR]: (state: IUploadState, { payload }: { payload: IAlignmentErrorPayload }) => {
      state.error = payload.error
    },
    [SET_IS_VALID]: (state: IUploadState, { payload }: { payload: boolean }) => {
      state.isValid = payload
    },
    [SITE_SELECTION_TOGGLE]: (state: IUploadState, { payload }: { payload: boolean }) => {
      state.siteSelectionRequired = payload
    },
  },
  initialState,
)

export default reducer
