/* eslint-disable no-undef */
import { createSelector, Selector } from 'reselect'
import { BRANCH_KEY } from '../branch'

import { INotificationsOwnState } from '../model'

import { INotificationState } from './models'

export const branch: Selector<INotificationsOwnState, INotificationState> = (state) => state[BRANCH_KEY].notification

export const isLoading = createSelector(branch, (branch) => branch.loading)

export const isActionLoading = createSelector(branch, (branch) => branch.actionLoading)

export const isAssigning = createSelector(branch, (branch) => branch.isAssigning)

export const isRestoreDefaultLoading = createSelector(branch, (branch) => branch.isRestoreDefaultLoading)

export const error = createSelector(branch, (branch) => branch.error ?? null)

export const permissions = createSelector(branch, (branch) => branch.permissions)

export const currentBuildingIds = createSelector(branch, (branch) => branch.currentBuildingIds)

export const openedListModalId = createSelector(branch, (branch) => branch.openedListModalId)
