import React, { useState } from 'react'

import {
  EuiButton,
  EuiButtonEmpty,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
} from '@elastic/eui'

import { isNumber } from 'modules/module-utils'
import { Grid, H1, H2, IModalProps, Modal, useTranslate } from 'modules/web-atoms'
import { FormRow } from 'modules/web-molecules'
import SiteSelector from '../../../components/SiteSelector/SiteSelector'

interface ISelectSiteProps extends Omit<IModalProps, 'onCancel' | 'children'> {
  onConfirmSite: (siteId: number) => void
  onClose: () => void
}

const SelectSiteModal: React.FC<ISelectSiteProps> = (props) => {
  const { onConfirmSite, onClose, ...rest } = props

  const t = useTranslate('recipients-upload.validate-rows.select-site')

  const [error, setError] = useState<string | undefined>(undefined)
  const [selectedSite, setSelectedSite] = useState<number | undefined>()

  const onConfirm = React.useCallback(() => {
    if (!selectedSite) {
      setError(t('error'))
      return
    }
    if (!error) onConfirmSite(selectedSite)
  }, [error, onConfirmSite, selectedSite, t])

  const onSiteChange = React.useCallback((value: string) => {
    if (isNumber(value)) {
      setSelectedSite(parseInt(value))
    }
  }, [])

  return (
    <Modal
      title={t('title')}
      minWidth={200}
      maxWidth={400}
      borderRadius={['none', 'large']}
      hasClose={true}
      onClose={onClose}
      {...rest}
    >
      <EuiModalHeader>
        <Grid flexGrow={1} alignItems="center">
          <Grid.Row>
            <EuiModalHeaderTitle>
              <H1 fontSize="xxxlarge" textAlign="center">
                {t('heading')}
              </H1>
            </EuiModalHeaderTitle>
          </Grid.Row>

          <Grid.Row mt="small">
            <H2 fontSize="large" textAlign="center">
              {t('subheading')}
            </H2>
          </Grid.Row>
        </Grid>
      </EuiModalHeader>

      <EuiModalBody>
        <Grid flexGrow={1} alignItems="center">
          <Grid.Row mt="smallest">
            <FormRow label={t('message')} marginTop="smallest" inputMode="text">
              <Grid alignItems="center">
                <Grid.Row>
                  <SiteSelector
                    buttonProps={{ iconType: 'arrowDown', iconSide: 'right' }}
                    labelProps={{ maxWidth: [120, 200], truncated: true, minWidth: [100, 150], textAlign: 'left' }}
                    selectedRadio={selectedSite?.toString() ?? ''}
                    searchLabel={t('search')}
                    onChange={onSiteChange}
                  />
                </Grid.Row>
              </Grid>
            </FormRow>
          </Grid.Row>
        </Grid>
      </EuiModalBody>

      <EuiModalFooter>
        <Grid mt={['none', 'large']} flexGrow={1} justifyContent="center" gutterSize="medium">
          <Grid.Col>
            <EuiButtonEmpty onClick={onClose}>{t('cancel')}</EuiButtonEmpty>
          </Grid.Col>
          <Grid.Col>
            <EuiButton
              type="submit"
              form="modalFormId"
              onClick={onConfirm}
              fill
              color="success"
              disabled={!selectedSite}
            >
              {t('confirm')}
            </EuiButton>
          </Grid.Col>
        </Grid>
      </EuiModalFooter>
    </Modal>
  )
}

export default React.memo(SelectSiteModal)
