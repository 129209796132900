import React from 'react'

import { EuiColorPicker } from '@elastic/eui'
import styled from '@emotion/styled'

import { StyledViewProps } from 'modules/web-atoms'
import * as system from 'styled-system'

export type IFieldColorPickerProps = Omit<StyledViewProps, 'color'> & React.ComponentProps<typeof EuiColorPicker>

export const StyledFieldColorPicker = styled(EuiColorPicker)<IFieldColorPickerProps>(
  system.color,
  system.flexbox,
  system.space,
  system.layout,
  system.position,
  system.justifyContent,
  system.alignItems,
  system.borders,
  system.shadow,
  system.background,
)

export const FieldColorPicker: React.FC<IFieldColorPickerProps> = (props) => {
  return <StyledFieldColorPicker {...props} />
}

export default React.memo(FieldColorPicker)
