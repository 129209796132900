import React from 'react'
import { FlexGrid, IFlexGridProps } from 'modules/web-atoms'
import ToolbarButton from './ToolbarButton'

const Toolbar: React.FC<IFlexGridProps> = (props) => {
  return (
    <FlexGrid className="notificationEditorFieldToolbar" marginBottom="smallest" padding="smallest" {...props}>
      <ToolbarButton format="bold" />
      <ToolbarButton format="italic" />
      <ToolbarButton format="underline" />
      <ToolbarButton format="code" />
      <ToolbarButton format="heading-one" />
      <ToolbarButton format="heading-two" />
      {/* TODO: Add block-quote, numbered-list, bulleted-list */}
      <ToolbarButton format="left" />
      <ToolbarButton format="center" />
      <ToolbarButton format="right" />
      <ToolbarButton format="justify" />
    </FlexGrid>
  )
}

export default Toolbar
