import { IDevelopmentDto } from 'api'
import { IPaginationParams, QueryKey, SortDirection, usePaginatedApi } from 'modules/module-api'
import { useDebounce } from '../use-debounce/use-debounce'

export enum SortField {
  ADDRESS = 'ADDRESS',
  NAME = 'NAME',
  CITY = 'CITY',
  SIZE = 'SIZE',
}

function useBuildings(
  params: IPaginationParams<SortField> = {
    page: 0,
    pageSize: 20,
    sortBy: SortField.NAME,
    sort: SortDirection.ASC,
    filter: '',
  },
) {
  const debouncedFilter = useDebounce('', 500, false)(params.filter)

  const { data, ...rest } = usePaginatedApi(
    QueryKey.BUILDINGS,
    IDevelopmentDto,
    '/v3/developments/search',
    {
      ...params,
      filter: debouncedFilter,
    },
    {
      enabled: true,
    },
  )

  return { buildings: data, ...rest }
}

export default useBuildings
