import { Currency } from 'modules/module-billing/billing'

export const currencyToCountryCodes: { [key in Currency]?: string[] } = {
  [Currency.GBP]: ['GB', 'GS'],
  [Currency.AUD]: ['AU', 'CX', 'CC', 'NR', 'NF', 'HM'],
  [Currency.EUR]: [
    'AT',
    'BE',
    'CY',
    'EE',
    'FI',
    'FR',
    'DE',
    'GR',
    'IE',
    'IT',
    'LV',
    'LT',
    'LU',
    'MT',
    'NL',
    'PT',
    'SK',
    'SI',
    'ES',
  ],
}
