import { IFeatureFlagsDto } from 'api'
import { handleActions } from 'modules/utils/redux'
import { BRANCH_KEY, INITIAL_STATE } from '../model'
import {
  AUTHENTICATED,
  LOADING,
  SET_CLIENT_ID,
  SET_COUNTRY_CODE,
  SET_FEATURE_FLAGS,
  SET_TENANT,
  SET_USER,
} from './actions'
import { ISessionState, ISetCurrentTenantPayload, ISetCurrentUserPayload } from './models'
import storage from 'redux-persist/lib/storage'
import persistReducer from 'redux-persist/es/persistReducer'

const reducer = handleActions<ISessionState>(
  {
    [SET_USER]: (state: ISessionState, { payload }: { payload: ISetCurrentUserPayload }) => {
      state.userId = payload.userId
      state.email = payload.email
      state.firstName = payload.firstName
      state.lastName = payload.lastName
      state.type = payload.type
      state.primarySite = payload.primarySite
      state.client = payload.client
      state.development = payload.development
      state.role = payload.role
      state.smsEnabled = payload.smsEnabled
      state.phone = payload.phone
    },
    [SET_TENANT]: (state: ISessionState, { payload }: { payload: ISetCurrentTenantPayload }) => {
      state.userId = payload.userId
      state.email = payload.email
      state.firstName = payload.firstName
      state.lastName = payload.lastName
      state.primarySite = payload.primarySite
      state.additionalSites = payload.additionalSites
      state.role = payload.role
    },
    [SET_CLIENT_ID]: (state: ISessionState, { payload }: { payload: number }) => {
      state.clientId = payload
    },
    [LOADING]: (state: ISessionState, { payload }: { payload: boolean }) => {
      state.loading = payload
    },
    [SET_FEATURE_FLAGS]: (state: ISessionState, { payload }: { payload: IFeatureFlagsDto }) => {
      state.featureFlags = payload
    },
    [AUTHENTICATED]: (state: ISessionState, { payload }: { payload: boolean }) => {
      state.isAuthenticated = payload
    },
    [SET_COUNTRY_CODE]: (state: ISessionState, { payload }: { payload: string }) => {
      state.countryCode = payload
    },
  },
  INITIAL_STATE[BRANCH_KEY].session,
)

const persistConfig = {
  key: 'session',
  storage,
  whitelist: ['countryCode'],
}

export const persistedReducer = persistReducer(persistConfig, reducer)

export default persistedReducer
