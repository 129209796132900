import { TenantType } from 'api'

export interface IRecipientProfileState {
  isLoading: boolean
}

export const initialState: IRecipientProfileState = {
  isLoading: false,
}

export interface IUpdateRecipientProfilePayload {
  recipientId: string
  firstName: string
  lastName: string
  email: string
  alias: string | undefined
  phone: string | undefined
  developmentId: number
  room: string
  notificationOptions: number | undefined
  type: TenantType | undefined
  gdprComply: boolean | undefined
  recordModified: string | undefined
}

export interface IChangePasswordPayload {
  email: string
  oldPassword: string
  newPassword: string
}

export interface IDeleteDataPayload {
  recipientId: string
}
