import styled from '@emotion/styled'

// Purely for overriding eui styling
export const ButtonContainer = styled.div`
  .euiButtonContent.euiButton__content {
    padding: 0px;
  }

  .euiButton__text {
    height: 100%;
  }

  /* Removes the weird white background and the bottom border on focus */
  .euiSuperSelectControl {
    background-image: none;
    background-color: transparent;
    border: 1px solid ${(props) => props.theme.colors.primaryMid};
    border-radius: ${(props) => props.theme.radii.small};
    background-color: ${(props) => props.theme.colors.primaryMid}00;
    transition: background-color 0.1s ease-in;

    :hover,
    :focus {
      background-color: ${(props) => props.theme.colors.primaryMid}1A;
    }
  }
`
