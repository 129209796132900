export enum FontSize {
  macro = 12,
  XSmall = 12,
  Small = 14,
  Medium = 15,
  XMedium = 18,
  Large = 20,
  XLarge = 22,
  XXLarge = 25,
  XXXLarge = 30,
  Huge = 40,
  XHuge = 50,
  Massive = 64,
}

export type FontSizeName =
  | 'macro'
  | 'xsmall'
  | 'small'
  | 'medium'
  | 'xmedium'
  | 'large'
  | 'xlarge'
  | 'xxlarge'
  | 'xxxlarge'
  | 'huge'
  | 'xhuge'
  | 'massive'

export type FontSizes = Record<FontSizeName, FontSize>

export const fontSizes: FontSizes = {
  macro: FontSize.macro,
  xsmall: FontSize.XSmall,
  small: FontSize.Small,
  medium: FontSize.Medium,
  xmedium: FontSize.XMedium,
  large: FontSize.Large,
  xlarge: FontSize.XLarge,
  xxlarge: FontSize.XXLarge,
  xxxlarge: FontSize.XXXLarge,
  huge: FontSize.Huge,
  xhuge: FontSize.XHuge,
  massive: FontSize.Massive,
}

export enum FontWeight {
  Light = 300,
  Regular = 400,
  Medium = 500,
  SemiBold = 600,
  Bold = 700,
}

export type FontWeightName = 'light' | 'regular' | 'medium' | 'semiBold' | 'bold'

export type FontWeights = Record<FontWeightName, FontWeight>

export const fontWeights: FontWeights = {
  light: FontWeight.Light,
  regular: FontWeight.Regular,
  medium: FontWeight.Medium,
  semiBold: FontWeight.SemiBold,
  bold: FontWeight.Bold,
}

export enum LineHeight {
  None = '0px',
  Macro = '16px',
  Smallest = '18px',
  XSmall = '18px',
  Small = '22px',
  Medium = '24px',
  Large = '28px',
  XLarge = '34px',
  XHuge = '50px',
}

export type LineHeightName =
  | 'none'
  | 'macro'
  | 'smallest'
  | 'xsmall'
  | 'small'
  | 'medium'
  | 'large'
  | 'xlarge'
  | 'xhuge'

export type LineHeights = Record<LineHeightName, LineHeight>

export const lineHeights: LineHeights = {
  none: LineHeight.None,
  macro: LineHeight.Macro,
  smallest: LineHeight.Smallest,
  xsmall: LineHeight.XSmall,
  small: LineHeight.Small,
  medium: LineHeight.Medium,
  large: LineHeight.Large,
  xlarge: LineHeight.XLarge,
  xhuge: LineHeight.XHuge,
}

export enum LetterSpacing {
  Macro = '0.1px',
  Smallest = '0.5px',
  Small = '1px',
  Medium = '2px',
  Large = '3px',
}

export type LetterSpacingName = 'macro' | 'smallest' | 'small' | 'medium' | 'large'

export type LetterSpacings = Record<LetterSpacingName, LetterSpacing>

export const letterSpacings: LetterSpacings = {
  macro: LetterSpacing.Macro,
  smallest: LetterSpacing.Smallest,
  small: LetterSpacing.Small,
  medium: LetterSpacing.Medium,
  large: LetterSpacing.Large,
}

export enum FontFace {
  SofiaPro = 'sofia-pro',
}
