import { createAction } from '@reduxjs/toolkit'
import { IDevelopmentDto, IOutboundParcelRowItem, IParcelRowItem } from 'api'
import prefix from 'modules/utils/prefix'
import {
  BatchActionPayload,
  IChangeParcelTenantPayload,
  ICreateExternalRecipientPayload,
  ILogNewDeliveryPayload,
  ILogNewOutboundParcelPayload,
  IMarkAsCollectedOutboundPayload,
  IMarkAsCollectedPayload,
  IMarkAsHeldPayload,
  IMoveParcelsPayload,
  IParcelLabelModalPayload,
  IRecipientCreateParcelPayload,
  IScheduleReminderPayload,
  ISetPermissionsPayload,
  ISetSelectedItemsPayload,
  IUpdateInboundParcelPayload,
} from './models'

export const namespaced = prefix('deliveries')

export const SET_ACTIVE_BUILDINGS = namespaced('setActiveBuildings')
export const setActiveBuildings = createAction<IDevelopmentDto[]>(SET_ACTIVE_BUILDINGS)

export const SET_LOADING = namespaced('setLoading')
export const setLoading = createAction<boolean>(SET_LOADING)

export const ERROR = namespaced('error')
export const error = createAction<string>(ERROR)

export const MARK_AS_COLLECTED = namespaced('markAsCollected')
export const markAsCollected = createAction<BatchActionPayload<IMarkAsCollectedPayload>>(MARK_AS_COLLECTED)

export const MARK_AS_COLLECTED_OUTBOUND = namespaced('markAsCollectedOutbound')
export const markAsCollectedOutbound = createAction<IMarkAsCollectedOutboundPayload>(MARK_AS_COLLECTED_OUTBOUND)

export const SET_LOADING_NEW_DELIVERY = namespaced('setLoadingNewDelivery')
export const setLoadingNewDelivery = createAction<boolean>(SET_LOADING_NEW_DELIVERY)

export const LOG_NEW_DELIVERY = namespaced('logNewDelivery')
export const logNewDelivery = createAction<ILogNewDeliveryPayload>(LOG_NEW_DELIVERY)

export const LOG_NEW_OUTBOUND_PARCEL = namespaced('logNewOutboundParcel')
export const logNewOutboundParcel = createAction<ILogNewOutboundParcelPayload>(LOG_NEW_OUTBOUND_PARCEL)

// The ParcelRowItem that's being edited
export const SET_CURRENT_PARCEL = namespaced('setCurrentParcel')
export const setCurrentParcel = createAction<IParcelRowItem | IOutboundParcelRowItem>(SET_CURRENT_PARCEL)

export const UPDATE_INBOUND_PARCEL = namespaced('updateInboundParcel')
export const updateInboundParcel = createAction<IUpdateInboundParcelPayload>(UPDATE_INBOUND_PARCEL)

export const SET_SHOW_MARK_AS_COLLECTED_MODAL = namespaced('setShowMarkAsCollectedModal')
export const setShowMarkAsCollectedModal = createAction<boolean>(SET_SHOW_MARK_AS_COLLECTED_MODAL)

export const SET_PERMISSIONS = namespaced('setPermissions')
export const setPermissions = createAction<ISetPermissionsPayload>(SET_PERMISSIONS)

export const SET_SELECTED_INBOUND_PARCELS = namespaced('setSelectedInboundParcels')
export const setSelectedInboundParcels = createAction<ISetSelectedItemsPayload>(SET_SELECTED_INBOUND_PARCELS)

export const SET_SELECTED_OUTBOUND_PARCELS = namespaced('setSelectedOutboundParcels')
export const setSelectedOutboundParcels = createAction<ISetSelectedItemsPayload>(SET_SELECTED_OUTBOUND_PARCELS)

export const SET_INBOUND_COLUMN_SELECTION = namespaced('setInboundColumnSelection')
export const setInboundColumnSelection = createAction<string[]>(SET_INBOUND_COLUMN_SELECTION)

export const SET_OUTBOUND_COLUMN_SELECTION = namespaced('setOutboundColumnSelection')
export const setOutboundColumnSelection = createAction<string[]>(SET_OUTBOUND_COLUMN_SELECTION)

export const SET_SELECTED_TAGS = namespaced('setSelectedTags')
export const setSelectedTags = createAction<number[]>(SET_SELECTED_TAGS)

export const MOVE_PARCELS = namespaced('moveParcels')
export const moveParcels = createAction<BatchActionPayload<IMoveParcelsPayload>>(MOVE_PARCELS)

export const CREATE_EXTERNAL_RECIPIENT = namespaced('createExternalRecipient')
export const createExternalRecipient = createAction<ICreateExternalRecipientPayload>(CREATE_EXTERNAL_RECIPIENT)

export const RECIPIENT_CREATE_PARCEL = namespaced('recipientCreateParcel')
export const recipientCreateParcel = createAction<IRecipientCreateParcelPayload>(RECIPIENT_CREATE_PARCEL)

export const SCHEDULE_REMINDER = namespaced('scheduleReminder')
export const scheduleReminder = createAction<IScheduleReminderPayload>(SCHEDULE_REMINDER)

export const MARK_AS_HELD = namespaced('markAsHeld')
export const markAsHeld = createAction<BatchActionPayload<IMarkAsHeldPayload>>(MARK_AS_HELD)

export const SET_PARCEL_LABEL_MODAL = namespaced('setParcelLabelModal')
export const setParcelLabelModal = createAction<IParcelLabelModalPayload>(SET_PARCEL_LABEL_MODAL)

export const CHANGE_PARCEL_TENANT = namespaced('changeParcelTenant')
export const changeParcelTenant = createAction<IChangeParcelTenantPayload>(CHANGE_PARCEL_TENANT)
