import { UserType } from 'api'

export namespace permissions {
  export const canCreateBuilding: UserType[] = [UserType.OWNER, UserType.DEBUG]
  export const canRemoveBuilding: UserType[] = [UserType.OWNER, UserType.DEBUG]
  export const canEditBuilding: UserType[] = [UserType.OWNER, UserType.ADMIN, UserType.DEBUG]
  export const canViewBuilding: UserType[] = [
    UserType.OWNER,
    UserType.ADMIN,
    UserType.DEBUG,
    UserType.NORMAL,
    UserType.BASIC,
  ]
}

export default permissions
