import { BannerType, ToastType } from 'modules/web-atoms'

export interface IAlertState {
  toasts: IToast[]
  banner?: IBanner
}

export const initialState: IAlertState = {
  toasts: [],
}

export interface IToast {
  id: string
  title?: string
  message: string
  type: ToastType
}

export interface IShowToastParams {
  title?: string
  message: string
  type?: ToastType
}
export type IShowTypedToastParams = Omit<IShowToastParams, 'type'>

export type IShowToastPayload = IToast
export interface ICopyPayload extends IShowToastParams {
  value: string
}
export interface IBanner {
  id: string
  message: string
  actionMessage: string
  type: BannerType
  // in milliseconds
  autohide?: number
  isDismissable?: boolean
}

export type IShowBannerPayload = IBanner

export interface IShowBannerParams {
  id: string
  message: string
  actionMessage: string
  type: BannerType
}

export type IShowTypedBannerParams = Omit<IShowBannerParams, 'type'>
