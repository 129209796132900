import autobind from 'autobind-decorator'
import axios from 'axios'
import { logError } from 'modules/logging/Sentry'
import { Currency } from 'modules/module-billing/billing'
import { REACT_APP_IP_LOOKUP_URL } from 'modules/utils/constants'
import { currencyToCountryCodes } from '../constants'

export class GeoService {
  @autobind
  async determineCountry(): Promise<string | undefined> {
    try {
      const { data } = await axios.get(REACT_APP_IP_LOOKUP_URL)
      return data?.countryCode
    } catch (error) {
      logError(error)
    }

    return undefined
  }

  @autobind
  determineRegionCurrency(countryCode: string | undefined): Currency {
    if (countryCode) return this.mapCountryToCurrency(countryCode)
    else return Currency.USD
  }

  @autobind
  private mapCountryToCurrency(countryCode: string): Currency {
    let regionCurrency = Currency.USD
    for (const currency in currencyToCountryCodes) {
      const countryCodes = currencyToCountryCodes[currency]
      if (countryCodes.includes(countryCode)) {
        regionCurrency = currency as Currency
        break
      }
    }
    return regionCurrency
  }
}

export const geoService = new GeoService()

export default geoService
