import { EuiTablePagination } from '@elastic/eui'
import { ParcelUnavailable } from 'modules/dna'
import { Grid, ImagePreview, useTranslate } from 'modules/web-atoms'
import React, { useState } from 'react'

interface IImageCarouselProps extends React.ComponentProps<typeof Grid> {
  photos: string[]
}

export const ImageCarousel: React.FC<IImageCarouselProps> = ({ photos, ...rest }) => {
  const t = useTranslate('web-molecules.image-carousel')
  const [activePage, setActivePage] = useState(0)
  const [hasLoaded, setHasLoaded] = useState(false)

  const onChange = (page: number) => {
    setActivePage(page)
  }

  const isImagePreviewVisible = photos.length > 0

  return (
    <Grid {...rest}>
      <Grid.Row
        height={[200, 227, 200, 360]}
        alignItems="center"
        justifyContent="center"
        borderWidth="small"
        borderColor="secondaryMid"
        borderStyle="solid"
        borderRadius="small"
        backgroundColor="primaryLight"
        overflow="hidden"
        width="100%"
      >
        {!!photos.length && isImagePreviewVisible && (
          <ImagePreview
            padding="macro"
            objectFit="contain"
            height="100%"
            width="100%"
            borderRadius="small"
            fallback={ParcelUnavailable}
            src={photos[activePage]}
            hidden={!hasLoaded}
            onLoad={() => setHasLoaded(true)}
            alt={t('photo', { index: activePage })}
          />
        )}
        {!isImagePreviewVisible && (
          <img
            style={{ objectFit: 'contain', width: 120, height: 120 }}
            hidden={hasLoaded}
            src={ParcelUnavailable}
            alt={t('image-unavailable')}
          />
        )}
      </Grid.Row>

      {!!photos.length && (
        <Grid.Row>
          <EuiTablePagination
            pageCount={photos.length}
            activePage={activePage}
            onChangePage={onChange}
            showPerPageOptions={false}
          />
        </Grid.Row>
      )}
    </Grid>
  )
}

export default React.memo(ImageCarousel)
