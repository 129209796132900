import { EuiButton } from '@elastic/eui'
import { CircleTick, Uploading } from 'modules/dna'
import { useAppDispatch, useAppSelector } from 'modules/module-utils'
import { FlexItem, Grid, H2, Icon, Image, Paragraph, useTranslate } from 'modules/web-atoms'
import * as React from 'react'
import upload, { UploadingStatus } from '../../../upload'
import lazy from '@loadable/component'

export interface IUploadProgressProps extends React.ComponentProps<typeof Grid> {}

export const Lottie = lazy(async () => import('react-lottie'))

export const UploadProgress: React.FC<IUploadProgressProps> = (props) => {
  const { ...rest } = props

  const t = useTranslate('recipients-upload.upload.uploading')
  const tCommon = useTranslate('commons')
  const dispatch = useAppDispatch()

  const uploading = useAppSelector(upload.selectors.uploading)
  const progress = useAppSelector(upload.selectors.progress)
  const total = useAppSelector(upload.selectors.total)
  const error = useAppSelector(upload.selectors.error)
  const isWiping = useAppSelector(upload.selectors.isSyncing)

  React.useEffect(() => {}, [uploading])

  const duplications = React.useMemo(() => {
    if (uploading && uploading?.duplicates > 0) {
      return (
        <Paragraph fontWeight="bold" color="dangerDestructive" textAlign="center">
          {t('duplicates', { count: uploading.duplicates, duplicates: uploading.duplicates })}
        </Paragraph>
      )
    } else {
      return undefined
    }
  }, [t, uploading])

  const errored = React.useMemo(() => {
    if (uploading && uploading?.errored > 0) {
      return (
        <Paragraph size="large" fontWeight="bold" color="dangerDestructive" textAlign="center">
          {t('errors', { count: uploading.errored, errored: uploading.errored })}
        </Paragraph>
      )
    } else {
      return undefined
    }
  }, [t, uploading])

  const lottieOptions = React.useMemo(() => {
    return {
      loop: true,
      autoplay: true,
      animationData: Uploading,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    }
  }, [])

  const retryUpload = () => {
    // todo: improve flow. Need to avoid reasking for site.
    dispatch(upload.actions.uploadRecipients())
  }

  const cancelUpload = () => {
    dispatch(upload.actions.resetUpload())
  }

  const onFinish = () => {
    dispatch(upload.actions.navigateRecipientUpload('finish'))
  }

  return (
    <FlexItem grow={true} alignItems="center" flex={1}>
      <Grid justifyContent="space-between" flex={1}>
        <Grid justifyContent="center" paddingX="large" marginTop="large" marginBottom="medium" flex={1}>
          <Grid alignItems="center">
            <Grid.Row>
              {uploading?.status === UploadingStatus.IN_PROGRESS && (
                <Grid margin="small">
                  <Lottie options={lottieOptions} height={250} width={250} />
                </Grid>
              )}
              {uploading?.status === UploadingStatus.PROCESS_FAILED && <Icon.ErrorAlt height={250} width={250} />}
              {uploading?.status == UploadingStatus.COMPLETED && <Image src={CircleTick} height={250} width={250} />}
            </Grid.Row>

            <Grid.Row marginTop="small">
              {uploading?.status === UploadingStatus.IN_PROGRESS && (
                <H2 textAlign="center">{t('in-progress.heading', { progress, total })}</H2>
              )}

              {uploading?.status == UploadingStatus.COMPLETED && (
                <H2>{t('completed.heading', { uploaded: uploading?.uploaded, total })}</H2>
              )}
            </Grid.Row>

            {!!duplications && <Grid.Row marginTop="small">{duplications}</Grid.Row>}
            {!!errored && <Grid.Row marginTop="small">{errored}</Grid.Row>}
            {!!isWiping && <Grid.Row marginTop="small">{t('wiping')}</Grid.Row>}
          </Grid>
        </Grid>
        <Grid marginTop={['large', 'xxlarge']} marginBottom="large" justifyContent="center" alignItems="center">
          <Grid.Col marginLeft="smallest">
            {uploading?.status === UploadingStatus.PROCESS_FAILED && (
              <Grid gutterSize="medium">
                <Grid.Col>
                  <EuiButton onClick={cancelUpload}>
                    <Paragraph color="primaryMid">{tCommon('action.cancel')}</Paragraph>
                  </EuiButton>
                </Grid.Col>

                <Grid.Col>
                  <EuiButton color="primary" onClick={retryUpload} fill>
                    {tCommon('action.retry')}
                  </EuiButton>
                </Grid.Col>
              </Grid>
            )}

            {uploading?.status === UploadingStatus.COMPLETED && (
              <Grid.Row marginTop="large">
                <EuiButton fill onClick={onFinish}>
                  {tCommon('action.finish')}
                </EuiButton>
              </Grid.Row>
            )}
          </Grid.Col>
        </Grid>
      </Grid>
    </FlexItem>
  )
}

export default React.memo(UploadProgress)
