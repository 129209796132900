import * as React from 'react'
import { Trans } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { EuiButton, EuiButtonEmpty } from '@elastic/eui'

import theme, { CSVList, OneByOne } from 'modules/dna'
import { FlexItem, Grid, H1, H2, H3, Image, Page, RadioButton, Span, useTranslate } from 'modules/web-atoms'
import { RadioGroup } from 'modules/web-molecules'

import board from '../../../board'

const INTL_PREFIX = 'dashboard.get-started.select-flow-step'

export enum UploadingMethod {
  FILE = 'file',
  MANUAL = 'manual',
}

export interface ISelectUploadFlowScreenProps {}

export const SelectUploadFlowScreen: React.FC<ISelectUploadFlowScreenProps> = (props) => {
  const t = useTranslate(INTL_PREFIX)
  const tCommon = useTranslate('commons')
  const dispatch = useDispatch()

  const radioOptions = React.useMemo(() => {
    return [
      {
        value: UploadingMethod.FILE,
        image: CSVList,
        legend: (
          <H3 textAlign="center" size="large" paddingX="medium" lineHeight="small">
            <Trans
              i18nKey={`${INTL_PREFIX}.list-flow`}
              components={{ span: <Span fontWeight="bold" color="primaryMid" />, br: <br /> }}
            />
          </H3>
        ),
      },
      {
        value: UploadingMethod.MANUAL,
        image: OneByOne,
        legend: (
          <H3 textAlign="center" size="large" paddingX="medium">
            <Trans
              i18nKey={`${INTL_PREFIX}.one-by-one-flow`}
              components={{ span: <Span fontWeight="bold" color="primaryMid" /> }}
            />
          </H3>
        ),
      },
    ]
  }, [])

  const [uploadMethod, setUploadMethod] = React.useState<UploadingMethod | undefined>()

  const handleNext = React.useCallback(
    (_) => {
      !!uploadMethod && dispatch(board.actions.setUploadingMethod(uploadMethod))
    },
    [dispatch, uploadMethod],
  )

  const handleBack = React.useCallback(() => {
    dispatch(board.actions.navigateOnboarding('previous'))
  }, [dispatch])

  const onChange = React.useCallback((value) => {
    setUploadMethod(value)
  }, [])

  return (
    <Page title={t('title')}>
      <FlexItem grow={true} alignItems="center" flex={1} marginBottom="large">
        <Grid alignItems="center" marginBottom="medium" height="100%" flex={1} justifyContent="center">
          <Grid>
            <H1 textAlign="center">{t('heading')}</H1>
            <H2 marginTop="smallest" textAlign="center">
              {t('subheading')}
            </H2>
            <RadioGroup marginTop={['large', 'xxlarge']} onChange={onChange}>
              {radioOptions.map(({ value, image, legend }) => (
                <RadioButton.Card containerStyle={{ maxHeight: 250 }} id={value} key={value} legend={legend}>
                  <Image src={image} minWidth={100} width={170} />
                </RadioButton.Card>
              ))}
            </RadioGroup>
          </Grid>
        </Grid>
        <Grid marginTop="large" justifyContent="center" alignItems="center">
          <Grid.Col>
            <EuiButtonEmpty onClick={handleBack}>{tCommon('action.back')}</EuiButtonEmpty>
          </Grid.Col>
          <Grid.Col>
            <EuiButton fill onClick={handleNext} style={{ marginLeft: theme.spacing.large }}>
              {tCommon('action.next')}
            </EuiButton>
          </Grid.Col>
        </Grid>
      </FlexItem>
    </Page>
  )
}

export default React.memo(SelectUploadFlowScreen)
