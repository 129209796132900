/* eslint-disable no-case-declarations */
import React, { useCallback, useMemo, useState } from 'react'
import { Descendant, Editor, Range, Transforms, createEditor } from 'slate'
import { withHistory } from 'slate-history'
import { Editable, RenderElementProps, RenderLeafProps, Slate, withReact } from 'slate-react'
import Element from './elements/Element/Element'
import Leaf from './elements/Leaf/Leaf'
import withTags from './hooks/withTags'

import { EuiLoadingSpinner } from '@elastic/eui'
import { Grid } from 'modules/web-atoms'
import { EditableProps } from 'slate-react/dist/components/editable'
import { TagElement } from 'types/slate-types'
import Toolbar from './components/Toolbar/Toolbar'
import { insertTag, withErrorHandler } from './editorUtils'
import TagPortal, { ITagOption } from './elements/Tag/TagPortal/TagPortal'
import { useForceUpdate } from './hooks/use-force-update/use-force-update'

export interface IFieldTextEditorRef {
  insertTag: (tagId: string, character: string, props: Pick<TagElement, 'backgroundColor' | 'textColor'>) => void
}

export type IFieldTextEditorProps = Omit<EditableProps, 'value' | 'onChange' | 'ref'> & {
  value: Descendant[]
  tags?: Array<ITagOption<string>>
  loading?: boolean
  toolbar?: boolean
  onChange: (value: Descendant[]) => void
}

const FieldTextEditor: React.ForwardRefRenderFunction<IFieldTextEditorRef, IFieldTextEditorProps> = (
  { tags, value, loading, toolbar, onChange, ...rest },
  ref,
) => {
  const [target, setTarget] = useState<Range | undefined>()
  const [portalIndex, setPortalIndex] = useState(0)
  const [search, setSearch] = useState('')
  const [prevValue, setPrevValue] = useState<Descendant[] | undefined>(undefined)

  const forceUpdate = useForceUpdate()

  const renderLeaf = useCallback((props: RenderLeafProps) => <Leaf {...props} />, [])
  const renderElement = useCallback((props: RenderElementProps) => <Element {...props} />, [])
  const editor = useMemo(() => withTags(withReact(withHistory(withErrorHandler(createEditor())))), [])

  const chars = React.useMemo(() => {
    return tags?.filter((tag) => tag.label.toLowerCase().includes(search.toLowerCase())).slice(0, 10)
  }, [search, tags])

  React.useImperativeHandle(ref, () => ({
    insertTag(tagId, character, props: Pick<TagElement, 'backgroundColor' | 'textColor'>) {
      insertTag(editor, tagId, character, props)
      setTarget(undefined)
    },
  }))

  const onKeyDown = useCallback(
    (event) => {
      if (target && chars) {
        switch (event.key) {
          case 'ArrowDown':
            event.preventDefault()
            const prevIndex = portalIndex >= chars.length - 1 ? 0 : portalIndex + 1
            setPortalIndex(prevIndex)
            break
          case 'ArrowUp':
            event.preventDefault()
            const nextIndex = portalIndex <= 0 ? chars.length - 1 : portalIndex - 1
            setPortalIndex(nextIndex)
            break
          case 'Tab':
          case 'Enter':
            event.preventDefault()
            Transforms.select(editor, target)
            insertTag(editor, chars[portalIndex].value, chars[portalIndex].label, {
              backgroundColor: chars[portalIndex].backgroundColor,
              textColor: chars[portalIndex].textColor,
            })
            setTarget(undefined)
            break
          case 'Escape':
            event.preventDefault()
            setTarget(undefined)
            break
        }
      }
    },
    [chars, editor, portalIndex, target],
  )

  const onEditChange = React.useCallback(
    (newValue: Descendant[]) => {
      const { selection } = editor

      if (selection && Range.isCollapsed(selection)) {
        const [start] = Range.edges(selection)
        const wordBefore = Editor.before(editor, start, { unit: 'word' })
        const before = wordBefore && Editor.before(editor, wordBefore)
        const beforeRange = before && Editor.range(editor, before, start)
        const beforeText = beforeRange && Editor.string(editor, beforeRange)
        const beforeMatch = beforeText && beforeText.match(/^@(\w+)$/)
        const after = Editor.after(editor, start)
        const afterRange = Editor.range(editor, start, after)
        const afterText = Editor.string(editor, afterRange)
        const afterMatch = afterText.match(/^(\s|$)/)

        if (beforeMatch && afterMatch) {
          setTarget(beforeRange)
          setSearch(beforeMatch[1])
          setPortalIndex(0)
          return
        }
      }
      const isAstChange = editor.operations.some((op) => 'set_selection' !== op.type)
      if (isAstChange && newValue.length) {
        setPrevValue(newValue)
        onChange(newValue)
      }

      setTarget(undefined)
    },
    [editor, onChange],
  )

  const onTagClick = React.useCallback(
    (char: string) => {
      const tag = chars?.find((tag) => tag.label == char)

      if (!!tag && target) {
        Transforms.select(editor, target)
        insertTag(editor, tag.value, tag.label, {
          backgroundColor: tag.backgroundColor,
          textColor: tag.textColor,
        })
        setTarget(undefined)
      }
    },
    [chars, editor, target],
  )

  const [hasUpdated, setHasUpdated] = React.useState(false)
  // Hacky solution to only update when data is fetched from network
  React.useEffect(() => {
    if ((!loading && !hasUpdated) || !prevValue) {
      setHasUpdated(true)
      editor.children = value
      forceUpdate()
      return
    }
    if (loading && hasUpdated) {
      setHasUpdated(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, prevValue])

  React.useEffect(() => {
    if (loading && hasUpdated) {
      setPrevValue(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  return (
    <Slate editor={editor} initialValue={value} onChange={onEditChange}>
      <Grid
        className={`notificationEditorField ${loading ? 'notificationEditorField--isLoading' : ''}`}
        position="relative"
      >
        <Grid.Col>
          {toolbar && <Toolbar />}
          <Editable renderElement={renderElement} renderLeaf={renderLeaf} onKeyDown={onKeyDown} {...rest} />
        </Grid.Col>
        {loading && (
          <Grid.Col position="absolute" right={12} top={12} bottom={12}>
            <EuiLoadingSpinner />
          </Grid.Col>
        )}
      </Grid>

      <TagPortal
        target={target}
        chars={chars?.map((s) => s.label)}
        editor={editor}
        onClick={onTagClick}
        index={portalIndex}
      />
    </Slate>
  )
}

export default React.forwardRef(FieldTextEditor)
