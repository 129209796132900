import { QueryFunction, QueryKey, useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query'
import orchestration from 'modules/module-orchestration/orchestration'
import reporting from 'modules/module-reporting'
import { useAppDispatch } from 'modules/module-utils'
import { QueryKey as AppQueryKey } from '../../query'

const useApi = <TQueryFnData = unknown, TError = unknown, TData = TQueryFnData, TQueryKey extends QueryKey = QueryKey>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<TQueryFnData, TQueryKey>,
  options?: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
): UseQueryResult<TData, TError> => {
  const dispatch = useAppDispatch()

  const result = useQuery(
    queryKey,
    async (ctx) => {
      try {
        const data = await queryFn(ctx)
        return data
      } catch (err) {
        dispatch(reporting.actions.error(err))
        throw err
      }
    },
    {
      ...options,
      onSettled: (data, error) => {
        if (options?.onSettled) options?.onSettled(data, error)

        const appQueryKeys: string[] = Object.values(AppQueryKey)
        const key = queryKey.find((key) => typeof key === 'string' && appQueryKeys.includes(key))
        if (key) dispatch(orchestration.actions.queryResult(key as AppQueryKey)({ success: !error }))
      },
    },
  )

  return result
}

export default useApi
