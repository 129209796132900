import { useAppSelector } from 'modules/module-utils'
import { useTranslate } from 'modules/web-atoms'
import React from 'react'
import settings from '../../../settings'
import CouriersForm from './CouriersForm'
import CouriersTable from './CouriersTable'
import SettingsTableFormTemplate, {
  ISettingsTableFormTemplateProps,
} from '../../../components/SettingsTableFormTemplate/SettingsTableFormTemplate'

export interface ICouriersTabProps extends Partial<ISettingsTableFormTemplateProps> {}

const CouriersTab: React.FC<ICouriersTabProps> = ({ ...rest }) => {
  const t = useTranslate('settings.tabs.couriers')
  const isFormVisible = useAppSelector(settings.selectors.isCourierFormVisible)
  const { canManageCouriers } = useAppSelector(settings.selectors.permissions)

  return (
    <SettingsTableFormTemplate
      heading={t('heading')}
      description={t('description')}
      isFormVisible={isFormVisible}
      hasManagePermissions={canManageCouriers}
      {...rest}
    >
      <SettingsTableFormTemplate.Table>
        <CouriersTable />
      </SettingsTableFormTemplate.Table>

      <SettingsTableFormTemplate.Form>
        <CouriersForm />
      </SettingsTableFormTemplate.Form>
    </SettingsTableFormTemplate>
  )
}

export default React.memo(CouriersTab)
