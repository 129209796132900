/* eslint-disable no-undef */
import { createAction } from 'redux-actions'

import analytics, { AnalyticalEvent, AnalyticalEventValue } from 'modules/module-analytics'
import { identityFor } from 'modules/utils'
import prefix from 'modules/utils/prefix'

import { SelectAccountDto } from '../api/dto'
import {
  IAccountTypePayload,
  IFinishSignupPayload,
  ILoggedInPayload,
  ILoginPayload,
  IOnboardingStagePayload,
  ISignedUpPayload,
  ISignupPayload,
} from './'
import {
  IGetLoginModePayload,
  ISendAppLinkPayload,
  ISetupBuildingPayload,
  IUserSetupRequestPayload,
  LoginMode,
  LoginStep,
  SignupStep,
} from './models'

export const namespaced = prefix('onboarding/authentication')

// actions
export const LOGIN = namespaced('login')
export const login = createAction<ILoginPayload>(LOGIN)

export const LOGGED_IN = namespaced('loggedIn')
export const loggedIn = createAction(LOGGED_IN, identityFor<ILoggedInPayload>(), (_) =>
  analytics.meta.whitelist({
    name: AnalyticalEvent.Login,
  }),
)

export const SIGN_UP = namespaced('signup')
export const signup = createAction(SIGN_UP, identityFor<ISignupPayload>(), (payload) =>
  analytics.meta.whitelist({
    data: {
      key: AnalyticalEventValue.AccountCreation_STARTED,
      email: payload.email,
    },
    name: AnalyticalEvent.AccountCreation,
  }),
)

export const SIGNED_UP = namespaced('signedUp')
export const signedUp = createAction<ISignedUpPayload>(SIGNED_UP)

export const SIGNUP_STEP = namespaced('signupStep')
export const signupStep = createAction<SignupStep | null>(SIGNUP_STEP)

export const LOGIN_STEP = namespaced('loginStep')
export const loginStep = createAction<LoginStep>(LOGIN_STEP)

export const SAVE_ACCOUNT_TYPE = namespaced('saveAccountType')
export const saveAccountType = createAction(SAVE_ACCOUNT_TYPE, identityFor<IAccountTypePayload>(), (payload) =>
  analytics.meta.whitelist({
    data: { ...payload },
    name: AnalyticalEvent.AccountType,
  }),
)

export const START_USER_LOGGED_IN_POLL = namespaced('startUserLoggedInPoll')
export const startUserLoggedInPoll = createAction(START_USER_LOGGED_IN_POLL)

export const STOP_USER_LOGGED_IN_POLL = namespaced('stopUserLoggedInPoll')
export const stopUserLoggedInPoll = createAction(STOP_USER_LOGGED_IN_POLL)

export const START_PARCEL_SCANNED_POLL = namespaced('startParcelScannedInPoll')
export const startParcelScannedInPoll = createAction(START_PARCEL_SCANNED_POLL)

export const STOP_PARCEL_SCANNED_POLL = namespaced('stopParcelScannedInPoll')
export const stopParcelScannedInPoll = createAction(STOP_PARCEL_SCANNED_POLL)

export const SEND_APP_LINK = namespaced('sendAppLink')
export const sendAppLink = createAction(SEND_APP_LINK, identityFor<ISendAppLinkPayload>(), (payload) =>
  analytics.meta.whitelist({
    data: { ...payload },
    name: AnalyticalEvent.SendSms,
  }),
)

export const SAVE_ONBOARDING_STAGE = namespaced('saveOnboardingStage')
export const saveOnboardingStage = createAction(
  SAVE_ONBOARDING_STAGE,
  identityFor<IOnboardingStagePayload>(),
  (payload) =>
    analytics.meta.whitelist({
      data: { ...payload },
      name: AnalyticalEvent.OnboardingStage,
    }),
)

export const FINISH_SIGNUP = namespaced('finishSignup')
export const finishSignup = createAction(FINISH_SIGNUP, identityFor<IFinishSignupPayload>(), (_) =>
  analytics.meta.whitelist({
    data: { key: AnalyticalEventValue.AccountCreation_Completed },
    name: AnalyticalEvent.AccountCreation,
  }),
)
export const FORGOTTEN = namespaced('forgotten')
export const forgotten = createAction(FORGOTTEN)

export const SETUP_BUILDING = namespaced('setupBuilding')
export const setupBuilding = createAction(SETUP_BUILDING, identityFor<ISetupBuildingPayload>(), (payload) =>
  analytics.meta.whitelist({
    data: { ...payload },
    name: AnalyticalEvent.BuildingInfoSet,
  }),
)

export const SET_ACCOUNT_LIST = namespaced('setAccountList')
export const setAccountList = createAction<SelectAccountDto[]>(SET_ACCOUNT_LIST)

export const FINISH_MULTI_LOGIN = namespaced('finishMultiLogin')
export const finishMultiLogin = createAction<{ userId: string }>(FINISH_MULTI_LOGIN)

export const CANCEL_MULTI_LOGIN = namespaced('cancelMultiLogin')
export const cancelMultiLogin = createAction(CANCEL_MULTI_LOGIN)

// action results
export const LOADING = namespaced('loading')
export const loading = createAction<boolean>(LOADING)

export const ERROR = namespaced('error')
export const error = createAction(ERROR, identityFor<Error | unknown>(), (payload) =>
  analytics.meta.whitelist({
    data: { message: payload?.message ?? '' },
    name: AnalyticalEvent.Error,
  }),
)

export const CLEAR_ERROR = namespaced('clearError')
export const clearError = createAction(CLEAR_ERROR)

export const SET_LOGIN_MODE = namespaced('setLoginMode')
export const setLoginMode = createAction<LoginMode | undefined>(SET_LOGIN_MODE)

export const GET_LOGIN_MODE = namespaced('getLoginMode')
export const getLoginMode = createAction<IGetLoginModePayload>(GET_LOGIN_MODE)

export const USER_SETUP_REQUEST = namespaced('userSetupRequest')
export const userSetupRequest = createAction<IUserSetupRequestPayload>(USER_SETUP_REQUEST)

type Action = typeof login | typeof signup | typeof forgotten | typeof loading | typeof error
export default Action
