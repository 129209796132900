import { SortDirection } from 'modules/module-api'
import { IUsePaginatedTableParams, useAppDispatch, useAppSelector, usePaginatedTable } from 'modules/module-utils'
import { H5, Paragraph, useTranslate } from 'modules/web-atoms'
import {
  IColumns,
  IPagination,
  ITableItem,
  ITableSelectedChange,
  TableColumnType,
  TableSelectionAction,
} from 'modules/web-molecules'
import React from 'react'
import settings, { SettingsFormState } from '../../settings'
import useCouriers, { CouriersSortField } from '../use-couriers/use-couriers'

export type ICouriersTableColumns = CouriersSortField
export type ICouriersTableItem = ITableItem<CouriersSortField>

export const useCouriersTable = (siteId: number | undefined) => {
  const t = useTranslate('settings.tabs.couriers.table')
  const dispatch = useAppDispatch()

  const selectedCourier = useAppSelector(settings.selectors.selectedCourier)
  const selectedCouriers = useAppSelector(settings.selectors.selectedCouriers)

  const onSortChange = () => {
    dispatch(settings.actions.setSelectedCourier(undefined))
  }

  const onSelectionChange: ITableSelectedChange<ICouriersTableColumns, ICouriersTableItem> = (
    items: ICouriersTableItem[],
    action: TableSelectionAction,
  ) => {
    dispatch(
      settings.actions.setSelectedCouriers({
        items: items.map((item) => item.id),
        action,
      }),
    )
  }

  const paginationParams: IUsePaginatedTableParams<
    ICouriersTableColumns,
    ICouriersTableItem,
    CouriersSortField,
    typeof CouriersSortField
  > = {
    sortClass: CouriersSortField,
    pageParams: {
      page: 0,
      pageSize: 10,
      sortBy: CouriersSortField.NAME,
      sort: SortDirection.ASC,
      filter: '',
    },
    itemsPerPage: [10, 20, 50],
    onSortChange,
    onSelectionChange,
  }

  const { paging, paginationOptions, onSearch, selection, sort } = usePaginatedTable<
    ICouriersTableColumns,
    ICouriersTableItem,
    CouriersSortField,
    typeof CouriersSortField
  >(paginationParams)

  const { couriers, isFetching, totalItems, error } = useCouriers(
    {
      page: paging.page + 1,
      sortBy: paging.sortBy,
      sort: paging.sort,
      pageSize: paging.pageSize,
      filter: paging.filter,
    },
    {
      enabled: !!siteId,
      cacheTime: 0,
    },
  )

  const onItemSelected = (item: ICouriersTableItem) => {
    if (selectedCourier?.id === item.id) {
      dispatch(settings.actions.setSelectedCourier(undefined))
      dispatch(settings.actions.setCourierFormState(SettingsFormState.HIDDEN))
    } else {
      const courier = couriers.find((courier) => courier.id === item.id)
      dispatch(settings.actions.setSelectedCourier(courier))
      dispatch(settings.actions.setCourierFormState(SettingsFormState.EDIT))
    }
  }

  const pagination: IPagination = {
    pageIndex: paging.page,
    pageSize: paging.pageSize,
    total: totalItems,
  }

  const columns: IColumns<ICouriersTableColumns> = {
    [CouriersSortField.NAME]: {
      label: <H5 padding="smallest">{t('column.name')}</H5>,
      type: TableColumnType.Column,
      isSortable: true,
    },
    [CouriersSortField.TYPE]: {
      label: <H5 padding="smallest">{t('column.type')}</H5>,
      type: TableColumnType.Column,
      isSortable: false,
    },
  }

  const items: ICouriersTableItem[] = React.useMemo(() => {
    return couriers.map((courier) => {
      const id = courier.id
      return {
        id,
        isSelectable: true,
        isChecked: !!selectedCouriers.find((itemId) => itemId === id),
        isSelected: !!selectedCourier && id === selectedCourier.id,
        isCheckable: true,
        [CouriersSortField.NAME]: {
          render: () => (
            <Paragraph title={courier.name} fontWeight="bold" size="medium" padding="smallest" truncated={true}>
              {courier.name}
            </Paragraph>
          ),
        },
        [CouriersSortField.TYPE]: {
          render: () => {
            return !courier.clientId ? (
              <Paragraph title={t('default')} fontWeight="bold" size="medium" padding="smallest" truncated={true}>
                {t('default')}
              </Paragraph>
            ) : (
              <></>
            )
          },
        },
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [couriers, selectedCourier, selectedCouriers])

  return {
    columns,
    items,
    selection,
    onSearch,
    onItemClicked: onItemSelected,
    isFetching,
    error,
    pagination,
    sort,
    paginationOptions,
    selectedCouriers: selectedCouriers,
  }
}
