import { Action } from 'redux-actions'
import { SagaIterator } from 'redux-saga'
import { all, put, takeLatest } from 'redux-saga/effects'

import autobind from 'autobind-decorator'
import { goBack, goForward, push, replace } from 'connected-react-router'
import { SupervisorSuite } from 'modules/redux-supervisor'

import navigation from '../navigator'
import { INavigatePayload } from '../navigator/models'
import { queryParamsToString } from 'modules/module-utils/queryParams'

export class NavigationSaga extends SupervisorSuite {
  @autobind
  *start(): SagaIterator {
    yield all([
      takeLatest(navigation.actions.NAVIGATE, this.navigate),
      takeLatest(navigation.actions.BACK, this.back),
      takeLatest(navigation.actions.FORWARD, this.forward),
      takeLatest(navigation.actions.RESET, this.reset),
    ])
  }

  @autobind
  *navigate(action: Action<INavigatePayload>): SagaIterator {
    const { route, query, state } = action.payload
    const search = query ? queryParamsToString(query) : undefined

    //TODO: analytics
    
    yield put(push({ pathname: route, search, state }))
    yield put(navigation.actions.navigated())
  }

  @autobind
  *reset(action: Action<string>): SagaIterator {
    yield put(replace(action.payload))
  }

  @autobind
  *back(): SagaIterator {
    yield put(goBack())
  }

  @autobind
  *forward(): SagaIterator {
    yield put(goForward())
  }
}

export default NavigationSaga
