import { emailRegex } from 'modules/module-utils/formValidations'

import { IDevelopmentDto } from 'api'
import { ContactPreference, FieldViolation, IdFrequencyMap, RecipientField, RecipientType } from './types'

const DATE_REGEX = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/

export const validateFieldValue = (
  field: RecipientField,
  value: string | undefined,
  recipientType: string | undefined = undefined,
  idFrequencyMap: IdFrequencyMap,
  sites: IDevelopmentDto[],
): FieldViolation | undefined => {
  let error: FieldViolation | undefined = undefined
  switch (field) {
    case RecipientField.ID:
      {
        if (value && idFrequencyMap[value]?.length > 1) error = FieldViolation.UNIQUE
        else {
          error = value !== undefined && value?.length && value?.length > 128 ? FieldViolation.MAX_LENGTH : undefined
        }
      }
      break
    case RecipientField.FIRST_NAME:
      error = !value?.length
        ? FieldViolation.REQUIRED
        : value.length < 2 && value?.length > 100
        ? FieldViolation.LENGTH
        : undefined
      break
    case RecipientField.LAST_NAME:
      {
        if (
          validateFieldValue(RecipientField.TYPE, recipientType, undefined, idFrequencyMap, sites) == undefined &&
          recipientType == RecipientType.COMPANY
        ) {
          error = !value?.length || value === undefined ? undefined : FieldViolation.NON_EMPTY
        } else {
          error =
            value !== undefined && value?.length && value.length < 1 && value?.length > 100
              ? FieldViolation.LENGTH
              : undefined
        }
      }
      break
    case RecipientField.NICK_NAME:
      error =
        value != undefined && value?.length && value?.length < 1 && value?.length > 100
          ? FieldViolation.LENGTH
          : undefined
      break
    case RecipientField.LOCATION:
      error = !value?.length ? FieldViolation.REQUIRED : value?.length > 150 ? FieldViolation.MAX_LENGTH : undefined
      break
    case RecipientField.EMAIL:
      error = !value?.length ? FieldViolation.REQUIRED : !emailRegex.test(value) ? FieldViolation.FORMAT : undefined
      break
    case RecipientField.PHONE: // TODO CHECK
      error = value != undefined && value?.length && value?.length < 10 ? FieldViolation.FORMAT : undefined
      break
    case RecipientField.CONTACT_PREFERENCE:
      error =
        value != undefined && value?.length && ContactPreference[value] != undefined ? FieldViolation.FORMAT : undefined
      break
    case RecipientField.TYPE:
      error =
        value != undefined && value?.length && RecipientType[value] != undefined ? FieldViolation.FORMAT : undefined
      break
    case RecipientField.SITE:
      {
        if (value != undefined && value?.length) {
          const isValid = sites.find((site) => site.id.toString() == value) != undefined
          error = !isValid ? FieldViolation.FORMAT : undefined
        } else {
          error = undefined
        }
      }
      break
    case RecipientField.ADDITIONAL_SITES:
      {
        const splitSites = value?.split(',')
        const containsSiteIds =
          splitSites?.every((splitSite) => {
            return sites.find((site) => site.id.toString() == splitSite) != undefined
          }) ?? false
        error = value != undefined && value?.length && !containsSiteIds ? FieldViolation.FORMAT : undefined
      }
      break
    case RecipientField.DROP_OFF:
      {
        if (value?.length && value?.length > 255) {
          return FieldViolation.MAX_LENGTH
        } else {
          error = undefined
        }
      }
      break
    default:
      break
  }
  return error
}
