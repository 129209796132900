import { EuiBadge, EuiButtonEmpty, EuiPopover, EuiSelectable, EuiSelectableOption } from '@elastic/eui'
import security from 'modules/module-security'
import { useAppSelector } from 'modules/module-utils'
import { Grid, useToggle } from 'modules/web-atoms'
import { FieldText, IFieldTextProps } from 'modules/web-molecules'
import * as React from 'react'
import { phoneCountries } from './countries'

const PHONE_REGEX = /^\+[0-9 ]*$/

export interface IPhoneValue {
  code: string
  number: string
}

export interface IFieldPhoneProps extends Omit<IFieldTextProps, 'onChange'> {
  onChange: (string) => void
}

export const FieldPhone: React.FC<IFieldPhoneProps> = ({ onChange, value, ...rest }) => {
  const country = useAppSelector(security.selectors.countryCode)

  const [currentCountry, setCountry] = React.useState('gb')
  const [phoneNumber, setPhoneNumber] = React.useState<IPhoneValue>({ code: '44', number: '' })
  const [phoneValue, setPhoneValue] = React.useState('')
  const [isPopoverOpen, togglePopover] = useToggle()

  React.useEffect(() => {
    if (country == undefined) return

    const countryCode = country.toLowerCase()
    const foundCountry = phoneCountries.find((entry) => {
      return entry.code == countryCode
    })
    if (!!foundCountry?.prefix) {
      setCountry(foundCountry.code)
      setPhoneNumber({ code: foundCountry.prefix, number: phoneNumber.number })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country])

  React.useEffect(() => {
    const newValue = `+${phoneNumber.code} ${phoneNumber.number}`
    setPhoneValue(newValue)

    const newPhoneValue = `${phoneNumber.code.trim()}${phoneNumber.number.trim()}`

    // Do not push values of just currentCountry codes
    if (phoneNumber.number == '' && value != '') {
      onChange('')
    } else if (phoneNumber.number != '' && newPhoneValue != value) {
      onChange(newPhoneValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneNumber])

  React.useEffect(() => {
    const newValue = `${value}`.trimStart()

    if (newValue.length != 0 && newValue != '+' && PHONE_REGEX.test(`+${newValue}`)) {
      let processedValue = newValue

      if (processedValue.startsWith('+')) {
        processedValue = processedValue.split('+')[1]
      }
      const matchAgainst = processedValue.trimStart()
      const foundCountry = phoneCountries.find((entry) => {
        return matchAgainst.startsWith(entry.prefix)
      })

      if (!!foundCountry) {
        setCountry(foundCountry.code)
        setPhoneNumber({
          code: foundCountry.prefix,
          number: processedValue.replace(foundCountry.prefix, '').trimStart(),
        })
      } else {
        // If no country is detected than set default country
        const countryCode = country?.toLowerCase()
        setCountry(countryCode ?? 'gb')
        const foundCountry = phoneCountries.find((entry) => {
          return entry.code == countryCode ?? 'gb'
        })
        setPhoneNumber({ code: foundCountry?.prefix ?? '', number: processedValue.trimStart() })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const onCountryChange = React.useCallback(
    (options: Array<EuiSelectableOption<string>>) => {
      const selectedOption = options.find((entry) => entry.checked == 'on') as any
      if (!!selectedOption && !!selectedOption.meta.id) {
        const foundCountry = phoneCountries.find((entry) => {
          return entry.code == selectedOption.meta.id
        })

        if (!!foundCountry) {
          setPhoneNumber({ code: foundCountry.prefix, number: phoneNumber.number })
          setCountry(selectedOption.meta.id)
        }
      }
      togglePopover()
    },
    [phoneNumber.number, togglePopover],
  )

  const onPhoneChange = React.useCallback(
    (e: React.ChangeEvent<any>) => {
      const { value } = e.target
      const newValue = (value as string | undefined)?.trimStart() ?? ''

      if (newValue.length == 0 || newValue == '+') {
        setPhoneNumber({ code: '', number: '' })
      } else if (PHONE_REGEX.test(newValue)) {
        let processedValue = newValue

        if (processedValue.startsWith('+')) {
          processedValue = processedValue.split('+')[1]
        }
        const matchAgainst = processedValue.trimStart()
        const foundCountry = phoneCountries.find((entry) => {
          return matchAgainst.startsWith(entry.prefix)
        })

        if (!!foundCountry) {
          setCountry(foundCountry.code)
          setPhoneNumber({
            code: foundCountry.prefix,
            number: processedValue.replace(foundCountry.prefix, '').trimStart(),
          })
        } else {
          // If no country is detected than set default country
          const countryCode = country?.toLowerCase()
          setCountry(countryCode ?? 'gb')
          const foundCountry = phoneCountries.find((entry) => {
            return entry.code == countryCode ?? 'gb'
          })
          setPhoneNumber({ code: foundCountry?.prefix ?? '', number: processedValue.trimStart() })
        }
      }
    },
    [country],
  )

  const countryOptions: Array<EuiSelectableOption<any>> = React.useMemo(() => {
    return phoneCountries.map((entry) => {
      return {
        checked: entry.code == currentCountry ? 'on' : undefined,
        label: entry.name,
        searchableLabel: `${entry.name} ${entry.code} ${entry.prefix}`,
        prepend: '',
        append: <EuiBadge>+{entry.prefix}</EuiBadge>,
        showIcons: false,
        meta: {
          id: entry.code,
        },
      }
    })
  }, [currentCountry])

  return (
    <FieldText
      value={phoneValue}
      onChange={onPhoneChange}
      prepend={
        <EuiPopover
          isOpen={isPopoverOpen}
          closePopover={togglePopover}
          button={
            <EuiButtonEmpty size="xs" iconType="arrowDown" iconSide="right" onClick={togglePopover}>
              {currentCountry.toUpperCase()}
            </EuiButtonEmpty>
          }
        >
          <EuiSelectable
            options={countryOptions}
            onChange={onCountryChange}
            listProps={{ bordered: true }}
            singleSelection
            searchable
          >
            {(list, search) => (
              <Grid flex={1}>
                <Grid.Row flex={1} marginBottom="small">
                  {search}
                </Grid.Row>
                {list}
              </Grid>
            )}
          </EuiSelectable>
        </EuiPopover>
      }
      {...rest}
    ></FieldText>
  )
}

export default React.memo(FieldPhone)
