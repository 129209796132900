import { SagaIterator } from '@redux-saga/types'
import { IChangePasswordDto, ITenantDto } from 'api'
import autobind from 'autobind-decorator'
import { AxiosInstance } from 'axios'
import { instanceToPlain } from 'class-transformer'
import http from 'modules/http'
import alerts from 'modules/module-alerts'
import { translate } from 'modules/module-intl'
import reporting from 'modules/module-reporting'
import security, { accessTokenStore, roleStore, session } from 'modules/module-security'
import { SupervisorSuite } from 'modules/redux-supervisor'
import { all, call, put, takeLeading } from 'redux-saga/effects'
import sha512 from 'sha512'
import profile from '../profile'
import { TabPanels } from '../screens/RecipientProfilePage/RecipientProfilePage'

const { updateRecipientProfile, changePassword, deleteData } = profile.actions

const INTL_PREFIX = 'recipient-portal'

export class RecipientProfileSaga extends SupervisorSuite {
  private readonly apiService: AxiosInstance
  private readonly authApiService: AxiosInstance

  constructor(apiService: AxiosInstance, authApiService: AxiosInstance) {
    super()
    this.apiService = apiService
    this.authApiService = authApiService
  }

  @autobind
  *start(): SagaIterator {
    yield all([
      takeLeading(profile.actions.UPDATE_RECIPIENT_PROFILE, this.updateRecipientProfile),
      takeLeading(profile.actions.CHANGE_PASSWORD, this.changePassword),
      takeLeading(profile.actions.DELETE_DATA, this.deleteData),
    ])
  }

  @autobind
  *updateRecipientProfile({ payload }: ReturnType<typeof updateRecipientProfile>): SagaIterator {
    const data = new ITenantDto()
    data.id = payload.recipientId
    data.firstName = payload.firstName
    data.lastName = payload.lastName
    data.email = payload.email
    data.alias = payload.alias
    data.primarySite = payload.developmentId
    data.gdprComply = payload.gdprComply
    data.notificationOptions = payload.notificationOptions
    data.phone = payload.phone
    data.type = payload.type
    data.room = payload.room
    data.recordModified = payload.recordModified

    try {
      yield put(profile.actions.setLoading(true))
      yield call(this.apiService, `/v3//tenants/${payload.recipientId}`, {
        method: 'PUT',
        data: instanceToPlain(data),
      })
      yield put(
        alerts.actions.success({ message: yield translate(`${INTL_PREFIX}.tabs.${TabPanels.MY_INFORMATION}.success`) }),
      )

      const role = yield call(roleStore.get)
      yield put(session.actions.pullCurrentUser({ role: role }))
    } catch (error) {
      yield put(reporting.actions.error(error))
      yield put(
        alerts.actions.success({ message: yield translate(`${INTL_PREFIX}.tabs.${TabPanels.MY_INFORMATION}.error`) }),
      )
    } finally {
      yield put(profile.actions.setLoading(false))
    }
  }

  @autobind
  *changePassword({ payload }: ReturnType<typeof changePassword>): SagaIterator {
    const { email, oldPassword, newPassword } = payload

    try {
      yield put(profile.actions.setLoading(true))
      const data = new IChangePasswordDto()
      data.email = email
      data.currentPassword = sha512(oldPassword).toString('hex')
      data.newPassword = sha512(newPassword).toString('hex')

      const token = yield call(accessTokenStore.get)
      yield call(this.authApiService, '/accounts/change-password', {
        method: 'POST',
        data: instanceToPlain(data),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      yield put(
        alerts.actions.success({ message: yield translate(`${INTL_PREFIX}.tabs.${TabPanels.SECURITY}.success`) }),
      )
    } catch (error) {
      yield put(reporting.actions.error(error))
      if (http.guards.isError(error) && error.response?.status === 400) {
        yield put(
          alerts.actions.error({
            message: yield translate(`${INTL_PREFIX}.tabs.${TabPanels.SECURITY}.bad-current-password-error`),
          }),
        )
      } else {
        yield put(alerts.actions.error({ message: yield translate(`${INTL_PREFIX}.tabs.${TabPanels.SECURITY}.error`) }))
      }
    } finally {
      yield put(profile.actions.setLoading(false))
    }
  }

  @autobind
  *deleteData({ payload }: ReturnType<typeof deleteData>): SagaIterator {
    const { recipientId } = payload

    try {
      yield put(profile.actions.setLoading(true))
      yield call(this.apiService, `/v3/tenants/${recipientId}`, {
        method: 'DELETE',
      })
      yield put(security.actions.end())
    } catch (error) {
      yield put(reporting.actions.error(error))
      yield put(alerts.actions.error({ message: yield translate(`${INTL_PREFIX}.tabs.${TabPanels.GDPR}.error`) }))
    } finally {
      yield put(profile.actions.setLoading(false))
    }
  }
}

export default RecipientProfileSaga
