import TagManager from 'react-gtm-module'

import { REACT_APP_ENVIRONMENT, TAG_MANAGER_ID } from 'modules/utils/constants'

const tagManagerArgs = {
  gtmId: TAG_MANAGER_ID,
}

export default function initTagManager() {
  if (REACT_APP_ENVIRONMENT === 'production') {
    TagManager.initialize(tagManagerArgs)
  }
}
