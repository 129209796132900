/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, useTheme } from '@emotion/react'
import * as React from 'react'
import { useLocation } from 'react-router-dom'

import { EuiPopover } from '@elastic/eui'

import { ResponsiveColor, useColor } from 'modules/dna'
import { Button, Grid, H3, Icon, useHover, useToggle, useTranslate } from 'modules/web-atoms'
import { ToolTip } from 'modules/web-molecules'

import { IMenuItemProps } from '../MenuItem/MenuItem'

export interface IMenuGroup extends Omit<IMenuItemProps, 'path'> {
  path?: string
  popoverBackgroundColor?: ResponsiveColor
  popoverWidth?: number
  contentColor?: ResponsiveColor
  popoverOverride?: boolean
}

export const MenuGroup: React.FC<IMenuGroup> = (props) => {
  const {
    title,
    icon,
    path,
    contentColor,
    isMinimized,
    popoverBackgroundColor,
    popoverOverride,
    backgroundColor,
    isDisabled,
    children,
    popoverWidth,
    ...rest
  } = props

  const t = useTranslate('web-organisms.menu')
  const location = useLocation()
  const { colors } = useTheme()

  const [isHovered, hoverEventHandlers] = useHover<HTMLButtonElement>()

  const [isCollapsed, toggleCollapsed] = useToggle(true)
  const [isOpen, togglePopover] = useToggle()

  const isSelected = React.useMemo(() => {
    return !!path ? location.pathname.startsWith(path) : !isCollapsed
  }, [isCollapsed, location.pathname, path])

  React.useEffect(() => {
    togglePopover(false)
  }, [location, togglePopover])

  const Image = React.useMemo(() => Icon[icon], [icon])
  const items = React.useMemo(() => {
    return React.Children.toArray(children).filter<React.ReactElement>(React.isValidElement)
  }, [children])

  const frameBackgroundColor: ResponsiveColor = React.useMemo(() => {
    switch (true) {
      case isDisabled:
        return 'none'
      case isSelected:
        return 'primaryMid'
      case !!isHovered:
        return 'secondaryMid'
      default:
        return 'none'
    }
  }, [isHovered, isSelected, isDisabled])

  const frameContentColor: ResponsiveColor = React.useMemo(() => {
    switch (true) {
      case isDisabled:
        return 'textLightMid'
      case isSelected:
        return 'primaryBackground'
      case !!isHovered:
        return 'primaryMid'
      default:
        return 'textMidDark'
    }
  }, [isHovered, isSelected, isDisabled])

  const theme = useTheme()
  const iconColor = useColor(contentColor ?? frameContentColor)

  const subMenu = React.useMemo(() => {
    return (
      <Grid>
        {items.map((item, index) => {
          return React.cloneElement<IMenuItemProps>(item, {
            ...item.props,
            isNested: true,
          })
        })}
      </Grid>
    )
  }, [items])

  React.useEffect(() => {
    if (!isMinimized) togglePopover(false)
  }, [isMinimized, togglePopover])

  const button = React.useMemo(
    () => (
      <ToolTip position="right" content={isDisabled ? t('disabled') : undefined} delay="regular">
        <Button.Frame
          label={title}
          borderRadius="small"
          paddingY={isMinimized ? 'none' : 'smallest'}
          paddingLeft={isMinimized ? 'macro' : isSelected ? 'small' : 'smallest'}
          paddingRight={isMinimized ? 'macro' : 'smallest'}
          height={isMinimized ? 35 : undefined}
          width={isMinimized ? 36 : 205}
          backgroundColor={backgroundColor ?? frameBackgroundColor}
          onClick={isMinimized || popoverOverride ? togglePopover : toggleCollapsed}
          disabled={isDisabled}
          css={{
            ':focus-visible': {
              backgroundColor: colors.secondaryMid,
              h3: {
                color: colors.primaryMid,
                textDecorationLine: 'underline',
              },
              svg: {
                fill: colors.primaryMid,
              },
            },
          }}
          {...hoverEventHandlers}
        >
          {isMinimized ? (
            <ToolTip content={title} position="right" delay="regular" disabled={isOpen}>
              <Grid height={35} alignItems="center" justifyContent="center">
                <Image fill={iconColor} />
              </Grid>
            </ToolTip>
          ) : (
            <Grid alignItems="center" justifyContent={'flex-start'} flex={1}>
              <Grid.Col marginLeft={'smallest'} width={25}>
                <Image fill={iconColor} />
              </Grid.Col>
              <Grid.Col marginLeft="smallest" flex={0} flexGrow={1} flexShrink={1} flexWrap="nowrap">
                <H3
                  fontSize="small"
                  color={contentColor ?? frameContentColor}
                  overflow="clip"
                  textOverflow="ellipsis"
                  textDecorationLine={isHovered ? 'underline' : undefined}
                  title={title}
                  truncated
                >
                  {title}
                </H3>
              </Grid.Col>
              <Grid.Col flex={1} flexGrow={1} marginRight="smallest">
                <Icon.ChevronDown fontSize="small" fill={iconColor} alignSelf="flex-end" />
              </Grid.Col>
            </Grid>
          )}
        </Button.Frame>
      </ToolTip>
    ),
    [
      isDisabled,
      t,
      title,
      isMinimized,
      isSelected,
      backgroundColor,
      frameBackgroundColor,
      popoverOverride,
      togglePopover,
      toggleCollapsed,
      colors.secondaryMid,
      colors.primaryMid,
      hoverEventHandlers,
      isOpen,
      Image,
      iconColor,
      contentColor,
      frameContentColor,
      isHovered,
    ],
  )

  return (
    <Grid {...rest}>
      <EuiPopover
        id={path}
        title={title}
        button={button}
        isOpen={isOpen}
        anchorPosition="rightDown"
        closePopover={togglePopover}
        panelPaddingSize="none"
        hasArrow={false}
        offset={25}
        repositionOnScroll={true}
        panelStyle={{ borderRadius: theme.radii.small }}
      >
        <Grid
          padding="macro"
          width={popoverWidth ?? 200}
          flex={1}
          backgroundColor={popoverBackgroundColor}
          borderRadius="medium"
        >
          {subMenu}
        </Grid>
      </EuiPopover>
      {!isMinimized && !isCollapsed && <Grid paddingTop="smallest">{subMenu}</Grid>}
    </Grid>
  )
}

export default React.memo(MenuGroup) as React.FC<IMenuGroup>
