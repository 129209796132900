import { IDevelopmentDto } from 'api'
import API from 'api/clients'
import { plainToInstance } from 'class-transformer'
import { QueryKey } from 'modules/module-api'
import useApi from 'modules/module-api/hooks/useApi/use-api'
import security from 'modules/module-security'
import React from 'react'
import { useAppSelector } from '../use-app-selector/use-app-selector'

function useClientBuildings() {
  const client = useAppSelector(security.selectors.client)

  const { data, isFetching, error } = useApi<IDevelopmentDto[]>([QueryKey.CLIENT_BUILDINGS, client], async () => {
    if (client?.Id) {
      const response = await API({
        url: '/v3/developments',
        params: {
          client_id: client.Id,
        },
      })
      return response.data
    }
  })

  const { buildings } = React.useMemo(() => {
    return {
      buildings: data ? plainToInstance(IDevelopmentDto, data) : [],
    }
  }, [data])

  return { buildings, isFetching, error }
}

export default useClientBuildings
