export type QueryParamValue = string | number | boolean | null | undefined | Date
export type QueryParams = Record<string, QueryParamValue>

export const queryParamsToString = (query: QueryParams) => {
  return (
    '?' +
    Object.keys(query)
      .filter((key) => query[key] !== undefined && query[key] !== null)
      .map((key) => {
        const value = query[key]!

        if (value instanceof Date) {
          return `${encodeURIComponent(key)}=${encodeURIComponent(value.toISOString())}`
        }
        return `${encodeURIComponent(key)}=${encodeURIComponent(value.toString())}`
      })
      .join('&')
  )
}
