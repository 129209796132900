export interface IOrchestrationState {
  isWipingBuilding: boolean
  error?: string
}

export const initialState: IOrchestrationState = {
  isWipingBuilding: false,
  error: undefined,
}

// List of building ids to delete all recipients from
export type IDeleteAllRecipientsPayload = number[]

export interface ISetActiveBuildingsPayload {
  buildings: string[]
}
