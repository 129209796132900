import * as React from 'react'

import { useAppSelector } from 'modules/module-utils/hooks'
import { FlexGroup, useTranslate } from 'modules/web-atoms'

import user, { FormState } from '../../user'

import { TablePageTemplate } from 'modules/web-templates'
import { UserForm } from './UserForm/UserForm'
import { UsersTable } from './UsersTable/UsersTable'

export interface IUsersPageProps extends React.ComponentProps<typeof FlexGroup> {}

export const UsersPage: React.FC<IUsersPageProps> = (props) => {
  const t = useTranslate('users-directory')
  const { ...rest } = props

  const { canViewUsers } = useAppSelector(user.selectors.permissions)
  const formState = useAppSelector(user.selectors.formState)

  return (
    <TablePageTemplate
      title={t('title')}
      hasViewPermission={canViewUsers}
      isFormVisible={formState !== FormState.HIDDEN}
      {...rest}
    >
      <TablePageTemplate.Table>
        <UsersTable />
      </TablePageTemplate.Table>
      <TablePageTemplate.Form>
        <UserForm />
      </TablePageTemplate.Form>
    </TablePageTemplate>
  )
}

export default React.memo(UsersPage)
