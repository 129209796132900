/* eslint-disable no-undef */
import { createAction } from '@reduxjs/toolkit'

import prefix from 'modules/utils/prefix'

import {
  FormState,
  IAddUserPayload,
  ISelectedUserPayload,
  ISelectedUsersPayload,
  ISetPermissionsPayload,
  ISetSelectedUserPayload,
  ISetSelectedUsersPayload,
  IUpdateUserPayload,
} from './'

export const namespaced = prefix('users/users')

// Users page actions
export const CREATE_USER = namespaced('createUser')
export const createUser = createAction(CREATE_USER)

export const SET_SUBMITTED_FORM = namespaced('setSubmittedForm')
export const setSubmittedForm = createAction<boolean>(SET_SUBMITTED_FORM)

export const SELECTED_USERS = namespaced('selectedUsers')
export const selectedUsers = createAction<ISelectedUsersPayload>(SELECTED_USERS)

export const SET_SELECTED_USERS = namespaced('setSelectedUsers')
export const setSelectedUsers = createAction<ISetSelectedUsersPayload>(SET_SELECTED_USERS)

export const SELECTED_USER = namespaced('selectedUser')
export const selectedUser = createAction<ISelectedUserPayload>(SELECTED_USER)

export const SET_SELECTED_USER = namespaced('setSelectedUser')
export const setSelectedUser = createAction<ISetSelectedUserPayload>(SET_SELECTED_USER)

export const DELETE_USER = namespaced('deleteUser')
export const deleteUser = createAction(DELETE_USER)

export const DELETE_USERS = namespaced('deleteUsers')
export const deleteUsers = createAction(DELETE_USERS)

export const DELETE_USERS_LOADING = namespaced('deleteUsersLoading')
export const deleteUsersLoading = createAction<boolean>(DELETE_USERS_LOADING)

export const DELETE_USER_LOADING = namespaced('deleteUserLoading')
export const deleteUserLoading = createAction<boolean>(DELETE_USER_LOADING)

export const UPDATE_LOADING = namespaced('updateLoading')
export const updateLoading = createAction<boolean>(UPDATE_LOADING)

export const CANCEL_USER_FORM = namespaced('cancelUserForm')
export const cancelUserForm = createAction(CANCEL_USER_FORM)

export const ADD_USER = namespaced('addUser')
export const addUser = createAction<IAddUserPayload>(ADD_USER)

export const UPDATE_USER = namespaced('updateUser')
export const updateUser = createAction<IUpdateUserPayload>(UPDATE_USER)

export const SET_FORM_STATE = namespaced('setFormState')
export const setFormState = createAction<FormState>(SET_FORM_STATE)

// action results
export const SET_PERMISSIONS = namespaced('setPermissions')
export const setPermissions = createAction<ISetPermissionsPayload>(SET_PERMISSIONS)

export const LOADING = namespaced('loading')
export const loading = createAction<boolean>(LOADING)

export const ERROR = namespaced('error')
export const error = createAction<Error>(ERROR)

type Action = typeof setPermissions
export default Action
