export enum QueryKey {
  BUILDING = 'building',
  BUILDINGS = 'buildings',
  LOCATIONS = 'locations',
  USERS = 'users',
  FETCH_USER = 'fetch-user',
  ALL_USERS = 'all-users',
  ASSIGNED_USERS = 'assigned-users',
  ASSIGNED_BUILDINGS = 'assigned-buildings',
  GET_CURRENT_RECIPIENT = 'get-current-recipient',
  FETCH_RECIPIENT = 'fetch-recipient',
  RECIPIENTS = 'recipients',
  COURIERS = 'couriers',
  SIZES = 'sizes',
  PARCELS_EXPORT = 'parcels-export',
  OUTBOUND_PARCELS_EXPORT = 'outbound-parcels-export',
  OUTBOUND_PARCELS = 'outbound-parcels',
  PARCEL_DETAILS = 'parcel-details',
  OUTBOUND_PARCEL_DETAILS = 'outbound-parcel-details',
  PARCEL_TAGS = 'parcel-tags',
  PARCEL_LOCATIONS = 'parcel-locations',
  DROPOFF_LOCATIONS = 'dropoff-locations',
  DROPOFF_LOCATIONS_PAGINATED = 'dropoff-locations-paginated',
  PARCELS = 'parcels',
  RECIPIENTS_AUTOCOMPLETE = 'recipients-autocomplete',
  REQUESTOR_RECIPIENTS_AUTOCOMPLETE = 'requestor-recipients-autocomplete',
  RECIPIENTS_EXPORT = 'recipients-export',
  CURRENT_USER = 'current-user',
  PARCEL_STATS = 'parcel-stats',
  PARCEL_STATS_EXPORT = 'parcel-stats-export',
  CHART_DATA = 'chart-data',
  CHART_DATA_EXPORT = 'chart-data-export',
  BAR_DATA = 'bar-data',
  HEATMAP_DATA = 'heatmap-data',
  REQUEST_CODE = 'request-code',
  RESET_PASSWORD = 'reset-password',
  PAYMENT_DETAILS_URL = 'payment-details-url',
  NOTIFICATION_ASSIGNMENTS = 'notification-assignments',
  NOTIFICATION_CONFIGURATION = 'notification-configuration',
  NOTIFICATION_TEMPLATE = 'notification-template',
  NOTIFICATION_TEMPLATE_LIST = 'notification-template-list',
  NOTIFICATION_CONFIGURATION_LIST = 'notification-configuration-list',
  RECIPIENT_BUILDINGS = 'recipient-buildings',
  ACTIVE_BUILDINGS = 'active-buildings',
  CLIENT_BUILDINGS = 'client-buildings',
  PARCEL_REMINDER_AVAILABLE = 'parcel-reminder-available',
  FETCH_TAGS = 'fetch-tags',
  PARCEL_LABEL_PDF = 'parcel-label-pdf',
  PARCEL_LABEL_PREVIEW = 'parcel-label-preview',
  GLOBAL_SIGNUP_SERVERS = 'global_signup_servers',
  AUTH_PASSWORD_RESET_REQUEST = 'auth_password_reset_request',
  AUTH_PASSWORD_RESET = 'auth_password_reset',
}

export default QueryKey
