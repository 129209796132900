import { IDropoffLocationDto, IFeatureFlagsDto } from 'api'
import autobind from 'autobind-decorator'
import { AxiosInstance } from 'axios'
import { instanceToPlain } from 'class-transformer'
import http from 'modules/http'
import alerts from 'modules/module-alerts'
import { QueryKey, QueryService } from 'modules/module-api'
import { translate } from 'modules/module-intl'
import orchestration from 'modules/module-orchestration'
import reporting from 'modules/module-reporting'
import security from 'modules/module-security'
import { SupervisorSuite } from 'modules/redux-supervisor'
import { TableSelectionAction } from 'modules/web-molecules'
import { SagaIterator } from 'redux-saga'
import { all, call, put, select, take, takeEvery, takeLatest, takeLeading } from 'redux-saga/effects'
import {
  ICourierDto,
  IDeleteDropoffLocationsDto,
  IDeleteParcelSizesDto,
  ILocationsDto,
  ISizeDto,
  ITagItemDto,
} from '../api/dto'
import settings, { FeatureFlag, ICourier, IDropoffLocation, ILocation, ISize, SettingsFormState } from '../settings'
import { addSize } from '../settings/actions'

const {
  setFeatureFlag,
  updateTag,
  addTag,
  deleteTag,
  addLocation,
  editLocation,
  addDropoffLocation,
  editDropoffLocation,
  addCourier,
  editCourier,
} = settings.actions

const INTL_PREFIX = 'settings'
const INTL_PREFIX_COMMON = 'commons'

export class SettingsSaga extends SupervisorSuite {
  private readonly apiService: AxiosInstance
  private readonly queryService: QueryService

  constructor(apiService: AxiosInstance, queryService: QueryService) {
    super()
    this.apiService = apiService
    this.queryService = queryService
  }

  @autobind
  *start(): SagaIterator {
    yield all([
      takeLatest(orchestration.actions.DETERMINE_USER_PERMISSIONS, this.determineUserPermissions),
      takeLeading(settings.actions.SET_FEATURE_FLAG, this.setFeatureFlag),
      takeLeading(settings.actions.ADD_TAG, this.addTag),
      takeEvery(settings.actions.UPDATE_TAG, this.updateTag),
      takeEvery(settings.actions.DELETE_TAG, this.deleteTag),
      takeLeading(settings.actions.ADD_LOCATION, this.addLocation),
      takeLeading(settings.actions.DELETE_LOCATIONS, this.deleteLocations),
      takeLeading(settings.actions.EDIT_LOCATION, this.editLocation),
      takeLeading(settings.actions.ADD_COURIER, this.addCourier),
      takeLeading(settings.actions.DELETE_COURIERS, this.deleteCouriers),
      takeLeading(settings.actions.EDIT_COURIER, this.editCourier),
      takeLeading(settings.actions.ADD_SIZE, this.addSize),
      takeLeading(settings.actions.DELETE_SIZES, this.deleteSizes),
      takeLeading(settings.actions.ADD_DROPOFF_LOCATION, this.addDropoffLocation),
      takeLeading(settings.actions.EDIT_DROPOFF_LOCATION, this.editDropoffLocation),
      takeLeading(settings.actions.DELETE_DROPOFF_LOCATIONS, this.deleteDropoffLocations),
    ])
  }

  @autobind
  *determineUserPermissions(): SagaIterator {
    const userType = yield select(security.selectors.type)
    yield put(settings.actions.setPermissions({ userType }))
  }

  @autobind
  *setFeatureFlag({ payload }: ReturnType<typeof setFeatureFlag>): SagaIterator {
    yield put(settings.actions.setLoadingFlag({ flag: payload.flag, state: true }))
    const { value, activeBuildings } = payload
    if (!activeBuildings.length) return
    const data = new IFeatureFlagsDto()

    switch (payload.flag) {
      case FeatureFlag.OUTBOUND:
        data.outboundEnabled = value
        break
      case FeatureFlag.COLLECTION:
        data.collectionEnabled = value
        break
      case FeatureFlag.BATCH_COLLECTION:
        data.batchCollectionEnabled = value
        break
      case FeatureFlag.ADDITIONAL:
        data.additionalParcelDetails = value
        break
    }

    try {
      yield call(this.apiService, `/v3/developments/feature-config/multibuilding/${activeBuildings.join(',')}`, {
        method: 'POST',
        data: instanceToPlain(data),
      })
      yield put(security.actions.loadPermissions())
      // Keep showing loading indicator until new feature flags are fetched
      yield take([security.actions.LOAD_PERMISSIONS_RESULT])
    } catch (err) {
      yield put(reporting.actions.error(err))
    } finally {
      yield put(settings.actions.setLoadingFlag({ flag: payload.flag, state: false }))
    }
  }

  @autobind
  *addTag({ payload }: ReturnType<typeof addTag>): SagaIterator {
    try {
      yield put(settings.actions.setTagCreation({ visible: true, isLoading: true }))

      const clientId = yield select(security.selectors.clientId)
      const data = new ITagItemDto()
      data.name = payload.name
      data.color = payload.color
      data.enabled = payload.enabled
      data.clientId = clientId

      yield call(this.apiService, '/v3/parcel-tags', {
        method: 'POST',
        data: instanceToPlain(data),
      })
      yield call(this.queryService.refetchQueries, [QueryKey.FETCH_TAGS])
      yield take(orchestration.actions.queryResult(QueryKey.FETCH_TAGS))
      yield put(alerts.actions.success({ message: yield translate(`${INTL_PREFIX}.tabs.tags.add.success`) }))
    } catch (error) {
      if (http.guards.isError(error) && error.response?.status === 409) {
        yield put(alerts.actions.error({ message: yield translate(`${INTL_PREFIX}.tabs.tags.error.duplicate`) }))
      } else {
        yield put(alerts.actions.error({ message: yield translate(`${INTL_PREFIX_COMMON}.alert.generic-error`) }))
      }
    } finally {
      yield put(settings.actions.setTagCreation({ visible: false, isLoading: false }))
    }
  }

  @autobind
  *updateTag({ payload }: ReturnType<typeof updateTag>): SagaIterator {
    try {
      yield put(settings.actions.setIsUpdatingTag({ id: payload.id, state: true }))

      const clientId = yield select(security.selectors.clientId)
      const data = new ITagItemDto()
      data.id = payload.id
      data.name = payload.name
      data.color = payload.color
      data.enabled = payload.enabled
      data.clientId = clientId

      yield call(this.apiService, `/v3/parcel-tags/${data.id}`, {
        method: 'PUT',
        data: instanceToPlain(data),
      })
      yield call(this.queryService.refetchQueries, [QueryKey.FETCH_TAGS])
      yield take(orchestration.actions.queryResult(QueryKey.FETCH_TAGS))
      yield put(alerts.actions.success({ message: yield translate(`${INTL_PREFIX}.tabs.tags.update.success`) }))
    } catch (error) {
      yield put(reporting.actions.error(error))
      yield put(alerts.actions.error({ message: yield translate(`${INTL_PREFIX_COMMON}.alert.generic-error`) }))
    } finally {
      yield put(settings.actions.setIsUpdatingTag({ id: payload.id, state: false }))
      yield put(settings.actions.setIsEditingTag({ id: payload.id, state: false }))
    }
  }

  @autobind
  *deleteTag({ payload }: ReturnType<typeof deleteTag>): SagaIterator {
    try {
      yield put(settings.actions.setIsDeletingTag({ id: payload, state: true }))
      yield call(this.apiService, `/v3/parcel-tags/${payload}`, {
        method: 'DELETE',
      })
      yield call(this.queryService.refetchQueries, [QueryKey.FETCH_TAGS])
      yield take(orchestration.actions.queryResult(QueryKey.FETCH_TAGS))
      yield put(alerts.actions.success({ message: yield translate(`${INTL_PREFIX}.tabs.tags.delete.success`) }))
    } catch (error) {
      yield put(reporting.actions.error(error))
      yield put(alerts.actions.error({ message: yield translate(`${INTL_PREFIX_COMMON}.alert.generic-error`) }))
    } finally {
      yield put(settings.actions.setIsDeletingTag({ id: payload, state: false }))
    }
  }

  @autobind
  *addLocation({ payload }: ReturnType<typeof addLocation>): SagaIterator {
    try {
      yield put(settings.actions.setIsAddingLocation(true))

      const selectedSite = yield select(settings.selectors.selectedSite)
      const data = new ILocationsDto()
      data.name = payload.name
      data.identifier = payload.identifier
      data.siteId = selectedSite
      data.enabled = payload.enabled

      if (!payload.keepShowingForm) {
        yield put(settings.actions.setLocationFormState(SettingsFormState.HIDDEN))
      }
      yield call(this.apiService, `/v3/mailroom-locations`, {
        method: 'POST',
        data: instanceToPlain(data),
      })
      yield call(this.queryService.refetchQueries, [QueryKey.LOCATIONS])
      const message = yield translate(`${INTL_PREFIX}.tabs.locations.add.success`)
      yield put(alerts.actions.success({ message }))
    } catch (error) {
      if (http.guards.isError(error) && error.response?.status === 409) {
        yield put(alerts.actions.error({ message: yield translate(`${INTL_PREFIX}.tabs.locations.error.duplicate`) }))
      } else {
        yield put(alerts.actions.error({ message: yield translate(`${INTL_PREFIX_COMMON}.alert.generic-error`) }))
      }
    } finally {
      yield put(settings.actions.setIsAddingLocation(false))
    }
  }

  @autobind
  *editLocation({ payload }: ReturnType<typeof editLocation>): SagaIterator {
    const selectedLocation: ReturnType<typeof settings.selectors.selectedLocation> = yield select(
      settings.selectors.selectedLocation,
    )

    if (selectedLocation) {
      try {
        yield put(settings.actions.setIsEditingLocation(true))

        const data = new ILocationsDto()
        data.id = selectedLocation.id
        data.name = payload.name
        data.identifier = payload.identifier
        data.siteId = selectedLocation.siteId
        data.enabled = payload.enabled
        yield call(this.apiService, `/v3/mailroom-locations/${selectedLocation.id}`, {
          method: 'PUT',
          data: instanceToPlain(data),
        })
        yield put(settings.actions.setSelectedLocation(undefined))
        yield put(settings.actions.setLocationFormState(SettingsFormState.HIDDEN))
        yield call(this.queryService.refetchQueries, [QueryKey.LOCATIONS])
        const message = yield translate(`${INTL_PREFIX}.tabs.locations.edit.success`)
        yield put(alerts.actions.success({ message }))
      } catch (error) {
        yield put(reporting.actions.error(error))
        yield put(alerts.actions.error({ message: yield translate(`${INTL_PREFIX_COMMON}.alert.generic-error`) }))
      } finally {
        yield put(settings.actions.setIsEditingLocation(false))
      }
    }
  }

  @autobind
  *deleteLocations(): SagaIterator {
    try {
      yield put(settings.actions.setIsDeletingLocations(true))

      const selectedLocations: string[] = yield select(settings.selectors.selectedLocations)
      const selectedLocation: ILocation = yield select(settings.selectors.selectedLocation)
      const selected = selectedLocations.length ? selectedLocations : [selectedLocation.id]

      yield all(selected.map((id) => call(this.apiService, `/v3/mailroom-locations/${id}`, { method: 'DELETE' })))

      yield call(this.queryService.refetchQueries, [QueryKey.LOCATIONS])
      yield put(settings.actions.setSelectedLocations({ items: [], action: TableSelectionAction.DESELECTED_ALL }))
      yield put(settings.actions.setSelectedLocation(undefined))
      yield put(settings.actions.setLocationFormState(SettingsFormState.HIDDEN))

      const message = yield translate(`${INTL_PREFIX}.tabs.locations.delete.success`)
      yield put(alerts.actions.success({ message }))
    } catch (error) {
      yield put(reporting.actions.error(error))
      yield put(alerts.actions.error({ message: yield translate(`${INTL_PREFIX_COMMON}.alert.generic-error`) }))
    } finally {
      yield put(settings.actions.setIsDeletingLocations(false))
    }
  }

  @autobind
  *addCourier({ payload }: ReturnType<typeof addCourier>): SagaIterator {
    try {
      yield put(settings.actions.setIsAddingCourier(true))

      const clientId = yield select(security.selectors.clientId)

      const data = new ICourierDto()
      data.name = payload.name
      data.clientId = clientId

      if (!payload.keepShowingForm) {
        yield put(settings.actions.setCourierFormState(SettingsFormState.HIDDEN))
      }
      yield call(this.apiService, `/v3/couriers`, {
        method: 'POST',
        data: instanceToPlain(data),
      })
      yield call(this.queryService.refetchQueries, [QueryKey.COURIERS])
      const message = yield translate(`${INTL_PREFIX}.tabs.couriers.add.success`)
      yield put(alerts.actions.success({ message }))
    } catch (error) {
      if (http.guards.isError(error) && error.response?.status === 409) {
        yield put(alerts.actions.error({ message: yield translate(`${INTL_PREFIX}.tabs.couriers.error.duplicate`) }))
      } else {
        yield put(alerts.actions.error({ message: yield translate(`${INTL_PREFIX_COMMON}.alert.generic-error`) }))
      }
    } finally {
      yield put(settings.actions.setIsAddingCourier(false))
    }
  }

  @autobind
  *editCourier({ payload }: ReturnType<typeof editCourier>): SagaIterator {
    const selectedCourier: ReturnType<typeof settings.selectors.selectedCourier> = yield select(
      settings.selectors.selectedCourier,
    )

    if (selectedCourier) {
      try {
        yield put(settings.actions.setIsEditingCourier(true))
        const clientId = yield select(security.selectors.clientId)

        const data = new ICourierDto()
        data.id = selectedCourier.id
        data.name = payload.name
        data.clientId = clientId

        yield call(this.apiService, `/v3/couriers/${selectedCourier.id}`, {
          method: 'PUT',
          data: instanceToPlain(data),
        })
        yield put(settings.actions.setSelectedCourier(undefined))
        yield put(settings.actions.setCourierFormState(SettingsFormState.HIDDEN))
        yield call(this.queryService.refetchQueries, [QueryKey.COURIERS])
        const message = yield translate(`${INTL_PREFIX}.tabs.couriers.edit.success`)
        yield put(alerts.actions.success({ message }))
      } catch (error) {
        yield put(reporting.actions.error(error))
        yield put(alerts.actions.error({ message: yield translate(`${INTL_PREFIX_COMMON}.alert.generic-error`) }))
      } finally {
        yield put(settings.actions.setIsEditingCourier(false))
      }
    }
  }

  @autobind
  *deleteCouriers(): SagaIterator {
    try {
      yield put(settings.actions.setIsDeletingCouriers(true))

      const selectedCouriers: string[] = yield select(settings.selectors.selectedCouriers)
      const selectedCourier: ICourier = yield select(settings.selectors.selectedCourier)
      const selected = selectedCouriers.length ? selectedCouriers : [selectedCourier.id]

      yield all(selected.map((id) => call(this.apiService, `/v3/couriers/${id}`, { method: 'DELETE' })))
      yield call(this.queryService.refetchQueries, [QueryKey.COURIERS])

      yield put(settings.actions.setSelectedCouriers({ items: [], action: TableSelectionAction.DESELECTED_ALL }))
      yield put(settings.actions.setSelectedCourier(undefined))
      yield put(settings.actions.setCourierFormState(SettingsFormState.HIDDEN))

      const message = yield translate(`${INTL_PREFIX}.tabs.couriers.delete.success`)
      yield put(alerts.actions.success({ message }))
    } catch (error) {
      yield put(reporting.actions.error(error))
      yield put(alerts.actions.error({ message: yield translate(`${INTL_PREFIX_COMMON}.alert.generic-error`) }))
    } finally {
      yield put(settings.actions.setIsDeletingCouriers(false))
    }
  }

  @autobind
  *addSize({ payload }: ReturnType<typeof addSize>): SagaIterator {
    try {
      yield put(settings.actions.setIsAddingSize(true))

      const clientId = yield select(security.selectors.clientId)

      const data = new ISizeDto()
      data.name = payload.name
      data.clientId = clientId

      if (!payload.keepShowingForm) {
        yield put(settings.actions.setSizeFormState(SettingsFormState.HIDDEN))
      }
      yield call(this.apiService, `/v3/parcel-sizes`, {
        method: 'POST',
        data: instanceToPlain(data),
      })
      yield call(this.queryService.refetchQueries, [QueryKey.SIZES])
      const message = yield translate(`${INTL_PREFIX}.tabs.sizes.add.success`)
      yield put(alerts.actions.success({ message }))
    } catch (error) {
      if (http.guards.isError(error) && error.response?.status === 409) {
        yield put(alerts.actions.error({ message: yield translate(`${INTL_PREFIX}.tabs.sizes.error.duplicate`) }))
      } else {
        yield put(alerts.actions.error({ message: yield translate(`${INTL_PREFIX_COMMON}.alert.generic-error`) }))
      }
    } finally {
      yield put(settings.actions.setIsAddingSize(false))
    }
  }

  @autobind
  *deleteSizes(): SagaIterator {
    try {
      yield put(settings.actions.setIsDeletingSizes(true))

      const selectedSizes: string[] = yield select(settings.selectors.selectedSizes)
      const selectedSize: ISize = yield select(settings.selectors.selectedSize)
      const selected = selectedSizes.length ? selectedSizes : [selectedSize.id]

      const data = new IDeleteParcelSizesDto()
      data.ids = selected
      yield call(this.apiService, `/v3/parcel-sizes`, {
        method: 'DELETE',
        data: instanceToPlain(data),
      })

      yield call(this.queryService.refetchQueries, [QueryKey.SIZES])

      yield put(settings.actions.setSelectedSizes({ items: [], action: TableSelectionAction.DESELECTED_ALL }))
      yield put(settings.actions.setSelectedSize(undefined))
      yield put(settings.actions.setSizeFormState(SettingsFormState.HIDDEN))

      const message = yield translate(`${INTL_PREFIX}.tabs.sizes.delete.success`)
      yield put(alerts.actions.success({ message }))
    } catch (error) {
      yield put(reporting.actions.error(error))
      yield put(alerts.actions.error({ message: yield translate(`${INTL_PREFIX_COMMON}.alert.generic-error`) }))
    } finally {
      yield put(settings.actions.setIsDeletingSizes(false))
    }
  }

  @autobind
  *addDropoffLocation({ payload }: ReturnType<typeof addDropoffLocation>): SagaIterator {
    yield put(settings.actions.setIsAddingDropoffLocation(true))
    const clientId = yield select(security.selectors.clientId)
    if (!clientId) {
      yield put(alerts.actions.error({ message: yield translate(`${INTL_PREFIX_COMMON}.alert.generic-error`) }))
      return
    }

    try {
      const data = new IDropoffLocationDto()
      data.name = payload.name
      data.enabled = payload.enabled
      data.clientId = clientId

      if (!payload.keepShowingForm) {
        yield put(settings.actions.setLocationFormState(SettingsFormState.HIDDEN))
      }
      yield call(this.apiService, `/v3/dropoff-locations`, {
        method: 'POST',
        data: instanceToPlain(data),
      })
      yield call(this.queryService.refetchQueries, [QueryKey.DROPOFF_LOCATIONS_PAGINATED])
      const message = yield translate(`${INTL_PREFIX}.tabs.dropoff-locations.add.success`)
      yield put(alerts.actions.success({ message }))
    } catch (error) {
      yield put(reporting.actions.error(error))
      if (http.guards.isError(error) && error.response?.status === 409) {
        yield put(
          alerts.actions.error({ message: yield translate(`${INTL_PREFIX}.tabs.dropoff-locations.error.duplicate`) }),
        )
      } else {
        yield put(alerts.actions.error({ message: yield translate(`${INTL_PREFIX_COMMON}.alert.generic-error`) }))
      }
    } finally {
      yield put(settings.actions.setIsAddingDropoffLocation(false))
    }
  }

  @autobind
  *editDropoffLocation({ payload }: ReturnType<typeof editDropoffLocation>): SagaIterator {
    yield put(settings.actions.setIsEditingDropoffLocation(true))
    const clientId = yield select(security.selectors.clientId)
    const selectedItem: IDropoffLocation | undefined = yield select(settings.selectors.selectedDropoffLocation)
    if (!clientId || !selectedItem) {
      yield put(alerts.actions.error({ message: yield translate(`${INTL_PREFIX_COMMON}.alert.generic-error`) }))
      return
    }

    try {
      const data = new IDropoffLocationDto()
      data.id = selectedItem.id
      data.name = payload.name
      data.enabled = payload.enabled
      data.clientId = clientId
      yield call(this.apiService, `/v3/dropoff-locations/${selectedItem.id}`, {
        method: 'PUT',
        data: instanceToPlain(data),
      })
      yield put(settings.actions.setSelectedDropoffLocation(undefined))
      yield put(settings.actions.setDropoffLocationFormState(SettingsFormState.HIDDEN))
      yield call(this.queryService.refetchQueries, [QueryKey.DROPOFF_LOCATIONS_PAGINATED])
      const message = yield translate(`${INTL_PREFIX}.tabs.dropoff-locations.edit.success`)
      yield put(alerts.actions.success({ message }))
    } catch (error) {
      yield put(reporting.actions.error(error))
      if (http.guards.isError(error) && error.response?.status === 409) {
        yield put(
          alerts.actions.error({ message: yield translate(`${INTL_PREFIX}.tabs.dropoff-locations.duplicate-error`) }),
        )
      } else {
        yield put(alerts.actions.error({ message: yield translate(`${INTL_PREFIX_COMMON}.alert.generic-error`) }))
      }
    } finally {
      yield put(settings.actions.setIsEditingDropoffLocation(false))
    }
  }

  @autobind
  *deleteDropoffLocations(): SagaIterator {
    yield put(settings.actions.setIsDeletingDropoffLocations(true))

    try {
      const selectedItems: string[] = yield select(settings.selectors.selectedDropoffLocations)
      const selectedItem: IDropoffLocation = yield select(settings.selectors.selectedDropoffLocation)

      const data = new IDeleteDropoffLocationsDto()
      data.dropoffLocationIds = selectedItems.length ? selectedItems : [selectedItem.id]
      yield call(this.apiService, `/v3/dropoff-locations`, {
        method: 'DELETE',
        data: instanceToPlain(data),
      })
      yield call(this.queryService.refetchQueries, [QueryKey.DROPOFF_LOCATIONS_PAGINATED])
      yield put(
        settings.actions.setSelectedDropoffLocations({ items: [], action: TableSelectionAction.DESELECTED_ALL }),
      )
      yield put(settings.actions.setSelectedDropoffLocation(undefined))
      const message = yield translate(`${INTL_PREFIX}.tabs.dropoff-locations.delete.success`)
      yield put(alerts.actions.success({ message }))
    } catch (error) {
      yield put(reporting.actions.error(error))
      yield put(alerts.actions.error({ message: yield translate(`${INTL_PREFIX_COMMON}.alert.generic-error`) }))
    } finally {
      yield put(settings.actions.setIsDeletingDropoffLocations(false))
    }
  }
}

export default SettingsSaga
