/* eslint-disable no-undef */
import { createAction } from '@reduxjs/toolkit'

import prefix from 'modules/utils/prefix'

import {
  FormState,
  IAddBuildingPayload,
  ISetDevelopmentsPayload,
  ISetPermissionsPayload,
  ISetSelectedBuildingPayload,
  ISetSelectedBuildingsPayload,
  IUpdateBuildingPayload,
} from './'

export const namespaced = prefix('developments/development')

// actions
export const PULL_CURRENT_DEVELOPMENTS = namespaced('pullCurrentDevelopments')
export const pullCurrentDevelopments = createAction(PULL_CURRENT_DEVELOPMENTS)

export const SET_SUBMITTED_FORM = namespaced('setSubmittedForm')
export const setSubmittedForm = createAction<boolean>(SET_SUBMITTED_FORM)

export const SET_CURRENT_DEVELOPMENTS = namespaced('setCurrentDevelopments')
export const setCurrentDevelopments = createAction<ISetDevelopmentsPayload>(SET_CURRENT_DEVELOPMENTS)

// Buildings page actions
export const CREATE_BUILDING = namespaced('createBuilding')
export const createBuilding = createAction(CREATE_BUILDING)

export const SET_SELECTED_BUILDINGS = namespaced('setSelectedBuildings')
export const setSelectedBuildings = createAction<ISetSelectedBuildingsPayload>(SET_SELECTED_BUILDINGS)

export const SET_SELECTED_BUILDING = namespaced('setSelectedBuilding')
export const setSelectedBuilding = createAction<ISetSelectedBuildingPayload>(SET_SELECTED_BUILDING)

export const DELETE_BUILDING = namespaced('deleteBuilding')
export const deleteBuilding = createAction(DELETE_BUILDING)

export const DELETE_BUILDINGS = namespaced('deleteBuildings')
export const deleteBuildings = createAction(DELETE_BUILDINGS)

export const DELETE_BUILDINGS_LOADING = namespaced('deleteBuildingsLoading')
export const deleteBuildingsLoading = createAction<boolean>(DELETE_BUILDINGS_LOADING)

export const DELETE_BUILDING_LOADING = namespaced('deleteBuildingLoading')
export const deleteBuildingLoading = createAction<boolean>(DELETE_BUILDING_LOADING)

export const UPDATE_LOADING = namespaced('updateLoading')
export const updateLoading = createAction<boolean>(UPDATE_LOADING)

export const CANCEL_BUILDING_FORM = namespaced('cancelBuildingForm')
export const cancelBuildingForm = createAction(CANCEL_BUILDING_FORM)

export const ADD_BUILDING = namespaced('addBuilding')
export const addBuilding = createAction<IAddBuildingPayload>(ADD_BUILDING)

export const UPDATE_BUILDING = namespaced('updateBuilding')
export const updateBuilding = createAction<IUpdateBuildingPayload>(UPDATE_BUILDING)

export const SET_SELECTED_ACTIVE_BUILDINGS = namespaced('setSelectedActiveBuildings')
export const setSelectedActiveBuildings = createAction(SET_SELECTED_ACTIVE_BUILDINGS)

export const SET_FORM_STATE = namespaced('setFormState')
export const setFormState = createAction<FormState>(SET_FORM_STATE)

// action results
export const LOADING = namespaced('loading')
export const loading = createAction<boolean>(LOADING)

export const ERROR = namespaced('error')
export const error = createAction<Error>(ERROR)

export const SET_PERMISSIONS = namespaced('setPermissions')
export const setPermissions = createAction<ISetPermissionsPayload>(SET_PERMISSIONS)

type Action = typeof setCurrentDevelopments | typeof loading | typeof error
export default Action
export function SET_ACTIVE_BUILDINGS(
  SET_ACTIVE_BUILDINGS: any,
  onSetActiveBuildings: ({ payload }: any) => import('redux-saga').SagaIterator,
): import('redux-saga/effects').ForkEffect<never> {
  throw new Error('Function not implemented.')
}
