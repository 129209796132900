import { UserType } from 'api'

export interface IPermissions {
  canEditClient: boolean
}

export interface IUserState {
  loading: boolean
  permissions: IPermissions
  error?: string
}

export const initialState: IUserState = {
  loading: false,
  permissions: {
    canEditClient: false,
  },
}

export interface ISetPermissionsPayload {
  userType: UserType
}

export interface IUpdateAccountPayload {
  id: string
  email: string
  firstName: string
  lastName: string
  role: string
  userType: UserType
  developmentId: number
}

export interface IUpdatePasswordPayload {
  userId: string
  currentPassword: string
  newPassword: string
}
export interface IUpdateCompanyInfoPayload {
  id: string
  companyName: string
  city: string
  addressLine1: string
  addressLine2?: string
  postcode: string
  country: string
}
