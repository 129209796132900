import { EuiButton, EuiFormRow, EuiLink } from '@elastic/eui'
import { useFormik } from 'formik'
import { AppStore, PlayStore } from 'modules/dna'
import { useValidations } from 'modules/module-utils'
import { OperatingSystem } from 'modules/ts/types'
import envUtil from 'modules/utils/EnvironmentUtils'
import { FlexItem, Grid, H1, H2, H3, Image, Paragraph, useDeeplink, useTranslate } from 'modules/web-atoms'
import { FieldPhone } from 'modules/web-organisms'
import QRCode from 'qrcode.react'
import * as React from 'react'
import { Trans } from 'react-i18next'
import * as Yup from 'yup'
import { ISendAppLinkPayload } from '../../../../authentication'

export interface IDownloadAppStepStateProps {}

export interface IDownloadAppStepDispatchProps {
  onSubmit: (params: ISendAppLinkPayload) => void
}

export enum Views {
  QR_CODE = 'QR_CODE',
  PHONE_FORM = 'PHONE_FORM',
}

export interface IDownloadAppStepFormData {
  number: string
}

export type IDownloadAppStepProps = IDownloadAppStepDispatchProps & IDownloadAppStepStateProps

const INTL_PREFIX = 'onboarding.signup.onboarding.download-app-step'

export const DownloadAppStep: React.FC<IDownloadAppStepProps> = (props) => {
  const { onSubmit } = props
  const t = useTranslate(INTL_PREFIX)

  const [currentView, setCurrentView] = React.useState(Views.PHONE_FORM)
  const [androidLink, iosLink] = useDeeplink(true)

  const validations = useValidations()
  const validationSchema = React.useMemo(
    () =>
      Yup.object<IDownloadAppStepFormData>().shape({
        number: validations.phone,
      }),
    [validations.phone],
  )
  const form = useFormik<IDownloadAppStepFormData>({
    initialValues: { number: '' },
    validationSchema,
    onSubmit: ({ number }) => onSubmit({ number }),
  })

  const isNotMobile = React.useMemo(() => {
    return (
      envUtil.getOperatingSystem() != OperatingSystem.iOS || envUtil.getOperatingSystem() != OperatingSystem.Android
    )
  }, [])

  const { values, handleChange, touched, errors, submitForm } = form
  return (
    <Grid flex={1} width={['100%', 680]} alignItems="center" margin="none">
      <H1 paddingX={['xlarge', 'large']} marginBottom="smallest" textAlign="center">
        {t('heading')}
      </H1>
      {currentView === Views.PHONE_FORM ? (
        <Grid>
          <H2 paddingX={['xlarge', 'large']} textAlign="center">
            {t('subheading-link')}
          </H2>
          {isNotMobile && (
            <Grid marginTop="large">
              <Grid.Col alignItems="center" paddingX={['medium', 'xxlarge']} marginBottom="medium">
                <EuiFormRow label={t('form.phone')} isInvalid={!!errors.number} error={errors.number} inputMode="text">
                  <Grid.Row>
                    <FieldPhone aria-label={t('form.phone')} value={values.number} onChange={handleChange('number')} />
                    <EuiButton
                      fill
                      onClick={submitForm}
                      style={{ minWidth: 70, width: 70, fontSize: 15, fontWeight: 'bold', marginLeft: 15 }}
                    >
                      {t('button.send')}
                    </EuiButton>
                  </Grid.Row>
                </EuiFormRow>
                <H3 size="large" mt="medium" paddingX="medium" textAlign="center" marginBottom="large">
                  <Trans
                    i18nKey={`${INTL_PREFIX}.scan-qr`}
                    components={{
                      linkage: (
                        <EuiLink
                          color="subdued"
                          onClick={() => {
                            setCurrentView(Views.QR_CODE)
                          }}
                        />
                      ),
                    }}
                  />
                </H3>
              </Grid.Col>
            </Grid>
          )}
        </Grid>
      ) : (
        <Grid>
          <H2 textAlign="center" marginBottom="medium">
            {t('subheading-qr')}
          </H2>
          <Grid>
            <Grid.Col alignItems="center" paddingX={['medium', 'xxlarge']}>
              <FlexItem style={{ background: '#EBEBEB', padding: 10, borderRadius: 15, marginBottom: 20, width: 250 }}>
                <FlexItem
                  style={{
                    background: '#FFFFFF',
                    padding: 15,
                    borderRadius: 15,
                  }}
                >
                  <QRCode renderAs="svg" value={androidLink ?? ''} style={{ width: '100%' }} />
                </FlexItem>
              </FlexItem>
              <Paragraph size="large" textAlign="center" marginBottom="smallest">
                <Trans
                  i18nKey={`${INTL_PREFIX}.get-link`}
                  components={{
                    linkage: (
                      <EuiLink
                        color="subdued"
                        onClick={() => {
                          setCurrentView(Views.PHONE_FORM)
                        }}
                      />
                    ),
                  }}
                />
              </Paragraph>
            </Grid.Col>
          </Grid>
        </Grid>
      )}
      <Grid flex={1} alignItems="center" justifyContent="center" isResponsive={true}>
        <Grid.Col alignItems="center" justifyContent="center" marginX="macro" marginTop={['small', 'none']}>
          <a href={androidLink} target="_blank" rel="noreferrer">
            <Image minWidth={150} width={[400, 150, 200]} src={PlayStore} />
          </a>
        </Grid.Col>
        <Grid.Col alignItems="center" justifyContent="center" marginX="macro" marginTop={['small', 'none']}>
          <a href={iosLink} target="_blank" rel="noreferrer">
            <Image minWidth={150} width={[400, 150, 200]} src={AppStore} />
          </a>
        </Grid.Col>
      </Grid>
    </Grid>
  )
}

export default React.memo(DownloadAppStep)
