import { Expose } from 'class-transformer'

export class IParcelLabelPreviewDto {
  @Expose({ name: 'Html' })
  html: string
}

export class IChangeParcelDetailsDto {
  @Expose({ name: 'TenantId' })
  tenantId: string
}
