import { Flow } from 'modules/module-navigation'
import { AlignColumnsPage, UploadFilePage, ValidateRowsPage, UploadPage } from 'modules/module-recipient-upload'

import UploadRecipientsFinishScreen from '../screens/UploadRecipientsFinishPage/UploadRecipientsFinishPage'

export type UploadFlow = Flow<UploadScreen>

export enum UploadScreen {
  UploadFile = 'upload-file',
  AlignColumns = 'align-columns',
  ValidateRows = 'validate-rows',
  Upload = 'upload',
  Finish = 'finish',
}

export const uploadFlow: UploadFlow = [
  {
    id: UploadScreen.UploadFile,
    component: UploadFilePage,
    actions: [
      {
        id: 'align-columns',
        to: UploadScreen.AlignColumns,
      },
    ],
  },
  {
    id: UploadScreen.AlignColumns,
    component: AlignColumnsPage,
    actions: [
      {
        id: 'validate',
        to: UploadScreen.ValidateRows,
      },
      {
        id: 'previous',
        to: UploadScreen.UploadFile,
      },
    ],
  },
  {
    id: UploadScreen.ValidateRows,
    component: ValidateRowsPage,
    actions: [
      {
        id: 'confirm',
        to: UploadScreen.Upload,
      },
      {
        id: 'previous',
        to: UploadScreen.AlignColumns,
      },
    ],
  },
  {
    id: UploadScreen.Upload,
    component: UploadPage,
    actions: [
      {
        id: 'finish',
        to: UploadScreen.Finish,
      },
      {
        id: 'previous',
        to: UploadScreen.ValidateRows,
      },
    ],
  },
  {
    id: UploadScreen.Finish,
    component: UploadRecipientsFinishScreen,
    actions: [
      {
        id: 'finish',
        to: UploadScreen.UploadFile,
      },
    ],
  },
]

export default uploadFlow
