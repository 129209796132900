import http from 'modules/http'
import { HttpStatusCode } from 'axios'
import { handleActions } from 'modules/utils/redux'
import { SelectAccountDto } from '../api/dto'

import { CLEAR_ERROR, ERROR, LOADING, LOGIN_STEP, SET_ACCOUNT_LIST, SET_LOGIN_MODE, SIGNUP_STEP } from './actions'
import { IAuthenticationState, initialState, LoginMode, LoginStep, SignupStep, VerificationError } from './models'

export const reducer = handleActions<IAuthenticationState>(
  {
    [ERROR]: (state: IAuthenticationState, { payload }: { payload: Error }) => {
      state.error = VerificationError.Unknown
      if (!http.guards.isError(payload)) {
        return
      }
      switch (payload.response?.status) {
        case HttpStatusCode.BadRequest:
          state.error = VerificationError.Invalid
          break
        case HttpStatusCode.Forbidden:
          state.error = VerificationError.Locked
          break
        case HttpStatusCode.Conflict:
          state.error = VerificationError.Exists
          break
        case HttpStatusCode.TooManyRequests:
          state.error = VerificationError.Throttle
          break
        default:
          state.error = VerificationError.Unknown
      }
    },
    [LOADING]: (state: IAuthenticationState, { payload }: { payload: boolean }) => {
      state.loading = payload
      if (payload) state.error = undefined
    },
    [SIGNUP_STEP]: (state: IAuthenticationState, { payload }: { payload: SignupStep | undefined }) => {
      state.signupStep = payload
      state.error = undefined
    },
    [SET_ACCOUNT_LIST]: (state: IAuthenticationState, { payload }: { payload: SelectAccountDto[] }) => {
      state.accountList = payload
    },
    [LOGIN_STEP]: (state: IAuthenticationState, { payload }: { payload: LoginStep }) => {
      state.loginStep = payload
      state.error = undefined
    },
    [CLEAR_ERROR]: (state: IAuthenticationState) => {
      state.error = undefined
    },
    [SET_LOGIN_MODE]: (state: IAuthenticationState, { payload }: { payload: LoginMode }) => {
      state.loginMode = payload
    },
  },
  initialState,
)

export default reducer
