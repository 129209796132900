export * from './alert/hooks/use-toast/use-toast'
export * from './alert/hooks/use-clipboard/use-clipboard'
export * from './alert/components/Toasts/Toasts'
export * from './alert/components/BannerContainer/BannerContainer'
export * from './alert/model'
export * from './alert/effects'

import alert from './alert'
import reducer from './root-reducer'
import alertsSagas from './sagas'

export const alerts = {
  actions: {
    snackbar: alert.actions.show,
    info: alert.actions.showInfo,
    success: alert.actions.showSuccess,
    warning: alert.actions.showWarning,
    error: alert.actions.showError,
    copy: alert.actions.copy,
  },
  sagas: alertsSagas,
  reducer,
}

export default alerts
