import { EuiButtonEmpty } from '@elastic/eui'
import { useTheme } from '@emotion/react'
import { usePaginationBoundaries } from 'modules/module-utils'
import { Grid, IGridProps, Paragraph, Span, useScreen, useTranslate } from 'modules/web-atoms'
import React from 'react'
import { Trans } from 'react-i18next'
import { IPagination } from '../Table'

const INTL_PREFIX = 'web-molecules.table'

interface ITableSelectionProps extends IGridProps {
  pagination: IPagination
  isSelectionEnabled: boolean
  numSelected: number
  onSelectAll: () => void
  onClear: () => void
}

const TableSelection: React.FC<ITableSelectionProps> = ({
  pagination,
  isSelectionEnabled,
  numSelected,
  onSelectAll,
  onClear,
  ...rest
}) => {
  const t = useTranslate(INTL_PREFIX)
  const theme = useTheme()
  const { pageStarting, pageEnding } = usePaginationBoundaries(pagination)
  const canBatchSelect = pagination.total > 0
  const { isMobile } = useScreen()

  const buttonStyles: React.CSSProperties = {
    height: 'fit-content',
  }

  return (
    <Grid direction="row" gutterSize="small" alignItems="center" flexWrap="wrap" {...rest}>
      <Paragraph size="medium">
        <Trans
          i18nKey={`${INTL_PREFIX}.showing-items`}
          components={{
            bold: <Span fontWeight="bold" />,
          }}
          values={{
            pageStarting,
            pageEnding,
            totalItems: pagination.total,
          }}
        />
      </Paragraph>
      {isSelectionEnabled && (
        <>
          <Paragraph size="medium" color="primaryMid" fontWeight="medium">
            {t('selected-items', { numSelected })}
          </Paragraph>
          <EuiButtonEmpty
            style={{ color: canBatchSelect ? theme.colors.primaryMid : undefined, ...buttonStyles }}
            flush={isMobile ? 'both' : undefined}
            iconType="pagesSelect"
            color="primary"
            onClick={onSelectAll}
            disabled={!canBatchSelect}
          >
            {t('select-all', { totalItems: pagination.total })}
          </EuiButtonEmpty>
          {numSelected > 0 && (
            <EuiButtonEmpty
              style={{ ...buttonStyles }}
              flush={isMobile ? 'both' : undefined}
              iconType="minusInCircleFilled"
              color="danger"
              onClick={onClear}
              disabled={!canBatchSelect}
            >
              {t('clear-selection')}
            </EuiButtonEmpty>
          )}
        </>
      )}
    </Grid>
  )
}

export default React.memo(TableSelection)
