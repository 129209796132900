import autobind from 'autobind-decorator'
import { AxiosInstance } from 'axios'
import { instanceToPlain } from 'class-transformer'
import dompurify from 'dompurify'
import http from 'modules/http'
import notificationsToast from 'modules/module-alerts'
import { IQueryService, QueryKey } from 'modules/module-api'
import { translate } from 'modules/module-intl'
import navigation from 'modules/module-navigation'
import orchestration from 'modules/module-orchestration'
import security from 'modules/module-security'
import { SupervisorSuite } from 'modules/redux-supervisor'
import { SagaIterator } from 'redux-saga'
import { all, call, put, select, takeLatest, takeLeading } from 'redux-saga/effects'
import { reporting } from '../../module-reporting/index'
import { IEditTemplateDto, INotificationConfigurationDto, ISetTemplateAsDefaultDto, TemplateType } from '../api/dto'
import notifications from '../notification'
import { NotificationsPath } from '../routes/notifications-routes'

const {
  updateConfiguration,
  updateTemplate,
  resetConfigurationTemplate,
  resetTemplate,
  configurationAssignAll,
  templateAssignAll,
  duplicateConfiguration,
  duplicateTemplate,
  deleteConfiguration,
  deleteTemplate,
  createNewTemplate,
  assignConfiguration,
  assignTemplate,
  removeTemplate,
  setTemplateAsDefault,
} = notifications.actions

export class NotificationsSaga extends SupervisorSuite {
  private readonly apiService: AxiosInstance
  private readonly queryService: IQueryService

  constructor(apiService: AxiosInstance, queryService: IQueryService) {
    super()
    this.apiService = apiService
    this.queryService = queryService
  }

  @autobind
  *start(): SagaIterator {
    yield all([
      takeLatest(orchestration.actions.DETERMINE_USER_PERMISSIONS, this.determineUserPermissions),
      takeLeading(notifications.actions.UPDATE_CONFIGURATION, this.updateConfiguration),
      takeLeading(notifications.actions.UPDATE_TEMPLATE, this.updateTemplate),
      takeLeading(notifications.actions.RESET_CONFIGURATION_TEMPLATE, this.resetConfigurationTemplate),
      takeLeading(notifications.actions.RESET_TEMPLATE, this.resetTemplate),
      takeLeading(notifications.actions.CONFIGURATION_ASSIGN_ALL, this.configurationAssignAll),
      takeLeading(notifications.actions.TEMPLATE_ASSIGN_ALL, this.templateAssignAll),
      takeLeading(notifications.actions.ASSIGN_CONFIGURATION, this.assignConfiguration),
      takeLeading(notifications.actions.ASSIGN_TEMPLATE, this.assignTemplate),
      takeLeading(notifications.actions.REMOVE_TEMPLATE, this.removeTemplate),
      takeLeading(notifications.actions.DUPLICATE_CONFIGURATION, this.duplicateConfiguration),
      takeLeading(notifications.actions.DUPLICATE_TEMPLATE, this.duplicateTemplate),
      takeLeading(notifications.actions.CREATE_NEW_TEMPLATE, this.createNewTemplate),
      takeLeading(notifications.actions.CREATE_NEW_CONFIGURATION, this.createNewConfiguration),
      takeLatest(notifications.actions.DELETE_CONFIGURATION, this.deleteConfiguration),
      takeLatest(notifications.actions.DELETE_TEMPLATE, this.deleteTemplate),
      takeLatest(notifications.actions.SET_TEMPLATE_AS_DEFAULT, this.setTemplateAsDefault),
    ])
  }

  @autobind
  *determineUserPermissions(): SagaIterator {
    const userType = yield select(security.selectors.type)
    yield put(notifications.actions.setPermissions({ userType }))
  }

  @autobind
  *createNewTemplate({ payload }: ReturnType<typeof createNewTemplate>): SagaIterator {
    const { templateType, siteId } = payload

    try {
      yield put(notifications.actions.setLoading(true))
      const response = yield call(this.apiService, `/v3/notifications/templates/new`, {
        method: 'POST',
        params: {
          development_id: siteId,
        },
        data: {
          Name: 'New Default Template',
          Type: templateType,
        },
      })
      if (response?.data?.Id) {
        const newTemplateId = response.data.Id
        yield put(
          navigation.actions.navigate({
            route: NotificationsPath.EDIT_TEMPLATE,
            query: { siteId, templateId: newTemplateId },
          }),
        )
      }
    } catch (error) {
      yield put(reporting.actions.error(error))
      yield put(
        notificationsToast.actions.error({
          message: yield translate('notifications.notification.kanban.actions.error'),
        }),
      )
    } finally {
      yield put(notifications.actions.setLoading(false))
    }
  }

  @autobind
  *createNewConfiguration(): SagaIterator {
    const client = yield select(security.selectors.client)

    if (client?.Id) {
      try {
        yield put(notifications.actions.setLoading(true))
        const response = yield call(this.apiService, `/v3/notifications/configurations/${client.Id}/new`, {
          method: 'POST',
          data: {
            Name: 'New Default Configuration',
          },
        })
        if (response?.data?.Id) {
          const newConfigId = response.data.Id
          yield put(
            navigation.actions.navigate({
              route: NotificationsPath.CONFIGURATION_PAGE,
              query: {
                templateId: newConfigId,
              },
            }),
          )
        }
      } catch (error) {
        yield put(reporting.actions.error(error))
        yield put(
          notificationsToast.actions.error({
            message: yield translate('notifications.notification.kanban.actions.error'),
          }),
        )
      } finally {
        yield put(notifications.actions.setLoading(false))
      }
    }
  }

  @autobind
  *configurationAssignAll({ payload }: ReturnType<typeof configurationAssignAll>): SagaIterator {
    const { configurationId } = payload
    const buildingIds = yield select(notifications.selectors.currentBuildingIds)

    if (buildingIds.length) {
      try {
        yield put(notifications.actions.setActionLoading(true))
        yield call(this.apiService, '/v3/notifications/assignments/configurations', {
          method: 'PUT',
          data: {
            DevelopmentIds: buildingIds,
            ConfigurationId: configurationId,
          },
        })
        yield call(this.queryService.refetchQueries, [QueryKey.NOTIFICATION_ASSIGNMENTS])
      } catch (error) {
        yield put(reporting.actions.error(error))
        yield put(
          notificationsToast.actions.error({
            message: yield translate('notifications.notification.kanban.actions.error'),
          }),
        )
      } finally {
        yield put(notifications.actions.setActionLoading(false))
      }
    }
  }

  @autobind
  *templateAssignAll({ payload }: ReturnType<typeof templateAssignAll>): SagaIterator {
    const { templateId, templateType } = payload
    const buildingIds = yield select(notifications.selectors.currentBuildingIds)

    if (buildingIds.length) {
      try {
        yield put(notifications.actions.setActionLoading(true))
        yield call(this.apiService, '/v3/notifications/assignments/templates', {
          method: 'PUT',
          data: {
            DevelopmentIds: buildingIds,
            TemplateIds: [templateId],
            TemplateType: templateType,
          },
        })
        yield call(this.queryService.refetchQueries, [QueryKey.NOTIFICATION_ASSIGNMENTS])
      } catch (error) {
        yield put(reporting.actions.error(error))
        yield put(
          notificationsToast.actions.error({
            message: yield translate('notifications.notification.kanban.actions.error'),
          }),
        )
      } finally {
        yield put(notifications.actions.setActionLoading(false))
      }
    }
  }

  @autobind
  *removeTemplate({ payload }: ReturnType<typeof removeTemplate>): SagaIterator {
    const { assignedTemplateIds, siteId, templateIdToRemove, templateType } = payload

    try {
      yield put(notifications.actions.setActionLoading(true))
      const newTemplateIds = assignedTemplateIds.filter((templateId) => templateId !== templateIdToRemove)
      yield call(this.apiService, `/v3/notifications/assignments/templates`, {
        method: 'PUT',
        data: {
          DevelopmentIds: [siteId],
          TemplateIds: newTemplateIds,
          TemplateType: templateType,
        },
      })
      yield call(this.queryService.refetchQueries, [QueryKey.NOTIFICATION_ASSIGNMENTS])
    } catch (error) {
      yield put(reporting.actions.error(error))
      yield put(
        notificationsToast.actions.error({
          message: yield translate('notifications.notification.kanban.actions.error'),
        }),
      )
    } finally {
      yield put(notifications.actions.setActionLoading(false))
    }
  }

  @autobind
  *assignTemplate({ payload }: ReturnType<typeof assignTemplate>): SagaIterator {
    const { templateIds, siteId, templateType } = payload

    try {
      yield put(notifications.actions.setIsAssigning(true))
      yield call(this.apiService, `/v3/notifications/assignments/templates`, {
        method: 'PUT',
        data: {
          DevelopmentIds: [siteId],
          TemplateIds: templateIds,
          TemplateType: templateType,
        },
      })

      yield call(this.queryService.refetchQueries, [QueryKey.NOTIFICATION_ASSIGNMENTS])
      yield call(this.queryService.refetchQueries, [QueryKey.NOTIFICATION_CONFIGURATION_LIST])
    } catch (error) {
      yield put(reporting.actions.error(error))
      yield put(
        notificationsToast.actions.error({
          message: yield translate('notifications.notification.kanban.actions.error'),
        }),
      )
    } finally {
      yield put(notifications.actions.setIsAssigning(false))
    }
  }

  @autobind
  *assignConfiguration({ payload }: ReturnType<typeof assignConfiguration>): SagaIterator {
    const { siteId, configurationId } = payload

    try {
      yield put(notifications.actions.setIsAssigning(true))
      yield call(this.apiService, '/v3/notifications/assignments/configurations', {
        method: 'PUT',
        data: {
          DevelopmentIds: [siteId],
          ConfigurationId: configurationId,
        },
      })
      yield call(this.queryService.refetchQueries, [QueryKey.NOTIFICATION_ASSIGNMENTS])
      yield call(this.queryService.refetchQueries, [QueryKey.NOTIFICATION_CONFIGURATION_LIST])
    } catch (error) {
      yield put(reporting.actions.error(error))
      yield put(
        notificationsToast.actions.error({
          message: yield translate('notifications.notification.kanban.actions.error'),
        }),
      )
    } finally {
      yield put(notifications.actions.setIsAssigning(false))
    }
  }

  @autobind
  *deleteConfiguration({ payload }: ReturnType<typeof deleteConfiguration>): SagaIterator {
    const configurationId = payload
    const client = yield select(security.selectors.client)

    if (client?.Id) {
      try {
        yield put(notifications.actions.setActionLoading(true))
        yield call(this.apiService, `/v3/notifications/configurations/${client.Id}/${configurationId}`, {
          method: 'DELETE',
        })
        yield call(this.queryService.refetchQueries, [QueryKey.NOTIFICATION_CONFIGURATION_LIST])
      } catch (error) {
        yield put(reporting.actions.error(error))

        if (http.guards.isError(error) && error.response?.status === 409) {
          yield put(
            notificationsToast.actions.error({
              message: yield translate('notifications.notification.kanban.actions.conflict-error'),
            }),
          )
        } else {
          yield put(
            notificationsToast.actions.error({
              message: yield translate('notifications.notification.kanban.actions.error'),
            }),
          )
        }
      } finally {
        yield put(notifications.actions.setActionLoading(false))
      }
    }
  }

  @autobind
  *deleteTemplate({ payload }: ReturnType<typeof deleteTemplate>): SagaIterator {
    const templateId = payload
    const client = yield select(security.selectors.client)

    if (client?.Id) {
      try {
        yield put(notifications.actions.setActionLoading(true))
        yield call(this.apiService, `/v3/notifications/templates/${client.Id}/${templateId}`, {
          method: 'DELETE',
        })
        yield call(this.queryService.refetchQueries, [QueryKey.NOTIFICATION_TEMPLATE_LIST])
      } catch (error) {
        yield put(reporting.actions.error(error))

        if (http.guards.isError(error) && error.response?.status === 409) {
          yield put(
            notificationsToast.actions.error({
              message: yield translate('notifications.notification.kanban.actions.conflict-error'),
            }),
          )
        } else {
          yield put(
            notificationsToast.actions.error({
              message: yield translate('notifications.notification.kanban.actions.error'),
            }),
          )
        }
      } finally {
        yield put(notifications.actions.setActionLoading(false))
      }
    }
  }

  @autobind
  *duplicateConfiguration({ payload }: ReturnType<typeof duplicateConfiguration>): SagaIterator {
    const id = payload
    const client = yield select(security.selectors.client)

    if (client?.Id) {
      try {
        yield put(notifications.actions.setActionLoading(true))
        const response = yield call(this.apiService, `/v3/notifications/configurations/${client.Id}/${id}/duplicate`, {
          method: 'POST',
          data: {
            Name: 'Configuration Template Duplicate',
          },
        })
        if (response?.data?.Id) {
          const configurationId = response.data.Id
          yield put(
            navigation.actions.navigate({
              route: NotificationsPath.CONFIGURATION_PAGE,
              query: { templateId: configurationId },
            }),
          )
        }
      } catch (error) {
        yield put(reporting.actions.error(error))
        yield put(
          notificationsToast.actions.error({
            message: yield translate('notifications.notification.kanban.actions.error'),
          }),
        )
      } finally {
        yield put(notifications.actions.setActionLoading(false))
      }
    }
  }

  @autobind
  *duplicateTemplate({ payload }: ReturnType<typeof duplicateTemplate>): SagaIterator {
    const { templateId, siteId } = payload
    const client = yield select(security.selectors.client)

    if (client?.Id) {
      try {
        yield put(notifications.actions.setActionLoading(true))
        const response = yield call(
          this.apiService,
          `/v3/notifications/templates/${client.Id}/${templateId}/duplicate`,
          {
            method: 'POST',
            data: {
              Name: 'Template Duplicate',
            },
          },
        )
        if (response?.data?.Id) {
          const templateId = response.data.Id
          yield put(
            navigation.actions.navigate({ route: NotificationsPath.EDIT_TEMPLATE, query: { siteId, templateId } }),
          )
        }
      } catch (error) {
        yield put(reporting.actions.error(error))
        yield put(
          notificationsToast.actions.error({
            message: yield translate('notifications.notification.kanban.actions.error'),
          }),
        )
      } finally {
        yield put(notifications.actions.setActionLoading(false))
      }
    }
  }

  @autobind
  *setTemplateAsDefault({ payload }: ReturnType<typeof setTemplateAsDefault>): SagaIterator {
    const data = new ISetTemplateAsDefaultDto()
    data.templateId = payload.templateId
    data.buildingId = payload.siteId
    data.templateType = payload.templateType

    try {
      yield put(notifications.actions.setActionLoading(true))
      yield call(this.apiService, '/v3/notifications/assignments/templates/set-default', {
        method: 'POST',
        data: instanceToPlain(data),
      })
      yield call(this.queryService.refetchQueries, [QueryKey.NOTIFICATION_ASSIGNMENTS])
    } catch (error) {
      yield put(reporting.actions.error(error))
      yield put(
        notificationsToast.actions.error({
          message: yield translate('notifications.notification.kanban.actions.error'),
        }),
      )
    } finally {
      yield put(notifications.actions.setActionLoading(false))
    }
  }

  @autobind
  *updateConfiguration({ payload }: ReturnType<typeof updateConfiguration>): SagaIterator {
    const {
      templateName,
      backgroundColor,
      secondaryColor,
      logoSize,
      locale,
      emailNotifications,
      smsNotifications,
      logoFile,
      logoUpdated,
      id,
      schedulerEnabled,
      schedulerType,
      timezone,
      schedules,
    } = payload
    const data = new INotificationConfigurationDto()
    data.logoWidth = logoSize
    data.name = templateName
    data.backgroundColour = backgroundColor
    data.accentColour = secondaryColor
    data.globalEmailsEnabled = emailNotifications
    data.globalSmsEnabled = smsNotifications
    data.logoUpdated = logoUpdated
    data.locale = locale
    data.scheduler = {
      enabled: schedulerEnabled,
      type: schedulerType,
      timezone,
      schedules,
    }

    if (logoFile) {
      yield call(this.uploadLogo, id, logoFile)
    }

    try {
      yield put(notifications.actions.setLoading(true))
      const client = yield select(security.selectors.client)
      yield call(this.apiService, `/v3/notifications/configurations/${client.Id}/${id}`, {
        method: 'PUT',
        data: instanceToPlain(data),
      })
      yield put(
        notificationsToast.actions.success({ message: yield translate('notifications.notification.settings.success') }),
      )
      yield call(this.queryService.refetchQueries, [QueryKey.NOTIFICATION_CONFIGURATION])
      yield put(navigation.actions.navigate({ route: NotificationsPath.KANBAN_PAGE }))
    } catch (err) {
      yield put(reporting.actions.error(err))
      yield put(
        notificationsToast.actions.error({ message: yield translate('notifications.notification.settings.error') }),
      )
    } finally {
      yield put(notifications.actions.setLoading(false))
    }
  }

  @autobind
  *uploadLogo(configId: string, logoFile: File): SagaIterator {
    const data = new FormData()
    data.append('logoFile', logoFile)

    try {
      const client = yield select(security.selectors.client)
      yield call(this.apiService, `/v3/notifications/configurations/${client.Id}/${configId}/upload-logo`, {
        method: 'PUT',
        data,
        headers: { 'Content-Type': 'multipart/form-data' },
      })
    } catch (error) {
      yield put(
        notificationsToast.actions.error({
          message: yield translate('notifications.notification.settings.upload-logo-error'),
        }),
      )
    }
  }

  @autobind
  *updateTemplate({ payload }: ReturnType<typeof updateTemplate>): SagaIterator {
    const client = yield select(security.selectors.client)

    if (!!client?.Id) {
      const { templateId, templateName, templateType, emailConfig, smsConfig } = payload
      const data = new IEditTemplateDto()
      data.name = templateName
      data.heading = dompurify.sanitize(emailConfig.heading)
      data.subheading = dompurify.sanitize(emailConfig.subheading)
      data.body = dompurify.sanitize(emailConfig.body)
      data.subject = dompurify.sanitize(emailConfig.subject)

      if (templateType === TemplateType.REMINDER) {
        data.reminderDelay = emailConfig.reminderDelay
        data.maxReminders = emailConfig.maxReminders
      }
      if ([TemplateType.DELIVERY].includes(templateType)) {
        data.smsTemplate = smsConfig.body
      }
      if ([TemplateType.DELIVERY, TemplateType.REMINDER].includes(templateType)) {
        data.qrCodeEnabled = emailConfig.enableQRCode
      }

      if ([TemplateType.DELIVERY, TemplateType.COLLECTION].includes(templateType)) {
        data.notesEnabled = emailConfig.enableNotes
        data.additionalParcelDetails = emailConfig.advancedParcelDetails
      }

      if (templateType === TemplateType.COLLECTION) {
        data.signatureEnabled = emailConfig.enableSignature
      }

      try {
        yield put(notifications.actions.setLoading(true))
        yield call(this.apiService, `/v3/notifications/templates/${client.Id}/${templateId}`, {
          method: 'PUT',
          data: instanceToPlain(data),
        })
        yield put(
          notificationsToast.actions.success({
            message: yield translate('notifications.notification.notification-template.success'),
          }),
        )
        yield put(navigation.actions.navigate({ route: NotificationsPath.KANBAN_PAGE }))
      } catch (err) {
        yield put(reporting.actions.error(err))
        yield put(
          notificationsToast.actions.error({
            message: yield translate('notifications.notification.notification-template.error'),
          }),
        )
      } finally {
        yield put(notifications.actions.setLoading(false))
      }
    }
  }

  @autobind
  *resetConfigurationTemplate({ payload }: ReturnType<typeof resetConfigurationTemplate>) {
    const client = yield select(security.selectors.client)

    if (client?.Id) {
      yield put(notifications.actions.setRestoreDefaultLoading(true))

      try {
        yield call(this.apiService, `/v3/notifications/configurations/${client.Id}/${payload}/reset`, {
          method: 'POST',
        })
        yield call(this.queryService.refetchQueries, [QueryKey.NOTIFICATION_CONFIGURATION, payload])
      } catch (error) {
        yield put(reporting.actions.error(error))
      } finally {
        yield put(notifications.actions.setRestoreDefaultLoading(false))
      }
    }
  }

  @autobind
  *resetTemplate({ payload }: ReturnType<typeof resetTemplate>) {
    const { templateId, siteId } = payload

    yield put(notifications.actions.setRestoreDefaultLoading(true))

    try {
      yield call(this.apiService, `/v3/notifications/templates/${templateId}/reset`, {
        method: 'POST',
        params: {
          development_id: siteId,
        },
      })
      yield call(this.queryService.refetchQueries, [QueryKey.NOTIFICATION_TEMPLATE, payload.templateId])
    } catch (error) {
      yield put(reporting.actions.error(error))
    } finally {
      yield put(notifications.actions.setRestoreDefaultLoading(false))
    }
  }
}

export default NotificationsSaga
