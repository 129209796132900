import { AnyAction } from 'redux'
import { Action, ActionMeta } from 'redux-actions'

import { isError as isFSAError } from 'flux-standard-action'

import { IContextShape, IErrorMeta } from '../model'

import { ERROR } from './actions'

export const isError = (action: AnyAction): action is Action<string | Error> =>
  action.type === ERROR || isFSAError(action)

export const context = <P = unknown>(action: Action<P>): action is ActionMeta<P, IErrorMeta<IContextShape>> =>
  !!(action as ActionMeta<P, IErrorMeta<IContextShape>>).meta?.errors?.context
