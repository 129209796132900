import { Currency, PaymentManagement, Plan, PlanBenefits } from '../billing/models'
import { IPlanConfig } from '../services/models'

export const MIN_PRO_RECIPIENTS = 100
export const MAX_PRO_RECIPIENTS = 2000

export const AVAILABLE_PLANS: IPlanConfig[] = [
  {
    id: Plan.FREE,
    color: 'featureAlt',
    paymentManagement: PaymentManagement.NONE,
    tier: 0,
    benefits: [PlanBenefits.LOG_LIMITED_PARCELS, PlanBenefits.DIGITAL_LOG, PlanBenefits.PROOF_OF_COLLECTION],
    pricing: 0,
  },
  {
    id: Plan.PRO,
    color: 'primaryMid',
    tier: 1,
    paymentManagement: PaymentManagement.VENDOR,
    benefits: [PlanBenefits.ALL_OF_FREE, PlanBenefits.LOG_UNLIMITED_PARCELS, PlanBenefits.LIVE_SUPPORT],
    pricing: 99,
  },
  {
    id: Plan.PRO_MULTI_SITE,
    color: 'primaryMid',
    tier: 2,
    paymentManagement: PaymentManagement.CUSTOM,
    benefits: [PlanBenefits.ALL_OF_PRO, PlanBenefits.MULTI_SITE, PlanBenefits.MULTI_SITE_MANAGEMENT],
    pricing: 99,
  },
  {
    id: Plan.ENTERPRISE,
    color: 'featureMain',
    tier: 3,
    paymentManagement: PaymentManagement.CUSTOM,
    benefits: [PlanBenefits.SECURITY_AND_PERMISSIONS, PlanBenefits.SLA, PlanBenefits.MULTI_SITE_MANAGEMENT],
  },
]

export type ICurrencyPricing = {
  [K in Currency]: number
}

export interface IPlanPricing {
  [key: string]: {
    startingPrice: ICurrencyPricing
    recipientPrice: ICurrencyPricing
    smsPrice: ICurrencyPricing
  }
}

export type ICurrencySymbols = {
  [K in Currency]: string
}

export const CURRENCY_SYMBOLS: ICurrencySymbols = {
  [Currency.USD]: '$',
  [Currency.GBP]: '£',
  [Currency.EUR]: '€',
  [Currency.AUD]: 'A$',
}

export const PLAN_PRICING: IPlanPricing = {
  [Plan.FREE]: {
    startingPrice: {
      [Currency.USD]: 0,
      [Currency.GBP]: 0,
      [Currency.EUR]: 0,
      [Currency.AUD]: 0,
    },
    recipientPrice: {
      [Currency.USD]: 0,
      [Currency.GBP]: 0,
      [Currency.EUR]: 0,
      [Currency.AUD]: 0,
    },
    smsPrice: {
      [Currency.USD]: 0,
      [Currency.GBP]: 0,
      [Currency.EUR]: 0,
      [Currency.AUD]: 0,
    },
  },
  [Plan.PRO]: {
    startingPrice: {
      [Currency.USD]: 130,
      [Currency.GBP]: 99,
      [Currency.EUR]: 120,
      [Currency.AUD]: 180,
    },
    recipientPrice: {
      [Currency.USD]: 0.33,
      [Currency.GBP]: 0.25,
      [Currency.EUR]: 0.3,
      [Currency.AUD]: 0.45,
    },
    smsPrice: {
      [Currency.USD]: 0.05,
      [Currency.GBP]: 0.05,
      [Currency.EUR]: 0.05,
      [Currency.AUD]: 0.1,
    },
  },
  [Plan.PRO_MULTI_SITE]: {
    startingPrice: {
      [Currency.USD]: 260,
      [Currency.GBP]: 199,
      [Currency.EUR]: 240,
      [Currency.AUD]: 360,
    },
    recipientPrice: {
      [Currency.USD]: 0.33,
      [Currency.GBP]: 0.25,
      [Currency.EUR]: 0.3,
      [Currency.AUD]: 0.45,
    },
    smsPrice: {
      [Currency.USD]: 0.05,
      [Currency.GBP]: 0.05,
      [Currency.EUR]: 0.05,
      [Currency.AUD]: 0.1,
    },
  },
}
