import {
  KEY_BUILDING_IDS,
  KEY_REFRESH_TOKEN,
  KEY_ROLE,
  KEY_SERVER_ID,
  KEY_TOKEN,
  KEY_USERNAME,
  KEY_USER_ID,
} from './constants'
import { LocalValueStore } from './local-store'
import { SessionValueStore } from './session-store'

export const serverIdStore = new LocalValueStore(KEY_SERVER_ID)
export const refreshTokenStore = new LocalValueStore(KEY_REFRESH_TOKEN)
export const accessTokenStore = new SessionValueStore(KEY_TOKEN)
export const usernameStore = new LocalValueStore(KEY_USERNAME)
export const userIdStore = new LocalValueStore(KEY_USER_ID)
export const buildingIdsStore = new LocalValueStore(KEY_BUILDING_IDS)
export const roleStore = new LocalValueStore(KEY_ROLE)
