import { ThemeColor } from 'modules/dna'
import { PaymentManagement, Plan, PlanBenefits } from '../billing'

export interface IPlanConfig {
  id: Plan
  color: ThemeColor
  paymentManagement: PaymentManagement
  tier: number
  benefits: PlanBenefits[]
  pricing?: number
}

export enum PaymentMethod {
  CARD = 'card',
  BANK = 'bank',
  PO = 'po',
}
