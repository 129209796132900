import { EuiButton, EuiSearchBar } from '@elastic/eui'
import { useLocationsTable } from 'modules/module-settings/hooks/use-locations-table/use-locations-table'
import { useAppDispatch, useAppSelector, useClientBuildings } from 'modules/module-utils'
import { Grid, useToggle, useTranslate } from 'modules/web-atoms'
import { DeleteConfirmationModal, Table } from 'modules/web-molecules'
import { IRadioSelectorItem, RadioSelector } from 'modules/web-organisms'
import { ICreateOptions, IDeleteOptions, ISearchOptions } from 'modules/web-templates'
import React from 'react'
import settings, { SettingsFormState } from '../../../settings'

const TABLE_ID = 'table-locations'
const INTL_PREFIX_MODAL = 'settings.tabs.locations.delete-modal'

const LocationsTable: React.FC = () => {
  const t = useTranslate('settings.tabs.locations.table')
  const dispatch = useAppDispatch()

  const [isDeleteModalOpen, toggleDeleteModal] = useToggle(false)

  const selectedItems = useAppSelector(settings.selectors.selectedLocations)
  const selectedItem = useAppSelector(settings.selectors.selectedLocation)
  const locationFormState = useAppSelector(settings.selectors.locationFormState)
  const selectedSite = useAppSelector(settings.selectors.selectedSite)
  const isAdding = useAppSelector(settings.selectors.isAddingLocation)
  const isDeleting = useAppSelector(settings.selectors.isDeletingLocations)
  const { canManageLocations } = useAppSelector(settings.selectors.permissions)

  const { buildings, isFetching: isFetchingBuildings } = useClientBuildings()
  const siteOptions: IRadioSelectorItem[] = React.useMemo(
    () =>
      buildings?.map((building) => ({
        id: building.id.toString(),
        label: building.buildingName,
      })) ?? [],
    [buildings],
  )

  const selectedLabel = React.useMemo(
    () => siteOptions.find((site) => site.id === selectedSite?.toString())?.label ?? t('loading-sites'),
    [selectedSite, siteOptions, t],
  )

  const {
    columns,
    items,
    selection,
    onItemClicked,
    isFetching,
    sort,
    onSearch,
    error,
    pagination,
    paginationOptions,
    selectedLocations,
  } = useLocationsTable(selectedSite)
  const isLoading = isFetching || isFetchingBuildings

  React.useEffect(() => {
    if (siteOptions && siteOptions.length) {
      dispatch(settings.actions.setSelectedSite(parseInt(siteOptions[0].id)))
    }
  }, [siteOptions, dispatch])

  const onSiteChange = (newSite: string) => {
    dispatch(settings.actions.setSelectedSite(parseInt(newSite)))
  }

  const onAddClick = React.useCallback(() => {
    dispatch(settings.actions.setLocationFormState(SettingsFormState.CREATE))
  }, [dispatch])

  const createOptions: ICreateOptions = React.useMemo(
    () => ({
      visible: canManageLocations && locationFormState !== SettingsFormState.CREATE,
      onClick: onAddClick,
      value: t('add'),
    }),
    [locationFormState, canManageLocations, onAddClick, t],
  )

  const onDelete = React.useCallback(() => {
    dispatch(settings.actions.deleteLocations())
    toggleDeleteModal()
  }, [dispatch, toggleDeleteModal])

  const searchOptions: ISearchOptions = React.useMemo(
    () => ({
      hint: t('search.placeholder'),
      onSearch,
    }),
    [t, onSearch],
  )

  const deleteOptions: IDeleteOptions = React.useMemo(
    () => ({
      visible: (selectedItems.length > 0 || !!selectedItem) && canManageLocations,
      isLoading: isDeleting,
      onClick: onDelete,
      value: t('delete', { count: selectedItems.length || 1 }),
    }),
    [selectedItems.length, selectedItem, isDeleting, onDelete, canManageLocations, t],
  )

  const onQuery = React.useCallback(
    ({ queryText: filter }) => {
      searchOptions?.onSearch(filter)
    },
    [searchOptions],
  )

  return (
    <Grid flexGrow={1} minHeight={0} gutterSize="small">
      <Grid flexWrap="wrap" gutterSize="small" justifyContent="space-between">
        <Grid.Col>
          <Grid>
            <Grid flexWrap="wrap">
              {deleteOptions.visible && (
                <Grid.Col>
                  <EuiButton color="danger" fill onClick={toggleDeleteModal} isLoading={deleteOptions.isLoading}>
                    {deleteOptions.value}
                  </EuiButton>
                </Grid.Col>
              )}

              <Grid.Col>
                <RadioSelector
                  buttonProps={{ iconType: 'arrowDown', iconSide: 'right' }}
                  labelProps={{ maxWidth: [120, 200], truncated: true, minWidth: [100, 150], textAlign: 'left' }}
                  label={selectedLabel}
                  radios={siteOptions}
                  selectedRadio={selectedSite?.toString() ?? ''}
                  searchLabel={t('search-sites')}
                  onChange={onSiteChange}
                />
              </Grid.Col>
            </Grid>
          </Grid>
        </Grid.Col>

        <Grid.Col alignSelf={['flex-start', 'flex-end']}>
          <Grid gutterSize={['smallest', 'medium']} flexWrap="wrap">
            <Grid.Col>
              <EuiSearchBar box={{ placeholder: searchOptions.hint, incremental: true }} onChange={onQuery} />
            </Grid.Col>
            {createOptions.visible && (
              <EuiButton
                onClick={createOptions.onClick}
                color="primary"
                fill
                isDisabled={!selectedSite}
                isLoading={isAdding}
              >
                {createOptions.value}
              </EuiButton>
            )}
          </Grid>
        </Grid.Col>
      </Grid>

      <Table
        tableId={TABLE_ID}
        columns={columns}
        items={items}
        selection={selection}
        sorting={sort}
        pagination={pagination}
        paginationOptions={paginationOptions}
        loading={isLoading}
        isCheckable={canManageLocations}
        onItemClicked={canManageLocations ? onItemClicked : undefined}
        selectedItems={selectedLocations}
      />

      {isDeleteModalOpen && (
        <DeleteConfirmationModal
          onClose={toggleDeleteModal}
          onDelete={onDelete}
          itemSize={selectedItems.length || 1}
          intlPrefix={INTL_PREFIX_MODAL}
        />
      )}
    </Grid>
  )
}

export default React.memo(LocationsTable)
