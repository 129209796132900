import { Role } from 'modules/module-security'
import * as React from 'react'
import { Route, Switch } from 'react-router-dom'

export interface IModuleRoute {
  path: string
  page?: React.ComponentType<any>
  role?: Role
  featureFlag?: string
  routes?: IModuleRoute[]
}

export const processRoute = (route: IModuleRoute) => {
  if (route.page != undefined) {
    return <Route path={route.path} component={route.page} key={route.path} />
  } else {
    return route.routes?.map((innerRoute) => processRoute(innerRoute))
  }
}
