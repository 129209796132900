import autobind from 'autobind-decorator'
import { AxiosInstance } from 'axios'
import reporting from 'modules/module-reporting'
import { SupervisorSuite } from 'modules/redux-supervisor'
import { SagaIterator } from 'redux-saga'
import { all, call, put, takeEvery } from 'redux-saga/effects'
import orchestration from '..'
import { deleteAllRecipients } from '../orchestration/actions'

export class OrchestrationSaga extends SupervisorSuite {
  private readonly apiService: AxiosInstance

  constructor(apiService: AxiosInstance) {
    super()
    this.apiService = apiService
  }

  @autobind
  *start(): SagaIterator {
    yield all([takeEvery(orchestration.actions.DELETE_ALL_RECIPIENTS, this.deleteAllRecipients)])
  }

  @autobind
  *deleteAllRecipients({ payload }: ReturnType<typeof deleteAllRecipients>): SagaIterator {
    yield put(orchestration.actions.setIsWipingBuildings(true))
    try {
      yield call(this.apiService, `developments/offboard`, {
        method: 'POST',
        data: {
          developmentIds: payload,
        },
      })
    } catch (err) {
      yield put(orchestration.actions.error('Something went wrong'))
      yield put(reporting.actions.error(err))
    } finally {
      yield put(orchestration.actions.setIsWipingBuildings(false))
    }
  }
}

export default OrchestrationSaga
