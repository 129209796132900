import parcels from './parcels'
import reducer from './root-reducer'
import deliveriesSagas from './sagas'

export * from './navigation/routes'
export * from './navigation/routes'
export { default as deliveriesRoutes } from './navigation/routes'

export const deliveriesModule = {
  actions: {
    ...parcels.actions,
  },
  selectors: {
    ...parcels.selectors,
  },
  reducer,
  sagas: deliveriesSagas,
}

export default deliveriesModule
