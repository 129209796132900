import {
  IDevelopmentDto,
  ITenantBatchUploadResultDto,
  ITenantDiffDeleteTenantDto,
  ITenantDto,
  ITenantsDiffDeleteDto,
} from 'api'
import { setOnboardingStage } from 'api/auth'
import autobind from 'autobind-decorator'
import { AxiosInstance, AxiosResponse } from 'axios'
import { instanceToPlain, plainToInstance } from 'class-transformer'
import notification from 'modules/module-alerts/alert'
import { translate } from 'modules/module-intl'
import moduleReporting, { reporting } from 'modules/module-reporting'
import security, { OnboardingStage } from 'modules/module-security'
import { SupervisorSuite } from 'modules/redux-supervisor'
import { Action } from 'redux-actions'
import { SagaIterator } from 'redux-saga'
import { all, call, delay, put, select, takeLatest, takeLeading } from 'redux-saga/effects'
import { IBatchTenantsDto, IUploadPreviewDto } from '../api/dto'
import { IValidateRowItem } from '../screens'
import upload, {
  AlignError,
  IAdjustRowPayload,
  IGeneratePreviewPayload,
  IRemoveRowsPayload,
  ISetColumnAlignmentPayload,
  ISetRecipientsSitePayload,
  IUpdateEditablePayload,
  IUploadPreviewSite,
  IUploadRecipientsFilePayload,
  UploadUpdate,
  UploadingStatus,
} from '../upload'
import { UploadingService } from '../uploading/UploadingService'
import IUploadingSession from '../uploading/UploadingSession'
import { ColumnAlignment, FileParseResult, RecipientField, RecipientUpload, Validation } from '../uploading/types'

const CHUNK_SIZE = 200

export class RecipientsUploadSaga extends SupervisorSuite {
  readonly uploadingService: UploadingService = new UploadingService()
  readonly apiService: AxiosInstance
  private uploadSession: IUploadingSession

  constructor(apiService: AxiosInstance) {
    super()
    this.apiService = apiService

    this.uploadSession = {
      defaultDevelopmentId: undefined,
      input: {},
      currentInput: {},
      currentInputOrder: [],
      columns: [],
      alignment: {},
      idFrequencyMap: {},
      editable: undefined,
      sites: [],
    }
  }

  @autobind
  *start(): SagaIterator {
    yield all([
      takeLatest(upload.actions.UPLOAD_RECIPIENTS_FILE, this.uploadRecipientsFile),
      takeLatest(upload.actions.CHECK_FIELD_VALIDATION, this.checkFieldValidation),
      takeLatest(upload.actions.VALIDATE_FILE, this.validateRecipientsFile),
      takeLatest(upload.actions.SET_COLUMN_ALIGNMENT, this.setColumnAlignment),
      takeLatest(upload.actions.ADJUST_ROW, this.adjustRow),
      takeLatest(upload.actions.REMOVE_ROWS, this.removeRows),
      takeLatest(upload.actions.UPDATE_EDITABLE, this.updateEditable),
      takeLatest(upload.actions.UPDATE_VALIDATE_PAGE, this.updateValidateItems),
      takeLatest(upload.actions.GENERATE_UPLOAD_PREVIEW, this.generatePreview),
      takeLeading(upload.actions.FINALISE_UPLOAD, this.finialiseRecipientData),
      takeLeading(upload.actions.SET_RECIPIENTS_SITE, this.setRecipientsSite),
      takeLeading(upload.actions.UPLOAD_RECIPIENTS, this.uploadRecipients),
    ])
  }

  @autobind
  *uploadRecipientsFile({ payload }: Action<IUploadRecipientsFilePayload>): SagaIterator {
    try {
      yield put(upload.actions.setIsValid(false))
      const fileFormat = yield call(this.uploadingService.validateFile, payload)

      if (fileFormat == FileParseResult.VALID) {
        const [sanitised, columns]: [RecipientUpload, string[]] = yield call(
          this.uploadingService.sanitiseFile,
          payload,
        )
        let alignment: ColumnAlignment = {}
        if (columns.length) {
          alignment = yield call(this.uploadingService.alignColumns, columns)
        }

        yield put(
          upload.actions.setRecipientsFile({
            input: sanitised,
            columns: columns,
            alignment: alignment,
          }),
        )

        const client = yield select(security.selectors.client)
        const sitesResponse = yield call(this.apiService, '/v3/developments', {
          params: {
            client_id: client.Id,
          },
        })

        const sites: IDevelopmentDto[] = plainToInstance(IDevelopmentDto, sitesResponse.data as [any])

        this.uploadSession = {
          defaultDevelopmentId: undefined,
          input: sanitised,
          currentInput: sanitised,
          currentInputOrder: [],
          columns: columns,
          alignment: alignment,
          validation: undefined,
          idFrequencyMap: {},
          editable: undefined,
          sites: sites,
        }

        yield put(upload.actions.navigateRecipientUpload('align-columns'))
        yield call(this.updateAlignmentItems)
      } else {
        // TODO different message based on error
        yield put(
          notification.actions.showError({
            title: 'Must contain required columns',
            message: 'File has missing columns required for recipient details',
          }),
        )
      }
    } catch (exception) {
      yield put(moduleReporting.actions.error(exception))
      yield put(
        notification.actions.showError({
          title: 'Something went wrong!',
          message: '',
        }),
      )
    }
  }

  @autobind
  *validateRecipientsFile(): SagaIterator {
    try {
      const alignments = this.uploadSession.alignment
      const invalidAlignments = yield call(this.uploadingService.isValidAlignment, alignments)
      if (invalidAlignments.length) {
        yield put(
          notification.actions.showError({
            title: 'Missing columns',
            message: `Required columns must be used.
            ${invalidAlignments.join(', ')}`,
          }),
        )
        yield put(upload.actions.alignmentError({ error: AlignError.REQUIRED_MISSING }))
      } else {
        const input = this.uploadSession.currentInput
        const sites = this.uploadSession.sites

        const isIdUpload = Object.values(alignments).some((alignment) => alignment === RecipientField.ID)
        yield put(upload.actions.setIsWipeAvailable(isIdUpload))

        const [validations, sortedInput, inputOrder, idFrequencyMap] = yield call(
          this.uploadingService.validateRows,
          input,
          alignments,
          sites,
          true,
        )

        this.uploadSession.validation = validations
        this.uploadSession.currentInput = sortedInput
        this.uploadSession.currentInputOrder = inputOrder
        this.uploadSession.idFrequencyMap = idFrequencyMap

        yield put(upload.actions.checkFieldValidation())
        yield put(upload.actions.navigateRecipientUpload('validate'))
        yield call(this.updateValidateItems)
      }
    } catch (error) {
      yield put(moduleReporting.actions.error(error))
      yield put(
        notification.actions.showError({
          title: 'Something went wrong!',
          message: '',
        }),
      )
      yield put(upload.actions.alignmentError({ error: AlignError.OTHER }))
    }
  }

  @autobind
  *checkFieldValidation(): SagaIterator {
    const validation = this.uploadSession.validation
    if (!!validation) {
      const [invalidFields, numInvalidRows] = yield call(this.uploadingService.generateValidationCounterSet, validation)
      yield put(upload.actions.invalidFields(Array.from(invalidFields)))
      yield put(upload.actions.setNumInvalidRows(numInvalidRows))
      yield put(upload.actions.setIsValid(!!!invalidFields.size))
    }
  }

  @autobind
  *adjustRow({ payload }: Action<IAdjustRowPayload>): SagaIterator {
    yield delay(250)
    const alignments = this.uploadSession.alignment
    let validation: Validation = this.uploadSession.validation ?? {}

    const input = this.uploadSession.currentInput
    const idFrequencyMap = this.uploadSession.idFrequencyMap
    const sites = this.uploadSession.sites

    const [updatedValidation, updatedInput, updatedIdFrequencyMap] = yield call(
      this.uploadingService.adjustAndValidateRow,
      payload.value,
      payload.id,
      payload.column,
      input,
      validation,
      alignments,
      idFrequencyMap,
      sites,
    )
    this.uploadSession.validation = updatedValidation
    this.uploadSession.currentInput = updatedInput
    this.uploadSession.idFrequencyMap = idFrequencyMap

    yield put(upload.actions.checkFieldValidation())
    yield call(this.updateValidateItems)

    this.uploadSession.currentInput[payload.id][payload.column] = payload.value
    const row = this.uploadSession.currentInput[payload.id]
    this.uploadSession.currentInput[payload.id] = row
  }

  @autobind
  *removeRows({ payload }: Action<IRemoveRowsPayload>): SagaIterator {
    const filteredKeys = Object.keys(this.uploadSession.currentInput).filter((rowId) => !payload.rowIds.includes(rowId))

    this.uploadSession.currentInput = filteredKeys.reduce((acc, rowId) => {
      acc[rowId] = this.uploadSession.currentInput[rowId]
      return acc
    }, {})

    const alignments = this.uploadSession.alignment
    const input = this.uploadSession.currentInput
    const sites = this.uploadSession.sites

    const [validations, sortedInput, inputOrder, idFrequencyMap] = yield call(
      this.uploadingService.validateRows,
      input,
      alignments,
      sites,
    )

    const updatedInputOrder = inputOrder.filter((rowId) => Object.keys(input).includes(rowId))

    this.uploadSession.validation = validations
    this.uploadSession.idFrequencyMap = idFrequencyMap
    this.uploadSession.currentInputOrder = updatedInputOrder

    yield put(upload.actions.checkFieldValidation())
    yield call(this.updateValidateItems)
  }

  /**
   * Request to upload recipients
   * Only allow upload if all recipients have a provided Site ID
   */
  @autobind
  *finialiseRecipientData(): SagaIterator {
    yield put(upload.actions.loading(true))

    try {
      const input = this.uploadSession.currentInput
      const alignments = this.uploadSession.alignment
      const developments = this.uploadSession.sites

      // Only require site selection if client has multiple sites
      if (developments.length > 1 && !this.uploadingService.checkSiteSelectionRequired(input, alignments)) {
        yield put(upload.actions.siteSelectionToggle(true))
      } else {
        yield put(upload.actions.navigateRecipientUpload('confirm'))

        const wipeEnabled = yield select(upload.selectors.wipeEnable)
        yield put(upload.actions.generateUploadPreview(wipeEnabled))
      }
    } catch (error: any) {
      yield put(moduleReporting.actions.error(error))
    } finally {
      yield put(upload.actions.loading(false))
    }
  }

  @autobind
  *setRecipientsSite({ payload }: Action<ISetRecipientsSitePayload>): SagaIterator {
    yield put(upload.actions.loading(true))

    try {
      this.uploadSession.defaultDevelopmentId = payload.siteId
      yield put(upload.actions.navigateRecipientUpload('confirm'))

      const wipeEnabled = yield select(upload.selectors.wipeEnable)
      yield put(upload.actions.generateUploadPreview(wipeEnabled))
    } catch (error: any) {
      yield put(moduleReporting.actions.error(error))
    } finally {
      yield put(upload.actions.loading(false))
    }
  }

  @autobind
  *generatePreview({ payload: isWipeEnabled }: Action<IGeneratePreviewPayload>): SagaIterator {
    yield put(upload.actions.loading(true))

    try {
      const alignments = this.uploadSession.alignment
      const validations = this.uploadSession.validation ?? {}
      const input = this.uploadSession.currentInput

      const invalidInputs = Object.keys(validations).map((idx) =>
        Object.values(validations[idx]).every((val) => !val) ? -1 : idx,
      )

      // Filter out inputs with invalid fields
      const filteredInput = Object.keys(input)
        .filter((rowIdx) => invalidInputs[rowIdx] === -1)
        .reduce((res, rowIdx) => {
          res[rowIdx] = input[rowIdx]
          return res
        }, {})

      const { multimailroomEnabled } = yield select(security.selectors.featureFlags)

      const previewRequestDto = this.uploadingService.createPreviewRecipients(
        filteredInput,
        alignments,
        this.uploadSession.defaultDevelopmentId ?? this.uploadSession.sites[0]!!.id,
        this.uploadSession.sites,
        multimailroomEnabled ?? false,
      )

      const { data } = yield call(this.apiService, `/v3/tenants/update-preview?wipe=${isWipeEnabled}`, {
        method: 'POST',
        data: instanceToPlain(previewRequestDto),
      })

      const response = plainToInstance(IUploadPreviewDto, data)

      const previews = response.currentCountPerBuilding.reduce((acc, site) => {
        const current = response.currentTotalCountPerBuilding.find(
          (previewSite) => previewSite.developmentId == site.developmentId,
        )
        const currentAdditional = response.currentAdditionalCountPerBuilding.find(
          (previewSite) => previewSite.developmentId == site.developmentId,
        )
        const additionPreview = response.additionsPerBuilding.find(
          (previewSite) => previewSite.developmentId == site.developmentId,
        )
        const additionAdditionalPreview = response.additionsAdditionalPerBuilding.find(
          (previewSite) => previewSite.developmentId == site.developmentId,
        )
        const deletionPreview = response.deletionsPerBuilding.find(
          (previewSite) => previewSite.developmentId == site.developmentId,
        )
        const deletionAdditionalPreview = response.deletionsAdditionalPerBuilding.find(
          (previewSite) => previewSite.developmentId == site.developmentId,
        )
        const finalPreview = response.finalCountPerBuilding.find(
          (previewSite) => previewSite.developmentId == site.developmentId,
        )
        const finalAdditionalPreview = response.finalAdditionalCountPerBuilding.find(
          (previewSite) => previewSite.developmentId == site.developmentId,
        )

        const siteName = this.uploadSession.sites.find(
          (storedSite) => storedSite.id == site.developmentId,
        )?.buildingName

        const preview: IUploadPreviewSite = {
          siteId: site.developmentId,
          siteName: siteName ?? '',
          current: current?.count ?? 0,
          currentAdditional: currentAdditional?.count ?? 0,
          additions: additionPreview?.count ?? 0,
          additionsAdditional: additionAdditionalPreview?.count ?? 0,
          deletions: deletionPreview?.count ?? 0,
          deletionsAdditional: deletionAdditionalPreview?.count ?? 0,
          final: finalPreview?.count ?? 0,
          finalAdditional: finalAdditionalPreview?.count ?? 0,
        }

        acc.push(preview)
        return acc
      }, [] as IUploadPreviewSite[])

      yield put(upload.actions.setUploadPreview({ sites: previews }))
    } catch (error: any) {
      yield put(moduleReporting.actions.error(error))
    } finally {
      yield put(upload.actions.loading(false))
    }
  }

  @autobind
  *uploadRecipients(): SagaIterator {
    yield put(upload.actions.loading(true))

    try {
      // Check if all rows are valid
      const sites = this.uploadSession.sites
      const validations = this.uploadSession.validation ?? {}
      const invalidInputs = Object.keys(validations).map((idx) =>
        Object.values(validations[idx]).every((val) => !val) ? -1 : idx,
      )
      yield put(upload.actions.uploadStatus(UploadingStatus.STARTED))

      const input = this.uploadSession.currentInput
      // Filter out inputs with invalid fields
      const filteredInput = Object.keys(input)
        .filter((rowIdx) => invalidInputs[rowIdx] === -1)
        .reduce((res, rowIdx) => {
          res[rowIdx] = input[rowIdx]
          return res
        }, {})

      const alignments = this.uploadSession.alignment

      const { multimailroomEnabled } = yield select(security.selectors.featureFlags)

      const tenants: ITenantDto[] = yield call(
        this.uploadingService.createRecipients,
        filteredInput,
        alignments,
        this.uploadSession.defaultDevelopmentId ?? this.uploadSession.sites[0]!!.id,
        sites,
        multimailroomEnabled ?? false,
      )

      yield put(upload.actions.uploadStatus(UploadingStatus.IN_PROGRESS))
      yield put(upload.actions.setTotal(tenants.length))
      const uploadResults: ITenantBatchUploadResultDto[] = []

      for (let i = 0; i < tenants.length; i += CHUNK_SIZE) {
        const chunk = tenants.slice(i, i + CHUNK_SIZE)
        const chunkData = new IBatchTenantsDto()
        chunkData.tenants = chunk

        const response: AxiosResponse<{ Results: ITenantBatchUploadResultDto[] }> = yield call(
          this.apiService,
          'v3/tenants/batch',
          {
            method: 'POST',
            data: instanceToPlain(chunkData),
          },
        )
        const chunkUploadResults = plainToInstance(ITenantBatchUploadResultDto, response.data.Results)
        uploadResults.push(...chunkUploadResults)
        yield put(upload.actions.uploadProgress(i + CHUNK_SIZE))
      }

      const { successes, duplicates, errors } = uploadResults.reduce(
        (acc, result) => {
          const { outcome } = result
          switch (outcome) {
            case UploadUpdate.SUCCESS:
              acc.successes += 1
              break
            case UploadUpdate.DUPLICATION:
              acc.duplicates += 1
              break
            case UploadUpdate.ERROR:
              acc.errors += 1
              break
          }
          return acc
        },
        {
          successes: 0,
          duplicates: 0,
          errors: 0,
        },
      )

      yield put(
        upload.actions.uploadFinishedUpdate({
          successes,
          duplicates,
          errors,
        }),
      )

      this.uploadSession = {
        defaultDevelopmentId: undefined,
        input: {},
        currentInput: {},
        currentInputOrder: [],
        columns: [],
        alignment: {},
        idFrequencyMap: {},
        editable: undefined,
        sites: [],
      }

      const isWipeEnable = yield select(upload.selectors.wipeEnable)
      if (isWipeEnable) {
        const externalRecipients: ITenantDiffDeleteTenantDto[] = []
        tenants.forEach((tenant) => {
          if (tenant.id2 != undefined) {
            const diffDto = new ITenantDiffDeleteTenantDto()
            diffDto.externalId = tenant.id2
            diffDto.developmentIds = [tenant.primarySite]
            externalRecipients.push(diffDto)
          }
        })
        const diffDto = new ITenantsDiffDeleteDto()
        diffDto.tenants = externalRecipients
        yield call(this.deletionSync, diffDto)
      } else {
        yield put(upload.actions.uploadStatus(UploadingStatus.COMPLETED))
        yield call(setOnboardingStage, {
          OnboardingStage: OnboardingStage.RECIPIENTS_UPLOADED,
        })
      }
    } catch (error: any) {
      yield put(moduleReporting.actions.error(error))
      yield put(upload.actions.error(error))
      yield put(upload.actions.uploadStatus(UploadingStatus.PROCESS_FAILED))
      yield put(
        notification.actions.showError({
          title: 'Something went wrong!',
          message: '',
        }),
      )
      yield put(upload.actions.alignmentError({ error: AlignError.OTHER }))
    } finally {
      yield put(upload.actions.loading(false))
    }
  }

  @autobind
  *deletionSync(dto: ITenantsDiffDeleteDto): SagaIterator {
    yield put(upload.actions.setIsSyncing(true))

    try {
      yield call(this.apiService, '/v3/tenants/diff-delete', {
        method: 'POST',
        data: instanceToPlain(dto),
      })
      yield put(
        notification.actions.showSuccess({
          message: yield translate('recipients-upload.deletion-sync.success'),
        }),
      )
    } catch (error) {
      yield put(reporting.actions.error(error))
      yield put(
        notification.actions.showSuccess({
          message: yield translate('recipients-upload.deletion-sync.error'),
        }),
      )
    }

    yield put(upload.actions.setIsSyncing(false))
    yield put(upload.actions.uploadStatus(UploadingStatus.COMPLETED))
    yield call(setOnboardingStage, {
      OnboardingStage: OnboardingStage.RECIPIENTS_UPLOADED,
    })
  }

  @autobind
  *setColumnAlignment({ payload }: Action<ISetColumnAlignmentPayload>): SagaIterator {
    this.uploadSession.alignment = {
      ...this.uploadSession.alignment,
      [payload.column]: payload.field,
    }

    yield call(this.updateAlignmentItems)
  }

  @autobind
  *updateEditable({ payload }: Action<IUpdateEditablePayload>): SagaIterator {
    this.uploadSession.editable = payload
    yield call(this.updateValidateItems)
  }

  @autobind
  *updateAlignmentItems() {
    const filter = this.uploadSession.alignment
      ? (Object.values(this.uploadSession.alignment).filter(
          (val) => !!val && val != RecipientField.UNKNOWN,
        ) as RecipientField[])
      : []

    const items =
      this.uploadSession.columns?.map((column) => {
        const chosen = this.uploadSession.alignment?.[column]
        const matched = chosen ? chosen != RecipientField.UNKNOWN : false

        return {
          matched,
          header: column,
          preview:
            Object.entries(this.uploadSession.input)
              .slice(0, 3)
              .map((row) => row[column]) ?? [],
          property: {
            column: column,
            chosen,
            filter: filter,
          },
        }
      }) ?? []

    yield put(upload.actions.updateAlignItems(items))
  }

  @autobind
  // eslint-disable-next-line require-yield
  *updateValidateItems(): SagaIterator {
    const input = this.uploadSession.currentInput
    const inputOrder = this.uploadSession.currentInputOrder
    const validation = this.uploadSession.validation
    const alignment = this.uploadSession.alignment
    const editable = this.uploadSession.editable
    const { pageIndex, pageSize } = yield select(upload.selectors.validatePage)

    const columnFields: RecipientField[] =
      (Object.values(alignment).filter((field) => !!field && field != RecipientField.UNKNOWN) as RecipientField[]) ?? []

    if (!input || !validation) {
      return yield put(upload.actions.updateValidateItems({ items: [], columns: columnFields }))
    }

    const startIndex = pageIndex == 0 ? 0 : pageIndex * pageSize
    let items: IValidateRowItem[] = []

    for (let i = startIndex; i <= Math.min(startIndex + pageSize, inputOrder.length - 1); i++) {
      const rowId = inputOrder[i]
      const row = input[rowId]
      const status = validation ? !Object.values(validation[rowId] ?? []).find((row) => !!row) : false

      const item = {
        itemId: rowId,
        status: status,
        ...columnFields.reduce((accumulator, column) => {
          const chosen = Object.keys(alignment).find((key) => alignment[key] === column)!!
          const isEditable = editable?.itemId == rowId && editable?.column == column

          return {
            ...accumulator,
            [column]: {
              id: rowId,
              value: row[chosen],
              error: validation && validation[rowId] ? validation[rowId][column] : undefined,
              editable: isEditable,
            },
          }
        }, {}),
      }
      items.push(item)
    }

    yield put(upload.actions.setTotal(inputOrder.length))
    yield put(upload.actions.updateValidateItems({ items: items, columns: columnFields }))
  }
}

export default RecipientsUploadSaga
