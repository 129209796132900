import { getCurrentUser } from 'api/auth'
import autobind from 'autobind-decorator'
import { AxiosInstance } from 'axios'
import { instanceToPlain, plainToClass } from 'class-transformer'
import http from 'modules/http'
import notifications from 'modules/module-alerts'
import notification from 'modules/module-alerts/alert'
import { banner } from 'modules/module-alerts/alert/effects'
import { PortalPath } from 'modules/module-core/routes/models'
import { translate } from 'modules/module-intl'
import navigation from 'modules/module-navigation'
import orchestration from 'modules/module-orchestration'
import reporting from 'modules/module-reporting'
import security, { GeoService } from 'modules/module-security'
import { SupervisorSuite } from 'modules/redux-supervisor'
import { BannerType } from 'modules/web-atoms'
import { SagaIterator } from 'redux-saga'
import { all, apply, call, delay, put, select, takeLatest, takeLeading } from 'redux-saga/effects'
import { PLAN_MAPPING, PLAN_TYPE_MAPPING } from '../api/mapping'
import billing from '../billing'
import { checkout, processGracePeriodCondition, processTrialCondition, submitContactForm } from '../billing/actions'
import BillingService from '../services/BillingService'
import { PaymentMethod } from '../services/models'
import {
  BillingPortalAccessDto,
  BillingPortalAccessResponseDto,
  CheckoutDto,
  CreatePaymentIntentDto,
  PlanStatusDto,
  PlanType,
  RecipientCountDto,
  SubmitContactFormDto,
} from './../api/dto'

// In milliseconds
const DEFAULT_POLLING_DELAY = 300 * 1000
const INTL_PREFIX = 'billing'

export class BillingSaga extends SupervisorSuite {
  private readonly apiService: AxiosInstance
  private readonly planManagementApiService: AxiosInstance
  private readonly billingService: BillingService
  private readonly geoService: GeoService
  private pollingDelay: number

  constructor(
    apiService: AxiosInstance,
    planManagementApiService: AxiosInstance,
    billingService: BillingService,
    geoService: GeoService,
  ) {
    super()
    this.apiService = apiService
    this.planManagementApiService = planManagementApiService
    this.billingService = billingService
    this.geoService = geoService
    this.pollingDelay = DEFAULT_POLLING_DELAY
  }

  @autobind
  *start(): SagaIterator {
    yield all([
      takeLatest(orchestration.actions.DETERMINE_USER_PERMISSIONS, this.determineUserPermissions),
      takeLatest(billing.actions.POLL_BILLING_DATA, this.pollBillingData),
      takeLeading(orchestration.actions.ACCOUNT_GEOLOCATION_READY, this.determineDefaultCurrency),
      takeLatest(billing.actions.FORCE_REFRESH, this.fetchBillingData),
      takeLeading(billing.actions.CHECKOUT, this.checkout),
      takeLatest(billing.actions.PROCESS_TRIAL_CONDITION, this.processTrialCondition),
      takeLatest(billing.actions.PROCESS_GRACE_PERIOD_CONDITION, this.processGracePeriodCondition),
      takeLeading(billing.actions.SUBMIT_CONTACT_FORM, this.submitContactForm),
      takeLeading(billing.actions.PAYMENT_DETAILS_REDIRECT, this.paymentDetailsRedirect),
      takeLeading(billing.actions.DOWNGRADE_PLAN, this.downgradePlan),
    ])
  }

  @autobind
  *determineUserPermissions(): SagaIterator {
    yield put(billing.actions.pollBillingData())
  }

  @autobind
  *determineDefaultCurrency(): SagaIterator {
    const countryCode = yield select(security.selectors.countryCode)
    if (countryCode) {
      const regionCurrency = yield apply(this.geoService, this.geoService.determineRegionCurrency, [countryCode])
      yield put(billing.actions.setCurrency(regionCurrency))
    }
  }

  @autobind
  *fetchBillingData(): SagaIterator {
    const {
      data: { Client: client },
    } = yield call(getCurrentUser)
    const planStatusResponse = yield call(this.planManagementApiService, 'plans', {
      params: {
        client_id: client.Id,
      },
    })
    const planStatus = plainToClass(PlanStatusDto, planStatusResponse.data)

    yield put(billing.actions.setManualOverride(!!planStatus.manualOverride))
    if (planStatus.currentPlan === PlanType.TRIAL) yield put(billing.actions.setIsTrial(true))
    if (!planStatus.manualOverride) {
      yield put(billing.actions.processTrialCondition(planStatus.daysLeftInTrial))
      yield put(billing.actions.processGracePeriodCondition(planStatus.daysLeftInGracePeriod))
    }
    if (planStatus.currency) yield put(billing.actions.setCurrency(planStatus.currency))
    yield put(billing.actions.setIsSmsEnabled(planStatus.smsEnabled))

    yield put(
      billing.actions.setPlanLimits({
        limitInboundParcels: planStatus.limitInboundParcelsScanning,
        limitOutboundParcels: planStatus.limitOutboundParcelsScanning,
        scannedInboundParcelsInBillingPeriod: planStatus.scannedInboundParcelsInBillingPeriod,
        scannedOutboundParcelsInBillingPeriod: planStatus.scannedOutboundParcelsInBillingPeriod,
      }),
    )
    const userType = yield select(security.selectors.type)
    yield put(billing.actions.setPermissions({ userType, manualOverride: !!planStatus.manualOverride }))

    const planId = planStatus.manualOverride ? PlanType.ENTERPRISE : planStatus.currentPlan
    const currentPlan = this.billingService.getPlan(PLAN_MAPPING[planId])
    yield put(billing.actions.setHasBeenPaidClient(planStatus.hasBeenPaidClient))
    yield put(billing.actions.setCurrentPlan(currentPlan))

    const { data: countRawData }: { data: RecipientCountDto[] } = yield call(
      this.apiService,
      '/v3/developments/tenant-count',
      {
        params: {
          client_id: client.Id,
        },
      },
    )
    const recipientCountData = countRawData ? plainToClass(RecipientCountDto, countRawData) : []
    const numBuildings = recipientCountData.length
    const numRecipients = Math.max(...recipientCountData.map((data) => data.recipientCount))
    yield put(
      billing.actions.setRecommendedPlan(
        this.billingService.getRecommendedPlan(currentPlan.id, numRecipients, numBuildings),
      ),
    )
  }

  @autobind
  *processTrialCondition({ payload }: ReturnType<typeof processTrialCondition>): SagaIterator {
    const previousValue = yield select(billing.selectors.daysLeftInTrial)
    if (payload && payload !== previousValue) {
      yield banner(
        {
          type: BannerType.WARNING,
          message: yield translate(`${INTL_PREFIX}.banner.days-left-trial`, { days: payload }),
          actionMessage: yield translate(`${INTL_PREFIX}.banner.days-left-trial.action-message`),
        },
        this.bannerAction,
      )
      yield put(billing.actions.setDaysLeftInTrial(payload))
    }
  }

  @autobind
  *processGracePeriodCondition({ payload }: ReturnType<typeof processGracePeriodCondition>): SagaIterator {
    const previousValue = yield select(billing.selectors.daysLeftInGracePeriod)
    if (payload && payload !== previousValue) {
      yield banner(
        {
          type: BannerType.DANGER,
          message: yield translate(`${INTL_PREFIX}.banner.days-left-grace-period`, { days: payload }),
          actionMessage: yield translate(`${INTL_PREFIX}.banner.days-left-grace-period.action-message`),
        },
        this.bannerAction,
      )
      yield put(billing.actions.setDaysLeftInGracePeriod(payload))
    }
  }

  @autobind
  *bannerAction(): SagaIterator {
    yield put(navigation.actions.navigate({ route: PortalPath.BILLING }))
  }

  @autobind
  *checkout({ payload }: ReturnType<typeof checkout>): SagaIterator {
    yield put(billing.actions.setLoadingCheckout(true))
    try {
      const client = yield select(security.selectors.client)

      if (payload.method === PaymentMethod.CARD || payload.method === PaymentMethod.BANK) {
        const data = new CreatePaymentIntentDto()
        data.clientId = client.Id

        const url = payload.method == PaymentMethod.CARD ? '/plans/checkout/card' : 'plans/checkout/direct-debit'
        const response = yield call(this.planManagementApiService, url, {
          method: 'post',
          data: instanceToPlain(data),
          params: {
            currency: payload.currency,
          },
        })
        const stripe = response.data ? plainToClass(CheckoutDto, response.data) : undefined

        if (!!stripe) {
          window.open(stripe.redirectUrl, '_self')
        } else {
          const message = yield translate('billing.overview.checkout.error')
          yield put(notification.actions.showError({ message }))
        }
      } else {
        const message = yield translate('billing.overview.checkout.error')
        yield put(notification.actions.showError({ message }))
      }
    } catch (error) {
      const message = yield translate('billing.overview.checkout.error')
      yield put(notification.actions.showError({ message }))
      yield put(billing.actions.error(error))
      yield put(reporting.actions.error(error))
    } finally {
      yield put(billing.actions.setLoadingCheckout(false))
    }
  }

  @autobind
  *paymentDetailsRedirect(): SagaIterator {
    yield put(billing.actions.setPaymentDetailsLoading(true))
    try {
      const client = yield select(security.selectors.client)
      const body = new BillingPortalAccessDto()
      body.urlRedirect = window.location.href
      body.clientId = client.Id

      const response = yield call(this.planManagementApiService, '/plans/billing-portal-access', {
        method: 'POST',
        data: instanceToPlain(body),
      })
      const data = plainToClass(BillingPortalAccessResponseDto, response.data)
      const stripeUrl = data.url
      window.open(stripeUrl, '_self')
    } catch (err) {
      yield put(billing.actions.error(err))
      yield put(reporting.actions.error(err))
      yield put(
        notification.actions.showError({ message: yield translate(`${INTL_PREFIX}.overview.payment-details.error`) }),
      )
    } finally {
      yield put(billing.actions.setPaymentDetailsLoading(false))
    }
  }

  @autobind
  *downgradePlan(): SagaIterator {
    try {
      const client = yield select(security.selectors.client)
      yield call(this.planManagementApiService, `/plans/downgrade/${client.Id}`, {
        method: 'POST',
      })
      yield put(
        notification.actions.showSuccess({
          message: yield translate(`${INTL_PREFIX}.overview.downgrade-modal.success`),
        }),
      )
    } catch (err) {
      yield put(billing.actions.error(err))
      yield put(reporting.actions.error(err))

      if (http.guards.isError(err) && err.response?.status === 404) {
        yield put(
          notification.actions.showError({
            message: yield translate(`${INTL_PREFIX}.overview.downgrade-modal.already-downgraded`),
          }),
        )
      } else {
        yield put(
          notification.actions.showError({
            message: yield translate(`${INTL_PREFIX}.overview.downgrade-modal.error`),
          }),
        )
      }
    }
  }

  @autobind
  *pollBillingData(): SagaIterator {
    while (true) {
      try {
        yield call(this.fetchBillingData)
        this.pollingDelay = DEFAULT_POLLING_DELAY
      } catch (err) {
        yield put(billing.actions.error(err))
        yield put(reporting.actions.error(err))
        this.pollingDelay *= 2
      } finally {
        yield delay(this.pollingDelay)
      }
    }
  }

  @autobind
  *submitContactForm({ payload }: ReturnType<typeof submitContactForm>) {
    try {
      const client = yield select(security.selectors.client)
      const {
        selectedPlan: { id: planId },
      } = yield select(billing.selectors.planData)
      const data = new SubmitContactFormDto()
      data.plan = PLAN_TYPE_MAPPING[planId]
      data.clientId = client.Id
      data.sites = payload.numSites
      data.email = payload.email
      data.mobile = payload.mobile

      yield call(this.apiService, '/v3/admin/plan-upgrade-notification', {
        method: 'POST',
        data: instanceToPlain(data),
      })
      yield put(
        notifications.actions.success({ message: yield translate(`${INTL_PREFIX}.contact-form.submit-success`) }),
      )
    } catch (error) {
      yield put(billing.actions.error(error))
      yield put(reporting.actions.error(error))
      yield put(notifications.actions.error({ message: yield translate(`${INTL_PREFIX}.contact-form.submit-error`) }))
    }
  }
}

export default BillingSaga
