import * as React from 'react'

import { IDevelopmentDto } from 'api'
import API from 'api/clients'
import { plainToClass } from 'class-transformer'
import { QueryKey } from 'modules/module-api'
import useApi from 'modules/module-api/hooks/useApi/use-api'
import { useClientBuildings } from 'modules/module-utils'

function useAssignedBuildings(userId?: string) {
  const { buildings: allBuildings, isFetching: isFetchingAllBuildings, error: allBuildingsError } = useClientBuildings()

  const {
    data,
    isFetching: isFetchingAssignedBuildings,
    refetch: refetchAssignedBuildings,
    error: assignedError,
  } = useApi<IDevelopmentDto[]>(
    [QueryKey.ASSIGNED_BUILDINGS, userId],
    async () => {
      const response = await API({
        method: 'get',
        url: `/v3/users/authorized-developments`,
        params: {
          user_id: userId,
        },
      })
      return response.data
    },
    {
      enabled: false,
    },
  )

  React.useEffect(() => {
    !!userId && refetchAssignedBuildings()
  }, [userId, refetchAssignedBuildings])

  const assignedBuildings: IDevelopmentDto[] = React.useMemo(() => {
    return data ? plainToClass(IDevelopmentDto, data) : []
  }, [data])

  return {
    allBuildings,
    assignedBuildings,
    isFetchingAllBuildings,
    isFetchingAssignedBuildings,
    error: allBuildingsError && assignedError,
  }
}

export default useAssignedBuildings
