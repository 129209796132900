import { combineReducers } from 'redux'
import billing from './billing'

const reducers = {
  billing: billing.reducer,
}

export const reducer = combineReducers(reducers)

export default reducer
