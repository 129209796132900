import React from 'react'
import { useHistory } from 'react-router'

import { EuiLink, EuiLinkButtonProps } from '@elastic/eui'

interface ILinkProps extends Omit<EuiLinkButtonProps, 'href'> {
  to?: string
  onClick?: () => void
}

const isModifiedEvent = (event: any) => !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey)
const isLeftClickEvent = (event: any) => event.button === 0

export const Link: React.FC<ILinkProps> = (props) => {
  const { to, onClick, children, ...rest } = props
  // This is the key!
  const history = useHistory()

  function onLinkClick(event: any) {
    // If target prop is set (e.g. to "_blank"), let browser handle link.
    if (
      event.defaultPrevented ||
      event.target.getAttribute('target') ||
      isModifiedEvent(event) ||
      !isLeftClickEvent(event)
    ) {
      return
    }

    // Prevent regular link behavior, which causes a browser refresh.
    event.preventDefault()
    // Push the route to the history.
    if (!!to) history.push(to)
  }

  return (
    <EuiLink onClick={onClick ?? onLinkClick} {...rest}>
      {children}
    </EuiLink>
  )
}

export default Link
