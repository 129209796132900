import * as Sentry from '@sentry/react'

import authentication from 'modules/module-onboarding/authentication'
import { AnyAction } from 'redux'
import reporting from '..'

export type ActionTransformer<Action = AnyAction> = (action: Action) => Action | null

export const removePassword: ActionTransformer = (action) => {
  if (action.type == authentication.actions.LOGIN) {
    return {
      ...action,
      payload: { ...action.payload, password: null },
    }
  }
  if (action.type == authentication.actions.SIGN_UP) {
    return {
      ...action,
      payload: { ...action.payload, password: null },
    }
  }

  return action
}

export const removeErrorData: ActionTransformer = (action) => {
  if (
    !!action.payload &&
    !!action.payload.request &&
    !!action.payload.response &&
    [reporting.actions.ERROR, reporting.actions.IDENTIFY, reporting.actions.WARN].includes(action.type)
  ) {
    return {
      ...action,
      payload: {
        ...action.payload,
        config: {
          ...action.payload.config,
          headers: { ...action.payload.headers, Authorization: null },
          data: null,
        },
        request: {
          ...action.payload.request,
          body: null,
          __sentry_xhr__: { ...action.payload.request.__sentry_xhr__, body: null },
        },
        headers: {
          ...action.headers?.Authorization,
          Authorization: null,
        },
        response: { ...action.payload.response, config: null, request: null },
      },
    }
  }

  return action
}

export const removeTokens: ActionTransformer = (action) => {
  if (action.type == 'security/session/storeTokens') {
    return null
  }
  return action
}

const ActionTransformers: ActionTransformer[] = [removePassword, removeTokens, removeErrorData]

const actionTransformer = (action: AnyAction | null): AnyAction | null => {
  return ActionTransformers.reduce((action, transformer) => {
    return !!action ? transformer(action) : action
  }, action)
}

export const sentryEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: actionTransformer,
})

export default sentryEnhancer
