import orchestration from './orchestration'
import reducer from './root-reducer'

export * from './model'
import orchestrationSagas from './sagas'

export const orchestrationModule = {
  actions: {
    ...orchestration.actions,
  },
  selectors: {
    ...orchestration.selectors,
  },
  reducer,
  sagas: orchestrationSagas,
}

export default orchestrationModule
