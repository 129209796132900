import * as React from 'react'
import { Trans } from 'react-i18next'

import { Paragraph, Span } from 'modules/web-atoms'
import { FilePicker } from 'modules/web-molecules'

export interface RecipientFilePickerProps extends React.ComponentProps<typeof FilePicker> {}

const FILE_ACCEPT_FORMATS =
  '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'

const INTL_PREFIX = 'recipients-upload.upload-file.file-picker'

export const RecipientFilePicker: React.FC<RecipientFilePickerProps> = (props) => {
  return (
    <FilePicker
      id="csv-file-uploader"
      className="euiFilePicker--xlarge"
      accept={FILE_ACCEPT_FORMATS}
      multiple={false}
      initialPromptText={
        <Paragraph size="medium" paddingX="large">
          <Trans
            i18nKey={`${INTL_PREFIX}.message`}
            components={{
              span: <Span color="primaryMid" />,
              br: <br />,
            }}
          />
        </Paragraph>
      }
      display="large"
      aria-label="Recipient file picker"
      {...props}
    />
  )
}

export default RecipientFilePicker
