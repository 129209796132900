import { createReducer } from '@reduxjs/toolkit'
import { initialState } from './models'
import permissions from '../permissions/Permissions'
import { setPermissions } from './actions'

const reducer = createReducer(initialState, (builder) =>
  builder.addCase(setPermissions, (state, { payload }) => {
    state.permissions.canViewStatistics = permissions.canViewStatistics.includes(payload.userType)
    state.permissions.canViewOutboundStats =
      permissions.canViewStatistics.includes(payload.userType) && !!payload.isOutboundEnabled
  }),
)

export default reducer
