import { UseQueryOptions } from '@tanstack/react-query'
import { IPaginationParams, QueryKey, SortDirection, usePaginatedApi } from 'modules/module-api'
import { useDebounce } from '../../../module-utils/hooks/use-debounce/use-debounce'
import { IPaginatedDropoffLocationsDto } from 'modules/module-settings/api/dto'

export enum DropoffLocationsSortField {
  NAME = 'NAME',
  ENABLED = 'ENABLED',
  RECIPIENTS_COUNT = 'RECIPIENTS_COUNT',
}

export interface IUseDropoffLocationsParams extends IPaginationParams<DropoffLocationsSortField> {
  client_id?: number
}

export const useDropoffLocations = (
  params: IUseDropoffLocationsParams = {
    page: 1,
    pageSize: 10,
    sortBy: DropoffLocationsSortField.NAME,
    sort: SortDirection.ASC,
    filter: '',
  },
  queryOptions?: UseQueryOptions<any>,
) => {
  const debouncedFilter = useDebounce('')(params.filter)

  const { data, ...rest } = usePaginatedApi(
    QueryKey.DROPOFF_LOCATIONS_PAGINATED,
    IPaginatedDropoffLocationsDto,
    '/v3/dropoff-locations',
    {
      ...params,
      filter: debouncedFilter,
    },
    queryOptions,
  )

  return {
    dropoffLocations: data,
    ...rest,
  }
}

export default useDropoffLocations
