import { combineReducers } from 'redux'
import { ReduxCompatibleReducer } from 'redux-actions'

import * as matchers from './actions/matchers'
import { IErrorState, INITIAL_STATE } from './model'
import * as selectors from './selectors'

export const context: ReduxCompatibleReducer<JSONPrimitiveObject, unknown> = (state, action) => {
  {
    if (matchers.context(action)) {
      const context = action.meta?.errors?.context
      return Object.assign({}, state, typeof context === 'object' ? context : { context })
    }
    return state ?? selectors.context(INITIAL_STATE)
  }
}

export default combineReducers<IErrorState>({ context })
