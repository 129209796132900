import { isElement } from 'modules/module-utils'
import { not } from 'modules/utils'
import { FlexGroup, FlexItem, Page, useScreen } from 'modules/web-atoms'
import { InsufficientAccess } from 'modules/web-molecules'
import * as React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'

export interface ITablePageTemplateProps extends React.ComponentProps<typeof FlexGroup> {
  title: string
  hasViewPermission: boolean
  isFormVisible: boolean
}

export const TablePageTemplate: React.FC<ITablePageTemplateProps> = (props) => {
  const { hasViewPermission, isFormVisible, title, ...rest } = props

  const { isMobile } = useScreen()
  const { path } = useRouteMatch()
  const isTableVisible = ((isMobile && !isFormVisible) || !isMobile) && hasViewPermission

  const { content, table, panel, itemView } = React.useMemo(() => {
    const children = React.Children.toArray(props.children)

    const content = children.filter(not(isElement(Table), isElement(Form), isElement(ItemView)))
    const table = children.filter(isElement(Table)).map((toolbar) => toolbar.props.children)
    const panel = children.filter(isElement(Form)).map((panel) => panel.props.children)
    const itemView = children.filter(isElement(ItemView)).map((itemView) => itemView.props.children)
    return { content, table, panel, itemView }
  }, [props.children])

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [isFormVisible])

  return (
    <Page title={title}>
      {hasViewPermission ? (
        <FlexGroup height={[null, '95vh']} flexWrap="nowrap" {...rest}>
          <FlexItem display={isMobile && isFormVisible ? 'none' : 'block'} position="relative" height="100%" grow={5}>
            <Switch>
              <Route exact={true} path={`${path}`}>
                {isTableVisible && table}
              </Route>

              <Route exact={true} path={`${path}/:id`}>
                {itemView}
              </Route>
            </Switch>
          </FlexItem>
          {isFormVisible && panel.length ? (
            <FlexItem height="100%" grow={2} maxWidth={[...Array(2).fill(null), '500px']}>
              {panel}
            </FlexItem>
          ) : undefined}
          {content}
        </FlexGroup>
      ) : (
        <InsufficientAccess />
      )}
    </Page>
  )
}

export interface ITablePageTemplate extends React.MemoExoticComponent<typeof TablePageTemplate> {
  Table: typeof Table
  Form: typeof Form
  ItemView: typeof ItemView
}

const Table: React.FC = ({ children }) => <>{children}</>
const Form: React.FC = ({ children }) => <>{children}</>
const ItemView: React.FC = ({ children }) => <>{children}</>

const template: ITablePageTemplate = Object.assign(React.memo(TablePageTemplate), {
  type: TablePageTemplate,
  Table,
  Form,
  ItemView,
})

export default template
