export enum Language {
  English = 'en',
  French = 'fr',
  Italian = 'it',
  Spanish = 'es',
  German = 'de',
}

export const SUPPORTED_LANGUAGES = [
  Language.English,
  Language.French,
  Language.Spanish,
  Language.Italian,
  Language.German,
]

export interface ILanguageSettingsState {
  language?: Language
}

export const initialState: ILanguageSettingsState = {
  language: undefined,
}
