/* eslint-disable no-undef */
import { createSelector, Selector } from 'reselect'
import { BRANCH_KEY } from '../branch'

import { IRecipientsOwnState } from '../model'

import { IUploadState } from './models'

export const branch: Selector<IRecipientsOwnState, IUploadState> = (state) => state[BRANCH_KEY].upload

export const currentScreen = createSelector(branch, (branch) => branch.currentScreen)

export const isLoading = createSelector(branch, (branch) => branch.loading)
