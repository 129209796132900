import { EuiComboBoxOptionOption } from '@elastic/eui/src/components'
import { IUserDtoClass, UserType } from 'api'
import alerts from 'modules/module-alerts'
import { strings, useAppDispatch } from 'modules/module-utils'
import { useTranslate } from 'modules/web-atoms'
import * as React from 'react'
import useAssignedUsers from '../use-assigned-users/use-assigned-users'

export const OPTION_ALL = 'all-users'

const allOption: EuiComboBoxOptionOption<string> = {
  label: 'Select all users',
  value: OPTION_ALL,
  key: OPTION_ALL,
}

const mapUserToComboOption = (user: IUserDtoClass): EuiComboBoxOptionOption<string> => {
  return {
    label: `${strings.capitalize(user.firstName) ?? ''} ${strings.capitalize(user.lastName) ?? ''}`,
    value: user.id,
    key: user.id,
  }
}

const useSelectedUsers = (buildingId?: string) => {
  const [selectedUsers, setSelectedUsers] = React.useState<EuiComboBoxOptionOption<string>[]>([])
  const t = useTranslate('buildings-directory.form')
  const dispatch = useAppDispatch()

  const { allUsers, assignedUsers, error, isFetchingAllUsers, isFetchingAssignedUsers } = useAssignedUsers(buildingId)

  const options: Array<EuiComboBoxOptionOption<string>> = React.useMemo(() => {
    const userOptions = allUsers?.map(mapUserToComboOption) ?? []
    if (userOptions.length) {
      return [allOption, ...userOptions]
    } else {
      return userOptions
    }
  }, [allUsers])

  React.useEffect(() => {
    if (!buildingId) {
      const assigned = allUsers.filter((user) => user.type === UserType.OWNER).map(mapUserToComboOption)
      setSelectedUsers(assigned)
    } else if (!!assignedUsers?.length) {
      const assignedIds = assignedUsers.map((user) => user.id)
      const hasAll = allUsers.filter((user) => assignedIds.includes(user.id)).length === 0

      if (hasAll) {
        setSelectedUsers([allOption])
      } else {
        const assigned = [
          ...allUsers.filter((user) => user.type === UserType.OWNER),
          ...assignedUsers.filter((user) => user.type !== UserType.OWNER),
        ].map((user) => ({
          label: `${strings.capitalize(user.firstName) ?? ''} ${strings.capitalize(user.lastName) ?? ''}`,
          value: user.id,
          key: user.id,
        }))
        setSelectedUsers(assigned)
      }
    }
  }, [assignedUsers, allUsers, buildingId])

  const onUserSelection = React.useCallback(
    (options: EuiComboBoxOptionOption<string>[]) => {
      if (options.length === 0) {
        setSelectedUsers(allUsers.filter((user) => user.type === UserType.OWNER).map(mapUserToComboOption))
      } else if (options.length === selectedUsers.length - 1) {
        // Cannot remove owners from access
        const deselectedValue = selectedUsers.find((user) => !options.includes(user))
        const deselectedUser = allUsers.find((user) => user.id === deselectedValue?.value!)
        if (deselectedUser?.type === UserType.OWNER) {
          setSelectedUsers(selectedUsers)
          dispatch(alerts.actions.error({ message: t('validation.cannot-remove-owner') }))
        } else {
          setSelectedUsers(options.filter((option) => option.value !== OPTION_ALL))
        }
      } else {
        const filteredOptions = options.filter((option) => option.value !== OPTION_ALL)

        const allUsersSelected = allUsers.length == filteredOptions.length
        const hasAll = options.find((option) => option.value == OPTION_ALL)
        const allSelected = selectedUsers?.find((user) => user.value === OPTION_ALL)

        if (hasAll && allUsersSelected) {
          setSelectedUsers([])
        } else if (!!hasAll && !allSelected) {
          setSelectedUsers(allUsers.map(mapUserToComboOption))
        } else {
          setSelectedUsers(filteredOptions)
        }
      }
    },
    [allUsers, selectedUsers, dispatch, t],
  )

  return {
    allUsers,
    assignedUsers,
    options,
    selectedUsers,
    onUserSelection,
    usersError: error,
    isFetchingAllUsers,
    isFetchingAssignedUsers,
    setSelectedUsers,
  }
}

export default useSelectedUsers
