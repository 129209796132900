import * as React from 'react'

import { EuiImage } from '@elastic/eui'
import styled from '@emotion/styled'
import shouldForwardProp from '@styled-system/should-forward-prop'

import * as system from 'styled-system'

import { StyledViewProps } from '../Grid/StyledView'

const StyledImage = styled(EuiImage, { shouldForwardProp })<StyledViewProps>(
  system.color,
  system.flexbox,
  system.space,
  system.layout,
  system.position,
  system.justifyContent,
  system.alignItems,
  system.borders,
  system.shadow,
  system.background,
)

export type StyledImageProps = React.ComponentProps<typeof StyledImage>

export interface IImageProps extends Omit<StyledImageProps, 'alt' | 'width'> {
  label?: string
  width?: any
}

export const Image: React.FC<IImageProps> = (props) => {
  const { height, width, label, children, ...rest } = props

  return (
    <StyledImage alt={label!!} {...rest}>
      {children}
    </StyledImage>
  )
}

Image.displayName = 'Image'

Image.defaultProps = {
  label: '',
}

export default React.memo(Image)
