import { applyMiddleware, compose, createStore, Middleware } from 'redux'
import { persistStore } from 'redux-persist'

import { sentryEnhancer } from 'modules/module-reporting'

import rootSaga from '../sagas/root-saga'

import middleware, { sagaMiddleware } from './middleware'
import rootReducer from './root-reducer'
import security from 'modules/module-security'

const composeEnhancer = (window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] as typeof compose) || compose

/* istanbul ignore next */
export const configStore = (initialState: any = {}, additionalMiddleware: Middleware[] = []) => {
  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancer(applyMiddleware(...additionalMiddleware, ...middleware), sentryEnhancer),
  )
  return store
}

const store = configStore()
export const persistor = persistStore(store)
security.persistor.attach(persistor)

sagaMiddleware.run(rootSaga)

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>

export default store
