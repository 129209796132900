import React from 'react'
import Grid, { IGridProps } from '../Grid/Grid'

export interface IDividerProps extends IGridProps {}

export const Divider: React.FC<IDividerProps> = (props) => {
  return (
    <Grid
      borderTopStyle="solid"
      borderTopColor="gridLine"
      borderTopWidth="small"
      minWidth="100%"
      width="100%"
      my="medium"
      mx="none"
      {...props}
    />
  )
}

export default React.memo(Divider)
