import {
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiButton,
  EuiButtonEmpty,
  EuiIcon,
  EuiSuperSelect,
  EuiSuperSelectOption,
  EuiToolTip,
} from '@elastic/eui'
/* eslint-disable react/display-name */
import theme from 'modules/dna'
import { FlexGroup, FlexItem, Grid, H1, H4, Icon, Page, Paragraph, useTranslate } from 'modules/web-atoms'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import upload from '../../upload'
import { RecipientField, recipientFields } from '../../uploading/types'

export enum AlignColumn {
  MATCHED = 'matched',
  HEADER = 'header',
  PREVIEW = 'preview',
  PROPERTY = 'property',
}
export interface IAlginItem {
  matched: boolean
  header: string
  preview: string[]
  property: {
    column: string
    chosen?: RecipientField
    filter: RecipientField[]
  }
}

export interface IAlignColumnsPageProps extends React.ComponentProps<typeof FlexGroup> {}

export const AlignColumnsPage: React.FC<IAlignColumnsPageProps> = (props) => {
  const { ...rest } = props

  const t = useTranslate('recipients-upload.align-columns')
  const dispatch = useDispatch()

  const alignItems = useSelector(upload.selectors.alignItems)

  const handleBack = () => {
    dispatch(upload.actions.navigateRecipientUpload('previous'))
  }

  const handleNext = () => {
    dispatch(upload.actions.validateFile())
  }

  const onPropertySelected = React.useCallback(
    (column: string) => {
      return (value: RecipientField) => {
        dispatch(upload.actions.setColumnAlignment({ column, field: value }))
      }
    },
    [dispatch],
  )

  const selectOptions = React.useCallback(
    (chosen: RecipientField, filter: RecipientField[]): Array<EuiSuperSelectOption<RecipientField>> => {
      return recipientFields.map((field) => {
        const disabled = !!Object.values(filter).includes(field) && field != chosen
        return { value: field, inputDisplay: t(`options.${field}`), disabled: disabled }
      })
    },
    [t],
  )

  const columns = React.useMemo((): Array<EuiBasicTableColumn<IAlginItem>> => {
    return [
      {
        field: AlignColumn.MATCHED,
        name: (
          <Paragraph size="medium" fontWeight="bold" paddingBottom="macro">
            {t(AlignColumn.MATCHED)}
            <EuiIcon type="iInCircle" />
          </Paragraph>
        ),
        dataType: 'boolean',
        render: (matched: boolean) => {
          const MatchIcon = matched ? Icon.Tick : Icon.Cross
          return <MatchIcon minWidth={20} width={20} marginLeft="small" />
        },
      },
      {
        field: AlignColumn.HEADER,
        name: (
          <EuiToolTip content={t(`${AlignColumn.HEADER}-tooltip`)}>
            <Paragraph size="medium" fontWeight="bold" paddingBottom="macro">
              {t(AlignColumn.HEADER)}
              <EuiIcon type="iInCircle" />
            </Paragraph>
          </EuiToolTip>
        ),
        render: (header) => {
          return (
            <Paragraph fontWeight="bold" color="primaryMid">
              {header}
            </Paragraph>
          )
        },
      },
      {
        field: AlignColumn.PREVIEW,
        name: (
          <EuiToolTip content={t(`${AlignColumn.PREVIEW}-tooltip`)}>
            <Paragraph size="medium" fontWeight="bold" paddingBottom="macro">
              {t(AlignColumn.PREVIEW)}
              <EuiIcon type="iInCircle" />
            </Paragraph>
          </EuiToolTip>
        ),
        render: (preview) => {
          return (
            <FlexGroup flexDirection="column" style={{ alignItems: 'flex-start' }}>
              {preview.map((preview, index) => (
                <Paragraph paddingTop="macro" key={index}>
                  {preview}
                </Paragraph>
              ))}
            </FlexGroup>
          )
        },
      },
      {
        field: AlignColumn.PROPERTY,
        name: (
          <Paragraph size="medium" fontWeight="bold" paddingBottom="macro">
            {t(AlignColumn.PROPERTY)}
            <EuiIcon type="iInCircle" />
          </Paragraph>
        ),
        render: (property) => {
          return (
            <EuiSuperSelect
              options={selectOptions(property.chosen, property.filter)}
              valueOfSelected={property.chosen}
              onChange={onPropertySelected(property.column)}
              style={{ minWidth: 200 }}
            />
          )
        },
      },
    ]
  }, [onPropertySelected, selectOptions, t])

  const getCellProps = (item, column) => {
    return {
      style: {
        padding: theme.spacing.small,
      },
    }
  }

  return (
    <Page title={t('title')}>
      <FlexItem grow={true} alignItems="center" flex={1} {...rest}>
        <Grid justifyContent="space-between" flex={1}>
          <Grid alignItems="center" paddingX="large" marginTop="large" marginBottom="medium" flex={1}>
            <H1 textAlign="center">{t('heading')}</H1>
            <H4 marginTop="smallest" color="primaryMid" textAlign="center">
              {t('subheading')}
            </H4>
            <Grid marginTop="xlarge" marginX="smallest" maxWidth={1150}>
              <Grid className="eui-yScroll" maxHeight={600}>
                <EuiBasicTable<IAlginItem>
                  items={alignItems}
                  className="module-dashboard-map-file-step-table"
                  rowHeader="firstName"
                  columns={columns}
                  style={{ padding: theme.spacing.smallest }}
                  cellProps={getCellProps}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid justifyContent="center" alignItems="center">
            <Grid.Col>
              <EuiButtonEmpty onClick={handleBack}>Back</EuiButtonEmpty>
            </Grid.Col>
            <Grid.Col marginLeft="smallest">
              <EuiButton fill onClick={handleNext} style={{ marginLeft: theme.spacing.large }}>
                Next
              </EuiButton>
            </Grid.Col>
          </Grid>
        </Grid>
      </FlexItem>
    </Page>
  )
}

export default React.memo(AlignColumnsPage)
