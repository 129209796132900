export type shadowName = 'small' | 'medium' | 'large'

export type Shadows = Record<shadowName, any>

export const shadows: Shadows = {
  small: '0 0 4px rgba(0, 0, 0, .125)',
  medium: '0px 4px 10px rgba(0, 0, 0, 0.25)',
  large: '0 0 24px rgba(0, 0, 0, .125)',
}

export default shadows
