import { REACT_APP_VERSION, SENTRY_DNS, SENTRY_ENVIRONMENT } from 'modules/utils/constants'

import { ReporterType } from '../model'
import factory from '../reporters/reporting-service-factory'

const reporter = factory.create({
  debug: SENTRY_ENVIRONMENT == 'development' ? true : false,
  environment: SENTRY_ENVIRONMENT ?? '',
  source: SENTRY_DNS ?? '',
  type: ReporterType.Sentry,
  version: REACT_APP_VERSION ?? '1.0',
})

export default reporter
