import * as React from 'react'

import { EuiModal, EuiOutsideClickDetector } from '@elastic/eui'
import styled from '@emotion/styled'
import shouldForwardProp from '@styled-system/should-forward-prop'

import * as system from 'styled-system'

import { StyledViewProps } from '../Grid/StyledView'

const StyledModal = styled(EuiModal, { shouldForwardProp })<StyledViewProps>(
  system.color,
  system.flexbox,
  system.space,
  system.layout,
  system.position,
  system.justifyContent,
  system.alignItems,
  system.borders,
  system.position,
)

export interface IModalProps extends React.ComponentProps<typeof StyledModal> {
  hasClose?: boolean
  closeOnOutsideClick?: boolean
}

export const Modal: React.FC<IModalProps> = (props) => {
  const { hasClose, closeOnOutsideClick, children, onClose, ...rest } = props

  const onOutsideClick = React.useCallback(() => {
    if (closeOnOutsideClick) onClose()
  }, [onClose, closeOnOutsideClick])

  return (
    <EuiOutsideClickDetector onOutsideClick={onOutsideClick}>
      <StyledModal className={hasClose ? undefined : 'euiModal--noCloseButton'} onClose={onClose} {...rest}>
        {children}
      </StyledModal>
    </EuiOutsideClickDetector>
  )
}

Modal.displayName = 'Modal'

export default React.memo(Modal)
