import { IUserDtoClass } from 'api'
import { IPaginationParams, QueryKey, SortDirection, usePaginatedApi } from 'modules/module-api'
import { useActiveBuildings, useDebounce } from 'modules/module-utils'

export enum SortField {
  NAME = 'NAME',
  EMAIL = 'EMAIL',
  TYPE = 'TYPE',
}

function useUsers(
  params: IPaginationParams<SortField> = {
    page: 0,
    pageSize: 20,
    sortBy: SortField.NAME,
    sort: SortDirection.ASC,
    filter: '',
  },
) {
  const { activeBuildings } = useActiveBuildings()
  const debouncedFilter = useDebounce('', 250, false)(params.filter)

  const { data, ...rest } = usePaginatedApi(
    QueryKey.USERS,
    IUserDtoClass,
    '/v3/users/search',
    {
      development_ids: activeBuildings.map((building) => building.id).join(),
      ...params,
      filter: debouncedFilter,
    },
    {
      enabled: !!activeBuildings.length,
    },
  )

  return { users: data, ...rest }
}

export default useUsers
