/* eslint-disable no-undef */
import { createAction } from 'redux-actions'

import analytics, { AnalyticalEvent } from 'modules/module-analytics'
import { identityFor } from 'modules/utils'
import prefix from 'modules/utils/prefix'

import { IOnboardingNavigatePayload, ISetUploadingMethodPayload } from './'

export const namespaced = prefix('dashboard')

export const NAVIGATE_ONBOARDING = namespaced('navigateOnboarding')
export const navigateOnboarding = createAction<IOnboardingNavigatePayload>(NAVIGATE_ONBOARDING)

export const GET_PARCELS_WAITING = namespaced('getParcelsWaiting')
export const getParcelsWaiting = createAction(GET_PARCELS_WAITING)

export const GET_PARCELS_SCANNED = namespaced('getParcelsScanned')
export const getParcelsScanned = createAction(GET_PARCELS_SCANNED)

export const SET_PARCELS_WAITING = namespaced('setParcelsWaiting')
export const setParcelsWaiting = createAction(SET_PARCELS_WAITING)

export const SET_PARCELS_SCANNED = namespaced('setParcelsScanned')
export const setParcelsScanned = createAction(SET_PARCELS_SCANNED)

export const SET_DISPLAY_ONBOARDING_MODAL = namespaced('setDisplayOnboardingModal')
export const setDisplayOnboardingModal = createAction(SET_DISPLAY_ONBOARDING_MODAL)

export const SET_CURRENT_ONBOARDING_MODAL_COMPONENT = namespaced('setCurrentOnboardingModalComponent')
export const setCurrentOnboardingModalComponent = createAction(SET_CURRENT_ONBOARDING_MODAL_COMPONENT)

export const SET_UPLOADING_METHOD = namespaced('setUploadingMethod')
export const setUploadingMethod = createAction(
  SET_UPLOADING_METHOD,
  identityFor<ISetUploadingMethodPayload>(),
  (payload) =>
    analytics.meta.whitelist({
      data: { payload },
      name: AnalyticalEvent.RecipientUploadMethodChosen,
    }),
)

export const LOADING = namespaced('loading')
export const loading = createAction<boolean>(LOADING)

export const ERROR = namespaced('error')
export const error = createAction(ERROR, identityFor<Error>(), (payload) =>
  analytics.meta.whitelist({
    data: { message: payload.message },
    name: AnalyticalEvent.Error,
  }),
)

type Action = typeof navigateOnboarding | typeof setUploadingMethod | typeof loading | typeof error

export default Action
