import { EuiButtonIcon, EuiSelectable, EuiSelectableOption, EuiSelectableProps } from '@elastic/eui'
import { Grid } from 'modules/web-atoms'
import React from 'react'

export type SelectableListOption = {
  listItemId: string
} & EuiSelectableOption

export type ISelectableListProps = {
  options: SelectableListOption[]
  onChange: (selectedId: string) => void
  listHeight?: number
  listAppend?: React.ReactNode
  hideList?: boolean
} & Omit<EuiSelectableProps, 'options' | 'onChange' | 'searchable' | 'singleSelection'>

export const SelectableList: React.FC<ISelectableListProps> = ({
  options: optionsProp,
  onChange,
  searchProps,
  hideList,
  listAppend,
  isLoading,
  listHeight = 600,
  ...rest
}) => {
  const [options, setOptions] = React.useState<EuiSelectableOption[]>([])
  const [selected, setSelected] = React.useState<string | undefined>()
  const [searchValue, setSearchValue] = React.useState('')

  React.useEffect(() => {
    const newOptions: EuiSelectableOption[] = optionsProp.map(({ listItemId, ...rest }) => ({
      key: listItemId,
      ...rest,
    }))
    setOptions(newOptions)
  }, [optionsProp])

  const onOptionsChange = (options: EuiSelectableOption[]) => {
    const selectedOption = options.find((option) => option.checked === 'on')
    if (selectedOption) {
      setSelected(selectedOption.label)
      onChange(selectedOption.key as string)
      if (searchProps?.onChange) searchProps.onChange(selectedOption.label, [])
    }
  }

  const onSearchChange = (value: string) => {
    if (searchProps?.onChange) searchProps.onChange(value, [])
    setSearchValue(value)
  }

  const onClear = () => {
    setSelected(undefined)
    onSearchChange('')
    onChange('')
  }

  return (
    <EuiSelectable
      options={options}
      onChange={onOptionsChange}
      isLoading={isLoading}
      searchable
      searchProps={{ ...searchProps, onChange: onSearchChange, value: selected || searchValue, disabled: !!selected }}
      singleSelection="always"
      {...rest}
    >
      {(list, search) => (
        <>
          <Grid alignItems="center">
            <Grid.Col flexGrow={1}>{search}</Grid.Col>
            {!!selected && (
              <Grid.Col>
                <EuiButtonIcon size="xs" iconType="cross" display="fill" color="ghost" onClick={onClear} />
              </Grid.Col>
            )}
          </Grid>
          {!selected && !hideList && (
            <Grid className="eui-yScrollWithShadows" pb="macro" gutterSize="none" maxHeight={listHeight}>
              {list}
              {!isLoading && listAppend}
            </Grid>
          )}
        </>
      )}
    </EuiSelectable>
  )
}

export default React.memo(SelectableList)
