/* eslint-disable no-undef */
import { createSelector, Selector } from 'reselect'
import { BRANCH_KEY } from '../branch'

import { IRecipientsUploadOwnState } from '../model'

import { IUploadState } from './models'

export const branch: Selector<IRecipientsUploadOwnState, IUploadState> = (state) => state[BRANCH_KEY].upload

export const isValid = createSelector(branch, (upload) => upload.isValid)

export const invalidFields = createSelector(branch, (upload) => upload.invalidFields)

export const alignItems = createSelector(branch, (branch) => branch.alignItems)

export const validateItems = createSelector(branch, (branch) => branch.validateItems)

export const validateColumns = createSelector(branch, (branch) => branch.validateColumns)

export const validatePage = createSelector(branch, (branch) => branch.validatePage)

export const siteSelectionRequired = createSelector(branch, (branch) => branch.siteSelectionRequired)

export const isWipeAvailable = createSelector(branch, (branch) => branch.isWipeAvailable)

export const uploadPreview = createSelector(branch, (branch) => branch.uploadPreview)

export const wipeEnable = createSelector(branch, (branch) => branch.wipeEnable)

export const uploading = createSelector(branch, (branch) => branch.uploadState)

export const progress = createSelector(branch, (branch) => branch.progress)

export const total = createSelector(branch, (branch) => branch.total)

export const numInvalidRows = createSelector(branch, (branch) => branch.numInvalidRows)

export const loading = createSelector(branch, (upload) => upload.loading)

export const isSyncing = createSelector(branch, (upload) => upload.isSyncing)

export const error = createSelector(branch, (upload) => upload.error)
