import { createReducer } from '@reduxjs/toolkit'

import permissions from '../permissions/Permissions'

import { TableSelectionAction } from 'modules/web-molecules'
import {
  cancelBuildingForm,
  createBuilding,
  deleteBuildingLoading,
  deleteBuildingsLoading,
  error,
  loading,
  setCurrentDevelopments,
  setFormState,
  setPermissions,
  setSelectedBuilding,
  setSelectedBuildings,
  setSubmittedForm,
  updateLoading,
} from './actions'
import { FormState, initialState } from './models'

const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(createBuilding, (state) => {
      if (!((state.isDeletingBuilding || state.isUpdating) && !!state.selectedBuilding)) {
        state.selectedBuilding = undefined
        state.formState = FormState.CREATING
      }
    })
    .addCase(setSelectedBuildings, (state, { payload }) => {
      switch (payload.action) {
        case TableSelectionAction.DESELECTED_PAGE:
          state.selectedBuildings = state.selectedBuildings.filter(
            (currentSelection) => !payload.items.includes(currentSelection),
          )
          break
        case TableSelectionAction.DESELECTED_ALL:
          state.selectedBuildings = []
          break
        case TableSelectionAction.SELECTED:
          {
            const newList = [...state.selectedBuildings, ...payload.items]
            state.selectedBuildings = newList.filter((element, index) => {
              return newList.indexOf(element) === index
            })
          }
          break
        case TableSelectionAction.DESELECTED:
          state.selectedBuildings = state.selectedBuildings.filter(
            (currentSelection) => currentSelection != payload.items[0],
          )
          break
      }
    })
    .addCase(setSelectedBuilding, (state, { payload }) => {
      if (!!payload.building) {
        state.selectedBuilding = payload.building
        state.formState = FormState.EDITING
      } else if (!((state.isDeletingBuilding || state.isUpdating) && !!state.selectedBuilding)) {
        state.selectedBuilding = undefined
        state.formState = FormState.HIDDEN
      }
    })
    .addCase(deleteBuildingsLoading, (state, { payload }) => {
      state.isDeletingBuildings = payload
    })
    .addCase(deleteBuildingLoading, (state, { payload }) => {
      state.isDeletingBuilding = payload
    })
    .addCase(cancelBuildingForm, (state) => {
      state.formState = FormState.HIDDEN
      state.selectedBuilding = undefined
    })
    .addCase(setCurrentDevelopments, (state, { payload }) => {
      state.currentDevelopments = payload
    })
    .addCase(error, (state, { payload }) => {
      state.error = payload.message
    })
    .addCase(updateLoading, (state, { payload }) => {
      state.isUpdating = payload
    })
    .addCase(loading, (state, { payload }) => {
      state.loading = payload
      if (payload) state.error = undefined
    })
    .addCase(setPermissions, (state, { payload }) => {
      state.permissions.canViewBuildings = permissions.canViewBuilding.includes(payload.userType)
      state.permissions.canEditBuildings = permissions.canEditBuilding.includes(payload.userType)
      state.permissions.canAddBuildings = permissions.canCreateBuilding.includes(payload.userType)
      state.permissions.canRemoveBuildings = permissions.canRemoveBuilding.includes(payload.userType)
    })
    .addCase(setSubmittedForm, (state, { payload }) => {
      state.submittedForm = payload
      if (payload) state.formState = FormState.HIDDEN
    })
    .addCase(setFormState, (state, { payload }) => {
      state.formState = payload
    }),
)

export default reducer
