import '../assets/fonts/fonts.scss'

import * as LightTheme from '@elastic/eui/dist/eui_theme_light.json'

import deepfinityLight from './deepfinityLight'

/**
 * Contains theme for both the EUI & Deepfinity components
 */
const customTheme = {
  ...LightTheme,
  ...deepfinityLight,
}

export const lightTheme = customTheme
