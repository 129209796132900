import React, { useMemo, useState } from 'react'

import {
  EuiButton,
  EuiButtonEmpty,
  EuiListGroup,
  EuiListGroupItem,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
} from '@elastic/eui'

import { IDevelopmentDto } from 'api'
import orchestration from 'modules/module-orchestration'
import { strings } from 'modules/module-utils'
import { useAppDispatch } from 'modules/module-utils/hooks'
import { isNumeric } from 'modules/utils/Utils'
import { Grid, H1, H2, IModalProps, Modal, Paragraph, useTranslate } from 'modules/web-atoms'
import { FieldText, FormRow } from 'modules/web-molecules'

interface IDeleteAllModalProps extends Omit<IModalProps, 'onCancel' | 'children'> {
  onClose: () => void
  activeBuildings: IDevelopmentDto[]
}

const INTL_PREFIX = 'settings.tabs.data.recipients.delete-all'

export const DeleteAllModal: React.FC<IDeleteAllModalProps> = ({ onClose, activeBuildings, ...rest }) => {
  const dispatch = useAppDispatch()
  const t = useTranslate(INTL_PREFIX)

  const [error, setError] = useState<string | undefined>(undefined)
  const [confirmedSize, setConfirmedSize] = useState<number | undefined>()

  const buildingIds = useMemo(() => activeBuildings.map((b) => b.id), [activeBuildings])
  const buildingNames = useMemo(() => activeBuildings.map((b) => b.buildingName), [activeBuildings])

  const onDeleteConfirm = React.useCallback(() => {
    if (!confirmedSize) {
      setError(t('invalid-count'))
      return
    }
    if (!error) {
      dispatch(orchestration.actions.deleteAllRecipients(buildingIds))
      onClose()
    }
  }, [confirmedSize, error, t, dispatch, buildingIds, onClose])

  const onConfirmationFieldChange = React.useCallback(
    (e: React.ChangeEvent<any>) => {
      const { value: rawValue } = e.target
      const value = isNumeric(rawValue) ? parseInt(rawValue) : undefined

      setConfirmedSize(value)
      if (value === buildingIds.length) setError(undefined)
      else setError(t('invalid-count'))
    },
    [buildingIds.length, t],
  )

  return (
    <Modal title={t('title')} width="650" borderRadius={['none', 'large']} hasClose={true} onClose={onClose} {...rest}>
      <EuiModalHeader>
        <Grid flexGrow={1} alignItems="center">
          <Grid.Row marginX={['smallest', 'medium', 'large']}>
            <EuiModalHeaderTitle>
              <H1 textAlign="center" color="dangerDestructive">
                {t('title')}
              </H1>
            </EuiModalHeaderTitle>
          </Grid.Row>
        </Grid>
      </EuiModalHeader>

      <EuiModalBody>
        <Grid flexGrow={1} alignItems="center">
          <Grid.Row marginTop="small" marginX={['smallest', 'medium', 'large']}>
            <H2 color="textDark" textAlign={['left', 'center']}>
              {t('confirm-text')}
            </H2>
          </Grid.Row>
          <Grid.Row marginTop="small" marginX={['smallest', 'medium', 'large']} maxHeight={300} className="eui-yScroll">
            <EuiListGroup bordered={false}>
              {buildingNames.map((siteName) => (
                <EuiListGroupItem onClick={() => {}} key={siteName} label={strings.capitalize(siteName)} />
              ))}
            </EuiListGroup>
          </Grid.Row>
          <Grid.Row>
            <FormRow
              width="270px"
              label={
                <Paragraph size="medium" fontWeight="bold">
                  {t('confirm-count')}
                </Paragraph>
              }
              marginTop="small"
              touched={!!error}
              inputMode="numeric"
              error={error}
            >
              <FieldText
                name="numToDelete"
                placeholder={`${buildingNames.length}`}
                value={confirmedSize ?? ''}
                onChange={onConfirmationFieldChange}
              />
            </FormRow>
          </Grid.Row>
        </Grid>
      </EuiModalBody>

      <EuiModalFooter>
        <Grid mt={['none', 'large']} flexGrow={1} justifyContent="center" gutterSize="medium">
          <Grid.Col>
            <EuiButtonEmpty onClick={onClose}>{t('cancel')}</EuiButtonEmpty>
          </Grid.Col>

          <Grid.Col>
            <EuiButton type="submit" form="modalFormId" onClick={onDeleteConfirm} fill color="danger">
              {t('confirm')}
            </EuiButton>
          </Grid.Col>
        </Grid>
      </EuiModalFooter>
    </Modal>
  )
}

export default React.memo(DeleteAllModal)
