import React from 'react'

import { EuiFilePicker } from '@elastic/eui'
import styled from '@emotion/styled'

import { StyledViewProps } from 'modules/web-atoms'
import * as system from 'styled-system'

export type IFilePickerProps = React.ComponentProps<typeof EuiFilePicker>
export type IStyledFilePickerProps = StyledViewProps & IFilePickerProps

const EuiFilePickerFC: React.FC<IFilePickerProps> = (props) => {
  return <EuiFilePicker {...props} />
}

export const StyledFilePicker = styled(EuiFilePickerFC)<IStyledFilePickerProps>(
  system.color,
  system.flexbox,
  system.space,
  system.layout,
  system.position,
  system.justifyContent,
  system.alignItems,
  system.borders,
  system.shadow,
  system.background,
)

export const FilePicker: React.FC<IStyledFilePickerProps> = (props) => {
  return <StyledFilePicker {...props} />
}

export default React.memo(FilePicker)
