import * as React from 'react'

import { EuiFlexItem } from '@elastic/eui'
import styled from '@emotion/styled'
import shouldForwardProp from '@styled-system/should-forward-prop'

import * as system from 'styled-system'

import { StyledViewProps } from '../Grid/StyledView'

export type IFlexItemProps = StyledViewProps & React.ComponentProps<typeof EuiFlexItem>

export const StyledFlexItem = styled(EuiFlexItem)<IFlexItemProps>(
  system.color,
  system.flexbox,
  system.space,
  system.layout,
  system.position,
  system.justifyContent,
  system.alignItems,
  system.borders,
  system.shadow,
  system.background,
)

export const FlexItem: React.FC<IFlexItemProps> = (props) => {
  const { children, ...rest } = props
  return <StyledFlexItem {...rest}>{children}</StyledFlexItem>
}

export default FlexItem
