import { createReducer } from '@reduxjs/toolkit'
import permissions from '../permissions/Permissions'
import { initialState, SettingsFormState } from './models'

import { TableSelectionAction } from 'modules/web-molecules'
import {
  setCourierFormState,
  setDropoffLocationFormState,
  setIsAddingCourier,
  setIsAddingDropoffLocation,
  setIsAddingLocation,
  setIsAddingSize,
  setIsDeletingCouriers,
  setIsDeletingDropoffLocations,
  setIsDeletingLocations,
  setIsDeletingSizes,
  setIsDeletingTag,
  setIsEditingCourier,
  setIsEditingDropoffLocation,
  setIsEditingLocation,
  setIsEditingSize,
  setIsEditingTag,
  setIsUpdatingTag,
  setLoadingFlag,
  setLocationFormState,
  setPermissions,
  setSelectedCourier,
  setSelectedCouriers,
  setSelectedDropoffLocation,
  setSelectedDropoffLocations,
  setSelectedLocation,
  setSelectedLocations,
  setSelectedSite,
  setSelectedSize,
  setSelectedSizes,
  setSizeFormState,
  setTagCreation,
  showAddTagForm,
} from './actions'

export const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(setPermissions, (state, { payload }) => {
      state.permissions.canDeleteAllRecipients = permissions.canDeleteAllRecipients.includes(payload.userType)
      state.permissions.canExportAllRecipients = permissions.canExportAllRecipients.includes(payload.userType)
      state.permissions.canEditFeatureFlags = permissions.canEditFeatureFlags.includes(payload.userType)
      state.permissions.canManageTags = permissions.canManageTags.includes(payload.userType)
      state.permissions.canManageLocations = permissions.canManageLocations.includes(payload.userType)
      state.permissions.canManageCouriers = permissions.canManageCouriers.includes(payload.userType)
      state.permissions.canManageSizes = permissions.canManageSizes.includes(payload.userType)
      state.permissions.canManageDropoffLocations = permissions.canManageDropoffLocations.includes(payload.userType)
    })
    .addCase(setLoadingFlag, (state, { payload }) => {
      if (payload.state) state.flagLoading = payload.flag
      else state.flagLoading = undefined
    })
    .addCase(setTagCreation, (state, { payload }) => {
      state.isAddingTag = payload.isLoading
      state.showAddTagForm = payload.visible
    })
    .addCase(showAddTagForm, (state, { payload }) => {
      state.showAddTagForm = payload
    })
    .addCase(setIsDeletingTag, (state, { payload }) => {
      if (payload.state) state.deletingTagIds = [...state.deletingTagIds, payload.id]
      else state.deletingTagIds = state.deletingTagIds.filter((id) => id !== payload.id)
    })
    .addCase(setIsUpdatingTag, (state, { payload }) => {
      if (payload.state) state.updatingTagIds = [...state.updatingTagIds, payload.id]
      else state.updatingTagIds = state.updatingTagIds.filter((id) => id !== payload.id)
    })
    .addCase(setIsEditingTag, (state, { payload }) => {
      if (payload.state) state.editingTagIds = [...state.editingTagIds, payload.id]
      else state.editingTagIds = state.editingTagIds.filter((id) => id !== payload.id)
    })
    .addCase(setSelectedLocation, (state, { payload }) => {
      state.selectedLocation = payload
    })
    .addCase(setSelectedLocations, (state, { payload }) => {
      switch (payload.action) {
        case TableSelectionAction.DESELECTED_PAGE:
          state.selectedLocations = state.selectedLocations.filter(
            (currentSelection) => !payload.items.includes(currentSelection),
          )
          break
        case TableSelectionAction.DESELECTED_ALL:
          state.selectedLocations = []
          break
        case TableSelectionAction.SELECTED:
          {
            const newList = [...state.selectedLocations, ...payload.items]
            state.selectedLocations = newList.filter((element, index) => {
              return newList.indexOf(element) === index
            })
          }
          break
        case TableSelectionAction.DESELECTED:
          state.selectedLocations = state.selectedLocations.filter(
            (currentSelection) => currentSelection != payload.items[0],
          )
          break
      }
    })
    .addCase(setSelectedSite, (state, { payload }) => {
      state.selectedSite = payload
    })
    .addCase(setLocationFormState, (state, { payload }) => {
      state.locationFormState = payload
      if ([SettingsFormState.CREATE, SettingsFormState.HIDDEN].includes(payload)) {
        state.selectedLocation = undefined
      }
    })
    .addCase(setIsAddingLocation, (state, { payload }) => {
      state.isAddingLocation = payload
    })
    .addCase(setIsEditingLocation, (state, { payload }) => {
      state.isEditingLocation = payload
    })
    .addCase(setIsDeletingLocations, (state, { payload }) => {
      state.isDeletingLocations = payload
    })
    .addCase(setSelectedCourier, (state, { payload }) => {
      state.selectedCourier = payload
    })
    .addCase(setSelectedCouriers, (state, { payload }) => {
      switch (payload.action) {
        case TableSelectionAction.DESELECTED_PAGE:
          state.selectedCouriers = state.selectedCouriers.filter(
            (currentSelection) => !payload.items.includes(currentSelection),
          )
          break
        case TableSelectionAction.DESELECTED_ALL:
          state.selectedCouriers = []
          break
        case TableSelectionAction.SELECTED:
          {
            const newList = [...state.selectedCouriers, ...payload.items]
            state.selectedCouriers = newList.filter((element, index) => {
              return newList.indexOf(element) === index
            })
          }
          break
        case TableSelectionAction.DESELECTED:
          state.selectedCouriers = state.selectedCouriers.filter(
            (currentSelection) => currentSelection != payload.items[0],
          )
          break
      }
    })
    .addCase(setCourierFormState, (state, { payload }) => {
      state.courierFormState = payload
      if ([SettingsFormState.CREATE, SettingsFormState.HIDDEN].includes(payload)) {
        state.selectedCourier = undefined
      }
    })
    .addCase(setIsAddingCourier, (state, { payload }) => {
      state.isAddingCourier = payload
    })
    .addCase(setIsEditingCourier, (state, { payload }) => {
      state.isEditingCourier = payload
    })
    .addCase(setIsDeletingCouriers, (state, { payload }) => {
      state.isDeletingCouriers = payload
    })
    .addCase(setSelectedDropoffLocations, (state, { payload }) => {
      switch (payload.action) {
        case TableSelectionAction.DESELECTED_PAGE:
          state.selectedDropoffLocations = state.selectedDropoffLocations.filter(
            (currentSelection) => !payload.items.includes(currentSelection),
          )
          break
        case TableSelectionAction.DESELECTED_ALL:
          state.selectedDropoffLocations = []
          break
        case TableSelectionAction.SELECTED:
          {
            const newList = [...state.selectedDropoffLocations, ...payload.items]
            state.selectedDropoffLocations = newList.filter((element, index) => {
              return newList.indexOf(element) === index
            })
          }
          break
        case TableSelectionAction.DESELECTED:
          state.selectedDropoffLocations = state.selectedDropoffLocations.filter(
            (currentSelection) => currentSelection != payload.items[0],
          )
          break
      }
    })
    .addCase(setSelectedDropoffLocation, (state, { payload }) => {
      state.selectedDropoffLocation = payload
    })
    .addCase(setDropoffLocationFormState, (state, { payload }) => {
      state.dropoffLocationFormState = payload
    })
    .addCase(setIsAddingDropoffLocation, (state, { payload }) => {
      state.isAddingDropoffLocation = payload
    })
    .addCase(setIsEditingDropoffLocation, (state, { payload }) => {
      state.isEditingDropoffLocation = payload
    })
    .addCase(setIsDeletingDropoffLocations, (state, { payload }) => {
      state.isDeletingDropoffLocation = payload
    })
    .addCase(setSelectedSize, (state, { payload }) => {
      state.selectedSize = payload
    })
    .addCase(setSelectedSizes, (state, { payload }) => {
      switch (payload.action) {
        case TableSelectionAction.DESELECTED_PAGE:
          state.selectedSizes = state.selectedSizes.filter(
            (currentSelection) => !payload.items.includes(currentSelection),
          )
          break
        case TableSelectionAction.DESELECTED_ALL:
          state.selectedSizes = []
          break
        case TableSelectionAction.SELECTED:
          {
            const newList = [...state.selectedSizes, ...payload.items]
            state.selectedSizes = newList.filter((element, index) => {
              return newList.indexOf(element) === index
            })
          }
          break
        case TableSelectionAction.DESELECTED:
          state.selectedSizes = state.selectedSizes.filter((currentSelection) => currentSelection != payload.items[0])
          break
      }
    })
    .addCase(setSizeFormState, (state, { payload }) => {
      state.sizeFormState = payload
      if ([SettingsFormState.CREATE, SettingsFormState.HIDDEN].includes(payload)) {
        state.selectedSize = undefined
      }
    })
    .addCase(setIsAddingSize, (state, { payload }) => {
      state.isAddingSize = payload
    })
    .addCase(setIsEditingSize, (state, { payload }) => {
      state.isEditingSize = payload
    })
    .addCase(setIsDeletingSizes, (state, { payload }) => {
      state.isDeletingSizes = payload
    }),
)

export default reducer
