import * as React from 'react'

import { EuiPageContent } from '@elastic/eui'
import styled from '@emotion/styled'
import shouldForwardProp from '@styled-system/should-forward-prop'

import { StyledViewProps } from 'modules/web-atoms'
import * as system from 'styled-system'

export type IPageContentProps = StyledViewProps & React.ComponentProps<typeof EuiPageContent>

export const StyledPageContent = styled(EuiPageContent, { shouldForwardProp })<IPageContentProps>(
  system.color,
  system.flexbox,
  system.space,
  system.layout,
  system.position,
  system.justifyContent,
  system.alignItems,
  system.borders,
  system.shadow,
  system.background,
)

export const PageContent: React.FC<IPageContentProps> = (props) => {
  const { children, ...rest } = props
  return <StyledPageContent {...rest}>{children}</StyledPageContent>
}

export default React.memo(PageContent)
