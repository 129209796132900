import { createReducer } from '@reduxjs/toolkit'
import { TableSelectionAction } from 'modules/web-molecules'
import permissions from '../permissions/Permissions'
import {
  cancelUserForm,
  createUser,
  deleteUserLoading,
  deleteUsersLoading,
  error,
  loading,
  setFormState,
  setPermissions,
  setSelectedUser,
  setSelectedUsers,
  setSubmittedForm,
  updateLoading,
} from './actions'
import { FormState, initialState } from './models'

const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(createUser, (state) => {
      if (!((state.isDeletingUser || state.isUpdating) && !!state.selectedUser)) {
        state.selectedUser = undefined
        state.formState = FormState.CREATING
      }
    })
    .addCase(setSubmittedForm, (state, { payload }) => {
      state.submittedForm = payload
      if (payload) state.formState = FormState.HIDDEN
    })
    .addCase(setSelectedUsers, (state, { payload }) => {
      switch (payload.action) {
        case TableSelectionAction.DESELECTED_PAGE:
          state.selectedUsers = state.selectedUsers.filter(
            (currentSelection) => !payload.items.includes(currentSelection),
          )
          break
        case TableSelectionAction.DESELECTED_ALL:
          state.selectedUsers = []
          break
        case TableSelectionAction.SELECTED:
          {
            const newList = [...state.selectedUsers, ...payload.items]
            state.selectedUsers = newList.filter((element, index) => {
              return newList.indexOf(element) === index
            })
          }
          break
        case TableSelectionAction.DESELECTED:
          state.selectedUsers = state.selectedUsers.filter((currentSelection) => currentSelection != payload.items[0])
          break
      }
    })
    .addCase(setSelectedUser, (state, { payload }) => {
      if (!!payload.user) {
        state.selectedUser = payload.user
      } else if (!((state.isDeletingUser || state.isUpdating) && !!state.selectedUser)) {
        state.selectedUser = undefined
      }
    })
    .addCase(deleteUsersLoading, (state, { payload }) => {
      state.isDeletingUsers = payload
    })
    .addCase(deleteUserLoading, (state, { payload }) => {
      state.isDeletingUser = payload
    })
    .addCase(cancelUserForm, (state) => {
      state.selectedUser = undefined
      state.formState = FormState.HIDDEN
    })
    .addCase(error, (state, { payload }) => {
      state.error = payload.message
    })
    .addCase(updateLoading, (state, { payload }) => {
      state.isUpdating = payload
    })
    .addCase(loading, (state, { payload }) => {
      state.loading = payload
      if (payload) state.error = undefined
    })
    .addCase(setPermissions, (state, { payload }) => {
      state.permissions.canViewUsers = permissions.canViewUsers.includes(payload.userType)
      state.permissions.canCreateUsers = permissions.canCreateUsers.includes(payload.userType)
      state.permissions.canRemoveUsers = permissions.canDeleteUsers.includes(payload.userType)
      state.permissions.canEditUsers = permissions.canEditUsers.includes(payload.userType)
    })
    .addCase(setFormState, (state, { payload }) => {
      state.formState = payload
    }),
)

export default reducer
