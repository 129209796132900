import notification from './notification'
import reducer from './root-reducer'
import notificationsSagas from './sagas'

export const notifications = {
  actions: { notification: notification.actions },
  reducer,
  selectors: { ...notification.selectors },
  sagas: notificationsSagas,
}

export default notifications
