import { Expose, Transform, Type } from 'class-transformer'
import { LoginMode } from '../authentication'

export enum AccountType {
  RECIPIENT = 'RECIPIENT',
  USER = 'USER',
}

export class ResetPasswordDto {
  @Expose({ name: 'Token' })
  token: string
  @Expose({ name: 'Email' })
  email: string
  @Expose({ name: 'Password' })
  password: string
}

export class RequestPasswordResetDto {
  @Expose({ name: 'Email' })
  email: string
}

export class SelectAccountDto {
  @Expose({ name: 'id' })
  id: string
  @Expose({ name: 'type' })
  type: AccountType
  @Expose({ name: 'fullName' })
  fullName: string
  @Expose({ name: 'clientName' })
  clientName: string
  @Expose({ name: 'location' })
  location: string
}

export class GetLoginModeDto {
  @Expose({ name: 'mode' })
  @Transform(
    ({ value }) => {
      switch (value) {
        case 'Saml':
          return LoginMode.SAML
        case 'Oidc':
          return LoginMode.OIDC
        default:
          return LoginMode.Password
      }
    },
    { toClassOnly: true },
  )
  mode: LoginMode
}

export class SetPasswordDto extends ResetPasswordDto {}

export class SignupServersDto {
  @Expose({ name: 'SignupServers' })
  @Type(() => SignupServerDto)
  servers: SignupServerDto[]
}

export class SignupServerDto {
  @Expose({ name: 'Id' })
  id: string
  @Expose({ name: 'Name' })
  name: string
}

export class IUserServerResolveRequestDto {
  @Expose({ name: 'HashedEmail' })
  hashedEmail: string
  @Expose({ name: 'DoubleHashedEmail' })
  doubleHashedEmail: string
}

export class IUserServerResolveDto {
  @Expose({ name: 'ServerId' })
  serverId: string
  @Expose({ name: 'FirebaseConfiguration' })
  @Type(() => IFirebaseConfigurationDto)
  firebaseConfiguration: IFirebaseConfigurationDto
}

export class IFirebaseConfigurationDto {
  @Expose({ name: 'ProjectId' })
  projectId: string
  @Expose({ name: 'ApplicationId' })
  applicationId: string
  @Expose({ name: 'ClientId' })
  clientId: string
  @Expose({ name: 'StorageBucket' })
  storageBucket: string
  @Expose({ name: 'ApiKeyAndroid' })
  apiKeyAndroid: string
  @Expose({ name: 'ApiKeyIos' })
  apiKeyIos: string
  @Expose({ name: 'GcmSenderId' })
  gcmSenderId: string
  @Expose({ name: 'DatabaseUrl' })
  databaseUrl: string
}
