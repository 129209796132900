import * as React from 'react'

import { IDeepfinityTheme, SemanticSpacing, spacingSizes } from 'modules/dna'
import { Grid, GridDirection, RadioButton } from 'modules/web-atoms'
import { VariableSizeList as List, VariableSizeListProps as ListProps } from 'react-window'
import { ResponsiveValue } from 'styled-system'

type RadioButtonProps = React.ComponentProps<typeof RadioButton>

export interface IListVirtualization {
  listHeight: number
  listWidth: number
  gap: SemanticSpacing
  padding?: SemanticSpacing
  rowHeight?: number
  columnWidth?: number
}
const DEFAULT_ITEM_SIZE = 100
const DEFAULT_GAP = spacingSizes.small as number
const DEFAULT_PADDING = spacingSizes.smallest as number

export interface IRadioGroupProps extends Omit<React.ComponentProps<typeof Grid>, 'onChange' | 'direction'> {
  initial?: any
  onChange?: (key: any) => void
  direction?: ResponsiveValue<GridDirection, IDeepfinityTheme>
  gutterSize?: ResponsiveValue<SemanticSpacing, IDeepfinityTheme>
  disableFlexGrow?: boolean
  // TODO: Refactor list virtualization to a separate component
  virtualization?: IListVirtualization
}

type Props = React.PropsWithChildren<IRadioGroupProps>
export const RadioGroup = (props: Props) => {
  const { initial, onChange, children, direction, gutterSize, disableFlexGrow, virtualization, ...grid } = props
  const virtualizationSpacing = React.useMemo(
    () => ({
      gutterSize: virtualization?.gap ? (spacingSizes[virtualization.gap] as number) : DEFAULT_GAP,
      padding: virtualization?.padding ? (spacingSizes[virtualization.padding] as number) : DEFAULT_PADDING,
    }),
    [virtualization],
  )
  const [currentRadio, setCurrentRadio] = React.useState()

  React.useEffect(() => {
    if (initial) setCurrentRadio(initial)
  }, [initial])

  const onRadioChange = (option: any) => {
    setCurrentRadio((prev: any) => (prev === option ? undefined : option))
  }

  React.useEffect(() => {
    onChange?.(currentRadio)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRadio])

  const items = React.Children.toArray(children).filter<React.ReactElement>(React.isValidElement)

  const getItemSize: ListProps['itemSize'] = () => {
    return (direction === 'row' ? virtualization?.columnWidth : virtualization?.rowHeight) || DEFAULT_ITEM_SIZE
  }

  const VirtualizedListItem = ({ index, style }) => {
    const item = items[index]
    const { gutterSize, padding } = virtualizationSpacing
    return (
      <div
        style={{
          ...style,
          ...(direction === 'column'
            ? {
                top: style.top + gutterSize + padding,
                height: style.height - gutterSize,
              }
            : {
                left: style.left + gutterSize + padding,
                width: style.width - gutterSize,
              }),
        }}
      >
        {React.cloneElement<RadioButtonProps>(item, {
          id: item.props.id,
          paddingY: 'small',
          ...item.props,
          selected: item.props.id === currentRadio,
          onClick: onRadioChange,
        })}
      </div>
    )
  }

  const innerElementType: ListProps['innerElementType'] = React.forwardRef(({ style, ...rest }, ref) => {
    const { padding } = virtualizationSpacing
    return (
      <div
        ref={ref}
        style={{
          ...style,
          height: direction === 'column' ? `${parseFloat(style.height) + padding * 2}px` : style.height,
          width: direction === 'row' ? `${parseFloat(style.width) + padding * 2}px` : style.width,
        }}
        {...rest}
      />
    )
  })
  innerElementType.displayName = 'innerElementType'

  return !!virtualization ? (
    <List
      height={virtualization.listHeight}
      width={virtualization.listWidth}
      innerElementType={innerElementType}
      itemSize={getItemSize}
      itemCount={items.length}
      layout={direction === 'row' ? 'horizontal' : 'vertical'}
    >
      {VirtualizedListItem}
    </List>
  ) : (
    <Grid gutterSize={gutterSize} flex={1} {...grid} role="radiogroup">
      {direction === 'row'
        ? items.map((row, index) => {
            return (
              <Grid.Col flex={disableFlexGrow ? undefined : 1} flexShrink={1} key={row.key}>
                {React.cloneElement<RadioButtonProps>(row, {
                  id: row.props.id,
                  paddingY: 'small',
                  width: '100%',
                  ...row.props,
                  selected: row.props.id === currentRadio,
                  onClick: onRadioChange,
                })}
              </Grid.Col>
            )
          })
        : items.map((col, index) => {
            return (
              <Grid.Row flex={disableFlexGrow ? undefined : 1} flexShrink={1} key={col.key}>
                {React.cloneElement<RadioButtonProps>(col, {
                  id: col.props.id,
                  paddingY: 'small',
                  ...col.props,
                  selected: col.props.id === currentRadio,
                  onClick: onRadioChange,
                })}
              </Grid.Row>
            )
          })}
    </Grid>
  )
}

RadioGroup.Button = RadioButton
RadioGroup.defaultProps = {
  direction: 'row',
  gutterSize: ['smallest', 'none'],
} as Props

export default React.memo(RadioGroup)
