/* eslint-disable react/display-name */
import * as React from 'react'

import { useAppDispatch, useAppSelector } from 'modules/module-utils/hooks'
import { useToggle, useTranslate } from 'modules/web-atoms'
import { DeleteConfirmationModal, IPanelProps, Table } from 'modules/web-molecules'
import { ICreateOptions, IDeleteOptions, ISearchOptions, TablePanelTemplate } from 'modules/web-templates'
import { useUsersTable } from '../../../hooks/use-user-table/use-user-table'
import user, { FormState } from '../../../user'

export type IUsersTablePageProps = IPanelProps

export const TABLE_ID = 'table-users'
const INTL_PREFIX_MODAL = 'users-directory.delete-modal'

export const UsersTable: React.FC<IUsersTablePageProps> = (props) => {
  const { ...rest } = props

  const t = useTranslate('users-directory.table')
  const tCommon = useTranslate('commons')
  const dispatch = useAppDispatch()

  const [isDeleteOpen, toggle] = useToggle(false)

  const { canRemoveUsers, canCreateUsers } = useAppSelector(user.selectors.permissions)
  const selectedItems = useAppSelector(user.selectors.selectedUsers)
  const selectedUsersSize = useAppSelector(user.selectors.selectedUsersSize)
  const formState = useAppSelector(user.selectors.formState)
  const isDeleting = useAppSelector(user.selectors.isDeletingUsers)

  const {
    columns,
    items,
    selection,
    onItemClicked,
    onSearch,
    isFetching,
    sort,
    error,
    pagination,
    paginationOptions,
    onRefresh,
  } = useUsersTable()

  const onAddUser = React.useCallback(() => {
    dispatch(user.actions.createUser())
  }, [dispatch])

  const onDelete = React.useCallback(() => {
    dispatch(user.actions.deleteUsers())
    toggle()
  }, [dispatch, toggle])

  const createOptions: ICreateOptions = {
    visible: canCreateUsers && formState !== FormState.CREATING,
    onClick: onAddUser,
    value: t('add-user'),
  }

  const searchOptions: ISearchOptions = {
    hint: t('search.placeholder'),
    onSearch: onSearch,
  }

  const deleteOptions: IDeleteOptions = {
    visible: selectedUsersSize > 0 && canRemoveUsers,
    isLoading: isDeleting,
    onClick: toggle,
    value: `${tCommon('action.delete')} ${selectedItems.length || 1}`,
  }

  return (
    <TablePanelTemplate
      heading={t('heading')}
      subheading={t('subheading', {
        displayed: Math.min(pagination.pageSize, pagination.total),
        total: pagination.total,
      })}
      createOptions={createOptions}
      searchOptions={searchOptions}
      deleteOptions={deleteOptions}
      isDeleteModalOpen={isDeleteOpen}
    >
      <TablePanelTemplate.Table>
        <Table
          tableId={TABLE_ID}
          columns={columns}
          items={items}
          selection={selection}
          sorting={sort}
          pagination={pagination}
          paginationOptions={paginationOptions}
          loading={isFetching}
          isCheckable={true}
          onItemClicked={onItemClicked}
          selectedItems={selectedItems}
          onRefresh={onRefresh}
        />
      </TablePanelTemplate.Table>
      <TablePanelTemplate.DeleteModal>
        <DeleteConfirmationModal
          onClose={toggle}
          onDelete={onDelete}
          itemSize={selectedUsersSize}
          intlPrefix={INTL_PREFIX_MODAL}
        />
      </TablePanelTemplate.DeleteModal>
    </TablePanelTemplate>
  )
}

export default React.memo(UsersTable)
