export enum Radii {
  None = 0,
  Small = 6,
  Medium = 8,
  XMedium = 12,
  Large = 16,
  XLarge = 24,
  XXLarge = 32,
  Massive = 64,
  Round = '50%',
}

export type RadiiName = 'none' | 'small' | 'medium' | 'xmedium' | 'large' | 'xlarge' | 'xxlarge' | 'massive' | 'round'

export type RadiiSizes = Record<RadiiName, Radii>

export const radiiSizes: RadiiSizes = {
  none: Radii.None,
  small: Radii.Small,
  medium: Radii.Medium,
  xmedium: Radii.XMedium,
  large: Radii.Large,
  xlarge: Radii.XLarge,
  xxlarge: Radii.XXLarge,
  massive: Radii.Massive,
  round: Radii.Round,
}

export default radiiSizes
