import { Paragraph } from 'modules/web-atoms'
import * as React from 'react'

export interface IColumnTextProps extends React.ComponentProps<typeof Paragraph> {}

const TableColumn: React.FC<IColumnTextProps> = ({ children, ...rest }) => {
  return (
    <Paragraph fontWeight="bold" color="primaryMid" size="medium" lineHeight={'small'} truncated={true} {...rest}>
      {children}
    </Paragraph>
  )
}

export default React.memo(TableColumn)
