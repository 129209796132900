import { API, IRecipientBuildingOptionDto } from 'api'
import { plainToInstance } from 'class-transformer'
import { QueryKey, useApi } from 'modules/module-api'
import security from 'modules/module-security'
import React from 'react'
import { useAppSelector } from '../use-app-selector/use-app-selector'

export const useRecipientBuildings = (recipientId: string | undefined, requesterEnabled: boolean = false) => {
  const featureFlags = useAppSelector(security.selectors.featureFlags)
  const { data, isFetching, error } = useApi<IRecipientBuildingOptionDto[]>(
    [QueryKey.RECIPIENT_BUILDINGS, recipientId, featureFlags?.multimailroomEnabled],
    async () => {
      if (recipientId && featureFlags?.multimailroomEnabled) {
        const response = await API({
          method: 'GET',
          url: `/v3/tenants/buildings-access`,
          params: {
            tenant_id: recipientId,
            requestor_enabled: requesterEnabled,
          },
        })
        return response.data
      }
    },
  )

  const recipientBuildings = React.useMemo(
    () => (data ? plainToInstance(IRecipientBuildingOptionDto, data) : []),
    [data],
  )

  return { recipientBuildings, isFetching, error }
}
