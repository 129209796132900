import React from 'react'

import { EuiFieldSearch } from '@elastic/eui'
import styled from '@emotion/styled'

import { StyledViewProps } from 'modules/web-atoms'
import * as system from 'styled-system'

export type IFieldSearchProps = React.ComponentProps<typeof EuiFieldSearch>
export type IStyledFieldSearchProps = StyledViewProps & IFieldSearchProps

export const StyledFieldSearch = styled(EuiFieldSearch)<IStyledFieldSearchProps>(
  system.color,
  system.flexbox,
  system.space,
  system.layout,
  system.position,
  system.justifyContent,
  system.alignItems,
  system.borders,
  system.shadow,
  system.background,
)

export const FieldSearch: React.FC<IStyledFieldSearchProps> = (props) => {
  return <StyledFieldSearch {...props} />
}

export default React.memo(FieldSearch)
