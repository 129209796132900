import statistics from './statistics'
import reducer from './root-reducer'
import statisticsSagas from './sagas'
export { default as statisticsRoutes } from './navigation/routes'

export const statisticsModule = {
  actions: {
    ...statistics.actions,
  },
  reducer,
  selectors: {
    ...statistics.selectors,
  },
  sagas: statisticsSagas,
}

export default statisticsModule
