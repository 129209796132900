import { combineReducers } from 'redux'

import parcels from './parcels'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const reducers = {
  parcels: parcels.reducer,
}

const persistConfig = {
  key: 'deliveries',
  storage,
  whitelist: [],
}

const reducer = combineReducers(reducers)

export const persistedReducer = persistReducer(persistConfig, reducer)

export default persistedReducer
