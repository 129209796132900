import { handleActions } from 'modules/utils/redux'
import { SET_IS_WIPING_BUILDINGS } from './actions'
import { initialState, IOrchestrationState } from './models'

export const reducer = handleActions<IOrchestrationState>(
  {
    [SET_IS_WIPING_BUILDINGS]: (state: IOrchestrationState, { payload }: { payload: boolean }) => {
      state.isWipingBuilding = payload
    },
    [SET_IS_WIPING_BUILDINGS]: (state: IOrchestrationState, { payload }: { payload: string | undefined }) => {
      state.error = payload
    },
  },
  initialState,
)

export default reducer
