import { EuiFormRow, EuiFormRowProps } from '@elastic/eui'
import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { Grid, StyledViewProps } from 'modules/web-atoms'
import React from 'react'
import * as system from 'styled-system'

// `EuiFormRow` by default seems to be a class based component. This is to just convert
// it into functional.
const FCEuiFormRow: React.FC<EuiFormRowProps> = ({ children, ...rest }) => <EuiFormRow {...rest}>{children}</EuiFormRow>

interface IBaseProps {
  touched?: boolean
  error?: string | string[] | false
  required?: boolean
}
export type IStyledFormRowProps = StyledViewProps & React.ComponentProps<typeof FCEuiFormRow>

export const StyledFormRow = styled(FCEuiFormRow)<IStyledFormRowProps>(
  system.color,
  system.flexbox,
  system.space,
  system.layout,
  system.position,
  system.justifyContent,
  system.alignItems,
  system.borders,
  system.shadow,
  system.background,
)

const transform = system.compose(
  system.color,
  system.flexbox,
  system.borders,
  system.margin,
  system.position,
  system.layout,
)

export type IFormRowProps = Omit<IStyledFormRowProps, 'isInvalid' | 'error'> & IBaseProps

export const FormRow: React.FC<IFormRowProps> = (props) => {
  // Omit creates a new type so it doesn't work well with ...rest (TS complains). asserted types for now.
  const { children, touched, error, label, required, ...rest } = props as IStyledFormRowProps & IBaseProps
  const container = transform({ ...rest, theme: useTheme() })

  return (
    <Grid {...container}>
      <StyledFormRow
        margin="none"
        padding="none"
        maxWidth="100%"
        isInvalid={touched && !!error}
        error={touched && error}
        label={required ? `${label} *` : label}
        {...rest}
      >
        {children}
      </StyledFormRow>
    </Grid>
  )
}

export default FormRow
