import { SagaIterator } from '@redux-saga/types'
import { ITenantDto } from 'api'
import autobind from 'autobind-decorator'
import { AxiosInstance } from 'axios'
import { instanceToPlain } from 'class-transformer'
import notifications from 'modules/module-alerts'
import { QueryKey, QueryService } from 'modules/module-api'
import { translate } from 'modules/module-intl'
import orchestration from 'modules/module-orchestration'
import { determineUserPermissions } from 'modules/module-orchestration/orchestration/actions'
import reporting from 'modules/module-reporting'
import security from 'modules/module-security'
import { SupervisorSuite } from 'modules/redux-supervisor'
import { TableSelectionAction } from 'modules/web-molecules'
import { all, call, put, select, takeEvery, takeLatest, takeLeading } from 'redux-saga/effects'
import { v4 as uuid } from 'uuid'
import recipients from '../recipients'
import { INotificationOptions } from './models'

// Actions
const { addRecipient, updateRecipient } = recipients.actions

const defaultErr = 'Something went wrong'
const INTL_PREFIX = 'recipients-directory'

export class RecipientsSaga extends SupervisorSuite {
  private readonly apiService: AxiosInstance
  private readonly queryService: QueryService

  constructor(apiService: AxiosInstance, queryService: QueryService) {
    super()
    this.apiService = apiService
    this.queryService = queryService
  }

  @autobind
  *start(): SagaIterator {
    yield all([
      takeLatest(orchestration.actions.DETERMINE_USER_PERMISSIONS, this.determineUserPermissions),
      takeLeading(recipients.actions.ADD_RECIPIENT, this.addRecipient),
      takeLeading(recipients.actions.UPDATE_RECIPIENT, this.updateRecipient),
      takeEvery(recipients.actions.DELETE_RECIPIENTS, this.deleteRecipients),
    ])
  }

  @autobind
  *determineUserPermissions({ payload }: ReturnType<typeof determineUserPermissions>): SagaIterator {
    const userType = yield select(security.selectors.type)
    yield put(
      recipients.actions.setPermissions({ userType, canManageRecipients: payload.flags.manageRecipientsEnabled }),
    )
  }

  @autobind
  *addRecipient({ payload }: ReturnType<typeof addRecipient>): SagaIterator {
    const {
      firstName,
      lastName,
      alias,
      email,
      location,
      phone,
      type,
      notifEmail,
      notifSMS,
      site,
      sites,
      dropoffLocation,
    } = payload
    yield put(recipients.actions.setLoading(true))
    const data = new ITenantDto()
    data.id = uuid()
    data.firstName = firstName.trim()
    data.lastName = lastName.trim()
    data.alias = alias.trim() || undefined
    data.email = email.trim()
    data.room = location.trim()
    data.phone = phone.trim()
    data.dropoffLocation = dropoffLocation
    data.notificationOptions = this.computeNotificationOptions({ notifEmail, notifSMS })
    data.primarySite = site
    data.additionalSites = sites
    data.dateOfBirth = undefined
    data.gdprComply = undefined
    data.type = type
    data.recordModified = new Date().toISOString()

    try {
      yield call(this.apiService, '/v3/tenants', {
        method: 'POST',
        data: instanceToPlain(data),
      })
      yield put(recipients.actions.resetFormState())
      yield call(this.queryService.refetchQueries, [QueryKey.RECIPIENTS])
      yield put(notifications.actions.success({ message: yield translate(`${INTL_PREFIX}.form.add-success`) }))
    } catch (err) {
      yield put(recipients.actions.error(defaultErr))
      yield put(reporting.actions.error(err))
      yield put(notifications.actions.error({ message: yield translate(`${INTL_PREFIX}.form.default-error`) }))
    } finally {
      yield put(recipients.actions.setLoading(false))
    }
  }

  @autobind
  *updateRecipient({
    payload: {
      id,
      firstName,
      lastName,
      alias,
      email,
      location,
      phone,
      type,
      notifEmail,
      notifSMS,
      site,
      sites,
      dropoffLocation,
    },
  }: ReturnType<typeof updateRecipient>): SagaIterator {
    yield put(recipients.actions.setLoading(true))
    const data = new ITenantDto()
    data.id = id
    data.firstName = firstName.trim()
    data.lastName = lastName.trim()
    data.alias = alias.trim() || undefined
    data.email = email.trim()
    data.room = location.trim()
    data.phone = phone.trim()
    data.dropoffLocation = dropoffLocation
    data.dateOfBirth = undefined
    data.gdprComply = undefined
    data.notificationOptions = this.computeNotificationOptions({ notifEmail, notifSMS })
    data.primarySite = site
    data.additionalSites = sites
    data.type = type
    data.recordModified = new Date().toISOString()

    try {
      yield call(this.apiService, `/v3/tenants/${id}`, {
        method: 'PUT',
        data: instanceToPlain(data),
      })
      yield put(recipients.actions.resetFormState())
      yield call(this.queryService.refetchQueries, [QueryKey.RECIPIENTS])
      yield put(notifications.actions.success({ message: yield translate(`${INTL_PREFIX}.form.update-success`) }))
    } catch (err) {
      yield put(recipients.actions.error(defaultErr))
      yield put(reporting.actions.error(err))
      yield put(notifications.actions.error({ message: yield translate(`${INTL_PREFIX}.form.default-error`) }))
    } finally {
      yield put(recipients.actions.setLoading(false))
    }
  }

  @autobind
  *deleteRecipients(): SagaIterator {
    yield put(recipients.actions.setDeletionLoading(true))
    const recipientsToDelete: string[] = yield select(recipients.selectors.recipientsToDelete)

    try {
      yield call(this.apiService, `/v3/tenants`, {
        method: 'DELETE',
        data: {
          TenantIds: recipientsToDelete
        }
      }),
      yield put(recipients.actions.resetFormState())
      yield put(recipients.actions.setSelectedItems({ items: [], action: TableSelectionAction.DESELECTED_ALL }))
    } catch (err) {
      yield put(recipients.actions.error(defaultErr))
      yield put(reporting.actions.error(err))
    } finally {
      yield put(recipients.actions.setDeletionLoading(false))
      yield call(this.queryService.refetchQueries, [QueryKey.RECIPIENTS])
    }
  }

  private computeNotificationOptions(options: INotificationOptions): number | undefined {
    const { notifEmail: email, notifSMS: phone } = options
    if (email && phone) {
      return 2
    } else if (phone) {
      return 1
    } else if (email) {
      return 0
    } else {
      return undefined
    }
  }
}

export default RecipientsSaga
