import * as React from 'react'

import styled from '@emotion/styled'

import {
  BannerDanger,
  BannerInfo,
  BannerSuccess,
  ChevronDown,
  ChevronUp,
  MenuBuildings,
  MenuDashboard,
  MenuDeliveries,
  MenuDeliveriesInbound,
  MenuDeliveriesOutbound,
  MenuHelp,
  MenuIcon,
  MenuNotifications,
  MenuRecipients,
  MenuSettings,
  MenuStatistics,
  MenuUsers,
  SelectedBuildingsAdd,
  MenuHeaderToggle,
  MenuPrivacyPolicy,
  MenuTerms,
  MenuBilling,
  Calendar,
  Success,
  ErrorAlt,
  Cross,
  Tick,
  FreePlan,
  ProPlan,
  ProMultiSitePlan,
  EnterprisePlan,
  PMParcel,
  PMBuilding,
  PMBell,
  Plus,
  Email,
  SMS,
  ColorSquare,
  Pencil,
  SiteMarker,
  Trash,
} from 'modules/dna'
import * as system from 'styled-system'

import { StyledViewProps } from '../Grid/StyledView'

export type SVGComponent = React.ComponentType<JSX.IntrinsicElements['svg']>
export type SvgProps = React.ComponentProps<SVGComponent>

export enum IconType {
  MenuDashboard = 'MenuDashboard',
  MenuRecipients = 'MenuRecipients',
  MenuDeliveries = 'MenuDeliveries',
  MenuDeliveriesInbound = 'MenuDeliveriesInbound',
  MenuDeliveriesOutbound = 'MenuDeliveriesOutbound',
  MenuStatistics = 'MenuStatistics',
  MenuUsers = 'MenuUsers',
  MenuBuildings = 'MenuBuildings',
  MenuNotifications = 'MenuNotifications',
  MenuSettings = 'MenuSettings',
  MenuBilling = 'MenuBilling',
  MenuHelp = 'MenuHelp',
  MenuIcon = 'MenuIcon',
  ChevronUp = 'ChevronUp',
  ChevronDown = 'ChevronDown',
  SelectedBuildingsAdd = 'SelectedBuildingsAdd',
  MenuHeaderToggle = 'MenuHeaderToggle',
  MenuPrivacyPolicy = 'MenuPrivacyPolicy',
  MenuTerms = 'MenuTerms',
  Calendar = 'Calendar',
  Success = 'Success',
  ErrorAlt = 'ErrorAlt',
  BannerSuccess = 'BannerSuccess',
  BannerInfo = 'BannerInfo',
  BannerDanger = 'BannerDanger',
  Cross = 'Cross',
  Tick = 'Tick',
  FreePlan = 'FreePlan',
  ProPlan = 'ProPlan',
  ProMultiSitePlan = 'ProMultiSitePlan',
  EnterprisePlan = 'EnterprisePlan',
  PMParcel = 'PMParcel',
  PMBuilding = 'PMBuilding',
  PMBell = 'PMBell',
  Plus = 'Plus',
  Email = 'Email',
  SMS = 'SMS',
  ColorSquare = 'ColorSquare',
  Pencil = 'Pencil',
  SiteMarker = 'SiteMarker',
  Trash = 'Trash',
}

export const mapping: Array<[IconType, SVGComponent]> = [
  [IconType.MenuDashboard, MenuDashboard],
  [IconType.MenuRecipients, MenuRecipients],
  [IconType.MenuDeliveries, MenuDeliveries],
  [IconType.MenuDeliveriesInbound, MenuDeliveriesInbound],
  [IconType.MenuDeliveriesOutbound, MenuDeliveriesOutbound],
  [IconType.MenuStatistics, MenuStatistics],
  [IconType.MenuUsers, MenuUsers],
  [IconType.MenuBuildings, MenuBuildings],
  [IconType.MenuNotifications, MenuNotifications],
  [IconType.MenuSettings, MenuSettings],
  [IconType.MenuHelp, MenuHelp],
  [IconType.MenuIcon, MenuIcon],
  [IconType.MenuBilling, MenuBilling],
  [IconType.ChevronUp, ChevronUp],
  [IconType.ChevronDown, ChevronDown],
  [IconType.SelectedBuildingsAdd, SelectedBuildingsAdd],
  [IconType.MenuHeaderToggle, MenuHeaderToggle],
  [IconType.MenuPrivacyPolicy, MenuPrivacyPolicy],
  [IconType.MenuTerms, MenuTerms],
  [IconType.Calendar, Calendar],
  [IconType.Success, Success],
  [IconType.ErrorAlt, ErrorAlt],
  [IconType.BannerSuccess, BannerSuccess],
  [IconType.BannerInfo, BannerInfo],
  [IconType.BannerDanger, BannerDanger],
  [IconType.Cross, Cross],
  [IconType.Tick, Tick],
  [IconType.FreePlan, FreePlan],
  [IconType.ProPlan, ProPlan],
  [IconType.ProMultiSitePlan, ProMultiSitePlan],
  [IconType.EnterprisePlan, EnterprisePlan],
  [IconType.PMParcel, PMParcel],
  [IconType.PMBuilding, PMBuilding],
  [IconType.PMBell, PMBell],
  [IconType.Plus, Plus],
  [IconType.Email, Email],
  [IconType.SMS, SMS],
  [IconType.ColorSquare, ColorSquare],
  [IconType.Pencil, Pencil],
  [IconType.SiteMarker, SiteMarker],
  [IconType.Trash, Trash],
]

const createStyledSvg = (svg: SVGComponent) => {
  const StyledIcon = styled(svg)<StyledViewProps>(
    system.color,
    system.flexbox,
    system.space,
    system.layout,
    system.position,
    system.justifyContent,
    system.alignItems,
    system.borders,
    system.shadow,
    system.background,
  )

  type StyledPageProps = React.ComponentProps<typeof StyledIcon>

  const Icon: React.FC<StyledPageProps> = (props) => {
    const { children, ...rest } = props

    return <StyledIcon {...rest}>{children}</StyledIcon>
  }
  return React.memo(Icon)
}

const SampleComponent = createStyledSvg(MenuHelp)

export type StyledIconProps = React.ComponentProps<typeof SampleComponent>

export type IIconProps = StyledIconProps

export type IconTypes = {
  [image in IconType]: React.ComponentType<IIconProps>
}
export type IconComponent = React.FunctionComponent<IIconProps> & IconTypes

export const Icon: IconComponent = Object.assign(
  mapping.reduce(
    (collection, [type, component]) => ({ ...collection, [type]: createStyledSvg(component) }),
    {} as IconTypes,
  ),
)

Icon.displayName = 'Icon'

export default Icon
