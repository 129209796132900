import * as actions from './actions'
import reducer from './reducer'
import * as selectors from './selectors'

const deliveries = {
  actions,
  reducer,
  selectors,
}

export * from './models'
export default deliveries
