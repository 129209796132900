import lazy from '@loadable/component'
import { FeatureFlags } from 'api'
import { IModuleRoute } from 'modules/module-navigation'

const MODULE_PATH = '/portal/statistics'

export enum StatisticsPath {
  INBOUND_STATS = '/portal/statistics/inbound-statistics',
  OUTBOUND_STATS = '/portal/statistics/outbound-statistics',
}

const StatsPage = lazy(async () => import('../screens/StatsPage/StatsPage'))
const OutboundStatsPage = lazy(async () => import('../screens/OutboundStatsPage/OutboundStatsPage'))

export const routes: IModuleRoute = {
  path: MODULE_PATH,
  routes: [
    {
      path: StatisticsPath.INBOUND_STATS,
      page: StatsPage,
    },
    {
      path: StatisticsPath.OUTBOUND_STATS,
      page: OutboundStatsPage,
      featureFlag: FeatureFlags.OUTBOUND,
    },
  ],
}

export default routes
