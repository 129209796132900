import * as React from 'react'

import styled from '@emotion/styled'

import shouldForwardProp from '@styled-system/should-forward-prop'
import {
  FontFace,
  IDeepfinityTheme,
  TextLineDecorationProp,
  textOverflowDecoration,
  TextOverFlowDecorationProp,
  whitespaceDecoration,
} from 'modules/dna'
import { AriaAttributes, AriaRole } from 'react'
import * as system from 'styled-system'

export type StyledTextProps = system.ColorProps<IDeepfinityTheme> &
  system.SpaceProps<IDeepfinityTheme> &
  system.TypographyProps<IDeepfinityTheme> &
  system.TextShadowProps<IDeepfinityTheme> &
  system.LayoutProps<IDeepfinityTheme> &
  system.FlexboxProps<IDeepfinityTheme> &
  system.PositionProps<IDeepfinityTheme> & {
    whiteSpace?: string
    textOverflow?: TextOverFlowDecorationProp
    textDecorationLine?: TextLineDecorationProp
    truncated?: boolean
    maxLines?: number
    title?: string
    role?: AriaRole
    '-webkit-box-orient'?: string
    '-webkit-line-clamp'?: string
  } & AriaAttributes

export type TextComponentType = Parameters<typeof styled>[0]

export const createStyledText = <C extends React.ComponentClass<React.ComponentProps<C>>>(
  type: TextComponentType,
): React.FC<StyledTextProps> => {
  const BaseStyledText: React.ComponentType<StyledTextProps> = styled(type, { shouldForwardProp })(
    system.color,
    system.space,
    system.typography,
    system.textShadow,
    system.layout,
    system.system({
      textDecorationLine: true,
      textTransform: true,
      role: true,
      '-webkit-box-orient': true,
      '-webkit-line-clamp': true,
    }),
    system.flexbox,
    whitespaceDecoration,
    textOverflowDecoration,
  )

  const StyledText: React.FC<StyledTextProps> = (props) => {
    const { truncated, maxLines, maxWidth, ...rest } = props
    const truncatedStyle: StyledTextProps | undefined = truncated
      ? {
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          display: 'block',
          maxWidth: maxWidth ?? '100%',
        }
      : undefined

    const maxLinesStyle: StyledTextProps | undefined = maxLines
      ? {
          maxWidth: maxWidth ?? '100%',
          display: '-webkit-box',
          '-webkit-box-orient': 'vertical',
          '-webkit-line-clamp': maxLines.toString(),
          overflow: 'hidden',
        }
      : undefined
    return <BaseStyledText {...rest} {...truncatedStyle} {...maxLinesStyle} />
  }

  StyledText.defaultProps = {
    color: 'textDark',
    fontFamily: FontFace.SofiaPro,
    fontSize: 'medium',
    whiteSpace: 'pre-wrap',
  }

  StyledText.displayName = 'StyledText'

  return StyledText
}

const StyledText: React.FC<StyledTextProps> = createStyledText('p')

export default StyledText
