import * as actions from './actions/actions'
import supportSagas from './sagas'

export * from './zendesk/ZendeskChat'
export { default as IZendeskChat } from './zendesk/ZendeskChat'

export const support = {
  actions,
  sagas: supportSagas,
}

export default support
