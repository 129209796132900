import {
  Color,
  RichTextAlignCenter,
  RichTextAlignJustify,
  RichTextAlignLeft,
  RichTextAlignRight,
  RichTextBold,
  RichTextBulletedList,
  RichTextCode,
  RichTextH1,
  RichTextH2,
  RichTextItalic,
  RichTextNumberedList,
  RichTextQuote,
  RichTextUnderlined,
} from 'modules/dna'
import { SvgProps } from 'modules/web-atoms'
import React from 'react'
import { Editor, Element, Transforms } from 'slate'
import { useSlate } from 'slate-react'

type Format =
  | 'bold'
  | 'italic'
  | 'underline'
  | 'code'
  | 'heading-one'
  | 'heading-two'
  | 'block-quote'
  | 'numbered-list'
  | 'bulleted-list'
  | 'left'
  | 'center'
  | 'right'
  | 'justify'

type FormatType = 'block' | 'mark'
type BlockType = 'align' | 'type'

const LIST_TYPES: Format[] = ['numbered-list', 'bulleted-list']
const TEXT_ALIGN_TYPES: Format[] = ['left', 'center', 'right', 'justify']

interface IToolbarButtonProps {
  format: Format
}

interface IToolbarButtonInfo {
  formatType: FormatType
  ToolbarIcon: React.FC<SvgProps>
}

interface IToolbarButtonHandlers {
  isActiveFormat: (editor: Editor, format: Format, blockType?: BlockType) => boolean
  toggleFormat: (editor: Editor, format: Format) => void
}

const isMarkActive = (editor: Editor, format: string) => {
  const marks = Editor.marks(editor)
  return marks ? marks[format] === true : false
}

const toggleMark = (editor: Editor, format: string) => {
  if (isMarkActive(editor, format)) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

const isBlockActive = (editor: Editor, format: Format, blockType: BlockType = 'type') => {
  const { selection } = editor
  if (!selection) return false

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n[blockType] === format,
    }),
  )

  return !!match
}

const toggleBlock = (editor: Editor, format: Format) => {
  const isActive = isBlockActive(editor, format, TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type')
  const isList = LIST_TYPES.includes(format)

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      Element.isElement(n) &&
      LIST_TYPES.includes(n.type as Format) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  })
  let newProperties: Partial<Element>
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    }
  } else {
    newProperties = {
      type: (isActive ? 'paragraph' : isList ? 'list-item' : format) as any,
    }
  }
  Transforms.setNodes<Element>(editor, newProperties)

  if (!isActive && isList) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block as any)
  }
}

const FORMAT_BUTTON_INFO_MAP = new Map<Format, IToolbarButtonInfo>([
  [
    'bold',
    {
      formatType: 'block',
      ToolbarIcon: RichTextBold,
    },
  ],
  [
    'italic',
    {
      formatType: 'mark',
      ToolbarIcon: RichTextItalic,
    },
  ],
  [
    'underline',
    {
      formatType: 'mark',
      ToolbarIcon: RichTextUnderlined,
    },
  ],
  [
    'code',
    {
      formatType: 'mark',
      ToolbarIcon: RichTextCode,
    },
  ],
  [
    'heading-one',
    {
      formatType: 'block',
      ToolbarIcon: RichTextH1,
    },
  ],
  [
    'heading-two',
    {
      formatType: 'block',
      ToolbarIcon: RichTextH2,
    },
  ],
  [
    'block-quote',
    {
      formatType: 'block',
      ToolbarIcon: RichTextQuote,
    },
  ],
  [
    'numbered-list',
    {
      formatType: 'block',
      ToolbarIcon: RichTextNumberedList,
    },
  ],
  [
    'bulleted-list',
    {
      formatType: 'block',
      ToolbarIcon: RichTextBulletedList,
    },
  ],
  [
    'left',
    {
      formatType: 'block',
      ToolbarIcon: RichTextAlignLeft,
    },
  ],
  [
    'right',
    {
      formatType: 'block',
      ToolbarIcon: RichTextAlignRight,
    },
  ],
  [
    'center',
    {
      formatType: 'block',
      ToolbarIcon: RichTextAlignCenter,
    },
  ],
  [
    'justify',
    {
      formatType: 'block',
      ToolbarIcon: RichTextAlignJustify,
    },
  ],
])

const FORMAT_TYPE_HANDLERS_MAP = new Map<FormatType, IToolbarButtonHandlers>([
  [
    'mark',
    {
      isActiveFormat: isMarkActive,
      toggleFormat: toggleMark,
    },
  ],
  [
    'block',
    {
      isActiveFormat: isBlockActive,
      toggleFormat: toggleBlock,
    },
  ],
])

const ToolbarButton: React.FC<IToolbarButtonProps & SvgProps> = ({ format, ...rest }) => {
  const editor = useSlate()

  const { formatType, ToolbarIcon } = FORMAT_BUTTON_INFO_MAP.get(format)!
  const { isActiveFormat, toggleFormat } = FORMAT_TYPE_HANDLERS_MAP.get(formatType)!

  const onClick = () => toggleFormat(editor, format)

  const fillColor = isActiveFormat(editor, format, TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type')
    ? Color.Black
    : Color.SecondaryDark

  return <ToolbarIcon role="button" width={18} height={18} fill={fillColor} onClick={onClick} {...rest} />
}

export default ToolbarButton
