import validatePhone from '../../validatePhone'
import { useTranslate } from 'modules/web-atoms'
import React from 'react'
import * as Yup from 'yup'

const useValidations = () => {
  const t = useTranslate('generic.validation')

  const validations = React.useMemo(
    () => ({
      password: Yup.string()
        .min(12, ({ min }) => t('password.min', { min }))
        .matches(/(?=.*\d)/, t('password.one-number'))
        .matches(/(?=.*[A-Z])/, t('password.one-upper'))
        .matches(/(?=.*[a-z])/, t('password.one-lower'))
        .matches(/.*[!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?].*/, t('password.one-special'))
        .required(t('missing')),
      phone: Yup.string().test('phone-validation', t('phone.invalid'), (value) => {
        if (value) return validatePhone(value)
        return true
      }),
      userEmail: Yup.string()
        .transform((v: string): string => v.replace(/\s/g, ''))
        .email(t('email.incorrect-format'))
        .required(t('missing')),
    }),
    [t],
  )

  return validations
}

export default useValidations
