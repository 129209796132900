export * from './screens'

import development from './development'
import reducer from './root-reducer'
import developmentsSagas from './sagas'

export const developments = {
  ...development,
  sagas: developmentsSagas,
  reducer: reducer,
}
export default developments
