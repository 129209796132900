import {
  Direction,
  EuiButtonEmpty,
  EuiCheckbox,
  EuiProgress,
  EuiTable,
  EuiTableHeader,
  EuiTableHeaderCell,
  EuiTableHeaderCellCheckbox,
  EuiTableHeaderMobile,
  EuiTableSortingType,
  EuiTableSortMobile,
  EuiTableSortMobileProps,
} from '@elastic/eui'
import { useTheme } from '@emotion/react'
import security, { Role } from 'modules/module-security'
import { IBatchTableSelection, isElement, useAppSelector } from 'modules/module-utils'
import { FlexGroup, FlexItem, Grid, Paragraph, useScreen, useTranslate } from 'modules/web-atoms'
import * as React from 'react'
import { TableBody } from './TableBody/TableBody'
import { IColumns, ITableItem, TableColumnType, TableItem } from './TableItem/TableItem'
import { TablePagination } from './TablePagination/TablePagination'
import TableSelection from './TableSelection/TableSelection'

const INTL_PREFIX = 'web-molecules.table'

export enum TableSelectionAction {
  SELECTED,
  DESELECTED,
  DESELECTED_PAGE,
  DESELECTED_ALL,
}

export type ITableSelectedChange<T extends string, Item extends ITableItem<T>> = (
  selection: Item[],
  action: TableSelectionAction,
) => void

export interface ITableSelection<T extends string, Item extends ITableItem<T>> {
  onSelectionChange?: ITableSelectedChange<T, Item>
  selectable?: (item: string) => boolean
}
export interface IPagination {
  pageIndex: number
  pageSize: number
  total: number
}

export interface IPaginationOptions {
  itemsPerPage: number[]
  onPageSizeChange: (pageSize: number) => void
  onPageIndexChange: (pageIndex: number) => void
}

export interface ISorting<Item> extends EuiTableSortingType<Item> {
  onSortChange: ((columnId: string, direction: Direction) => void) | undefined
}

export interface ITableProps<T extends string, Item extends ITableItem<T>> extends React.ComponentProps<typeof Grid> {
  tableId: string
  isCheckable: boolean
  columns: IColumns<T>
  items: Item[]
  onItemClicked?: (item: Item) => void
  selection: ITableSelection<T, Item>
  batchSelection?: IBatchTableSelection<Item>
  sorting: ISorting<Item>
  visibleColumns?: string[]
  pagination: IPagination
  paginationOptions: IPaginationOptions
  onRefresh?: () => void
  loading?: boolean
  selectedItems: string[]
}

export function Table<T extends string, Item extends ITableItem<T>>(
  props: ITableProps<T, Item>,
): React.ReactElement<ITableProps<T, Item>> {
  const {
    tableId,
    columns,
    items,
    onItemClicked,
    selection,
    batchSelection,
    sorting,
    isCheckable,
    pagination,
    paginationOptions,
    loading,
    visibleColumns,
    selectedItems,
    onRefresh,
    ...rest
  } = props

  const { isMobile } = useScreen()
  const t = useTranslate(INTL_PREFIX)
  const tCommons = useTranslate('commons')
  const userRole = useAppSelector(security.selectors.role)
  const theme = useTheme()

  const checkableItems: Item[] = React.useMemo(() => items.filter((item) => item.isCheckable !== false), [items])
  // For faster batch selection modifications
  const itemIdsMap: Record<string, Item> = React.useMemo(
    () =>
      items.reduce((map, item) => {
        map[item.id] = item
        return map
      }, {}),
    [items],
  )

  React.useEffect(() => {
    if (batchSelection?.isBatchSelectEnabled && batchSelection?.deselectedItems.length === pagination.total) {
      batchSelection.toggleBatchSelect(false)
    }
  }, [batchSelection, pagination.total])

  const areAllItemsSelected = React.useMemo(() => {
    if (batchSelection?.isBatchSelectEnabled) {
      return batchSelection.deselectedItems.filter((item) => !!itemIdsMap[item.id]).length === 0
    }
    return items.every((item) => {
      return item.isCheckable ? item.isChecked : true
    })
  }, [batchSelection?.isBatchSelectEnabled, batchSelection?.deselectedItems, items, itemIdsMap])

  const allColumns: IColumns<T> = React.useMemo(() => {
    let adjustedColumns: IColumns<T> = {
      ...columns,
    }
    // Filter out columns that should not be visible
    Object.keys(columns).forEach((colId) => {
      if (!!visibleColumns && !visibleColumns?.includes(colId as T)) delete adjustedColumns[colId as T]
    })
    const showCheckbox = userRole === Role.RECIPIENT ? false : isCheckable
    if (!showCheckbox) return adjustedColumns
    adjustedColumns = {
      checkbox: {
        label: '',
        type: TableColumnType.Checkbox,
        isSortable: false,
      },
      ...adjustedColumns,
    }
    return adjustedColumns
  }, [columns, isCheckable, userRole, visibleColumns])

  const onAllSelected = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const isChecked = event.target.checked
      if (isChecked) {
        if (batchSelection?.isBatchSelectEnabled) {
          batchSelection?.setDeselectedItems(batchSelection?.deselectedItems.filter((item) => !itemIdsMap[item.id]))
        } else {
          selection.onSelectionChange?.(checkableItems, TableSelectionAction.SELECTED)
        }
      } else {
        if (batchSelection?.isBatchSelectEnabled) {
          batchSelection.setDeselectedItems([...batchSelection.deselectedItems, ...items])
        } else {
          selection.onSelectionChange?.(items, TableSelectionAction.DESELECTED_PAGE)
        }
      }
    },
    [selection, checkableItems, batchSelection, itemIdsMap, items],
  )

  const onItemSelected = React.useCallback(
    (item: Item) => {
      if (batchSelection?.isBatchSelectEnabled) {
        const { deselectedItems, setDeselectedItems } = batchSelection
        if (deselectedItems.some((deselectedItem) => deselectedItem.id === item.id)) {
          setDeselectedItems(deselectedItems.filter((deselectedItem) => deselectedItem.id !== item.id))
        } else {
          setDeselectedItems([...deselectedItems, item])
        }
      } else {
        // If item is already selected then unselect
        if (selectedItems.find((selected) => selected === item.id)!!) {
          selection.onSelectionChange?.([item], TableSelectionAction.DESELECTED)
        } else {
          selection.onSelectionChange?.([item], TableSelectionAction.SELECTED)
        }
      }
    },
    [batchSelection, selectedItems, selection],
  )

  const rows = React.useMemo(() => {
    if (!items?.length) return []
    const rows: JSX.Element[] = []

    let firstItemIndex = 0
    let lastItemIndex = items.length - 1

    if (!!pagination && items.length >= pagination.pageSize * (pagination.pageIndex + 1)) {
      firstItemIndex = Math.max(pagination.pageSize * pagination.pageIndex, 0)
      lastItemIndex = Math.min(firstItemIndex + pagination.pageSize - 1, pagination.total - 1)
    }

    for (let itemIndex = firstItemIndex; itemIndex <= lastItemIndex; itemIndex++) {
      const item = items[itemIndex]
      const isChecked = batchSelection?.isBatchSelectEnabled
        ? !batchSelection.deselectedItems.some((deselected) => deselected.id === item.id)
        : !!selectedItems.filter((selectedItem) => selectedItem === item.id).length

      rows.push(
        <TableItem
          item={item}
          columns={allColumns}
          isChecked={isChecked}
          onSelectionChange={onItemSelected}
          isCheckboxDisabled={batchSelection?.isBatchSelectEnabled ? false : !item.isCheckable}
          onClick={onItemClicked}
        />,
      )
    }
    return rows
  }, [items, pagination, batchSelection, selectedItems, allColumns, onItemSelected, onItemClicked])

  const onSortChange = React.useCallback(
    (columnId: string) => {
      selection.onSelectionChange?.([], TableSelectionAction.DESELECTED_ALL)
      if (sorting.sort && sorting.sort.field == columnId) {
        sorting.onSortChange?.(columnId, sorting.sort.direction === 'asc' ? 'desc' : 'asc')
      } else {
        sorting.onSortChange?.(columnId, 'asc')
      }
    },
    [selection, sorting],
  )

  const onBatchSelect = () => {
    batchSelection?.toggleBatchSelect(true)
  }

  const onClearSelection = React.useCallback(() => {
    selection.onSelectionChange?.([], TableSelectionAction.DESELECTED_ALL)
    batchSelection?.toggleBatchSelect(false)
  }, [selection, batchSelection])

  const header = React.useMemo(() => {
    const childHeader = React.Children.toArray(props.children).filter(isElement(Header))
    if (!childHeader?.length) {
      const headers =
        !!allColumns &&
        Object.keys(allColumns)?.map((columnKey) => {
          const column = allColumns[columnKey]!!
          if (column.type == TableColumnType.Checkbox) {
            return (
              <EuiTableHeaderCellCheckbox key={columnKey} width={column.width}>
                <EuiCheckbox
                  id="selectAllCheckbox"
                  checked={areAllItemsSelected}
                  disabled={batchSelection?.isBatchSelectEnabled ? false : checkableItems.length === 0}
                  onChange={onAllSelected}
                  type={'inList'}
                  aria-label={t('check-all-items')}
                />
              </EuiTableHeaderCellCheckbox>
            )
          } else {
            return (
              <EuiTableHeaderCell
                key={columnKey}
                align={column.alignment}
                width={column.width}
                onSort={column.isSortable ? () => onSortChange(columnKey) : undefined}
                isSorted={sorting.sort && sorting.sort.field === columnKey}
                isSortAscending={sorting.sort && sorting.sort.direction === 'asc'}
                mobileOptions={column.mobileOptions}
              >
                {column.label}
              </EuiTableHeaderCell>
            )
          }
        })
      return <EuiTableHeader>{headers}</EuiTableHeader>
    }
    return childHeader
  }, [
    props.children,
    allColumns,
    areAllItemsSelected,
    batchSelection?.isBatchSelectEnabled,
    checkableItems.length,
    onAllSelected,
    t,
    sorting.sort,
    onSortChange,
  ])

  type mobileItems = EuiTableSortMobileProps['items']

  const mobileSortItems: mobileItems = React.useMemo(() => {
    return allColumns
      ? Object.keys(allColumns)
          .filter((columnKey) => {
            return allColumns[columnKey].type !== TableColumnType.Checkbox || allColumns[columnKey].isSortable
          })
          .map((columnKey) => {
            const column = allColumns[columnKey]
            return {
              name: column.label,
              key: column.id,
              onSort: column.isSortable ? () => onSortChange(columnKey) : undefined,
              isSorted: sorting.sort && sorting.sort.field === columnKey,
              isSortAscending: sorting.sort && sorting.sort.direction === 'asc',
            }
          })
      : undefined
  }, [allColumns, onSortChange, sorting.sort])

  const mobileHeader = React.useMemo(() => {
    const childMobileHeader = React.Children.toArray(props.children).filter(isElement(MobileHeader))
    if (!childMobileHeader?.length) {
      return (
        <EuiTableHeaderMobile>
          <FlexGroup responsive={false} justifyContent="spaceBetween" alignItems="baseline">
            {isCheckable && (
              <FlexItem grow={false}>
                <EuiCheckbox
                  id="selectAllCheckboxMobile"
                  aria-label={t('select-all-rows')}
                  checked={areAllItemsSelected}
                  onChange={onAllSelected}
                  type={undefined}
                />
              </FlexItem>
            )}
            <FlexItem grow={false}>
              <EuiTableSortMobile items={mobileSortItems} />
            </FlexItem>
          </FlexGroup>
        </EuiTableHeaderMobile>
      )
    }
    return childMobileHeader
  }, [props.children, areAllItemsSelected, onAllSelected, mobileSortItems, isCheckable, t])

  const body = React.useMemo(() => {
    const childBody = React.Children.toArray(props.children).filter(isElement(Body))
    if (!childBody?.length) {
      return <TableBody>{rows}</TableBody>
    }
    return childBody
  }, [props.children, rows])

  const footer = React.Children.toArray(props.children).filter(isElement(Footer))

  React.useEffect(() => {
    const container = document?.getElementById('table-container')
    if (container!!) {
      container.scrollTop = 0
    }
  }, [pagination])

  const numSelected = batchSelection?.isBatchSelectEnabled
    ? pagination.total - batchSelection.deselectedItems.length
    : selectedItems.length
  return (
    <Grid
      justifyContent={!isMobile ? 'space-between' : undefined}
      height="100%"
      flex="1 1 auto"
      minHeight={0}
      gutterSize="none"
      {...rest}
    >
      <Grid>
        <Grid.Col>
          <TableSelection
            pagination={pagination}
            isSelectionEnabled={!!batchSelection}
            numSelected={numSelected}
            onSelectAll={onBatchSelect}
            onClear={onClearSelection}
          />
        </Grid.Col>
        <Grid.Col justifyContent="center">
          {onRefresh && (
            <EuiButtonEmpty
              style={{ height: 'fit-content' }}
              disabled={loading}
              iconType="refresh"
              color="primary"
              onClick={onRefresh}
            >
              <Paragraph size="small" color="primaryDark">
                {tCommons('action.refresh')}
              </Paragraph>
            </EuiButtonEmpty>
          )}
        </Grid.Col>
      </Grid>

      <Grid
        id={'table-container'}
        className="eui-yScroll"
        flexGrow={1}
        minHeight={0}
        maxHeight={!isMobile ? '90%' : undefined}
      >
        {mobileHeader}
        <EuiTable id={tableId}>
          {header}
          {body}
          {footer}
        </EuiTable>
      </Grid>
      {loading && (
        <Grid width="100%" mb="small">
          <EuiProgress size="xs" color={theme.colors.primaryMid} />
        </Grid>
      )}
      {pagination && paginationOptions && (
        <TablePagination
          aria-label={`${tableId} pagination`}
          aria-controls={tableId}
          activePage={pagination.pageIndex}
          itemsPerPage={pagination.pageSize}
          itemsPerPageOptions={paginationOptions.itemsPerPage}
          pageCount={Math.max(Math.ceil(pagination.total / pagination.pageSize), 0)}
          onChangeItemsPerPage={paginationOptions.onPageSizeChange}
          onChangePage={paginationOptions.onPageIndexChange}
        />
      )}
    </Grid>
  )
}

const MobileHeader: React.FunctionComponent = ({ children }) => <>{children}</>
const Header: React.FunctionComponent = ({ children }) => <>{children}</>
const Body: React.FunctionComponent = ({ children }) => <>{children}</>
const Footer: React.FunctionComponent = ({ children }) => <>{children}</>

export interface ITable<T extends string, Item extends ITableItem<T>>
  extends React.MemoExoticComponent<React.ComponentType<ITableProps<T, Item>>> {
  MobileHeader: typeof MobileHeader
  Header: typeof Header
  Body: typeof Body
  Footer: typeof Footer
}

//TODO: fix typing
function table<T extends string, Item extends ITableItem<T>>(): ITable<T, Item> {
  return Object.assign(React.memo(Table as ITable<T, Item>), {
    type: Table,
    MobileHeader,
    Header,
    Body,
    Footer,
  })
}

export default React.memo(Table) as typeof Table
