import { SagaIterator, Task } from 'redux-saga'
import { all, spawn, takeLatest } from 'redux-saga/effects'

import autobind from 'autobind-decorator'

import recover, { ISuperviseResult } from '../effects/supervise/recover'
import { ISagaSuite, ISupervisorParams, Supervisor } from '../model'

export abstract class SupervisorSuite implements ISagaSuite {
  abstract start(): SagaIterator<void>

  static SUPERVISORS: Supervisor[] = []

  constructor(private readonly supervisors: Supervisor[] = SupervisorSuite.SUPERVISORS) {}

  @autobind
  *supervise(): SagaIterator {
    const { parent, children: tasks }: ISuperviseResult = yield recover(this.start)

    yield takeLatest(tasks, this.notifySupervisors, { parent })
  }

  @autobind
  *notifySupervisors(params: ISupervisorParams, task: Task): SagaIterator {
    yield all(this.supervisors.map((supervisor) => spawn(supervisor, task, params)))
  }
}

export default SupervisorSuite
