import { FormikConfig, useFormik } from 'formik'
import { strings, useAppDispatch, useAppSelector } from 'modules/module-utils'
import { Form, IGridProps, useToggle, useTranslate } from 'modules/web-atoms'
import { ComboBox, DeleteConfirmationModal, FieldText, FormRow } from 'modules/web-molecules'
import { TableForm, TableFormTemplate } from 'modules/web-templates'
import * as React from 'react'
import * as Yup from 'yup'
import development, { FormState } from '../../../development'
import useBuilding from '../../../hooks/use-building/use-building'
import useSelectableUsers from '../../../hooks/use-selected-users/use-selected-users'

export interface IFormValues {
  name: string
  address: string
  city: string
  postcode: string
}

const INTL_PREFIX_MODAL = 'buildings-directory.delete-modal'

export interface IBuildingFormsProps extends IGridProps {}

export const BuildingForm: React.FC<IBuildingFormsProps> = (props) => {
  const { ...rest } = props

  const t = useTranslate('buildings-directory.form')
  const dispatch = useAppDispatch()
  const [isDeleteOpen, toggleDeletion] = useToggle()

  const { canEditBuildings, canRemoveBuildings, canAddBuildings } = useAppSelector(development.selectors.permissions)
  const formState = useAppSelector(development.selectors.formState)
  const isUpdating = useAppSelector(development.selectors.isUpdating)
  const isDeleting = useAppSelector(development.selectors.isDeletingBuilding)
  const selectedBuilding = useAppSelector(development.selectors.selectedBuilding)
  const submittedForm = useAppSelector(development.selectors.submittedForm)

  const { building, error, isFetching } = useBuilding(selectedBuilding)
  const {
    allUsers,
    options,
    selectedUsers,
    usersError,
    isFetchingAllUsers,
    isFetchingAssignedUsers,
    onUserSelection,
    setSelectedUsers,
  } = useSelectableUsers(selectedBuilding)

  const onSubmit: FormikConfig<IFormValues>['onSubmit'] = React.useCallback(
    (values) => {
      const users = selectedUsers
        .map((selectedUser) => allUsers.find((u) => u.id === selectedUser.value)?.email)
        .filter((userEmail) => !!userEmail) as string[]

      if (!!selectedBuilding) {
        dispatch(
          development.actions.updateBuilding({
            id: selectedBuilding,
            name: values.name,
            address: values.address,
            city: values.city,
            postcode: values.postcode,
            users,
          }),
        )
      } else {
        dispatch(
          development.actions.addBuilding({
            name: values.name,
            address: values.address,
            city: values.city,
            postcode: values.postcode,
            users,
          }),
        )
      }
    },
    [allUsers, selectedBuilding, dispatch, selectedUsers],
  )

  const validationSchema = React.useMemo(
    () =>
      Yup.object<IFormValues>().shape({
        name: Yup.string()
          .transform((v: string): string => v.trim())
          .min(3, ({ min }) => t('validation.min', { min }))
          .max(100, ({ max }) => t('validation.max', { max }))
          .required(t('validation.required')),
        address: Yup.string()
          .transform((v: string): string => v.trim())
          .min(1, ({ min }) => t('validation.min', { min }))
          .max(100, ({ max }) => t('validation.max', { max })),
        city: Yup.string()
          .transform((v: string): string => v.trim())
          .min(1, ({ min }) => t('validation.min', { min }))
          .max(100, ({ max }) => t('validation.max', { max })),
        postcode: Yup.string()
          .transform((v: string): string => v.trim())
          .min(1, ({ min }) => t('validation.min', { min }))
          .max(100, ({ max }) => t('validation.max', { max })),
      }),
    [t],
  )

  const formik = useFormik<IFormValues>({
    initialValues: {
      name: '',
      address: '',
      city: '',
      postcode: '',
    },
    onSubmit: onSubmit,
    validationSchema,
  })
  const { values, resetForm, touched, errors, setFieldValue, submitForm, handleSubmit, setValues } = formik

  React.useEffect(() => {
    if (submittedForm) {
      resetForm()
      setSelectedUsers([])
    }
  }, [submittedForm])

  const customHandleChange = React.useCallback(
    (e: React.ChangeEvent<any>) => {
      const { value, name } = e.target
      void setFieldValue(name, value)
    },
    [setFieldValue],
  )

  const onDelete = React.useCallback(() => {
    dispatch(development.actions.deleteBuilding())
    toggleDeletion()
  }, [dispatch, toggleDeletion])

  const onCancel = React.useCallback(() => {
    void setValues((values) => ({
      ...values,
      name: '',
      address: '',
      city: '',
      postcode: '',
    }))
    setSelectedUsers([])
    dispatch(development.actions.cancelBuildingForm())
  }, [dispatch, setSelectedUsers, setValues])

  React.useEffect(() => {
    void setValues((values) => ({
      ...values,
      name: strings.capitalize(building?.buildingName) || '',
      address: strings.capitalize(building?.addressLine1) || '',
      city: strings.capitalize(building?.city) || '',
      postcode: strings.capitalize(building?.postcode) || '',
    }))
  }, [building, setValues])

  const deleteOptions: TableForm.IDeleteOptions = React.useMemo(() => {
    return {
      value: t('delete'),
      loading: isDeleting,
      isDeleteModalOpen: isDeleteOpen,
      onClick: toggleDeletion,
    }
  }, [isDeleteOpen, isDeleting, t, toggleDeletion])

  const heading = formState === FormState.CREATING ? t('create.heading') : strings.capitalize(building?.buildingName)
  const subheading = t('create.subheading')

  const formType: TableForm.FormType =
    formState === FormState.CREATING ? TableForm.FormType.CREATE : TableForm.FormType.EDIT
  const permissions: TableForm.IPermissions = {
    canDelete: canRemoveBuildings,
    canEdit: canEditBuildings,
    canAdd: !!canAddBuildings,
  }

  return (
    <TableFormTemplate
      heading={heading}
      subheading={subheading}
      cancelValue={t('cancel')}
      saveValue={t('save')}
      createValue={t('create')}
      formType={formType}
      deleteOptions={deleteOptions}
      permissions={permissions}
      onSubmit={submitForm}
      onCancel={onCancel}
      loading={isUpdating}
      isFetching={isFetching}
    >
      <TableFormTemplate.Form>
        <Form component="form" paddingX="none" py="large" onSubmit={handleSubmit}>
          <FormRow label={t('name')} touched={touched.name} error={errors.name} inputMode="text">
            <FieldText
              name="name"
              value={values.name}
              onChange={customHandleChange}
              disabled={!canEditBuildings}
              autoFocus={canEditBuildings}
            />
          </FormRow>
          <FormRow
            label={t('address')}
            touched={touched.address}
            error={errors.address}
            inputMode="text"
            marginTop="small"
          >
            <FieldText
              name="address"
              value={values.address}
              onChange={customHandleChange}
              disabled={!canEditBuildings}
            />
          </FormRow>
          <FormRow label={t('city')} touched={touched.city} error={errors.city} inputMode="text" mt="small">
            <FieldText name="city" value={values.city} onChange={customHandleChange} disabled={!canEditBuildings} />
          </FormRow>
          <FormRow
            label={t('postcode')}
            touched={touched.postcode}
            error={errors.postcode}
            inputMode="text"
            marginTop="small"
          >
            <FieldText
              name="postcode"
              value={values.postcode}
              onChange={customHandleChange}
              disabled={!canEditBuildings}
            />
          </FormRow>
          <FormRow label={t('users')} marginTop="small">
            <ComboBox
              placeholder={t('users.hint')}
              options={options}
              width="100%"
              aria-label={t('users')}
              selectedOptions={selectedUsers}
              onChange={onUserSelection}
              isLoading={isFetchingAllUsers || isFetchingAssignedUsers}
              isDisabled={!canEditBuildings}
            />
          </FormRow>
        </Form>
      </TableFormTemplate.Form>

      <TableFormTemplate.DeleteModal>
        <DeleteConfirmationModal
          onClose={toggleDeletion}
          onDelete={onDelete}
          itemSize={1}
          intlPrefix={INTL_PREFIX_MODAL}
        />
      </TableFormTemplate.DeleteModal>
    </TableFormTemplate>
  )
}

export default React.memo(BuildingForm)
