import { ThemeProvider } from '@emotion/react'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { lightTheme } from 'modules/dna'
import { Toasts } from 'modules/module-alerts'
import queryClient from 'modules/module-api/query/client'
import { DeliveriesPath } from 'modules/module-deliveries'
import { ConnectedRouterProvider } from 'modules/module-navigation/connected-router'
import authentication, { SignupStep } from 'modules/module-onboarding/authentication'
import security, { KEY_ROLE, refreshTokenStore, Role, userIdStore } from 'modules/module-security'
import { ZendeskChat } from 'modules/module-support'
import { ZENDESK_KEY } from 'modules/utils/constants'
import moment from 'moment'
import 'moment-timezone'
import 'moment/min/locales'
import * as React from 'react'
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux'
import { Redirect, Switch } from 'react-router-dom'
import { OpenPath, PortalPath } from './routes/models'
import AuthenticatedRoute from './routes/route/authenticated-route'
import UnauthenticatedRoute from './routes/route/unauthenticated-route'

export interface IAppDispatchProps {}

export interface IAppStateProps {
  role?: Role
  showOnboardingModal: boolean
  isAuthenticated?: boolean
  signupStep: SignupStep | null
}
export type IAppProps = IAppDispatchProps & IAppStateProps

export const App: React.FC<IAppProps> = (props) => {
  const { isAuthenticated, role, showOnboardingModal, signupStep } = props

  const [hasTokens, setHasTokens] = React.useState(true)
  const [currentRole, setCurrentRole] = React.useState<string | null>(localStorage.getItem(KEY_ROLE))

  React.useEffect(() => {
    const checkTokens = async () => {
      const exists = (await refreshTokenStore.exists()) && (await userIdStore.exists())
      setHasTokens(exists)
    }
    void checkTokens()
  }, [])

  React.useEffect(() => {
    setCurrentRole(localStorage.getItem(KEY_ROLE))
  }, [])

  React.useEffect(() => {
    const locale = navigator.language
      ? navigator.language
      : navigator.languages && navigator.languages.length
      ? navigator.languages[0]
      : 'en'
    moment.locale(locale)
  }, [])

  return (
    <ConnectedRouterProvider>
      <ThemeProvider theme={lightTheme}>
        <QueryClientProvider client={queryClient}>
          <React.Suspense fallback="">
            <Switch>
              <UnauthenticatedRoute isAuthenticated={!!isAuthenticated} role={role} path="/auth" />
              <AuthenticatedRoute
                isAuthenticated={!!isAuthenticated || (!!signupStep && hasTokens)}
                showOnboardingModal={showOnboardingModal}
                path="/portal"
              />
              {!hasTokens && <Redirect from="*" to={OpenPath.LOGIN} />}
              {currentRole && (
                <Redirect
                  from="*"
                  to={currentRole == Role.USER ? PortalPath.GET_STARTED : DeliveriesPath.INBOUND_DIRECTORY}
                />
              )}
            </Switch>
            <Toasts />
            <ReactQueryDevtools initialIsOpen={false} />
            <ZendeskChat defer zendeskKey={ZENDESK_KEY ?? ''} />
          </React.Suspense>
        </QueryClientProvider>
      </ThemeProvider>
    </ConnectedRouterProvider>
  )
}

const mapStateToProps: MapStateToProps<IAppStateProps, {}, any> = (state) => ({
  isAuthenticated: security.selectors.isAuthenticated(state),
  role: security.selectors.role(state),
  showOnboardingModal: security.selectors.showOnboardingModal(state),
  signupStep: authentication.selectors.signupStep(state),
})

const mapDispatchToProps: MapDispatchToProps<IAppDispatchProps, {}> = (dispatch, _) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(App)
