import { Role } from 'modules/module-security'
import { SelectAccountDto } from '../api/dto'

export enum LoginMode {
  Password = 'password',
  SAML = 'saml',
  OIDC = 'oidc',
}

export enum VerificationError {
  Invalid = 'invalid',
  Locked = 'locked',
  Exists = 'exists',
  Throttle = 'throttle',
  Unknown = 'unknown',
}

export enum SignupStep {
  AccountDetails = 'ACCOUNT_DETAILS',
  AccountType = 'ACCOUNT_TYPE',
  ConfigureBuilding = 'CONFIGURE_BUILDING',
}

export enum LoginStep {
  AccountDetails = 'account-details',
  SelectAccount = 'select-account',
  Loading = 'loading',
}

export interface ISetupBuildingPayload {
  companyName: string
  buildingName: string
  industry: string
  referrerName: string
  phone: string
}

export interface IAuthenticationState {
  signupStep?: SignupStep
  loginStep?: LoginStep
  loginMode?: LoginMode
  accountList: SelectAccountDto[]
  error?: VerificationError
  loading: boolean
}

export const initialState: IAuthenticationState = {
  signupStep: SignupStep.AccountDetails,
  loginStep: LoginStep.AccountDetails,
  accountList: [],
  error: undefined,
  loading: false,
}
export interface ILoginPayload {
  email: string
  password?: string
  rememberMe: boolean
  requiresResolve: boolean
  preHashed: boolean
  accessToken?: string
}

export interface ISignupPayload {
  firstName: string
  lastName: string
  email: string
  password: string
  referrer: string | null
  regionId: string
}

export interface IGetLoginModePayload {
  email: string
}

export interface IUserSetupRequestPayload {
  email: string
  password: string
  token: string
  serverId: string
}

export interface IFinishSignupPayload {
  clientId: number
  companyName: string
  buildingName: string
}

export interface ISignedUpPayload {
  id: number
  name: string
  email: string
  password: string
  addressLine1: string | null
  addressLine2: string | null
  postcode: string | null
  city: string | null
  country: string | null
  recordModified: string
}

export enum AccountType {
  PERSONAL = 'PERSONAL',
  MAILROOM = 'MAILROOM',
  TRACK_INTERNAL = 'TRACK_INTERNAL',
  ECOMMERCE = 'ECOMMERCE',
  FREIGHT_FORWARDING = 'FREIGHT_FORWARDING',
  PO_RECONCILIATION = 'PO_RECONCILIATION',
}

export interface IAccountTypePayload {
  accountType: AccountType
}
export interface IOnboardingStagePayload {
  onboardingStage: string
}

export interface ISendAppLinkPayload {
  number: string
}
export interface ILoggedInPayload {
  username: string
  accessToken: string
  refreshToken: string
  userId: string
  role: Role
}

export interface IStoreTokensPayload {
  username: string
  accessToken: string
  refreshToken: string
  userId: string
  role: Role
}
