/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, useTheme } from '@emotion/react'
import { EuiLink, EuiPopover, htmlIdGenerator } from '@elastic/eui'
import { ResponsiveColor } from 'modules/dna'
import { PortalPath } from 'modules/module-core/routes/models'
import security from 'modules/module-security'
import { strings, useAppDispatch, useAppSelector } from 'modules/module-utils'
import { Button, Grid, H3, H6, IGridProps, Link, Paragraph, useHover, useToggle, useTranslate } from 'modules/web-atoms'
import { Avatar } from 'modules/web-molecules'
import * as React from 'react'

export interface IProfileMenuItem extends IGridProps {
  isMinimized: boolean
}

export const ProfileMenuItem: React.FC<IProfileMenuItem> = (props) => {
  const { isMinimized, children, ...rest } = props

  const t = useTranslate('core.header.user')
  const dispatch = useAppDispatch()
  const theme = useTheme()

  const firstName = useAppSelector(security.selectors.firstName)
  const lastName = useAppSelector(security.selectors.lastName)
  const email = useAppSelector(security.selectors.email)

  const [isOpen, toggle] = useToggle()

  const id = React.useMemo(() => htmlIdGenerator()(), [])
  const avatarColor: ResponsiveColor = React.useMemo(() => {
    return !!firstName ? 'primaryMid' : 'infoLight'
  }, [firstName])

  const onSignOut = React.useCallback(() => {
    toggle()
    dispatch(security.actions.end())
  }, [dispatch, toggle])

  const [isHovered, hoverEventHandlers] = useHover<HTMLButtonElement>()

  const backgroundColor: ResponsiveColor = React.useMemo(() => {
    switch (true) {
      case !!isHovered:
      case isOpen:
        return 'secondaryMid'
      default:
        return 'none'
    }
  }, [isHovered, isOpen])

  const menuItem = React.useMemo(
    () => {
      const firstChar = firstName ? strings.capitalize(firstName)[0] : ""
      const secondChar = lastName ? strings.capitalize(lastName)[0] : ""

      return (<Button.Frame
        label={`${t('label')}: ${firstChar}${secondChar} ${firstName} ${lastName} ${email}`}
        py="smallest"
        onClick={toggle}
        width={!isMinimized ? 205 : undefined}
        borderRadius="small"
        backgroundColor={backgroundColor}
        padding="smallest"
        css={{
          ':focus-visible': {
            backgroundColor: theme.colors.secondaryMid,
            p: {
              color: theme.colors.primaryMid,
              textDecorationLine: 'underline',
            },
          },
        }}
        {...hoverEventHandlers}
      >
        <Grid>
          <Grid.Col justifyContent="center">
            <Avatar backgroundColor={avatarColor} firstChar={firstName} secondChar={lastName} fontColor="textLight" />
          </Grid.Col>
          {!isMinimized && (
            <Grid.Col marginLeft="macro">
              <Grid alignItems="flex-start" justifyContent="center" gutterSize="none">
                <H3 textAlign="start" fontSize="small" fontWeight="bold" truncated={true}>
                  {firstName} {lastName}
                </H3>
                <Paragraph
                  textOverflow="ellipsis"
                  lineHeight="smallest"
                  textAlign="start"
                  size="medium"
                  color="textDark"
                  truncated={true}
                  maxWidth="120px"
                >
                  {email}
                </Paragraph>
              </Grid>
            </Grid.Col>
          )}
        </Grid>
      </Button.Frame>)
    },
    [
      t,
      toggle,
      isMinimized,
      backgroundColor,
      theme.colors.secondaryMid,
      theme.colors.primaryMid,
      hoverEventHandlers,
      avatarColor,
      firstName,
      lastName,
      email,
    ],
  )

  return (
    <Grid {...rest}>
      <EuiPopover
        id={id}
        button={menuItem}
        isOpen={isOpen}
        anchorPosition="rightDown"
        closePopover={toggle}
        panelPaddingSize="none"
        hasArrow={false}
        offset={isMinimized ? 15 : 35}
        repositionOnScroll={true}
        panelStyle={{ borderRadius: theme.radii.small }}
      >
        <Grid flex={1} width={320} alignItems="center" padding="small" borderRadius="medium">
          <Grid.Col>
            <Avatar
              backgroundColor="primaryMid"
              firstChar={firstName}
              secondChar={lastName}
              fontColor="textLight"
              size="xl"
            />
          </Grid.Col>
          <Grid.Col marginLeft="small" marginRight="medium">
            <Grid>
              <H6 color="textDark" truncated>
                {t('name', { firstName: strings.capitalize(firstName), lastName: strings.capitalize(lastName) })}
              </H6>
              <Grid>
                <Grid.Col>
                  <Paragraph size="medium" title={t('edit')} truncated>
                    <Link to={PortalPath.PROFILE} onClickCapture={toggle}>
                      {t('edit')}
                    </Link>
                  </Paragraph>
                </Grid.Col>
                <Grid.Col marginLeft="small">
                  <Paragraph size="medium" title={t('logout')} truncated>
                    <EuiLink onClick={onSignOut}>{t('logout')}</EuiLink>
                  </Paragraph>
                </Grid.Col>
              </Grid>
            </Grid>
          </Grid.Col>
        </Grid>
      </EuiPopover>
    </Grid>
  )
}

export default React.memo(ProfileMenuItem)
