import * as React from 'react'

import StyledView, { StyledViewProps } from '../StyledView'

export const GridCol: React.FunctionComponent<StyledViewProps> = ({ children, ...rest }) => (
  <StyledView flexDirection="column" display="flex" {...rest}>
    {children}
  </StyledView>
)

GridCol.displayName = 'Grid.Col'

export default GridCol
