import { AxiosResponse } from 'axios'
import qs from 'qs'
import API from './clients'
import { IUserDevelopmentClientDto } from './dto/dto'

export enum GRANT_TYPE {
  PASSWORD = 'password',
  REFRESH = 'refresh_token',
}

export interface ISignupRequestBody {
  firstName: string
  lastName: string
  email: string
  password: string
  referrer: string | null
}

export interface IAddClientDetailsRequestBody {
  ClientId: number
  CompanyName: string
  BuildingName: string
  Industry: string
  JobTitle?: string
  AddressLine1?: string
  AddressLine2?: string
  Postcode?: string
  City?: string
  Country?: string
}
export interface ISetAccountTypeRequestBody {
  AccountType: string
}
export interface ISetOnboardingStateRequestBody {
  OnboardingStage: string
}

export interface ISendAppDownloadLinkRequestBody {
  Number: string
}

export interface IRefreshRequestBody {
  email: string
  refresh_token: string
  grant_type: GRANT_TYPE
}

export interface IRefreshResponseBody {
  access_token: string
  refresh_token: string
  token_type: string
  expires_in: number
}

export async function signupUser(data: ISignupRequestBody) {
  return API({
    method: 'post',
    url: '/v3/portal/signup',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    data: qs.stringify(data),
  })
}

export async function addClientDetails(data) {
  return API({
    method: 'patch',
    url: `/v3/portal/add-client-details/${data.ClientId}`,
    data,
  })
}

export async function setAccountType(data: ISetAccountTypeRequestBody) {
  return API({
    method: 'post',
    url: '/v3/portal/signup/set-account-type',
    data: data,
  })
}

export async function setOnboardingStage(data: ISetOnboardingStateRequestBody) {
  return API({
    method: 'post',
    url: '/v3/portal/signup/set-onboarding-stage',
    data: data,
  })
}

export async function sendAppDownloadLink(data: ISendAppDownloadLinkRequestBody) {
  return API({
    method: 'post',
    url: '/v3/portal/signup/send-app-download-link',
    data: data,
  })
}

export async function checkUserLoggedIn() {
  return API({
    method: 'get',
    url: '/v3/portal/signup/check-user-logged-in',
  })
}

export async function checkUserScannedParcel() {
  return API({
    method: 'get',
    url: '/v3/portal/signup/check-user-scanned-parcel',
  })
}

export async function getCurrentUser(): Promise<AxiosResponse<IUserDevelopmentClientDto>> {
  return API({
    method: 'get',
    url: '/v3/current_user',
    params: { include: '*' },
  })
}
