/* eslint-disable react/display-name */
import { ActiveBuilding } from 'modules/dna'
import { SortDirection } from 'modules/module-api'
import security from 'modules/module-security'
import {
  IUsePaginatedTableParams,
  useActiveBuildings,
  useAppSelector,
  useBuildings,
  usePaginatedTable,
} from 'modules/module-utils'
import { SortField as BuildingsSortField } from 'modules/module-utils/hooks/use-buildings/use-buildings'
import { Image, Paragraph, useTranslate } from 'modules/web-atoms'
import {
  IColumns,
  IPagination,
  ITableItem,
  ITableSelectedChange,
  TableColumn,
  TableColumnType,
  TableSelectionAction,
} from 'modules/web-molecules'
import * as React from 'react'
import { useDispatch } from 'react-redux'
import development, { FormState } from '../../development'
import permissions from '../../permissions/Permissions'

export type IBuildingsTableColumns = BuildingsSortField | 'active'

export type IBuildingTableItem = ITableItem<IBuildingsTableColumns>

export const useBuildingsTable = () => {
  const t = useTranslate('buildings-directory.table')
  const dispatch = useDispatch()

  const currentUserType = useAppSelector(security.selectors.type)
  const selectedItem = useAppSelector(development.selectors.selectedBuilding)
  const selectedItems = useAppSelector(development.selectors.selectedBuildings)
  const { canEditBuildings } = useAppSelector(development.selectors.permissions)

  const onItemSelected = React.useCallback(
    (item: IBuildingTableItem) => {
      if (selectedItem == item.id) {
        dispatch(development.actions.setSelectedBuilding({ building: undefined }))
        dispatch(development.actions.setFormState(FormState.HIDDEN))
      } else {
        if (canEditBuildings) {
          dispatch(development.actions.setSelectedBuilding({ building: item.id }))
          dispatch(development.actions.setFormState(FormState.EDITING))
        }
      }
    },
    [dispatch, canEditBuildings, selectedItem],
  )

  const onSortChange = React.useCallback(() => {
    dispatch(development.actions.setSelectedBuilding({ building: undefined }))
  }, [dispatch])

  const onSelectionChange: ITableSelectedChange<IBuildingsTableColumns, IBuildingTableItem> = React.useCallback(
    (items: IBuildingTableItem[], action: TableSelectionAction) => {
      dispatch(development.actions.setSelectedBuildings({ items: items.map((item) => item.id), action }))
    },
    [dispatch],
  )

  const paginationParams: IUsePaginatedTableParams<
    IBuildingsTableColumns,
    IBuildingTableItem,
    BuildingsSortField,
    typeof BuildingsSortField
  > = React.useMemo(() => {
    return {
      sortClass: BuildingsSortField,
      pageParams: {
        page: 0,
        pageSize: 20,
        sortBy: BuildingsSortField.NAME,
        sort: SortDirection.ASC,
        filter: '',
      },
      itemsPerPage: [10, 20, 50],
      onSortChange,
      onSelectionChange,
      onItemClicked: onItemSelected,
    }
  }, [onSelectionChange, onItemSelected, onSortChange])

  const { paging, paginationOptions, selection, sort, onSearch } = usePaginatedTable<
    IBuildingsTableColumns,
    IBuildingTableItem,
    BuildingsSortField,
    typeof BuildingsSortField
  >(paginationParams)

  const { buildings, isFetching, totalItems, error, refetch } = useBuildings({
    page: paging.page + 1,
    sortBy: paging.sortBy,
    sort: paging.sort,
    pageSize: paging.pageSize,
    filter: paging.filter,
  })

  const onRefresh = () => {
    if (!isFetching) {
      void refetch()
    }
  }

  const { activeBuildings, isFetching: isFetchActiveUsers, error: errorActiveUsers } = useActiveBuildings()

  const pagination: IPagination = React.useMemo(() => {
    return {
      pageIndex: paging.page,
      pageSize: paging.pageSize,
      total: totalItems,
    }
  }, [paging.page, paging.pageSize, totalItems])

  const columns: IColumns<IBuildingsTableColumns> = React.useMemo(() => {
    return {
      [BuildingsSortField.NAME]: {
        label: <TableColumn>{t('column.name')}</TableColumn>,
        type: TableColumnType.Column,
        isSortable: true,
      },
      [BuildingsSortField.ADDRESS]: {
        label: <TableColumn>{t('column.address')}</TableColumn>,
        type: TableColumnType.Column,
        isSortable: true,
      },
      [BuildingsSortField.CITY]: {
        label: <TableColumn>{t('column.location')}</TableColumn>,
        type: TableColumnType.Column,
        isSortable: true,
      },
      [BuildingsSortField.SIZE]: {
        label: <TableColumn>{t('column.size')}</TableColumn>,
        type: TableColumnType.Column,
        isSortable: true,
      },
      active: {
        label: <TableColumn>{t('column.active')}</TableColumn>,
        type: TableColumnType.Column,
        isSortable: false,
      },
    }
  }, [t])

  const items: IBuildingTableItem[] = React.useMemo(() => {
    const activeBuildingsIds = activeBuildings.map((building) => building.id.toString())
    return buildings
      ? buildings.map((building): IBuildingTableItem => {
          const id = building.id.toString()
          return {
            id: id,
            isSelectable: true,
            isChecked: !!selectedItems.find((itemId) => itemId === id),
            isSelected: !!selectedItem && id == selectedItem,
            isCheckable: !!currentUserType && permissions.canRemoveBuilding.includes(currentUserType),
            [BuildingsSortField.NAME]: {
              render: () => (
                <Paragraph
                  title={building.buildingName}
                  fontWeight="bold"
                  size="medium"
                  padding="smallest"
                  truncated={true}
                >
                  {building.buildingName}
                </Paragraph>
              ),
            },
            [BuildingsSortField.ADDRESS]: {
              render: () => (
                <Paragraph title={building.addressLine1} padding="smallest" truncated={true}>
                  {building.addressLine1}
                </Paragraph>
              ),
            },
            [BuildingsSortField.CITY]: {
              render: () => (
                <Paragraph title={building.city} padding="smallest" truncated={true}>
                  {building.city}
                </Paragraph>
              ),
            },
            [BuildingsSortField.SIZE]: {
              render: () => (
                <Paragraph title={`${building.tenantsCount}`} padding="smallest" truncated={true}>
                  {building.tenantsCount}
                </Paragraph>
              ),
            },
            active: {
              render: () => {
                return activeBuildingsIds.includes(id) ? (
                  <Paragraph>
                    <Image src={ActiveBuilding} />
                  </Paragraph>
                ) : (
                  <></>
                )
              },
            },
          }
        })
      : []
  }, [activeBuildings, buildings, currentUserType, selectedItem, selectedItems])

  return {
    columns: columns,
    items,
    selection,
    onSearch,
    onItemClicked: onItemSelected,
    isFetching,
    error,
    pagination,
    sort,
    paginationOptions,
    totalBuildings: totalItems,
    selectedItems,
    onRefresh,
  }
}

export default useBuildingsTable
