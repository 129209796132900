import { IRefreshTokenDto, IRequestTokenDto } from 'api'
import { AuthAPI } from 'api/clients'
import { instanceToPlain, plainToClass } from 'class-transformer'
import http from 'modules/http'
import tokenService from 'modules/module-security/tokens/TokenService'
import { OpenPath } from '../routes/models'

export const refresh = async (token: string, userId: string) => {
  const data = new IRefreshTokenDto()
  data.refreshToken = token
  data.userId = userId

  try {
    const response = await AuthAPI({
      method: 'POST',
      url: `auth/token/refresh/`,
      headers: { 'cache-control': 'no-cache, no-store, max-age=0, must-revalidate' },
      data: instanceToPlain(data),
    })
    return plainToClass(IRequestTokenDto, response.data)
  } catch (err) {
    if (http.guards.isError(err) && err.response?.status === 400) {
      await tokenService.dispose()
      window.location.href = OpenPath.LOGIN
    }
  }

  return undefined
}
