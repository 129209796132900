import { EuiSelect, EuiSelectProps } from '@elastic/eui'
import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import React from 'react'
import * as system from 'styled-system'
import { StyledViewProps } from '../Grid/StyledView'

export interface IOption<T> {
  label: string
  value: T
}

export interface ISelectBaseProps<T> extends Omit<EuiSelectProps, 'onSelect' | 'options'> {
  options: Array<IOption<T>>
  onSelect: (value: T | undefined) => void
  selectedValue: T | undefined
}

export type ISelectProps<T> = StyledViewProps & ISelectBaseProps<T>

const StyledSelect = styled(EuiSelect)(
  system.color,
  system.flexbox,
  system.space,
  system.layout,
  system.position,
  system.justifyContent,
  system.alignItems,
  system.borders,
  system.shadow,
  system.background,
)

function Select<T extends number | string = string>(props: ISelectProps<T>) {
  const { options, onSelect, selectedValue, ...rest } = props
  const theme = useTheme()

  const onChange = (e: React.BaseSyntheticEvent) => {
    const value = e.target.value
    onSelect(typeof selectedValue === 'number' ? parseInt(value) : value)
  }

  return (
    <StyledSelect
      style={{
        borderRadius: theme.radii.medium,
      }}
      options={options}
      value={selectedValue}
      onChange={onChange}
      {...rest}
    />
  )
}

export default React.memo(Select) as typeof Select
