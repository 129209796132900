import * as React from 'react'
import { PopupModal, useCalendlyEventListener } from 'react-calendly'
import { Trans } from 'react-i18next'
import { MapDispatchToProps, MapStateToProps, connect } from 'react-redux'

import { EuiButton, EuiButtonEmpty, EuiLink, EuiStepsHorizontal } from '@elastic/eui'

import { noop } from 'modules/utils'
import { Grid, Modal, Paragraph, useScreen, useToggle, useTranslate } from 'modules/web-atoms'

import { EuiStepHorizontalProps } from '@elastic/eui/src/components/steps/step_horizontal'
import { useTheme } from '@emotion/react'
import security, { OnboardingStage } from 'modules/module-security'
import authentication, { IOnboardingStagePayload, ISendAppLinkPayload } from '../../authentication'
import DownloadAppStep from './steps/DownloadAppStep/DownloadAppStep'
import FinishOnboardingStep from './steps/FinishOnboardingStep/FinishOnboardingStep'
import ScanParcelStep from './steps/ScanParcelStep/ScanParcelStep'
import SelectFlowStep from './steps/SelectFlowStep/SelectFlowStep'
import WatchVideoStep from './steps/WatchVideoStep/WatchVideoStep'

export enum Flow {
  SETUP_APP = 'SETUP_APP',
  DEMO_BOOKED = 'DEMO_BOOKED',
}

export interface IOnboardingStep extends Omit<EuiStepHorizontalProps, 'step'> {
  previousStepName: string
  stepname: string
  step: number
  onClick: () => void
}
export interface IOnboardingModalStateProps {
  error: any
  loading: boolean
  onboardingStages: OnboardingStage
  lastOnboardingStage: string
  firstName: string | undefined
  lastName: string | undefined
  email: string | undefined
}

export interface IOnboardingModalDispatchProps {
  startUserLoggedInPoll: () => void
  stopUserLoggedInPoll: () => void
  startParcelScannedInPoll: () => void
  stopParcelScannedInPoll: () => void
  onSubmitStage: (params: IOnboardingStagePayload) => void
  onSendAppLink: (params: ISendAppLinkPayload) => void
}

export type IOnboardingModalProps = IOnboardingModalDispatchProps & IOnboardingModalStateProps

const CALENDLY_URL =
  'https://calendly.com/parcel-tracker/discover?hide_gdpr_banner=1&text_color=1c1c1c&primary_color=3f8089'

const initialSteps: IOnboardingStep[] = [
  {
    title: '',
    step: 1,
    previousStepName: OnboardingStage.ACC_PURPOSE_SELECTED,
    stepname: OnboardingStage.WATCH_VIDEO,
    isComplete: false,
    isSelected: true,
    onClick: noop,
  },
  {
    title: '',
    step: 2,
    previousStepName: OnboardingStage.WATCH_VIDEO,
    stepname: OnboardingStage.AWAIT_APP_DOWNLOAD,
    isComplete: false,
    isSelected: false,
    onClick: noop,
  },
  {
    title: '',
    step: 3,
    previousStepName: OnboardingStage.AWAIT_APP_DOWNLOAD,
    stepname: OnboardingStage.AWAIT_PARCEL_SCAN,
    isComplete: false,
    isSelected: false,
    onClick: noop,
  },
  {
    title: '',
    step: 4,
    previousStepName: OnboardingStage.AWAIT_PARCEL_SCAN,
    stepname: OnboardingStage.APP_SETUP_FINISHED,
    isComplete: false,
    isSelected: false,
    onClick: noop,
  },
  {
    title: '',
    step: 5,
    previousStepName: OnboardingStage.APP_SETUP_FINISHED,
    stepname: OnboardingStage.APP_SETUP_COMPLETE,
    isComplete: false,
    isSelected: false,
    onClick: noop,
  },
]

const INTL_PREFIX = 'onboarding.signup.onboarding'

export const OnboardingModal: React.FC<IOnboardingModalProps> = (props) => {
  const {
    startUserLoggedInPoll,
    stopUserLoggedInPoll,
    startParcelScannedInPoll,
    stopParcelScannedInPoll,
    lastOnboardingStage,
    onSubmitStage,
    onSendAppLink,
    onboardingStages,
    firstName,
    lastName,
    email,
    loading,
  } = props

  const t = useTranslate(INTL_PREFIX)
  const tCommon = useTranslate('commons')
  const { colors } = useTheme()
  const { isMobile } = useScreen()

  const [flow, setFlow] = React.useState<Flow | string>('')
  const [steps, setSteps] = React.useState<IOnboardingStep[]>(initialSteps)
  const [currentStep, setCurrentStep] = React.useState<IOnboardingStep>(initialSteps[0])
  const [isCalendlyOpen, toggleCalendly] = useToggle()

  const onEventScheduled = (e) => {
    onSubmitStage({ onboardingStage: OnboardingStage.DEMO_BOOKED })
  }

  const handleFlowChange = (value) => {
    setFlow(value)
  }

  const handleStepChange = () => {
    if (onboardingStages.includes(OnboardingStage.AWAIT_APP_DOWNLOAD)) {
      setFlow(Flow.SETUP_APP)
    }
    if (lastOnboardingStage === OnboardingStage.AWAIT_APP_DOWNLOAD) {
      startUserLoggedInPoll()
    } else {
      stopUserLoggedInPoll()
    }
    if (lastOnboardingStage === OnboardingStage.AWAIT_PARCEL_SCAN) {
      startParcelScannedInPoll()
    } else {
      stopParcelScannedInPoll()
    }
    if (onboardingStages.includes(OnboardingStage.ACC_PURPOSE_SELECTED)) {
      let updatedSteps = [...steps]
      let newCurrentStep: IOnboardingStep | undefined

      /* 1.- Find the current step */
      newCurrentStep = updatedSteps.find((step) => step.previousStepName === lastOnboardingStage)
      /* 2.- Change the previous steps as completed  */
      if (!!newCurrentStep) {
        updatedSteps = updatedSteps.map((step) =>
          step.step < newCurrentStep!!.step ? { ...step, isComplete: true, isSelected: false } : step,
        )
        updatedSteps = updatedSteps.map((step) =>
          step.step > newCurrentStep!!.step ? { ...step, isComplete: false, isSelected: false } : step,
        )
        updatedSteps = updatedSteps.map((step) =>
          step.step === newCurrentStep!!.step ? { ...step, isComplete: false, isSelected: true } : step,
        )
        /* 3.- Set the current step as selected */
        setSteps(updatedSteps)
        setCurrentStep(newCurrentStep)
      }
    }
  }

  React.useEffect(() => {
    if (onboardingStages && lastOnboardingStage) {
      handleStepChange()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onboardingStages, lastOnboardingStage])

  const CurrentStep = React.useMemo(() => {
    switch (currentStep.step) {
      case 1:
        return <WatchVideoStep />
      case 2:
        return <SelectFlowStep value={flow} onChange={handleFlowChange} />
      case 3:
        return <DownloadAppStep onSubmit={onSendAppLink} />
      case 4:
        return <ScanParcelStep />
      case 5:
        return <FinishOnboardingStep />
      default:
        break
    }
    return
  }, [currentStep.step, flow, onSendAppLink])

  const handleClick = (step) => {
    if (step === 2 && flow == Flow.DEMO_BOOKED) {
      toggleCalendly()
    } else {
      switch (step) {
        case 0:
          onSubmitStage({ onboardingStage: OnboardingStage.ACC_PURPOSE_SELECTED })
          break
        case 1:
          onSubmitStage({ onboardingStage: OnboardingStage.WATCH_VIDEO })
          break
        case 2:
          onSubmitStage({ onboardingStage: OnboardingStage.AWAIT_APP_DOWNLOAD })
          break
        case 3:
          onSubmitStage({ onboardingStage: OnboardingStage.AWAIT_PARCEL_SCAN })
          break
        case 4:
          onSubmitStage({ onboardingStage: OnboardingStage.APP_SETUP_FINISHED })
          break
        default:
          break
      }
    }
  }

  const handleClose = () => {
    onSubmitStage({ onboardingStage: OnboardingStage.APP_SETUP_SKIPPED })
  }

  const handleFinish = () => {
    onSubmitStage({ onboardingStage: OnboardingStage.APP_SETUP_COMPLETE })
  }

  useCalendlyEventListener({
    onProfilePageViewed: () => console.log('onProfilePageViewed'),
    onDateAndTimeSelected: () => console.log('onDateAndTimeSelected'),
    onEventTypeViewed: () => console.log('onEventTypeViewed'),
    onEventScheduled: onEventScheduled,
  })

  return (
    <>
      {onboardingStages && lastOnboardingStage && !isCalendlyOpen ? (
        <Modal onClose={handleClose} className="module-onboarding-modal">
          <Grid flex={1} padding="medium">
            {!isMobile && (
              <Grid alignItems="center" justifyContent="center">
                <EuiStepsHorizontal className="module-onboarding-modal-steps" steps={steps} />
              </Grid>
            )}
            <Grid flex={1} margin="none" justifyContent="center">
              <Grid flex={1}>
                <Grid.Row flex={1} alignItems="center" justifyContent="center" margin="none">
                  {CurrentStep}
                </Grid.Row>
                <Grid.Row
                  marginTop={['medium', 'small', 'small', 'large']}
                  justifyContent="center"
                  alignItems="flex-end"
                >
                  {currentStep.step > 1 && currentStep.step < 5 ? (
                    <EuiButtonEmpty
                      style={{ marginRight: 30 }}
                      onClick={() => handleClick(currentStep.step - 2)}
                      isLoading={loading}
                    >
                      {tCommon('action.back')}
                    </EuiButtonEmpty>
                  ) : null}
                  {currentStep.step < 5 ? (
                    <EuiButton
                      fill
                      disabled={!flow && currentStep.step === 2}
                      onClick={() => handleClick(currentStep.step)}
                      isLoading={loading}
                    >
                      {tCommon('action.next')}
                    </EuiButton>
                  ) : null}
                  {currentStep.step === 5 ? (
                    <EuiButton fill onClick={handleFinish} isLoading={loading}>
                      {tCommon('action.finish')}
                    </EuiButton>
                  ) : null}
                </Grid.Row>
                <Grid.Row justifyContent="center" marginTop="small">
                  {currentStep.step === 3 || currentStep.step === 4 ? (
                    <Paragraph textAlign="center">
                      <Trans
                        i18nKey={`${INTL_PREFIX}.book-live-demo`}
                        components={{
                          linkage: <EuiLink color="subdued" onClick={toggleCalendly} />,
                        }}
                      />
                    </Paragraph>
                  ) : null}
                </Grid.Row>
              </Grid>
            </Grid>
          </Grid>
        </Modal>
      ) : null}
      {isCalendlyOpen && (
        <PopupModal
          url={CALENDLY_URL}
          rootElement={document.getElementById('root')!}
          open={true}
          onModalClose={toggleCalendly}
          pageSettings={{
            hideGdprBanner: true,
            primaryColor: colors.primaryMid,
            textColor: colors.textDark,
          }}
          prefill={{
            email,
            firstName,
            lastName,
            name: `${firstName} ${lastName}`,
          }}
        />
      )}
    </>
  )
}

const mapStateToProps: MapStateToProps<IOnboardingModalStateProps, {}, any> = (state) => ({
  error: authentication.selectors.error(state),
  loading: authentication.selectors.isLoading(state),
  onboardingStages: security.selectors.onboardingStages(state),
  lastOnboardingStage: security.selectors.lastOnboardingStage(state),
  firstName: security.selectors.firstName(state),
  lastName: security.selectors.lastName(state),
  email: security.selectors.email(state),
})

const mapDispatchToProps: MapDispatchToProps<IOnboardingModalDispatchProps, {}> = (dispatch, _) => ({
  startUserLoggedInPoll: () => dispatch(authentication.actions.startUserLoggedInPoll()),
  stopUserLoggedInPoll: () => dispatch(authentication.actions.stopUserLoggedInPoll()),
  startParcelScannedInPoll: () => dispatch(authentication.actions.startParcelScannedInPoll()),
  stopParcelScannedInPoll: () => dispatch(authentication.actions.stopParcelScannedInPoll()),
  onSubmitStage: (params) => dispatch(authentication.actions.saveOnboardingStage(params)),
  onSendAppLink: (params) => dispatch(authentication.actions.sendAppLink(params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingModal)
