import { ITenantDto, TenantType } from 'api'
import API from 'api/clients'
import { plainToInstance } from 'class-transformer'
import { QueryKey } from 'modules/module-api'
import { SortDirection } from 'modules/module-api/hooks/use-paginated-api/use-paginated-api'
import useApi from 'modules/module-api/hooks/useApi/use-api'
import { useDebounce } from 'modules/module-utils/hooks'
import { useCallback, useMemo } from 'react'

export enum RecipientSortField {
  NAME = 'NAME',
  ALIAS = 'ALIAS',
  EMAIL = 'EMAIL',
  LOCATION = 'ROOM',
  LAST_MODIFIED = 'RECORD_MODIFIED',
  TYPE = 'TYPE',
  BUILDING = 'BUILDING_NAME',
  DROPOFF_LOCATION_NAME = 'DROPOFF_LOCATION_NAME',
}

interface IUseRecipientsParams {
  buildingIds: number[]
  page: number
  pageSize: number
  sortBy: RecipientSortField | undefined
  sort: SortDirection
  filter?: string
}

interface IResponse {
  Elements: ITenantDto[]
  TotalResults: number
}

export interface IRecipientItem {
  // Tenant ID
  id: string
  name: string
  alias?: string
  email: string
  location: string
  lastModified: string
  type?: TenantType
  sites: string[]
  siteIds: number[]
  dropoffLocationName?: string
}

function useRecipients({ buildingIds, page, pageSize, sortBy, sort, filter }: IUseRecipientsParams) {
  const debouncedFilter = useDebounce<string | undefined>('', 500, false)(filter)

  const formatTenants = useCallback((data: ITenantDto[]): IRecipientItem[] => {
    return data.map((item) => {
      const {
        id,
        firstName,
        lastName,
        alias,
        email,
        room,
        recordModified,
        siteName,
        primarySite,
        additionalSites,
        additionalSiteNames,
        type,
        dropoffLocationName,
      } = item

      return {
        id,
        name: `${firstName} ${lastName}`,
        alias,
        email,
        type,
        location: room,
        lastModified: recordModified || '',
        sites: [siteName ?? '', ...(additionalSiteNames ?? [])],
        siteIds: [primarySite as unknown as number, ...(additionalSites ?? [])],
        dropoffLocationName,
      }
    })
  }, [])

  const { data, isFetching, error, refetch } = useApi<IResponse>(
    [QueryKey.RECIPIENTS, buildingIds, page, pageSize, sortBy, sort, debouncedFilter],
    async () => {
      if (!!buildingIds.length) {
        const response = await API({
          url: '/v3/tenants',
          params: {
            development_ids: buildingIds.join(','),
            page,
            page_size: pageSize,
            sort_by: sortBy,
            sort,
            filter: debouncedFilter?.trim(),
          },
        })
        return response.data
      }
    },
    { keepPreviousData: true },
  )
  const { recipientsList, totalItems } = useMemo(() => {
    const recipientsList = data?.Elements ? plainToInstance(ITenantDto, data?.Elements) : []
    const totalItems: number = data?.TotalResults || 0
    return { recipientsList: formatTenants(recipientsList), totalItems }
  }, [data, formatTenants])

  return { recipientsList, totalItems, isFetchingTenants: isFetching, error, refetch }
}

export default useRecipients
