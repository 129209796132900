/* eslint-disable react/display-name */

import {
  CriteriaWithPagination,
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiButton,
  EuiButtonEmpty,
  EuiFieldText,
  EuiFormRow,
  EuiLink,
  EuiTableSelectionType,
  EuiToolTip,
  Pagination,
} from '@elastic/eui'
import theme from 'modules/dna'
import { Grid, H1, H3, H4, Icon, Page, Paragraph, useScreen, useToggle, useTranslate } from 'modules/web-atoms'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import upload from '../../upload'
import { RecipientField } from '../../uploading/types'
import { InvalidFieldWarningModal } from './InvalidFieldWarningModal/InvalidFieldWarningModal'
import SelectSiteModal from './SelectSiteModal/SelectSiteModal'

export type IValidateValues = Partial<
  Record<RecipientField, { id: string; value?: string; error?: any; editing: boolean }>
>

export interface IValidateRowItem extends IValidateValues {
  itemId: string
  status: boolean
}

export interface IValidateRowsPageProps {}

export const ValidateRowsPage: React.FC<IValidateRowsPageProps> = (props) => {
  const { ...rest } = props

  const t = useTranslate('recipients-upload.validate-rows')
  const dispatch = useDispatch()
  const { isMobile, isLaptop } = useScreen()

  const [selectedItems, setSelectedItems] = React.useState<IValidateRowItem[]>([])
  const [isWarningModalOpen, toggleWarningModal] = useToggle()

  const [showPerPageOptions, setShowPerPageOptions] = React.useState(true)
  const tableRef = React.useRef<any>()

  const invalidFields = useSelector(upload.selectors.invalidFields)

  const items = useSelector(upload.selectors.validateItems)
  const columnFields = useSelector(upload.selectors.validateColumns)
  const { pageIndex, pageSize } = useSelector(upload.selectors.validatePage)
  const totalItems = useSelector(upload.selectors.total)

  const isValid = useSelector(upload.selectors.isValid)
  const numInvalidRows = useSelector(upload.selectors.numInvalidRows)
  const error = useSelector(upload.selectors.error)

  const siteSelectionRequired = useSelector(upload.selectors.siteSelectionRequired)

  const handleBack = () => {
    dispatch(upload.actions.navigateRecipientUpload('previous'))
  }

  const onNext = () => {
    if (!isValid && !isWarningModalOpen) {
      dispatch(upload.actions.checkFieldValidation())
      toggleWarningModal(true)
      return
    }
    toggleWarningModal(false)
    dispatch(upload.actions.finaliseUpload())
  }

  const onTableChange = ({ page }: CriteriaWithPagination<any>) => {
    const { index: pageIndex, size: pageSize } = page
    dispatch(upload.actions.updateValidatePage({ page: pageIndex, pageSize }))
  }

  const onSelectionChange = React.useCallback((selectedItems) => {
    setSelectedItems(selectedItems)
  }, [])

  const onClickDelete = React.useCallback(() => {
    const rowIds = selectedItems.map((item) => item.itemId)
    dispatch(upload.actions.removeRows({ rowIds }))
    setSelectedItems([])
    tableRef?.current?.setSelection([])
  }, [dispatch, selectedItems])

  const onConfirmSite = (siteId: number) => {
    dispatch(upload.actions.setRecipientsSite({ siteId: siteId }))
  }

  const onCloseSiteSelection = () => {
    dispatch(upload.actions.siteSelectionToggle(false))
  }

  const selection = React.useMemo((): EuiTableSelectionType<IValidateRowItem> => {
    return {
      selectable: (item) => true,
      selectableMessage: (selectable) => '',
      onSelectionChange: onSelectionChange,
      initialSelected: selectedItems,
    }
  }, [onSelectionChange, selectedItems])

  const onFieldChange = React.useCallback(
    (column: string, id: string) => (event: React.ChangeEvent<any>) => {
      const { value } = event.target
      dispatch(upload.actions.adjustRow({ column, id, value }))
    },
    [dispatch],
  )

  // TODO for future editing feature
  const onSiteChange = React.useCallback(
    (column: string, id: string) => (value: string) => {
      dispatch(upload.actions.adjustRow({ column, id, value }))
    },
    [dispatch],
  )

  const columns = React.useMemo((): Array<EuiBasicTableColumn<IValidateRowItem>> => {
    const recipientFields = columnFields.map((column) => {
      return {
        field: [column],
        name: <Paragraph color="primaryMid">{t(`column.${column}`)}</Paragraph>,
        render: (data) => {
          return data.editable ? (
            <EuiFormRow
              style={{ margin: 0, padding: 0 }}
              error={data.error ? t(`error.${column}.${data.error}`) : undefined}
              isInvalid={!!data.error}
            >
              <EuiFieldText
                defaultValue={data.value}
                style={{ margin: 0 }}
                onChange={onFieldChange(column, data.id)}
                isInvalid={data.error != undefined}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    dispatch(upload.actions.updateEditable(undefined))
                  }
                }}
                onBlur={() => dispatch(upload.actions.updateEditable(undefined))}
                autoFocus
                compressed
              />
            </EuiFormRow>
          ) : data.error ? (
            <EuiToolTip position="top" content={t(`error.${column}.${data.error}`)}>
              <Grid direction="row" alignItems="center" margin="none">
                <Grid.Col>
                  <Icon.ErrorAlt size={20} />
                </Grid.Col>
                <Grid.Col>
                  <EuiLink
                    color="danger"
                    style={{ margin: 0, padding: 0 }}
                    onClick={() => {
                      dispatch(upload.actions.updateEditable({ itemId: data.id, column: column }))
                    }}
                  >
                    {t('invalid')}
                  </EuiLink>
                </Grid.Col>
              </Grid>
            </EuiToolTip>
          ) : (
            <Paragraph title={data.value} marginBottom="medium" flex={1} truncated={true}>
              {data.value}
            </Paragraph>
          )
        },
      }
    })

    return [
      ...recipientFields,
      {
        field: 'status',
        name: t('column.status'),
        dataType: 'boolean',
        render: (status: boolean) => {
          const StatusIcon = status ? Icon.Tick : Icon.Cross
          return <StatusIcon minWidth={20} width={20} marginLeft="small" />
        },
      },
    ]
  }, [columnFields, t, onFieldChange, dispatch])

  const pagination: Pagination = React.useMemo(() => {
    return {
      pageIndex,
      pageSize,
      totalItemCount: totalItems,
      pageSizeOptions: [5, 10, 20, 30],
      hidePerPageOptions: !showPerPageOptions,
    }
  }, [totalItems, pageIndex, pageSize, showPerPageOptions])

  const getRowProps = React.useCallback((item) => {
    const { status } = item
    return {
      className: status ? 'validated' : 'error',
      onClick: () => {},
    }
  }, [])

  return (
    <Page title={t('title')}>
      <Grid
        maxHeight={'100vh'}
        justifyContent="space-between"
        alignItems="center"
        paddingX="medium"
        marginTop={isLaptop ? 'small' : 'large'}
        marginBottom="medium"
      >
        <H1 textAlign="center">{t(isValid ? 'ready-to-upload' : 'heading')}</H1>
        {!isValid && (
          <H4 marginTop="smallest" color="primaryMid" textAlign="center">
            {t('subheading')}
          </H4>
        )}
        <Grid height={['100%', '70%']} marginTop={['smallest', 'medium']} marginX="smallest" paddingX="small">
          <Grid justifyContent="space-between" mb="small">
            {numInvalidRows > 0 && (
              <Grid.Col>
                <H3 color="dangerDestructive">{t('invalid-rows', { cur: numInvalidRows, total: items.length })}</H3>
              </Grid.Col>
            )}

            {selectedItems.length > 0 && (
              <Grid.Col>
                <EuiButton iconType="trash" color="warning" onClick={onClickDelete} fill>
                  Remove recipients
                </EuiButton>
              </Grid.Col>
            )}
          </Grid>

          <Grid height="100%">
            <EuiBasicTable
              className={`module-dashboard-map-file-step-table-validation ${isMobile ? '' : 'eui-yScroll'}`}
              ref={tableRef}
              items={items}
              key="constrained"
              itemId="itemId"
              rowHeader="firstName"
              noItemsMessage={'Validating file...'}
              columns={columns}
              rowProps={getRowProps}
              selection={selection}
              hasActions={true}
              isSelectable={true}
              pagination={pagination}
              onChange={onTableChange}
            />
          </Grid>
        </Grid>

        <Grid marginTop={['large', 'xxlarge']} justifyContent="center" alignItems="center">
          <Grid.Col>
            <EuiButtonEmpty onClick={handleBack}>Back</EuiButtonEmpty>
          </Grid.Col>
          <Grid.Col marginLeft="smallest">
            <EuiButton fill onClick={onNext} style={{ marginLeft: theme.spacing.large }}>
              Next
            </EuiButton>
          </Grid.Col>
        </Grid>
      </Grid>
      {isWarningModalOpen && (
        <InvalidFieldWarningModal
          onConfirm={onNext}
          onClose={() => toggleWarningModal()}
          fields={invalidFields ?? []}
        />
      )}
      {!!siteSelectionRequired && <SelectSiteModal onConfirmSite={onConfirmSite} onClose={onCloseSiteSelection} />}
    </Page>
  )
}

export default React.memo(ValidateRowsPage)
