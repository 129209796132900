import reducer from './root-reducer'
import sagas from './sagas'
import session from './session'
import reduxPersist from './storage/redux-persist/redux-persist'
import indexed from './storage/indexed/IndexedService'

export * from './storage'
export * from './session'
export * from './services'

export const security = {
  sagas,
  actions: session.actions,
  selectors: session.selectors,
  reducer: reducer,
  persistor: reduxPersist,
  indexed: indexed,
}

export default security
