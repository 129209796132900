export async function validatePhone(phone: string) {
  try {
    const validateAgainst = phone.startsWith('+') ? phone : `+${phone}`
    const phoneUtil = (await import('google-libphonenumber')).PhoneNumberUtil.getInstance()
    const number = phoneUtil.parseAndKeepRawInput(validateAgainst)
    return phoneUtil.isValidNumber(number)
  } catch (err) {
    return false
  }
}

export default validatePhone
