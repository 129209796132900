import lazy from '@loadable/component'
import { FeatureFlags } from 'api'
import { IModuleRoute } from 'modules/module-navigation'

const MODULE_PATH = '/portal/deliveries'

export enum DeliveriesPath {
  INBOUND_DIRECTORY = '/portal/deliveries/inbound-directory',
  OUTBOUND_DIRECTORY = '/portal/deliveries/outbound-directory',
}

const DeliveriesDirectory = lazy(async () => import('../screens/DeliveriesDirectory/DeliveriesDirectory'))
const OutboundDeliveriesDirectory = lazy(async () => import('../screens/OutboundDeliveriesDirectory/OutboundDeliveriesDirectory'))

export const routes: IModuleRoute = {
  path: MODULE_PATH,
  routes: [
    {
      path: DeliveriesPath.INBOUND_DIRECTORY,
      page: DeliveriesDirectory,
    },
    {
      path: DeliveriesPath.OUTBOUND_DIRECTORY,
      featureFlag: FeatureFlags.OUTBOUND,
      page: OutboundDeliveriesDirectory,
    },
  ],
}

export default routes
