import { get } from '@styled-system/core'

import Color from 'color'
import { ResponsiveValue, ThemeValue } from 'styled-system'

import theme from '../'
import { IDeepfinityTheme } from '../themes/model'

export type ResponsiveColor = ResponsiveValue<ThemeValue<'colors', IDeepfinityTheme>, IDeepfinityTheme>

export const useColor = (name: ResponsiveColor): Color => {
  const color = get(theme.colors, name)

  return new Color(color)
}

export default useColor
