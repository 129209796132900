import { useDebounce as useDebounceHook } from '@react-hook/debounce'
import * as React from 'react'

export const useDebounce =
  <T>(initialValue: T | undefined, wait?: number | undefined, leading?: boolean | undefined) =>
  (value: T | undefined) => {
    const [debounced, setDebounce] = useDebounceHook(initialValue, wait, leading)

    React.useEffect(() => {
      setDebounce(value)
    }, [value, setDebounce])

    return debounced
  }
