import * as React from 'react'

import { FontSize, FontSizeName, ResponsiveColor } from 'modules/dna'
import { strings } from 'modules/module-utils'
import { Grid, H3, IGridProps } from 'modules/web-atoms'

type Size = 's' | 'm' | 'l' | 'xl'
const sizes: { [K in Size]: number } = {
  s: 43,
  m: 50,
  l: 60,
  xl: 70,
}

const fontSizes: { [K in Size]: FontSizeName } = {
  s: 'large',
  m: 'xlarge',
  l: 'xxlarge',
  xl: 'xxxlarge',
}

export interface IAvatarProps extends IGridProps {
  firstChar?: string
  secondChar?: string
  fontColor: ResponsiveColor
  size?: Size
}

export const Avatar: React.FC<IAvatarProps> = (props) => {
  const { firstChar, secondChar, fontColor, size: sizeProp, ...rest } = props
  const size = sizeProp as Size

  return (
    <Grid
      borderRadius="round"
      width={sizes[size]}
      height={sizes[size]}
      {...rest}
      justifyContent="center"
      alignItems="center"
    >
      <H3 color={fontColor} fontSize={fontSizes[size]} fontWeight="bold" lineHeight="none" paddingBottom="macro">
        {firstChar?.length ? strings.capitalize(firstChar[0]) : ''}
        {secondChar?.length ? strings.capitalize(secondChar[0]) : ''}
      </H3>
    </Grid>
  )
}

Avatar.defaultProps = {
  size: 's',
}

export default React.memo(Avatar)
