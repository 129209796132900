import React from 'react'

interface IEventHandlers<T> {
  onMouseOver?: React.MouseEventHandler<T> | undefined
  onMouseOut?: React.MouseEventHandler<T> | undefined
}

export const useHover = <T>(): [boolean, IEventHandlers<T>] => {
  const [isHovered, setIsHovered] = React.useState(false)

  const eventHandlers: IEventHandlers<T> = React.useMemo(
    () => ({
      onMouseOver: () => {
        setIsHovered(true)
      },
      onMouseOut: () => {
        setIsHovered(false)
      },
    }),
    [],
  )

  return [isHovered, eventHandlers]
}
