import prefix from 'modules/utils/prefix'
import { createAction } from '@reduxjs/toolkit'
import {
  IConfigurationAssignAll,
  ITemplateAssignAll,
  IUpdateConfiguration,
  IAssignConfiguration,
  IAssignTemplate,
  IRemoveTemplate,
  ISetPermissionsPayload,
  ISetTemplateAsDefaultPayload,
  ICreateTemplate,
  IResetTemplate,
  IDuplicateTemplate,
} from './models'
import { IFormValues } from '../screens/EditNotificationTemplatePage/EditNotificationTemplatePage'

const namespaced = prefix('notifications/notification')

export const SET_LOADING = namespaced('setLoading')
export const setLoading = createAction<boolean>(SET_LOADING)

export const SET_ACTION_LOADING = namespaced('actionLoading')
export const setActionLoading = createAction<boolean>(SET_ACTION_LOADING)

export const SET_IS_ASSIGNING = namespaced('setIsAssigning')
export const setIsAssigning = createAction<boolean>(SET_IS_ASSIGNING)

export const SET_RESTORE_DEFAULT_LOADING = namespaced('setRestoreDefaultLoading')
export const setRestoreDefaultLoading = createAction<boolean>(SET_RESTORE_DEFAULT_LOADING)

export const UPDATE_CONFIGURATION = namespaced('updateConfiguration')
export const updateConfiguration = createAction<IUpdateConfiguration>(UPDATE_CONFIGURATION)

export const UPDATE_TEMPLATE = namespaced('updateTemplate')
export const updateTemplate = createAction<IFormValues>(UPDATE_TEMPLATE)

export const RESET_CONFIGURATION_TEMPLATE = namespaced('resetConfigurationTemplate')
export const resetConfigurationTemplate = createAction<string>(RESET_CONFIGURATION_TEMPLATE)

export const RESET_TEMPLATE = namespaced('resetTemplate')
export const resetTemplate = createAction<IResetTemplate>(RESET_TEMPLATE)

export const TEMPLATE_ASSIGN_ALL = namespaced('templateAssignAll')
export const templateAssignAll = createAction<ITemplateAssignAll>(TEMPLATE_ASSIGN_ALL)

export const CONFIGURATION_ASSIGN_ALL = namespaced('configurationAssignAll')
export const configurationAssignAll = createAction<IConfigurationAssignAll>(CONFIGURATION_ASSIGN_ALL)

export const ASSIGN_CONFIGURATION = namespaced('assignConfiguration')
export const assignConfiguration = createAction<IAssignConfiguration>(ASSIGN_CONFIGURATION)

export const ASSIGN_TEMPLATE = namespaced('assignTemplate')
export const assignTemplate = createAction<IAssignTemplate>(ASSIGN_TEMPLATE)

export const REMOVE_TEMPLATE = namespaced('removeTemplate')
export const removeTemplate = createAction<IRemoveTemplate>(REMOVE_TEMPLATE)

export const DUPLICATE_TEMPLATE = namespaced('duplicateTemplate')
export const duplicateTemplate = createAction<IDuplicateTemplate>(DUPLICATE_TEMPLATE)

export const DUPLICATE_CONFIGURATION = namespaced('duplicateConfiguration')
export const duplicateConfiguration = createAction<string>(DUPLICATE_CONFIGURATION)

export const SET_CURRENT_BUILDING_IDS = namespaced('setCurrentBuildingIds')
export const setCurrentBuildingIds = createAction<number[]>(SET_CURRENT_BUILDING_IDS)

export const DELETE_CONFIGURATION = namespaced('deleteConfiguration')
export const deleteConfiguration = createAction<string>(DELETE_CONFIGURATION)

export const DELETE_TEMPLATE = namespaced('deleteTemplate')
export const deleteTemplate = createAction<string>(DELETE_TEMPLATE)

export const CREATE_NEW_TEMPLATE = namespaced('createNewTemplate')
export const createNewTemplate = createAction<ICreateTemplate>(CREATE_NEW_TEMPLATE)

export const CREATE_NEW_CONFIGURATION = namespaced('createNewConfiguration')
export const createNewConfiguration = createAction(CREATE_NEW_CONFIGURATION)

export const SET_TEMPLATE_AS_DEFAULT = namespaced('setTemplateAsDefault')
export const setTemplateAsDefault = createAction<ISetTemplateAsDefaultPayload>(SET_TEMPLATE_AS_DEFAULT)

export const SET_PERMISSIONS = namespaced('setPermissions')
export const setPermissions = createAction<ISetPermissionsPayload>(SET_PERMISSIONS)

export const SET_OPENED_LIST_MODAL = namespaced('setOpenedListModal')
export const setOpenedListModal = createAction<string | undefined>(SET_OPENED_LIST_MODAL)
