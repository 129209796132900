import { EuiSwitch } from '@elastic/eui'
import styled from '@emotion/styled'
import shouldForwardProp from '@styled-system/should-forward-prop'
import React from 'react'
import * as system from 'styled-system'
import { StyledViewProps } from '../Grid/StyledView'

export type ISwitchProps = StyledViewProps & React.ComponentProps<typeof EuiSwitch>

export const StyledSwitch = styled(EuiSwitch, { shouldForwardProp })<ISwitchProps>(
  system.color,
  system.flexbox,
  system.space,
  system.layout,
  system.position,
  system.justifyContent,
  system.alignItems,
  system.borders,
  system.shadow,
  system.background,
)

export const Switch: React.FC<ISwitchProps> = (props) => {
  const { children, ...rest } = props
  return <StyledSwitch {...rest}>{children}</StyledSwitch>
}

export default React.memo(Switch)
