import React from 'react'

import { EuiBasicTable, EuiBasicTableProps } from '@elastic/eui'
import styled from '@emotion/styled'

import { StyledViewProps } from 'modules/web-atoms'
import * as system from 'styled-system'

const FCBasicTable: React.FC<EuiBasicTableProps<any>> = (props) => <EuiBasicTable {...props} />

export type IBasicTableProps = StyledViewProps & React.ComponentProps<typeof FCBasicTable>

const StyledTable = styled(FCBasicTable)<IBasicTableProps>(
  system.color,
  system.flexbox,
  system.space,
  system.layout,
  system.position,
  system.justifyContent,
  system.alignItems,
  system.borders,
  system.shadow,
  system.background,
)

export const BasicTable: React.FC<IBasicTableProps> = (props) => {
  return <StyledTable {...props} />
}

export default React.memo(BasicTable)
