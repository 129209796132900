import { combineReducers } from 'redux'

import session from './session'
import storage from 'redux-persist/lib/storage'
import persistReducer from 'redux-persist/es/persistReducer'

export const reducer = combineReducers({
  session: session.reducer,
})

const persistConfig = {
  key: 'security',
  storage,
  whitelist: [],
}

const persistedReducer = persistReducer(persistConfig, reducer)

export default persistedReducer
