/* eslint-disable no-undef */
import prefix from 'modules/utils/prefix'
import { ISetPermissionsPayload, IUpdateAccountPayload, IUpdatePasswordPayload, IUpdateCompanyInfoPayload } from './'
import { createAction } from '@reduxjs/toolkit'

export const namespaced = prefix('account/user')

export const SET_LOADING = namespaced('setLoading')
export const setLoading = createAction<boolean>(SET_LOADING)

export const SET_PERMISSIONS = namespaced('setPermissions')
export const setPermissions = createAction<ISetPermissionsPayload>(SET_PERMISSIONS)

export const UPDATE_ACCOUNT = namespaced('updateAccount')
export const updateAccount = createAction<IUpdateAccountPayload>(UPDATE_ACCOUNT)

export const UPDATE_COMPANY_INFO = namespaced('updateCompanyInfo')
export const updateCompanyInfo = createAction<IUpdateCompanyInfoPayload>(UPDATE_COMPANY_INFO)

export const UPDATE_PASSWORD = namespaced('updatePassword')
export const updatePassword = createAction<IUpdatePasswordPayload>(UPDATE_PASSWORD)

export const ERROR = namespaced('error')
export const error = createAction<string | undefined>(ERROR)

type Action = typeof setPermissions
export default Action
