/* eslint-disable */
export const emailRegex = RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
)

export const textRegex = RegExp(/^[a-zA-záéíóúÁÉÍÓÚñÑ]+(\s+|[a-zA-ZáéíóúÁÉÍÓÚñÑ])*$/)

export const phoneRegex = RegExp(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]*$/)

export const passwordRegex = RegExp(
  /^(?=[a-zA-ZÑ!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])(?=.*[0-9])[a-zA-ZÑ!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?\d]{8,}$/,
)

export const zipRegex = RegExp(/^(\d{5}([-]\d{4})?)$/)

export const formValid = (errors: any, values: any): boolean => {
  let valid = true
  // validate form errors being empty
  Object.values(errors).forEach((val: any) => {
    val.length > 0 && (valid = false)
  })
  // validate the form was filled out
  Object.values(values).forEach((val: any) => {
    ;(val === null || !val.length) && (valid = false)
  })
  return valid
}
