import { EuiButton, EuiButtonEmpty, EuiSwitchEvent } from '@elastic/eui'
import { FormikConfig, useFormik } from 'formik'
import { useAppDispatch, useAppSelector } from 'modules/module-utils'
import { Grid, H1, H3, Paragraph, Switch, useTranslate } from 'modules/web-atoms'
import { FieldText, FormRow, IPanelProps, Panel } from 'modules/web-molecules'
import React from 'react'
import * as Yup from 'yup'
import settings, { SettingsFormState } from '../../../settings'

export interface ILocationFormValues {
  name: string
  identifier: string
  enabled: boolean
  keepShowingForm?: boolean
}

const INITIAL_VALUES: ILocationFormValues = {
  name: '',
  identifier: '',
  enabled: true,
}

export type ILocationFormProps = {} & IPanelProps

export const LocationForm: React.FC<ILocationFormProps> = ({ ...rest }) => {
  const t = useTranslate('settings.tabs.locations.form')
  const dispatch = useAppDispatch()
  const formState = useAppSelector(settings.selectors.locationFormState)
  const selectedLocation = useAppSelector(settings.selectors.selectedLocation)
  const isAdding = useAppSelector(settings.selectors.isAddingLocation)
  const isEditing = useAppSelector(settings.selectors.isEditingLocation)
  const isFormLoading = formState === SettingsFormState.CREATE ? isAdding : isEditing

  const onCancel = () => {
    dispatch(settings.actions.setLocationFormState(SettingsFormState.HIDDEN))
  }

  const onSubmit: FormikConfig<ILocationFormValues>['onSubmit'] = (values, { resetForm }) => {
    if (formState === SettingsFormState.CREATE) {
      dispatch(settings.actions.addLocation({ ...values }))
      resetForm()
    } else {
      dispatch(settings.actions.editLocation({ ...values }))
    }
  }

  const validationSchema = React.useMemo(
    () =>
      Yup.object<ILocationFormValues>().shape({
        name: Yup.string().trim().required(t('validation.required')),
        identifier: Yup.string().trim().optional().min(1),
      }),
    [t],
  )
  const formik = useFormik<ILocationFormValues>({
    initialValues: INITIAL_VALUES,
    validationSchema,
    onSubmit,
  })
  const { values, touched, errors, setValues, setFieldValue, submitForm, handleChange, resetForm } = formik

  const onSaveAndAddAnother = () => {
    void setFieldValue('keepShowingForm', true)
    void submitForm()
  }

  const onEnabledChange = (e: EuiSwitchEvent) => {
    const { checked } = e.target
    void setFieldValue('enabled', checked)
  }

  React.useEffect(() => {
    if (selectedLocation) {
      void setValues({
        ...INITIAL_VALUES,
        name: selectedLocation.name,
        identifier: selectedLocation.identifier,
        enabled: !!selectedLocation.enabled,
      })
    } else {
      resetForm()
    }
  }, [selectedLocation, setValues, resetForm])

  return (
    <Panel width={['100%', 300, 350, 450]} py="medium" px="large" {...rest}>
      <Grid height="100%" justifyContent="space-between">
        <Grid flexGrow={1} minHeight={0} gutterSize="medium" className="eui-yScroll">
          <Grid>
            {formState === SettingsFormState.CREATE ? (
              <Grid gutterSize="none">
                <H1 color="primaryMid" marginBottom={['none', 'smallest']}>
                  {t('heading')}
                </H1>

                <H3 mb="none">{t('subheading')}</H3>
              </Grid>
            ) : (
              <H1 color="primaryMid" marginBottom={['none', 'smallest']}>
                {selectedLocation?.name}
              </H1>
            )}
          </Grid>

          <Grid justifyContent="flex-start" paddingX="none">
            <FormRow label={t('name')} touched={touched.name} error={errors.name}>
              <FieldText type="text" name="name" value={values.name} onChange={handleChange('name')} />
            </FormRow>
            <FormRow label={t('identifier')} touched={touched.identifier} error={errors.identifier} mt="smallest">
              <FieldText
                type="text"
                name="identifier"
                value={values.identifier}
                onChange={handleChange('identifier')}
              />
            </FormRow>

            <Switch label={t('enabled')} checked={values.enabled} onChange={onEnabledChange} mt="small" ml="macro" />
          </Grid>
        </Grid>

        <Grid alignItems="center">
          {!selectedLocation && (
            <EuiButtonEmpty color="primary" flush="both" isLoading={isFormLoading} onClick={onSaveAndAddAnother}>
              {t('save-another')}
            </EuiButtonEmpty>
          )}

          <Grid alignItems="center" justifyContent="center" gutterSize="medium">
            <Grid.Col>
              <EuiButtonEmpty color="primary" onClick={onCancel} isDisabled={isFormLoading}>
                <Paragraph color="textDark">{t('cancel')}</Paragraph>
              </EuiButtonEmpty>
            </Grid.Col>
            <Grid.Col>
              <EuiButton color="primary" fill isLoading={isFormLoading} onClick={submitForm}>
                {t('save')}
              </EuiButton>
            </Grid.Col>
          </Grid>
        </Grid>
      </Grid>
    </Panel>
  )
}

export default React.memo(LocationForm)
