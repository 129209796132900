import prefix from 'modules/utils/prefix'
import { ToastType } from 'modules/web-atoms'
import { createAction } from 'redux-actions'
import { v4 as uuidv4 } from 'uuid'
import { ICopyPayload, IShowBannerPayload, IShowToastParams, IShowToastPayload, IShowTypedToastParams } from './model'

const namespaced = prefix('alerts/alert')

// Toasts
export const SHOW = namespaced('show')
export const show = createAction<IShowToastPayload, IShowToastParams>(SHOW, (config) => ({
  id: uuidv4(),
  title: config.title,
  message: config.message,
  type: config.type ?? ToastType.INFO,
}))

export const HIDE = namespaced('hide')
export const hide = createAction<string>(HIDE)

export const COPY = namespaced('copy')
export const copy = createAction<ICopyPayload>(COPY)

export const showInfo = (config: IShowTypedToastParams) => show({ ...config, type: ToastType.INFO })
export const showSuccess = (config: IShowTypedToastParams) => show({ ...config, type: ToastType.SUCCESS })
export const showWarning = (config: IShowTypedToastParams) => show({ ...config, type: ToastType.WARNING })
export const showError = (config: IShowTypedToastParams) => show({ ...config, type: ToastType.DANGER })

// Banner
export const SHOW_BANNER = namespaced('showBanner')
export const showBanner = createAction<IShowBannerPayload>(SHOW_BANNER)

export const STORE_BANNER = namespaced('storeBanner')
export const storeBanner = createAction<IShowBannerPayload>(STORE_BANNER)

export const BANNER_HIDE = namespaced('bannerHide')
export const bannerHide = createAction<string>(BANNER_HIDE)

export const BANNER_ACTION = namespaced('bannerAction')
export const bannerAction = createAction(BANNER_ACTION)
